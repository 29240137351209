import React, { Component,Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import {OverlayTrigger,Popover} from 'react-bootstrap'
const popoverStyles={
  popover:{
    minWidth:'550px',
    fontSize:'12px',
    pointerEvents:'none' 
  }
}
const PopOverContent=(props)=>{
  let recObj=props.original[props.column.Header][0]
  return (
  <div className="pd-5 bg-white">
  <div className="row">
    <div className="col-md-3">
    <strong>Commission Type:</strong>
    </div>
    <div className="col-md-3"> 
    <label>{recObj.commissionTypeName}</label>     
    </div>
    <div className="col-md-3"> 
    <strong>Driver Level:</strong>     
    </div>
    <div className="col-md-3">  
    <label>{recObj.driverLevelName}</label>    
    </div>
  </div>
  <div className="row">
    <div className="col-md-3">
      <div className="row">
        <div className="col-md-12 mt-3 mb-3  text-center">
        <h6  className="text-green">{'FLAT RATE'}</h6>
        </div>
        <div className="col-md-12 text-center">
          <label>{'$ ' +recObj.commFlatRate||0}</label>
        </div>
      </div>
    </div>
    <div className="col-md-4">
    <div className="row">
        <div className="col-md-12 mt-3 mb-3  text-center">
        <h6 className="text-green">{'HOURLY'}</h6>
        </div>
        <div className="col-md-12  text-center">
        <label>
          <p style={{textAlign:'justify', textTransform:'uppercase'}}>{`$ ${recObj.hourlyComm} PER HOUR WITH ${recObj.hourlyCommMinHrs||0} HRS MIN BY ${recObj.hourlyCommIncrHrs||0} HR INCREMENT STARTING FROM ${recObj.commStartFrom} TO ${recObj.commEndBy}` }</p>
        </label>
          
        </div>
    </div>
    </div>
    <div className="col-md-5">
    <div className="row">
        <div className="col-md-12 mt-3 mb-3  text-center">
        <h6  className="text-green">{'PERCENTAGE'}</h6>
        </div>
        <div className="col-md-12  text-center">
        <label>
          <p style={{textAlign:'left'}}>
            <span style={{display:'block'}}>{`${recObj.commPercentAllCharges}% OF TOTAL`}</span>
          <span style={{display:'block'}}>{`${recObj.commPercentTowCharge}% OF TOW CHARGE`}</span>
          <span style={{display:'block'}}>{`${recObj.commPercentLabourCharge}% OF LABOUR CHARGE`}</span>
          <span style={{display:'block'}}>{`${recObj.commPercentStorageCharge}% OF STORAGE CHARGE`}</span>
          <span style={{display:'block'}}>{`${recObj.commPercentMileageCharge}% OF MILEAGE CHARGE`}</span>
          </p>
        </label>
        </div>
    </div>
  </div>
	
  </div></div>
)}
class CommissionList extends Component {
  constructor(props){
    super(props);
    this.bindCols=this.bindCols.bind(this);
    this.bindData=this.bindData.bind(this); 
    this.onCommissionBtnClick=this.onCommissionBtnClick.bind(this);
  }
  onCommissionBtnClick=(e,props,_type)=>{
    if(props.original[props.column.id]){
      this.props.onAddEditCommissionDetailsClick(props.original[props.column.id]||[],_type);
     }else{
      let _commissionType= this.props.commissionData.commissionTypes.find(ctype=>{
        return ctype.optionTxt===props.original.commissionType
      }) ;
      let _driverLevel=this.props.commissionData.driverLevels.find(level=>{
        return level.optionTxt===props.column.id
      });
      this.props.onAddEditCommissionDetailsClick([{
        commissionTypeId:_commissionType.optionVal,
        driverLevelId:_driverLevel.optionVal
        }],_type);
     }

  }
  bindCols=()=>{
    let cols=[{
      Header:"COMMISSION TYPE",
      accessor:'commissionType',
      minWidth:200,
      Cell:(props)=>{
        return props.original['commissionType']!==''?<span>{props.original['commissionType']}</span>:
        <button className="btn btn-link" onClick={(e)=>{
         this.props.showModalForAdd(e,'C')
        }} disabled={(this.props.accessLevel.basicAccess.add||false)===true?false:true}><i className="fa fa-plus-circle"></i></button>
      }
    }]
    this.props.commissionData.driverLevels.forEach(driverItem=>{
      cols.push({
        Header:driverItem.optionTxt,
        accessor:driverItem.optionTxt,
        Cell:(props)=>{
          let isAdd=(props.original[props.column.id]||[]).length >0?false:true
         return props.original['commissionType']!==''?
         isAdd?
          <button className="btn btn-primary btn-sm" onClick={(e)=>{
          this.onCommissionBtnClick(e,props,'add')
        }} disabled={(this.props.accessLevel.basicAccess.add||false)===true?false:true}>{'Add'}</button>:

         <OverlayTrigger
        trigger="hover"
        placement="auto"
        overlay={
          <Popover id="popover-contained" style={popoverStyles.popover} ><PopOverContent {...props} /></Popover>
        }
      >
        <button className="btn btn-primary btn-sm" onClick={(e)=>{
           this.onCommissionBtnClick(e,props,'edit')
         }}>{'Edit'}</button></OverlayTrigger>

         :null 
        }
      })
    })
    return cols;
  }
  bindData=()=>{
    let commissionDetailsList=this.props.commissionData.commissionDetailsList
    let commissionTypes=this.props.commissionData.commissionTypes.map(item=>{
      return item.optionTxt
    })
    let groupByCommissionType=commissionDetailsList.reduce((a, b) => {
          (a[b['commissionTypeName']] = a[b['commissionTypeName']] || []).push(b);
          return a;
      }, {});
      let groupByCommission_DriverLevel=[]
   Object.keys(groupByCommissionType).forEach(item=>{  
    commissionTypes.splice( commissionTypes.indexOf(item), 1 );  
     let subGroup=groupByCommissionType[item].reduce((a,b) => {
      (a[b['driverLevelName']] = a[b['driverLevelName']] || []).push(b);
      return a;
     },{});
     groupByCommission_DriverLevel.push({'commissionType':item, ...subGroup});
   });
   if(commissionTypes.length>0){
    commissionTypes.forEach(item=>{
      groupByCommission_DriverLevel.push({'commissionType':item})
    })
   }
  groupByCommission_DriverLevel.push({'commissionType':''})
  // console.log(groupByCommission_DriverLevel);
   return groupByCommission_DriverLevel
  }

  
  render() {
    const tableData=this.bindData();
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-10">				
        <TmReactTable
            filterable={false}
            sortable={true}
            resizable={false}
            showPagination={false}
            pageSize={tableData.length}
            data={tableData}
            columns={this.bindCols()}
        />	
        </div></div>
      </Fragment>
    );
  }
}

export default CommissionList;
