import * as actionTypes from '../../../actions/actionTypes';
const defaultObject={
  auctionBatchPay:{
    "checkNo": "",
    "companyId": "",
    "invoicesPayment": [],
    "monthlyPayment": [],
    "paymentDate": "",
    "paymentType": "",
    "totalPaymentAmt": "",
    "transactionNo": ""
  }  
}
export const auctionBatchPaymentReducer = (state=defaultObject, action) => {
    switch (action.type) {
      case actionTypes.GET_AUCTIONPAYMENT_LIST:
        return {...state , auctionBatchPay:JSON.parse(JSON.stringify(action.auctionBatchPay || defaultObject.auctionBatchPay))};
      default:
        return state;
    }
  };