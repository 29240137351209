import * as actionTypes from '../actionTypes';
import {getAxiosInstance} from '../../util/service';
import {moduleTypes} from '../../util/constants';
import{store}  from '../../store/configureStore';
import {getAccessToken} from '../authenticateActions'
import {getCompanyId,isManagement} from '../../util/customMethods';
import moment from 'moment';

export const getInvoiceMoreThan = (range) => {
    return async(dispatch) => {
      let url=`search/account/invoiceMoreThanThirtyDays?query=&companyId=${getCompanyId()}&noOfDays=${range}&access_token=${getAccessToken()}`;
      try{
      const response=await getAxiosInstance(moduleTypes.ADMIN).get(url)        
          if(response.status==200){
            dispatch(
              {
                  type: actionTypes.ADMIN_GET_INV_MORETHAN,
                  invoiceMoreThan:response.data
              });
            }else{
            dispatch(
              {
                  type: actionTypes.ADMIN_GET_INV_MORETHAN,
                  invoiceMoreThan:[]
              });
           }             
          }
          //dispatch(spinnerActions.hideSpinner());          
        catch(error) {
          //dispatch(spinnerActions.hideSpinner());
          dispatch(
            {
                type: actionTypes.ADMIN_GET_INV_MORETHAN,
                invoiceMoreThan:[]
            });
        };
    };
};

export const getExpireAccounts = (range) => {
  return async (dispatch) => {
    //dispatch(spinnerActions.showSpinner());   
    let url=`search/account/expireAccounts?query=&companyId=${getCompanyId()}&noOfDays=${range}&access_token=${getAccessToken()}`;
    try{
      const response=await getAxiosInstance(moduleTypes.ADMIN).get(url)        
          if(response.status==200){
            dispatch(
              {
                  type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS,
                  expireAccounts:response.data
              });
            }else{
              dispatch(
                {
                    type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS,
                    expireAccounts:[]
                });
           }             
          }
          //dispatch(spinnerActions.hideSpinner());          
        catch(error) {
          //dispatch(spinnerActions.hideSpinner());
          dispatch(
            {
                type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS,
                expireAccounts:[]
            });
        };
    };
};
export const saveInvoiceMoreThanDays = (range) => {
  return (dispatch) => {
        dispatch({
            type: actionTypes.ADMIN_GET_INV_MORETHAN_DAYS,
            invoiceMoreThanDays:range
        })
       }
}
export const saveExpireAccountsDays = (range) => {
  return (dispatch) => {
        dispatch({
            type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS_DAYS,
            expiryAccountsDays:range
        })
       }
}
export const getAllAccounts = () => {
  return async (dispatch) => {
    //dispatch(spinnerActions.showSpinner());   
    let url=`account/searchNameAndType/${isManagement()}?searchQuery=&limit=null&accountName=&accountType=&accountStatus=0,1&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
      const response=await getAxiosInstance(moduleTypes.ADMIN).get(url)        
          if(response.status==200){
            dispatch(
              {
                type: actionTypes.ADMIN_GET_ALL_ACCOUNTS,
                allAccounts:response.data
            });
            }else{
              dispatch(
                {
                  type: actionTypes.ADMIN_GET_ALL_ACCOUNTS,
                  allAccounts:[]
              });
           }             
          }
          //dispatch(spinnerActions.hideSpinner());          
        catch(error) {
          //dispatch(spinnerActions.hideSpinner());
          dispatch(
            {
              type: actionTypes.ADMIN_GET_ALL_ACCOUNTS,
              allAccounts:[]
          });
        };
    };
};
