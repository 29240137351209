import React, { Component } from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import * as reportsDashboardActions from '../../../../../actions/launchAction/reportsDashboardActions';
let CurrentDate = moment().format('YYYY-MM-DD');
class PaylessInventory extends Component{
    componentWillMount(){        
        let {dispatch} =this.props;
        dispatch(reportsDashboardActions.getPaylessInventory(CurrentDate, CurrentDate))
    }
    render(){
        return(
            <>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12"> 
                    <div class="card card-stats" style={{minHeight: '185px'}}>
                        <div class="card-header" data-background-color="maroon"><img width="40" src={require('../../../../../assets/img/totVehicles.png')}/></div>
                        <div class="card-content">                          
                            <p class="category" style={{minHeight: '35px', fontSize: '0.85em'}}>Payless Inventory</p>
                            <p class="category" style={{fontSize: '11px', paddingTop: '11px', color: 'rgb(115, 135, 156)', fontWeight: 'bold'}}>{'till '+CurrentDate }</p>
                            <span class="title" style={{fontSize: '25px'}}>{this.props.reportDashboard.paylessInventory.paylessVehicleCount || 0}</span>
                        </div>
                        <div class="card-footer text-right">
                        <div class="stats">               
                        </div>
                        </div>                       
                    </div> 
                    </div>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(PaylessInventory);