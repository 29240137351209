import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isValidForm } from '../../../util/validationMethods';
import {tableColumnsPartial} from '../../../util/tableColumnUtil/managedInvoicesCols'
import { initInvoiceManageState} from '../../../util/stateUtil/initialStateForComponents'
import PartialPaymentReportCtrl from '../../../components/reportsComponents/invoicemanagement/partialPaymentReportCtrl';
import PartialPaymentReportTable from '../../../components/reportsComponents/invoicemanagement/partialPaymentReportTable';
import {isManagement,getCompanyId} from '../../../util/customMethods'
import {cleanManagedInvociesReport,emailManagedInvoice,retrieveInvoiceManageReport} from '../../../actions/reportAction/invoiceManage/invoiceManageAction'
import { notify } from '../../../util/notifications';
import printJS from 'print-js';
const printJSHeaders = tableColumnsPartial.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
const pageTitle = 'Partial Payment Report'
let reportForm={
}

class PartialPaymentReport extends Component {
	state = {
        ...initInvoiceManageState
    }
    Go = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retrieveInvoiceManageReport(payload, status));
        if (res.statusCode === 200) {
            notify.createNotification('success', res.statusMsg, this.props.refNotify);
            this.setState({ render: false });
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    Export = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retrieveInvoiceManageReport(payload, status));
        if (res.statusCode === 200) {
            this.setState({ render: !this.state.render });
            notify.createNotification('success','Report exported successfully.', this.props.refNotify);
            this.setState({ render: false });
        } else {
            this.setState({ render: false });
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    Print = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retrieveInvoiceManageReport(payload, status));
        if (res.statusCode === 200) {
            printJS({
                printable: this.props.data,
                properties: printJSHeaders,
                type: 'json',
                documentTitle: 'Driver performance Report'
            });
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    email = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(emailManagedInvoice(payload, status));
        if (res.statusCode === 200) {
            notify.createNotification('success',res.statusMsg, this.props.refNotify);
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

        /* Building all invoice object for post request */
        buildObject = () => {
            const stateObj = this.state;
            const constractObj = {
                idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
                asOfDate: stateObj.asOfDate,
                location:stateObj.selectedLocation,
                name:"partial payment"
            };
            return constractObj;
        }
	componentWillMount = () => {
        const { dispatch } = this.props;
        dispatch(cleanManagedInvociesReport());
		document.title = pageTitle;
		
    }
    onPartialPaymentReportFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let stateObj = this.state;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Partial Payment Report</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <PartialPaymentReportCtrl 
                     Go={()=>{
                        reportForm.validateAll()
                        if(isValidForm(reportForm)){
                            this.Go();
                        }}}  
                        Export={()=>{
                            reportForm.validateAll()
                            if(isValidForm(reportForm)){
                                this.Export();
                            }}}
                    email={()=>{
                        reportForm.validateAll()
                        if(isValidForm(reportForm)){
                            this.email();
                        }}} Print={()=>{
                            reportForm.validateAll()
                            if(isValidForm(reportForm)){
                                this.Print();
                            }}}
                    childCompList={this.props.childCompList}  stateObj={stateObj} location={this.props.locations}
                    onPartialPaymentReportFieldChange={(key, value)=>this.onPartialPaymentReportFieldChange(key, value)}
                    initCallBack={(frm)=>{
						reportForm = frm;
                    }} asofdate={this.state.asOfDate} data={this.props.data}/>
                    <PartialPaymentReportTable
                     cols={tableColumnsPartial}
                    data={this.props.data}/>
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
        locations: state.location.locationList,
        childCompList: state.searchInvoiceList.childComList,
        data:state.invoiceManageObj.invoiceManageReportData
	}
};

export default withRouter(connect(mapStateToProps)(PartialPaymentReport));
