//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
export const tableColumns = [
    {
        accessor: 'account',
        Header: 'Account',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'unPaid',
        Header: '# Unpaid',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'balance',
        Header: 'Balance',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.balance}`
    }
];
