import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table-v6';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { required, isValidForm } from '../../../../util/validationMethods'
import {isManagement} from '../../../../util/customMethods'
import Select from 'react-validation/build/select';
import CitiesReviewedByLocation from './citiesReviewedByLocation';
import TmGoogleAutocomplete from '../../../utilComponents/tmGooglePlaceSuggest/tmGoogleAutocomplete'
class LocationInfo extends Component {
   state = {
            showAddressModal: false,
            addressArray: []
    }
    bindCityList = (cityList) => {
        let _cityArray = [];
        if ((cityList || '').length > 0) {
            _cityArray = (cityList).split(',').map(item => {
                return { cityName: item }
            });
        }
        return _cityArray
    }
    getAddressComponent(address, component, type) {
        var element = '';
        element = address.address_components.filter(address_component => {
            return address_component.types[0] === component
        })
        if (element.length > 0) {
            return type === 'short' ? element[0].short_name || '' : element[0].long_name || '';
        } else {
            return '';
        }
    }
    splitGoogleAddress = (googleAddress) => {
        let _fullAddress = googleAddress.formatted_address;
        let addressObj = {
            selected: false,
            addrLine: _fullAddress.split(',')[0] || '',
            city: _fullAddress.split(',')[1] || '',
            state: this.getAddressComponent(googleAddress, 'locality', 'long'),
            country: this.getAddressComponent(googleAddress, 'locality', 'long'),
            zip: this.getAddressComponent(googleAddress, 'postal_code', 'short'),
            fullAddress: _fullAddress
        }
        this.setState({ addressArray: [addressObj], showAddressModal: true })
    }
    checkAccess = () => {
        if (this.props.isEdit === false) {
            if ((this.props.accessLevel.basicAccess.add || false) === true) {
                return false
            } else {
                return true
            }
        } else {
            if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                return false;
            } else {
                return true;
            }
        }
    }

    render() {
        console.log('props', this.props);
        
        const { locationRecord } = this.props

        console.log(locationRecord.state, 'is');
        
        return (<Fragment>
            <div className="card box-shadow">
                <div className="card-body">
                    <Form ref={(frm) => { this.form = frm }} >
                        <fieldset disabled={this.checkAccess()}>
                            <div className="row">

                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">LOCATION NAME</label>
                                        <div className="">

                                            <Input className="form-control" name="location" value={locationRecord.location} type="text" placeholder=""
                                                requiredmsg="Please enter Location Name"
                                                validations={this.props.isEdit ? [] : [required]} disabled={this.props.isEdit} onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="custom-control custom-checkbox ml-3 mb-5">
                                            <input type="checkbox" name="physicalLocation" disabled={this.props.isEdit} checked={(locationRecord.physicalLocation || 0) === 1} className="custom-control-input"
                                                id="physicalLCheck" onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.checked ? 1 : 0);
                                                }} />
                                            <label className="custom-control-label" htmlFor="physicalLCheck">PHYSICAL LOCATION</label>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="custom-control custom-checkbox ml-3 mb-5">
                                            <input type="checkbox" name="impoundLocation" disabled={this.props.isEdit} checked={(locationRecord.impoundLocation || 0) === 1} className="custom-control-input" id="impoundLCheck"
                                                onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.checked ? 1 : 0);
                                                }} />
                                            <label className="custom-control-label" htmlFor="impoundLCheck">IMPOUND LOCATION</label>
                                        </div>
                                    </div>
                                   {!isManagement()?null:<div className="form-group row">
                                        <div className="custom-control custom-checkbox ml-3 mb-5">
                                            <input type="checkbox" name="paymentLocation" disabled={(this.props.isEdit)} checked={(locationRecord.paymentLocation || 0) === 1} className="custom-control-input" id="paymentLCheck"
                                                onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.checked ? 1 : 0);
                                                }} />
                                            <label className="custom-control-label" htmlFor="paymentLCheck">PAYMENT LOCATION</label>
                                        </div>
                                    </div>}
                                    {/* <div className="form-group row">
                                        <div className="custom-control custom-checkbox ml-3 mb-5">
                                            <input type="checkbox" name="reviewLocation" disabled={this.props.isEdit} checked={(locationRecord.reviewLocation || 0) === 1} className="custom-control-input" id="reviewLCheck"
                                                onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.checked ? 1 : 0);
                                                }} />
                                            <label className="custom-control-label" htmlFor="reviewLCheck">REVIEW LOCATION</label>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="">
                                        <label className="col-form-label">ADDRESS</label>
                                        <div className="">
                                            {/* <Input className="form-control" name="addrLine1" value={locationRecord.addrLine1} type="text" placeholder=""
                                                validations={this.props.isEdit?[required]:[]} disabled={this.props.isEdit} onChange={(e)=>{
                                                    this.props.onLocationFieldChange(e.target.name,e.target.value);
                                                }} /> */}
                                                <TmGoogleAutocomplete className="form-control" showConformModel={false}
                                            value={locationRecord.addrLine1} 
                                            disabled={this.props.isEdit} 
                                            name="addrLine1" placeholder="Search address.." 
                                               
                                                handleSelectAddress={(address) => {
                                                    this.setState({ addressArray: [address], showAddressModal: true })
                                                    
                                                    
                                                }} validations={this.props.isEdit ? [] : [required]} requiredmsg="Please enter Address" /> 
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="col-form-label">CITY</label>
                                        <div className="">
                                            <Input className="form-control" name="cityLocation" value={locationRecord.cityLocation} type="text" placeholder=""
                                                requiredmsg="Please enter City"
                                                validations={this.props.isEdit ? [] : [required]} disabled={this.props.isEdit} onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="col-form-label">STATE</label>
                                        <div className="">
                                            <Select id='state' className="form-control" name="state" value={locationRecord.state} validations={this.props.isEdit ? [] : [required]} disabled={this.props.isEdit} onChange={(e) => {
                                                this.props.onLocationFieldChange(e.target.name, e.target.value);
                                            }}>
                                                <option value=''>{''}</option>
                                                {
                                                    this.props.stateList.map((item, key) => {
                                                        return <option key={key} value={item.optionVal}>{item.optionTxt}</option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">ZIP</label>
                                        <div className="">
                                            <Input className="form-control" name="zip" value={locationRecord.zip} type="text" placeholder=""
                                                requiredmsg="Please enter Zip"
                                                validations={this.props.isEdit ? [] : [required]} disabled={this.props.isEdit} onChange={(e) => {
                                                    this.props.onLocationFieldChange(e.target.name, e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 mt-10 mb-10">
                                    <h6 className="text-blue text-bg-color">CITIES REVIEWED BY LOCATION</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <CitiesReviewedByLocation
                                        citiesReviewedByLocationListData={this.bindCityList(locationRecord.cityList)}
                                        onCityNameAdded={(e, value) => {
                                            let _cityListString = locationRecord.cityList || ''
                                            if (_cityListString.length > 0)
                                                _cityListString = _cityListString + ',' + value
                                            else {
                                                _cityListString = value;
                                            }
                                            this.props.onLocationFieldChange('cityList', _cityListString);
                                        }}
                                        onCityNameDelete={(e, value) => {
                                            let _cityListString = locationRecord.cityList || ''
                                            if (_cityListString.trim().length > 0) {
                                                let _cityList = _cityListString.split(',')
                                                let _index = _cityList.indexOf(value);
                                                if (_index > -1) {
                                                    _cityList.splice(_index, 1)
                                                    _cityListString = _cityList.join(',');
                                                }
                                            } else {
                                                _cityListString = '';
                                            }
                                            this.props.onLocationFieldChange('cityList', _cityListString);

                                        }}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 mb-5 text-right">
                                <button type="button" disabled={this.checkAccess()} className="btn btn-success btn-sm mr-10" onClick={(e) => {
                                    this.form.validateAll();
                                    if (!isValidForm(this.form)) {
                                    } else {
                                        this.props.onLocationSubmit(e);
                                    }
                                }} >{this.props.isEdit ? 'Save changes' : 'Save'}</button>
                                <button type="button" className="btn btn-secondary btn-sm" onClick={(e) => {
                                    this.props.onCancelClick(e);
                                }}> Cancel</button>
                            </div>
                        </div>

                    </Form>
                </div>
                <Modal show={this.state.showAddressModal} >
                    <div className="modal-header">
                        <Modal.Title>{'Add Tow Type'}</Modal.Title>
                    </div>
                    <div className="modal-body">
                        <div className="col-md-12 col-sm-12">
                            <ReactTable
                                data={this.state.addressArray}
                                pageSize={this.state.addressArray.length}
                                showPagination={false}
                                columns={
                                    [
                                        {
                                            Header: 'Select',
                                            width: 50,
                                            Cell: (props) => <input type='radio' data-index={props.index} checked={props.original.selected} name='addressRadio' onChange={(e, props) => {
                                                let _addressArray = this.state.addressArray;
                                                _addressArray[e.currentTarget.getAttribute('data-index')].selected = e.currentTarget.checked
                                                this.setState({ addressArray: _addressArray })
                                            }} />
                                        },
                                        {
                                            Header: 'ADDRESS',
                                            width: 200,
                                            accessor: 'addrLine',

                                        },
                                        {
                                            Header: 'CITY',
                                            width: 100,
                                            accessor: 'city'
                                        },
                                        {
                                            Header: 'STATE',
                                            width: 100,
                                            accessor: 'state'
                                        },
                                        {
                                            Header: 'ZIP',
                                            width: 100,
                                            accessor: 'zip'
                                        }
                                    ]
                                } />

                        </div>
                        <div className="modal-footer">
                            <button variant="secondary" className="btn btn-danger btn-sm" onClick={(e) => {
                                this.setState({ showAddressModal: false }
                                    , this.props.onLocationFieldChange('addrLine1', this.state.addressArray[0].fullAddress)
                                )
                            }}>Cancel</button>
                            <button type="button" variant="primary" className="btn btn-success btn-sm" onClick={
                                (e) => {
                                    const selectedAddress = this.state.addressArray.filter(add => {
                                        return add.selected
                                    })
                                    if (selectedAddress.length > 0) {
                                        this.setState({ showAddressModal: false }
                                            , () => {
                                                let _latLng=selectedAddress[0].lat && selectedAddress[0].lng?(selectedAddress[0].lat +','+ selectedAddress[0].lng):''
                                                this.props.onLocationFieldChange('addrLine1', selectedAddress[0].addrLine);
                                                this.props.onLocationFieldChange('cityLocation', selectedAddress[0].city);
                                                this.props.onLocationFieldChange('state', selectedAddress[0].state);
                                                this.props.onLocationFieldChange('zip', selectedAddress[0].zip);
                                                this.props.onLocationFieldChange('latLong',_latLng)
                                            }
                                        )
                                    } else {
                                        alert('Please check any one of the addresses...');
                                    }
                                }
                            } >Ok</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Fragment>);
    }

}
export default LocationInfo;