import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import { GlobalValues } from '../../util/constants';
import moment from 'moment';

export const defaultCharges=()=>{
    return [
        {
            text: 'Tow Charge',
            field: 'commPercentTowCharge',
            isSelected:false
        }, {
            text: 'Labor Charge',
            field: 'commPercentLabourCharge',
            isSelected:false
        },
        {
            text: 'Mileage Charge',
            field: 'commPercentMileageCharge',
            isSelected:false
        },
        {
            text: 'Storage Charge',
            field: 'commPercentStorageCharge',
            isSelected:false
        },
        {
            text: 'Miscellaneous Charge',
            field: 'commPercentMiscCharges',
            isSelected:false
        },
        {
            text: 'Advanced Payout',
            field: 'commPercentAdvPay',
            isSelected:false
        },
        {
            text: 'Gate Fee',
            field: 'commPercentGate',
            isSelected:false
        },
        {
            text: 'Tax',
            field: 'commPercentTax',
            isSelected:false
        },
        {
            text: 'Discount',
            field: 'commPercentDiscount',
            isSelected:false
        }
    ]
}

export const getCommissionTypes=()=>{
    const _url=`commission/get/commissionTypes?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const response= await getAxiosInstance(moduleTypes.ADMIN).get(_url)        
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_COMMISSION_TYPES,
                    commissionTypes:response.data
                });
            }else{    
                dispatch({
                    type: actionTypes.GET_COMMISSION_TYPES,
                    commissionTypes:undefined
                });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_COMMISSION_TYPES,
                commissionTypes:undefined
            })
        }
    }
}
export const getDriverLevels=()=>{
    const _url=`commission/get/driverLevels?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)         
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_DRIVER_LEVELS,
                    driverLevels:response.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_DRIVER_LEVELS,
                    driverLevels:undefined
                });
                }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_DRIVER_LEVELS,
                driverLevels:undefined
            });
          }
    }
}
export const getCommissionDetails=()=>{
    const _url=`commission/get/allCommissionDetails?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
       // dispatch(spinnerActions.showSpinner());
       try{
           const response= await getAxiosInstance(moduleTypes.ADMIN).get(_url)
           if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_COMMISSION_DETAIL_LIST,
                    commissionDetailsList:response.data
                });
            }else{  
                dispatch({
                    type: actionTypes.GET_COMMISSION_DETAIL_LIST,
                    commissionDetailsList:undefined
                });
            }
        }
        catch(error){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_COMMISSION_DETAIL_LIST,
                commissionDetailsList:undefined
            });
       }    
    }
}

export const onCommissionTypeNameFieldChange=(value)=>{
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_COMMISSION_TYPE,
            newCommissionType:value
        }); 
    } 
}
export const onDriverLevelFieldChange=(value)=>{
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_DRIVER_LEVEL,
            newDriverLevel:value
        }); 
    } 
}
export const onCommissionDetailsFieldChange=(key,value)=>{
    let _commissionObj=Object.assign({},(store.getState()).commission.commissionDetailObj) ;
    _commissionObj[key]=value;
    return (dispatch)=>{
        dispatch({
            type: actionTypes.SET_COMMISSION_DETAIL_FIELD,
            commissionDetailObj:JSON.parse(JSON.stringify(_commissionObj)) 
        })
    }
}
export const onCommissionDetailObjectChanged=(commissionObject)=>{
    let _commissionObj=Object.assign({},(store.getState()).commission.commissionDetailObj) ;
    _commissionObj=commissionObject;
    return (dispatch)=>{
        dispatch({
            type: actionTypes.SET_COMMISSION_DETAIL_FIELD,
            commissionDetailObj:JSON.parse(JSON.stringify(_commissionObj)) 
        })
    }
}
export const onCommissionChargesChange=(key,list)=>{
    return (dispatch)=>{
        let _commissionObj=Object.assign({},((store.getState()).commission.commissionDetailObj)||{}) ;
        _commissionObj[key]=list
        dispatch({
            type: actionTypes.SET_COMMISSION_DETAIL_FIELD,
            commissionDetailObj:_commissionObj
        });
    }
}
export const getDefaultCharges=()=>{
    return(dispatch)=>{
        dispatch({
            type: actionTypes.GET_DEFAULT_CHARGES,
            defaultCharges:defaultCharges()
        })
    }
}
export const onCommissionTypeNameSubmit=(commissionTypeName)=>{
    return async (dispatch) => {
        try{
            let _url=`commission/add/commissionType?companyId=${getCompanyId()}&access_token=${getAccessToken()}`; 
            const typeObj={value:commissionTypeName}      
            //dispatch(spinnerActions.showSpinner());
            const response= await getAxiosInstance(moduleTypes.ADMIN).put(_url,typeObj) 
            if(response.status===200) { 
                return {statusCode:response.status,statusMsg:response.data.message};
            } else{
                return {statusCode:null,statusMsg:response.data.message};
            }
        }catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        }
    }
}
export const onDriverLevelSubmit=(driverLevels)=>{
    return async (dispatch) => {
        try{
            let _url=`commission/add/driverLevel?companyId=${getCompanyId()}&access_token=${getAccessToken()}`; 
            const typeObj={value:driverLevels}      
            //dispatch(spinnerActions.showSpinner());
            const response= getAxiosInstance(moduleTypes.ADMIN).put(_url,typeObj) 
            if(response.status===200) { 
                return {statusCode:response.status, statusMsg:response.data.message};
            } else{
                return {statusCode:null, statusMsg:response.data.message}
            }
            //dispatch(spinnerActions.hideSpinner());
        }catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null, statusMsg:error.message}
        }
    }
}

// export const getUserDefaultCharges=()=>{
//     const _url=`newservicecall/select/userDefinedCharges?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
//     return async(dispatch) => {
//         //dispatch(spinnerActions.showSpinner());
//         try{
//             const response=await getAxiosInstance(moduleTypes.DISPATCH).get(_url)
//             if(response.status===200) {         
//                 dispatch({
//                     type: actionTypes.GET_USER_DEFAULT_CHARGES,
//                     userDefaultCharges:response.data
//                 });
//             } else{                
//                 dispatch({
//                     type: actionTypes.GET_USER_DEFAULT_CHARGES,
//                     userDefaultCharges:undefined
//                 });
//             }
//             //dispatch(spinnerActions.hideSpinner());
//         }
//         catch(error){
//             //dispatch(spinnerActions.hideSpinner());
//             dispatch({
//                 type: actionTypes.GET_USER_DEFAULT_CHARGES,
//                 userDefaultCharges:undefined
//             });
//           }
//     }
// }
export const getCommissionDetailRecord=(commissionTypeId,driverLevelId)=>{
    const _url=`commission/get/commissionNameAndDetails?commissionTypeId=${commissionTypeId}&driverLevelId=${driverLevelId}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200) {
                let respObj= response.data.commissionDetails;
                respObj.commissionNames=response.data.commissionNames;
                respObj.defaultCharges=defaultCharges().map((item) => {
                    return { 
                        text: item.text,
                        field: item.field,
                        isSelected: respObj[item.field] !== null ? true: false 
                    }
                });
                let _userCharges=(((store.getState()).ratePlanList.chargeList)||[]).slice() ;
                let selectedCustomCharges= (respObj.commPercentCustomCharge === null ? '' : 
                respObj.commPercentCustomCharge).substr(0,(respObj.commPercentCustomCharge === null ? '' : respObj.commPercentCustomCharge).length-1).split('|');
                let _customCharges=_userCharges.map((item)=>{
                    let select_item=selectedCustomCharges.findIndex((sItem)=>{
                            return (sItem.split(',')[0])===item.chargeName
                    });
                    return {
                        text:item.chargeName,
                        field:item.chargeName,
                        isSelected: select_item===-1?false:true
                    }
                })
                respObj.userDefaultCharges=_customCharges
                dispatch({
                    type: actionTypes.GET_COMMISSION_DETAIL_REC,
                    commissionDetailObj:respObj
                });
            }else{
                dispatch({
                    type: actionTypes.GET_COMMISSION_DETAIL_REC,
                    commissionDetailObj:undefined
                });
            }
        }
        catch(error){
                //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_COMMISSION_DETAIL_REC,
                commissionDetailObj:undefined
            });
        }
    }
}


export const onCommissionSave=(commissionObj)=>{
    return async(dispatch)=>{
        let _url=`commission/add/commissionDetails?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        let _commissionObj=commissionObj;
        _commissionObj.defaultCharges.map((defCharge)=>{
            if(defCharge.isSelected){
                _commissionObj[defCharge.field]=_commissionObj.commPercentAllCharges===''?0.00:_commissionObj.commPercentAllCharges
            }else{
                _commissionObj[defCharge.field]=undefined;
            }            
        });
        _commissionObj.commPercentCustomCharge="";
        _commissionObj.userDefaultCharges.map((usrCharge)=>{
            if(usrCharge.isSelected){
                _commissionObj.commPercentCustomCharge +=usrCharge.field+','+(_commissionObj.commPercentAllCharges===''?0.00:_commissionObj.commPercentAllCharges)+'|'
            }           
        });
        try{
            //dispatch(spinnerActions.showSpinner());
            const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,_commissionObj);
            if(response.status===200) {
                return {statusCode:response.status, statusMsg:response.data.message};
            }else{
                return {statusCode:null, statusMsg:response.data.message};
            }
        }catch(error){
            return {statusCode:null, statusMsg:error.message};
        }    }
}
