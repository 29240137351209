import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { getBillingEmail, saveBillingEmail, changeEmailField } from '../../actions/adminAction/billingReportEmailActions';
import BillingReportEmail from '../../components/adminComponents/billingReportEmail/billingReportEmail';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle="Billing Report Email";
class BillingReportEmailPage extends Component {
	constructor(props){
		super(props);
		this.state = {
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.getReportBillingEmail = this.getReportBillingEmail.bind(this);
		this.onFieldDataChange = this.onFieldDataChange.bind(this);
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.getReportBillingEmail();		
	}
	getReportBillingEmail=()=>{
		const {dispatch}=this.props;
		dispatch(getBillingEmail());
	}
	handleSubmit=async (e,mailid)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		const res=await dispatch(saveBillingEmail(mailid))
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
	onFieldDataChange=(e,recObj)=>{
		const {dispatch}=this.props;
		dispatch(changeEmailField(recObj.emailAddr));
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Statement Email');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4>Billing Report Outgoing Email</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-30 bg-white border-radius-4 box-shadow container-box">
				{accessLevelObj.basicAccess.view?
					<BillingReportEmail accessLevel={accessLevelObj||{}}  onFieldDataChange={(e,recObject)=>{
					this.onFieldDataChange(e,recObject);
				}} billingReportEmail={this.props.billingReportEmail} onSubmitForm={this.handleSubmit}/>:null}	
			</div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		billingReportEmail:state.billingReportEmail
  }
};
export default withRouter(connect(mapStateToProps)(BillingReportEmailPage));