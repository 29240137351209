import React from 'react';
import Select from 'react-select';
const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#blue',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
  
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };

  const styles={
    multiValueLabel: (base) => ({
      ...base,
      backgroundColor: '#2684ff',
      color: 'white',
    }),
  }
  const formatOptionLabel = ({ label }) => <div style={{ fontSize: '14px' }}>{(label||"").toUpperCase()}</div>;
const ReactSelect = (props) =>
(
    <Select  formatOptionLabel={formatOptionLabel} zIndex={9999} {...props} isDisabled={props.isDisabled} styles={(props.notAmultiSelect||false)?customStyles:styles} />
);
export default ReactSelect;