import * as actionTypes  from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';

export const getVendorList=()=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).get(`searchVendor?companyId=${getCompanyId()}&access_token=${getAccessToken()}`);
            if(resp.status===200){
                dispatch({ 
                    type: actionTypes.GET_ALL_VENDORS,
                    vendorList:resp.data})
            }else{
                dispatch({ type: actionTypes.GET_ALL_VENDORS,
                    vendorList:[]})
            }
        }catch(ex){
            dispatch({ type: actionTypes.GET_ALL_VENDORS,
                vendorList:undefined})
        }
    }
}
export const getVendor=(id)=>{
    return (dispatch) => {
        let obj = getVendorRecFromStore(id);
       dispatch({
        type: actionTypes.GET_VENDOR_RECORD,
        vendorObject:obj
    });
    }
}
export const clearVendor=()=>{
    return (dispatch) => {
       dispatch({
        type: actionTypes.GET_VENDOR_RECORD,
        vendorObject:undefined
    });
    }
}
export const changeVendorField=(key,value)=>{
    return (dispatch)=>{
        let _vendorObj= (store.getState()).vendorList.vendorsObject;
        _vendorObj[key]=value;
         dispatch({
            type: actionTypes.GET_VENDOR_RECORD,
            vendorObject:_vendorObj
        }); 
    }
}
export const saveVendor=(object,action)=>{
    return async(dispatch)=>{
        try{
            if(action==='NEW'){
                let _url=`svmsvendor/vendor?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
                const resp=await getAxiosInstance(moduleTypes.ADMIN).post(_url,object);
                if(resp.status===200){
                    dispatch(clearVendor());
                    return resp.data                    
                }else{
                    return {statusCode:null,statusMsg:resp.data.error}
                }
            }else{
                let _url=`svmsvendor/editVendorUser?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
                const resp=await getAxiosInstance(moduleTypes.ADMIN).put(_url,object);
                if(resp.status===200){
                    dispatch(clearVendor());
                    return resp.data
                }else{
                    return {statusCode:null,statusMsg:resp.data.error}
                }
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}
        }
    }
}
export const isValidUser=(id)=>{
    const _url= `svmsvendor/isValidUser?emaiId=${id}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(resp.status===200) {                   
                return resp.data.isValidStatus;
            }else {               
                return resp.data.isValidStatus;   
            }
        }
       catch(ex){
           return false
       }
    }
}
export const deleteVendor=(id,status)=>{
    const _url= `svmsvendor/activeInactiveVendor/${id}?isActive=${status}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(resp.status===200){
                let _vendorObj= getVendorRecFromStore(id);
                _vendorObj.isActive=status;
                dispatch({
                    type: actionTypes.GET_VENDOR_RECORD,
                    vendorObject:_vendorObj
                })
                return resp.data
            }else{
                return {statusCode:null,statusMsg:resp.data.message}
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}
        }  
    }
}
const getVendorRecFromStore=(vid)=>{
    let _vendorObj={}
    if( Object.keys((store.getState()).vendorList).length>0){
        _vendorObj= (store.getState()).vendorList.vendorsList.find(obj=>{
           return obj.vendorId===vid;
       });
       return _vendorObj;    
}}

