import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment'
import {isManagement } from '../../../util/customMethods'
class AccountList extends Component {
  constructor() {
    super();
    this.state = {
      ActiveInactiveFilterVal: [1],
      searchObject: { accountName: '', accountType: '', activeFilter: 1, limit: null }
    }
    this.handleActiveInactiveFilterChange = this.handleActiveInactiveFilterChange.bind(this)
  }

  handleActiveInactiveFilterChange = (value, e) => {
    let _searchObject = this.state.searchObject;
    _searchObject.activeFilter = parseInt(e.currentTarget.value);
    this.setState({ ActiveInactiveFilterVal: [parseInt(e.currentTarget.value)], searchObject: _searchObject },
      this.props.onSearchChange(_searchObject)
    );
  }

  buildDataFormatForExport = () => {
    return (
      [{
        columns: [{ title: 'ACCOUNT NAME' }, { title: 'ACCOUNT TYPE' }],
        data: this.props.data.map(item => {
          return [{ value: item.accountName || '' }, { value: item.accountType || '' }]
        })
      }]
    )
  }

  render() {
    const { data } = this.props;
    const columns = [{
      Header: 'Account Name',
      accessor: 'accountName', // String-based value accessors!
      filterable: true,
      width: 350,
      style: {height: '30px', display: 'flex', alignItems: 'center'},
      Cell: props => <Link to={'/admin/accountDetails?aid=' + props.original.accountId} >{props.value}</Link>

    }, {
      Header: 'Account Type',
      accessor: 'accountType',
      filterable: true,
      Cell: props => <a className="btn btn-link " href="javascript:void(0)">{props.value}</a>,
      width: 350,
      style: {height: '30px', display: 'flex', alignItems: 'center'},
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value=""></option>
          {
            this.props.accountTypes.map((atype, k) => {
              return <option key={k} value={atype.optionVal}>{atype.optionTxt}</option>
            })
          }
        </select>
    }];
    return (
      <Fragment>
       {isManagement()? <div className="row">
         
          <div className="col-md-12 col-sm-12 mb-10 ">
            <div className="pull-right">
             <ToggleButtonGroup
                bsPrefix="btn-group-toggle btn-group"
                type="checkbox"
                value={this.state.ActiveInactiveFilterVal}
                onChange={this.handleActiveInactiveFilterChange} defaultValue={-1}
              >
               
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}>ACTIVE</ToggleButton>
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>INACTIVE</ToggleButton>                
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>ALL</ToggleButton>
              </ToggleButtonGroup>
              <div className="btn-list " style={{ display: 'inline-block' }}>
                <TmExportExcel
                  name={"Account List"}
                  filename={`${"AccountsList_"}${moment().format('MMDDYYhhmm')}`} 
                  fileExtension={'xlsx'}
                  element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                  dataSet={this.buildDataFormatForExport()}
                />
                {
                  (this.props.accessLevel.basicAccess.add || false) === true ?
                    <button className="btn btn-success btn-sm" onClick={() => {
                      this.props.onClickNewAccount();
                    }} ><i className="fa fa-plus"></i> Add Account</button> : null
                  }
              </div>
            </div>
          </div>
        </div>:null}


        <div className="row">
          <div className="col-md-12 col-sm-12 mb-30">
            <TmReactTable
              filterable
              sortable
              resizable
              defaultPageSize={10}
              data={data}
              columns={columns}
            />
          </div></div>
      </Fragment>
    );
  }
}

export default AccountList;
