import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import {Modal} from 'react-bootstrap';
import Textarea from 'react-validation/build/textarea';
import { required, isValidForm} from '../../../../util/validationMethods';
import { isManagement} from '../../../../util/customMethods';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import TMReactTable from '../../../utilComponents/tmReactTable/tmReactTable.jsx'
import moment from 'moment'

const defaultNodeObj={
    "accountId": 0,
    "companyId": 0,
    "corporateId": 0,
    "notesData": [],
    "notesDate": "",
    "notesId": 0,
    "notesTime": "",
    "userId": ""
}

class AccountNotes extends Component {
    constructor(props){
        super(props);
        this.state={
          modalIsOpen:false,
          validated: false,
          nodeObj: Object.assign({},defaultNodeObj),
          index:-1   
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
    }
    buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'USER'},{title:'DATE'},{title:'TIME'},{title:'NOTES'}],
          data: this.props.notesData.map((item)=>{
            return [{value:item.userId||''},{value:item.notesDate||''},{value:item.notesTime||''},{value:item.notesData||''}]
          })
        }])
    }
    handleSubmit=(event)=>{
        this.form.validateAll();
        if (!isValidForm(this.form)) {

        } else {
          let _nodeObj=Object.assign({},this.state.nodeObj) ;
          let _idx=this.state.index;
          this.setState({ validated: false, nodeObj:Object.assign({},defaultNodeObj),modalIsOpen:false,index:-1},
          this.props.onNotesSubmit(event,_nodeObj,_idx));
        }   
    }

    render(){
        
        const excelDataSet=this.buildDataFormatForExport();
        const notesCols=[{
            Header: 'USER',
            accessor: 'userId', // String-based value accessors!
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width:100
            
          }, {
            Header: 'DATE',
            accessor: 'notesDate',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: 100
          }, {
            Header: 'TIME',
            accessor: 'notesTime',
            filterable:true,
            Cell: props => <span >{props.value}</span>,
            width: 100
          },
          {
            Header: 'NOTES',
            accessor: 'notesData',
            style: {overflow: 'wrap', whiteSpace: 'unset'},
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            minWidth: 300
          },
          {
            Header: 'Edit',
            filterable:false,
            Cell: (props) => <button type="button" data-idx={props.index} className="btn btn-success btn-sm" 
              onClick={(e)=>{
                let idx = e.target.getAttribute('data-idx');
                let selectedNote=Object.assign({},this.props.notesData[idx]);
               this.setState({nodeObj:selectedNote,index:idx},()=>{this.setState({modalIsOpen:true})})
              }}><i className="fa fa-edit" data-idx={props.index}></i> Edit</button>,
            width: 100,
            show:!this.props.disabled
          },{
            Header: 'Delete',
            filterable:false,
            Cell: (props) => <button type="button" data-idx={props.index} className="btn btn-danger btn-sm" 
              onClick={(e)=>{
                let idx=e.target.getAttribute('data-idx');
                this.props.onNotesDelete(idx)
              }}><i className="fa fa-trash" data-idx={props.index}></i> Delete</button>,
            width: 100,
            show:!this.props.disabled
          }];
          
        return(<Fragment>
        <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
			    <div className="pull-left">                
				    <h6 className="text-blue">Notes</h6>                   
			    </div>                
               {isManagement() ?<div className="pull-right">
                    <div className="btn-list">
                    <TmExportExcel name={'Notes'} filename={`${"Notes_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                        element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                        dataSet={excelDataSet} />

                        <button className="btn btn-success btn-sm " onClick={(e)=>{
                        this.setState({ modalIsOpen: true,index:-1,nodeObj:Object.assign({},defaultNodeObj) });
                        }} disabled={this.props.disabled} ><i className="fa fa fa-plus"></i> Add Notes</button>
                    </div>                    
                </div>:null}
            </div>
        </div>
        <div className="row">
        <div className="col-md-12 col-sm-12 mb-30">
            <TMReactTable 
            filterable 
            sortable
            resizable
            defaultPageSize={3}
            data={this.props.notesData||[]}
            columns={notesCols}
        /> 
        </div>
        </div>
        <Modal show={this.state.modalIsOpen} > 
				<div className="modal-header">
            <Modal.Title>{this.state.nodeObj.notesId===0?'Add Note':'Edit Note'}</Modal.Title>
        </div>
        <Form ref={(frm) => this.form = frm}>
          <div className="modal-body">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <label>Note *</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Textarea className="form-control" rows="100"               
              style={{minHeight:'100px'}} 
              onChange={(e)=>{
                  let _nodeObj=this.state.nodeObj
                  _nodeObj.notesData=e.currentTarget.value
                  this.setState({nodeObj:_nodeObj});
                }} value={this.state.nodeObj.notesData}
                requiredmsg="Please enter notes."
                validations={[required]}
                ></Textarea>
            </div>
          </div>

          </div>
          <div className="modal-footer">         
            <button type="button" className="btn btn-danger btn-sm" onClick={(e)=>{
              this.setState({ modalIsOpen: false,index:-1,nodeObj:Object.assign({},defaultNodeObj) });
            }}>Cancel</button>
            <button type="button" className="btn btn-success btn-sm" onClick={e => this.handleSubmit(e)}>
            {this.state.nodeObj.notesId===0?'Add Note':'Save Change'}</button>
            </div>
        </Form>
        </Modal>
        </Fragment>);
    }

}
export default AccountNotes