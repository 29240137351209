import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { notify } from '../../util/notifications';
import SearchInvoice from '../../components/dispatchComponents/invoiceSearch/searchInvoice';
import SearchCriteria from '../../components/dispatchComponents/invoiceSearch/search/searchCriteria';
import {getSearchInvoiceList ,clearSearchData,getChildList} from '../../actions/dispatchAction/invoiceSearchAction';
import {getAccessLevelsByModuleName , isManagement} from '../../util/customMethods';
const pageTitle = 'Invoice Details';
class InvoiceSearch extends Component {
	state = {
			fromDate: '',
			toDate: '',
			customInvoiceValues: [
				{ optionTxt: 'InvoiceNumber', optionVal: ''}
			  ]
		}
	componentWillMount = () => {
		const {dispatch}=this.props;
		document.title = pageTitle;
		if(isManagement()){
			dispatch(getChildList());
		}
		dispatch(clearSearchData());
	}
	/**to clear search data when component unmounted */
	componentWillUnmount=()=>{
		const {dispatch}=this.props;
		document.title = pageTitle;
		dispatch(clearSearchData());
	}

	onCallActionBtnClick=async(req,companyId)=>{
		const {dispatch}=this.props;
		const {fromDate, toDate} = this.state;
		let jsonObject = {
			fromDate: (fromDate||'') =='' ?'':moment(fromDate).format("YYYY-MM-DD"),
			toDate: (toDate||'') =='' ?'':moment(toDate).format("YYYY-MM-DD")
		  };
		  req.map((el, i) =>{
			jsonObject[el.optionTxt]=el.optionVal;
		  })
		  if((companyId||0)!=0)
		  jsonObject['companyId']=companyId;
		let a = JSON.stringify(jsonObject);
		const res = await dispatch(getSearchInvoiceList(a));
		if(res.statusCode==200){
			if(res.response.length === 1){
				window.open('/dispatch/serviceCallInfo/' + res.response[0].invoiceId, '_blanK');
			}
		}
		else
		notify.createNotification('error', res.statusMsg, this.props.refNotify);
		
	}
	onFieldValueChange = (key, value) => {
		this.setState({[key]:value})
	}
	onInvoiceTypeFieldChange = (e,index,type) => {
		let newStateArray = [...this.state.customInvoiceValues];
		if(type === 'T'){
			newStateArray[index].optionTxt=e.target.value;
		}
		if(type === 'V'){
			newStateArray[index].optionVal=e.target.value;
		}
		this.setState(() => {
		return {
			customInvoiceValues: newStateArray
		};
		});
	}
	addSearchCriteria = () => {
    	this.setState(prevState => ({ customInvoiceValues: [...prevState.customInvoiceValues, { optionTxt: '', optionVal: '' }] }));
	}
	removeSearchCriteria = (index) => {
		let newStateArray = [...this.state.customInvoiceValues];
		if (newStateArray.length > 1) {
		newStateArray.splice(index,1);
		}
		this.setState(() => {
		return {
			customInvoiceValues: newStateArray
		};
		});
	}
	onenterKeyPress=(e,req,companyId)=>{
		if (e.which === 13) {
			this.onCallActionBtnClick(req,companyId)
		}
	}
	render() {
		const {fromDate, toDate, customInvoiceValues} = this.state;
		let dashboard = getAccessLevelsByModuleName('Search Existing Call')[0]||{};
		return (
			<Fragment>
				{(dashboard.basicAccess.view)?<div>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Invoice Search Screen</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-10">
					<div className="col-md-12 col-sm-12">
						<SearchCriteria childCompList={this.props.childCompList} onDateFieldsChange={(key, value)=>{
                  this.onFieldValueChange(key, value)
				}}
				onenterKeyPress={(e,req,companyId)=>this.onenterKeyPress(e,req,companyId)}
				onInvoiceTyoeChange={(value,index,type)=>{
					this.onInvoiceTypeFieldChange(value,index,type)
				  }} 
				  addSearchCriteria={(e)=>{
					this.addSearchCriteria(e)
				  }}
				  removeSearchCriteria={(index)=>{
					this.removeSearchCriteria(index)
				  }} 
				onCallActionBtnClick={(req,companyId)=>{this.onCallActionBtnClick(req,companyId)}} 
				fromDate={fromDate} toDate={toDate} customVal={customInvoiceValues}/>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<SearchInvoice data={this.props.searchInvoiceListData}/>
				</div>
				</div>:<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<div class="text-center text-</h3>danger"><h3 class="text-danger">Not Authorised User</h3></div></div>}
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
		authInfo: state.authInfo,
		searchInvoiceListData: state.searchInvoiceList.searchInvoiceList,
		childCompList:state.searchInvoiceList.childComList
	}
};

export default withRouter(connect(mapStateToProps)(InvoiceSearch));
