import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { connect } from 'react-redux';
import CustomChargeList from '../../components/adminComponents/customCharges/customChargeList';
import CustomCharge from '../../components/adminComponents/customCharges/customCharge';
import { getChargeList, changeGlobalCharges, saveChargeList,
	 onCustomChargeNameFieldChange, onCustomChargeNameSubmit, clearCustomChargeName} from '../../actions/adminAction/ratePlanActions';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods.js';
const pageTitle ="Custom Charges";
class CustomChargesPage extends Component {
	constructor(){
		super();
		this.state={
			modalShow:false,
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.bindChargeList=this.bindChargeList.bind(this);
		this.onEditCharge=this.onEditCharge.bind(this);
		this.onSubmitSaveChargeList=this.onSubmitSaveChargeList.bind(this);
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.bindChargeList();		
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindChargeList=()=>{
		const {dispatch}=this.props;
		dispatch(getChargeList());
	}
	onEditCharge=(e,chargeId)=>{
		const {dispatch}=this.props;
		dispatch(changeGlobalCharges(chargeId));
		this.setState({modalShow:true});	
	}
	onSubmitSaveChargeList=async (recObj)=>{
		const {dispatch} =this.props;
		const res=await dispatch(saveChargeList(recObj))
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
	onFieldDataChange=(key,value)=>{
		const {dispatch}=this.props;
		dispatch(onCustomChargeNameFieldChange(key,value))
	}
	onCancelForm=()=>{
		this.setState({
			modalShow:false,
			})
			const {dispatch}=this.props;
		dispatch(clearCustomChargeName());
	}
	onSubmitForm=async (e)=>{
		e.preventDefault();
		const {dispatch}=this.props;
		const res=await dispatch(onCustomChargeNameSubmit(this.props.newCustomCharge));
		if(res.statusCode===200){
				this.setState({
					modalShow:false
					});
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
			}else{
				this.setState({
					modalShow:false
					})
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
	}
	
  render() {
		const accessLevelObj=getAccessLevelsByModule('Custom Charges');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
			<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-globe"></i> {pageTitle}</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
			<CustomChargeList globalChargeListData={this.props.chargeList}
					accessLevel={accessLevelObj||{}}
					onEditCharge={this.onEditCharge}					
					showModalForAdd={(e)=>{
						this.setState({
							modalShow:true,									
						});
					}} gotoRatePlanPage={this.gotoRatePlanPage}
				gotoDefaultRatePlanDetailsPage={this.gotoDefaultRatePlanDetailsPage} />				
			</div>
			<Modal show={this.state.modalShow} > 
				<div className="modal-header">
            		<Modal.Title> {this.props.newCustomCharge.chargeListId==0?'Add Custom Charge':'Edit Custom Charge'}</Modal.Title>
        		</div>
				<CustomCharge
				chargeObj={this.props.newCustomCharge} 
					onFieldDataChange={(key,value)=>{
						this.onFieldDataChange(key,value);
						}} 
					onCancelForm={(e)=>{
							this.onCancelForm(e)
						}} 
					onSubmitForm={(e)=>{
							this.onSubmitForm(e)
						}} 
				/>
        	</Modal>			
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		chargeList:state.ratePlanList.chargeList,
		newCustomCharge:state.ratePlanList.newCustomCharge,
  }
};

export default withRouter(connect(mapStateToProps)(CustomChargesPage));