import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
import Input from 'react-validation/build/input';
class crStatementCtrl extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card form-group">
                            <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formStatementsInfo = c;
                                    this.props.initCallBack(c);
                                }}>
                                    <div className="form-row">
                                        <div className="col-5 form-group">
                                            <div className="form-group row">
                                                <div className="col-sm-4">
                                                    <label>
                                                        <Toggle
                                                            defaultChecked={this.props.isBillable}
                                                            icons={false}
                                                            onChange={(e) => { 
                                                                this.props.onStatementsFieldChange('isBillable', !this.props.isBillable)
                                                                if (this.props.isBillable)
                                                                this.props.onStatementsFieldChange('idCompany', 0)
                                                                }} />
                                                        <span className='label-text'>All Billable</span>
                                                    </label>
                                                </div>
                                                <div className="col">
                                                    <ReactSelect name="companyId"
                                                        closeMenuOnSelect={true}
                                                        isDisabled={!this.props.isBillable}
                                                        options={this.props.childCompList.map((item, t) => {
                                                            return ({ value: item.optionVal, label: item.optionText })
                                                        })}
                                                        value={{
                                                            value: ((this.props.idCompany || '').toString()),
                                                            label: (this.props.childCompList.find((e) => e.optionVal == (this.props.idCompany || '').toString()) || {}).optionText
                                                        }}
                                                        onChange={(selectedOption) => {
                                                            this.props.onStatementsFieldChange('idCompany', parseInt(selectedOption.value));
                                                        }}
                                                    />
                                                   <Input hidden={true} value={(this.props.idCompany || '')} validations={this.props.isBillable?[required]:[]} name="idCompany" requiredmsg="Please select company name"></Input> </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="custom-control custom-checkbox mb-5 mr-2">
                                                <input className="custom-control-input"
                                                    id="inlineIncludeInvoice"
                                                    type="checkbox"
                                                    name="includeInvoice"
                                                    onChange={(e) => { this.props.onStatementsFieldChange('invoicesWithStatement', !this.props.invoicesWithStatement) }}
                                                    isChecked={this.props.invoicesWithStatement}
                                                />
                                                <label className="custom-control-label" htmlFor="inlineIncludeInvoice">Include Invoice With Statements</label>
                                            </div>
                                        </div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of Date </label>
                                        </div>
                                        <div className="col-2 form-group">
                                            <div>
                                                <TmDateTimePicker
                                                    className="form-control"
                                                    selectedDate={
                                                        this.props.asofdate === "" || this.props.asofdate === null
                                                            ? null
                                                            : moment(
                                                                this.props.asofdate,
                                                                GlobalValues.dateFormat
                                                            )._d
                                                    }
                                                    dateFormat={GlobalValues.dateFormat}
                                                    validationArray={[required]}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                    name="asOfDate"
                                                    reqmsg="As of date is required"
                                                    onDateSelectChange={e => {
                                                        this.props.onStatementsFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 form-group">
                                            <button type="button" className="btn btn-success mr-2" onClick={this.props.viewStatement} >View Statement(s)</button>
                                            <button type="button" className="btn btn-primary" onClick={this.props.sendCompanyStatement}>Send Bill</button>

                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default crStatementCtrl;