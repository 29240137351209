import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import DateTimeRangePicker from '../../datepicker/DateTimeRangePicker';
import { connect } from 'react-redux';
import {utilMethods}  from '../../../../../util/util.js';
import * as reportsDashboardActions from '../../../../../actions/launchAction/reportsDashboardActions';
let CurrentDate = moment().format('YYYY-MM-DD');
class Commissions extends Component{
    constructor(props){
        super(props);
        this.state={
            startDate: CurrentDate,
            endDate: CurrentDate,
            chosenLabel: 'Today'
        }
        //this.handleSelectChange = this.handleSelectChange.bind(this);
    }
    componentWillMount(){       
        let {dispatch} =this.props;
        dispatch(reportsDashboardActions.getCommissions(CurrentDate, CurrentDate))
    }
    totalCommission=(resp)=>{
        let totComm=0.00;
        (resp||[]).forEach(item=>{
            totComm=totComm+utilMethods.convertToFloat(item.commission) 
        });
        return utilMethods.convertToFloat(totComm)
    }
    render(){
        return(
            <>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12"> 
                    <div class="card card-stats" style={{minHeight: '185px'}}>
                        <div class="card-header" data-background-color="orange"><img width="40" src={require('../../../../../assets/img/commission.png')}/></div>
                        <div class="card-content">
                            <DateTimeRangePicker applyCallback={(event,picker)=>{
                                let {dispatch} =this.props;                              
                                dispatch(reportsDashboardActions.getCommissions(picker.startDate, picker.endDate));
                                if(picker.chosenLabel === 'Today'){
                                    this.setState({chosenLabel:'Today'});  
                                }else if(picker.chosenLabel === 'Yesterday'){
                                    this.setState({chosenLabel:'Yesterday'});
                                }else{
                                    this.setState({chosenLabel:''});
                                }
                                this.setState({startDate:moment(picker.startDate).format('YYYY-MM-DD'), endDate:moment(picker.endDate).format('YYYY-MM-DD')});
                            }} isDateShow={false} position={'left'} dropdown={'up'}/>
                            <p class="category" style={{minHeight: '35px', fontSize: '0.85em'}}>Commissions</p>
                            <p class="category" style={{fontSize: '11px', paddingTop: '11px', color: 'rgb(115, 135, 156)', fontWeight: 'bold'}}>{this.state.chosenLabel === 'Today' ? 'for '+this.state.startDate : this.state.chosenLabel === 'Yesterday' ? 'for '+this.state.startDate : 'from '+this.state.startDate+' to '+this.state.endDate}</p>
                            <span class="title" style={{fontSize: '25px'}}>{'$ '+utilMethods.convertToFloat(this.totalCommission(this.props.reportDashboard.commissions||[])) || 0.00}</span>
                        </div>
                        <div class="card-footer text-right">
                        <div class="stats">
                            <Link to={`/reportschart/commissionchart?from=${this.state.startDate}&to=${this.state.endDate}`} class="btn btn-sm btn-primary" style={{marginBottom: '0px', marginRight: '0px', padding: '2px 6px'}}>
                            <i class="fa fa-bar-chart"></i>
                            </Link>
                        </div>
                        </div>                       
                    </div> 
                    </div>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
    }
};

export default connect(mapStateToProps)(Commissions);