import * as actionTypes from '../actionTypes';
import {moduleTypes, GlobalValues} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId,isManagement } from '../../util/customMethods';
import moment from 'moment';
const truckFilterOptions={
    Available:{key:'Available',value:'available'},
    Assigned:{key:'Assigned',value:'Assigned,Accepted,Enroute,Arrived'},
    Loaded:{key:'Loaded',value:'Arrived,Hooked'},
    Doubled:{key:'Doubled',value:'Arrived,Hooked'}
}

export const assignInvoice=(invoiceId,driverId,truckId)=>{
    return async (dispatch)=>{
        const _url=`dashboard/${invoiceId}/assign?driverId=${driverId}&truckId=${truckId}&access_token=${getAccessToken()}&isManagement=${isManagement()}`
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).put(_url);
            if(response.status===200) {
                return {statusCode:response.status,statusMsg:response.data.statusMsg}
            } else{                
                return {statusCode:null,statusMsg:response.data.statusMsg}
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}

        }
    }
}

export const assignInvoiceToCompany=(invoiceId,companyId)=>{
    return async (dispatch)=>{
        const _url=`dashboard/${invoiceId}/assignToCompany/${companyId}?access_token=${getAccessToken()}&isManagement=${isManagement()}`
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).put(_url);
            if(response.status===200) {
                return {statusCode:response.status,statusMsg:response.data.statusMsg}
            } else{                
                return {statusCode:null,statusMsg:response.data.statusMsg}
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}

        }
    }
}
export const fitlerInvoiceList=(list,invoiceFilters)=>{
    let pickupStatus=[];
    pickupStatus=Object.keys(invoiceFilters.pickup).filter(item=>{
        return invoiceFilters.pickup[item].selected
    });
    let priorityStatus=[];
    priorityStatus=Object.keys(invoiceFilters.priority).filter(item =>{
        return invoiceFilters.priority[item].selected
    });
    if(pickupStatus.length<=0 && priorityStatus.length<=0)
        return list;
    else{
        let finalPickupArray=list;
        if(pickupStatus.length>0){
            finalPickupArray=list.filter(item=>{ 
                return pickupStatus.includes(item.callStatus);
            })
        }
        let finalPriorityArray=finalPickupArray;
        if(priorityStatus.length>0){
            finalPriorityArray=finalPickupArray.filter(item=>{ 
                return priorityStatus.includes(item.priority.toString());
            })
        }
        return finalPriorityArray;
    }
}

export const filterTruckList=(list,selectedFitlers)=>{
        let selectedStatus=[]
        selectedStatus=Object.keys(selectedFitlers).filter(item=>{
            return selectedFitlers[item].selected
        });
        if(selectedStatus.length===0){
            return list
        }else{
            let statusList=[];
            let finalList=[];
            if(selectedStatus.includes(truckFilterOptions.Available.key)){
                statusList.push(truckFilterOptions.Available.value);
            }
            if(selectedStatus.includes(truckFilterOptions.Assigned.key)){
                let assignList=truckFilterOptions.Assigned.value.split(',')
                if(selectedStatus.includes(truckFilterOptions.Loaded.key)||selectedStatus.includes(truckFilterOptions.Loaded.key)){
                    const idx=assignList.indexOf('Arrived');
                    if(idx!==-1){
                        assignList.splice(idx,1);
                    }
                }
                statusList=statusList.concat(assignList) ;
            }
            finalList=list.filter(item=>{
                if(isStatusExists(statusList,item.truckStatus.split(','))){
                    return true;
                }else if(isStatusExists(truckFilterOptions.Loaded.value.split(','),item.truckStatus.split(','))
                && item.invoiceCount===1){
                    return true;
                }else if(isStatusExists(truckFilterOptions.Doubled.value.split(','),item.truckStatus.split(','))
                && item.invoiceCount>1){
                    return true;
                }else{
                    return false;
                }
            });
            return finalList;
        }
    }

    const isStatusExists=(statusList,truckStatusList)=>{
        return truckStatusList.some(status=>{
            return statusList.includes(status);
        })
    }




/*
    1.All

2. available-available
3. assigned-assigned, accepted, enroute, arrived
4. loaded -  arrived, hooked (only one call)
5. doubled- arrived, hooked (more than one) and tow type flat bed


Accepted,Enroute,
*/
