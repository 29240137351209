import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';

export const getStateList=()=>{
    const _url=`account/select/states?access_token=${getAccessToken()}`;
    return async(dispatch)=>{
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200){
                dispatch({
                    type: actionTypes.GET_ALL_STATES,
                    stateList:response.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_ALL_STATES,
                    stateList:undefined
                });
            }

        }catch(ex){
            dispatch({
                type: actionTypes.GET_ALL_STATES,
                stateList:undefined
            });
        }
    }
}