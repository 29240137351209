import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
class AuctionListCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                    <Form ref={c => {
                                        this.formAuctionListInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                        <div className="col-2 form-group">
                                            <select className="form-control" >
                                                <option>Auction List</option>
                                            </select>
                                        </div>
                                        <div className="col-2 form-group">
                                            <select className="form-control" >
                                                <option>Auction Date</option>
                                            </select>
                                        </div>
                                        <div className="col-2 form-group">
                                            <select className="form-control" >
                                                <option>Location(All)</option>
                                            </select>
                                        </div>
                                        <div className="col-6 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            >Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" >Email</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" >Print</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default AuctionListCtrl;