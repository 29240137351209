import * as actionTypes from '../../../actions/actionTypes';
const defaultMonetizationDetails={
  monetizationObj:{}
}
export const monetizationDetailsReducer = (state = defaultMonetizationDetails, action) => {
    switch (action.type) {
      case actionTypes.GET_MONETIZAION_LIST:
        return {...state ,monetizationDetails:(action.monetizationObj||defaultMonetizationDetails.monetizationObj)};
      default:
        return state;
    }
  };