import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {utilMethods}  from '../../../util/util';
import { months } from '../../../util/constants';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
let batchData = [];
class AuctionBatchPaymentTable extends Component {
	constructor(props) {
		super();
		this.state = {
			
    }
    this.renderEditable = this.renderEditable.bind(this);
  }

      /** Function to add check box to react table */
      renderEditable = cellInfo => {
        const cellValue = batchData[cellInfo.index][cellInfo.column.id];
        return (
            <input
                placeholder="type here"
                name="paidInFull"
                type="checkbox"
                onChange={(e)=>{this.props.setBatchPayData(cellInfo.index,cellValue=="1"?"":"1" ,"paidInFull",this.props.type)}}
                checked={cellValue=='1'}
            />
        );
    };

/** Function to add input box to react table */
renderEditable1 = cellInfo => {
    const cellValue = batchData[cellInfo.index][cellInfo.column.id];
    return (
        <input
            className="form-control"
            placeholder="type here"
            name="input"
            type="text"
            onChange={(e)=>{this.props.setBatchPayData(cellInfo.index,e.target.value,"paymentAmount",this.props.type)}}
            onBlur={(e)=>{this.props.setBatchPayData(cellInfo.index,parseFloat(e.target.value==""?0:e.target.value).toFixed(2),"paymentAmount",this.props.type)}}
            value={cellValue}
        />
    );
};
  /** Function to add input box to react table */
	render() {
    let batchPayayData = [];
    let companyData = {};
    if (this.props.data[0] != undefined) {
      if(this.props.type === 'managed'){
        companyData = this.props.data[0];
        batchPayayData = this.props.data[0].companyReceivableInvoiceDetailList || [];
        batchData=batchPayayData;
      }
      else{
        companyData = this.props.data[0];
        batchPayayData = this.props.data[0].companyReceivableMonthlyDetailList || [];
        batchData=batchPayayData;
      }
    }
        const manageCols = [
          {
              accessor: 'displayInvoiceId',
              Header: 'Invoice #',
              style: { textAlign: 'center' },
              width: 200,
              filterable: true
          },
          {
            accessor: 'accountName',
            Header: 'Account Name',
            style: { textAlign: 'center' },
            width: 200,
            filterable: true
        },
        {
          accessor: 'billableType',
          Header: 'Billing Type',
          style: { textAlign: 'center' },
          width: 200,
          filterable: true
      },
          {
              accessor: 'receivable',
              Header: 'Balance',
              style: { textAlign: 'center' },
              width: 100,
              filterable: false,
              Cell: props => `$ ${props.original.receivable}`
           }
          ,
          {
              accessor: 'paymentAmount',
              Header: 'Payment',
              style: { textAlign: 'center' },
              width: 100,
              filterable: false,
              Cell: this.renderEditable1
          },{
            accessor: 'paidInFull',
            Header: 'Paid In Full',
            style: { textAlign: 'center' },
            width: 100,
            filterable: false,
            show: true,
            Cell: this.renderEditable
        }
      ];
      const retailCols = [
        {
            accessor: 'month',
            Header: 'Month #',
            style: { textAlign: 'center' },
            width: 200,
            filterable: true,
            Cell: props => `${months[props.original.month-1]}`
        },
        {
          accessor: 'noOfUsers',
          Header: 'Users',
          style: { textAlign: 'center' },
          width: 200,
          filterable: true
      },
      {
        accessor: 'year',
        Header: 'Year',
        style: { textAlign: 'center' },
        width: 200,
        filterable: true
    },
        {
            accessor: 'receivable',
            Header: 'Balance',
            style: { textAlign: 'center' },
            width: 100,
            filterable: false,
            Cell: props => `$ ${props.original.receivable}`
         }
        ,
        {
            accessor: 'paymentAmount',
            Header: 'Payment',
            style: { textAlign: 'center' },
            width: 100,
            filterable: false,
            Cell: this.renderEditable1
        }
        ,{
            accessor: 'paidInFull',
            Header: 'Paid In Full',
            style: { textAlign: 'center' },
            width: 100,
            filterable: false,
            show: true,
            Cell: this.renderEditable
        }
    ]
		return (
            <Fragment>
                <div className="row">
                <div className="col-md-12">
                    <div className="col-md-12 col-sm-12 mb-30">
                    <div className="card form-group">
                            <div className="card-body pd-12">
                                    <div className="form-inline">
                                        <div className='form-group mx-sm-4 '>
                                        <label>COMPANY NAME : </label>{(companyData.companyName||"").toUpperCase()}
                                        </div>
                                        <div className='form-group mx-sm-4'>
                                        <label>BILLABLE TYPE : </label>
                                            {(companyData.billableType||"").toUpperCase()}
                                        </div>
                                        <div className='form-group mx-sm-4'>
                                        <label>TOTAL AMOUNT: </label>
                                            {(companyData.totalReceivable||"").toUpperCase()}
                                        </div>
                                    </div>
                            </div>

                        </div>
               
                        <TmReactTable 
                         getTableProps={() => ({ style: { textTransform: 'uppercase', fontSize: '12px', fontWeight: 700 } })}
                         getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
                         resizable={true}
                         className='-striped -highlight'
                         noDataText='No data found'
                        filterable 
                        sortable
                        defaultPageSize={10}
                        data={batchPayayData}
                        columns={(this.props.type === 'managed') ? manageCols:retailCols}
                    /> 
                    </div>
                </div>
                </div>
            </Fragment>
		);
	}
}


export default AuctionBatchPaymentTable;