import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {getCommissionTypes,
	getDriverLevels,
	getCommissionDetails,
	onCommissionTypeNameFieldChange,
	onDriverLevelFieldChange, onCommissionTypeNameSubmit, onDriverLevelSubmit} from '../../actions/adminAction/commissionActions';
import CommissionList from '../../components/adminComponents/commission/commissionList';
import AddCommissionTypeName from '../../components/adminComponents/commission/commissionInfo/addCommissionTypeName';
import DriverLevel from '../../components/adminComponents/commission/commissionInfo/addDriverLevel';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods'
const modalTitle={commissionTitle:"Add Commission Type",driverLevel:'Add Driver Level'}
const pageTitle ="Commission"
class CommissionPage extends Component {
	constructor(props){
		super(props);
		this.state={
			modalShow:false,
			type:'C'
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.bindCommissionData=this.bindCommissionData.bind(this);
		this.bindCommissionTypes=this.bindCommissionTypes.bind(this);
		this.bindCommissionDetails=this.bindCommissionDetails.bind(this);
		this.bindDriverLevels=this.bindDriverLevels.bind(this);
		this.onCancelForm=this.onCancelForm.bind(this);
		this.onSubmitForm=this.onSubmitForm.bind(this);
		this.onFieldDataChange=this.onFieldDataChange.bind(this)
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.bindCommissionData();
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindCommissionData=()=>{
		const {dispatch} = this.props;
		dispatch(this.bindCommissionTypes()).then(()=>
		dispatch(this.bindDriverLevels()).then(()=>
		dispatch(this.bindCommissionDetails())))
		
		
		//dispatch(getCommissionTypes());
		//dispatch(getCommissionDetails());
		//dispatch(getDriverLevels());
			
	}
	bindCommissionTypes=()=>{
		return (dispatch)=> dispatch(getCommissionTypes());
	}
	bindCommissionDetails=()=>{
		return (dispatch)=> dispatch(getCommissionDetails());
	}
	bindDriverLevels=()=>{
		return (dispatch)=> dispatch(getDriverLevels());
	}
	onFieldDataChange=(e)=>{
		const {dispatch}=this.props;
		dispatch(
			this.state.type==='C'?
			onCommissionTypeNameFieldChange(e.target.value):
			onDriverLevelFieldChange(e.target.value));
	}
	onCancelForm=()=>{
		const {dispatch}=this.props;
		const _type=this.state.type;
		this.setState({
			modalShow:false,
			type:'C'
			},
			dispatch(
				_type==='C'?
				onCommissionTypeNameFieldChange(''):
				onDriverLevelFieldChange(''))
			);
	}
	onSubmitForm=async (e)=>{
		e.preventDefault();
		const {dispatch}=this.props;
		const _type=this.state.type;
		if(_type==='C'){
			const res=await dispatch(onCommissionTypeNameSubmit(this.props.commission.newCommissionType))
			if(res.statusCode===200){
				this.setState({
					modalShow:false,
					type:'C'
					});
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
				this.bindCommissionData();				
			}else{
				this.setState({
					modalShow:false,
					type:'C'
					})
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}else{
			const res=await dispatch(onDriverLevelSubmit(this.props.commission.newDriverLevel))
			if(res.statusCode===200){
				this.setState({
					modalShow:false,
					type:'C'
					});
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
				this.bindCommissionData();			
			}else{
				this.setState({
					modalShow:false,
					type:'C'
					})
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}
		
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Commission');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-money"></i> Commissions</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-30 bg-white border-radius-4 box-shadow container-box">
				<CommissionList accessLevel={accessLevelObj||{}} commissionData={this.props.commission} showModalForAdd={(e,type)=>{
					if(type==='C'){
						this.props.dispatch(onCommissionTypeNameFieldChange(''))
					}else{
						this.props.dispatch(onDriverLevelFieldChange(''))
					}
					this.setState({
						modalShow:true,
						type:type
						});
				}} onAddEditCommissionDetailsClick={(rec,type)=>
				{
					let commissionTypeId=0
					let driverLevelId=0
					if(rec.length>0){
						commissionTypeId=rec[0].commissionTypeId||0;
						driverLevelId=rec[0].driverLevelId||0;
					}
					const {history} =this.props;					
					history.push('/admin/commissionDetails?cTypeId='+commissionTypeId+'&dLId='+driverLevelId+'&Type='+type);
				}}/>
			</div>

			<Modal show={this.state.modalShow} > 
				<div className="modal-header">
            <Modal.Title>{this.state.type==='C'? modalTitle.commissionTitle:modalTitle.driverLevel}</Modal.Title>
        </div>
				{
					this.state.type==='C'?
					<AddCommissionTypeName value={this.props.commission.newCommissionType} 
				onFieldDataChange={(e)=>{
					this.onFieldDataChange(e);
				}} 
				onCancelForm={(e)=>{
					this.onCancelForm(e)
				}} onSubmitForm={(e)=>{
					this.onSubmitForm(e)
				}} />:
				<DriverLevel value={this.props.commission.newDriverLevel} onFieldDataChange={(e,recObject)=>{
					this.onFieldDataChange(e,recObject);
				}} onCancelForm={(e)=>{
					this.onCancelForm(e)
				}} onSubmitForm={(e)=>{
					this.onSubmitForm(e)
				}} /> 
				}
        </Modal>		
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
		refNotify:state.refNotify,
		commission:state.commission
	  }
};

export default withRouter(connect(mapStateToProps)(CommissionPage));