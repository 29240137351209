import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString  from 'query-string';
import DefaultRatePlanInfo from '../../components/adminComponents/defaultRatePlan/defaultRatePlanInfo/defaultRatePlanInfo';
import {getRatePlanDetails, changeRatePlanField, saveRatePlan, clearRatePlan} from '../../actions/adminAction/ratePlanActions';
import {getAllTowTypes} from '../../actions/adminAction/towTypeActions';
import {constStoragePeriod} from '../../util/constants'
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods.js';
let pageTitle='Add New Default Rate Plan';
let rateplan_id=-1;
class DefaultRateplanRecPage extends Component {
	constructor(props){
		super(props);
		this.state={
			rpid: 0,
			searchObject:{searchQuery:''}
		}
		this.checkViewAccess=this.checkViewAccess.bind(this)
		this.bindTowTypeList = this.bindTowTypeList.bind(this);
		this.onRatePlanInfoFieldChange = this.onRatePlanInfoFieldChange.bind(this);
		this.onRatePlanSelect = this.onRatePlanSelect.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
		this.handleClearAll = this.handleClearAll.bind(this);
	}
	componentWillMount=()=>{
		this.bindTowTypeList();	
		const queryValues=	queryString.parse(this.props.location.search);
		rateplan_id = parseInt(queryValues.rateplanid);
		if (rateplan_id !== 0 && rateplan_id !== -1) {
			this.setState({rpid: rateplan_id});
		}else{
			this.setState({rpid: 0});
		}
		this.initRatePlanForm(queryValues.rateplanid);
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindTowTypeList=()=>{
		const {dispatch}=this.props;
		dispatch(getAllTowTypes(this.state.searchObject));
	}
	initRatePlanForm=(id)=>{
		const {dispatch}=this.props;
		if(id === 'new'){
			dispatch(clearRatePlan(id));
		}else if(parseInt(id) === -1){
			dispatch(clearRatePlan());
		}else{
			dispatch(getRatePlanDetails(id));
		}	
	}
	onRatePlanInfoFieldChange=(key,value)=>{
		const {dispatch}=this.props;
		let _ratePlanObject=Object.assign({},this.props.ratePlanObject);
		_ratePlanObject[key]=value;
		if(key==='storagePeriod' && constStoragePeriod.custom!==_ratePlanObject.storagePeriod){
			_ratePlanObject['storageCustomPeriod']='';
			_ratePlanObject['freeHours']=0;
			_ratePlanObject['threshold']='';
		}
		dispatch(changeRatePlanField(_ratePlanObject));
	}
	
	onRatePlanSelect=(key, id)=>{
		this.onRatePlanInfoFieldChange(key, id);
		if(id !== ""){
			if(key === 'defaultRatePlanId'){
				this.initRatePlanForm(id);
			}		
		}else{
			this.handleClearAll();
		}
			
	}
	handleSubmit=async (e,recObj)=>{
		e.preventDefault();
		if(recObj.defaultRatePlanId === 'new'){
			recObj.defaultRatePlanId=0;
		}
		const {dispatch, history} =this.props;
		if(this.props.ratePlanObject.ratePlanId<=0)
			recObj.ratePlanId=null;
		const res=await dispatch(saveRatePlan(recObj))
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);
			history.push('/admin/rateplan');
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
	handleClearAll=()=>{
		const {dispatch}=this.props;
		dispatch(clearRatePlan());
	}
	componentWillUnmount=()=>{
		const {dispatch}=this.props;
		dispatch(clearRatePlan(''))
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Default Rate Plan');
		document.title= this.state.rpid===0?pageTitle:this.props.ratePlanObject.defaultRatePlanName;
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
			<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-list-alt"></i> {this.state.rpid !== 0 ? 'Edit Default Rate Plan' : 'Add Default Rate Plan'}</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
							 <DefaultRatePlanInfo 
							 accessLevel={accessLevelObj||{}} 
							 rateplan={this.props.ratePlanObject} 
							 ratePlanList={this.props.ratePlanList}
							  towTypeList={this.props.towTypeList} onRatePlanInfoFieldChange={(e, key,value)=>{
									this.onRatePlanInfoFieldChange(key,value);
								}} onRatePlanSelect={(e, key, id)=>{
									this.onRatePlanSelect(key, id);
								}} onSubmitForm={this.handleSubmit} handleClearAll={(e)=>{
									this.handleClearAll(e)
								}} rateplan_id={rateplan_id}  />
			</div>
						
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		towTypeList:state.towTypeList.towTypes,
		ratePlanList:state.ratePlanList.ratePlanList,
		ratePlanObject:state.ratePlanList.ratePlanObject
  }
};

export default withRouter(connect(mapStateToProps)(DefaultRateplanRecPage));