import React, { Component,Fragment } from 'react';
import { Link } from 'react-router-dom';
import {Navbar,Nav,NavDropdown}  from 'react-bootstrap';
import { connect } from 'react-redux';
import logo from '../../../assets/img/SVMS Logo Final-A-FULL-SML.png';
import { getAccessToken, getAccessLevels, getUserName} from '../../../util/customMethods';
import { isManagementCompany } from '../../../actions/authenticateActions';
import HeaderMenu from './headerMenu';
import SideMenuCls from '../sidebar/sideMenuCls';
import {getAccessLevelsByModuleName} from '../../../util/customMethods';
import './Header.css';
let reviewPermission={edit:false,view:false};
class Header extends Component {
    constructor(props){
        super(props);
        this.handleRedirectClick=this.handleRedirectClick.bind(this);
        this.toggleMenu=this.toggleMenu.bind(this);
      }
      handleRedirectClick=(base,root,token)=>{
        console.log(base,root,token);
        window.location = window.location.origin+base+root;
      }
      handleNoPermission=(e)=>{
        e.preventDefault();
        alert("You don't have permission for review the invoice details.")
      }
      toggleMenu=(event)=>{
        event.preventDefault();
        if(document.body.classList.contains('menu-left-opened')){
          event.currentTarget.classList.remove('is-active');
          document.body.classList.remove('menu-left-opened');
          document.documentElement.style.overflow="auto";
        }else{
          event.currentTarget.classList.add('is-active');
          document.body.classList.add('menu-left-opened');
          document.documentElement.style.overflow="hidden";
        }
      }
    render(){
      const sideMenuCls=new SideMenuCls();
      const {reviewInbox}=this.props;
      let reviewPermissionData = getAccessLevelsByModuleName("Review Invoice");
      if(reviewPermissionData[0]){
        reviewPermission = reviewPermissionData[0].basicAccess
      }
        return(
          <header className="site-header">
          <div className="container-fluid"> <a to="/" className="site-logo-text"><img src={logo} alt="SVMS" className="block-center img-rounded" /></a>
              <button className="hamburger hamburger--htla" onClick={this.toggleMenu}> <span>toggle menu</span> </button>
              <div className="site-header-content">
                  <div className="site-header-content-in">
                  {/* {isManagementCompany() ? null :
                      <Link to={(reviewPermission.view) ? '/dispatch/inbox' : 'javascript:void(0)'} onClick={(e) => { (reviewPermission.view) ? 
                        this.submit(e):  this.handleNoPermission(e)  }} target="_blank" className="mobile-envelope-icon"> 
                        <i i className="fa fa-envelope-o" aria-hidden="true">
                        <span className="invoice-label invoice-label-pill invoice-label-danger" key={"alert"}>{reviewInbox.reviewedInvoicesCount || 0}</span>
                        </i> Inbox
                      </Link>
                  } */}
                      <div className="site-header-shown">
                          <div className="dropdown user-menu">
                              <button className="dropdown-toggle" id="dd-user-menu" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {getUserName()} 
                              <img src={require('../../../assets/images/avatar-2-64.png')} alt="" /> </button>
                              <div className="dropdown-menu " >
                                  <a className="dropdown-item" to="/Login" onClick={(e) => {
                                      e.preventDefault();
                                      this.props.onLogOut();
                                  }}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</a> </div>
                          </div>
                      </div>
                      <div className="mobile-menu-right-overlay"></div>
                      <HeaderMenu accessLevels={this.props.accessLevels||{}} dashboardAccess={this.props.dashboardAccess} 
                      reviewInbox={this.props.reviewInbox}/>
                  </div>
              </div>
          </div>
      </header>
        )
    }
}
export default Header