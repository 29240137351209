import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { isManagement } from '../../../util/customMethods';
import {
    required
} from "../../../util/validationMethods.js";
class ARDetailByAccountTypeCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card form-group">
                            <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formARDetailByAccountTypeInfo = c;
                                    this.props.initCallBack(c);
                                }} autocomplete="off">
                                    <div className="form-row">
                                        {isManagement() ? <div className="col-3 form-group">
                                            <ReactSelect name="idCompany"
                                                closeMenuOnSelect={true}
                                                options={this.props.childCompList.map((item, t) => {
                                                    return ({ value: item.optionVal, label: item.optionText })
                                                })}
                                                value={{
                                                    value: ((this.props.idCompany || '').toString()),
                                                    label: (this.props.childCompList.find((e) => e.optionVal == (this.props.idCompany || '').toString()) || {}).optionText
                                                }}
                                                onChange={(selectedOption) => {
                                                    this.props.onARDetailByAccountTypeFieldChange('idCompany', parseInt(selectedOption.value));
                                                }}
                                            />
                                            <Input hidden={true} name='idCompany' value={this.props.idCompany || ''} validations={[required]} requiredmsg="Please select company"></Input>
                                        </div> : null}
                                        <div className="col-2 form-group">
                                            <Select className="form-control form-control-sm" name="accountType" value={this.props.accountType}
                                                onChange={(e) => this.props.onARDetailByAccountTypeFieldChange(e.target.name, e.target.value)}>
                                                <option value="">Acccount Type</option>
                                                {
                                                    this.props.accountTypes.map((atype, k) => {
                                                        return <option key={k} value={atype.optionVal}>{atype.optionTxt}</option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <div className="ml-2 mr-2 form-group">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of</label>
                                        </div>
                                        <div className="col-2 form-group">
                                            <div>
                                                <TmDateTimePicker
                                                    className="form-control"
                                                    selectedDate={
                                                        this.props.asofdate === "" || this.props.asofdate === null
                                                            ? null
                                                            : moment(
                                                                this.props.asofdate,
                                                                GlobalValues.dateFormat
                                                            )._d
                                                    }
                                                    dateFormat={GlobalValues.dateFormat}
                                                    validationArray={[required]}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                    name="asOfDate"
                                                    reqmsg="As of date is required"
                                                    onDateSelectChange={e => {
                                                        this.props.onARDetailByAccountTypeFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2"
                                                onClick={(e) => this.props.getARDetailByAccountType(e)}>Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2"
                                                onClick={(e) => { this.props.exportARDetailsToExcel(e)}}>Export</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2"
                                                onClick={(e) => {}}>Print</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div></div>
            </Fragment>
        );
    }
}
export default ARDetailByAccountTypeCtrl;