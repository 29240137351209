import React from 'react'
import Scrollbars from 'react-custom-scrollbars';

class TmMultiSelectList extends React.Component{
    constructor(props){
        super(props)
        this.state={
            unSelectedList:[],
            unSelectSearch:'',
            selectedList:[],
            selectSearch:''
        }
        this.splitList=this.splitList.bind(this)
    }
    componentWillMount=()=>{
        this.splitList(this.props.accountList,this.props.selectedAccounts);
    }
    componentWillReceiveProps=(nextProps)=>{
        this.splitList(nextProps.accountList,nextProps.selectedAccounts)
    }
    splitList=(list,selectedList)=>{
        let _unSelectedList=list.filter(item=>{
                item.isSelected=false;
            return !selectedList.some(sItem=>{
                return sItem.idAccount == item.idAccount
            })
        });
        this.setState({unSelectedList:(_unSelectedList||[]).slice(),selectedList:(selectedList).slice()})
    }
    render(){
        return(<>
        <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="card box-shadow">
                    <div className="card-header">
                        <input type="text" placeholder="Search" name='unSelectSearch' className="form-control form-control-sm" onChange={(e)=>{
                            this.setState({unSelectSearch:e.target.value})
                        }} />
                    </div>
                    
                    <div className="list-group">
                        <Scrollbars autoHeight autoHeightMin={350}>
                            <ul>
                                {
                                    this.state.unSelectedList.filter(us=>{
                                        return (us.accountName||'').toUpperCase().includes((this.state.unSelectSearch||'').toUpperCase())
                                    }).map((item,k)=>{
                                        return(
                                            <li key={k} style={{marginRight:'10px'}} 
                                            className={"list-group-item"} >
                                                <div className="custom-control custom-checkbox mb-2">
                                                    <input type="checkbox" checked={(item.isSelected||false)?true:false} 
                                                    className="custom-control-input" id={'unSelect-'+k} onClick={(e)=>{
                                                let _unSelectedList=this.state.unSelectedList;
                                                let _selectIdx=_unSelectedList.findIndex((i)=>{
                                                    return i.idAccount==item.idAccount
                                                })
                                                if(_selectIdx>=0){
                                                    _unSelectedList[_selectIdx]['isSelected']=!(_unSelectedList[_selectIdx]['isSelected']||false)
                                                    this.setState({unSelectedList:_unSelectedList})
                                                }
                                            }} />
                                                    <label className="custom-control-label" for={'unSelect-'+k}>
                                                    {item.accountName}</label>
									            </div>
                                            </li>)
                                    })
                                }
                            </ul>
                        </Scrollbars>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 align-middle mb-20">
                    <button className="btn btn-info btn-lg" onClick={(e)=>{
                        if(this.state.unSelectedList.some(item=>item.isSelected)){
                           let _selectedList= this.state.unSelectedList.filter(item=>item.isSelected);
                           this.props.updateSelectedList(_selectedList)
                           this.forceUpdate();
                        }                            
                        }}>
                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 align-middle">
                        <button className="btn btn-info btn-lg" onClick={(e)=>{
                            if(this.state.selectedList.some(item=>item.isSelected)){
                            let _unSelectedList= this.state.selectedList.filter(item=>!item.isSelected);
                            this.props.updateUnSelectedList(_unSelectedList)
                            this.forceUpdate();
                            }                            
                        }}>
                            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                        </button>
                    </div>                    
                </div>
            </div>
            <div className="col-lg-5 col-md-2 col-sm-12">
                <div className="card box-shadow">
                    <div className="card-header"> 
                        <input type="text" placeholder="Search" className="form-control form-control-sm" onChange={(e)=>{
                            this.setState({selectSearch:e.target.value})
                        }} />                       
                    </div>
                    <div className="list-group"> 
                    <Scrollbars autoHeight autoHeightMin={350}>
                    <ul>
                            {
                                this.state.selectedList.filter(s=>{
                                        return (s.accountName||'').toUpperCase().includes((this.state.selectSearch||'').toUpperCase())
                                    }).map((item,k)=>{
                                    return(
                                        <li key={k} style={{marginRight:'10px'}} 
                                            className="list-group-item" >
                                            <div className="custom-control custom-checkbox mb-2">
                                                    <input type="checkbox" checked={(item.isSelected||false)?true:false} 
                                                    className="custom-control-input" id={'select-'+k} onClick={(e)=>{
                                                let _selectedList=this.state.selectedList;
                                                let _selectIdx=_selectedList.findIndex((i)=>{
                                                    return i.idAccount==item.idAccount
                                                })
                                                if(_selectIdx>=0){
                                                    _selectedList[_selectIdx]['isSelected']=!(_selectedList[_selectIdx]['isSelected']||false)
                                                    this.setState({selectedList:_selectedList})
                                                }                                                
                                            }} />
                                                    <label className="custom-control-label" for={'select-'+k}>
                                                    {item.accountName}</label>
									            </div>
                                            </li>)
                                })
                            }
                        </ul> 
                        </Scrollbars>                      
                    </div>
                </div>
            </div>
        </div>

        </>)
    }
}
export default TmMultiSelectList