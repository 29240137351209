import * as actionTypes from '../actionTypes';
import {moduleTypes, GlobalValues} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import moment from 'moment';


export const uploadAttachments=(name,formObject,invoiceId)=>{  
    return async (dispatch)=>{
        const _url=`invoice/${invoiceId}/attachments?attachmentType=${name}&access_token=${getAccessToken()}`
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).post(_url,formObject);
            if(response.status===200) { 
                let _invoiceObj={};      
                let _attachmentList=[];
                let _attachmentVendorList=[];
                    _attachmentList= (store.getState()).invoiceInfo._invoiceObj.invoiceAttachments;
                    _attachmentVendorList= (store.getState()).invoiceInfo._invoiceObj.vendorAttachments;
                    _invoiceObj=(store.getState()).invoiceInfo._invoiceObj;
                    let list=response.data.response[0];
                    if(name === 'towsheet'){
                        _invoiceObj.vendorAttachments.push(list);
                    }else{
                        _invoiceObj.invoiceAttachments.push(list);
                    }
                    dispatch({
                        type: actionTypes.GET_INVOICE_INFO,
                        _invoiceObj:_invoiceObj
                    })
                return response.data;
            } else{    
                // dispatch({
                //     type: actionTypes.GET_INVOICE_SEARCH_LIST,
                //     searchInvoiceList:[]
                // });
                return response.data;
            }
        }catch(ex){
            // dispatch({
            //     type: actionTypes.GET_INVOICE_SEARCH_LIST,
            //     searchInvoiceList:[]
            // })

        }
    }
}
export const deleteAttachments=(invoiceId,name,id)=>{
    let _url=`invoice/${invoiceId}/attachment/${id}?attachmentType=${name}&access_token=${getAccessToken()}`;
    return async(dispatch)=>{
       // dispatch(spinnerActions.showSpinner());
       try{
        const response=await getAxiosInstance(moduleTypes.DISPATCH).delete(_url)
            if(response.status===200) {
                let _invoiceObj={};      
                let _attachmentList=[];
                let _attachmentVendorList=[];
                    _attachmentList= (store.getState()).invoiceInfo._invoiceObj.invoiceAttachments;
                    _attachmentVendorList= (store.getState()).invoiceInfo._invoiceObj.vendorAttachments;
                    _invoiceObj=(store.getState()).invoiceInfo._invoiceObj;
                    if(name === 'towsheet'){
                        _invoiceObj.vendorAttachments.splice(_invoiceObj.vendorAttachments.indexOf(_invoiceObj.vendorAttachments.find(item => item.attachmentId == id)), 1);
                    }else{
                       _invoiceObj.invoiceAttachments.splice(_invoiceObj.invoiceAttachments.indexOf(_invoiceObj.invoiceAttachments.find(item => item.attachmentId == id)), 1);
                    }
                    dispatch({
                        type: actionTypes.GET_INVOICE_INFO,
                        _invoiceObj:_invoiceObj
                    })
                //success(response.message);
                return {statusCode:response.status,statusMsg:response.data.statusMsg};
            } else{
                return {statusCode:null,statusMsg:response.data.statusMsg};                 
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        }
    }
}
export const deleteNotes=(invoiceId,id)=>{
    let _url=`invoice/${invoiceId}/notes/${id}?access_token=${getAccessToken()}`;
    return async(dispatch)=>{
       // dispatch(spinnerActions.showSpinner());
       try{
        const response=await getAxiosInstance(moduleTypes.DISPATCH).delete(_url)
            if(response.status===200) {
                let _invoiceObj={};      
                // let _attachmentList=[];
                //     _attachmentList= (store.getState()).invoiceInfo._invoiceObj.invoiceAttachments;
                    _invoiceObj=(store.getState()).invoiceInfo._invoiceObj;
                        _invoiceObj.invoiceNotes.splice(_invoiceObj.invoiceNotes.indexOf(_invoiceObj.invoiceNotes.find(item => item.invoiceNotesId == id)), 1);
                    
                    dispatch({
                        type: actionTypes.GET_INVOICE_INFO,
                        _invoiceObj:_invoiceObj
                    })
                //success(response.message);
                return {statusCode:response.status,statusMsg:response.data.statusMsg};
            } else{
                return {statusCode:null,statusMsg:response.data.statusMsg};                 
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        }
    }
}
export const addUserNotes=(invoiceId, noteId, noteTxt)=>{
    return async (dispatch)=>{
        const _url=`invoice/${invoiceId}/notes/${noteId}?access_token=${getAccessToken()}`
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).put(_url, noteTxt, { headers: { 'Content-Type': 'application/json' } });
            if(response.status===200) {
                let _invoiceObj={};      
                    _invoiceObj=(store.getState()).invoiceInfo._invoiceObj;
                    let list={};
                    if(noteId === 0){
                        list=response.data.response;
                    }else{
                        let foundIndex = _invoiceObj.invoiceNotes.findIndex(x => x.invoiceNotesId == noteId);
                        _invoiceObj.invoiceNotes[foundIndex].notesText = response.data.response.notesText;
                    }
                        _invoiceObj.invoiceNotes.push(list);
                    dispatch({
                        type: actionTypes.GET_INVOICE_INFO,
                        _invoiceObj:_invoiceObj
                    })
                return {statusCode:response.status,statusMsg:response.data.statusMsg}
            } else{                
                return {statusCode:null,statusMsg:response.data.statusMsg}
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}

        }
    }
}
