import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StatementsCtrl from '../../../components/reportsComponents/accounting/statementsCtrl';
import StatementsTable from '../../../components/reportsComponents/accounting/statementsTable';
const pageTitle = 'Statements'
let StatementsForm={
	formStatementsInfo:{}
}

class Statements extends Component {
	constructor() {
		super();
		this.state = {
			asOfDate:''
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		
    }
    onStatementsFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Statements</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <StatementsCtrl onStatementsFieldChange={(key, value)=>this.onStatementsFieldChange(key, value)}
                    initCallBack={(frm)=>{
						StatementsForm.formStatementsInfo=frm;
                    }} asofdate={this.state.asOfDate}/>
                    <StatementsTable />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(Statements));
