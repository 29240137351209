import React, { Component, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import { isYear, isMileage, isGreaterOrEqual, vinValidate, required, isValidForm, isNumeric } from "../../../../util/validationMethods";
import TruckInfoServiceDetails from "./truckInfoServiceDetails";
import TruckInfoServiceRecord from "./truckInfoServiceRecord";
import TmDateTimePicker from "../../../../components/utilComponents/tmDateTimePicker/tmDateTimePicker";
import { GlobalValues } from "../../../../util/constants";
import moment from 'moment'
// import Cleave from 'cleave.js/react';
class TruckInfo extends Component {
  constructor() {
    super();
    this.state = {
      validated: false
    };
    this.checkAccess = this.checkAccess.bind(this);
  }
  checkAccess = () => {
    if (this.props.action === 'NEW') {
      if ((this.props.accessLevel.basicAccess.add || false) === true) {
        return false
      } else {
        return true
      }
    } else {
      if ((this.props.accessLevel.basicAccess.edit || false) === true) {
        return false;
      } else {
        return true;
      }
    }
  }

  render() {
    const { validated } = this.state;
    const { truck } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-10">
            <Form
              ref={c => {
                this.form = c;
              }}
            >
              <fieldset disabled={this.checkAccess()}>
                <div className="row">
                  <div className="col-md-12 mb-15">
                    <div className="row">
                      <div className="col-md-3">

                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Truck#
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="form-control"
                              type="text"
                              name="truckId"
                              requiredmsg="Please enter Truck#"
                              value={this.props.truck.truckId || ''}
                              placeholder=""
                              maxlength="10"
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              validations={[required]}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            GVWR
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="input-numeral form-control"
                              placeholder="Enter numeral"
                              options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                              name="gvwr"
                              requiredmsg="Please enter a valid GVWR"
                              value={truck.gvwr || ''}
                              // placeholder=""
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              validations={[required, isNumeric]}

                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Truck Type
                          </label>
                          <div className="col-sm-12">
                            <Select className="form-control"
                              name="truckTypeCd"
                              requiredmsg="Please select Truck Type"
                              value={truck.truckTypeCd || ''}
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              validations={[required]}
                            >
                              <option value="" />
                              <option value="Flat Bed">Flat Bed</option>
                              <option value="Heavy Duty">Heavy Duty</option>
                              <option value="Landoll">Landoll</option>
                              <option value="Medium Duty">Medium Duty</option>
                              <option value="Wheel Lift">Wheel Lift</option>
                            </Select>
                          </div>
                        </div>
                        {/* <Input
                                className="form-control"
                                type="number"
                                name="gvwr"
                                requiredmsg="Please enter GVWR"
                                value={truck.gvwr || ''}
                                placeholder=""
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required, isNumeric]}
                              />
                            </div>
                          </div>
                          <div className="">
                            <label className="col-sm-12 col-form-label">
                              DPS ID
                            </label>
                            <div className="col-sm-12">
                              <Cleave
                                className="input-numeral form-control" 
                                options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                name="dpsid"
                                requiredmsg="Please enter DPS ID"
                                value={truck.dpsid || ''}
                                placeholder=""
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required]}
                              />
                            </div>
                          </div>
                          <div className="">
                            <label className="col-sm-12 col-form-label">
                              Truck Type
                            </label>
                            <div className="col-sm-12">
                              <Select className="form-control"
                                name="truckTypeCd"
                                requiredmsg="Please select Truck Type"
                                value={truck.truckTypeCd || ''}
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required]}
                              >
                                <option value="" />
                                <option value="Flat Bed">Flat Bed</option>
                                <option value="Heavy Duty">Heavy Duty</option>
                                <option value="Landoll">Landoll</option>
                                <option value="Medium Duty">Medium Duty</option>
                                <option value="Wheel Lift">Wheel Lift</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">  
                          <div className="">
                            <label className="col-sm-12 col-form-label">
                              Group
                            </label>
                            <div className="col-sm-12">
                              <Input
                                className="form-control"
                                type="text"
                                name="groupNo"
                                requiredmsg="Please enter Group"
                                value={truck.groupNo || ''}
                                placeholder=""
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required]}
                              />
                            </div>
                          </div>
                          <div className="">
                            <label className="col-sm-12 col-form-label">
                              License
                            </label>
                            <div className="col-sm-12">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="licenseNo"
                                requiredmsg="Please enter License"
                                value={truck.licenseNo || ''}
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required]}
                              />
                            </div>
                          </div>
                          <div className="">
                            <label className="col-sm-12 col-form-label">
                              State
                            </label>
                            <div className="col-sm-12">
                              <Select className="form-control"
                                name="state"
                                requiredmsg="Please select State"
                                value={truck.state || ''}
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required]}
                              >
                                <option value={""}>{""}</option>
                                {this.props.stateList.map((state, key) => {
                                  return (
                                    <option key={key} value={state.optionVal}>
                                      {state.optionTxt}
                                    </option>
                                  );
                                })}
                              </Select>
                            </div>
                            </div>
                            <div className="">
                              <label className="col-sm-12 col-form-label">
                                License Plate
                              </label>
                              <div className="col-sm-12">
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="licensePlateNo"
                                  requiredmsg="Please enter License Plate"
                                  value={truck.licensePlateNo || ''}
                                  onChange={e => {
                                    this.props.onTruckFieldChange(
                                      e,
                                      e.currentTarget.name,
                                      e.currentTarget.value
                                    );
                                  }}
                                  placeholder=""
                                  validations={[required]}
                                />
                              </div>
                            </div>                                                    
                      </div>
                      <div className="col-md-3">
                          <div className="">
                              <label className="col-sm-12 col-form-label">
                                Last Inspection Date
                              </label>
                              <div className="col-sm-12" >
                              <TmDateTimePicker className="form-control" selectedDate={truck.lastInspectionDate===''?undefined: moment(truck.lastInspectionDate)._d}
                                    dateFormat={GlobalValues.dateFormat}
                                    validationArray={[required]}
                                    name="lastInspectionDate"
                                    requiredmsg="Please enter Date"
                                    onDateSelectChange={(e)=>{
                                        this.props.onTruckFieldChange(
                                      e,
                                      'lastInspectionDate',
                                      moment(e).format(GlobalValues.dateFormat)
                                    );
                                        }} disabled={this.checkAccess()} />

                            </div>
                          </div>
                          <div className="">
                          <Form
                          ref={c => {
                            this.vinform = c;
                          }}
                        >
                            <label className="col-sm-12 col-form-label">
                              VIN
                            </label>
                            <div className="col-sm-12">
                              <Input
                                className="form-control"
                                type="text"
                                name="vin"
                                requiredmsg="Please enter VIN"
                                value={truck.vin}
                                placeholder=""                               
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[ required, vinValidate]}
                              />
                            </div>
                            </Form>
                          </div>
                          <div className="">
                          <div className="col-sm-12">
                          <label className="col-sm-12 col-form-label"></label>
                          <button type="button" className="btn btn-success btn-sm" onClick={e => {
                                e.preventDefault();                                                          
                                this.vinform.validateAll();
                                if(!isValidForm(this.vinform)){
                                }else{
                                  this.props.getVINValidate(e,truck.vin);                       
                                }                                                             
                              }}>Validate</button>
                          </div>
                          
                          </div>                        
                          <div className="">
                            <label className="col-sm-12 col-form-label">
                              Year
                            </label>
                            <div className="col-sm-12">
                            {/* <Cleave placeholder="Enter date" 
                            options={{date: true, datePattern: ['Y']}}
                        onChange={this.onDateChange}/> */}


                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Year
                          </label>
                          <div className="col-sm-12">
                            <Input
                              options={{ date: true, datePattern: ['Y'] }}
                              className="form-control"
                              type="text"
                              name="year"
                              value={truck.year || ''}
                              placeholder=""
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              validations={[isYear]}
                            />
                          </div>
                        </div>


                      </div>





                      <div className="col-md-3">
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Make
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="form-control"
                              type="text"
                              name="make"
                              value={truck.make || ''}
                              placeholder=""
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            DPS ID#
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="input-numeral form-control"
                              options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                              name="dpsid"
                              requiredmsg="Please enter DPS ID"
                              value={truck.dpsid || ''}
                              placeholder=""
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            License
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="licenseNo"
                              requiredmsg="Please enter License"
                              value={truck.licenseNo || ''}
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* <Input
                className="form-control"
                type="number"
                name="gvwr"
                requiredmsg="Please enter GVWR"
                value={truck.gvwr || ''}
                placeholder=""
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[required, isNumeric]}
              />
            </div>
          </div>
          <div className="">
            <label className="col-sm-12 col-form-label">
              DPS ID
            </label>
            <div className="col-sm-12">
              <Input
                className="input-numeral form-control" 
                options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                name="dpsid"
                requiredmsg="Please enter DPS ID"
                value={truck.dpsid || ''}
                placeholder=""
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[required]}
              />
            </div>
          </div>
          <div className="">
            <label className="col-sm-12 col-form-label">
              Truck Type
            </label>
            <div className="col-sm-12">
              <Select className="form-control"
                name="truckTypeCd"
                requiredmsg="Please select Truck Type"
                value={truck.truckTypeCd || ''}
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[required]}
              >
                <option value="" />
                <option value="Flat Bed">Flat Bed</option>
                <option value="Heavy Duty">Heavy Duty</option>
                <option value="Landoll">Landoll</option>
                <option value="Medium Duty">Medium Duty</option>
                <option value="Wheel Lift">Wheel Lift</option>
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">  
          <div className="">
            <label className="col-sm-12 col-form-label">
              Group
            </label>
            <div className="col-sm-12">
              <Input
                className="form-control"
                type="text"
                name="groupNo"
                requiredmsg="Please enter Group"
                value={truck.groupNo || ''}
                placeholder=""
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[required]}
              />
            </div>
          </div>
          <div className="">
            <label className="col-sm-12 col-form-label">
              License
            </label>
            <div className="col-sm-12">
              <Input
                className="form-control"
                type="text"
                placeholder=""
                name="licenseNo"
                requiredmsg="Please enter License"
                value={truck.licenseNo || ''}
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[required]}
              />
            </div>
          </div>
          <div className="">
            <label className="col-sm-12 col-form-label">
              State
            </label>
            <div className="col-sm-12">
              <Select className="form-control"
                name="state"
                requiredmsg="Please select State"
                value={truck.state || ''}
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[required]}
              >
                <option value={""}>{""}</option>
                {this.props.stateList.map((state, key) => {
                  return (
                    <option key={key} value={state.optionVal}>
                      {state.optionTxt}
                    </option>
                  );
                })}
              </Select>
            </div>
            </div>
            <div className="">
              <label className="col-sm-12 col-form-label">
                License Plate
              </label>
              <div className="col-sm-12">
                <Input
                  className="form-control"
                  type="text"
                  name="licensePlateNo"
                  requiredmsg="Please enter License Plate"
                  value={truck.licensePlateNo || ''}
                  onChange={e => {
                    this.props.onTruckFieldChange(
                      e,
                      e.currentTarget.name,
                      e.currentTarget.value
                    );
                  }}
                  placeholder=""
                  validations={[required]}
                />
              </div>
            </div>                                                    
      </div>
      <div className="col-md-3">
          <div className="">
              <label className="col-sm-12 col-form-label">
                Last Inspection Date
              </label>
              <div className="col-sm-12" >
              <TmDateTimePicker className="form-control" selectedDate={truck.lastInspectionDate===''?undefined: moment(truck.lastInspectionDate)._d}
                    dateFormat={GlobalValues.dateFormat}
                    validationArray={[required]}
                    name="lastInspectionDate"
                    requiredmsg="Please enter Date"
                    onDateSelectChange={(e)=>{
                        this.props.onTruckFieldChange(
                      e,
                      'lastInspectionDate',
                      moment(e).format(GlobalValues.dateFormat)
                    );
                        }} disabled={this.checkAccess()} />

            </div>
          </div>
          <div className="">
          <Form
          ref={c => {
            this.vinform = c;
          }}
        >
            <label className="col-sm-12 col-form-label">
              VIN
            </label>
            <div className="col-sm-12">
              <Input
                className="form-control"
                type="text"
                name="vin"
                requiredmsg="Please enter VIN"
                value={truck.vin}
                placeholder=""                               
                onChange={e => {
                  this.props.onTruckFieldChange(
                    e,
                    e.currentTarget.name,
                    e.currentTarget.value
                  );
                }}
                validations={[ required, vinValidate]}
              />
            </div>
            </Form>
          </div>
          <div className="">
          <div className="col-sm-12">
          <label className="col-sm-12 col-form-label"></label>
          <button type="button" className="btn btn-success btn-sm" onClick={e => {
                e.preventDefault();                                                          
                this.vinform.validateAll();
                if(!isValidForm(this.vinform)){
                }else{
                  this.props.getVINValidate(e,truck.vin);                       
                }                                                             
              }}>Validate</button>
          </div>
          
          </div>                        
          <div className="">
            <label className="col-sm-12 col-form-label">
              Year
            </label>
            <div className="col-sm-12">
            {/* <Cleave placeholder="Enter date" 
            options={{date: true, datePattern: ['Y']}}
        onChange={this.onDateChange}/> */}


                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            License PlateNo
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="form-control"
                              type="text"
                              name="licensePlateNo"
                              requiredmsg="Please enter License Plate"
                              value={truck.licensePlateNo || ''}
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              placeholder=""
                              validations={[required]}
                            />
                          </div>
                        </div>


                      </div>




                      <div className="col-md-3">
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            State
                          </label>
                          <div className="col-sm-12">
                            <Select className="form-control"
                              name="state"
                              requiredmsg="Please select State"
                              value={truck.state || ''}
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              validations={[required]}
                            >
                              <option value={""}>{""}</option>
                              {this.props.stateList.map((state, key) => {
                                return (
                                  <option key={key} value={state.optionVal}>
                                    {state.optionTxt}
                                  </option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Model
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="form-control"
                              type="text"
                              name="model"
                              value={truck.model || ''}
                              type="text"
                              placeholder=""
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Device
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="form-control"
                              type="text"
                              name="deviceId"
                              value={truck.deviceId || ''}
                              placeholder=""
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Initial Mileage
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="input-numeral form-control"
                              options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                              name="initialMileage"
                              requiredmsg="Please enter Intial Mileage"
                              value={(truck.initialMileage === 0) ? 0 : (truck.initialMileage || '')}
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              placeholder=""
                              validations={[isMileage, required]}
                            />
                          </div>
                        </div>
                       
                      </div>
                      <div className="col-md-3">
                      <div className="">
                          <label className="col-sm-12 col-form-label">
                            Current Mileage
                          </label>
                          <div className="col-sm-12">
                            <Input
                              className="input-numeral form-control"
                              options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                              name="currentMileage"
                              compareTo={truck.initialMileage || ''}
                              requiredmsg="Please enter Current Mileage"
                              comparemsg="Current mileage should be equal or greater than Initial mileage."
                              value={truck.currentMileage}
                              onChange={e => {
                                this.props.onTruckFieldChange(
                                  e,
                                  e.currentTarget.name,
                                  e.currentTarget.value
                                );
                              }}
                              placeholder=""
                              validations={[isMileage, isGreaterOrEqual, required]}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label className="col-sm-12 col-form-label">
                            Last Inspection Date
                          </label>
                          <div className="col-sm-12">
                            <TmDateTimePicker className="form-control" selectedDate={(truck.lastInspectionDate || '') === '' ? null : moment(truck.lastInspectionDate)._d}
                              dateFormat={GlobalValues.dateFormat}
                              validationArray={[required]}
                              name="lastInspectionDate"
                              requiredmsg="Please enter Date"
                              showYearDropdown={true}
                              showmonthdropdown={true}
                              onDateSelectChange={(e) => {
                                this.props.onTruckFieldChange(
                                  e,
                                  'lastInspectionDate',
                                  e === null ? null : moment(e).format(GlobalValues.dateFormat)
                                );
                              }} disabled={this.checkAccess()} />
                          </div>
                        </div>
                        {/* <div className="">
                            <label className="col-sm-12 col-form-label">
                              Device
                            </label>
                            <div className="col-sm-12">
                            <Input
                                className="form-control"
                                type="text"
                                name="vin"
                                requiredmsg="Please enter VIN"
                                value={truck.vin}
                                placeholder=""                               
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[ required, vinValidate]}
                              />
                            </div>
                          </div> */}
                        <div className="">
                          <Form
                            ref={c => {
                              this.vinform = c;
                            }}
                          >
                            <label className="col-sm-12 col-form-label">
                              VIN
                            </label>
                            <div className="col-sm-12">
                              <Input
                                className="form-control"
                                type="text"
                                name="vin"
                                requiredmsg="Please enter VIN"
                                value={truck.vin}
                                placeholder=""
                                onChange={e => {
                                  this.props.onTruckFieldChange(
                                    e,
                                    e.currentTarget.name,
                                    e.currentTarget.value
                                  );
                                }}
                                validations={[required, vinValidate]}
                              />
                            </div>
                          </Form>
                        </div>
                        <div className="">
                          <div className="col-sm-12">
                            <label className="col-sm-12 col-form-label"></label>
                            <button type="button" className="btn btn-success btn-sm" onClick={e => {
                              e.preventDefault();
                              this.vinform.validateAll();
                              if (!isValidForm(this.vinform)) {
                              } else {
                                this.props.getVINValidate(e, truck.vin);
                              }
                            }}>Validate</button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4 mb-10">
                      <TowTypeSelect
                        towTypeData={this.props.towTypes}
                        selectedTows={
                          (truck.towTypes || "") === ""
                            ? []
                            : truck.towTypes.split("/")
                        }
                        onTowSelectionChange={(e, value) => {
                          let _towTypes = truck.towTypes.split("/");
                          if (_towTypes.includes(value)) {
                            _towTypes.splice(_towTypes.indexOf(value), 1);
                          } else {
                            _towTypes.push(value);
                          }
                          this.props.onTruckFieldChange(
                            e,
                            "towTypes",
                            _towTypes.join("/")
                          );
                        }}
                      />
                  </div> */}


                  <div className="col-md-12">
                    <TruckInfoServiceRecord disabled={this.checkAccess()} truckPrevServiceStatus={truck.serviceStatus || ''}
                      onServiceSubmit={(e, serviceObj) => {
                        this.props.onTruckServiceSubmit(e, serviceObj);
                      }}
                    />
                  </div>

                </div>
              </fieldset>
            </Form>
          </div>
          <div className="col-md-12 col-sm-12 md-10">
            <TruckInfoServiceDetails disabled={this.checkAccess()}
              serviceDetailsListData={this.props.serviceList}
            />
          </div>
          <div className="col-md-12 col-sm-12 md-10">
            <button disabled={this.checkAccess()}
              type="button"
              className="btn btn-success btn-sm pull-right"
              onClick={e => {
                e.preventDefault();
                this.form.validateAll();
                if (!isValidForm(this.form)) {
                } else {
                  this.props.onTruckSubmit(e);
                }
              }}
            >{this.props.action === 'NEW' ? 'Save' : 'Update'}</button>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default TruckInfo