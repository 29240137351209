import React, { Component,Fragment } from 'react';
import {Modal,Button} from 'react-bootstrap';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
import moment from 'moment';
import Acc_Link_Info from './Acc_Link_Info';
const defaultObj = {id: 0,gp_name: '',acc_Id: '',accLink: '',uniqueCode:'',active: 1}
const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
  }
};
class ARS_Acc_Link_Group extends Component {
    state={       
        modalIsOpen:false,
        deleteOpen:false,
        generateOpen:false,
        Acc_ARSObj: JSON.parse(JSON.stringify(defaultObj)),
        Acc_ARSObjIndex: -1  
    }
    
    buildDataFormatForExport=()=>{
      return([{
        columns:[{title:'GROUP NAME'},{title:'LINK'}],
        data: this.props.listOfAccountsLink.map((item)=>{
          return [{value:item.gp_name||''},{value:item.accLink||''}]
        })
      }])
  }
  handleClose=()=>{
    this.setState({modalIsOpen:false})
  }
  onAcc_ARSObjFieldChange=(name,value)=>{
    let _recObj=this.state.Acc_ARSObj;
    _recObj[name]=value;
    this.setState({Acc_ARSObj:_recObj})
  }
    render(){
        const excelDataSet=this.buildDataFormatForExport();
        const contactCols=[     
          {
            Header: 'GROUP NAME',
            accessor: 'gp_name', 
            filterable:false,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
            
          },
          {
            Header: 'LINK',
            accessor: 'accLink',
            filterable:false,
            Cell: row => <div><button style={{ float: "right" }} className="btn btn-success btn-sm" onClick={() => copyToClipboard(row.value)}><i class="fa fa-clipboard" aria-hidden="true"></i></button></div>,
            align:'center',
            width: 60
          },
          {
            Header: 'Regenerate',
            filterable:false,
            Cell: (row) => <button style={{ float: "right" }} type="button"  className="btn btn-warning btn-sm" 
              onClick={(e)=>{
                this.setState({ generateOpen: true,Acc_ARSObj:Object.assign({},row.original)});
              }} ><i class="fa fa-refresh" aria-hidden="true"></i></button>,
            width: 60
          },
          {
            Header: 'Delete',
            filterable:false,
            Cell: (row) => <button style={{ float: "right" }} type="button"   className="btn btn-danger btn-sm" 
              onClick={(e)=>{
                this.setState({ deleteOpen: true,Acc_ARSObj:Object.assign({},row.original)});
              }} ><i className="fa fa-trash"></i></button>,
            width: 60
          }
        ];
          
        return(<Fragment>
        <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
			    <div className="pull-left">
				    <h6 className="text-blue">ARS Account Groups List</h6>				
			    </div>
                <div className="pull-right">
                  <div className="btn-list">
                    <TmExportExcel name={'Notes'} filename={`${"AccountLinkList"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                            element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                            dataSet={excelDataSet} />
                    <button className="btn btn-success btn-sm " onClick={(e)=>{
                        this.setState({ modalIsOpen: true,Acc_ARSObjIndex:-1,Acc_ARSObj:Object.assign({},defaultObj)});
                        }} disabled={this.props.disabled} ><i className="fa fa fa-plus"></i>ADD LINK</button>
                  </div>
                </div>
        </div></div>
        <div className="row">
        <div className="col-md-12 col-sm-12 mb-30">
            <TmReactTable 
            filterable 
            sortable
            resizable
            defaultPageSize={3}
            data={this.props.listOfAccountsLink}
            columns={contactCols}
        /> 
        </div>
        </div>
        <Modal show={this.state.modalIsOpen} > 
				<div className="modal-header">
            <Modal.Title>{'ADD LINK'}</Modal.Title>
        </div>
        <div className="modal-body">
                  <Acc_Link_Info isPhoneRequired={this.props.isPhoneRequired} isEmailRequired={this.props.isEmailRequired} 
                    onSubmit={(e) => {
                        this.props.Go(this.state.Acc_ARSObj)
                        this.setState({modalIsOpen:false,Acc_ARSObjIndex:-1,Acc_ARSObj:Object.assign({},defaultObj)})   
                    }}
                    Acc_ARSObj={this.state.Acc_ARSObj} onFieldChange={(name,value)=>this.onAcc_ARSObjFieldChange(name,value)} handleClose={this.handleClose}/>
        </div>
        </Modal>      
        <Modal show={this.state.deleteOpen} onHide={()=>{this.setState({deleteOpen:false})}}>
        <Modal.Header closeButton>
          <Modal.Title>Delete ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will delete the ARS link for {this.state.Acc_ARSObj.gp_name}, would you like to continue </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{this.setState({deleteOpen:false})}} >
            Close
          </Button>
          <Button variant="danger" onClick={()=>{
            this.props.deleteLink(this.state.Acc_ARSObj.id);
            this.setState({deleteOpen:false,Acc_ARSObj:Object.assign({},defaultObj)})}}>
          Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.generateOpen} onHide={()=>{this.setState({generateOpen:false})}}>
        <Modal.Header closeButton>
          <Modal.Title>Regenerate ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will regenrerate the ARS link for {this.state.Acc_ARSObj.gp_name}, would you like to continue </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{this.setState({generateOpen:false})}} >
            Close
          </Button>
          <Button variant="warning" onClick={()=>{
             this.props.Go(this.state.Acc_ARSObj);
            this.setState({generateOpen:false,Acc_ARSObj:Object.assign({},defaultObj)})}}>
          Continue
          </Button>
        </Modal.Footer>
      </Modal>
        </Fragment>);
    }

}
export default ARS_Acc_Link_Group