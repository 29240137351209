import * as actionTypes from '../actionTypes';
import {moduleTypes, GlobalValues} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import moment from 'moment';
export const getReviewLocationList=(location, flagVal)=>{
    const _url=`dashboard/reviewInvoice?companyId=${getCompanyId()}&reviewLocation=${location || null}&isListView=${flagVal}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const response= await getAxiosInstance(moduleTypes.MANAGE).get(_url)
            if(response.status===200) {  
                if(flagVal){
                    dispatch({
                        type: actionTypes.GET_REVIEW_INVOICE_LIST,
                        reviewInvoiceList:response.data.response
                    });
                }else{
                    dispatch({
                        type: actionTypes.GET_REVIEW_LOCATION_LIST,
                        reviewLocationList:response.data.response
                    });
                }      
                } else{ 
                    if(flagVal){
                        dispatch({
                            type: actionTypes.GET_REVIEW_INVOICE_LIST,
                            reviewInvoiceList:{}
                        });
                    }else{
                        dispatch({
                            type: actionTypes.GET_REVIEW_LOCATION_LIST,
                            reviewLocationList:{}
                        });
                    }   
                }
            }
        catch(error){
            if(flagVal){
                dispatch({
                    type: actionTypes.GET_REVIEW_INVOICE_LIST,
                    reviewInvoiceList:{}
                });
            }else{
                dispatch({
                    type: actionTypes.GET_REVIEW_LOCATION_LIST,
                    reviewLocationList:{}
                });
            }   
        }
    }
}