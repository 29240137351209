import * as actionTypes from '../../actions/actionTypes';
const defaultObject={
    reportData:[],
    driverList:[],
    truckList:[]

}
export const allInvoiceReducer = (state = defaultObject, action) => {
    switch (action.type) {
      case actionTypes.GET_TRUCK_LIST:
        return {...state ,truckList:(action.truckList||[])};
      case actionTypes.GET_DRIVER_LIST:
        return {...state ,driverList:(action.driverList||[])}; 
      case actionTypes.GET_REPORTS_DATA:
        return {...state ,reportData:(action.reportData||[])};    
      default:
        return state;
    }
  };

