import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import EmployeeList from '../../components/adminComponents/employees/employeesList';
import {getEmployeeList,clearEmployeeRec} from '../../actions/adminAction/employeeActions';
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle='Employee';
class EmployeesPage extends Component {
	constructor(){
		super();
		this.state={	
			searchObj:	{
				"searchText":"",
				"accountStatus":"1"
			}	
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.bindEmployeesList=this.bindEmployeesList.bind(this);
		this.onSearchChange=this.onSearchChange.bind(this);
	}
	componentWillMount=()=>{
		document.title = pageTitle;
		this.bindEmployeesList()

	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('403');
		}
	}
	bindEmployeesList=()=>{
		const {dispatch}=this.props;
		dispatch(clearEmployeeRec());
		dispatch(getEmployeeList(this.state.searchObj));
	}
	onSearchChange=(searchObject)=>{
		let _searchObj=this.state.searchObj;
		_searchObj.searchText=searchObject.searchText;
		_searchObj.accountStatus=searchObject.activeFilter===-1?"1','0":searchObject.activeFilter;
		this.setState({searchObj:_searchObj},this.bindEmployeesList());
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Employee');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-users"></i> Employee Search</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<EmployeeList accessLevel={accessLevelObj||{}} data={this.props.employeeList} onSearchChange={this.onSearchChange} onNewEmpClick={(e)=>{
					this.props.history.push('/admin/employeesDetails?eid=0');
				}} onEditEmpClick={(e,id)=>{
					this.props.history.push("/admin/employeesDetails?eid="+id)
				}} />
			</div>					
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		employeeList:state.employees.employeeList
  }
};

export default withRouter(connect(mapStateToProps)(EmployeesPage));
