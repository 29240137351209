import * as actionTypes from '../actionTypes';
import { moduleTypes, dropOffKeyValPair, pickUpkeyValPair } from '../../util/constants';
import { getAccessToken } from '../authenticateActions'
import { getAxiosInstance } from '../../util/service';
import { store } from '../../store/configureStore';
import { getCompanyId,sortName ,isManagement} from '../../util/customMethods';
import moment from 'moment'

export const fetchInvoiceInfoById = (invoiceId) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice/${invoiceId}?isManagement=${isManagement()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).get(_url);
            if (response.status === 200) {
                _invoiceObj=response.data.response;
                _invoiceObj.isManualClear = (response.data.response.clearedTime||null)==null?false:true
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj: _invoiceObj
                });
                return response.data;
            } else {
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj: undefined
                });
                return response.data;
            }
        }
        catch (error) {
            dispatch({
                type: actionTypes.GET_INVOICE_INFO,
                _invoiceObj: undefined
            });
            return error;
        }
    }
}
export const fetchAccountByType = (type) => {
    const _url = `/company/${getCompanyId()}/accountList/{0}?type=${type}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).get(_url);
            if (response.status === 200) {
                dispatch({
                    type: actionTypes.GET_ACCOUNT_BY_TYPE,
                    accountListByType: response.data.response
                });
            } else {
                dispatch({
                    type: actionTypes.GET_ACCOUNT_BY_TYPE,
                    accountListByType: undefined
                });
            }
        }
        catch (error) {
            dispatch({
                type: actionTypes.GET_ACCOUNT_BY_TYPE,
                _invoiceObj: undefined
            });
        }
    }
}

export const fetchReQAccountInfoData = async(accountId, invoiceId, callType, isDefault) => {
    const _url = `invoice/accountInfo?accountId=${accountId}&invoiceId=${invoiceId}&callType=${callType}&isDefaultNotReq=${isDefault}&access_token=${getAccessToken()}`;
       try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).get(_url);
            if (response.status === 200) {
            return response.data.response;
            } else {
                return undefined;
            }
        }
        catch (error) {
            return undefined;
        }
}

export const fetchReQAccountInfo = (accountId, invoiceId, callType, isDefault) => {
    return async (dispatch) => {
            const response = await fetchReQAccountInfoData(accountId, invoiceId, callType, isDefault);
                dispatch({
                    type: actionTypes.GET_REQ_ACCOUNT_INFO,
                    accountReqData: response
                });
                dispatch(setDefaultsValues(callType))
                return ((response.accountingType||'')=='Cash');
    }
}

export const defaultInvoiceInfo = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let _towList = (store.getState()).towTypeList.towTypes || [];
    const _url = `invoice/defaultInvoiceInfo?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).post(_url,JSON.stringify(_invoiceObj),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                let billing = response.data.response.billing||{}
                const found = _towList.find(obj => {return obj.towTypeId === parseInt(billing.ratePlan.accountPlanRateTowType||0);});
                _invoiceObj.invoiceBilling= billing;
                _invoiceObj.towType = found.towType||'';
                if(response.data.response.billing)
              //  _invoiceObj.invoiceVehicle= response.data.response.vehicle||{}
                 dispatch({
                     type: actionTypes.GET_INVOICE_INFO,
                     _invoiceObj:_invoiceObj
                 });

            } else {
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj: undefined
                });
            }
        }
        catch (error) {
            dispatch({
                type: actionTypes.GET_INVOICE_INFO,
                _invoiceObj: undefined
            });
        }
    }
}
/**Function to add and update payments */
export const addUpdatePayment = (invoiceId,paymentObj) => {
    const _url = `invoice/${invoiceId}/payment?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(paymentObj),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

/**Function to delete payments */
export const deletePayments = (id) => {
    const _url = `invoice/payment/${id}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).delete(_url);
            if (response.status === 200) {
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

export const AddInvoice = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).post(_url,JSON.stringify(_invoiceObj),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                _invoiceObj.invoiceId= response.data.response.invoiceId||'';
                _invoiceObj.callStatus=response.data.response.callStatus||'';
                 dispatch({
                     type: actionTypes.GET_INVOICE_INFO,
                     _invoiceObj:_invoiceObj
                 });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

export const updateInvoice = (invoiceId) => {
    let _requestedObj={};
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    _requestedObj = _invoiceObj;
    let flag = _requestedObj.isManualClear;
    if(!flag)
    _requestedObj.isManualClear=((_requestedObj.clearedTime||null)==null?false:true);
    else
    _requestedObj.isManualClear=false;
    const _url = `invoice/${_requestedObj.invoiceId}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(_requestedObj),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                _invoiceObj.duplicateCopyCount=0;
                _invoiceObj.isManualClear=(flag?flag:_requestedObj.isManualClear?true:false)
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj:_invoiceObj
                });  
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

export const serviceCallInfoFieldChange = (objName, keyName, value) => {
    return (dispatch) => {
        let _invoiceObj = {}
        _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
        if (objName !== '') {
            let objToModified = _invoiceObj[objName];
            objToModified[keyName] = value;
            _invoiceObj[objName] = objToModified;
        } else {
            _invoiceObj[keyName] = value;
        }
        dispatch({
            type: actionTypes.GET_INVOICE_INFO,
            _invoiceObj: _invoiceObj
        })
    }
}
export const validateVin = (vinNo) => {
    let vinData = JSON.stringify({
        "vinLookupReq": { "vin": vinNo }
      });
    const _url = `VINLookupService/getVehicleDetails?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.SERVICE).post(_url,vinData,{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'appId': 'AQWS1234'
                }});
            if (response.status === 200) {
                let _invoiceObj = {}
                _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
                    let objToModified = _invoiceObj['invoiceVehicle'];
                    objToModified['vehicle_Year'] = response.data.vehicleDetails.modelYear;
                    objToModified['vehicle_Make'] = response.data.vehicleDetails.make;
                    objToModified['vehicle_Model'] = response.data.vehicleDetails.model;
                    objToModified['vehicle_Style'] = response.data.vehicleDetails.bodyType;
                    _invoiceObj['invoiceVehicle'] = objToModified;
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj: _invoiceObj
                })
                return {statusCode:response.status,statusMsg:response.data.message}
            } else {
                return {statusCode:null,statusMsg:response.data.message}
            }
        }
        catch (ex) {
            return {statusCode:null,statusMsg:ex.message}
        }
    }
}
export const getVehicleDetails= (vinNo) => {
    let vinData = JSON.stringify({
        "vinLookupReq": { "vin": vinNo }
      });
    const _url = `VINLookupService/getCompleteVehicleDetails?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.SERVICE).post(_url,vinData,{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'appId': 'AQWS1234'
                }});
            if (response.status === 200) {
                let listObj = [];
                listObj.push(response.data)
                dispatch({
                    type: actionTypes.GET_VIN_DETAILS,
                    vinDetails: listObj
                })
            } else {
                dispatch({
                    type: actionTypes.GET_VIN_DETAILS,
                    vinDetails: []
                })
            }
        }
        catch (ex) {
            dispatch({
                type: actionTypes.GET_VIN_DETAILS,
                vinDetails: []
            })
        }
    }
}
export const changeLocation = (type, AddKey, objAddress) => {
    return (dispatch) => {
        let _invoiceObj = {};
        let _keyPair = [];
        if (type.toUpperCase() === 'PICKUP')
            _keyPair = pickUpkeyValPair;
        else
            _keyPair = dropOffKeyValPair;
        _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
        _invoiceObj.isAddressChange=true;
        if (AddKey === '' && _keyPair.length > 0) {
            _keyPair.map((key, index) => {
                _invoiceObj[Object.keys(key)] = objAddress[Object.values(key)];
            })
            if ((_invoiceObj.dropOffLocation || '') === '')
                _invoiceObj.invoiceVehicle.isImpound = 0;
            else
                _invoiceObj.invoiceVehicle.isImpound = dispatch(checkLocationType(_invoiceObj.dropOffLocation));
        } else {
            let valuePair = _keyPair.find((key) => (Object.values(key)) == AddKey);
            if (valuePair)
                _invoiceObj[Object.keys(valuePair)[0]] = objAddress;
            if(AddKey=='state' && type.toUpperCase() === 'PICKUP')
                _invoiceObj.pickUpAddr_Address_State=sortName(objAddress)
            else if(AddKey=='state')
                _invoiceObj.dropOffAddr_Address_State=sortName(objAddress);
        }
        dispatch({
            type: actionTypes.GET_INVOICE_INFO,
            _invoiceObj: _invoiceObj
        })
    }
}
/* function to set default values if required  */
export const setDefaultsValues= (callType) => {
    return (dispatch) => {
        let accountReqData = (store.getState()).invoiceInfo.accountReqData || {};
        let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
        if(accountReqData.accountDefaultLocation||null !=null){
            let accountDefault = accountReqData.accountDefaultLocation;
            _invoiceObj.priority=accountReqData.priority
            _invoiceObj.disableAutoAssign=accountReqData.isAutoDispatch==1?0:1;
            _invoiceObj.accountId=accountDefault.accountId;
            _invoiceObj.pickUpAddr_Address_Line1=accountDefault.pickupAddr_AddressLine1;
            _invoiceObj.pickUpAddr_Address_City=accountDefault.pickupAddr_City;
            _invoiceObj.pickUpAddr_Address_State=callType=='regular'?sortName(accountDefault.pickupAddr_State):accountDefault.pickupAddr_State;
            _invoiceObj.pickUpAddr_Address_Zip= accountDefault.pickupAddr_Zip;
            _invoiceObj.pickUpLocation= accountDefault.pickupAddr_Location;
            _invoiceObj.pickUpAddr_Address_Landmark= accountDefault.pickupAddr_LandMark;
            _invoiceObj.pickupAddr_Country= accountDefault.pickupAddr_Country;
            _invoiceObj.dropOffAddr_Address_Line1= accountDefault.droffOffAddr_AddressLine1;
            _invoiceObj.dropOffAddr_Address_City= accountDefault.droffOffAddr_City;
            _invoiceObj.dropOffAddr_Address_State= callType=='regular'?sortName(accountDefault.droffOffAddr_State):accountDefault.droffOffAddr_State;
            _invoiceObj.dropOffAddr_Address_Zip=accountDefault.droffOffAddr_Zip;
            _invoiceObj.dropOffLocation=accountDefault.droffOffAddr_Location;
            _invoiceObj.dropOffAddr_Address_Landmark= accountDefault.droffOffAddr_LandMark;
            _invoiceObj.serviceCallInfo.callerName= accountDefault.defCallerName;
            _invoiceObj.serviceCallInfo.callerPhoneNo=accountDefault.defCallerPhone;
            _invoiceObj.serviceCallInfo.callerEmailId=accountDefault.defCallerEmail;
            _invoiceObj.contactPerson=accountDefault.defContactName;
            _invoiceObj.contactPhoneNo=accountDefault.defContactPhone;
            _invoiceObj.invoiceBilling.billingTo=(accountDefault.billTo||0)==0?accountDefault.accountId:accountDefault.billTo;
            _invoiceObj.pick_up_Lat_Lang=accountDefault.pickup_lat_long;
            _invoiceObj.drop_Lat_Lang=accountDefault.dropoff_lat_long;
            _invoiceObj.invoiceBilling.pONo=accountDefault.pono;
        }
        dispatch({
            type: actionTypes.GET_INVOICE_INFO,
            _invoiceObj: _invoiceObj
        })
    }
}
/* function to fill caller info from caller list*/
export const selectCallerInfoByCallerName = (name) => {
    return (dispatch) => {
        let accountReqData = (store.getState()).invoiceInfo.accountReqData || {};
        let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
        if (accountReqData.accountContacts.length > 0) {
            let obj = (accountReqData.accountContacts || []).find((item) => (item.contact_Name).toUpperCase() === name.toUpperCase());
            if (obj) {
                _invoiceObj.serviceCallInfo.callerName = obj.contact_Name;
                _invoiceObj.serviceCallInfo.callerEmailId = obj.contact_EmailId
                _invoiceObj.serviceCallInfo.callerPhoneNo = obj.contact_phone
            }
            dispatch({
                type: actionTypes.GET_INVOICE_INFO,
                _invoiceObj: _invoiceObj
            });
        }
    }
}
export const clearInvoiceRec = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_INVOICE_INFO,
            _invoiceObj: {
                "invoiceId": null,
                "displayInvoiceId":null,
                "serviceCallId": null,
                "accountId": '',
                "accountRatePlanId": null,
                "priority": null,
                "reason": "",
                "contactPerson": "",
                "contactPhoneNo": "",
                "callStatus": "",
                "requestTime": null,
                "dispatchTime": null,
                "acceptedTime": null,
                "enrouteTime": null,
                "arrivedTime": null,
                "hookedTime": null,
                "dropOffTime": null,
                "clearedTime": null,
                "elapsedTime": null,
                "callHold": 0,
                "callHoldDate": null,
                "callHoldTime": null,
                "rehookInvoiceId": null,
                "pickUpLocation": "",
                "pickUpAddr_Address_Line1": "",
                "pickUpAddr_Address_Line2": "",
                "pickUpAddr_Address_Landmark": "",
                "pickUpAddr_Address_City": "",
                "pickUpAddr_Address_State": "",
                "pickUpAddr_Address_Country": "",
                "pickUpAddr_Address_Zip": "",
                "dropOffLocation": "",
                "dropOffAddr_Address_Line1": "",
                "dropOffAddr_Address_Line2": "",
                "dropOffAddr_Address_Landmark": "",
                "dropOffAddr_Address_Type": "",
                "dropOffAddr_Address_City": "",
                "dropOffAddr_Address_State": "",
                "dropOffAddr_Address_Zip": "",
                "dropOffAddr_Address_Country": "",
                "dropOff_GPS_Address": null,
                "truckNo": 0, "towType": null,
                "driverId": null,
                "assignedBy": null,
                "clearedBy": null,
                "invoiceCreatedDate": "", 
                "enroute_Lat_Lang": null,
                "hooked_Lat_Lang": null,
                "dropoff_Lat_Lang": null,
                "enrouteLocation": null,
                "pickupToDropoffTime": "",
                "statusROPhoneWhenClear": 0,
                "statusROEmailWhenClear": 0,
                "reviewNote": null,
                "reviewLocation": null,
                "isDispatchCleared": null,
                "sourceReqBy": "",
                "salvageInvoiceId": null,
                "towBackSrcInvoiceId": null,
                "srcForSalvage": null,
                "srcForRehook": null,
                "idCompany": null,
                "pick_up_Lat_Lang": "",
                "drop_Lat_Lang": "",
                "dispatchClearedTime": null,
                "etaTime": "null",
                "isAddressChange": true,
                "duplicateCopyCount": null,
                "sourceInvoiceId": null,
                "callType": "",
                "isManualClear": false,
                "disableAutoAssign":false,
                "isArsCall":0,
                invoiceVehicle: {
                    "invoiceId": null,
                    "vehicle_Info_DLNo": "",
                    "vehicle_VIN": "",
                    "noVIN": 0,
                    "noVINReason": "",
                    "vehicle_Year": "",
                    "vehicle_Make": "",
                    "vehicle_Model": "",
                    "vehicle_Color": "",
                    "vehicle_Style": "",
                    "vehicle_State": "",
                    "vehicle_Country": "",
                    "vehicle_Plate_No": "",
                    "vehicle_Plate_Expiry": "",
                    "vehicle_Key_Location": "",
                    "vehicle_ODM_Reading": "",
                    "vehicle_Status": "",
                    "vehicle_Defects": "",
                    "vehicle_Comm_Unit_No": "",
                    "vehicle_InsuranceClaimNo": "",
                    "isDriverIsOwner": 0,
                    "noInfo": 0,
                    "vehicle_Owner_FirstName": "",
                    "vehicle_Owner_LastName": "",
                    "vehicle_Owner_Addr_Line1": "",
                    "vehicle_Owner_Addr_Line2": null,
                    "vehicle_Owner_City": "",
                    "vehicle_Owner_State": "",
                    "vehicle_Owner_Zip": "",
                    "vehicle_Owner_Country": null,
                    "vehicle_Owner_PhoneNo": "",
                    "vehicle_keys": null,
                    "vehicle_Radio": null,
                    "police_impound": "",
                    "lotLocation": "",
                    "vehicle_Owner_Email": "",
                    "vehicle_OR_DR_No": "",
                    "isImpound": 0,
                    "outOfStorageDate": "",
                    "vehicle_Image_URL": null,
                    "isAppCleared": 0, "aaFile": null,
                    "idCompany": null
                },
                serviceCallInfo: {
                    "serviceCallId": null,
                    "accountId": 0,
                    "serviceCallType": "regular",
                    "vehiclesToRelocate": null,
                    "callRecevierId": "",
                    "callRecevierFullName": "",
                    "callerName": "",
                    "callerPhoneNo": "",
                    "callerEmailId": "",
                    "callCreatedTime": "",
                    "idCompany": null
                },
                invoiceBilling: {
                    "invoiceId": null,
                    "accountId": null,
                    "billingTo": null,
                    "acountRatePlan": null,
                    "totalTimeToClear": null,
                    "unloadedMiles": 0.0,
                    "loadedMiles": 0.0,
                    "totalMiles": 0.0,
                    "unloadedMilesRate": 0.0,
                    "loadedMilesRate": 0.0,
                    "towCharge": 0.0,
                    "laborCharge": 0.0,
                    "mileageCharge": 0.0,
                    "winch_Charge": 0.0,
                    "storageCharge": 0.0,
                    "storageDays": 0.0,
                    "miscChargeLabel": null,
                    "miscCharge": 0.0,
                    "gateCharge": 0.0,
                    "userDefined_Charge": "",
                    "tax": 0.0,
                    "invoiceHourlyRate": 0.0,
                    "invoiceCommFlatRate": 0.0,
                    "invoiceCommHourlyRate": 0.0,
                    "invoiceCommHours": 0.0,
                    "invoiceCommPercentage": 0.0,
                    "invoiceCommMinPayPerCall": 0.0,
                    "commission": 0.0,
                    "uncollectedRevenue": 0.0,
                    "extraCollectedRevenue": 0.0,
                    "supervisorFee": 0.0,
                    "refrigerationFee": 0.0,
                    "dryRunFee": 0.0,
                    "advancedPayout": 0.0,
                    "totalCharge": 0.0,
                    "serviceBillableHours": "0.0",
                    "pONo": "",
                    "serviceAdvisorName": null,
                    "repairOrderNo": null,
                    "paymentType": null,
                    "flatDiscount": 0.0,
                    "discountOnTow": 0.0,
                    "discountOnLabor": 0.0,
                    "discountOnMileage": 0.0,
                    "discountOnWinch": 0.0,
                    "discountOnMisc": 0.0,
                    "discountOnStorage": 0.0,
                    "totalDiscount": 0.0,
                    "isPaid": 0,
                    "invoiceDate": "",
                    "billDate": null,
                    "isPoliceHold": 0,
                    "isVerifiedAndLocked": 0,
                    "paymentLocation": null,
                    "verifiedForReview": 0,
                    "idCompany": null,
                    "rehookedInvoiceId": null,
                    "calendarDays": 0.0,
                    "paidNoOfDays": 0,
                    "storageAfterLimitCharge": 0.0,
                    "balanceAmt": 0.0,
                    "paymentAmt": 0.0,
                    "ratePlan": {
                        "accountRatePlanId": 0,
                        "accountPlanRateCd": "",
                        "idCompany": null,
                        "idCorporation": 0,
                        "idAccount": 0,
                        "accountPlanRateName": "0",
                        "accountPlanRateTowType": "",
                        "ratePlanStartDate": "",
                        "ratePlanEndDate": "",
                        "sync_with_default": 0,
                        "defaultRatePlanId": 0,
                        "min_service_charge": 0.0,
                        "service_flat_rate": 0.0,
                        "service_hourly_rate": 0.0,
                        "service_min_hours": 0.0,
                        "service_increment_hours": 0.0,
                        "service_free_hours": 0.0,
                        "service_start_from": "",
                        "service_end_by": "",
                        "service_mileage_loaded_limit1": 0.0,
                        "service_mileage_loaded_limit2": 0.0,
                        "service_mileage_loaded_limit1_charge": 0.0,
                        "service_mileage_loaded_limit2_charge": 0.0,
                        "service_mileage_loaded_charge_after_limit": 0.0,
                        "service_mileage_unloaded_limit1": 0.0,
                        "service_mileage_unloaded_limit2": 0.0,
                        "service_mileage_unloaded_limit1_charge": 0.0,
                        "service_mileage_unloaded_limit2_charge": 0.0,
                        "service_mileage_unloaded_charge_after_limit": 0.0,
                        "storage_period": "",
                        "storage_limit1_days": 0.0,
                        "storage_limit1_charge": 0.0,
                        "storage_limit2_days": 0.0,
                        "storage_limit2_charge": 0.0,
                        "storage_custom_period": 0.0,
                        "storage_each_day_after_limit2_charge": 0.0,
                        "driver_commision_type_id": 0,
                        "free_hours": 0,
                        "threshold": "",
                        "customBehaviorId": null,
                        "maxStorageCharge": null,
                        "in_storage_time":
                            "Dropped"
                    },
                    "pcc36Charge": null
                },
                invoiceNotes: [],
                invoiceTitleProcess: null,
                invoiceRelease: null,
                invoicePayments: [],
                invoiceAttachments: [],
                auctionListInvoice: null,
                eligibilityStatus: {},
                eligibilityStatus:{
                    "isSrcInvoiceTowback":false,
                    "isSourceOfSalvage":false,
                    "isSalvageElig":false,
                    "isRehookOrTowbackElig":false,
                    "isSrcInvoiceRehook":false
                }
            }
        });
        let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
        _invoiceObj.invoiceCreatedDate=moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        _invoiceObj.requestTime=moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        dispatch({
            type: actionTypes.GET_INVOICE_INFO,
            _invoiceObj: _invoiceObj
        });
        dispatch({
            type: actionTypes.GET_REQ_ACCOUNT_INFO,
            accountReqData: undefined
        });
    }
}
  /* MILEAGE MANUAL CALCULATION */
  export const mileageManualCal=(key,value)=> {
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let mileageChargeOld = _invoiceObj.invoiceBilling.mileageCharge||0.00;
    _invoiceObj.invoiceBilling[key]=parseFloat(value||0);
    const {invoiceBilling} = _invoiceObj;
    const {ratePlan} = invoiceBilling;
    let mileageCharge = 0.00;
    let loadedMileageCharge = 0.00;
    let unloadedMileageCharge = 0.00;
    let loadLimit1 = ratePlan.service_mileage_loaded_limit1 || 0.00;
    let loadLimit2 = ratePlan.service_mileage_loaded_limit2 || 0.00;
    let loadLimit1Charge = ratePlan.service_mileage_loaded_limit1_charge || 0.00;
    let loadLimit2Charge = ratePlan.service_mileage_loaded_limit2_charge || 0.00;
    let loadLimitAfterCharge = Number(invoiceBilling.loadedMilesRate || 0.00);
    let unloadLimit1 = ratePlan.service_mileage_unloaded_limit1 || 0.00;
    let unloadLimit2 = ratePlan.service_mileage_unloaded_limit2 || 0.00;
    let unloadLimit1Charge = ratePlan.service_mileage_unloaded_limit1_charge || 0.00;
    let unloadLimit2Charge = ratePlan.service_mileage_unloaded_limit2_charge || 0.00;
    let unloadLimitAfterCharge = Number(invoiceBilling.unloadedMilesRate || 0.00);
    let loadedMiles = Number(invoiceBilling.loadedMiles || 0.00);
    let unloadedMiles = Number(invoiceBilling.unloadedMiles || 0.00)

    if (loadedMiles > 0) {
      if (loadLimit1 >= loadedMiles) {
        loadedMileageCharge = loadedMiles * loadLimit1Charge;
      }
      else if ((loadLimit1 + loadLimit2) >= loadedMiles) {
        loadedMileageCharge = loadLimit1 * loadLimit1Charge;
        loadedMileageCharge += (loadedMiles - loadLimit1) * loadLimit2Charge;
      }
      else {
        loadedMileageCharge = loadLimit1 * loadLimit1Charge;
        loadedMileageCharge += loadLimit2 * loadLimit2Charge;
        loadedMileageCharge += (loadedMiles - loadLimit2 - loadLimit1) * loadLimitAfterCharge;
      }

    }

    if (unloadedMiles > 0) {
      if (unloadLimit1 >= unloadedMiles) {
        unloadedMileageCharge = unloadedMiles * unloadLimit1Charge;
      }
      else if ((unloadLimit1 + unloadLimit2) >= unloadedMiles) {
        unloadedMileageCharge = unloadLimit1 * unloadLimit1Charge;
        unloadedMileageCharge += (unloadedMiles - unloadLimit1) * unloadLimit2Charge;
      }
      else {
        unloadedMileageCharge = unloadLimit1 * unloadLimit1Charge;
        unloadedMileageCharge += unloadLimit2 * unloadLimit2Charge;
        unloadedMileageCharge += (unloadedMiles - unloadLimit2 - unloadLimit1) * unloadLimitAfterCharge;
      }
    }
    mileageCharge = loadedMileageCharge + unloadedMileageCharge;
    _invoiceObj.invoiceBilling.mileageCharge=mileageCharge;
    _invoiceObj.invoiceBilling.balanceAmt=_invoiceObj.invoiceBilling.balanceAmt+mileageCharge-mileageChargeOld;
    _invoiceObj.invoiceBilling.totalCharge=_invoiceObj.invoiceBilling.totalCharge+mileageCharge-mileageChargeOld;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
  }}
   /* function to handle changes in verious charges   */
   export const handleCharge=(key,value)=> {
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let oldCharge = _invoiceObj.invoiceBilling[key]||0.00;
    _invoiceObj.invoiceBilling[key]=parseFloat(value||0);
    let newCharge =_invoiceObj.invoiceBilling[key]||0.00;
    if(key=='flatDiscount'){
        newCharge=-newCharge;
        oldCharge=-oldCharge;
    }
    if(key!='flatDiscount')
    _invoiceObj.invoiceBilling.totalCharge=_invoiceObj.invoiceBilling.totalCharge+newCharge-oldCharge;
    _invoiceObj.invoiceBilling.balanceAmt=_invoiceObj.invoiceBilling.balanceAmt+newCharge-oldCharge;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
  }}
  export const addCutomCharge=()=>{
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let firstOption='';
    const{invoiceBilling} = _invoiceObj;
    if(invoiceBilling.customChargesOptions.length>0)
    firstOption =invoiceBilling.customChargesOptions[0].optionText;
    _invoiceObj.invoiceBilling.userDefined_Charge=invoiceBilling.userDefined_Charge.concat(firstOption+',0|');
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
  }
}
export const manipulateCustomCharge=(index1,index2,value)=>{
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let finalVal='';
    let finalString='';
    let oldCharge=0.0;
    let newCharge=0.0;
    const{ invoiceBilling } = _invoiceObj;
    oldCharge=sumOfCustomCharge(invoiceBilling.userDefined_Charge||'');
    if(invoiceBilling.userDefined_Charge !="" && value !=''){
        let arrayCharge = invoiceBilling.userDefined_Charge.split('|');
        let valueToChange = arrayCharge[index1];
       if(index2==0)
       finalVal=value.concat(','+valueToChange.split(',')[1])
       else
       finalVal=(valueToChange.split(',')[0]).concat(','+value);
       arrayCharge[index1]=finalVal;
       finalString=arrayCharge.join('|');
       _invoiceObj.invoiceBilling.userDefined_Charge=finalString;
    }
    newCharge=sumOfCustomCharge(finalString)||'';
    _invoiceObj.invoiceBilling.balanceAmt=_invoiceObj.invoiceBilling.balanceAmt+newCharge-oldCharge;
    _invoiceObj.invoiceBilling.totalCharge=_invoiceObj.invoiceBilling.totalCharge+newCharge-oldCharge;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
}
}
export const deleteCustomCharge=(index)=>{
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let finalString = "";
    let oldCharge=0.0;
    let newCharge=0.0;
    const{ invoiceBilling } = _invoiceObj;
    oldCharge=sumOfCustomCharge(invoiceBilling.userDefined_Charge||'');
    if(invoiceBilling.userDefined_Charge !="" ){
        let arrayCharge = invoiceBilling.userDefined_Charge.split('|');
        arrayCharge.splice(index, 1);
       finalString=arrayCharge.join('|');
       _invoiceObj.invoiceBilling.userDefined_Charge=finalString;
    }
    newCharge=sumOfCustomCharge(finalString||'');
    _invoiceObj.invoiceBilling.balanceAmt=_invoiceObj.invoiceBilling.balanceAmt+newCharge-oldCharge;
    _invoiceObj.invoiceBilling.totalCharge=_invoiceObj.invoiceBilling.totalCharge+newCharge-oldCharge;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
}
}
const sumOfCustomCharge=(customCharge)=>{
    let count=0.0;
    if(customCharge !='' && customCharge !=',|')
    {
       let chargeArray = customCharge.split('|');
       chargeArray=chargeArray.filter(v=>v!='');
       chargeArray.map((item)=>{
           count+=parseFloat(item.split(',')[1]||0);
       })

    }
return count;
}

export const handleSubInvoice =(type,accountId)=>{
let _invoiceObjOld = (store.getState()).invoiceInfo._invoiceObj || {};
let callType='towback';
if(type.toUpperCase()=='REHOOK')
callType='re-hook';
return async(dispatch) => {
/**Clear invoice data */
dispatch(clearInvoiceRec())
/* fetch account info and setup of default invoice*/
const rep = await dispatch(fetchReQAccountInfo(accountId, _invoiceObjOld.invoiceId, callType, false));   
let accountReqData = (store.getState()).invoiceInfo.accountReqData || {};
let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
let rateplanObj = accountReqData.ratePlanOptions.find((item) => { return (item.optionText.replace(/ /g,'')).toUpperCase() === type.toUpperCase() })
   if(callType=='re-hook')
    _invoiceObj.rehookInvoiceId = _invoiceObjOld.invoiceId;
    else
    _invoiceObj.towBackSrcInvoiceId = _invoiceObjOld.invoiceId;
    _invoiceObj.sourceInvoiceId =  _invoiceObjOld.invoiceId;
    _invoiceObj.sourceReqBy = _invoiceObjOld.accountId;
    _invoiceObj.callType = callType;
    _invoiceObj.serviceCallInfo.serviceCallType=callType;
    _invoiceObj.reason=type.toUpperCase();
    _invoiceObj.accountRatePlanId=rateplanObj.optionVal;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
    /**fetch default billing and vehicle info */
    dispatch(defaultInvoiceInfo());
}
}
/*this method is used to handle release field changes */
export const handleReleaseChange=(key,value)=>{
    let releaseObj= {invoiceId:null,releaseName:'',releaseTo:'',releaseAccountName:'',releaseDate:null};
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj;
    let invoiceRelease = _invoiceObj.invoiceRelease;
    if(invoiceRelease==null && invoiceRelease==undefined)
     invoiceRelease=releaseObj;
     invoiceRelease[key]=value;
     _invoiceObj.invoiceRelease=invoiceRelease;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
  }
}
/** functon used to handle title field change */
export const handleTitleProcess=(key,value)=>{
    let titeleObj= {invoiceId:null,avrDate:null,transAuthDate:null,titleDate:null};
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj;
    let invoiceTitleProcess = _invoiceObj.invoiceTitleProcess;
    if(invoiceTitleProcess==null && invoiceTitleProcess==undefined)
    invoiceTitleProcess=titeleObj;
    invoiceTitleProcess[key]=value;
     _invoiceObj.invoiceTitleProcess=invoiceTitleProcess;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
  }
}
export const releaseInvoice = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    _invoiceObj.invoiceRelease.invoiceId =_invoiceObj.invoiceId;
    let releaseData = _invoiceObj.invoiceRelease;
    const _url = `invoice/${_invoiceObj.invoiceId}/releaseInvoice?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(releaseData),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                _invoiceObj.invoiceBilling.totalCharge = response.data.response.totalCharge;
                _invoiceObj.invoiceBilling.calendarDays = response.data.response.calenderDays;
                _invoiceObj.invoiceBilling.storageCharge = response.data.response.storageCharge;
                _invoiceObj.invoiceBilling.paidNoOfDays = response.data.response.storageDays;
                _invoiceObj.invoiceVehicle.isImpound = 0;
               dispatch({
                type: actionTypes.GET_INVOICE_INFO,
                _invoiceObj: _invoiceObj
                
            });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

/**this method used get commission   */
export const getInvoiceComm = (isDefault) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice/billingCommission/${_invoiceObj.invoiceId}?isDefault=${isDefault||false}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).get(_url);
            if (response.status === 200) {
               dispatch({
                type: actionTypes.GET_INVOICE_COMM,
                billingCommission: response.data.response
                
            });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

/**this method used save invoice commission   */
export const saveInvoiceComm = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let billingCommission = (store.getState()).invoiceInfo.billingCommission || {};
    const _url = `invoice/billingCommission/${_invoiceObj.invoiceId}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(billingCommission),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
               dispatch({
                type: actionTypes.GET_INVOICE_COMM,
                billingCommission: response.data.response
                
            });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

/**this method used to return invoice under storage */
export const returnToStorage = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice/${_invoiceObj.invoiceId}/returnToStorage?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).delete(_url);
            if (response.status === 200) {
                _invoiceObj.invoiceRelease=null;
                _invoiceObj.invoiceBilling.totalCharge = response.data.response.totalCharge;
                _invoiceObj.invoiceBilling.calendarDays = response.data.response.calenderDays;
                _invoiceObj.invoiceBilling.storageCharge = response.data.response.storageCharge;
                _invoiceObj.invoiceBilling.paidNoOfDays = response.data.response.storageDays;
                _invoiceObj.invoiceVehicle.isImpound = 1;
               dispatch({
                type: actionTypes.GET_INVOICE_INFO,
                _invoiceObj: _invoiceObj
                
            });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}
/** functon used to handle auction  selection */
export const handleAuctionSelect=(id)=>{
    let auctionObj= {id:null,invoiceId:null,auctionListId:null,auctionListName:null,auctionDate:null};
    return (dispatch) => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj;
    let existingObj = _invoiceObj.auctionListInvoice;
    if(existingObj!==null && existingObj != undefined)
    auctionObj=existingObj;
    let selectedObj = (_invoiceObj.auctionLists||[]).find((item)=>item.auctionListId==id);
    if(selectedObj)
        {
            auctionObj.invoiceId = _invoiceObj.invoiceId;
            auctionObj.auctionListId = selectedObj.auctionListId;
            auctionObj.auctionListName = selectedObj.auctionListName;
            auctionObj.auctionDate = selectedObj.date;
        }
    _invoiceObj.auctionListInvoice=auctionObj;;
    dispatch({
        type: actionTypes.GET_INVOICE_INFO,
        _invoiceObj: _invoiceObj
    });
  }
}
export const deleteAuction = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice/${_invoiceObj.invoiceId}/auction?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).delete(_url);
            if (response.status === 200) {
                _invoiceObj.auctionListInvoice=null;
               dispatch({
                type: actionTypes.GET_INVOICE_INFO,
                _invoiceObj: _invoiceObj
                
            });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}
/**this function is used for add invoice to auction */
export const addUpdateAuction = () => {
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    let auctionObj = _invoiceObj.auctionListInvoice;
    const _url = `invoice/${_invoiceObj.invoiceId}/auction?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(auctionObj),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    }
}

export const calculateComm=(key,value)=>{
    return (dispatch) => {
    let billingCommission = (store.getState()).invoiceInfo.billingCommission || {}
    billingCommission[key]=value;
    let hourlyRate = 0.00;
    let CommHourlyRate = 0.00;
    let minhr = (billingCommission.minHour || 0.00) * 60
    let incrHr = (billingCommission.incrementHours || 0.00) * 60
    let totalTime = parseInt((billingCommission.invoiceCommHours || '00:00').split(':')[0] * 60) + parseInt((billingCommission.invoiceCommHours || '00:00').split(':')[1]);
    if (Number(billingCommission.invoiceCommHourlyRate || 0) != 0)
      hourlyRate = Number(billingCommission.invoiceCommHourlyRate || 0.00);
    if ((billingCommission.invoiceCommHours != "" && billingCommission.invoiceCommHours != "00:00")) {
      if (totalTime <= minhr) {
        CommHourlyRate = minhr * hourlyRate
        billingCommission.commissionHourlyRate=CommHourlyRate;
      } else if (incrHr == 0) {
        CommHourlyRate = minhr * (hourlyRate || 0.00)
        billingCommission.commissionHourlyRate=CommHourlyRate;
      }
      else {
        let totalHourInMinute1 = (Math.ceil(totalTime / incrHr) * (incrHr));
        CommHourlyRate = ((totalHourInMinute1 / 60) * hourlyRate);
        billingCommission.commissionHourlyRate=CommHourlyRate;
      }
    } else {
        billingCommission.commissionHourlyRate=0;
    }
    let percentageValue = 0;
    let commission = 0;
    if (Number(billingCommission.invoiceCommPercentage || 0) !== 0)
      percentageValue = Number(billingCommission.invoiceCommPercentage || 0);
    let commHourlyRate1 = CommHourlyRate || 0;
    let flatRateFinal = 0
    if (Number(billingCommission.invoiceCommFlatRate || 0.00) !== 0)
      flatRateFinal = Number(billingCommission.invoiceCommFlatRate || 0.00);
    commission = ((Number(commHourlyRate1) + Number(percentageValue) + flatRateFinal));
    billingCommission.commission=commission;
    if (Number(billingCommission.invoiceCommPercentage||0) < Number(billingCommission.invoiceCommMinPayPerCall||0)) {
        billingCommission.commission=commission =  parseFloat(billingCommission.invoiceCommMinPayPerCall||0);
    }
    dispatch({
        type: actionTypes.GET_INVOICE_COMM,
        billingCommission: billingCommission
        
    });
}
}
/** this method is used to calculate mileage charge if locaton is changed */
export const reCalMileage=()=>{
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice/getMilesMileage?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(_invoiceObj),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                _invoiceObj.invoiceBilling.loadedMiles=response.data.response.loadedMiles||0.0;
                _invoiceObj.invoiceBilling.unloadedMiles=response.data.response.unloadedMiles||0.0;
                _invoiceObj.invoiceBilling.totalMiles=response.data.response.totalMiles||0.0;
                _invoiceObj.invoiceBilling.mileageCharge=response.data.response.mileageCharge||0.0;
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj: _invoiceObj
                    
                });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    } 
}
export const checkLocationType = (location) => {
    return (dispatch) => {
    let stateList = (store.getState()).location.locationList || [];
    let obj = stateList.find(loc => loc.location == location);
    if (obj)
    return (obj.impoundLocation||0);   
    else 
    return 0;
}
}

/** this method is used to calculate mileage charge if locaton is changed */
export const reviewInvoice=()=>{
    let _invoiceObj = (store.getState()).invoiceInfo._invoiceObj || {};
    const _url = `invoice/${_invoiceObj.invoiceId}/reviewInvoice?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify({
                invoiceId:_invoiceObj.invoiceId,
                note:_invoiceObj.reviewNote,
                location:_invoiceObj.reviewLocation}),{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                _invoiceObj.invoiceBilling.isVerifiedAndLocked=0;
                _invoiceObj.invoiceBilling.verifiedForReview=0;
                dispatch({
                    type: actionTypes.GET_INVOICE_INFO,
                    _invoiceObj: _invoiceObj
                    
                });
               return response.data;
            } else {
                return response.data;
            }
        }
        catch (error) {
            return error;
        }
    } 
}
export const clearPrintEmailInvoice = () => {
    return (dispatch) => {
        let printEmailReceipt = (store.getState()).invoiceInfo.printEmailReceipt || {};
        printEmailReceipt.emailFrom="noreply@allcitytowing.com";
        printEmailReceipt.emailTo="";
        printEmailReceipt.emailText="";
        printEmailReceipt.base64Data="";
        printEmailReceipt.extension="pdf";
        printEmailReceipt.attachments=[];
        printEmailReceipt.systemNotes=[];
        printEmailReceipt.userNotes=[];
        dispatch({
            type: actionTypes.PRINT_EMAIL_RECEIPT,
            printemailObj: printEmailReceipt
            
        });
    }
}
export const saveEmailInvoice = (name, value, ext)=>{
    return (dispatch) => {
        let printEmailReceipt = (store.getState()).invoiceInfo.printEmailReceipt || {};
        printEmailReceipt[name]=value;
        if((ext || '') !== ''){
            printEmailReceipt['extension']=ext;
        }
        dispatch({
            type: actionTypes.PRINT_EMAIL_RECEIPT,
            printemailObj: printEmailReceipt
            
        });
    }
}
export const savePrintEmailInvoice = (obj, type, status) => {
    return (dispatch) => {
    let printEmailReceipt = (store.getState()).invoiceInfo.printEmailReceipt || {};
    let attachmentList = printEmailReceipt.attachments || [];
    let systemNotesList = printEmailReceipt.systemNotes || [];
    let userNotesList = printEmailReceipt.userNotes || [];
    if(status){
        if(type === 'attachments'){
            attachmentList.push(obj.attachmentPath);
        }
        if(type === 'notes'){
            userNotesList.push(obj);
        }
    }else{
        if(type === 'attachments'){
            let index = attachmentList.indexOf(obj.invoiceNotesId);
            attachmentList.splice(index, 1);
        }
        if((type === 'notes')){
            let index = userNotesList.findIndex(x => x.invoiceNotesId === obj.invoiceNotesId);
            userNotesList.splice(index, 1);
        }
    }
    printEmailReceipt.attachments = attachmentList;
    printEmailReceipt.systemNotes = systemNotesList;
    printEmailReceipt.userNotes = userNotesList;
    dispatch({
        type: actionTypes.PRINT_EMAIL_RECEIPT,
        printemailObj: printEmailReceipt
        
    });
}
}
export const printoremailReceipt = (invoiceId, status) => {
    let printEmailReceipt = (store.getState()).invoiceInfo.printEmailReceipt;
    let header = '';
    if(status){
        header = {
            headers : {'Content-Type': 'application/json'}
        }
    }else{
        header={
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    const _url = `invoice/${invoiceId}/printInvoiceReceipt?companyId=${getCompanyId()}&access_token=${getAccessToken()}&isSend=${status}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.MANAGE).put(_url,JSON.stringify(printEmailReceipt),
            header);
            if (response.status === 200) {
                if(status){
                    return response.data;
                }else{
                    return response;
                }
               
            } else {
                if(status){
                    return response.data;
                }else{
                    return response;
                }
            }
        }
        catch (error) {
            dispatch({
                type: actionTypes.PRINT_EMAIL_RECEIPT,
                printemailObj: undefined
                
            });
            return error;
        }
    }
}
export const getRatePlanById=(ratePlanId)=>{
    const _url = `dashboard/ratePlanById/${ratePlanId}?access_token=${getAccessToken()}`;
    return async(dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.MANAGE).get(_url);
            if (response.status === 200) {
                dispatch({
                    type: actionTypes.RATEPLAN_INFO_DETAILS,
                    _rateplanInfo: response.data.response
                });
            } else {
                dispatch({
                    type: actionTypes.RATEPLAN_INFO_DETAILS,
                    _rateplanInfo: undefined
                });
            }
        }
        catch (error) {
            dispatch({
                type: actionTypes.RATEPLAN_INFO_DETAILS,
                _rateplanInfo: undefined
            });
        }
    }
}