import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import { GlobalValues } from '../../util/constants';
import moment from 'moment';

export const getAuctionList=(filter)=>{
    const _url=`get/auctionList/${filter.isActive}?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response= await getAxiosInstance(moduleTypes.ADMIN).get(_url)
        if(response.status===200) {        
                dispatch({
                    type: actionTypes.GET_ALL_AUCTIONS,
                    auctionList:response.data
                });
            } else{    
                    dispatch({
                        type: actionTypes.GET_ALL_AUCTIONS,
                        auctionList:[]
                    });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ALL_AUCTIONS,
                auctionList:[]
            })
        }
    }
}

export const changeActiveStatus=(reqObj)=>{
    return async (dispatch)=>{
        let _url=`changeAuctionStatus?status=${(reqObj.status===1?'0':'1')}&auctionId=${reqObj.auctionId}&access_token=${getAccessToken()}`;
       // dispatch(spinnerActions.showSpinner());
       try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)         
            if(response.status===200) { 
               return {statusCode:response.status,statusMsg:response.data.message}
            } else
                return {statusCode:null,statusMsg:response.data.message}
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
           // dispatch(spinnerActions.hideSpinner());
           return {statusCode:null,statusMsg:error}
          }
    }

}

export const getAuction=(id)=>{
    return (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
       dispatch({
        type: actionTypes.GET_AUCTION_RECORD,
        auctionObject:getAuctionRecFromStore(id)
    });
    //dispatch(spinnerActions.hideSpinner());
    }
}

export const clearAuction=()=>{
    return (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
       dispatch({
        type: actionTypes.CLEAR_AUCTION_RECORD,
        auctionObject:undefined
    });
    //dispatch(spinnerActions.hideSpinner());
    }
}
export const changeAuctionField=(recObject)=>{
    return (dispatch)=>{
         dispatch({
            type: actionTypes.GET_AUCTION_RECORD,
            auctionObject:recObject
        }); 
    }
} 
export const saveAuction=(object)=>{    
    return async (dispatch)=>{        
       // dispatch(spinnerActions.showSpinner());
        let _url=`add/auctionList?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        if(object.auctionId===0){
            object={...object,date:moment(object.date).format(GlobalValues.dateFormat)};
            try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,object);        
            if(response.data.statusCode===200) {
                return {statusCode:response.data.statusCode, statusMsg:response.data.message}
            } else{               
                    return {statusCode:null,statusMsg:response.data.message}
                   // dispatch(getAuctionList()); 
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error}
        }
        }else{
            _url=`update/auctionList?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
            object={...object,date:moment(object.date).format(GlobalValues.dateFormat)};
            try{
                const response=await getAxiosInstance(moduleTypes.ADMIN).put(_url,object)
                    if(response.data.statusCode===200) {         
                        //dispatch(getAuctionList());
                        return {statusCode:response.data.statusCode, statusMsg:response.data.message}
                    } else{
                           // dispatch(getAuctionList());
                           return {statusCode:null,statusMsg:response.data.message}
                    }
                     //dispatch(spinnerActions.hideSpinner());
                }
                catch(error) {
                      //dispatch(spinnerActions.hideSpinner());
                      return {statusCode:null,statusMsg:error}
                }
        }
    }
}
const getAuctionRecFromStore=(aid)=>{
    let _auctionObj={}
    if( Object.keys((store.getState()).auction).length>0){
        _auctionObj= (store.getState()).auction.auctionList.find(obj=>{
           return obj.auctionId===aid;
       });
       if(_auctionObj){
        _auctionObj={..._auctionObj, date:moment(_auctionObj.date)}
       }
       return _auctionObj;    
}}