import React, { Component, Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { Link } from 'react-router-dom';
class SummaryByAccountRatePlanTable extends Component {
	render() {
        const cols=[   
          {
            accessor: 'accountName',
            Header: 'Account',
            style: { textAlign: 'center' },
            Footer:( `Total :`) 
        },       
          {
            accessor: 'accountRatePlan',
            Header: 'Account Rate Plan',
            style: { textAlign: 'center' },
            Cell: props => <Link to={`/reports/summary-details?fromDate=${this.props.stateObj.fromDate}&toDate=${this.props.stateObj.toDate}&id=${props.original.accountRatePlan}&type=${'rateplan'}&accountId=${this.props.stateObj.accountId}&idCompany=${this.props.stateObj.idCompany}`} target="_blank">{props.original.accountRatePlan}</Link>,
           
        },
        {
            accessor: 'noOfTows',
            Header: 'Number Of Tows',
            style: { textAlign: 'center' },
            Footer:( ` ${this.props.data.totalTows||0}`) 
        },
        {
            accessor: 'totalCharges',
            Header: '* Total Charges',
            style: { textAlign: 'center' },
            Footer:( `$ ${this.props.data.sumOfTotalCharges||0}`) 
        },
        {
            accessor: 'use',
            Header: 'Use %',
            style: { textAlign: 'center' },
        },
        {
            accessor: 'chargesPerTow',
            Header: '* Charges Per Tow',
            style: { textAlign: 'center' },
            Footer:( `$ ${this.props.data.avgChargePerTow||0}`) 
        },
          ];
		return (
            <Fragment>
                <div className="row ">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={this.props.data.accountRatePlanDetails||[]}
                        columns={cols}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default SummaryByAccountRatePlanTable;