import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
class StatementsCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <Form ref={c => {
                                        this.formStatementsInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                    <div className="col-4 form-group">
                                            <div className="form-group row">
                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="rdstatements" id="inlineAllBillable" />
                                                        <label htmlFor="inlineAllBillable" className="custom-control-label">All Billable</label>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="rdstatements" id="inlineAccount" />
                                                        <label htmlFor="inlineAccount" className="custom-control-label">Account</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="custom-control custom-checkbox mb-5 mr-2">
                                                <input className="custom-control-input"
                                                    id="inlineIncludeInvoice"
                                                    type="checkbox"
                                                    name="includeInvoice"
                                                    value=""
                                                />
                                                <label className="custom-control-label" htmlFor="inlineIncludeInvoice">Include Invoice With Statements</label>
                                            </div>
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <input type="text" className="form-control"
                                                name="invoiceId" value={''}
                                            />
                                        </div>
                                        <div className="offset-3"></div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.asofdate === "" || this.props.asofdate === null
                                                ? null
                                                : moment(
                                                    this.props.asofdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="asOfDate"
                                        reqmsg="As of date is required"
                                        onDateSelectChange={e => {
                                            this.props.onStatementsFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-6 form-group">
                                        <button type="button" className="btn btn-success mr-2" >View Statement(s)</button>
                                            <button type="button" className="btn btn-primary" >Send Bill</button>
                                            
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default StatementsCtrl;