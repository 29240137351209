import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required, isValidForm} from '../../../util/validationMethods.js';
class TowTypeRec extends Component {
    constructor(){
        super();        
        this.handleSubmit=this.handleSubmit.bind(this);
        this.checkAccess=this.checkAccess.bind(this);
    }
    handleSubmit=(event)=>{
        event.preventDefault();
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{
            this.props.onSubmitForm(event,this.props.recData)
        }
    }
    checkAccess=()=>{
        if((this.props.recData.towTypeId||0)==0){
            if((this.props.accessLevel.basicAccess.add||false)===true){
                return false
            }else{
                return true
            }
        }else{
            if((this.props.accessLevel.basicAccess.edit||false)===true){
                return false;
            }else{
                return true;
            }
        }
    }
render(){
    return(
        <Form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>        
        <div className="modal-body">
        <fieldset disabled={this.checkAccess()}>
        <div className="col-md-12 col-sm-12">
			<div className="form-group">
				<label className="form-control-label">Tow Type Name *</label>
                    <Input name="towType" type="text" className="form-control"  placeholder="Tow Type"
                    requiredmsg="Please enter Tow Type Name" 
                    value={this.props.recData.towType} onChange={(e)=>{
                        let _recObj={...this.props.recData};
                        _recObj[e.currentTarget.name]=e.currentTarget.value;
                        this.props.onFieldDataChange(e,_recObj);
                    }} validations={[required]} />					
				</div>
            </div>
            </fieldset>
            <div className="modal-footer">
            <button variant="secondary" className="btn btn-danger btn-sm" onClick={(e)=>{this.props.onCancelForm(e)}}>Close</button>
            <button type="submit" variant="primary" className="btn btn-success btn-sm"
             disabled={this.checkAccess()}>
             {(this.props.recData.towTypeId||0)==0?"Save":"Save changes"}</button>
            </div>
        </div>
        </Form>
    )
}
}
export default TowTypeRec