import * as actionTypes from '../actions/actionTypes';
const defaultAuth={};       

export const authReducer = (state = defaultAuth, action) => {
    switch (action.type) {
        case actionTypes.SET_AUTH_INFO:
            return { ...action.payload||defaultAuth} ;
        default:
            return state;
    }
  };

  const defaultAccessLevels={
    accessLevel:{}
  }
  
  export const accessLevelReducer = (state = defaultAccessLevels, action) => {
      switch (action.type) {
        case actionTypes.SET_ACCESS_LEVELS:
          return action.payload||defaultAccessLevels.accessLevel;        
        default:
          return state;
      }
    };
    const defaultDashboardAccess={
      dashboardAccess:{}
    }
    export const dashboardAccessReducer = (state = defaultDashboardAccess.dashboardAccess, action) => {
      switch (action.type) {
          case actionTypes.SET_DASHBOARD_ACCESS_LEVELS:
          return action.payload||defaultDashboardAccess.dashboardAccess;
        default:
          return state;
      }
    };