import * as actionTypes  from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import {GlobalValues} from '../../util/constants'
import moment from 'moment'

export const getStatementMessage=()=>{
    const _url=`config/getStatementMessage?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch)=>{
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(resp.status===200){
                dispatch({
                    type: actionTypes.GET_STATEMENT_MESSAGE,
                    statementMsg:resp.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_STATEMENT_MESSAGE,
                    statementMsg:[]
                });

            }
        }catch(ex){
            dispatch({
                type: actionTypes.GET_STATEMENT_MESSAGE,
                statementMsg:[]
            });
        }
    }
}
export const saveStatementMessage=(statementMsg)=>{
    return async (dispatch)=>{        
        //dispatch(spinnerActions.showSpinner());
    let _url=`config/EditStatementMessage?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    const _statementMsg={...statementMsg,updateDate:moment().format(GlobalValues.dateTimeFormat),companyId:getCompanyId()};
    try{
        const resp=await getAxiosInstance(moduleTypes.ADMIN).put(_url,_statementMsg);
            if(resp.data.statusCode===200){
                dispatch(getStatementMessage())
                return {statusCode:200, statusMsg:resp.data.message}
            }else{
                return {statusCode:null, statusMsg:resp.data.message}
            }
        }catch(ex){
            return {statusCode:null, statusMsg:ex.message}
        }
    }
}
export const onStatementMessageFieldUpdate=(key,value)=>{
    let _statementMegObj=Object.assign({},(store.getState()).statementMsg) ;
    _statementMegObj[key]=value;
    return (dispatch)=>{
        dispatch({
            type: actionTypes.GET_STATEMENT_MESSAGE,
            statementMsg:_statementMegObj
        })
    }
}