import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { notify } from '../../../util/notifications';
import { isValidForm } from '../../../util/validationMethods';
import printJS from 'print-js';
import {tableColumnsNegative} from '../../../util/tableColumnUtil/managedInvoicesCols'
import { initInvoiceManageState} from '../../../util/stateUtil/initialStateForComponents'
import NegativeBalanceReportCtrl from '../../../components/reportsComponents/invoicemanagement/negativeBalanceReportCtrl';
import NegativeBalanceReportTable from '../../../components/reportsComponents/invoicemanagement/negativeBalanceReportTable';
import {isManagement,getCompanyId} from '../../../util/customMethods'
import {cleanManagedInvociesReport,emailManagedInvoice,retrieveInvoiceManageReport} from '../../../actions/reportAction/invoiceManage/invoiceManageAction'
const pageTitle = 'Negative Balance Report'
const printJSHeaders = tableColumnsNegative.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))

let reportForm={}

class NegativeBalanceReport extends Component {
	state = {
            ...initInvoiceManageState
		}

        Go = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(retrieveInvoiceManageReport(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success', res.statusMsg, this.props.refNotify);
                this.setState({ render: false });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
        Export = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(retrieveInvoiceManageReport(payload, status));
            if (res.statusCode === 200) {
                this.setState({ render: !this.state.render });
                notify.createNotification('success','Report exported successfully.', this.props.refNotify);
                this.setState({ render: false });
            } else {
                this.setState({ render: false });
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
        Print = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(retrieveInvoiceManageReport(payload, status));
            if (res.statusCode === 200) {
                printJS({
                    printable: this.props.data,
                    properties: printJSHeaders,
                    type: 'json',
                    documentTitle: 'Driver performance Report'
                });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
        email = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(emailManagedInvoice(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success',res.statusMsg, this.props.refNotify);
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
            /* Building all invoice object for post request */
            buildObject = () => {
                const stateObj = this.state;
                const constractObj = {
                    idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
                    asOfDate: stateObj.asOfDate,
                    location:stateObj.selectedLocation,
                    name:"negative"
                };
                return constractObj;
            }
	componentWillMount = () => {
        const { dispatch } = this.props;
        dispatch(cleanManagedInvociesReport());
		document.title = pageTitle;
		
    }
    onNegativeBalanceReportFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let stateObj = this.state;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Negative Balance Report</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <NegativeBalanceReportCtrl 
                    Go={()=>{
                        reportForm.validateAll()
                        if(isValidForm(reportForm)){
                            this.Go();
                        }}}  
                        Export={()=>{
                            reportForm.validateAll()
                            if(isValidForm(reportForm)){
                                this.Export();
                            }}}
                    email={()=>{
                        reportForm.validateAll()
                        if(isValidForm(reportForm)){
                            this.email();
                        }}} Print={()=>{
                            reportForm.validateAll()
                            if(isValidForm(reportForm)){
                                this.Print();
                            }}}
                    childCompList={this.props.childCompList} stateObj={stateObj} location={this.props.locations}
                    onNegativeBalanceReportFieldChange={(key, value)=>this.onNegativeBalanceReportFieldChange(key, value)}
                    initCallBack={(frm)=>{
						reportForm=frm;
                    }} asofdate={this.state.asOfDate} data={this.props.data}/>
                    <NegativeBalanceReportTable
                      cols={tableColumnsNegative}
                     data={this.props.data}/>
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
        childCompList: state.searchInvoiceList.childComList,
        locations: state.location.locationList,
        data:state.invoiceManageObj.invoiceManageReportData
	}
};

export default withRouter(connect(mapStateToProps)(NegativeBalanceReport));
