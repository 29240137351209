import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const defaultLocation={
  locationList:[],
  locationObject: {
    "locFlag":0,
    "addrLine2":'',
    "addrLine1":"",
    "cityLocation":"",
    "cityList":"",
    "zip":"",
    latLong:'',
    "reviewLocation":0,
    "cityName":[],
    "physicalLocation":0,
    "impoundLocation":0,
    "paymentLocation":0,
    "location":"",
    "state":""
  }  
}
export const locationReducer = (state = defaultLocation, action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_LOCATIONS:
        return {...state ,locationList:(action.locationList||defaultLocation.locationList)};
      case actionTypes.GET_SINGLE_LOCATION:
        return {...state , locationObject:(action.locationObject|| defaultLocation.locationObject) };
        case actionTypes.CLEAR_LOCATION_RECORD:
        return {...state , locationObject: defaultLocation.locationObject };
      default:
        return state;
    }
  };