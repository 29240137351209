import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {notify} from '../../util/notifications'
import CallVisibility from '../../components/dispatchComponents/dashboard/callVisibility';
import TruckVisibility from '../../components/dispatchComponents/dashboard/truckVisibility';
import {getCallList,getTruckList, invoiceClearOrCancel, forceLogout} from '../../actions/dispatchAction/dispatchCallsAction'
import {isManagement,getAccessLevelsByModuleName} from '../../util/customMethods';
const pageTitle = 'Dispatch Dashboard';
const statusArray1=['Cleared','Cancel'];
const statusArray2=['Assign','Reassign'];
class DispatchDashboard extends Component {
	constructor(props){
		super(props);
		this.state={
			interval:0
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		this.bindCallList();
	if(!isManagement())
	   this.bindTruckList();
	}
	componentDidMount(){
		const interval = setInterval(()=>{
			this.bindCallList();
			if(!isManagement())
			this.bindTruckList();
		}, 15000)
		this.setState({ interval: interval });
	}	  
	componentWillUnmount=()=>{
		clearInterval(this.state.interval)
	  }
	bindCallList=async()=>{
		const {dispatch} =this.props
		await dispatch(getCallList())
	}
	bindTruckList=async()=>{
		const {dispatch} =this.props
		await dispatch(getTruckList())
	}
	onCallActionBtnClick=async(req)=>{
		const {dispatch,history}=this.props;
		if(statusArray1.includes(req.status)){
			const res=await dispatch(invoiceClearOrCancel(req));
			if(res.statusCode===200){
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
				this.bindCallList();
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}else if(statusArray2.includes(req.status)){
			history.push('/dispatch/assign/'+req.invoiceId)
		}else{
			
		}
	}

	routeInv=async(id)=>{
		const {dispatch, history}=this.props;
		history.push('/dispatch/serviceCallInfo/'+id)
	}

	forceLogout = async(e,row) => {
		e.preventDefault();
		if (window.confirm('Are you sure you wish to logout ?')) {
			const {dispatch} = this.props;
			const res=await dispatch(forceLogout(row.original.driverId, row.original.truckId));
			if(res.statusCode===200){
				this.bindTruckList();
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		  } 
	}
	permissionByPage=(obj,page)=>{
		let permissionObj = obj.pageLvlAccess[page]||{};
		return permissionObj.view||false;
	}
	render() {
		let dashboard = getAccessLevelsByModuleName('Dispatcher Dashboard')[0]||{};
		return (
			<Fragment>
				{(dashboard.basicAccess.view)?<div>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Dispatch Dashboard</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box dispatch-dashboard">
					<CallVisibility data={this.props.callList||[]} routeInv={(id)=>{this.routeInv(id)}} onCallActionBtnClick={(req)=>{this.onCallActionBtnClick(req)}} 
					canClear={this.permissionByPage(dashboard,'Clear Call')} canAssign={this.permissionByPage(dashboard,'Assign/Re-Assign Call')}
					canCancel={this.permissionByPage(dashboard,'Cancel Call')}/>
					{!isManagement()?<TruckVisibility data={this.props.truckList||[]} forceLogout={(e,row)=>this.forceLogout(e,row)} 
					canlogout={this.permissionByPage(dashboard,'Force Log Out')}/>:null}
				</div>
				</div>:
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<div class="text-center text-</h3>danger"><h3 class="text-danger">Not Authorised User</h3></div></div>}
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify:state.refNotify,
		authInfo: state.authInfo,
		callList:state.dispatchCalls.callList,
		truckList:state.dispatchCalls.truckList
	}
};

export default withRouter(connect(mapStateToProps)(DispatchDashboard));
