import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import input from 'react-validation/build/input'
import Textarea from 'react-validation/build/textarea'
import './statementMessage.css';
class StatementMessage extends Component {
    constructor(props){
        super(props)
        this.checkAccess=this.checkAccess.bind(this);
    }
    checkAccess=()=>{
        if(((this.props.accessLevel.basicAccess.add||false)===true) ||((this.props.accessLevel.basicAccess.edit||false)===true)){
            return false
        }else{
            return true
        }
    }
    render() {
        return (
            <Fragment>
            <fieldset disabled={this.checkAccess()}>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                    <Form ref={c => { this.form = c }}>                    
                    <label className="col-md-12 text-right text-color-red"><small><em>{'Last Updated on: '+this.props.statementObj.updateDate}</em></small></label>
                    <div className="">
							<label className="col-form-label">Greeting</label>
							<div className="">
								<Textarea className="form-control" rows="3" name="greetings" value={this.props.statementObj.greetings||''} type="text" placeholder="" 
                                
                                onChange={(e)=>{
                                    this.props.onFieldValueChange(e,e.target.name,e.target.value)
                                }}  />
							</div>
                            
						</div>
                        <div className="">
							<label className="col-form-label">Paragraph 1</label>
							<div className="">
								<Textarea className="form-control" rows="4" style={{height:'125px'}} name="paragraph1" value={this.props.statementObj.paragraph1||''} type="text" placeholder="" 
                                onChange={(e)=>{
                                    this.props.onFieldValueChange(e,e.target.name,e.target.value)
                                }} />
							</div>                            
						</div>
                        <div className="">
							<label className="col-form-label">Paragraph 2</label>
							<div className="">
								<Textarea className="form-control" rows="4" style={{height:'125px'}} name="paragraph2" value={this.props.statementObj.paragraph2||''} type="text" placeholder="" 
                                onChange={(e)=>{
                                    this.props.onFieldValueChange(e,e.target.name,e.target.value)
                                }} />
							</div>                            
						</div>
                        <div className="">
							<label className="col-form-label">Paragraph 3</label>
							<div className="">
								<Textarea className="form-control" rows="4" style={{height:'125px'}} name="paragraph3" value={this.props.statementObj.paragraph3||''} type="text" placeholder="" 
                                onChange={(e)=>{
                                    this.props.onFieldValueChange(e,e.target.name,e.target.value)
                                }} />
							</div>                            
						</div>
                        <div className="">
							<label className="col-form-label">Thanks Message</label>
							<div className="">
								<Textarea className="form-control" rows="1" name="thanksMessage" value={this.props.statementObj.thanksMessage||''} type="text" placeholder=""  
                                onChange={(e)=>{
                                    this.props.onFieldValueChange(e,e.target.name,e.target.value)
                                }} />
							</div>                            
						</div>
                        <div className="">
							<label className="col-form-label">Signature</label>
							<div className="">
								<Textarea className="form-control" rows="1" name="signature" value={this.props.statementObj.signature||''} type="text" placeholder=""  
                                onChange={(e)=>{
                                    this.props.onFieldValueChange(e,e.target.name,e.target.value)
                                }} />
							</div>                            
						</div>
                    </Form>       
                           
                    </div>
                </div>
                <div className="row">
                        <div className="text-center col">
                            <button type="button" className="btn-size btn btn-success btn-sm"
                            onClick={(event)=>{
                                event.preventDefault();
                                this.props.onSubmitStatementMessage();
                            }}>Save</button>
                        </div>
                        </div>
                </fieldset>
            </Fragment>
        );
    }
}

export default StatementMessage;
