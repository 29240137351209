import React from 'react';
const NotFound=()=>
    <div class="row align-items-center">
        <div class="col-4 offset-md-5" style={{position:'absolute', top:'50%', transform:'translate(-50%,-50%)'}}>
            <div class="card text-white bg-danger">
                <div class="card-body text-center">
                    <h3 class="card-title">Page not found</h3>
                    <p class="card-text">You do not have view permission.</p>
                </div>
            </div>
        </div>
    </div>
export default  NotFound;