import React from 'react';
import { control} from 'react-validation';
import './tmInputGroup.css'

const TmInputGroup = ({ error, isChanged, isUsed, ...props }) => (
    <>
    {
        props.prepend && props.prependText
    }
            
            <input {...props}  />
            {
        props.append && props.appendText
    }
      {isChanged && isUsed && error}
    </>
  );

  export default control(TmInputGroup)