import * as actionTypes from '../../../actions/actionTypes';
const defaultARDetails={
  arDetailsObj:{}
}
export const arDetailsReducer = (state = defaultARDetails, action) => {
    switch (action.type) {
      case actionTypes.GET_ARDETAILS_LIST:
        return {...state ,arDetails:(action.arDetailsObj||defaultARDetails.arDetailsObj)};
      default:
        return state;
    }
  };