import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../util/constants';
import { isManagement} from '../../../util/customMethods';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
import {tableColumns} from '../../../util/tableColumnUtil/driversColumn'
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class DriverPerformanceSummaryCtrl extends Component {
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                    <Form ref={c => {
                                        this.formDriverPerformanceSummaryInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                    {!isManagement()?<div className="col-md-2 form-group">
                                    </div>:null}
                                    <label htmlFor="startdate" className="form-label col-form-label">Start Date</label>
                                        <div className="col-3 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.stateObj.fromDate === "" || this.props.stateObj.fromDate === null
                                                ? null
                                                : moment(
                                                    this.props.stateObj.fromDate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="fromDate"
                                        reqmsg="Start date is required"
                                        onDateSelectChange={e => {
                                            this.props.onDriverPerformanceSummaryFieldChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                    </div>
                                    
                                            <label htmlFor="enddate" className="form-label col-form-label">End Date</label>
                                        
                                        <div className="col-3 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.stateObj.toDate === "" || this.props.stateObj.toDate === null
                                                ? null
                                                : moment(
                                                    this.props.stateObj.toDate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="toDate"
                                        reqmsg="End date is required"
                                        onDateSelectChange={e => {
                                            this.props.onDriverPerformanceSummaryFieldChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div> 
                                       
                                    {isManagement()?<div className="col-md-1 form-group">
                                            <label htmlFor="location" >Company</label>
                                    </div>:<div className="col-md-2 form-group">
                                    </div>}
                                    {isManagement()?
                                    <div className="col-md-3 form-group">
                                    <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={this.props.childCompList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.stateObj.idCompany || '').toString()),
                                                        label: (this.props.childCompList.find((e) => e.optionVal == (this.props.stateObj.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onDriverPerformanceSummaryFieldChange('idCompany', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                    </div>:null}
                                    </div>
                                    <div className="form-row pd-10"></div>
                                    <div className="form-row">
                                    <div className="col-3 form-group"></div>
                                        <div className="col-6 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            onClick={this.props.Go}>Go</button>
                                            <button onClick={this.props.Export} type="button" className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                            {this.props.stateObj.render ? 
                                    <ExcelFile hideElement={true}
                                        filename={`${"DriverPerformanceSummary_"}${moment().format('MMDDYYhhmm')}`}
                                        fileExtension={'xlsx'}
                                    >
                                        <ExcelSheet 
                                            data={this.props.data || []}
                                            name={'Driver performance Report'}
                                        >
                                            {(tableColumns||[]).map(column => {
                                                return (
                                                    <ExcelColumn
                                                        key={column.accessor}
                                                        label={column.Header}
                                                        value={column.accessor}
                                                    />
                                                    );
                                                })}
                                        </ExcelSheet>
                                    </ExcelFile>
                                : null}
                                            <button onClick={this.props.email} type="button" className="btn btn-primary custom-reports-btn mr-2" >Email</button>
                                            <button onClick={this.props.Print }type="button" className="btn btn-success custom-reports-btn mr-2" >Print</button>
                                        </div>
                                        <div className="col-3 form-group"></div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default DriverPerformanceSummaryCtrl;