import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import {notify} from '../../util/notifications';
import {GOOGLE_API_KEY} from '../../util/constants'
import {isManagement} from '../../util/customMethods'
import PaymentProcess from '../../components/dispatchComponents/payment/paymentProcess'
const pageTitle = 'Payment';
class InvoicePayments extends Component {	
    render() {
		document.title=`${pageTitle}`;
		return (
			<>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-credit-card"></i> Payment </h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<div className="row">
						<div className="col-md-12">
                            <PaymentProcess/>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
	}
};

export default withRouter(connect(mapStateToProps)(InvoicePayments));

