import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {notify} from '../../../util/notifications';
import {viewStatement,sendCompanyStatement} from '../../../actions/reportAction/monetization/monetizationAction';
import StatementsCtrl from '../../../components/reportsComponents/monetization/crStatementCtrl';
import {isValidForm} from '../../../util/validationMethods';
import { getCompanyId, isManagement } from '../../../util/customMethods';
const pageTitle = 'C/R Statements'
let StatementsForm={
	formStatementsInfo:{}
}

class Statements extends Component {
	constructor() {
		super();
		this.state = {
            idCompany:0,
            asOfDate:null,
            invoicesWithStatement:false,
            isBillable:false
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;	
    }
    onStatementsFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }

    getByCompanyId=()=>{
        let id_company=0;
        if(!isManagement()){
            id_company=getCompanyId();
        }else{
            id_company=this.state.idCompany;
        }
        return id_company;
    }

    /*function call to  */
    sendCompanyStatement = async (e) => {
        e.preventDefault();
		const { dispatch } = this.props
        const res = await dispatch(sendCompanyStatement(this.state.asOfDate,this.state.idCompany,this.state.invoicesWithStatement));
		if (res.statusCode === 200) {
            notify.createNotification('success', res.message, this.props.refNotify);
        } 
        else {
			notify.createNotification('error', res.message, this.props.refNotify);
		}
    }
    
    viewStatement = async (e) => {
        e.preventDefault();
		const { dispatch } = this.props
        const res = await dispatch(viewStatement(this.state.asOfDate,this.state.idCompany,this.state.invoicesWithStatement));
	}
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> C/R Statements</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <StatementsCtrl onStatementsFieldChange={(key, value)=>this.onStatementsFieldChange(key, value)}
                   childCompList={this.props.childCompList} idCompany={this.state.idCompany}
                   asOfDate={this.state.asOfDate} isBillable={this.state.isBillable}
                   onStatementsFieldChange={(key, value)=>{this.onStatementsFieldChange(key, value)}}
                   invoicesWithStatement={this.state.invoicesWithStatement}
                   viewStatement={(e)=>{
                    e.preventDefault();
                    StatementsForm.formStatementsInfo.validateAll();
                    if(isValidForm(StatementsForm.formStatementsInfo))
                       this.viewStatement(e)}
                    }
                    sendCompanyStatement={(e)=>{
                        e.preventDefault();
                        StatementsForm.formStatementsInfo.validateAll();
                        if(isValidForm(StatementsForm.formStatementsInfo))
                           this.sendCompanyStatement(e)}
                        }
                   initCallBack={(frm)=>{StatementsForm.formStatementsInfo=frm;}} asofdate={this.state.asOfDate}/>
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        childCompList:state.searchInvoiceList.childComList,
        refNotify:state.refNotify,
	}
};

export default withRouter(connect(mapStateToProps)(Statements));
