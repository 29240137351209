import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import accessLevel from '../../util/modules.json'

export const getEmployeeList=(searchObj)=>{
    const _url=`employee/searchEmployee?searchQuery=${searchObj.searchText}&accountStatus=${searchObj.accountStatus}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response= await getAxiosInstance(moduleTypes.ADMIN).get(_url)
        if(response.status===200) {        
            dispatch({
                type: actionTypes.GET_ALL_EMPLOYEES,
                employeeList:(response.data||[]).map(item=>{
                    return {...item,userFullName:item.fname+' '+item.lname}
                })
            });
            } else{    
                dispatch({
                    type: actionTypes.GET_ALL_EMPLOYEES,
                    employeeList:undefined
                });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ALL_EMPLOYEES,
                employeeList:undefined
            });
        }
    }
}
export const onFieldChange=(key,value)=>{
  return (dispatch)=>{
    let _employeeObj={}
    if( Object.keys((store.getState()).employees).length>0){
        _employeeObj= (store.getState()).employees.employeeRec;
       if(_employeeObj){
        _employeeObj.employeeInformation[key]=value;
        dispatch({
            type: actionTypes.GET_EMPLOYEE_REC,
            employeeRec:_employeeObj 
        })
       }
  }      
}}
export const onEmpLocationChange=(empObj)=>{
    return (dispatch)=>{
      let _employeeObj={}
      if( Object.keys((store.getState()).employees).length>0){
          _employeeObj= (store.getState()).employees.employeeRec;
         if(_employeeObj){
          _employeeObj.employeeInformation=empObj;
          dispatch({
              type: actionTypes.GET_EMPLOYEE_REC,
              employeeRec:_employeeObj
          })
         }
    }      
  }}
export const onEmployeeRemarksAdded=(remarkObj)=>{
    return (dispatch)=>{
      let _employeeObj={}
      if( Object.keys((store.getState()).employees).length>0){
          _employeeObj= (store.getState()).employees.employeeRec;
         if(_employeeObj){
          _employeeObj.employeeRemarksForm.remarks.push(remarkObj);
          dispatch({
              type: actionTypes.GET_EMPLOYEE_REC,
              employeeRec:_employeeObj
          })
         }  
    }      
  }}
export const onDashboardPermissionChange=(item)=>{
    return (dispatch)=>{
      let _employeeObj={}
      if( Object.keys((store.getState()).employees).length>0){
          _employeeObj= (store.getState()).employees.employeeRec;
         if(_employeeObj){
          let _dashboardAccess=_employeeObj.dashboardAccess;
          let index= _dashboardAccess.findIndex(k => (k.module===item.module && k.tileName===item.tileName ))
          if(index!==-1){
            _dashboardAccess[index]=item;
            _employeeObj.dashboardAccess=_dashboardAccess
          }
          dispatch({
              type: actionTypes.GET_EMPLOYEE_REC,
              employeeRec:_employeeObj
          })
         }
  
    }      
  }}
export const clearEmployeeRec=()=>{
    return(dispatch)=>{
        dispatch({
            type: actionTypes.GET_EMPLOYEE_REC,
            employeeRec:undefined
        });
        dispatch(getEmployeeRec(0))
    }
}

export const getEmployeeRec=(empId)=>{
    const _url=`employee/select/employeeInfo?empId=${empId}&locationName= &companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => { 
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200) {
                await dispatch({
                    type: actionTypes.GET_EMPLOYEE_REC,
                    employeeRec:undefined
                });
               await dispatch({
                    type: actionTypes.GET_EMPLOYEE_REC,
                    employeeRec:response.data||{} 
                });
                } else{    
                    dispatch({
                        type: actionTypes.GET_EMPLOYEE_REC,
                        employeeRec:undefined
                    });
                }


        }catch(error){
            dispatch({
                type: actionTypes.GET_EMPLOYEE_REC,
                employeeRec:undefined
            });
        }
    }
}
export const getDashboardTails=()=>{
    const _url=`employee/getEmployeeTilesAccess?employeeId=0&access_token=${getAccessToken()}`;
    return async (dispatch)=>{
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200){
                dispatch({
                    type: actionTypes.GET_DEFAULT_DASHBOARD_TAILS,
                    dashboardAccess:response.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_DEFAULT_DASHBOARD_TAILS,
                    dashboardAccess:undefined
                });
            }

        }catch(error){
            dispatch({
                type: actionTypes.GET_DEFAULT_DASHBOARD_TAILS,
                dashboardAccess:undefined
            });
        }
    }
}


export const saveEmployee=(employeeRec)=>{
    const _url=`employee/insertEmployeeInfo?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        try{
            if ((employeeRec.employeeInformation.employeeId||0)==0 || (employeeRec.employeeInformation.employeeId||'')=='')
                employeeRec.employeeInformation.empStatus=1;
            const response =await getAxiosInstance(moduleTypes.ADMIN).post(_url,employeeRec);
            if(response.status===200){
                return {statusCode:response.status, statusMsg:response.data.message}
            }else{
                return {statusCode:null, statusMsg:response.data.message}
            }
        }catch(error){
            return {statusCode:null, statusMsg:error.message}
        }
    }
}
export const onAccessLevelsChanged=(changedAccessLevels)=>{
    return (dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        dispatch({
            type: actionTypes.GET_ALL_MODULES,
            empAccess:changedAccessLevels//.slice()
        });
        //dispatch(spinnerActions.hideSpinner());
    }
}
export const onProfileImageUpload=(profileImage)=>{
    return (dispatch)=>{
        let _employeeObj={}
        if( Object.keys((store.getState()).employees).length>0){
            _employeeObj= (store.getState()).employees.employeeRec;
           if(_employeeObj){
                const tempUrl= window.URL.createObjectURL(profileImage);
                convertImgToBase64URL(tempUrl,(dataUrl)=>{
                    _employeeObj.employeeInformation.uploadImage=(dataUrl.split(','))[1]
                    _employeeObj.employeeInformation['uploadFile']=profileImage;
                    _employeeObj.employeeInformation.imageURL=tempUrl;           
                        dispatch({
                            type: actionTypes.GET_EMPLOYEE_REC,
                            employeeRec:Object.assign({},_employeeObj) 
                        })

                });                
           }
      }      
    }
}
const convertImgToBase64URL=(url, callback, outputFormat)=>{
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null; 
    };
    img.src = url;
}
export const onEmployeeActiveStatusChange=(eid,activeStatus)=>{
    return async(dispatch)=>{
        const _url=`employee/statusChange?empId=${eid}&empStatus=${activeStatus}&access_token=${getAccessToken()}`;
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200) {         
                dispatch(onFieldChange('empStatus',activeStatus));
                return {statusCode:response.status,statusMsg:response.data.message}
            }else{
                return {statusCode:null,statusMsg:response.data.message}
            }
        }catch(error){
            return {statusCode:null,statusMsg:error.message}
        }
    }
}
export const getModuleAccessLevels=()=>{
    return (dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        dispatch({
            type: actionTypes.GET_ALL_MODULES,
            accessLevel:accessLevel.accessLevel.slice()
        });
        //dispatch(spinnerActions.hideSpinner());
    }
}