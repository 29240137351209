import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table-v6';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import moment from 'moment';
class AccountAssignedComp extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const historyCols = [{
      Header: 'Company Name',
      accessor: 'companyName',
      filterable: true,
      Cell: props => <span>{props.value}</span>,
      minWidth: '60px'

    }, {
      Header: 'Company Type',
      accessor: 'companyType',
      filterable: true,
      Cell: props => <span>{props.value}</span>,
      minWidth: '60px'
    }, {
      Header: 'Sub Domain',
      accessor: 'subdomain',
      filterable: true,
      Cell: props => <span>{props.value}</span>,
      minWidth: '150px'
    },
    ];
    return (<Fragment>
      <div className="pd-10">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="text-blue text-bg-color form-group">ACCOUNT ASSIGNED INFO</h6>
          </div>
        </div>
        <div className="row mb-10">
          <div className="col-md-12 col-sm-12 md-10">
            <TmReactTable
              filterable
              sortable
              resizable
              defaultPageSize={10}
              data={this.props.assignedComList}
              columns={historyCols}
            />
          </div>
        </div>
      </div>

    </Fragment>)
  }
}
export default AccountAssignedComp