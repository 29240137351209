import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ARSummaryCtrl from '../../../components/reportsComponents/accounting/arSummaryCtrl';
import ARSummaryTable from '../../../components/reportsComponents/accounting/arSummaryTable';
import {isValidForm} from '../../../util/validationMethods';
import {getARSummaryDetailsList, getARSummaryDetailsClearList} from '../../../actions/reportAction/accounting/arSummaryAction';
const pageTitle = 'A/R Summary'
let arSummaryForm={
	formARSummaryInfo:{}
}

class ARSummary extends Component {
	constructor() {
		super();
		this.state = {
            asOfDate:'',
            accountType:'',
            dataFetched:false
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		this.props.dispatch(getARSummaryDetailsClearList());
    }
    getARSummaryDetails=(e)=>{
        e.preventDefault();
        arSummaryForm.formARSummaryInfo.validateAll();
        if(isValidForm(arSummaryForm.formARSummaryInfo)){
            this.props.dispatch(getARSummaryDetailsList(this.state.accountType, this.state.asOfDate))
            .then(() => {this.setState({ dataFetched: true })})   
            .catch(err => {
                console.error('fetchReport methods failed with error: ', err);
            });
        }
    }
    onSummaryFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> A/R Summary</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <ARSummaryCtrl accountTypes={this.props.accountTypes} 
                    asofdate={this.state.asOfDate} initCallBack={(frm)=>{
						arSummaryForm.formARSummaryInfo=frm;
                    }} accountType={this.state.accountType}
                    arSummaryList={this.props.arSummaryList}
                    getARSummaryDetails={(e)=>this.getARSummaryDetails(e)}
                    onSummaryFieldChange={(key, value)=>this.onSummaryFieldChange(key, value)}/>
                    {this.state.dataFetched &&
                    <ARSummaryTable arSummaryList={this.props.arSummaryList}/>
                    }
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        accountTypes: state.accountTypes.accountTypes,
        arSummaryList:state.arSummary.arSummaryDetails
	}
};

export default withRouter(connect(mapStateToProps)(ARSummary));
