import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import { getCompanyId } from '../../util/customMethods';

const fetchAccountList=(account)=>{
    const _url=`company/${getCompanyId()}/accountList/${account||0}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
       try{
           const response=await getAxiosInstance(moduleTypes.DISPATCH).get(_url);       
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_ACCOUNT_LIST,
                    accountList:response.data.response
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_ACCOUNT_LIST,
                        accountList:undefined
                    });
                }            
        }
        catch(error){            
            dispatch({
                type: actionTypes.GET_ACCOUNT_LIST,
                accountList:undefined
            });
        }
    }
}
export{
    fetchAccountList
}