
import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom'
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import {ToggleButtonGroup,ToggleButton} from 'react-bootstrap'
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
import moment from 'moment'

class AuctionList extends Component {
  constructor(){
    super();
    this.state={
      ActiveInactiveFilterVal:[1],
      excelDataSet:[]
    }
    this.handleActiveInactiveFilterChange=this.handleActiveInactiveFilterChange.bind(this);
    this.buildDataFormatForExport=this.buildDataFormatForExport.bind(this)
  }

  componentWillReceiveProps=(nextProps)=>{
    this.setState({excelDataSet:nextProps.data});
  }

  handleActiveInactiveFilterChange=(value,e)=>{
    this.setState(
      {ActiveInactiveFilterVal:[parseInt(e.currentTarget.value)]},   
    this.props.onSearchChange({isActive:parseInt(e.currentTarget.value)})
    );
  }

  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'Action List Name'},{title:'Action Date'}],
      data: this.state.excelDataSet.map((item)=>{
        return [{value:item.auctionName},{value:item.date}]
      })
    }])
  }
  
  render() {
    const {data} =this.props;

    const sortedData = data.map(data => {
      for(let key in data){
          data[key] = typeof data[key] === ('undefined' || 'object') || key.startsWith('_') || Number(data[key]).toString() === 'NaN' || Number(data[key]) === 1 ? data[key] : Number(data[key])
      }
      return data
  })


    const columns=[{
      Header: 'ACTION LIST NAME',
      accessor: 'auctionName', // String-based value accessors!
      filterable:true,
      Cell: props => ( <a className="btn-link" href="javascript:void(0)" title="Edit" onClick={(e)=>{
        let _aid=props.original.auctionId;
        this.props.editAuctionOnClick(e,_aid)
      }}>{props.value}</a>)
      
    }, {
      Header: 'DATE',
      accessor: 'date',
      style:{textAlign:'center'},
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      width: 300
    },    
    {
      Header: 'ACTION',
      filterable:false,
      style:{textAlign:'center'},
      Cell: (props) =>{
       return <Fragment>
      <div className="btn-list"> 

          <button type="button" className={props.original.status===1?"btn btn-danger btn-sm":"btn btn-success btn-sm"} onClick={(e)=>{
            let _aid=props.original.accountId;
           let confRes= window.confirm(props.original.status===1?'Do you want to Deactivate the Auction Acc?':'Do you want to activate the Auction Acc?');
           if(confRes){
             this.props.ActiveInActiveAuction(e,props.original);
           }
          }}>{ props.original.status===1? ' Deactivate':' Activate' } </button></div>
       </Fragment>
      } ,
      width: 200
    }];
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-4 col-sm-12 mb-10"></div>
      <div className="col-md-8 col-sm-6 mb-10">  
      <div className="pull-right">   
      <ToggleButtonGroup
      bsPrefix="btn-group-toggle btn-group"
        type="checkbox"
        value={this.state.ActiveInactiveFilterVal}
        onChange={this.handleActiveInactiveFilterChange} defaultValue={-1}
      >
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}>ACTIVE</ToggleButton>
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>INACTIVE</ToggleButton>
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>ALL</ToggleButton>
      </ToggleButtonGroup>      
      <div className="btn-list" style={{display:'inline-block'}}>
      <TmExportExcel name={'Auction List'} filename={`${"AuctionList_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
       {
         (this.props.accessLevel.basicAccess.add||false)===true?
         <button className="btn btn-success btn-sm" onClick={(e)=>{
        this.props.addAuctionOnClick(e,0)
      }} ><i className="fa fa-plus"></i> Add Auction List</button>:null
       }
      
      </div>
      </div> 
      </div>
      </div>
      
      
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">				
        <TmReactTable ref={(table)=>this.auctionTable=table}
            filterable 
            sortable={true}
            resizable
            defaultPageSize={10}
            data={sortedData}
            columns={columns}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default AuctionList;
