import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {authenticateUser} from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";
import {required} from '../../util/validationMethods.js'
import {getSubDomain} from '../../util/customMethods';
import TmInputGroup from '../../components/utilComponents/tmInputGroup/tmInputGroup'
import Background from '../../assets/img/bg_body.jpg';
import './login.css';
const pageTitle="Login";
class Login extends Component {
    constructor() {
        super();
        this.state = {
            userName: '',
            password: ''
        }
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    onChangeValues = (event) => {
        let _state = this.state;
        _state[event.currentTarget.name] = event.currentTarget.value;
        this.setState(_state);
    }
    handleSubmit = async () => {
        const { dispatch, history } = this.props;
        let resp = await dispatch(authenticateUser(this.state.userName, this.state.password, getSubDomain()));
        if (resp) {
            if (resp.loginUserResp.userDetails.isReset === 0) {
                let userid = resp.loginUserResp.userDetails.userId;
                let resetPwdToken = resp.pwdResetToken;
                history.push('/resetPassword?forgetToken=' + resetPwdToken + '&userid=' + userid)
            } else {
                history.push('/');
            }
        } else {
            console.log('invalid Access token');
            notify.createNotification('error', 'Invalid UserName/Password', this.props.refNotify);
        }
    } 
    render() {        
        return (<>
        <section className="wrapper" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat,no-repeat",
                backgroundPosition: "center center,center center",
                backgroundSize: "cover,cover"
            }}>
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center pd-20">
                <div className="login-box bg-white box-shadow pd-20 border-radius-5">
			        <img src={require('../../assets/images/SVMS Logo Final-A-FULL-MED.png')} alt="ACT" className="login-img"/>
			        <h6 className="text-center mb-20"><strong>SIGN IN TO CONTINUE.</strong></h6>
                    <Form name="frmLogin" className="mb-lg">
                        <Fragment>
                            <div className="input-group custom input-group-lg">
                                <TmInputGroup id="userName" name="userName" type="text" placeholder="Enter User name"
                                    className="form-control" value={this.state.userName} validations={[required]} onChange={(e) => {
                                        this.onChangeValues(e);
                                }} requiredmsg="Please enter user name" append='true' appendText={
                                <div className="input-group-append custom">
                                    <span className="input-group-text"><i className="fa fa-user" aria-hidden="true"></i></span>
                                </div>} />
                                                                            
                            </div>
                            <div className="input-group custom input-group-lg">
                                <TmInputGroup id="password" name="password" type="password" placeholder="Password"
                                    className="form-control" value={this.state.password} validations={[required]} onChange={(e) => {
                                                        this.onChangeValues(e);
                                                    }} requiredmsg="Please enter password" append='true' appendText={
                                                        <div className="input-group-append custom">
                                    <span className="input-group-text"><i className="fa fa-lock" aria-hidden="true"></i></span>
                                </div>}/> 
                            </div>
                            <div className="row mb-20">
                                <div className="col-sm-6">
                                        <div className="custom-control custom-checkbox custom-control">
                                            <input type="checkbox" className="custom-control-input" name="rememberMe" id="rememberMe"/>
                                            <label htmlFor="rememberMe" className="custom-control-label">REMEMBER ME</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link style={{ "font-weight": "490"}} to="/accountrecovery">Account Recovery</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="input-group">                                        
                                        <Button type="button" onClick={()=>{
                                            this.handleSubmit();
                                        }} className="btn btn-outline-primary btn-lg btn-block" >LOGIN </Button>
                                    </div>
                                </div>
                                
                            </div>                            
                        </Fragment>                                    
                    </Form>
                </div>
            </div>
            </section>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        refNotify:state.refNotify
    }
};

export default withRouter(connect(mapStateToProps)(Login))