import React, { Component, Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';

class AllInvoiceTable extends Component {
	
		state = {
		}
	render() {
        const cols=[          
            {
              Header: 'INVOICE#',
              accessor: 'invoice'
              
            }, 
            {
              Header: 'SERVICE CALL DATE',
              accessor: 'servicecalldate'
            }
          ];
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={this.props.reportData}
                        columns={this.props.selectedCols}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default AllInvoiceTable;