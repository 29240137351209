import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId, isManagement } from '../../util/customMethods';
import {getStateList} from './statesActions'
import {getLocationList} from './locationActions'
import {getAllTowTypes} from './towTypeActions'
import {getRatePlanList} from './ratePlanActions'
import {getCommissionTypes} from './commissionActions'
import { getChildList } from '../dispatchAction/invoiceSearchAction';

const getDepartments=()=>{
    const _url=`employee/select/departments?companyId=${getCompanyId()}&isManagement=${isManagement()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
       try{
           const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);       
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_DEPARTMENTS,
                    departmentList:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_DEPARTMENTS,
                        departmentList:undefined
                    });
                }            
        }
        catch(error){            
            dispatch({
                type: actionTypes.GET_DEPARTMENTS,
                departmentList:undefined
            });
        }
    }
}
const getLicenseTypes=()=>{
    const _url=`employee/select/licenseTypes?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
       // dispatch(spinnerActions.showSpinner());
       try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_LICENSE_TYPES,
                    licenseTypeList:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_LICENSE_TYPES,
                        licenseTypeList:undefined
                    });
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {            
            dispatch({
                type: actionTypes.GET_LICENSE_TYPES,
                licenseTypeList:undefined
            });
            //dispatch(spinnerActions.hideSpinner());
        }
    }
}
 const getAccountType=()=>{
    const _url=`account/select/accountTypes?idCompany=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_ACCOUNT_TYPE,
                    accountTypes:response.data
                });
            }else{
                    dispatch({
                        type: actionTypes.GET_ACCOUNT_TYPE,
                        accountTypes:[]
                    });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ACCOUNT_TYPE,
                accountTypes:[]
            });
          }   

    }
}
const getSalesRep=()=>{
    const _url=`account/select/salesRep?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async(dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_SALES_REP,
                    salesRep:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_SALES_REP,
                        salesRep:undefined
                    });
                }
            //dispatch(spinnerActions.hideSpinner());
        }catch(error){
            dispatch({
                type: actionTypes.GET_SALES_REP,
                salesRep:undefined
            });
            //dispatch(spinnerActions.hideSpinner());
          };
    }
}

const getCallReasons=()=>{
    const _url=`account/select/callReasons?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_CALL_REASONS,
                    callReasons:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_CALL_REASONS,
                        callReasons:undefined
                    });
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            dispatch({
                type: actionTypes.GET_CALL_REASONS,
                callReasons:undefined
            });
            //dispatch(spinnerActions.hideSpinner());
          };
    }
}

const getLocationDetailsBySearch=()=>{
    const _url=`config/getLocationDetailsBySearch/searchText?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_LOCATION_LIST,
                    locationList:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_LOCATION_LIST,
                        locationList:undefined
                    });
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            dispatch({
                type: actionTypes.GET_LOCATION_LIST,
                locationList:undefined
            });
            //dispatch(spinnerActions.hideSpinner());
          };
    }
}

const getComapanyTypes=()=>{
    const _url=`company/companyTypes?access_token=${getAccessToken()}`;
    return async (dispatch) => {
       // dispatch(spinnerActions.showSpinner());
       try{
           const response=await getAxiosInstance(moduleTypes.MANAGE).get(_url);       
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_COMPANY_TYPE,
                    companyType:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_COMPANY_TYPE,
                        companyType:undefined
                    });
                }            
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){            
            dispatch({
                type: actionTypes.GET_COMPANY_TYPE,
                companyType:undefined
            });
            //dispatch(spinnerActions.hideSpinner());
        }
    }
}

export{
    getStateList,
    getLocationList,
    getAllTowTypes,
    getDepartments,
    getLicenseTypes,
    getAccountType,
    getSalesRep,
    getCallReasons,
    getRatePlanList,
    getCommissionTypes,
    //getUserDefaultCharges,
    getLocationDetailsBySearch,
    getComapanyTypes,
    getChildList
}