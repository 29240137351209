import React, { Component,Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Scrollbars from 'react-custom-scrollbars';
import SideMenuCls from './sideMenuCls';
import {getAccessToken, isManagementCompany } from '../../../actions/authenticateActions';
import './Sidebar.css';
class Sidebar extends Component {
  constructor(props){
    super(props);
    this.handleRedirectClick=this.handleRedirectClick.bind(this);
    this.getRedirectUrl=this.getRedirectUrl.bind(this)
  }
 handleRedirectClick=(base,root,token)=>{
    console.log(base,root,token);
    window.location = window.location.origin+base+root //+'?access_token='+token;
  }
  getRedirectUrl=(base,root,token)=>{
    return window.location.origin+base+root //+'?access_token='+token;
}
getStatus=(selectedMenu, subItem)=>{
  return subItem.submenu.some((subMenuItem,key) => subMenuItem.spath.toUpperCase() === ('/'+selectedMenu.child).toUpperCase())
}
  
  render() {    
    const sideMenuCls=new SideMenuCls();
    const selectedMenu=sideMenuCls.getSelectedMenuObj(window.location.pathname);//{root:'admin',subRoot:'user',child:''}//
    return (
      <nav id="column_left" className="side-menu" style={{overflow:'hidden'}}>
          <Scrollbars style={{height:'90vh'}}>
            <ul className="nav-menu nav-list side-menu-nav-list" data-name="main">
            {
              sideMenuCls.getMenuList().map((mainItem,mainkey)=>{
                if(isManagementCompany() &&  mainItem.showManageCompany===false){
                  return false 
                }
                if(!isManagementCompany() && mainItem.showChildCompany===false){
                  return false;
                }
             if(sideMenuCls.havingAccess(mainItem,this.props.accessLevels,this.props.dashboardAccess)){
              return(
                mainItem.type==='I'?
                <li className={selectedMenu.root.toUpperCase()===mainItem.module.toUpperCase()?'active':''}>
                <>{/* <a href={this.getRedirectUrl('',mainItem.path,getAccessToken())}>
                        <i className="fa fa-tachometer" aria-hidden="true"></i>{mainItem.sidebarName}</a> */}
                        <Link to={mainItem.path}><i className="fa fa-tachometer" aria-hidden="true"></i>{mainItem.sidebarName}</Link></>
                </li> :
                <li className={selectedMenu.root.toUpperCase()===mainItem.rootName.toUpperCase()?'active':''} >
                  <Link to={mainItem.path} className="accordion-heading" data-toggle="collapse" data-target={`#submenu0${mainkey+1}`}>
                  <i className={mainItem.icon} aria-hidden="true"></i> <span className="nav-header-primary">{mainItem.sidebarName} <span className="pull-right"><i className="caret"></i></span></span></Link>
                   {mainItem.sub.length > 0 ? 
                  (<ul className={selectedMenu.root.toUpperCase()===mainItem.rootName.toUpperCase()?"nav-menu nav-list collapse show ":"nav-menu nav-list collapse"} id={`submenu0${mainkey+1}`} data-name="sub">
                  {                
                    mainItem.sub.map((subItem,key) =>{
                      if(sideMenuCls.havingAccess(subItem, this.props.accessLevels,this.props.dashboardAccess)){
                      return(
                         subItem.submenu.length > 0 ? 
                         (<li className={this.getStatus(selectedMenu, subItem)?'active':''
                        }><a href="javascript:void(0)" className="accordion-heading" data-toggle="collapse" data-target={`#submenu${mainkey+1}${key+1}`}><i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName} <span className="pull-right"><i className="caret"></i></span></a>
                       <ul className={this.getStatus(selectedMenu, subItem)?'nav-menu nav-list collapse show ':'nav-menu nav-list collapse'}  id={`submenu${mainkey+1}${key+1}`} data-name="sub">
                        {
                          subItem.submenu.map((subMenuItem,key) =>{
                            if(sideMenuCls.havingAccess(subMenuItem,this.props.accessLevels,this.props.dashboardAccess)){
                            return(
                              <li className={('/'+selectedMenu.child).toUpperCase()===subMenuItem.spath.toUpperCase()?'active':''}>
                              {window.location.pathname.split('/').includes(mainItem.rootName)&& ((subItem.isLaunch||false)===false)?
                              <Link to={subMenuItem.path}><i className="fa fa-minus" aria-hidden="true">    
                                  </i>{subMenuItem.sidebarName}</Link>
                              :
                              (((subItem.isLaunch||false)===true)?
                        <>{/* <a  href={this.getRedirectUrl('',subItem.path,getAccessToken())}>
                        <i className="fa fa-minus" aria-hidden="true"></i>{subItem.sidebarName}</a> */}
                        <Link to={subItem.path}><i className="fa fa-minus" aria-hidden="true"></i>{subItem.sidebarName}</Link></>
                        :
                        <>
                        {/* <a href={window.location.origin+subMenuItem.baseURL+subMenuItem.path}>
                        <i className="fa fa-minus" aria-hidden="true"></i>
                        {subMenuItem.sidebarName}</a> */}
                        <Link to={subMenuItem.path}><i className="fa fa-minus" aria-hidden="true"></i>
                        {subMenuItem.sidebarName}</Link>
                        </>
                        )
                              }
                              </li>
                            )  
                            }                        
                          })
                        }
                        </ul>
                        </li>
                      ) 
                        :
                        (<li className={('/'+selectedMenu.child).toUpperCase()===subItem.spath.toUpperCase()?'active':''}>{window.location.pathname.split('/').includes(mainItem.rootName) && ((subItem.isLaunch||false)===false)?
                        <Link to={subItem.path}><i className="fa fa-arrow-right" aria-hidden="true">    
                        </i>{subItem.sidebarName}</Link>:
                        (((subItem.isLaunch||false)===true)?
                        <>{/* <a href={this.getRedirectUrl('',subItem.path,getAccessToken())}
                        >
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName}</a> */}
                        <Link to={subItem.path}>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName}</Link></>
                        :
                        <>{/* <a href={this.getRedirectUrl(subItem.baseURL,subItem.path,getAccessToken())}>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName}</a> */}
                        <Link to={subItem.path}>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName}</Link>
                        </>)}</li>)                      
                      )
                      }
                  })
                  }</ul>):null
                  
                  }
                </li>                  
                
              )
             }
            })
          }
            </ul> 
            </Scrollbars>
          </nav>
    );
  }
}
export default withRouter(Sidebar)
