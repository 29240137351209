import React, { Component } from 'react';
import './footer.css';
class Footer extends Component {
    render() {
        return (
            // <div className="pd-45 bg-white border-radius-4 box-shadow mt-35 footer-wrap">
            <div className="container-fluid">
                <footer className="footer-wrap">
                   <div className='text-center align-self-auto' style={{backgroundColor: 'white', fontWeight: 'normal'}}>
                        Copyright © {new Date().getFullYear()} ACT Towing LLC, All Rights Reserved
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;