import storage from 'redux-persist/es/storage';
//import storageSession  from 'redux-persist/lib/storage/session';
import { persistCombineReducers } from 'redux-persist'
import {setNotificationRefReducer} from './setNotificationRefReducer';
import {companyReducer} from './adminReducers/companyReducer';
import {authReducer, accessLevelReducer,dashboardAccessReducer} from './authReducer';

import {adminDashboardReducer} from './launchReducers/adminDashboardReducer'
import {dispatchDashboardReducer} from './launchReducers/dispatchDashboardReducer'
import {reportsDashboardReducer} from './launchReducers/reportsDashboardReducer';

import {accountReducer} from './adminReducers/accountReducer';
import {towTypeReducer} from './adminReducers/towTypeReducer'
import {auctionReducer} from './adminReducers/auctionReducer';
import {truckReducer} from './adminReducers/truckReducer';
import {stateReducer,departmentReducer,licenseTypeReducer,accountTypeReducer,
   salesRepReducer,callReasonsReducer,locationListReducer,companyTypeReducer} from './adminReducers/stateReducer';
import {ratePlanReducer} from './adminReducers/ratePlanReducer';
import {statementMessageReducer} from './adminReducers/statementMessageReducer';
import {locationReducer} from './adminReducers/locationReducer';
import {billingReportEmailReducer} from './adminReducers/billingReportEmailReducer';
import {vendorReducer} from './adminReducers/vendorReducer';
import {commissionReducer} from './adminReducers/commissionReducer';
import {employeeReducer} from './adminReducers/employeeReducer';
import {apkUploadReducer} from './adminReducers/apkUploadReducer';
import {dispatchCallsReducer} from './dispatchReducers/dispatchCallsReducer';
import {invoiceSearchReducer} from './dispatchReducers/invoiceSearchReducer';
import {invoiceReducer} from './dispatchReducers/invoiceReducer';
import {reviewInboxReducer} from './dispatchReducers/reviewInboxReducer';
import {arSummaryReducer} from './reportReducers/accounting/arSummaryReducer';
import {arDetailsReducer} from './reportReducers/accounting/arDetailsReducer';
import {arDetailByAccountTypeReducer} from './reportReducers/accounting/arDetailByAccountTypeReducer';
import {monetizationDetailsReducer} from './reportReducers/monetization/monetizationReducer';
import {crDetailsReducer} from './reportReducers/monetization/crDetailReducer';
import {auctionBatchPaymentReducer} from './reportReducers/monetization/auctionBatchPaymentReducer';
import { pendingTasksReducer} from 'react-redux-spinner';
import {repAccReducer} from './reportReducers/accounting/reportsAccountListReducer';
import {batchPaymentReducer} from './reportReducers/accounting/batchPaymentReducer';
import {allInvoiceReducer} from './reportReducers/allInvoiceReducer.js';
import {storageManagement} from './reportReducers/storageManagement/storageManagement';
import {driverReducer} from './reportReducers/driver/driverReducer';
import { invoiceManageReducer } from './reportReducers/invoiceManagement/invoiceManageReducer';
import { summaryReducer } from './reportReducers/summary/summaryReducer';
import { auditReducer } from './reportReducers/accounting/auditReducer';
export default persistCombineReducers({ key: 'manage-root', storage:storage,blacklist:['refNotify'] },{
    refNotify:setNotificationRefReducer,
    pendingTasks: pendingTasksReducer,
    companies:companyReducer,
    authInfo:authReducer,
    accessLevel:accessLevelReducer,
    dashboardAccess:dashboardAccessReducer,	
    adminDashboard:adminDashboardReducer,
    dispatchDashboard:dispatchDashboardReducer,
    reportDashboard:reportsDashboardReducer,
    accountList:accountReducer,
    towTypeList:towTypeReducer,
    auction:auctionReducer,
    truck:truckReducer,
    states:stateReducer,
    ratePlanList:ratePlanReducer,
    statementMsg:statementMessageReducer,
    location:locationReducer,
    billingReportEmail:billingReportEmailReducer,
    vendorList:vendorReducer,
    employees:employeeReducer,
    commission:commissionReducer,
    department:departmentReducer,
    licenseTypes:licenseTypeReducer,
    accountTypes:accountTypeReducer,
    salesRep:salesRepReducer,
    callReasons:callReasonsReducer,
    locationList:locationListReducer,
    apkUploads:apkUploadReducer,
    dispatchCalls:dispatchCallsReducer,
    searchInvoiceList:invoiceSearchReducer,
    invoiceInfo:invoiceReducer,
    companyTypes:companyTypeReducer,
    reviewInbox:reviewInboxReducer,
    arSummary:arSummaryReducer,
    arDetailsList:arDetailsReducer,
    arDetailsAccountTypeList:arDetailByAccountTypeReducer,
    monetizationDetailsList:monetizationDetailsReducer,
    crDetailsList:crDetailsReducer,
    auctionPaymentDetails:auctionBatchPaymentReducer,
    reportsAccountList:repAccReducer,
    bpDetailsList:batchPaymentReducer,
    allInvoice:allInvoiceReducer,
    storageManagement:storageManagement,
    driverObj:driverReducer,
    invoiceManageObj:invoiceManageReducer,
    summaryObj:summaryReducer,
    auditObj : auditReducer
});