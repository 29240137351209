//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
import React, { Component }  from 'react';
import { isManagement } from '../../../../util/customMethods';
export  const tableColumns = [
    {
        accessor: 'accountName',
        Header: 'Account Name',
        width: 400,
        Cell: row => <span className='number'>Account Name:{isManagement() ? <a href="javascript:void(0)">{' '+row.original.accountName}</a>:<a href={'/admin/accountDetails?aid=' + row.original.accountId} target="_blank"
    >{' '+row.original.accountName}</a>}</span>
    },
    {
        accessor: 'accountPrimaryContact',
        Header: 'Account Primary Contact',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'asOfDate',
        Header: 'As Of Date',
        style: { textAlign: 'center' },
        width: 200
    }
];


