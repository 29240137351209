import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const companyObj={
    "idCompany": 0,
    "idCorporation": 1,
    "companyType":"",
    "code": "",
    "name": "",
    "description": "",
    "logoPath": "",
    "subdomain": "",
    "isActive": true,
    "contactNo": "",
    "paymentDetails": "",
    "addressLine1": "",
    "addressLine2": "",
    "city": "",
    "state": "",
    "zip": "",
    "fax": "",
    "contactEmail": "",
    "reviewDate": null,
    "companyBilling": {
      "idCompanyBilling": 0,
      "idCompany": 0,
      "addressLine1": "",
      "addressLine2": "",
      "city": "",
      "state": "",
      "zip": "",
      "isSameCompInfo":false,
      "fax": "",
      "billingEmail": "",
      "phone": "",
      "billableType": "",
      "billableAmt":"",
      "companyBillingContacts": [],
      "companyBillingNotes": []
    },
    "companyContactInfos": [],
    "companyNotes": [],
    "companyHistories": [],
    "employee": {
      "idEmployee": 0,
      "firstName": "",
      "lastName": "",
      "emailId": "",
      "lastUpdated": "",
      "emp_status": 1,
      "idCompany": 0,
      "isPrimary": true,
      "emer_Contact_no": "",
      "userAuth": {
        "userId": "",
        "tempPassword":""
      }
    },
    "isAccountAssignChange": false,
    "companyAccountMappings": []
  }
 
const paymentDetails = {
  id: 0,
  companyId: 0,
  marchantId: '',
  userId: '',
  keyInEntry: '',
  pinSwipe: '',
  payLocationPinJson: []
}

const defaultCompany={
    companyList:[],
    companyRec:companyObj,
    paymentObj:paymentDetails
};       

export const companyReducer = (state = defaultCompany, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_LIST:
      return { ...state, companyList: action.payload || defaultCompany.companyList };
    case actionTypes.GET_COMPANY_PAY_DETAILS:
      return { ...state, paymentObj: JSON.parse(JSON.stringify(action.payload || defaultCompany.paymentObj)) };
    case actionTypes.GET_COMPANY_RECORD:
      return { ...state, companyRec: action.payload || JSON.parse(JSON.stringify(defaultCompany.companyRec)) };
    default:
      return state;
  }
};