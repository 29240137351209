import React, { Component, Fragment } from 'react';
import TmMultiSelectList from '../../../utilComponents/tmMultiSelectList/tmMultiSelectList'

class AccountAssign extends Component {
    onAccountAssignChange=(selectedOption)=>{
        let selectedAccounts = [];
        (this.props.selectedAccounts||[]).forEach(item=>{
            selectedAccounts.push({
                "idAccount": item.idAccount,
                "accountName":item.accountName 
            });
        });
        (selectedOption || []).forEach(selectedVal => {
            selectedAccounts.push({
                "idAccount": selectedVal.idAccount,
                "accountName":  selectedVal.accountName
            });
        });            
        this.props.onCompanyBasicInfoFieldsChange('companyAccountMappings', selectedAccounts);
    }
    onAccountUnAssignChange=(selectedOption)=>{
        let selectedAccounts = [];        
        (selectedOption || []).forEach(selectedVal => {
            selectedAccounts.push({
                "idAccount": selectedVal.idAccount,
                "accountName":  selectedVal.accountName
            });
        });            
        this.props.onCompanyBasicInfoFieldsChange('companyAccountMappings', selectedAccounts);
    }
    render(){
        const accountList=this.props.accountList||[];
        const selectedAccounts=this.props.selectedAccounts||[];
        const { cid }=this.props;
        return(
        <>
            <div className="pd-10">
                <fieldset>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mb-10">
                            <div className="mb-5 row">
                                <div className="col-sm-12">
                                    <h6>ACCOUNT ASSIGNER</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8 col-sm-12 offset-md-2">
                                    <TmMultiSelectList 
                                    accountList={accountList}
                                    selectedAccounts={selectedAccounts} 
                                    updateSelectedList={(list)=>{
                                        this.onAccountAssignChange(list)
                                    }} updateUnSelectedList={(list)=>{
                                        this.onAccountUnAssignChange(list)
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <div className="mr-3">
                        <button className="btn btn-success btn-sm pull-right" onClick={() => {
                                this.props.onSubmitAccountAssign();
                        }}>NEXT</button>
                    </div>
                    {(cid <= 0) ? null : <div className="mr-4">
                        <button
                            className="btn btn-sm btn-success pull-right"
                            style={{ marginTop: '0px' }} onClick={(e) => { 
                                e.preventDefault();      
                                this.props.onSaveCompany();                            
                            }}
                        >SAVE COMPANY</button>
                    </div>}
                </div>
                </fieldset>
            </div>
    </>)
    }

}
export default AccountAssign