import * as actionTypes from '../../actions/actionTypes';
const defaultTowType={
  towTypes:[],
  towTypeObject: {
    "towType":"",
    "towTypeCd":"",
    "towTypeDesc":"",
    "towTypeId":0
  }
  
}

export const towTypeReducer = (state = defaultTowType, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_TOW_TYPES:
      return {...state , towTypes:(action.towTypes || defaultTowType.towTypes)};
    case actionTypes.GET_TOWTYPE_RECORD:
      return {...state , towTypeObject:(action.towTypeObject || defaultTowType.towTypeObject)};
    case actionTypes.CLEAR_TOWTYPE_RECORD:
      return {...state , towTypeObject:defaultTowType.towTypeObject};
    default:
      return state;
  }
};