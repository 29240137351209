//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
import React, { Fragment }  from 'react';
import { isManagement } from '../../../../util/customMethods';
export  const InvoicetableColumns = [
    {
        accessor: 'invoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <Fragment>{isManagement()?<a href="javascript:void(0)">{' '+props.original.displayInvoiceId}</a>:<a href={'/dispatch/serviceCallInfo/'+props.original.invoiceId} target="_blank"
    >{props.original.displayInvoiceId}</a>}</Fragment>,
        width: 200
    },
    {
        accessor: 'serviceCallDate',
        Header: 'Service Call Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'dueDate',
        Header: 'Due Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'age',
        Header: 'Age',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'balance',
        Header: 'Balance',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.balance}`
    }
];