import React, { Component,Fragment } from 'react';
import ReactTable from 'react-table-v6';
import {Form,Col,Button,Modal} from 'react-bootstrap';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment';
import {GlobalValues} from '../../../../util/constants'
import {getUserName,getLoginUserName,getLoginUserId} from '../../../../util/customMethods'
import ReactTablePagination from '../../../utilComponents/tmReactTable/reactTablePagination'
const defaultNoteObj={
    empId: 0,
    empRemarks: "",
    remarkDate: "",
    remarkId: 0,
    remarkTime: "",
    userFullName: "",
    userId: ""
}
class UserNotes extends Component {
  constructor(props){
    super(props);
    this.state={
      modalIsOpen:false,
      validated: false,
      nodeObj: Object.assign({},defaultNoteObj)

    }
    this.handleSubmit=this.handleSubmit.bind(this);
    this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
  }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'USER ID'},{title:'USER FULL NAME'},{title:'DATE'},{title:'TIME'},{title:'REMARKS'}],
      data: this.props.notesData.map((item)=>{
        return [{value:item.userId||''},{value:item.userFullName||''},{value:item.remarkDate||''},{value:item.remarkTime||''},{value:item.empRemarks||''}]
      })
    }])
  }
  handleSubmit=(event)=>{
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {      
      event.stopPropagation();
      this.setState({ validated: true });
    }else{
      let _nodeObj=Object.assign({},this.state.nodeObj) 
      this.setState({ validated: false, nodeObj:Object.assign({},defaultNoteObj),modalIsOpen:false},
      this.props.onNotesSubmit(event,_nodeObj));
    }
    
  }
    render(){
      const { validated } = this.state;
      const excelDataSet=this.buildDataFormatForExport();
        const notesCols=[{
            Header: 'USER ID',
            accessor: 'userId',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: '100%'
            
          }, {
            Header: 'USER FULL NAME',
            accessor: 'userFullName',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: 200
          }, {
            Header: 'DATE',
            accessor: 'remarkDate',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: 200
          },
          {
            Header: 'TIME',
            accessor: 'remarkTime',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: '100%'
          },
          {
            Header:"REMARKS",
            accessor: 'empRemarks',
            filterable:true,
            style: {overflow: 'wrap', whiteSpace: 'unset'},
            Cell: props => <span>{props.value}</span>,
            width: '100%'
        }];
          
        return(<Fragment>
        <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
			    <div className="pull-left">                
				    <h6 className="text-blue">Remarks</h6>                    
			    </div>                
            <div className="pull-right">
              <div className="btn-list">
              <TmExportExcel name={'Notes'} filename={`${"Notes_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                dataSet={excelDataSet} />

                <button disabled={this.props.disabled} className="btn btn-success btn-sm " onClick={(e)=>{
                  this.setState({ modalIsOpen: true });
                }} ><i className="fa fa fa-plus"></i> Add Remarks</button>
              </div>                                     
            </div>
        </div></div>        
        <div className="row">
        <div className="col-md-12 col-sm-12 mb-30">
            <ReactTable 
            filterable 
            sortable
            resizable
            defaultPageSize={5}
            PaginationComponent={ReactTablePagination}
            data={this.props.notesData||[]}
            columns={notesCols}
        /> 
        </div>
        </div>

        <Modal show={this.state.modalIsOpen} > 
				<div className="modal-header">
            <Modal.Title>{'Add Remark'}</Modal.Title>
        </div>
        <Form noValidate validated={validated} onSubmit={e => this.handleSubmit(e)} >
            <div className="modal-body">
              <Form.Group controlId="noteCtrl">
                <Form.Label>Remark *</Form.Label>
                <Form.Control as="textarea"  required onChange={(e)=>{
                  let _nodeObj=this.state.nodeObj
                  _nodeObj.empRemarks=e.currentTarget.value
                  _nodeObj.remarkDate=moment().format(GlobalValues.dateFormat);
                  _nodeObj.remarkTime=moment().format(GlobalValues.timeFormat);
                  _nodeObj.userFullName=getUserName();
                  _nodeObj.userId=getLoginUserName();
                  this.setState({nodeObj:_nodeObj});
                }} />
                <Form.Control.Feedback type="invalid">
                Please enter employee remarks.
              </Form.Control.Feedback>
              </Form.Group>
            </div>
             
            <div className="modal-footer">         
            <Button variant="secondary" className="btn btn-danger btn-sm" onClick={(e)=>{
              this.setState({ modalIsOpen: false });
            }}>Close</Button>
            <Button type="submit" variant="primary" className="btn btn-success btn-sm">Save changes</Button>
            </div>
        </Form>
        
        </Modal>
        
        </Fragment>);
    }

}
export default UserNotes;