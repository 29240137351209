import * as actionTypes from '../../actions/actionTypes';
const defaultBillingEmail = {
  billingEmail:'', 
  emailAddr:''
}

export const billingReportEmailReducer = (state = defaultBillingEmail, action) => {
  switch (action.type) {
    case actionTypes.GET_BILLING_EMAIL:
      return {...state, billingEmail: (action.billingEmail.billingEmail || defaultBillingEmail.billingEmail)};
    case actionTypes.GET_EMAIL_REC:
      return {...state, emailAddr:(action.emailAddr || defaultBillingEmail.emailAddr)};
      
    default:
      return state;
  }
};