import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required,isValidForm} from '../../../../util/validationMethods';
import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import moment from 'moment'
import {GlobalValues} from '../../../../util/constants'
const defaultServiceObj={
    idTruckServiceDetails: null,
    createdDate: moment().format(GlobalValues.dateFormat ),
    createdTime: moment().format(GlobalValues.timeFormat ),    
    serviceStatus: "",
    serviceStatusUpdateDate: null,
    truckId: null,
    userId: "",
  }
class TruckInfoServiceRecord extends Component {
    constructor(){
        super();
        this.state={            
            serviceObj:Object.assign({},defaultServiceObj)
        }
        this.handleServiceSubmit=this.handleServiceSubmit.bind(this);
    }
    handleServiceSubmit(event) {
        event.preventDefault();
        let _serviceObj=Object.assign({},this.state.serviceObj)
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{
            this.setState({
                serviceObj:Object.assign({},defaultServiceObj)
            },
            this.props.onServiceSubmit(event,_serviceObj));
        }
      }
      componentWillReceiveProps=(newProps)=>{
         let _serviceObj= this.state.serviceObj;
         _serviceObj.serviceStatus=newProps.truckPrevServiceStatus
        this.setState({serviceObj:_serviceObj})

      }
render(){
    const { serviceObj } =this.state;
    return (
        <div className="card box-shadow">
                                <div className="card-header"><h5>Add Service Details</h5></div>
                                <div className="card-body"> 
                                <Form ref={c => { this.form = c }}> 
                                <fieldset disabled={this.props.disabled}>
                                <div className="row">
                                <div className="col-md-2 col-sm-12">
                                    <label className="col-form-label"></label>
                                    <div className="custom-control custom-radio mb-5">
                                    <input type="radio" name="serviceStatus" checked={serviceObj.serviceStatus==='In Service'} value="In Service" id='inServiceCtrl' className="custom-control-input"
                                    onChange={(e)=>{
                                        let _recObj=this.state.serviceObj;
                                        _recObj[e.target.name]=e.target.value;
                                        this.setState({serviceObj:_recObj})
                                    }} />
                                    <label className="custom-control-label" htmlFor="inServiceCtrl">In Service</label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12">
                                <label className="col-form-label"></label>
                                <div className="custom-control custom-radio mb-5">
                                <input type="radio" name="serviceStatus"  checked={serviceObj.serviceStatus==='Out of Service'} id='outServiceCtrl' value="Out of Service" className="custom-control-input"
                                onChange={(e)=>{
                                        let _recObj=this.state.serviceObj;
                                        _recObj[e.target.name]=e.target.value;
                                        this.setState({serviceObj:_recObj})
                                    }} />
                                <label className="custom-control-label" htmlFor="outServiceCtrl">Out of Service</label>
                                </div>
                                </div>
                                <div className="col-md-2 col-sm-12">
                                <label className="col-form-label"></label>
                                <div className="custom-control custom-radio mb-5">
                                <input type="radio" name="serviceStatus" checked={serviceObj.serviceStatus==='Sold'} value="Sold" id='SoldCtrl' className="custom-control-input" 
                                onChange={(e)=>{
                                        let _recObj=this.state.serviceObj;
                                        _recObj[e.target.name]=e.target.value;
                                        this.setState({serviceObj:_recObj})
                                    }} />
                                <label className="custom-control-label" htmlFor="SoldCtrl">Sold</label>
                                </div>
                                </div>
                                
                                <div className="col-md-3">
                                <label className="col-form-label col-md-12" style={{paddingTop:'0px', paddingLeft:'0px'}}>Date *</label>
                                <div className="col-md-12" style={{paddingLeft:'0px'}}>
                                    <TmDateTimePicker selectedDate={(serviceObj.createdDate||'')===''?'': moment(serviceObj.createdDate)._d}
                                    dateFormat={GlobalValues.dateFormat}
                                    validationArray={[required]}
                                    name="Service Date"
                                            showYearDropdown={true}
                                            showmonthdropdown={true}
                                    onDateSelectChange={(e)=>{
                                            let _recObj=this.state.serviceObj;
                                            _recObj['createdDate']= e===null?null:moment(e).format(GlobalValues.dateFormat)|| '';
                                            this.setState({serviceObj:_recObj})
                                        }} disabled={this.props.disabled} />
                                </div></div>
                                <div className="col-md-3">
                                <label className="col-form-label"></label>
                                <div>
                                <button type="button" className="btn btn-success btn-sm" onClick={(e)=>{
                                                this.handleServiceSubmit(e)
                                            }} > Add Service Details</button>
                                </div>
                                </div>
                                </div>
                                </fieldset>
                                </Form>
                                </div>

                                </div>
    )
}
}
export default TruckInfoServiceRecord