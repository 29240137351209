import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
class PPIRevenueReportCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                    <Form ref={c => {
                                        this.formPPIRevenueReportInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                        <div className="col-1 form-group">
                                            <label htmlFor="fromdate" className="form-label col-form-label">From</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.fromdate === "" || this.props.fromdate === null
                                                ? null
                                                : moment(
                                                    this.props.fromdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="fromDate"
                                        reqmsg="From date is required"
                                        onDateSelectChange={e => {
                                            this.props.onPPIRevenueFieldChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="todate" className="form-label col-form-label">To</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.todate === "" || this.props.todate === null
                                                ? null
                                                : moment(
                                                    this.props.todate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="toDate"
                                        reqmsg="To date is required"
                                        onDateSelectChange={e => {
                                            this.props.onPPIRevenueFieldChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-6 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            >Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default PPIRevenueReportCtrl;