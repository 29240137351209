import React, { Component, Fragment } from 'react';
class AuctionBatchPaymentCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <form className="form-row">
                                        <div className="col-3 form-group">
                                            <input type="file" className=""
                                                name="aucionFile" value={''}
                                            />
                                        </div>
                                        <div className="col-2 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" >Go</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div></div>       
            </Fragment>
        );
    }
}
export default AuctionBatchPaymentCtrl;