import React, { Component,Fragment } from 'react';
import ReactTable from 'react-table-v6';
import {Form,Col,Button,Modal} from 'react-bootstrap';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
import moment from 'moment';
import AccountInfoContacts from './accountInfoContacts';
const defaultContactObj={
  "accountId": 0,
  "companyId": 0,
  "contactId": 0,
  "contactName": "",
  "corporateId": 0,
  "defaultEmailId": 0,
  "emailId": "",
  "ext": "",
  "phoneNo": ""
}
class AccountInfoContactList extends Component {
    constructor(props){
      super(props);
      this.state={       
        modalIsOpen:false,
        accountContactRec: JSON.parse(JSON.stringify(defaultContactObj)),
        accountContactRecIndex: -1  
      }
      this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this);
      this.onContactFieldChange=this.onContactFieldChange.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
    buildDataFormatForExport=()=>{
      return([{
        columns:[{title:'CONTACT NAME'},{title:'PHONE NO'},{title:'EXT'},{title:'EMAIL ID'}],
        data: this.props.contactListData.map((item)=>{
          return [{value:item.contactName||''},{value:item.phoneNo||''},{value:item.ext||''},{value:item.emailId||''}]
        })
      }])
  }
  handleClose(){
    this.setState({modalIsOpen:false,accountContactRecIndex:-1,accountContactRec:Object.assign({},defaultContactObj)})
  }
  onContactFieldChange=(name,value)=>{
    let _recObj=this.state.accountContactRec;
    _recObj[name]=value;
    this.setState({accountContactRec:_recObj})
  }
    render(){
        const excelDataSet=this.buildDataFormatForExport();
        const contactCols=[
          {
            Header: 'DEFAULT',
            accessor: 'defaultEmailId', // String-based value accessors!
            filterable:true,
            Cell: props =>
              <div className="custom-control custom-radio custom-control custom-radio ml-4">
                <input type="radio" disabled={this.props.disabled} id={"rdDefaultEmailId"+props.index} className="custom-control-input" data-idx={props.index} name="defaultEmailId" 
                    checked={props.original.defaultEmailId===1?true:false}  onChange={(e)=>{
                      this.props.onDefaultContactChanged(e,e.target.getAttribute('data-idx'));
                    }}/>
                <label  htmlFor={"rdDefaultEmailId"+props.index} className="custom-control-label"></label>
              </div>,
            width:'100',
            show:this.props.showDefaultOption===true?true:false

          },          
          {
            Header: 'CONTACT NAME',
            accessor: 'contactName', // String-based value accessors!
            filterable:true,
            Cell: props => props.value,
            width:'100%'
            
          }, {
            Header: 'PHONE NO',
            accessor: 'phoneNo',
            filterable:true,
            Cell: props => props.value,
            width: 200
          }, {
            Header: 'EXT',
            accessor: 'ext',
            filterable:true,
            Cell: props => props.value,
            width: 200
          },
          {
            Header: 'EMAIL ID',
            accessor: 'emailId',
            filterable:true,
            Cell: props => props.value,
            width: 200
          },
          {
            Header: 'Edit',
            filterable:false,
            Cell: (props) => <button type="button" data-idx={props.index}  className="btn btn-success btn-sm" 
              onClick={(e)=>{
                //this.props.onContactEdit(e,e.target.getAttribute('data-idx'));
                let idx=e.target.getAttribute('data-idx');
                let selectedContact=Object.assign({},this.props.contactListData[idx]);
                this.setState({accountContactRec:selectedContact ,accountContactRecIndex:idx,modalIsOpen:true})          
              }} ><i className="fa fa-edit"  data-idx={props.index}></i> Edit</button>,
            width: 100,
            show:!this.props.disabled
          },
          {
            Header: 'Delete',
            filterable:false,
            Cell: (props) => <button type="button" data-idx={props.index}  className="btn btn-danger btn-sm" 
              onClick={(e)=>{
                this.props.onContactDelete(e.target.getAttribute('data-idx'));
              }} ><i className="fa fa-trash" data-idx={props.index}></i> Delete</button>,
            width: 100,
            show:!this.props.disabled
          }
        ];
          
        return(<Fragment>
        <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
			    <div className="pull-left">
				    <h6 className="text-blue">Contact Details</h6>				
			    </div>
                <div className="pull-right">
                  <div className="btn-list">
                    <TmExportExcel name={'Notes'} filename={`${"ContactDetails_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                            element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                            dataSet={excelDataSet} />
                    <button className="btn btn-success btn-sm " onClick={(e)=>{
                        this.setState({ modalIsOpen: true,accountContactRecIndex:-1,accountContactRec:Object.assign({},defaultContactObj)});
                        }} disabled={this.props.disabled} ><i className="fa fa fa-plus"></i>ADD CONTACT</button>
                  </div>
                </div>
        </div></div>
        <div className="row">
        <div className="col-md-12 col-sm-12 mb-30">
            <TmReactTable 
            filterable 
            sortable
            resizable
            defaultPageSize={3}
            data={this.props.contactListData}
            columns={contactCols}
        /> 
        </div>
        </div>
        <Modal show={this.state.modalIsOpen} > 
				<div className="modal-header">
            <Modal.Title>{this.state.accountContactRec.contactId === 0 ? 'Add Contact' : 'Edit Contact'}</Modal.Title>
        </div>
        <div className="modal-body">
                  <AccountInfoContacts isPhoneRequired={this.props.isPhoneRequired} isEmailRequired={this.props.isEmailRequired} submitBtnText={this.state.accountContactRec.contactId === 0 ? 'Add New Contact' : 'Save Contact'} 
                    onContactSubmit={(e) => {
                        this.props.onContactSubmit(e, this.state.accountContactRec,this.state.accountContactRecIndex,(success)=>{
                          this.setState({modalIsOpen:false,accountContactRecIndex:-1,accountContactRec:Object.assign({},defaultContactObj)})
                        },(error)=>{
                        });    
                    }}
                    contactObj={this.state.accountContactRec} onContactFieldChange={(name,value)=>this.onContactFieldChange(name,value)} handleClose={this.handleClose}/>
        </div>
        </Modal>
        </Fragment>);
    }

}
export default AccountInfoContactList