import * as actionTypes from '../../actions/actionTypes';
const defaultLocations={
    reviewLocationList:{
      location:'',
      reviewedInvoicesCount:0
    },
    reviewInvoiceList:{
      reviewedInvoices:[],
      location:'',
      allReviewLocations:[]
    }
}
export const reviewInboxReducer = (state = defaultLocations, action) => {
  switch (action.type) {
    case actionTypes.GET_REVIEW_LOCATION_LIST:
            return {...state, reviewLocationList:action.reviewLocationList||defaultLocations.reviewLocationList} ;
    case actionTypes.GET_REVIEW_INVOICE_LIST:
            return {...state, reviewInvoiceList:action.reviewInvoiceList||defaultLocations.reviewInvoiceList} ;
    default:
      return state;
  }
};