import React from 'react';
import {tableColumns} from './subTableConfig'
import {ARDetailsClmn} from '../../commonComponents/ARReportComClmn';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
let status = true;
class ReactTableDisplay extends React.Component {

    state = {
        expanded: { 0: true }
    }

    handleExpand = () => {
        const expandedObj = {}
        this.props.data.forEach((item, index) => expandedObj[index] = true)
        this.setState({ expanded: expandedObj })
    }

    handleCollapse = () => {
        const expandedObj = {}
        this.props.data.forEach((item, index) => expandedObj[index] = false)
        this.setState({ expanded: expandedObj })
    }
    render(){
    return (
        <React.Fragment>
            <div className="form-group">
                <button className="btn btn-success btn-xs mr-2" style={{width:'auto'}} onClick={this.handleExpand}>Expand All</button>
                <button className="btn btn-success btn-xs" style={{width:'auto'}} onClick={this.handleCollapse}>Collapse All</button>
            </div>
        <TmReactTable
        
            data={this.props.data}
            defaultPageSize={10}
            showPagination={true}
            sortable={false}
            getTableProps={() => ({ style: { textTransform: 'uppercase', fontSize: '12px', fontWeight: 700 } })}
            getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
            ref={this.props.tableReference}
            filterable={false}
            onFilteredChange={this.props.tableDataChanged}
            onSortedChange={this.props.tableDataChanged}
            resizable={true}
            expanded={this.state.expanded}
            onExpandedChange={e => this.setState({expanded: e})}
            columns={this.props.columns}
            className='-striped -highlight'
            noDataText='No data found'
              SubComponent={row => {
                  let arr=[];
                  arr.push(row.original)
                return (
                    <div >
                        <em>
                            <TmReactTable
                                data={arr}
                                showPagination={true}
                                defaultPageSize={10}
                                sortable={false}
                                getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
                                filterable={false}
                                resizable={true}
                                columns={ARDetailsClmn}
                                className='-striped -highlight'
                                noDataText='No data found'
                            />
                        </em>
                        {((row.original.accountsReceivableAccTypeDetailList || []).length > 0) &&
                        <TmReactTable
                            data={row.original.accountsReceivableAccTypeDetailList || []}
                            defaultPageSize={10}
                            showPagination={true}
                            sortable={false}
                            getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
                            filterable={false}
                            resizable={true}
                            columns={tableColumns}
                            className='-striped -highlight'
                            noDataText='No data found'
                        />
                        }
                    </div>
                );
              }}
        />
         </React.Fragment>
    )};
};
export default ReactTableDisplay;