import React, { Component,Fragment } from 'react';
import ReactTable from 'react-table-v6';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment';
import { getCompanyId } from '../../../../util/customMethods';
class AccountHistory extends Component {
  constructor(props){
    super(props)
    this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
  }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'DATE'},{title:'TIME'},{title:'USER ID'},{title:'USER FULL NAME'},{title:'CHANGE MODE'}],
      data: this.props.accountBasicInfo.accountHistories.map((item)=>{
        return [{value:item.createdDate||''},{value:item.createdTime||''},{value:item.userId||''},
        {value:item.userFullName||''},{value:item.changeMode||''}]
      })
    }])
}

checkAccess = () => {
  if ((this.props.accountBasicInfo.companyId === getCompanyId()) || (this.props.accountBasicInfo.companyId === 0)) {
    if (this.props.accountBasicInfo.accountId === 0) {
        if ((this.props.accessLevel.basicAccess.add || false) === true) {
            return false
        } else {
            return true
        }
    } else {
        if ((this.props.accessLevel.basicAccess.edit || false) === true) {
            return false;
        } else {
            return true;
        }
    }
  }else{
    return true;
  }
}

    render(){
      const excelDataSet=this.buildDataFormatForExport();
        const historyCols=[{
            Header: 'DATE',
            accessor: 'createdDate', // String-based value accessors!
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            minWidth:'60px'
            
          }, {
            Header: 'TIME',
            accessor: 'createdTime',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            minWidth: '60px'
          }, {
            Header: 'USER ID',
            accessor: 'userId',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            minWidth: '150px'
          },
          {
            Header: 'USER NAME',
            accessor: 'userFullName',
            filterable:true,
            Cell: props => <a className="btn btn-link" href="javascript:void(0)">{props.value}</a>,
            minWidth: '200px'
          },
          {
            Header: 'DETAIL',
            accessor: 'changeMode',
            filterable:false,
            Cell: (props) =><span>{props.value}</span>,
            minWidth:'100%'
          }];
        return(<Fragment>
        <div className="pd-10">
        <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <h6 className="text-blue text-bg-color form-group">ACCOUNT HISTORY</h6>
                    </div>
                </div>
            <div className="row mb-10">
            <div className="col-md-12 col-sm-12 md-10">
            <div className="col-md-12">
              <div className='row pull-right'>
              <TmExportExcel name={'Account History'} filename={`${"AccountHistory_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'} element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>} dataSet={excelDataSet} />
              </div>
              </div>
            <div className="row">
              <div className="col-md-6 ">
              <p>Account Creation Date: <strong className="weight-600">{this.props.accountBasicInfo.createdDate}</strong></p>
              </div>
              <div className="col-md-6 text-right">
              <p>Created by: <strong className="weight-600">{this.props.accountBasicInfo.userId}</strong></p>
              </div>
            </div>
                <TmReactTable 
                filterable 
                sortable
                resizable
                defaultPageSize={10}
                data={this.props.accountBasicInfo.accountHistories}
                columns={historyCols}
            /> 
            </div>                
            </div>
            <div className="row mb-10">
            <div className="col-md-12">
                <button 
                    className="btn btn-sm btn-success pull-right" 
                    style={{marginTop: '20px'}}
                    onClick={(e) => {
                    e.preventDefault();
                        this.props.onTimePlanSubmit(e);
                     }} 
                    disabled={this.checkAccess()}
                >SAVE ACCOUNT</button>
            </div>

            </div>
            </div>
            
        </Fragment>)
    }
}
export default AccountHistory