import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import CRDetails from './monetizationComponents/crDetails';
import AuctionBatchPaymentMonetization from './monetizationComponents/auctionBatchPaymentMonetization'
import AllInvoice from './allInvoice';
import crStatements from './monetizationComponents/crStatements'
import ARDetails from './accountingComponents/arDetails';
import ARDetailByAccountType from './accountingComponents/arDetailByAccountType';
import ARSummary from './accountingComponents/arSummary';
import BatchPayments from './accountingComponents/batchPayments';
import PaymentAudit from './accountingComponents/paymentAudit';
import Receipts from './accountingComponents/receipts';
import Statements from './accountingComponents/statements';
import DriverCommissionsDetail from './driverManagementComponents/driverCommissionsDetail';
import DriverCommissionsSummary from './driverManagementComponents/driverCommissionsSummary';
import DriverPerformanceSummary from './driverManagementComponents/driverPerformanceSummary';
import AuctionList from './storageManagementComponents/auctionList';
import StorageInventory from './storageManagementComponents/storageInventory';
import NoAVRFiled from './storageManagementComponents/noAVRFiled';
import AccountContactReport from './accountManagementComponents/accountContactReport';
import AccountActivityReport from './accountManagementComponents/accountActivityReport';
import PPIRevenueReport from './accountManagementComponents/ppiRevenueReport';
import MissingPOReport from './invoiceManagementComponents/missingPOReport';
import NegativeBalanceReport from './invoiceManagementComponents/negativeBalanceReport';
import PartialPaymentReport from './invoiceManagementComponents/partialPaymentReport';
import UnlockedInvoices from './invoiceManagementComponents/unlockedInvoices';
import UnverifiedInvoices from './invoiceManagementComponents/unverifiedInvoices';
import SummaryByAccountRatePlan from './summariesComponents/summaryByAccountRatePlan';
import SummaryByDriver from './summariesComponents/summaryByDriver';
import SummaryByReason from './summariesComponents/summaryByReason';
import SummaryByTowType from './summariesComponents/summaryByTowType';
import SummaryByTruck from './summariesComponents/summaryByTruck';
import SummaryDetails from './summariesComponents/summaryDetails';
import ResponseTime from './summariesComponents/responseTime';
import AuctionBatchPayment from './customComponents/auctionBatchPayment';
import CustomPDAllTows from './customComponents/customPDAllTows';
import customPDRelease from './customComponents/customPDRelease';
import CRSummary from './monetizationComponents/crSummary';

class ReportsHome extends React.Component {
    constructor(){
      super();
      
    }
    render(){
        return(<>
        <Switch>
            <Route exact path="/reports/cr-details" component={CRDetails} />
            <Route exact path="/reports/cr-summary" component={CRSummary} />
            <Route exact path="/reports/cr-statements" component={crStatements} />
            <Route exact path="/reports/auction-batch-payment" component={AuctionBatchPaymentMonetization} />
            <Route exact path="/reports/allInvoices" component={AllInvoice} />
            <Route exact path="/reports/ar-details" component={ARDetails} />
            <Route exact path="/reports/ar-detail-by-account-type" component={ARDetailByAccountType} />
            <Route exact path="/reports/ar-summary" component={ARSummary} />
            <Route exact path="/reports/multiple-invoice-payment" component={BatchPayments} />
            <Route exact path="/reports/payment-audit-report" component={PaymentAudit} />
            <Route exact path="/reports/receipts" component={Receipts} />
            <Route exact path="/reports/billing" component={Statements} />
            <Route exact path="/reports/driver-commission" component={DriverCommissionsDetail} />
            <Route exact path="/reports/driver-commission-summary" component={DriverCommissionsSummary} />
            <Route exact path="/reports/driver-performance-summary" component={DriverPerformanceSummary} />
            <Route exact path="/reports/auction-list" component={AuctionList} />
            <Route exact path="/reports/storage-inventory" component={StorageInventory} />
            <Route exact path="/reports/no-avr-filed" component={NoAVRFiled} />
            <Route exact path="/reports/account-contact-report" component={AccountContactReport} />
            <Route exact path="/reports/activity-report" component={AccountActivityReport} />
            <Route exact path="/reports/ppi-revenue-report" component={PPIRevenueReport} />
            <Route exact path="/reports/missing-po-number" component={MissingPOReport} />
            <Route exact path="/reports/negative-balance-report" component={NegativeBalanceReport} />
            <Route exact path="/reports/partial-payment-report" component={PartialPaymentReport} />
            <Route exact path="/reports/unlocked-invoices" component={UnlockedInvoices} />
            <Route exact path="/reports/unverified-invoices" component={UnverifiedInvoices} />
            <Route exact path="/reports/summary-by-account-rate-plan" component={SummaryByAccountRatePlan} />
            <Route exact path="/reports/summary-by-driver" component={SummaryByDriver} />
            <Route exact path="/reports/summary-by-reason-code" component={SummaryByReason} />
            <Route exact path="/reports/summary-by-tow-type" component={SummaryByTowType} />
            <Route exact path="/reports/summary-by-truck" component={SummaryByTruck} />
            <Route exact path="/reports/summary-details" component={SummaryDetails} />
            <Route exact path="/reports/response-time-report" component={ResponseTime} />
            <Route exact path="/reports/batch-invoice-payment" component={AuctionBatchPayment} />
            <Route exact path="/reports/custom-phoenix-pd-all-tows" component={CustomPDAllTows} />
            <Route exact path="/reports/custom-phoenix-pd-release" component={customPDRelease} />
        </Switch>
        </>)
    }
}
export default ReportsHome