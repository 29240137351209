//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
import React, { Component }  from 'react';
import { Link } from 'react-router-dom';
import { months } from '../../../../util/constants';
export  const InvoicetableManageColumns = [
    {
        accessor: 'invoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href={'/dispatch/serviceCallInfo/'+props.original.invoiceId} 
        target="_blank" >{props.original.displayInvoiceId}</a>,
        width: 120
    },
    {
        accessor: 'releaseDate',
        Header: 'RELEASE DATE',
        style: { textAlign: 'center' },
        width: 120
    },
    {
        accessor: 'dueDate',
        Header: 'DUE DATE',
        style: { textAlign: 'center' },
        width: 120
    },
    {
        accessor: 'age',
        Header: 'AGE',
        style: { textAlign: 'center' },
        width: 80
    },
    {
        accessor: 'accountName',
        Header: 'Account Name',
        style: { textAlign: 'center' },
        Cell: props => <Link to={'/admin/accountDetails?aid=' + props.original.accountId} target="_blank">{props.original.accountName}</Link>
    },
    {
        accessor: 'billableType',
        Header: 'BILLING TYPE',
        style: { textAlign: 'center' },
        width: 120,
        Cell: props => `${(props.original.billableType || '') ==='PerMonth' ?
         'Per Month' : (props.original.billableType || '') ==='PerTow' ? 
         'Per Tow' : (props.original.billableType || '') ==='PerUser' ? 
         'Per User' : props.original.billableType}`
    },
    {
        accessor: 'billableRate',
        Header: 'Billing rate',
        style: { textAlign: 'center' },
        width: 120,
        Cell: props => `${(props.original.billableType || '') ==='Percentage'? '%' : '$'} ${props.original.billableRate || 0}`
    },
    {
        accessor: 'receivable',
        Header: 'Receivable',
        style: { textAlign: 'center' },
        width: 120,
        Cell: props => `$ ${props.original.receivable || 0}`
    }
];

export  const MonthlyTableRetailColumns = [
    {
        accessor: 'month',
        Header: 'Month',
        style: { textAlign: 'center' },
        Cell: props => `${months[props.original.month-1]}`
    },
    {
        accessor: 'year',
        Header: 'Year',
        style: { textAlign: 'center' }
    },
    
    {
        accessor: 'noOfUsers',
        Header: 'NO OF USERS',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'receivable',
        Header: 'Receivable',
        style: { textAlign: 'center' },
        Cell: props => `$ ${props.original.receivable || 0}`
    }
];