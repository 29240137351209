import React from 'react';
class StatusSearch extends React.Component{
    
    isAllSelected=()=>{
       return Object.keys(this.props.searchObject).every(item=>{
            return this.props.searchObject[item].selected===false
         })
    } 
    render(){
        const id=this.props.id;
        const showAll=this.props.showAllOption===undefined?true:this.props.showAllOption;
        return (
            <div className="row">        
                <div className="col-md-12">
                {/* <label  style={{fontWeight:'400', width:'1px'}}>{this.props.label}</label>   */}
                { (showAll)? <div className="custom-control custom-checkbox mb-5 mr-2" style={{display:'inline'}}>
                                <input type="checkbox" className="custom-control-input" id={id+"-all"} 
                                checked={this.isAllSelected()}
                                onChange={(e)=>{
                                        let _searchObj=JSON.parse(JSON.stringify(this.props.searchObject)) ;
                                        Object.keys(_searchObj).forEach(item=>{
                                            _searchObj[item].selected=false;
                                        });       
                                        this.props.onChange(_searchObj);
                                    }} />
                                <label className="custom-control-label" htmlFor={id+"-all"}>All</label>
                            </div>:null}
                {
                    Object.keys(this.props.searchObject).map((item,key)=>{
                        return(<>
                        {(item === 'Available') && ((this.props.byType || '') !== 'vehicle') ? null :
                        (item === 'Requested') && ((this.props.byType || '') !== 'callStatus') ? null :
                        <div className="custom-control custom-checkbox mb-5 mr-2" style={{display:'inline'}}>
                                    <input key={id+'-'+key} type="checkbox" className="custom-control-input" checked={this.props.searchObject[item].selected} 
                                    id={id+"-"+item} onChange={(e)=>{
                                        let _searchObj=JSON.parse(JSON.stringify(this.props.searchObject));
                                        _searchObj[item].selected=e.target.checked?true:false;
                                        this.props.onChange(_searchObj);
                                    }}/>
                                    <label className="custom-control-label" htmlFor={id+"-"+item}>{this.props.searchObject[item].text}</label>
                                </div>}</>)
                    })
                }
                </div>
            </div>
          );

    }  
    
}
export default StatusSearch
