import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReviewInvoiceList from '../../components/dispatchComponents/reviewInvoice/reviewInvoiceList';
import { getReviewLocationList } from '../../actions/dispatchAction/reviewInboxAction';
import { validate, isManagement, getAccessLevelsByModuleName } from '../../util/customMethods';
const pageTitle = 'Review Inbox';
let reviewPermission;
class Inbox extends Component {
	constructor() {
		super();
		this.state = {
	
		}
	}
	componentWillMount = () => {
		const {dispatch}=this.props;
		document.title = pageTitle;
		let reviewPermissionData = getAccessLevelsByModuleName("Review Invoice");
		reviewPermission = reviewPermissionData[0].basicAccess
		dispatch(getReviewLocationList(null, true));
	}
	onInvoiceInfoFieldsChange=(value)=>{
		const {dispatch}=this.props;
		dispatch(getReviewLocationList(value, true))
	}
	render() {
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-envelope-o"></i> Inbox</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<ReviewInvoiceList reviewInvoice={this.props.reviewInvoice} reviewPermission={reviewPermission}
					onInvoiceInfoFieldsChange={(value)=>this.onInvoiceInfoFieldsChange(value)}/>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
		authInfo: state.authInfo,
		reviewInvoice:state.reviewInbox.reviewInvoiceList
	}
};

export default withRouter(connect(mapStateToProps)(Inbox));
