import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const defaultEmployee={
    employeeList:[],
    employeeRec:{
      "employeeInformation": {
          "employeeNo": '',
          "firstName": '',
          "lastName": '',
          "emailId": '',
          "ssn": '',
          "lastUpdated": '',
          "dob": '',
          "emrgencyContactNo": '',
          "doj": '',
          "uploadImage": '',
          "imageURL":'',
          'uploadFile':null,
          "loginName": '',
          "temPassword": '',
          "neverExpire":false,
          "personalAddressLine1": '',
          "personalAddressLine2": '',
          "personalCity": '',
          "personalState": '',
          "personalZipcode": '',
          "landLineNo": '',
          "moblieNo": '',
          "officeLocation": '',
          "officeAddressLine1": '',
          "officeAddressLine2": '',
          "officeCity": '',
          "officeState": '',
          "officeZipcode": '',
          "ext": '',
          "fax": '',
          "department": '',
          "jobTitle": '',
          "level": '',
          "employeeId": 0,
          "license": '',
          "licenseType": '',
          "expiryDate": '',
          "weeklySalary": "0.00",
          "dailySalary": "0.00",
          "empStatus": 1,
          "companyId": 0,
          "primary": false
      },
      "employeeRemarksForm": {
          "remarks": []
      },
      "employeeAccessLevelForm": {
          "accessLevel": []
      },
      empAccess:{},
      "licenseType": null,
      "locations": null,
      "departments": null,
      "dashboardAccess": []
  }
}
export const employeeReducer = (state = defaultEmployee, action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_EMPLOYEES:
        return {...state ,employeeList:(action.employeeList||defaultEmployee.employeeList)};
      case actionTypes.GET_EMPLOYEE_REC:
        return {...state , employeeRec:({...action.employeeRec|| defaultEmployee.employeeRec} ) };
      case actionTypes.GET_DEFAULT_DASHBOARD_TAILS:
        let _empObj=state.employeeRec;
        _empObj.dashboardAccess=(action.dashboardAccess||[])
        return {...state, employeeRec:_empObj}
      case actionTypes.GET_ALL_MODULES:
        let empObj=state.employeeRec;
        //empObj.employeeAccessLevelForm.accessLevel=(action.accessLevel||[])
        empObj.empAccess=action.empAccess||{}
        return {...state, employeeRec:empObj}
      default:
        return state;
    }
};
