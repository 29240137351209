import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Dispatcher from '../../../components/launchComponents/Dispatcher/dispatcher'
import {dashboardViewAccess,dashboardTailViewAccess} from '../../../util/accessLevelMethods';
const pageTitle="Summary Dashboard";
const moduleName="Dispatcher";
class DispatcherDashboarPage extends Component {
    componentWillMount=()=>{
        document.title=pageTitle
    }
    getTailsViewAccess=()=>{
        let resultObj=new Object();
        (this.props.dashboardAccess[moduleName]||[]).map((item)=>{
            resultObj[item.tileName.toLowerCase()]=dashboardTailViewAccess(moduleName,item.tileName,this.props.dashboardAccess)
        });
        return resultObj;
    }

    render() {
        return (
        <> 
            <div className="page-header">
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12">
                        <div className="title">
                            <h4><i className="fa fa-tachometer"></i> {pageTitle}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pd-10 bg-white border-radius-4 box-shadow container-box tile-card-box">
            <div className="row">
                <div className="col-xl-12 col-md-12 col-lg-12">
                    {
                    dashboardViewAccess(moduleName,this.props.dashboardAccess)===true? 
                    <Dispatcher 
                        moduleName={moduleName} 
                        tailsAccessList={this.getTailsViewAccess()}  />
                    :
                    <div className="pd-10">
                        <div className="error-page-wrap text-center color-dark">
                            <h1 className="color-dark weight-500"></h1>
                            <p style={{color:'#b7abab'}}> No tiles available.
                            </p>
                        </div>
                    </div>
                    }
                </div>
                </div>
            </div>
                
        </>)
    }

}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
    dashboardAccess:state.dashboardAccess
  }
};
export default withRouter(connect(mapStateToProps)(DispatcherDashboarPage))