import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required, email, isValidForm} from '../../../util/validationMethods';
class BillingReportEmail extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.checkAccess=this.checkAccess.bind(this);
    }
    handleSubmit=(event)=>{
        event.preventDefault();
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{
            this.props.onSubmitForm(event,this.props.billingReportEmail.emailAddr)
        }
    }
    checkAccess=()=>{
        if(((this.props.accessLevel.basicAccess.add||false)===true) ||((this.props.accessLevel.basicAccess.edit||false)===true)){
            return false
        }else{
            return true
        }
    }
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-8 col-sm-12 mb-30 offset-2">
                        <div className="card box-shadow">
                            <div className="card-body">
                                <Form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>
                                <fieldset disabled={this.checkAccess()}>
                                    <div className="row">
                                        <div className="col">                                    
                                            <div className="form-group">
                                                <label for="terms-net">CURRENT OUTGOING EMAIL:</label>
                                                <input type="text" className="form-control" value={this.props.billingReportEmail.billingEmail} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="billing-report-email">NEW OUTGOING EMAIL:</label>                                                
                                                <Input value={this.props.billingReportEmail.emailAddr} name="billingReportEmail" 
                                                type="text" placeholder="Email"
                                                requiredmsg="Please enter valid new outgoing email"
                                                 className="form-control" 
                                                 onChange={(e)=>{
                                                    let _recObj={...this.props.billingReportEmail};
                                                    _recObj['emailAddr']=e.currentTarget.value;
                                                    this.props.onFieldDataChange(e,_recObj);
                                                }} validations={[required, email]}/>   
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-success btn-sm pull-right">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default BillingReportEmail;
