import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import logo from '../../../assets/images/SVMS Logo Final-A-FULL-MED.png';
import SideMenuCls from '../sidebar/sideMenuCls';
import { getAccessToken, isManagementCompany } from '../../../actions/authenticateActions';
import {getAccessLevelsByModuleName} from '../../../util/customMethods';
import './Header.css';
let reviewPermission={edit:false,view:false};
class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.handleRedirectClick = this.handleRedirectClick.bind(this);
    this.getRedirectUrl = this.getRedirectUrl.bind(this)
  }
  handleRedirectClick = (base, root, token) => {
    console.log(base, root, token);
    window.location = window.location.origin + base + root//+'?access_token='+token;
  }
  getRedirectUrl = (base, root, token) => {
    return window.location.origin + base + root//+'?access_token='+token;
  }
  submit=(e)=>{

  }
  handleNoPermission=(e)=>{
    e.preventDefault();
    alert("You don't have permission for review the invoice details.")
  }
  render() {
    const sideMenuCls = new SideMenuCls();
    const selectedMenu = sideMenuCls.getSelectedMenuObj(window.location.pathname);//{root:'admin',subRoot:'user',child:''}//
    const {reviewInbox}=this.props;
    let reviewPermissionData = getAccessLevelsByModuleName("Review Invoice");
    if(reviewPermissionData[0]){
      reviewPermission = reviewPermissionData[0].basicAccess
    }
    return (
      <div className="site-header-collapsed">
        <ul class="nav navbar-nav">
          {
            sideMenuCls.getMenuList().map((mainItem, mainkey) => {
              if (isManagementCompany() && mainItem.showManageCompany === false) {
                return false
              }
              if (!isManagementCompany() && mainItem.showChildCompany === false) {
                return false;
              }
              if (sideMenuCls.havingAccess(mainItem, this.props.accessLevels, this.props.dashboardAccess)) {
                return (
                  mainItem.type === 'I' ?
                    <li key={mainkey}>
                      <>{/* <a href={this.getRedirectUrl('',mainItem.path,getAccessToken())}>
                        <i className="fa fa-tachometer" aria-hidden="true"></i>{mainItem.sidebarName}</a> */}
                        <Link to={mainItem.path}><i className="fa fa-tachometer" aria-hidden="true"></i> {mainItem.sidebarName}</Link></>
                    </li> :
                    <li key={mainkey} className={mainItem.className}>
                      {/* <Link to={mainItem.path} className="accordion-heading" data-toggle="collapse" data-target={`#submenu0${mainkey+1}`}>
                  <i className={mainItem.icon} aria-hidden="true"></i> <span className="nav-header-primary">{mainItem.sidebarName} <span className="pull-right"><i className="caret"></i></span></span></Link> */}
                      <Link to={mainItem.path} className="dropdown-toggle" data-toggle="dropdown"><i className={mainItem.icon} aria-hidden="true"></i> {mainItem.sidebarName}<b className="caret"></b></Link>
                      {mainItem.sub.length > 0 ?
                        (<ul className="dropdown-menu multi-level">
                          {
                            mainItem.sub.map((subItem, key) => {
                              if (sideMenuCls.havingAccess(subItem, this.props.accessLevels, this.props.dashboardAccess)) {
                                return (
                                  subItem.submenu.length > 0 ?
                                    (<li key={key} className={subItem.submenu.length > 0 ? 'dropdown-submenu' : ''}><a className="dropdown-toggle" href="javascript:void(0)" >{subItem.sidebarName}</a>
                                      <ul className="dropdown-menu">
                                        {
                                          subItem.submenu.map((subMenuItem, key) => {
                                            if (sideMenuCls.havingAccess(subMenuItem, this.props.accessLevels, this.props.dashboardAccess)) {
                                              return (
                                                <li key={key}>
                                                  {window.location.pathname.split('/').includes(mainItem.rootName) && ((subItem.isLaunch || false) === false) ?
                                                    <Link to={subMenuItem.path}>{subMenuItem.sidebarName}</Link>
                                                    :
                                                    (((subItem.isLaunch || false) === true) ?
                                                      <>{/* <a  href={this.getRedirectUrl('',subItem.path,getAccessToken())}>
                        <i className="fa fa-minus" aria-hidden="true"></i>{subItem.sidebarName}</a> */}
                                                        <Link to={subItem.path}>{subItem.sidebarName}</Link></>
                                                      :
                                                      <>
                                                        {/* <a href={window.location.origin+subMenuItem.baseURL+subMenuItem.path}>
                        <i className="fa fa-minus" aria-hidden="true"></i>
                        {subMenuItem.sidebarName}</a> */}
                                                        <Link to={subMenuItem.path}>
                                                          {subMenuItem.sidebarName}</Link>
                                                      </>
                                                    )
                                                  }
                                                </li>
                                              )
                                            }
                                          })
                                        }
                                      </ul>
                                    </li>
                                    )
                                    :
                                    (<li>{window.location.pathname.split('/').includes(mainItem.rootName) && ((subItem.isLaunch || false) === false) ?
                                      <Link to={subItem.path}>
                                        {subItem.sidebarName}</Link> :
                                      (((subItem.isLaunch || false) === true) ?
                                        <>{/* <a href={this.getRedirectUrl('',subItem.path,getAccessToken())}
                        >
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName}</a> */}
                                          <Link to={subItem.path}>
                                            {subItem.sidebarName}</Link></>
                                        :
                                        <>{/* <a href={this.getRedirectUrl(subItem.baseURL,subItem.path,getAccessToken())}>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{subItem.sidebarName}</a> */}
                                          <Link to={subItem.path}>
                                            {subItem.sidebarName}</Link>
                                        </>)}</li>)
                                )
                              }
                            })
                          }</ul>) : null

                      }
                    </li>

                )
              }
            })
          }
          {isManagementCompany() ? null :
            <li>
              <Link to={(reviewPermission.view) ? '/dispatch/inbox' : 'javascript:void(0)'} onClick={(e) => { (reviewPermission.view) ? 
                 this.submit(e):  this.handleNoPermission(e)  }} target="_blank" style={{ color: 'black' }}> 
                <i i className="fa fa-envelope-o" aria-hidden="true">
                <span className="invoice-label invoice-label-pill invoice-label-danger" key={"alert"}>{reviewInbox.reviewedInvoicesCount || 0}</span>
                </i> Inbox
              </Link>
            </li>
          }
        </ul>
      </div>
    )
  }
}
export default HeaderMenu