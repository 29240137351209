import React, { Component, Fragment } from 'react';
import Autocomplete from 'react-autocomplete'
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import AddressEntry from './addressEntry'
import { phone, isNumeric,email } from "../../../../util/validationMethods.js";
import { getCompanyId,isManagement } from '../../../../util/customMethods';
const getAccountNameById = (list, id) => {
    if (id === '0' || id === '' || id === undefined) {
        return ''
    } else {
        let idx = list.findIndex((item) => {
            return item.accountId === id
        });
        if (idx === -1) {
            return ''
        } else {
            return list[idx].accountName
        }
    }
}
class DefaultAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            billTo: getAccountNameById(this.props.accountList, this.props.accountBasicInfo.accountDefaultLocation.otherInfo.billTo)
            
        }
        this.checkAccess = this.checkAccess.bind(this);
    }
    checkAccess = () => {
        if ((this.props.accountBasicInfo.companyId === getCompanyId()) || (this.props.accountBasicInfo.companyId === 0)) {
            if (this.props.accountBasicInfo.accountId === 0) {
                if ((this.props.accessLevel.basicAccess.add || false) === true) {
                    return false
                } else {
                    return true
                }
            } else {
                if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                    return false;
                } else {
                    return true;
                }
            }
        }else{
            return true;
        }
    }
    render() {
        const { accountBasicInfo } = this.props;
        return (<Fragment>
            <div className="pd-10">
            <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <h6 className="text-blue text-bg-color form-group">DEFAULTS</h6>
                    </div>
                </div>
                <fieldset disabled={this.checkAccess() ||isManagement()}>
                    <div className="row mb-10">
                        <div className="col-md-6 col-sm-12 mb-5">
                            
                            <AddressEntry title="Pick-up Location" address={accountBasicInfo.accountDefaultLocation.pickUpDetails}
                                reviewLocation={this.props.reviewLocation} allStates={this.props.allStates} onFieldValueChange={(key, value) => {
                                    this.props.onAddressFieldChange('pickUpDetails', key, value)
                                }} onAddressObjByLocationChange={(addrObj)=>{
                                    this.props.onAddressObjectChange('pickUpDetails',addrObj);
                                }} 
                                initAddressCallBack = {(f)=>this.props.initPickAddressCallBack(f)}/>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-5">
                            <AddressEntry title="Drop-Off Location" type={''} address={accountBasicInfo.accountDefaultLocation.dropOffDetails} reviewLocation={this.props.reviewLocation}
                                allStates={this.props.allStates} onFieldValueChange={(key, value) => {
                                    this.props.onAddressFieldChange('dropOffDetails', key, value)
                                }} onAddressObjByLocationChange={(addrObj)=>{
                                    this.props.onAddressObjectChange('dropOffDetails',addrObj);

                                }} 
                                initAddressCallBack = {(f)=>this.props.initDropAddressCallBack(f)}/>
                        </div>

                    </div>
                </fieldset>
                <Form ref={(f) => {
                    this.fromAddress = f;
                    this.props.initCallBack(f);
                }}>
                    
                        <div className="row mb-10">
                            <div className="col-md-6 col-sm-12 mb-5">
                                <div className="card box-shadow">
                                <fieldset disabled={this.checkAccess()||isManagement()}>
                                    <div className="card-header">BILLING</div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="" className="form-label col-form-label">BILLING TO</label>
                                                    <div className="">
                                                        <Autocomplete
                                                            inputProps={{ id: 'accountName', className: 'form-control', style: { width: '100%', zIndex: 'auto' } }}
                                                            items={this.props.accountList}
                                                            shouldItemRender={(item, value) => item.accountName.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                            getItemValue={item => item.accountName}
                                                            renderItem={(item, highlighted) =>
                                                                <div
                                                                    key={item.accountId}
                                                                    style={{ backgroundColor: highlighted ? '#eee' : 'white' }}
                                                                >
                                                                    {item.accountName}
                                                                </div>
                                                            }
                                                            value={this.state.billTo}
                                                            onChange={(e) => {
                                                                this.setState({ billTo: e.target.value });
                                                            }}
                                                            onSelect={(value, item) => {
                                                                this.setState({ billTo: value },
                                                                    this.props.onAddressFieldChange('otherInfo', 'billTo', item.accountId)
                                                                )

                                                                this.forceUpdate();
                                                            }}
                                                            wrapperStyle={{ position: 'relative' }}
                                                            menuStyle={{ position: 'absolute', height: '250px', overflow: 'auto', top: '26', left: '15', cursor: 'pointer', zIndex: '1' }}
                                                            selectOnBlur={true}
                                                            selectOnTab={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <label htmlFor="" className="form-label col-form-label">PO#</label>
                                                <div className="">
                                                        <Input type="text" name="poNo" value={accountBasicInfo.accountDefaultLocation.otherInfo.poNo || ''}
                                                           className="form-control form-control-sm" onChange={(e) => {
                                                                this.props.onAddressFieldChange('otherInfo', e.target.name, e.target.value)
                                                            }} />
                                                   
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                                </div>

                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                                <div className="card box-shadow">
                                    
                                <fieldset disabled={this.checkAccess()}>
                                    <div className="card-header">CALLER CONTACT DETAILS</div>
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="" className="form-label col-form-label">CALLER NAME</label>
                                                    <div className="">
                                                        <Input type="text" disabled={isManagement()} name="defCallerName" value={accountBasicInfo.accountDefaultLocation.otherInfo.defCallerName}
                                                            className="form-control form-control-sm" onChange={(e) => {
                                                                this.props.onAddressFieldChange('otherInfo', e.target.name, e.target.value)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="" className="form-label col-form-label">CALLER PHONE</label>
                                                    <div className="">
                                                        <Input type="text" name="defCallerPhone"
                                                            value={accountBasicInfo.accountDefaultLocation.otherInfo.defCallerPhone}
                                                            className="form-control form-control-sm" onChange={(e) => {
                                                                this.props.onAddressFieldChange('otherInfo', e.target.name, e.target.value)
                                                            }}
                                                            validations={[phone,isNumeric]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="" className="form-label col-form-label">CALLER EMAIL ID</label>
                                                    <div className="">
                                                        <Input type="text" name="defCallerEmail" disabled={isManagement()}
                                                            value={accountBasicInfo.accountDefaultLocation.otherInfo.defCallerEmail}
                                                            className="form-control form-control-sm" onChange={(e) => {
                                                                this.props.onAddressFieldChange('otherInfo', e.target.name, e.target.value)
                                                            }}
                                                            validations={[email]} 
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="" className="form-label col-form-label">CONTACT NAME</label>
                                                    <div className="">
                                                        <Input type="text" name="defCallerName" disabled={isManagement()}
                                                            value={accountBasicInfo.accountDefaultLocation.otherInfo.defCallerName}
                                                            className="form-control form-control-sm" onChange={(e) => {
                                                                this.props.onAddressFieldChange('otherInfo', e.target.name, e.target.value)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="" className="form-label col-form-label">CONTACT PHONE</label>
                                                    <div className="">
                                                        <Input type="text" name="defContactPhone" disabled={isManagement()}
                                                            value={accountBasicInfo.accountDefaultLocation.otherInfo.defContactPhone}
                                                            className="form-control form-control-sm" onChange={(e) => {
                                                                this.props.onAddressFieldChange('otherInfo', e.target.name, e.target.value)
                                                            }} validations={[phone, isNumeric]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    </fieldset> 
                                </div>
                            </div>
                        
                        </div>
                    
                </Form>
                <div className="row" style={{display: 'flex', justifyContent: 'flex-end'}} >
                    <div className="mr-3">
                        <button className="btn btn-sm btn-success pull-right" onClick={(e) => {
                            this.props.onSubmitDefaultAddress(e);
                        }}>Next</button>
                    </div>
                    {
                        ((this.props.accountBasicInfo.accountId||0) !== 0)?
                        <div className="mr-3">
                        <button 
                            className="btn btn-sm btn-success pull-right" 
                            style={{marginTop: '0px'}}
                            onClick={(e) => {
                            e.preventDefault();
                            // this.frmServiceCallRate.validateAll();
                            // if (!isValidForm(this.frmServiceCallRate)) {
                            // } else {
                                //this.props.onAccountServiceCallRateNextClick(e);
                                this.props.onTimePlanSubmit(e);
                                this.props.history.push('/')
                            // }
                            }} 
                            disabled={this.checkAccess()}
                        >SAVE ACCOUNT</button>
                    </div>:null
                    }
                    
                </div>
            </div>
        </Fragment>)
    }
}
export default DefaultAddress
