import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { utilMethods } from '../../../util/util.js'
import { isValidForm, required } from '../../../util/validationMethods'
import APKUpdateList from './apkUpdateList/apkUpdateList';
import APKVersionList from './apkVersionList';
class APKUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpr: {
                modalShow: false,
                file: {},
                version: ''
            },
            versionToApplyAll: '',
            searchVersions: 1
        }
        this.getLatestVersion = this.getLatestVersion.bind(this);
        this.checkAccess = this.checkAccess.bind(this);
        this.getFile = this.getFile.bind(this);
    }
    getLatestVersion = () => {
        let versionsList = (this.props.apkActiveVersions || []).map((item) => {
            return item.apkVersion
        });
        if (versionsList.length > 0) {
            return (versionsList.sort(utilMethods.compareVersions).reverse())[0] || ''
        }
        return '';
    }
    checkAccess = () => {
        if (this.props.isEdit === false) {
            if ((this.props.accessLevel.basicAccess.add || false) === true) {
                return false
            } else {
                return true
            }
        } else {
            if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                return false;
            } else {
                return true;
            }
        }
    }
    getFile(e) {
        e.preventDefault();
        console.log('File Name', e.target.files);
        this.props.onFileUploadChange(e.target.files);
      }
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                        <Form ref={(f) => this.frmVersionApply = f}>
                            <div className="row">
                                <div className="col-md-4">
                                    <label><b>Current Android Latest Version: {this.getLatestVersion()}</b></label>
                                </div>
                                <div className="col-md-2">
                                    <label><b>Available Versions</b></label>
                                </div>
                                <div className="col-md-2">
                                    <Select name="availableversions" className="form-control"
                                        disabled={(this.props.accessLevel.basicAccess.edit || false) === true ? false : true}
                                        value={this.state.versionToApplyAll}
                                        requiredmsg="Please select .apk version"
                                        validations={[required]} onChange={(e) => {
                                            this.setState({ versionToApplyAll: e.target.value });
                                        }}>
                                        <option>Select version</option>
                                        {this.props.apkActiveVersions.map((item, k) => {
                                            return <option key={k} value={item.apkVersion}>{item.apkVersion}</option>
                                        })}
                                    </Select>
                                </div>
                                <div className="col-md-2">
                                    <button type="button" className="btn btn-sm btn-success"
                                        disabled={(this.props.accessLevel.basicAccess.edit || false) === true ? false : true}
                                        onClick={(e) => {
                                            this.frmVersionApply.validateAll();
                                            if (!isValidForm(this.frmVersionApply)) {
                                            } else {
                                                if (this.state.versionToApplyAll != '') {
                                                    this.props.onAPkVersionUpdate('ALL', -1, this.state.versionToApplyAll)
                                                }
                                            }
                                        }}>
                                        Apply to all
                                                    </button>
                                </div>
                                <div className=" col-md-2 text-right">
                                    <button className="btn btn-sm btn-info"
                                        disabled={(this.props.accessLevel.basicAccess.add || false) === true ? false : true}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let _modalOpr = this.state.modalOpr;
                                            _modalOpr.modalShow = true;
                                            this.setState({ modalOpr: _modalOpr })
                                        }}>
                                        Upload
                                                </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <APKUpdateList accessLevel={this.props.accessLevel}
                            apkUpdateListData={this.props.apkDeviceData.apkDeviceList || []}
                            apkVersions={this.props.apkActiveVersions || []}
                            onDeviceVersionChange={(type, index, version) => {
                                this.props.onAPkVersionUpdate(type, index, version);
                            }}
                            onAPKVersionDelete={(deviceId) => {
                                this.props.onAPKVersionDelete(deviceId);
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-right mt-10 mb-10">
                            <button className="btn btn-success btn-sm"
                                disabled={(this.props.accessLevel.basicAccess.edit || false) === true ? false : true}
                                onClick={(e) => {
                                    this.props.onAPKVersionChangeSave(this.props.apkDeviceData.apkDeviceList)
                                }}>Save</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <APKVersionList accessLevel={this.props.accessLevel}
                            apkVersionListData={(this.props.apkDeviceData.apkVersionList || []).filter((item) => {
                                return this.state.searchVersions == -1 ? true : ((item.isDeactivated ? 1 : 0) === this.state.searchVersions ? false : true)
                            })}
                            onAPKVersionActive_Deactive={(recId, status) => {
                                this.props.onAPKVersionActive_Deactive(recId, status)
                            }}
                            onSearchVersionChange={(filterStatus) => {
                                this.setState({ searchVersions: filterStatus.isActive })
                            }}
                        />
                    </div>
                </div>
                    <Modal show={this.state.modalOpr.modalShow} >
                        <div className="modal-header">
                            <Modal.Title>{'APK Upload'}</Modal.Title>
                        </div>
                        <div className="modal-body">
                            <Form ref={(f) => this.frmAPkUpload = f}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="ulApk">UPLOAD LATEST APK</label>
                                            <Input type="file" className="form-control" accept=".apk" id="ulApk" requiredmsg="Please select .apk file" validations={[required]} onChange={this.getFile}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="txtVersion">Enter APK Version:</label>
                                            <Input type="text" className="form-control" name="version"
                                                id="txtVersion" requiredmsg="Please provide APK version"
                                                validations={[required]} onChange={(e) => {
                                                    let _modalOpr = this.state.modalOpr;
                                                    _modalOpr[e.target.name] = e.target.value;
                                                    this.setState({ modalOpr: _modalOpr });
                                                }} onKeyPress={(e) => utilMethods.versionFormat(e)} />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div className="model-footer">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="btn-list">
                                            <button type="button" className="btn btn-sm btn-success pull-right" onClick={(e) => {
                                                this.frmAPkUpload.validateAll();                                       
                                                if (!isValidForm(this.frmAPkUpload)) {
                                                } else {
                                                    console.log(this.fileUploadCtrl)
                                                    if (this.props.attachedFileName.length > 0) {
                                                        var formData = new FormData();
                                                        let apkFile = this.props.attachedFileName[0];
                                                        //let apkFile=this.fileUploadCtrl.files[0]                                        
                                                        formData.append("file", apkFile);
                                                        let _modalOpr = this.state.modalOpr;
                                                        _modalOpr.modalShow = false;
                                                        this.setState({ modalOpr: _modalOpr });
                                                        this.props.onAPKUpload(e, formData, this.state.modalOpr.version);
                                                    }
                                                }
                                            }}>Save</button>
                                            <button type="button"
                                                className="btn btn-sm btn-danger pull-right" onClick={(e) => {
                                                    e.preventDefault();
                                                    let _modalOpr = this.state.modalOpr;
                                                    _modalOpr.modalShow = false;
                                                    this.setState({ modalOpr: _modalOpr })
                                                }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
            </Fragment>
                );
            }
        }
        
        export default APKUpdate;
