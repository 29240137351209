import React, { Component,Fragment } from 'react';
import {withRouter,Link} from 'react-router-dom'
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable'
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
import moment from 'moment'
import {ToggleButtonGroup,ToggleButton} from 'react-bootstrap';
class VendorList extends Component {
    state={
      ActiveInactiveFilterVal:[1],
      excelDataSet:[]
    }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'First Name'},{title:'Last Name'},
      {title:'Email ID'}],      
      data:this.props.data.map((item)=>{
        return [{value:item.vendorFirstName||''},{value:item.vendorLastName||''},{value:item.vendorUserId||''}]
      })
    }])
  }
  handleActiveInactiveFilterChange=(value,e)=>{
    this.props.bindVendorList(e.currentTarget.value);
    this.setState({ActiveInactiveFilterVal:[parseInt(e.currentTarget.value)]})
  }

  render() {
    const {data} =this.props;
    const columns=[{
      Header: 'First Name',
      accessor: 'vendorFirstName', // String-based value accessors!
      filterable:true,
      /* Cell: props => <a className="btn btn-link" href="javascript:void(0)" title="Edit" onClick={(e)=>{
        let _vid=props.original.vendorId;
        this.props.editVendorOnClick(e,_vid)
      }}>{props.value}</a> */
      Cell:props=><Link to={"vendorsDetails?vid="+props.original.vendorId}>{props.value}</Link>
    },
    {
      Header: 'Last Name',
      accessor: 'vendorLastName',
      filterable:true,
      Cell: props => <Link to={"vendorsDetails?vid="+props.original.vendorId}>{props.value}</Link>,
    },
    {
      Header: 'User ID',
      accessor: 'vendorUserId',
      filterable:true,
      Cell: props => <Link to={"vendorsDetails?vid="+props.original.vendorId}>{props.value}</Link>,
    },{
      Header:'Status',
      style:{textAlign:'center'},
      Cell:(props)=>{
        return(
          <span className={`badge ${props.original.isActive ? 'badge-success' : 'badge-danger'}`}>
            {`${props.original.isActive ? 'Active' : 'Inactive'}`}
          </span>
        )
      }
    }    
  ];
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-4 col-sm-12 mb-10"></div>
      <div className="col-md-8 col-sm-6 mb-10">  
      <div className="pull-right"> 
      <ToggleButtonGroup
      bsPrefix="btn-group-toggle btn-group"
        type="checkbox"
        value={this.state.ActiveInactiveFilterVal}
        onChange={this.handleActiveInactiveFilterChange} defaultValue={-1}
      >
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}  >Active </ToggleButton>
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>Inactive </ToggleButton>
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>All</ToggleButton>
      </ToggleButtonGroup>        
      <div className="btn-list" style={{display:'inline-block'}}>
       <TmExportExcel name={'Vendors List'} filename={`${"VendorsList_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
      {
        (this.props.accessLevel.basicAccess.add||false)===true?
        <button className="btn btn-success btn-sm" onClick={(e)=>{
        this.props.addVendorOnClick(e);
      }} ><i className="fa fa-plus"></i> Add Vendor</button>:null
      }
      </div>
      </div> 
      </div>
      </div>
      
      
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">				
        <TmReactTable ref={(table)=>this.vendorsTable=table}
        onFilteredChange={()=>{
          let data=this.vendorsTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={data}
            columns={columns}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default VendorList;
