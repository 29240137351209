import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom';
import LocationInfo from '../../components/adminComponents/locations/locationsInfo/locationInfo';
import {getLocationRecordByName,onLocationFieldUpdate,onLocationSave} from '../../actions/adminAction/locationActions';
import {getStateList} from '../../actions/adminAction/statesActions'
import {notify} from '../../util/notifications'
import {getAccessLevelsByModule} from '../../util/customMethods'
let pageTitle='Add New Location';
class LocationsRecPage extends Component {
    constructor(props){
        super(props);
        this.state={
            lid:0,
            action:'NEW'
        }
        this.initLocationForm=this.initLocationForm.bind(this);
        this.bindStateList=this.bindStateList.bind(this);
        this.onLocationFieldChange=this.onLocationFieldChange.bind(this);
        this.checkViewAccess=this.checkViewAccess.bind(this);
    }
    componentWillMount=()=>{
        const queryValues=	queryString.parse(this.props.location.search);
        if((queryValues.lName||'')!==''){
            this.setState({action:'EDIT', lid:1},
            this.initLocationForm(queryValues.lName||'')
            )
        }else{
            this.setState({action:'NEW',lid:0},
            this.initLocationForm(queryValues.lName||'')
            )

        }
		
    }
    checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
    initLocationForm=(locationName)=>{
        const {dispatch}=this.props;
        dispatch(getLocationRecordByName(locationName));
        this.bindStateList();
    }
    bindStateList=()=>{
        const {dispatch}=this.props;
		dispatch(getStateList());
    }
    onLocationFieldChange=(key,value)=>{
        const {dispatch}=this.props;
		dispatch(onLocationFieldUpdate(key,value));
    }


    render() {
        const accessLevelObj=getAccessLevelsByModule('Location Manager');
        document.title=this.state.lid===0?pageTitle:this.props.locationRec.location;
	    this.checkViewAccess(accessLevelObj)
        return (<Fragment>
            <div className="page-header">	
				<div className="row">			
					<div className="col-md-12 col-sm-12">
						<div className="title">
							<h4><i className="fa fa-thumb-tack"></i>{this.state.lid===0?' Add Location':' Edit Location'} </h4>
						</div>						
					</div>
				</div>			
			</div>
            <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
                <div className="row">
                    <div className="col-md-10 offset-1 col-sm-12 mb-5">
                        <LocationInfo stateList={this.props.stateList} isEdit={this.state.action==='NEW'?false:true}
                        locationRecord={this.props.locationRec} accessLevel={accessLevelObj||{}}
                        onLocationFieldChange={(key,value)=>{
                            this.onLocationFieldChange(key,value)
                        }} 
                        onLocationSubmit={async (e)=>{
                            e.preventDefault();
                            const res=await this.props.dispatch(onLocationSave(this.state.action,this.props.locationRec));
                            if(res.statusCode===200){
                                notify.createNotification('success',res.statusMsg,this.props.refNotify);
                                this.props.history.push('/admin/locations')
                            }else{
                                notify.createNotification('error',res.statusMsg,this.props.refNotify);
                            }

                        }} onCancelClick={(e)=>{
                            e.preventDefault();
                            this.props.history.push('/admin/locations');
                        }} />
                    </div>
                    </div>      
            </div>
        </Fragment>);
    }

}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        refNotify:state.refNotify,
        locationRec:state.location.locationObject,
        stateList:state.states.stateList
    }
  };
export default withRouter(connect(mapStateToProps)(LocationsRecPage));