import {GET_AUCTIONPAYMENT_LIST} from './../../actionTypes';
import * as spinnerActions from '../../spinnerActions';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
import { store } from '../../../store/configureStore';
export const getMonetizationAuctionBatch=(formObject)=>{    
    return async(dispatch)=>{        
        dispatch(spinnerActions.showSpinner());
        try{
        let _url=`getMonetizationAuctionBatch?access_token=${getAccessToken()}`;        
        const response=await getAxiosInstance(moduleTypes.REPORT).post(_url,formObject,
            {headers:{'Content-Type': 'multipart/form-data'}});
            debugger
            if(response.status===200) {
                dispatch(spinnerActions.hideSpinner());
                dispatch({type:GET_AUCTIONPAYMENT_LIST,auctionPaymentObj:response.data.resp});
                return {statusCode:response.data.statusCode,statusMsg:response.data.message};
            }else{
                dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:response.message};
                   // dispatch(getAuctionList());                    
                }
        }
        catch(error) {
            dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        }
    }
}

export const savAuctionBatch = (id) => {
    let crDetails = (store.getState()).crDetailsList.crDetails || {};
    let batchPayObj = {};
    let auctionBatchPay = (store.getState()).auctionPaymentDetails.auctionBatchPay || {};
    auctionBatchPay.companyId = id;
    if (crDetails.managedCompanyReceivableDetailList.length || [] > 0) {
        batchPayObj = crDetails.managedCompanyReceivableDetailList[0].companyReceivableInvoiceDetailList;
        batchPayObj.map((val) => {
            if (parseFloat(val.paymentAmount) !== 0) {
                let payObj = { "invoiceId": val.invoiceId, "amount": val.paymentAmount }
                auctionBatchPay.invoicesPayment.push(payObj);
            }
        })
    }
    if (crDetails.retailCompanyReceivableDetailList.length || [] > 0) {
        batchPayObj = crDetails.retailCompanyReceivableDetailList[0].companyReceivableMonthlyDetailList;
        batchPayObj.map((val) => {
            if (parseFloat(val.paymentAmount) !== 0) {
                let payObj = { "amount": val.paymentAmount, "month": val.month, "noOfUsers": val.noOfUsers, "year": val.year };
                auctionBatchPay.monthlyPayment.push(payObj);
            }
        })
    }
    return async (dispatch) => {
        try {
            if (auctionBatchPay.monthlyPayment.length > 0 || auctionBatchPay.invoicesPayment.length > 0) {
                let _url = `invoice/monetizationBatchPayment?access_token=${getAccessToken()}`;
                const response = await getAxiosInstance(moduleTypes.DISPATCH).post(_url, auctionBatchPay,
                    { headers: { 'Content-Type': 'application/json' } });
                if (response.status === 200) {
                    return response.data;
                } else {
                    return response.data;
                }
            } else {
                return { statusCode: 404, statusMsg: "Please select atleast one record" };
            }
        }
        catch (error) {
            return { statusCode: null, statusMsg: error.message };
        }
    }
}

export const setBatchPayObj=(name,val)=>{
    let auctionBatchPay = (store.getState()).auctionPaymentDetails.auctionBatchPay || {};
    auctionBatchPay[name]=val;
    return async(dispatch)=>{  
    dispatch({type:GET_AUCTIONPAYMENT_LIST,auctionBatchPay:auctionBatchPay});
    }
}

export const setBatchPayObjEmpty=()=>{
    return async (dispatch) => {
        dispatch({type:GET_AUCTIONPAYMENT_LIST,auctionBatchPay:undefined});
    }
}