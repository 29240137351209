import { allInvoiceFieldData } from './allInvoiceFieldData';

export const initialState = {
    byServiceCallDate: true, byReleaseDate: false, fromDate: '', toDate: '', allowCancell: false,
    allowInprogress: false, byAccount: false, byTowType: false, byReason: false, byDriver: false,
    byTruck: false, selectedAccount: "", accountId: "", selectedReason: "", selectedDriver: "", driverId: "",
    selectedTowType: "", selectedTruck: "", selectCompany: "", companyId: "", repFieldObj: allInvoiceFieldData,
    selectedCols: [], initialevent: '1', render: false, byCompany: false
}
export const initInventoryState = {
    daysLimitOne: 0, daysLimitTwo: 0, selectedLocation: '', isBtwEnabled: false, idCompany: 0, render: false, accountId: 0
}
export const initDriverState = {
    fromDate: '', toDate: '', idCompany: 0, render: false
}
export const initInvoiceManageState = {
    asOfDate: '', selectedLocation: '', idCompany: 0, render: false
}
export const initSummState = {
    truckId: '', driverId: '', accountId: 0, towType: '', empId: '', reason: '', fromDate: '', toDate: '', idCompany: 0, render: false
}
export const initResponseState = {
    lateOnly: false, driverId: '', accountId: 0, empId: '', fromDate: '', toDate: '', idCompany: 0, render: false, payLevel: 1, userId: ''
}
export const initSummDetailsState = {
    accountId: 0, typeId: '', fromDate: '', toDate: '', idCompany: 0, render: false, reportText: ''
}

export const initReciptState = { accountId: 0, isRequestedBy: true, fromDate: '', toDate: '', idCompany: 0, render: false }
export const paymentState = {
    cctxNo: "", chequeNo: "", invoiceId: "", paymentAmt: 0, paymentDate: "",
    paymentId: 0, paymentLocation: "", paymentType: "", transactionNo: "", userId: "", deleteModel: false, paymentId: 0, editPaymentModel: false
}
export const serviceCallState = {
    invoiceId: null, originalInvoiceId: null, showInvoiceModel: false, invoicemsg: '', newInvoice: true, userNotes: '',
    attachmentFiles: { attachments: [], coversheets: [], towsheets: [] }, attachment_Name: '', deleteAttachmentIsOpen: false, attachId: '', attachmentType: '', deleteNotesIsOpen: false, notesId: '',
    notesText: '', editNotesIsOpen: false, invoiceObjChanged: false, releaseModel: false, duplicateInvoiceIds: [], addChanged: false,
    addresModel: false, reviewModel: false, cashOnly: false, isRelease: true, printModalIsOpen: false, modalTypeVal: '', otherEmail: false,
    base64Data: [], openAccountNotesList: false, openRatePlanData: false, account_rate_planID: '', openPaymentWindow: false
}