import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { required, email, isValidForm, phone, fax, zipValidation, NotZero } from "../../../../util/validationMethods.js";
import AccountInfoContactList from './accountInfoContactList';
import AccountNotes from './accountNotes';
import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import ReactSelect from '../../../utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../../util/constants.js';
import moment from 'moment';
import { getCompanyId, isManagement } from '../../../../util/customMethods';

class AccountInfo extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            addressSearch: '',
            city: '',
            state: '',
            zipcode: '',
            streetAddress: '',
            data: [],

        }
        this.checkAccess = this.checkAccess.bind(this);
    }
    checkAccess = () => {
        if ((this.props.accountBasicInfo.companyId === getCompanyId()) || (this.props.accountBasicInfo.companyId === 0)) {
            if (this.props.accountBasicInfo.accountId === 0) {
                if ((this.props.accessLevel.basicAccess.add || false) === true) {
                    return false
                } else {
                    return true
                }
            } else {
                if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
    render() {
        const { accountTypes, allStates, salesRep, reviewLocation, accountBasicInfo } = this.props;
        return (<Fragment>
            <div className="pd-10">
                {isManagement() ? <Form ref={c => { this.formAccountInfo = c; this.props.initCallBack(c); }} className=""><fieldset disabled={this.checkAccess()}>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 mb-10">
                            <h6 className="text-blue text-bg-color form-group">BASIC INFO</h6>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="accountName" className="form-label col-form-label">ACCOUNT NAME</label>
                                        <div className="">
                                            <Input type="text" className="form-control form-control-sm" validations={[required]}
                                                name="accountName" value={accountBasicInfo.accountName} requiredmsg="Please enter Account Name"
                                                onChange={(e) => {
                                                    this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpPhoneNo" className="form-label col-form-label">PHONE NO</label>
                                        <div className="">
                                            <Input type="text" className="form-control form-control-sm"
                                                name="corpPhoneNo" value={accountBasicInfo.accountAddress.corpPhoneNo} onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please enter valid Phone Number" validations={[required, phone]}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="accountType" className="form-label col-form-label">ACCOUNT TYPE</label>
                                        <div className="">
                                            <Select className="form-control form-control-sm" name="accountType" value={accountBasicInfo.accountType}
                                                onChange={(e) => {
                                                    this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please select Account Type" validations={[required]}>
                                                <option value={""}>{""}</option>
                                                {accountTypes.map((state, key) => {
                                                    return (
                                                        <option key={key} value={state.optionVal}>
                                                            {state.optionTxt}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpExt" className="form-label col-form-label">EXT</label>
                                        <div className="">
                                            <Input type="text" name="corpExt"
                                                value={accountBasicInfo.accountAddress.corpExt}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="accountPriority" className="form-label col-form-label">ACCOUNT PRIORITY</label>
                                        <div className="">
                                            <Select className="form-control form-control-sm" name="accountPriority" value={accountBasicInfo.accountPriority}
                                                onChange={(e) => {
                                                    this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                }}>
                                                <option value={""}>{""}</option>
                                                <option value={1}>{1}</option>
                                                <option value={2}>{2}</option>
                                                <option value={3}>{3}</option>
                                                <option value={4}>{4}</option>
                                                <option value={5}>{5}</option>
                                                <option value={6}>{6}</option>
                                                <option value={7}>{7}</option>
                                                <option value={8}>{8}</option>

                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpFaxNo" className="form-label col-form-label">FAX NO</label>
                                        <div className="">
                                            <Input type="text" name="corpFaxNo" value={accountBasicInfo.accountAddress.corpFaxNo}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} validations={[fax]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpAddressLine1" className="form-label col-form-label">ADDRESS LINE 1</label>
                                        <div className="">
                                            <Input type="text" name="corpAddressLine1" value={accountBasicInfo.accountAddress.corpAddressLine1}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please enter Address Line1" validations={[required]} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corporateEmail" className="form-label col-form-label">CORPORATE EMAIL ID </label>
                                        <div className="">
                                            <Input type="text" name="corporateEmail" value={accountBasicInfo.accountAddress.corporateEmail}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please enter valid Email ID" validations={[required, email]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpAddressLine2" className="form-label col-form-label">ADDRESS LINE 2</label>
                                        <div className="">
                                            <Input type="text" name="corpAddressLine2" value={accountBasicInfo.accountAddress.corpAddressLine2}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="contract" className="form-label col-form-label">CONTRACT</label>
                                        <div className="">
                                            <Input type="text" name="contract" value={accountBasicInfo.contract}
                                                className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpCity" className="form-label col-form-label">CITY</label>
                                        <div className="">
                                            <Input type="text" name="corpCity" value={accountBasicInfo.accountAddress.corpCity}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please enter City" validations={[required]} />

                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="vendorId" className="form-label col-form-label">VENDOR ID</label>
                                        <div className="">
                                            <Input type="text" name="vendorId" value={accountBasicInfo.vendorId}
                                                className="form-control form-control-sm" onChange={(e) => {
                                                    this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpState" className="form-label col-form-label">STATE</label>
                                        <div className="">
                                            <Select className="form-control form-control-sm" name="corpState" value={accountBasicInfo.accountAddress.corpState}
                                                onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please select State" validations={[required]} >
                                                <option value={""}>{""}</option>
                                                {allStates.map((state, key) => {
                                                    return (
                                                        <option key={key} value={state.optionVal}>
                                                            {state.optionTxt}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col">
                                    <div className="">
                                        <label htmlFor="salesRepId" className="form-label col-form-label">SALES REP</label>
                                        <div className="">
                                            <Select className="form-control form-control-sm" name="salesRepId" value={accountBasicInfo.salesRepId}
                                                onChange={(e) => {
                                                    this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                }}>
                                                <option value={""}>{""}</option>
                                                {salesRep.sort(function (a, b) {
                                                    if (a.optionTxt.toUpperCase() < b.optionTxt.toUpperCase())
                                                        return -1;
                                                }).map((state, key) => {
                                                    return (
                                                        <option key={key} value={state.optionVal}>
                                                            {state.optionTxt}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="corpZipCode" className="form-label col-form-label">ZIP</label>
                                        <div className="">
                                            <Input type="text" name="corpZipCode" value={accountBasicInfo.accountAddress.corpZipCode} className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                }} requiredmsg="Please enter valid ZIP code" validations={[required, zipValidation]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="maxArrivalTime" className="form-label col-form-label">MAX ARRIVAL TIME</label>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <Input type="number" name="maxArrivalTime" value={accountBasicInfo.maxArrivalTime} className="form-control form-control-sm"
                                                    requiredmsg="Please enter max arrival time"
                                                    validations={isManagement() ? [required, NotZero] : [NotZero]}
                                                    onChange={(e) => {
                                                        this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="acc-info-contact-zip" className="form-label col-form-label col-sm-2">Mins</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="reviewLocation" className="form-label col-form-label">REVIEW LOCATION</label>
                                        <div className="">
                                            <Select className="form-control form-control-sm" name="reviewLocation" value={accountBasicInfo.reviewLocation} onChange={(e) => {
                                                this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                            }}>
                                                <option value={""}>{""}</option>
                                                {reviewLocation.sort(function (a, b) {
                                                    if (a.location.toUpperCase() < b.location.toUpperCase())
                                                        return -1;
                                                }).map((location, key) => {
                                                    return (
                                                        <option key={key} value={location.location}>
                                                            {location.location}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <div className="col-md-4">
                            <h6 className="text-blue text-bg-color form-group">TIME PLAN</h6>
                            <h6 className="text-bg-color">Reason Codes</h6>
                            <div className="form-group">
                                <label htmlFor="accountReasons" className="form-label col-form-label"> ACCOUNT REASONS</label>
                                <div className="">
                                    <ReactSelect name="accountReasons"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={this.props.callReasons.map((reason, t) => {
                                            return ({ value: reason.optionVal, label: reason.optionTxt })
                                        })}
                                        value={(this.props.accountBasicInfo.accountReasons || []).map((item) => {
                                            return ({ value: item.reasonId, label: item.reasonName })
                                        })}
                                        
                                        onChange={(selectedOption) => {
                                            let selectedReasons = [];
                                            (selectedOption || []).map((selectedVal) => {
                                                selectedReasons.push({
                                                    "reasonId": selectedVal.value,
                                                    "reasonName": selectedVal.label,
                                                    'idCompany': getCompanyId()
                                                })
                                            })
                                            this.props.onTimePlanFieldChange('accountReasons', selectedReasons);
                                            this.forceUpdate();
                                        }} isDisabled={this.checkAccess()} />
                                </div>
                            </div>
                            <div>
                                <h6 className="text-bg-color">ACCOUNT EXPIRE</h6>
                                <div className="form-group">

                                    <label className="form-label col-form-label">ACCOUNT WILL EXPIRE ON</label>
                                    <div className="">
                                        <TmDateTimePicker
                                            className="form-control"
                                            selectedDate={
                                                this.props.accountBasicInfo.expiryDate === "" || this.props.accountBasicInfo.expiryDate === null
                                                    ? null
                                                    : moment(
                                                        this.props.accountBasicInfo.expiryDate,
                                                        GlobalValues.dateFormat
                                                    )._d
                                            }
                                        minDate={this.props.accountBasicInfo.expiryDate === "" || this.props.accountBasicInfo.expiryDate === null
                                        ? new Date()
                                        : moment(
                                            this.props.accountBasicInfo.expiryDate,
                                            GlobalValues.dateFormat
                                        )._d}
                                            dateFormat={GlobalValues.dateFormat}
                                            validationArray={[required]}
                                            showYearDropdown={true}
                                            showmonthdropdown={true}
                                            name="expiryDate"
                                            reqmsg="Expiry Date required"
                                            onDateSelectChange={e => {
                                                this.props.onTimePlanFieldChange('expiryDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                this.forceUpdate()
                                            }} disabled={this.checkAccess()}
                                        />

                                    </div>
                                    {
                                        this.props.accountBasicInfo.accountId !== 0 ?
                                            <div className="form-group">
                                                <label className=""><b>IF THE ACCOUNT IS ALREADY CREATED</b></label>

                                                <button type="button" className={this.props.accountBasicInfo.isActive === 1 ? "btn btn-sm btn-danger" : "btn btn-sm btn-success"} onClick={() => {
                                                    this.props.onActiveInactiveAccount(this.props.accountBasicInfo.isActive === 1 ? 0 : 1)
                                                }}>{this.props.accountBasicInfo.isActive === 1 ? "Disable The Account" : "Enable The Account"}</button>
                                            </div>:null } </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                </Form> : null}
                {isManagement() ? <AccountInfoContactList disabled={this.checkAccess()}
                    isPhoneRequired={false} isEmailRequired={false}
                    contactListData={this.props.accountBasicInfo.accountContactForm.accountContacts || []}
                    initRecForEdit={this.state.accountContactRec}
                    onContactSubmit={(e, contactObj, index, success, fail) => {
                        this.props.onAccountContactSubmit(e, contactObj, index, success, fail);
                    }}
                    onContactDelete={(index) => {
                        this.props.onAccountContactDelete(index);
                    }}
                /> : null}
                <AccountNotes notesData={this.props.accountBasicInfo.accountNotesForm.accountNotes || []}
                    onNotesSubmit={(e, notesObj, index) => {
                        this.props.onAccountNotesSubmit(e, notesObj, index);
                    }}
                    onNotesDelete={(index) => {
                        this.props.onAccountNotesDelete(index)
                    }} disabled={this.checkAccess()}
                />

               {isManagement()? <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px' }} >
                    <div className="mr-3">
                        <button className="btn btn-success btn-sm pull-right" onClick={e => {
                            e.preventDefault();
                            this.formAccountInfo.validateAll();
                            if (!isValidForm(this.formAccountInfo)) {
                            } else {
                                this.props.onSubmitBasicInfo(e);
                            }
                        }} disabled={this.checkAccess()}>Next</button>
                    </div>
                    {
                        ((this.props.accountBasicInfo.accountId || 0) !== 0) ?
                            <div className="mr-4">
                                <button
                                    className="btn btn-sm btn-success pull-right"
                                    style={{ marginTop: '0px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.onTimePlanSubmit(e);
                                    }}
                                    disabled={this.checkAccess()}
                                >SAVE ACCOUNT</button>
                            </div> : null
                    }
                </div>:null}
            </div>
        </Fragment>);
    }

}
export default AccountInfo
