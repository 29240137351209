import {GET_CRDETAIL_LIST,GET_AUCTIONPAYMENT_LIST} from './../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
import { store } from '../../../store/configureStore';
export const getCRDetailsList=(asOfDate, idCompany,companyType)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`getCompanyReceivableReport?access_token=${getAccessToken()}&companyId=${idCompany}&asOfDate=${asOfDate}&companyType=${companyType}`);
            if(resp.status===200){
                dispatch({type:GET_CRDETAIL_LIST,crDetailObj:resp.data})
            }else{
                dispatch({type:GET_CRDETAIL_LIST,crDetailObj:undefined})
            }
        }catch(ex){
            dispatch({type:GET_CRDETAIL_LIST,crDetailObj:undefined})
        }
    }
}
export const getClearCRDetailsList=()=>{
    return async (dispatch) => {
        dispatch({type:GET_CRDETAIL_LIST,crDetailObj:undefined})
    }
}

export const setBatchPayData=(index, value,key,companyType)=>{
    let batchPayObj = {};
    let sum =0;
    let crDetails = (store.getState()).crDetailsList.crDetails || {};
    let auctionBatchPay = (store.getState()).auctionPaymentDetails.auctionBatchPay || {};
    if (companyType=="managed") {
        batchPayObj = crDetails.managedCompanyReceivableDetailList[0].companyReceivableInvoiceDetailList;
    }else{
        batchPayObj = crDetails.retailCompanyReceivableDetailList[0].companyReceivableMonthlyDetailList;
    }
    if (key == "paidInFull") {
        batchPayObj[index]["paymentAmount"]=0;
        if (value==1)
        batchPayObj[index]["paymentAmount"]=batchPayObj[index]["receivable"];
    } 
    batchPayObj[index][key]=value;
    if (companyType=="managed") {
        crDetails.managedCompanyReceivableDetailList[0].companyReceivableInvoiceDetailList=batchPayObj;
    }else{
        crDetails.retailCompanyReceivableDetailList[0].companyReceivableMonthlyDetailList=batchPayObj;
    }
    if (crDetails.managedCompanyReceivableDetailList.length||[]>0) {
       let finalData = crDetails.managedCompanyReceivableDetailList[0].companyReceivableInvoiceDetailList;
       sum = finalData.reduce(function(cnt,o){
           return cnt + parseFloat(o.paymentAmount==""?0.0:o.paymentAmount);
       },0)
    }
    if (crDetails.retailCompanyReceivableDetailList.length||[]>0) {
        let finalData = crDetails.retailCompanyReceivableDetailList[0].companyReceivableMonthlyDetailList;
        sum += finalData.reduce(function(cnt,o){
            return cnt + parseFloat(o.paymentAmount==""?0.0:o.paymentAmount);
        },0)
    }
    auctionBatchPay["totalPaymentAmt"]=parseFloat(sum).toFixed(2);
    return async (dispatch) => {
        dispatch({type:GET_CRDETAIL_LIST,crDetailObj:crDetails})
        dispatch({type:GET_AUCTIONPAYMENT_LIST,auctionBatchPay:auctionBatchPay});
    }
}
