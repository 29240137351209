import * as actionTypes from '../../actions/actionTypes';
const defaultRatePlan={
  ratePlanList:[],
  ratePlanObject: {
    "defaultRatePlanCd": "",
    "defaultRatePlanId": "",
    "defaultRatePlanName": "",
    "defaultRatePlanTowType": "",
    "freeHours": 0,
    "minServiceCharge": "",
    "serviceEnd": null,
    "serviceFlatRate": "",
    "serviceFreeHours": 0,
    "serviceHourlyRate": "0.00",
    "serviceIncrementHours": "0.00",
    "serviceMileageLoadChargeAfterLimit": "0.00",
    "serviceMileageLoadLimit1": "0.00",
    "serviceMileageLoadLimit1Charge": "0.00",
    "serviceMileageLoadLimit2": "0.00",
    "serviceMileageLoadLimit2Charge": "0.00",
    "serviceMileageUnloadChargeAfterLimit": "0.00",
    "serviceMileageUnloadLimit1": "0.00",
    "serviceMileageUnloadLimit1Charge": "0.00",
    "serviceMileageUnloadLimit2": "0.00",
    "serviceMileageUnloadLimit2Charge": "0.00",
    "serviceMinHours": 0,
    "serviceStart": null,
    "status": 0,
    "storageChargeAfterLimit": "0.00",
    "storageCustomPeriod": 0,
    "storageLimit1": "0.00",
    "storageLimit1Charge": "0.00",
    "storageLimit2": "0.00",
    "storageLimit2Charge": "0.00",
    "storagePeriod": "",
    "threshold": ""
  },
  chargeList:[],
  index:null,
  monetizationChargeList:[],
  newCustomCharge:{
    "chargeListId":0,
    "chargeName":"",
    "isEnableForDriver":"0",
    "isEnabled":"0",
    "addlActionForDriver":"0",
    "promptMessage":"",
    "companyId":"0",
    "global":false,
    "isBillable":0
  }
}
export const ratePlanReducer = (state = defaultRatePlan, action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_RATEPLANS:       
        return {...state , ratePlanList:(action.ratePlanList || defaultRatePlan.ratePlanList)};
      case actionTypes.GET_RATEPLAN_RECORD:
        return {...state , ratePlanObject:(action.ratePlanObject || defaultRatePlan.ratePlanObject) };
      case actionTypes.CLEAR_RATEPLAN_RECORD:
        return {...state , ratePlanObject:(action.ratePlanObject || defaultRatePlan.ratePlanObject) };
      case actionTypes.GET_CHARGE_LIST:
        return {...state , chargeList:(action.chargeList || defaultRatePlan.chargeList) };
      case actionTypes.GET_CHARGE_LIST_INDEX:
        return {...state , index:(action.index || defaultRatePlan.index) };
      case actionTypes.GET_MONETIZATION_CHARGE_LIST:
          return {...state , monetizationChargeList:JSON.parse(JSON.stringify((action.monetizationChargeList || defaultRatePlan.monetizationChargeList)))};  
      case actionTypes.SET_CUSTOM_CHARGE_TYPE:
        return {...state , newCustomCharge:(action.newCustomCharge || JSON.parse(JSON.stringify(defaultRatePlan.newCustomCharge)) ) };
      default:
        return state;
    }
  };