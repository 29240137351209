import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import VendorInfo from '../../components/adminComponents/vendors/vendorInfo/vendorInfo';
import {getAccountList} from '../../actions/adminAction/accountAction';
import {changeVendorField, saveVendor, isValidUser, getVendor, clearVendor,deleteVendor,getVendorList} from '../../actions/adminAction/vendorActions';
import {notify} from '../../util/notifications';
import queryString  from 'query-string';
import {getAccessLevelsByModule} from '../../util/customMethods.js'
let pageTitle='Add New Vendor';
class VendorsRecPage extends Component {
		state={
			action:'NEW',
			accountSearch:	{"searchQuery":"","limit":0,"accountName":"","accountType":"","accountStatus":"1"}
		}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	componentWillMount=()=>{
		const {dispatch}=this.props;
		const queryValues=queryString.parse(this.props.location.search);
		dispatch(clearVendor());
		if(parseInt(queryValues.vid) !== -1){
			dispatch(getVendor(parseInt(queryValues.vid)));
			this.setState({action:'EDIT'})
		}
		else
		{
			this.setState({action:'NEW'})
		}
		dispatch(getAccountList(this.state.accountSearch));
}

	onVendorSubmit=async ()=>{
		const {dispatch,history}=this.props;
		let _reqObj=this.props.vendorsObject;
		let resp=await dispatch(saveVendor(_reqObj,this.state.action));
		if(resp.statusCode===200){
			notify.createNotification('success',resp.message,this.props.refNotify);
			history.push('/admin/vendors');
			}else{
				notify.createNotification('error',resp.message,this.props.refNotify);
			}
	}
	isValidUser=async (email)=>{
		const {dispatch}=this.props;
		let res=await dispatch(isValidUser(email));
		return res;
	}

	onVendorFieldChange=(key,value)=>{
		 const {dispatch}=this.props;
		 dispatch(changeVendorField(key,value));
	}

	deleteVandar = async(vid, status) => {
		const { dispatch ,history} = this.props;
		const res = await dispatch(deleteVendor(vid, status))
		if (res.statusCode === 200) {
			notify.createNotification('success', res.message, this.props.refNotify);
		} else {
			notify.createNotification('error', res.message, this.props.refNotify);
		};
		history.push('/admin/vendors');
	}
	cancelVendorOnClick=()=>{
		const {dispatch, history}=this.props;
		dispatch(clearVendor());
		history.push('/admin/vendors');
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Vendor');
		this.checkViewAccess(accessLevelObj);
		document.title= this.state.action==='NEW'?pageTitle:this.props.vendorsObject.vendorFirstName+' '+this.props.vendorsObject.vendorLastName;
    return (
      <Fragment>
			<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-money"></i> {this.state.action==='NEW' ?'Add New Vendor User':`Edit Vendor ${this.props.vendorsObject.vendorFirstName}`}</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
							 <VendorInfo 
							 activeInactiveVendorOnClick={(e,vid,status)=>{
								this.deleteVandar(vid,status);
							 }}
							 onVendorSubmit={()=>{
								this.onVendorSubmit();
							}} 
							 accessLevel={accessLevelObj||{}}
							 onVendorFieldChange={(key,val)=>{this.onVendorFieldChange(key,val);}}
							 data={this.props.accountList} 
							  isValidUser={(e,email)=>{ 
							 	return this.isValidUser(email)
							}}
							vendor={this.props.vendorsObject} 
							
								cancelVendorOnClick={(e)=>{
									this.cancelVendorOnClick();
								}}
								action={this.state.action}/>
			</div>
						
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		accountList:state.accountList.accountInfoList,
		vendorsObject:state.vendorList.vendorsObject
  }
};
export default withRouter(connect(mapStateToProps)(VendorsRecPage));