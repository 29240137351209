import React, { Component,Fragment } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {utilMethods} from '../../../util/util';
import ReactTablePagination from './reactTablePagination'
class TmReactTable extends Component {
render(){
    return(<>
        <ReactTable {...this.props} rowsText="" ref={(reactTable) => { this.tmReactTable = reactTable; }} 
        minRows = {0}
        PaginationComponent={ReactTablePagination}
         defaultFilterMethod={(filter, row)=> {
              const id = filter.pivotId || filter.id;
              return (
                row[id] !== undefined ?
                utilMethods.isNumber(row[id])? (row[id].toString()).includes(filter.value):
                  String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                  true
              )}} />
    </>)
}
}
export default TmReactTable