import * as actionTypes from '../../actionTypes';
import { moduleTypes } from '../../../util/constants';
import { getAccessToken } from '../../authenticateActions'
import { getAxiosInstance } from '../../../util/service';
export const getAuditReport = (obj, status) => {
    return async (dispatch) => {
        try {
             const _url = `/fetchAuditReport/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&userId=${obj.userId}&location=`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if (response.status === 200) {
                dispatch({ type: actionTypes.GET_AUDIT_REPORT, auditReport: response.data.response || [] })
            } else {
                dispatch({ type: actionTypes.GET_AUDIT_REPORT, auditReport: undefined })
            }
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_AUDIT_REPORT, auditReport: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}
export const cleanAuditReport = () => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_AUDIT_REPORT, auditReport: undefined })
    }
}
export const emailResponseReport = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/emailAuditReport/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&userId=${obj.userId}&location=`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}