import * as actionTypes from '../../actions/actionTypes';
const defaultObject={
  callList:[],
  truckList:[],
  invoiceRecord:{}
}
export const dispatchCallsReducer = (state = defaultObject, action) => {
  switch (action.type) {
    case actionTypes.GET_DISPATCH_CALL_LIST:
        return {...state , callList:(action.callList || defaultObject.callList)};
    case actionTypes.GET_DISPATCH_TRUCK_LIST:
        return {...state , truckList:(action.truckList || defaultObject.truckList)};
    case actionTypes.GET_INVOICE_DETAILS:
        return {...state, invoiceRecord:(action.invoiceRecord||defaultObject.invoiceRecord)}    
    default:
      return state;
  }
};