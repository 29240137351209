import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import queryString  from 'query-string'
import TruckInfo from '../../components/adminComponents/trucks/truckInfo/truckInfo';
import {getTruck,changeTruckField,getServiceDetails,
	getTruckNotes,
	setTruckNotesToList,
	setTruckServiceToList,
saveTruck,saveServiceList,saveTruckNotes, getVehicleDetails} from '../../actions/adminAction/truckActions';
import {getAllTowTypes} from '../../actions/adminAction/towTypeActions';
import {getStateList} from '../../actions/adminAction/statesActions'
import moment  from 'moment'
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods';
let pageTitle='Add New Truck';
class TrucksRecPage extends Component {
	constructor(){
		super();
		this.state={
			action:'NEW',
			tid: 0
		}
		this.checkViewAccess=this.checkViewAccess.bind(this)
		this.initTruckForm=this.initTruckForm.bind(this);
		this.getTruckRecordById=this.getTruckRecordById.bind(this);
		this.getAllTowTypes=this.getAllTowTypes.bind(this);
		this.getServiceDetails=this.getServiceDetails.bind(this);
		this.getTruckNotes=this.getTruckNotes.bind(this);
		this.onTruckFieldChange=this.onTruckFieldChange.bind(this);
		this.onTruckNotesSubmit=this.onTruckNotesSubmit.bind(this);
		this.onTruckServiceSubmit=this.onTruckServiceSubmit.bind(this);
		this.onTruckSubmit=this.onTruckSubmit.bind(this);
	}
	componentWillMount=()=>{
		const queryValues=	queryString.parse(this.props.location.search);
		if(parseInt(queryValues.tid) !== -1){
			this.setState({action:'EDIT', tid: parseInt(queryValues.tid)})
			}else{
					this.setState({action:'NEW', tid: 0})
			}	
		this.initTruckForm(queryValues.tid)
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	initTruckForm=async(id)=>{
		const {dispatch}=this.props;
		await dispatch(getStateList());		
		//await this.getAllTowTypes();
		this.getTruckRecordById(id);
	}
	getTruckRecordById=(id)=>{
		const {dispatch}=this.props;
		dispatch(getTruck(parseInt(id)));
	}
	getAllTowTypes=()=>{
		const {dispatch}=this.props;
		dispatch(getAllTowTypes({searchQuery:''}));
	}
	onTruckFieldChange=(key,value)=>{
		const {dispatch}=this.props;
		let _truckObject=Object.assign({},this.props.truckObject);
		_truckObject[key]=value;
		dispatch(changeTruckField(_truckObject));
	}
	
	getServiceDetails=(id)=>{
		const {dispatch}=this.props;
		dispatch(getServiceDetails(id));
	}

	getTruckNotes=(id)=>{
		const {dispatch}=this.props;
		dispatch(getTruckNotes(id))
	}
	onTruckNotesSubmit=(e,notesObj)=>{
		const {dispatch}=this.props;
		notesObj.notesDate= moment().format('MM/DD/YYYY')
		notesObj.notesTime=moment().format("HH:mm:ss")
		notesObj.truckId= this.props.truckObject.truckId===-1?null:this.props.truckObject.truckId
		notesObj.userId= 'admin';
		dispatch(setTruckNotesToList(notesObj));
	}
	onTruckServiceSubmit=(e,serviceObj)=>{
		const {dispatch}=this.props;		

		if(!serviceObj.serviceStatus){
			notify.createNotification('warning',"Select service detail type",this.props.refNotify);
			return;
		}
		serviceObj.truckId= this.props.truckObject.truckId===-1?null:this.props.truckObject.truckId
		serviceObj.userId= 'admin';
		dispatch(setTruckServiceToList(serviceObj));
		this.onTruckFieldChange('serviceStatus',serviceObj.serviceStatus);
		if(serviceObj.serviceStatus){
			notify.createNotification('success',"service detail type saved",this.props.refNotify);
			return;
		}
		// console.log("SERVICE LIST: ", this.props.serviceList)
		// console.log("TRUCK: ", this.props.truck)
	}

	onTruckSubmit=async (e)=>{
		const {dispatch,history}=this.props;
		let _reqObj={
			addTruck:this.props.truckObject,
			serviceDetailsForm:{serviceDetails:this.props.serviceList},
			truckNotesForm:{truckNotes:this.props.truckNotesList}
		}
		if(this.props.serviceList.length > 0){
			const res=await dispatch(saveTruck(_reqObj));
			if(res.statusCode===200){
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
				history.push('/admin/trucks');
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}else{
			notify.createNotification('error','Please submit add service details',this.props.refNotify);
		}	
	}

	getVINValidate=async (e,vinNum)=>{
		const {dispatch,history}=this.props;
		let _reqObj={
			"vinLookupReq": {
			  "vin": vinNum
			}
		  }
		const res=await dispatch(getVehicleDetails(_reqObj))
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}

	gotoTruckList=()=>{
		this.props.history.push('/admin/trucks');
	}

  render() {
	const accessLevelObj=getAccessLevelsByModule('Trucks');
	document.title= this.state.tid===0?pageTitle:`Truck# ${this.props.truckObject.truckId}`;
	this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
			<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-truck"></i> {this.state.action==='NEW' ?'Add Truck':'Edit Truck'}</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
			<TruckInfo
				accessLevel={accessLevelObj||{}} 
				stateList={this.props.stateList} 
				truck={this.props.truckObject} 
				serviceList={this.props.serviceList} 
				notesList={this.props.truckNotesList}
				towTypes={this.props.towTypes} 
				action={this.state.action}
				onTruckFieldChange={(e, key,value)=>{
				 this.onTruckFieldChange(key,value);
			 }}
			 getVINValidate={(e,vinNum)=>{this.getVINValidate(e,vinNum)}}
			 onTruckNotesSubmit={(e,notesObj)=>{this.onTruckNotesSubmit(e,notesObj)}}
			 onTruckServiceSubmit={(e,serviceObj)=>{this.onTruckServiceSubmit(e,serviceObj)}}
			 onTruckSubmit={(e)=>{this.onTruckSubmit(e)}} gotoTruckList={this.gotoTruckList}  />
			</div>
						
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		truckObject:state.truck.truckObject,
		stateList:state.states.stateList,
		towTypes:state.towTypeList.towTypes,
		serviceList:state.truck.serviceList,
		truckNotesList:state.truck.truckNotesList
  }
};

export default withRouter(connect(mapStateToProps)(TrucksRecPage)) ;