import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DriverCommissionsSummaryCtrl from '../../../components/reportsComponents/drivermanagement/driverCommissionsSummaryCtrl';
import DriverCommissionsSummaryTable from '../../../components/reportsComponents/drivermanagement/driverCommissionsSummaryTable';
const pageTitle = 'Driver Commission Summary'
let DriverCommissionsSummaryForm={
	formDriverCommissionsSummaryInfo:{}
}

class DriverCommissionsSummary extends Component {
	constructor() {
		super();
		this.state = {
            fromDate:'',
            toDate:''
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
    }
    onDriverCommissionsSummaryFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Driver Commission Summary</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <DriverCommissionsSummaryCtrl onDriverCommissionsSummaryFieldChange={(key, value)=>this.onDriverCommissionsSummaryFieldChange(key, value)}
                    initCallBack={(frm)=>{
						DriverCommissionsSummaryForm.formDriverCommissionsSummaryInfo=frm;
                    }} fromdate={this.state.fromDate} todate={this.state.toDate}/>
                    <DriverCommissionsSummaryTable />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(DriverCommissionsSummary));
