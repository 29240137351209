import * as actionTypes from '../../../actions/actionTypes';
const defaultBPDetails={
  bpDetailsObj:{}
}
export const batchPaymentReducer = (state = defaultBPDetails, action) => {
    switch (action.type) {
      case actionTypes.GET_BATCHPAYMENT_LIST:
        return {...state ,bpDetails:(action.bpDetailsObj||defaultBPDetails.bpDetailsObj)};
      default:
        return state;
    }
  };