import * as actionTypes from '../../actions/actionTypes';
const statementMessage={
    "greetings":'',
    "paragraph1":'',
    "paragraph2":'',
    "paragraph3":'',
    "thanksMessage":'',
    "signature":'',
    "updateDate":'',
    "companyId":0,
    "corporationId":0
}

export const statementMessageReducer = (state = statementMessage, action) => {
  switch (action.type) {
    case actionTypes.GET_STATEMENT_MESSAGE:
      return  (action.statementMsg || statementMessage);
    default:
      return state;
  }
};