import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {resetPassword} from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";
import {required, patterncheck, compare} from '../../util/validationMethods.js'
import {getSubDomain} from '../../util/customMethods';
import TmInputGroup from '../../components/utilComponents/tmInputGroup/tmInputGroup'
import Background from '../../assets/img/bg_body.jpg';
import './login.css';
const pageTitle="Account Recovery";
class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            forgetToken:'',
            newPassword:'',
            conformPwd:'',
            empEmail:'',
            userId:''
        }
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount=()=>{
        let _queryString=queryString.parse(this.props.location.search)
        this.setState({
            forgetToken:(_queryString.forgetToken||''),
            empEmail:(_queryString.empEmail),
            userId:(_queryString.userid)
        });
    }
    onChangeValues = (event) => {
        let _state = this.state;
        _state[event.currentTarget.name] = event.currentTarget.value;
        this.setState(_state
        );
    }
    handleSubmit = async () => {   
        const { dispatch, history } = this.props;
        let res=await dispatch(resetPassword(this.state.newPassword, this.state.forgetToken,this.state.empEmail,this.state.userId,getSubDomain()));
        if(res.statusCode===200){
            notify.createNotification('success',res.message,this.props.refNotify);
            history.push('/login');
        }
        else{
             notify.createNotification('error',res.message,this.props.refNotify);
        }
    }
    render() {        
        return (<>
        <section className="wrapper" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat,no-repeat",
                backgroundPosition: "center center,center center",
                backgroundSize: "cover,cover"
            }}>
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center pd-20">
                <div className="login-box bg-white box-shadow pd-20 border-radius-5">
			        <img src={require('../../assets/images/SVMS Logo Final-A-FULL-MED.png')} alt="ACT" className="login-img"/>
			        <h6 className="text-center mb-10"><strong>Reset Password</strong></h6>
                    <Form name="frmForgot" className="mb-lg">
                        <Fragment>
                        <div className="input-group custom input-group-lg">
                                <TmInputGroup id="newPassword" name="newPassword" type="password" 
                                placeholder="Enter Password" className="form-control" 
                                value={this.state.newPassword} 
                                onChange={(e)=>{this.onChangeValues(e)}} 
                                validations={[required, patterncheck]} 
                                requiredmsg="Please enter password" 
                                append='true' appendText={
                                                        <div className="input-group-append custom">
                                    <span className="input-group-text"><i className="fa fa-lock" aria-hidden="true"></i></span>
                                </div>}/> 
                        </div>
                        <div className="input-group custom input-group-lg">
                                <TmInputGroup id="conformPwd" name="conformPwd" type="password" 
                                placeholder="Confirm Password" className="form-control" 
                                compareVal={this.state.newPassword}
                                value={this.state.conformPwd} 
                                onChange={(e)=>{this.onChangeValues(e)}} 
                                validations={[required, compare]} 
                                requiredmsg="Please enter password" 
                                append='true' appendText={
                                                        <div className="input-group-append custom">
                                    <span className="input-group-text"><i className="fa fa-lock" aria-hidden="true"></i></span>
                                </div>}/> 
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="input-group">                                        
                                    <Button type="button" onClick={()=>{
                                        this.handleSubmit();
                                    }} className="btn btn-outline-primary btn-lg btn-block" >Submit </Button>
                                </div>
                            </div>
                        </div>                            
                        </Fragment>                                    
                    </Form>
                </div>
            </div>
            </section>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        refNotify:state.refNotify
    }
};

export default withRouter(connect(mapStateToProps)(ResetPassword))