import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {getCallList,getTruckList} from '../../actions/dispatchAction/dispatchCallsAction'
import {filterTruckList,fitlerInvoiceList} from '../../actions/dispatchAction/assignInvoiceAction';
import VisibilityToggles from '../../components/dispatchComponents/assign/visibilityToggles/VisibilityToggles'
import Map from '../../components/dispatchComponents/assign/AssignMap';
import {setDashboardFilterByType,dashboardFilterByType} from '../../util/customMethods';
const pageTitle = 'Map';
class MapSearch extends Component {
	state={
			intervalId:0,
			filterData:{
				trucks:{
					'Available': {text:'Available',selected:false},
					'Assigned': {text:'Assigned',selected:false},
					'Loaded': {text:'Loaded',selected:false},
					'Doubled': {text:'Doubled',selected:false}
				},
				invoice:{
					pickup:{
						'Unassigned': {text:'Unassigned',selected:true},
						'Enroute': {text:'Enroute',selected:true},
						'Arrived': {text:'Arrived',selected:true},
						'Hooked': {text:'Hooked',selected:true},
						'Dropped': {text:'Dropped',selected:true}
					},
					priority:{
						1: {text:'1',selected:true},
						2: {text:'2',selected:true},
						3: {text:'3',selected:true},
						4: {text:'4',selected:true},
						5: {text:'5',selected:true},
						6: {text:'6',selected:true},
						7: {text:'7',selected:true},
						8: {text:'8',selected:true}

					}
				},
				dropLocation:true
			}
		}
	componentWillMount=()=>{
		this.bindCallListAndCall();
	}
	bindCallListAndCall=async()=>{
		const {dispatch} =this.props
		await dispatch(getCallList());
		await dispatch(getTruckList())
	}
	componentDidMount(){
		let intervalId = setInterval(this.bindCallListAndCall, 30000)
		this.setState({ intervalId: intervalId })
	  }
	  
	  componentWillUnmount = () => {
		clearInterval(this.state.intervalId)
	}
	render() {
		document.title=pageTitle;
		const {callList,truckList}=this.props;
		let  filterData = dashboardFilterByType('mapFilter');
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Map</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<div className="row">
						<div className="col-md-12 mb-10">
							<VisibilityToggles filterContent={filterData} onSelectionChange={(key,newObject)=>{
									let _filterData=filterData;
									_filterData[key]=newObject;
									this.setState({filterData:_filterData})
									setDashboardFilterByType("mapFilter",_filterData);
								}} />
						</div>
						<div className="col-md-12">
							<Map mapVehicleInfo={filterTruckList(truckList,filterData.trucks)} 
									dropPointers={filterData.dropLocation} 
									PickUpOinters={true} 
									callData={fitlerInvoiceList(callList,{pickup:filterData.invoice.pickup,priority:filterData.invoice.priority})} 
									/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		authInfo: state.authInfo,
		callList: state.dispatchCalls.callList,
		truckList:state.dispatchCalls.truckList
	}
};

export default withRouter(connect(mapStateToProps)(MapSearch));
