import {utilMethods} from './util'
export const haveAccess=(mainModule,subModule=undefined,accessLevelList)=>{
    let haveAccess=false;
    let accessGroup=utilMethods.groupBy(accessLevelList,"module");
    if(!subModule){
        if(Object.keys(accessGroup).length>0){
            haveAccess=accessGroup[mainModule].some((mod)=>{           
                return (mod.permissionView==1 || mod.permissionUpdate==1|| mod.permissionAdd==1 || mod.permissionDelete==1)
            })
        }
    }
    else{
        if(Object.keys(accessGroup).length>0){
            let Idx=accessGroup[mainModule].findIndex((mod)=>{           
                return (mod.subModule.toLowerCase()===subModule.toLowerCase())
            })
            if(Idx!==-1){
                haveAccess=accessGroup[mainModule][Idx].permissionView===1?true:false
            }
        }

    }
    return haveAccess;
}

export const dashboardViewAccess=(module,dashboardAccess)=>{
    let show=false;
    if((dashboardAccess[module]||[]).length>0){
        show=dashboardAccess[module].some((item)=>{
            return item.visible===true
        });
    }
    return show;
}
export const dashboardTailViewAccess=(module,tailName,dashboardAccess)=>{
    let show=false;
    if((dashboardAccess[module]||[]).length>0){
        let idx=dashboardAccess[module].findIndex((item)=>{
            return item.visible===true && (item.tileName||'').toLowerCase()===tailName.toLowerCase()
        });
        if(idx!==-1){
            show=true;
        }
    }    
    return show;    
}