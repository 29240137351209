import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {notify} from '../../../util/notifications';
import { GlobalValues } from '../../../util/constants';
import moment from 'moment';
import {isValidForm} from '../../../util/validationMethods';
import { getCompanyId, isManagement } from '../../../util/customMethods';
import {getComapanyTypes} from '../../../actions/adminAction/dropDownDataAction'
import {getCRDetailsList, getClearCRDetailsList,setBatchPayData} from '../../../actions/reportAction/monetization/crDetailAction';
import AuctionBatchPaymentCtrl from '../../../components/reportsComponents/monetization/auctionBatchPaymentCtrl';
import AuctionBatchPaymentTable from '../../../components/reportsComponents/monetization/auctionBatchPaymentTable';
import {getMonetizationAuctionBatch ,setBatchPayObj,setBatchPayObjEmpty,savAuctionBatch} from '../../../actions/reportAction/monetization/auctionBatchPaymentAction';
const pageTitle = 'Auction Batch Payment'
let crDetailForm={
    formCRDetailInfo:{},
    formBatchDetailInfo:{}
}
class AuctionBatchPaymentMonetization extends Component {
	constructor(props) {
		super(props);
		this.state = {
            dataFetched:false,
            idCompany:'',
            companyType:""
		}
	}
	componentWillMount = () => {
        document.title = pageTitle;
        this.props.dispatch(setBatchPayObjEmpty())
        this.props.dispatch(getClearCRDetailsList());
        if(isManagement()){
            this.props.dispatch(getComapanyTypes());
        }
    }
    componentWillUnmount=() => {
        this.props.dispatch(setBatchPayObjEmpty())
    }
    onCRDetailFieldChange=(key, value)=>{
        if (key=='idCompany')
        this.setState({idCompany:value});
        else
       this.props.dispatch(setBatchPayObj(key, value))
    }
    getByCompanyId=()=>{
        let id_company=0;
        if(!isManagement()){
            id_company=getCompanyId();
        }else{
            id_company=this.state.idCompany;
        }
        return id_company;
    }
    getCRDetails=(e)=>{
        e.preventDefault();
        crDetailForm.formCRDetailInfo.validateAll();
        if(isValidForm(crDetailForm.formCRDetailInfo)){
            this.props.dispatch(getCRDetailsList(moment(new Date()).format(GlobalValues.dateFormat),this.state.idCompany,this.state.companyType))
            .then(() => {
                this.setState({ dataFetched: true })
        })   
            .catch(err => {
                console.error('fetchReport methods failed with error: ', err);
            });
        }
    }

    	/*function call to release invoice */
        savAuctionBatch = async (e) => {
        e.preventDefault();
		const { dispatch } = this.props
		const res = await dispatch(savAuctionBatch(this.state.idCompany));
		if (res.statusCode === 200) {
            notify.createNotification('success', res.statusMsg, this.props.refNotify);
           dispatch(getClearCRDetailsList());
           dispatch(setBatchPayObjEmpty())
           this.getCRDetails(e);
        } 
        else if(res.statusCode === 404){
			notify.createNotification('warning', res.statusMsg, this.props.refNotify);
		}
        else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

    AuctionBatchPaymentOnchange = (name, value)=>{
        this.setState({[name]:value})
    }
    
    setReferenceToReactTable = ref => {
        this.reactTable = ref;
    };

    tableDataChanged = () => {
        console.log('table data has changed!');
        this.setState({
            data: this.reactTable.getResolvedState().sortedData
        });
    };
    exportToExcel = e => {
        e.preventDefault();
        
    }
	render() {
        const {managedCompanyReceivableDetailList, retailCompanyReceivableDetailList} = this.props.crReportResponse || [];
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4>Batch Payment</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <AuctionBatchPaymentCtrl fetchReportsSubmit={(e)=>this.fetchReportsSubmit(e)}
                    AuctionBatchPaymentOnchange={(name, value)=>this.AuctionBatchPaymentOnchange(name, value)}
                    childCompList={this.props.childCompList} companyTypes={this.props.companyType||[]} idCompany={this.state.idCompany}
                    auctionBatchPaymentObj={this.props.auctionBatchPaymentObj} isFetched={this.state.dataFetched}
                    savAuctionBatch={(e)=>{
                        e.preventDefault();
                        crDetailForm.formBatchDetailInfo.validateAll();
                        if(isValidForm(crDetailForm.formBatchDetailInfo))
                        this.savAuctionBatch(e)
                    }}
                    getCRDetails={(e)=>this.getCRDetails(e)}  initCallBackBatch={(frm)=>{crDetailForm.formBatchDetailInfo=frm;}} 
                    onCRDetailFieldChange={(key, value)=>this.onCRDetailFieldChange(key, value)}
                    setBatchPayData={(index, value,key,companyType)=>this.props.dispatch(setBatchPayData(index, value,key,companyType))}
                    initCallBack={(frm)=>{crDetailForm.formCRDetailInfo=frm;}} 
                    />
                    {this.state.dataFetched?
                   <Fragment>
                    <h6 className="text-blue text-bg-color form-group mt-5">Invoice Billable:</h6>
                    <AuctionBatchPaymentTable type="managed" data={managedCompanyReceivableDetailList||[]}  setBatchPayData={(index, value,key,companyType)=>this.props.dispatch(setBatchPayData(index, value,key,companyType))}
                   />
                    <h6 className="text-blue text-bg-color form-group mt-5">Monthly Billable:</h6>
                    <AuctionBatchPaymentTable type="retail" data={retailCompanyReceivableDetailList||[]}  setBatchPayData={(index, value,key,companyType)=>this.props.dispatch(setBatchPayData(index, value,key,companyType))}
                   />
                    </Fragment>:null
                }
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        refNotify:state.refNotify,
        auctionBatchPaymentObj:state.auctionPaymentDetails.auctionBatchPay||{},
        crReportResponse: state.crDetailsList.crDetails,
        childCompList:state.searchInvoiceList.childComList,
        companyTypes:state.companyTypes.companyType,
        crReportResponse: state.crDetailsList.crDetails
	}
};

export default withRouter(connect(mapStateToProps)(AuctionBatchPaymentMonetization));
