import React, { Component, Fragment } from 'react';
import {isManagement ,isManagemed} from '../../../../util/customMethods';

class AccessPermissions extends Component {
    constructor(props){
        super(props);
        this.onAccessLevelCheckboxChanged=this.onAccessLevelCheckboxChanged.bind(this)
    }

    onAccessLevelCheckboxChanged=(e,isAll,accessType)=>{
        let testObj=this.props.accessLevel;

        Object.keys(testObj).map((_module)=>{
            Object.keys(testObj[_module]).map(_group=>{
                testObj[_module][_group].map(_page=>{
                    switch (accessType) {
                        case 'V': 
                            if(_page.basicAccess.view!==undefined){
                                _page.basicAccess.view=e.target.checked?true:false
                            } 
                            Object.keys(_page.pageLvlAccess).map(_pageLvl=>{
                                if(_page.pageLvlAccess[_pageLvl].view!==undefined){
                                    _page.pageLvlAccess[_pageLvl].view=e.target.checked?true:false
                                }
                            })

                            break;
                        case 'E': 
                            if(_page.basicAccess.edit!==undefined){
                                _page.basicAccess.edit=e.target.checked?true:false
                            } 
                            Object.keys(_page.pageLvlAccess).map(_pageLvl=>{
                                if(_page.pageLvlAccess[_pageLvl].edit!==undefined){
                                    _page.pageLvlAccess[_pageLvl].edit=e.target.checked?true:false
                                }
                            })                                            
                            break;
                        case 'A':
                            if(_page.basicAccess.add!==undefined){
                                _page.basicAccess.add=e.target.checked?true:false
                            }  
                            Object.keys(_page.pageLvlAccess).map(_pageLvl=>{
                                if(_page.pageLvlAccess[_pageLvl].add!==undefined){
                                    _page.pageLvlAccess[_pageLvl].add=e.target.checked?true:false
                                }
                            })                                              
                            break;
                        case 'D':
                            if(_page.basicAccess.delete!==undefined){
                                _page.basicAccess.delete=e.target.checked?true:false
                            } 
                            Object.keys(_page.pageLvlAccess).map(_pageLvl=>{
                                if(_page.pageLvlAccess[_pageLvl].delete!==undefined){
                                    _page.pageLvlAccess[_pageLvl].delete=e.target.checked?true:false
                                }
                            })                       
                            break;
                            default:
                            break;
                        }

                })
            });
        })
        this.props.onAccessLevelAllChanged(testObj)
    }
    
    render(){ 
        const testObj=this.props.accessLevel||{};
        if(isManagement() && testObj.Dispatcher!=undefined && this.props.eid==0){
            delete testObj.Dispatcher['Invoice screen'][3];
            delete testObj.Dispatcher['Invoice screen'][1].pageLvlAccess.Commission;
        }
        if (isManagemed() && testObj.Admin != undefined && this.props.eid == 0) {
            delete testObj.Admin['Account'][0].basicAccess.add;
            delete testObj.Dispatcher['Create New Call'];
        }
        return(<>
         <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
         <table width="100%" border="1" className="table text-uppercase" style={{border:'solid 1px #dcdcdc', overflow: 'visible'}}>
         <thead>
             <tr>
                 <th rowSpan="2" style={{position: 'sticky', top: '-22px', zIndex: '1', backgroundColor: '#C1C1C1'}}>Module</th>
                 <th rowSpan="2" style={{position: 'sticky', top: '-22px', zIndex: '1', backgroundColor: '#C1C1C1'}}>Sub Module</th>
                 <th rowSpan="2" style={{position: 'sticky', top: '-22px', zIndex: '1', backgroundColor: '#C1C1C1'}}>Page</th>
                 <th rowSpan="2" style={{position: 'sticky', top: '-22px', zIndex: '1', backgroundColor: '#C1C1C1'}}>Page level Access</th>
                 <th colSpan="4" style={{position: 'sticky', top: '49px', zIndex: '1', backgroundColor: '#C1C1C1'}}>Permissions</th>
             </tr>
             <tr>
                <th>View 
                <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                <input type="checkbox" id="allView" className="custom-control-input"
                                        onChange={(e)=>{
                                        this.onAccessLevelCheckboxChanged(e,true,'V')
                                    }} /><label className="custom-control-label" htmlFor="allView"></label> </div></th>
                <th>Edit 
                <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                <input type="checkbox"  id="allEdit" className="custom-control-input"
                                        onChange={(e)=>{
                                        this.onAccessLevelCheckboxChanged(e,true,'E')
                                    }} /><label className="custom-control-label" htmlFor="allEdit"></label></div></th>
                <th>Add 
                <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                <input type="checkbox"  id="allAdd" className="custom-control-input"
                                        onChange={(e)=>{
                                        this.onAccessLevelCheckboxChanged(e,true,'A')
                                    }} /><label className="custom-control-label" htmlFor="allAdd"></label></div></th>
                <th >Delete 
                <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                <input type="checkbox"  id="allDel" className="custom-control-input"
                                        onChange={(e)=>{
                                        this.onAccessLevelCheckboxChanged(e,true,'D')
                                    }} /><label className="custom-control-label" htmlFor="allDel"></label></div></th>
             </tr>
         </thead>
         <tbody>
         {
            Object.keys(testObj).map((_module)=>{
                let pagecount=0;
                Object.keys(testObj[_module]).map(_group=>{
                    pagecount+=testObj[_module][_group].length;
                    testObj[_module][_group].map(_page=>{
                        pagecount+=Object.keys(_page.pageLvlAccess).length
                    })
                })
               return (<>
               <tr><td 
               rowSpan={Object.keys(testObj[_module]).length+1+pagecount}>{_module}</td>               
               </tr>
                {
                    Object.keys(testObj[_module]).map(_group=>{
                        let pageLvlCount=0;
                        testObj[_module][_group].map(_page=>{
                            pageLvlCount+=Object.keys(_page.pageLvlAccess).length
                        })

                        return (<>                        
                        <tr><td rowSpan={testObj[_module][_group].length+1+pageLvlCount}>{_group}</td>                        
                        </tr>
                        {
                            testObj[_module][_group].map((_page)=>{
                                return (<>
                                <tr >
                                <td rowSpan={Object.keys(_page.pageLvlAccess).length+1}>{_page.pageName}</td>
                                {/* {Object.keys(_page.pageLvlAccess).length==0? 
                                <> */}
                                <td></td>
                                <td>{_page.basicAccess.view!==undefined?
                                <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                <input type="checkbox" className="custom-control-input" id={'view-'+_page.moduleId} 
                                checked={_page.basicAccess.view} 
                                onChange={(e)=>{
                                    _page['basicAccess'].view=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);

                                }} /><label className="custom-control-label" htmlFor={'view-'+_page.moduleId}></label></div>:null}</td>
                                <td>{_page.basicAccess.edit!==undefined?
                                    <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                <input type="checkbox" className="custom-control-input" id={'edit-'+_page.moduleId} 
                                checked={_page.basicAccess.edit} onChange={(e)=>{
                                    _page['basicAccess'].edit=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);

                                }} /><label className="custom-control-label" htmlFor={'edit-'+_page.moduleId}></label></div>:null}</td>
                                <td>{_page.basicAccess.add!==undefined?
                                    <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                <input type="checkbox" className="custom-control-input" id={'add-'+_page.moduleId}
                                 checked={_page.basicAccess.add} onChange={(e)=>{
                                    _page['basicAccess'].add=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);

                                }} /><label className="custom-control-label" htmlFor={'add-'+_page.moduleId}></label></div>:null}</td>
                                <td>{_page.basicAccess.delete!==undefined?
                                    <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                <input type="checkbox" className="custom-control-input" id={'del-'+_page.moduleId}
                                checked={_page.basicAccess.delete} onChange={(e)=>{
                                    _page['basicAccess'].delete=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);

                                }} /><label className="custom-control-label" htmlFor={'del-'+_page.moduleId}></label></div>:null}</td>
                                {/* </>
                                :null} */}
                                 </tr>
                                {
                                    Object.keys(_page.pageLvlAccess).map(_pageLvl=>{
                                        return (<tr><td>{_pageLvl}</td>
                                        <td>{_page.pageLvlAccess[_pageLvl].view!==undefined?
                                            <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                        <input type="checkbox" className="custom-control-input" id={_pageLvl+'-view-'+_page.moduleId} 
                                        checked={_page.pageLvlAccess[_pageLvl].view} onChange={(e)=>{
                                    _page['pageLvlAccess'][_pageLvl].view=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);

                                }} /><label className="custom-control-label" htmlFor={_pageLvl+'-view-'+_page.moduleId}></label></div>:null}</td>
                                        <td>{_page.pageLvlAccess[_pageLvl].edit!==undefined?
                                            <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                        <input type="checkbox" className="custom-control-input" id={_pageLvl+'-edit-'+_page.moduleId}
                                         checked={_page.pageLvlAccess[_pageLvl].edit} onChange={(e)=>{
                                    _page['pageLvlAccess'][_pageLvl].edit=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);
                                }} /><label className="custom-control-label" htmlFor={_pageLvl+'-edit-'+_page.moduleId}></label></div>:null}</td>
                                        <td>{_page.pageLvlAccess[_pageLvl].add!==undefined?
                                            <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                        <input type="checkbox"  className="custom-control-input" id={_pageLvl+'-add-'+_page.moduleId}
                                         checked={_page.pageLvlAccess[_pageLvl].add} onChange={(e)=>{
                                    _page['pageLvlAccess'][_pageLvl].add=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);
                                }} /><label className="custom-control-label" htmlFor={_pageLvl+'-add-'+_page.moduleId}></label></div>:null}</td>
                                        <td>{_page.pageLvlAccess[_pageLvl].delete!==undefined?
                                            <div className="custom-control custom-checkbox" style={{marginLeft: '35%'}}>
                                        <input type="checkbox" className="custom-control-input" id={_pageLvl+'-del-'+_page.moduleId}
                                        checked={_page.pageLvlAccess[_pageLvl].delete} onChange={(e)=>{
                                    _page['pageLvlAccess'][_pageLvl].delete=e.target.checked?true:false
                                    this.props.onAccessLevelChanged(_module,_group,_page);

                                }} /><label className="custom-control-label" htmlFor={_pageLvl+'-del-'+_page.moduleId}></label></div>:null}</td>
                                        </tr>)
                                    })
                                }                                
                                </>)
                            })
                        }
                        </>)
                    })               
               }
               </>)
            })
         }</tbody>
         </table>
         </div></div>
         <div className="row">
                        <div className="col-md-12 col-sm-12 text-right">
                            <button className="btn btn-sm btn-primary" onClick={(e)=>{
                                this.props.onSubmitAccessLevel(e)
                            }}>{isManagement()?"Save Next" :"Save"}</button>
                        </div>
                    </div>
            <br/>
            <br/>
            <br/>

        </>);
    }
}
export default AccessPermissions