import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import TrucksList from '../../components/adminComponents/trucks/trucksList';
import {getTruckList} from '../../actions/adminAction/truckActions';
import { notify } from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle='Trucks';
class TrucksPage extends Component {
	constructor(props){
		super(props);
		this.state={
			filter:	{
				accountStatus:"In Service",
				searchQuery:''
			}
		}
		this.checkViewAccess=this.checkViewAccess.bind(this)
		this.bindTruckList=this.bindTruckList.bind(this);
		this.onSearchChange=this.onSearchChange.bind(this);
		this.onToggleChange=this.onToggleChange.bind(this);
		this.addTruckOnClick=this.addTruckOnClick.bind(this);
		this.editTruckOnClick=this.editTruckOnClick.bind(this);
	}
	componentWillMount=()=>{
		document.title = pageTitle;
		this.bindTruckList();
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindTruckList=()=>{
		const {dispatch}=this.props;
		dispatch(getTruckList(this.state.filter))
	}
	onSearchChange=(filterObj)=>{
		if(!filterObj.searchQuery){
			// console.log("SEARCH", this.state.searchQuery)
			notify.createNotification("warning","Please enter search string",this.props.refNotify);
		}
		let _filter=this.state.filter;
		_filter.accountStatus=filterObj.accountStatus===-1?"Sold','Out of Service','In Service":
		filterObj.accountStatus===1?"In Service":"Sold','Out of Service"
		_filter.searchQuery=filterObj.searchQuery||'';
		this.setState({filter:_filter},this.bindTruckList());
	}
	onToggleChange=(filterObj)=>{
		let _filter=this.state.filter;
		_filter.accountStatus=filterObj.accountStatus===-1?"Sold','Out of Service','In Service":
		filterObj.accountStatus===1?"In Service":"Sold','Out of Service"
		_filter.searchQuery=filterObj.searchQuery||'';
		this.setState({filter:_filter},this.bindTruckList());
	}
	addTruckOnClick=(e)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('/admin/trucksDetails?tid=-1');
	}
	editTruckOnClick=(e,tid)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('/admin/trucksDetails?tid='+tid);
	}
	
  render() {
		const accessLevelObj=getAccessLevelsByModule('Trucks');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-truck"></i> Truck Search</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<TrucksList trucksListData={this.props.truckList} onSearchChange={this.onSearchChange} 
				onToggleChange={this.onToggleChange} accessLevel={accessLevelObj||{}}
			addTruckOnClick={this.addTruckOnClick} editTruckOnClick={this.editTruckOnClick} />
			</div>			
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		truckList:state.truck.truckList
  }
};
export default withRouter(connect(mapStateToProps)(TrucksPage));
