import React, { Component, Fragment } from 'react';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
//import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment';
class CompanyHistory extends Component {

    render() {
        const companyHistories=this.props.company.companyHistories||[];
        const historyCols = [{
            Header: 'DATE',
            accessor: 'createdDate', // String-based value accessors!
            filterable: true,
            Cell: props => <span>{props.value}</span>,
            minWidth: '60px'

        }, {
            Header: 'TIME',
            accessor: 'createdTime',
            filterable: true,
            Cell: props => <span>{props.value}</span>,
            minWidth: '60px'
        }, {
            Header: 'USER ID',
            accessor: 'userId',
            filterable: true,
            Cell: props => <span>{props.value}</span>,
            minWidth: '150px'
        },
        // {
        //     Header: 'USER NAME',
        //     accessor: 'userFullName',
        //     filterable: true,
        //     Cell: props => <a className="btn btn-link" href="javascript:void(0)">{props.value}</a>,
        //     minWidth: '200px'
        // },
        {
            Header: 'DETAIL',
            accessor: 'historyText',
            filterable: false,
            Cell: (props) => <span>{props.value}</span>,
            minWidth: '100%'
        }];
        return (<Fragment>
            <div className="pd-10">
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                        <h4 className="mb-10">Company History</h4>
                    </div>
                </div>
                <div className="row pb-1">
                    <div className="col-md-6">
                        <p className="font-14 mb-5 pl-5">Company Created On: <strong className="weight-600"> { (this.props.company.createdOn||'')!==''?moment(this.props.company.createdOn).format('YYYY-MM-DD HH:mm:ss'):''}</strong></p>
                    </div>
                    <div className="col-md-6">
                        <p className="font-14 mb-5 text-right pr-5">Created By: <strong className="weight-600"> { this.props.company.createdBy||''}</strong></p>
                    </div>
                </div>
                <div className="row pb-1">
                    <div className="col-md-12">
                        <div className="pull-right">
                            <div className="btn-list">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12 md-10">
                        <TmReactTable
                            filterable
                            sortable
                            resizable
                            defaultPageSize={10}
                            data={companyHistories}
                            columns={historyCols}
                        />
                    </div>
                </div>
            </div>

        </Fragment>)
    }
}
export default CompanyHistory