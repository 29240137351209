import moment from 'moment';
class UtilMethods{
    setCookie=(cName, cValue, minutes)=> {
        var d = new Date();
        d.setTime(d.getTime() + (minutes*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires;
    }
    deleteCookie=(cname)=> {
        var d = new Date(); 
        d.setTime(d.getTime() - (1000*60*60*24)); 
        var expires = "expires=" + d.toGMTString(); 
        document.cookie = cname+"="+"; "+expires;     
    }
    enumerateDaysBetweenDates = (startDate, endDate)=> {
        let now = endDate;let dates = [];        
       while (now.isSameOrAfter(startDate)) {
              dates.push(now.format('YYYY/MM/DD'));
              now.subtract(1, 'days');
          }
        return dates;
    };
    getYearByMonthsFromNow=(limit=5)=>{
        let yearMonthsList=[]; 
        let groupYears=[];
        let now=moment().startOf('month');
        let endDate=moment().startOf('year');
        endDate.subtract(limit,"year");        
         while (endDate.isSameOrBefore(now)) {
            groupYears.push({year:now.year(),month:now.format('MMM'),monthNo:now.format('M')});
            now.subtract(1, 'Month');
        }
        yearMonthsList = groupYears.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null)); 
        return yearMonthsList;
    }
    sortInvoiceArray=(invArray)=>{
        let sortedArray=[];
        let _invalidArray=[]
        invArray.map(item=>{
            if(parseInt(item.replace('-','')))
            sortedArray.push({intVal:parseInt(item.replace('-','')),invNo:item});
            else{
                    _invalidArray.push({intVal:'',invNo:item});
            }
        });
        return sortedArray.sort((a,b)=>{
           return a.intVal-b.intVal
        }).concat(_invalidArray.sort((x,y)=>{return x.invNo-y.invNo}));
    }
    isNumber=(input)=>{
        if(String(input).match(/^-{0,1}\d+$/)){
            return true
          }else if(String(input).match(/^\d+\.\d+$/)){
            return true
          }else{
            return false
          }
    }
     isNumeric=(evt)=>{
        var iKeyCode = (evt.which) ? evt.which : evt.keyCode
        if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57))
            evt.preventDefault();
		else{
			if(iKeyCode===46){
				var existingVal=evt.target.value;
				if((existingVal+'').indexOf(".")!==-1){
					evt.preventDefault();				
				}				
			}
		}
    }
    setDefaultValue=(value,defaultVal)=>{
        if(value===undefined || value===null || value.trim()===''){
            return defaultVal;
        }else{
            return value
        }
    }
    versionFormat=(evt)=>{
        var iKeyCode = (evt.which) ? evt.which : evt.keyCode
        if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57))
            evt.preventDefault();
		else{
			if(iKeyCode===46){
                var existingVal=evt.target.value;
                var prevChar=existingVal[existingVal.length-1];
                if(existingVal.length===0){
                    evt.preventDefault();
                }
                if(prevChar==='.'){
					evt.preventDefault();				
				}				
			}
		}
    }
    compareVersions=(a,b)=>{
        let i, diff;
        let regExStrip0 = /(\.0+)+$/;
        let segmentsA = a.replace(regExStrip0, '').split('.');
        let segmentsB = b.replace(regExStrip0, '').split('.');
        let l = Math.min(segmentsA.length, segmentsB.length);
    
        for (i = 0; i < l; i++) {
            diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
            if (diff) {
                return diff;
            }
        }
        return segmentsA.length - segmentsB.length;
    }
    groupBy=(list, props)=> {
        return (list||[]).reduce((a, b) => {
           (a[b[props]] = a[b[props]] || []).push(b);
           return a;
        }, {});
      }
      
    convertToFloat=(value,decimalLength=2)=>{
      return parseFloat((isNaN(parseFloat(value))?0.00:parseFloat(value)).toFixed(decimalLength));
      
    }
    allowNumberWithDecimalNegative(event){
        let input = event.target;
            let ponto = input.value.split('.').length;
            let slash = input.value.split('-').length;
            if (ponto > 2)
                    input.value=input.value.substr(0,(input.value.length)-1);
      
            if(slash > 2){            
                input.value=input.value.substr(0,(input.value.length)-1);
            }else{
                if(input.value.indexOf('-')>0){
                    input.value=input.value.substr(0,(input.value.length)-1);
                }
            }
            input.value=input.value.replace(/[^0-9.-]/,'');
            if (ponto ==2){
                input.value=input.value.substr(0,(input.value.indexOf('.')+3));
            }
            if(event.keyCode===8){
                if(input.value.length===(input.value.indexOf('.')+1)){
                    input.value=input.value.substr(0,(input.value.length)-1);
                }
            }        
        if(input.value == '.')
            input.value = "";
      }
}
export const utilMethods=new UtilMethods() 