import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import AllInvoiceCtrl from '../../components/reportsComponents/allInovice/allInvoiceCtrl';
import AllInvoiceTable from '../../components/reportsComponents/allInovice/allInvoiceTable';
import { fetchAllDriverList, fetchAllTruckList, retriveAllInvoiceReport, requestInvoiceReportLink }
    from '../../actions/reportAction/allinvoice/allinvoice';
import { isManagement, getCompanyId ,LAUNCH_URL,ROOT_DISPATCHER} from '../../util/customMethods';
import { initialState } from '../../util/stateUtil/initialStateForComponents';
import { notify } from '../../util/notifications';
import { isValidForm } from '../../util/validationMethods'
const pageTitle = 'All Invoices'
let allInvoiceForm = {};
class AllInvoice extends Component {
    state = {
        ...initialState
    }
    /** Function to set state field for parent component **/
    setStateField = (key, value) => {
        this.setState({ [key]: value });
    }

    // Set state to default state
    setDefaultState = () => {
        let init = initialState;
        Object.keys(init.repFieldObj).map((key) => {
            init.repFieldObj[key].map((val, index) => {
                init.repFieldObj[key][index].status = false;
            })
        })
        this.setState({
            ...init
        });
    }

    // Set state to default state
    setReportOutputState = (e, key, index) => {
        let obj = this.state.repFieldObj;
        obj[key][index].status = e;
        this.setState({ repFieldObj: obj });
        this.forceUpdate();
    }

    allInvoiceGo = async (state) => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.companyId || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retriveAllInvoiceReport(payload, status));
        if (res.statusCode === 200) {
            if (state) {
                this.setState({ render: !this.state.render });
            }
            notify.createNotification('success', res.statusMsg, this.props.refNotify);
            this.setState({ render: false });
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    allInvoiceGoForLink = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.companyId || 0) == 0) {
            status = true;
        }
        const res = await dispatch(requestInvoiceReportLink(payload, status))
        if (res.statusCode === 202) {
            notify.createNotification('success', res.statusMsg, this.props.refNotify);
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    validateAllInvoiceGoRequest = async () => {
        const { dispatch } = this.props;
        let startAt = moment(this.state.fromDate);
        let endAt = moment(this.state.toDate);
        let duration = moment.duration(endAt - startAt, 'milliseconds')
        let days = duration.asDays();
        if (days <= 30) {
            this.allInvoiceGo(false);
        }
        else if (days > 30 && days <= 90) {
            notify.createNotification('info', 'Date range is too large to view in page and will be downloaded in a spreadsheet.', this.props.refNotify);
            this.allInvoiceGo(true);
        } else {
            // notify.createNotification('info', 'Date range is too large to be view in page and will be delivered to email when ready', this.props.refNotify);
            this.allInvoiceGoForLink();
        }
    }
    validateAllInvoiceExportRequest = async () => {
        const { dispatch } = this.props;
        let startAt = moment(this.state.fromDate);
        let endAt = moment(this.state.toDate);
        let duration = moment.duration(endAt - startAt, 'milliseconds')
        let days = duration.asDays();
        if (days <= 90) {
           this.allInvoiceGo(true);
        } else {
             this.allInvoiceGoForLink();
        }
    }
    /* Building all invoice object for post request */
    buildObject = () => {
        let cols = [];
        const obj = this.state;
        let fieldsList = "sc.serviceCallId";
        let objMap = this.state.repFieldObj;
        Object.keys(objMap).map((key) => {
            objMap[key].map((val) => {
                if (val.status) {
                    if(val.title=="Invoice")
                    { cols.push({
                            Header: val.title,
                            accessor: val.fieldName,
                            style: { textAlign: 'center' },
                            Cell: props => <a href="javascript:void(0)" onDoubleClick={() => {
                                window.open(LAUNCH_URL + '/' + ROOT_DISPATCHER + '/serviceCallInfo/' + props.original.invoice, '_blank');
                            }}>{props.original.displayInvoiceId}</a>
                        });
                    }else{
                        cols.push({
                            Header: val.title,
                            accessor: val.fieldName,
                            style: { textAlign: 'center' }
                        });
                    }
                    if ((val.value || '') != '') {
                        fieldsList += "," + val.value
                    }
                }
            })
        })
        if(cols.length<=0){
            notify.createNotification('warning', 'Please specify report output field.', this.props.refNotify);
            return undefined;
        }
        /** setting up the selected field  */
        this.setState({ selectedCols: cols })
        return JSON.stringify({
            fromDate: moment(obj.fromDate).format('MM/DD/YYYY'),
            toDate: moment(obj.toDate).format('MM/DD/YYYY'),
            byServiceCallDate: obj.byServiceCallDate,
            byReleaseDate: obj.byReleaseDate,
            allowCancell: obj.allowCancell,
            allowInprogress: obj.allowInprogress,
            byAccount: (obj.accountId || 0) == 0 ? false : true,
            byTowType: (obj.selectedTowType || '') == '' ? false : true,
            byReason: (obj.selectedReason || '') == '' ? false : true,
            byDriver: (obj.driverId || 0) == 0 ? false : true,
            byTruck: (obj.selectedTruck || '') == '' ? false : true,
            accountId: this.state.accountId,
            selectedReason: this.state.selectedReason,
            driverId: this.state.driverId,
            selectedTowType: this.state.selectedTowType,
            selectedTruck: this.state.selectedTruck,
            selectedField: this.state.selectedField,
            fieldsList: fieldsList,
            companyId: isManagement() ? (this.state.companyId || getCompanyId()) : getCompanyId()
        });
    }

    componentWillMount = () => {
        document.title = pageTitle;
        this.props.dispatch(fetchAllDriverList());
        this.props.dispatch(fetchAllTruckList());
    }

    render() {
        const obj = this.state;
        return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> All Invoices</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <AllInvoiceCtrl myObject={this.state.repFieldObj} parantObj={obj}
                        setStateField={(key, value) => this.setStateField(key, value)}
                        initCallBack={(frm) => { allInvoiceForm = frm; }}
                        accountTypes={this.props.accountTypes}
                        accountList={this.props.accountList}
                        childCompList={this.props.childCompList}
                        callReasons={this.props.callReasons}
                        towTypes={this.props.towTypes}
                        allInvoiceObj={this.props.allInvoiceObj}
                        setDefaultState={this.setDefaultState}
                        allInvoiceGo={() => {
                            allInvoiceForm.validateAll();
                            if (isValidForm(allInvoiceForm)) {
                                this.validateAllInvoiceGoRequest();
                            }
                        }}
                        allInvoiceExport={() => {
                            allInvoiceForm.validateAll();
                            if (isValidForm(allInvoiceForm)) {
                                this.validateAllInvoiceExportRequest();
                            }
                        }}
                        allInvoiceEmail={() => {
                            allInvoiceForm.validateAll();
                            if (isValidForm(allInvoiceForm)) {
                                this.allInvoiceGoForLink();
                            }
                        }}
                        setReportOutputState={(e, key, index) => {
                            this.setReportOutputState(e, key, index)
                        }}
                        reportData={this.props.allInvoiceObj.reportData || []}
                    />
                    <AllInvoiceTable
                        reportData={this.props.allInvoiceObj.reportData || []}
                        showPagination={true}
                        selectedCols={this.state.selectedCols}
                        minRows={3}
                        className='-striped -highlight'
                        noDataText='No data found'
                    />
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        refNotify: state.refNotify,
        accountTypes: state.accountTypes.accountTypes,
        accountList: state.invoiceInfo.accountList,
        childCompList: state.searchInvoiceList.childComList,
        callReasons: state.callReasons.callReasons,
        towTypes: state.towTypeList.towTypes,
        allInvoiceObj: state.allInvoice
    }
};
export default withRouter(connect(mapStateToProps)(AllInvoice));
