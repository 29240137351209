//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
import React, { Component }  from 'react';
import { Link } from 'react-router-dom';
export  const tableColumns = [
    {
        accessor: 'companyName',
        Header: 'Company Name',
        Cell: props => <span className='number'>Company Name:<Link to={'/admin/companyRecord?cid=' + props.original.companyId} target="_blank">{' '+props.original.companyName}</Link></span>
    },
    {
        accessor: 'companyType',
        Header: 'Company Type',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'asOfDate',
        Header: 'As Of Date',
        style: { textAlign: 'center' }
    }
];


