import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {isValidForm} from '../../../util/validationMethods';
import {utilMethods}  from '../../../util/util';
import { getCompanyId, isManagement } from '../../../util/customMethods';
import BatchPaymentsCtrl from '../../../components/reportsComponents/accounting/batchPaymentsCtrl';
import BatchPaymentsTable from '../../../components/reportsComponents/accounting/batchPaymentsTable';
import {fetchReportAccountList, fetchReportAccountListClear} from '../../../actions/reportAction/accounting/reportAccountListAction';
import {getBatchPaymentList, getBatchPaymentClearList, postMultipleInvoicePayments} from '../../../actions/reportAction/accounting/batchPaymentAction';
const pageTitle = 'Batch Payment Report';
let amtToPay = 0.00;
let salvageInvoiceIds = [];
let BatchPaymentsForm={
    formBatchPaymentsInfo:{},
    formBatchPaymentsAcceptInfo:{}
}
class BatchPayments extends Component {
	constructor() {
		super();
		this.state = {
            asOfDate:'',
            location: '',
            paymentType: '',
            checkNo: '',
            accountId:'',
            accountName:'',
            idCompany:'',
            render: false,
            dataFetched:false,
            amount: 0.00,
            totalAmt: 0.00,
            batchData: [],
            noOfOpenInv: 0,
            amtToPay: 0.00,
            amtToProcess: 0.00,
            dataFetched: false
		}
	}
	componentWillMount = () => {
        document.title = pageTitle;
        this.props.dispatch(getBatchPaymentClearList());
        if(!isManagement()){
        this.getAccountList(getCompanyId());
        }	
    }
    getAccountList=(_idCompany)=>{
        this.props.dispatch(fetchReportAccountList(_idCompany));
    }
    onBatchPaymentsFieldChange=(key, value, accName)=>{
        this.setState({[key]:value});
        if(key === 'idCompany'){
            if(value !== ''){
                this.getAccountList(value);
            }else{
                this.props.dispatch(fetchReportAccountListClear());
            }  
        }
        if((key === 'accountId') && ((accName || '') !== '')){
            this.setState({accountName:accName})
        }
    }
    getByCompanyId=()=>{
        let id_company=0;
        if(!isManagement()){
            id_company=getCompanyId();
        }else{
            id_company=this.state.idCompany;
        }
        return id_company;
    }
    /** Function to build multipal invoice payment report data for updation */

    mapTableData = () => {
        let paymentLoc = this.state.location;
        let paymentType = this.state.paymentType;
        let accountName = this.state.accountName;
        let chequeNo = this.state.checkNo;
        let updateInvoicePaymentsList = {
            companyId: this.getByCompanyId(), invoiceId: [], paymentType: []
            , paymentAmt: [], chequeNo: [], accountName: [], ccTxNo: [], userId: [], paymentLoc: [], lockInvoices: []
            , discountBalance: [], discountAmount: [], asOfDate: [], salvageInvoiceId: [], balanceAmtByInvoice: {}
        };
        this.state.batchData.map((value) => {
            let updateInvoicePayments = {
                invoiceId: value.invoiceId,
                paymentType: paymentType,
                paymentAmt: value.paymentAmt,
                chequeNo: chequeNo,
                accountName: accountName,
                ccTxNo: ' ',
                userId: '',
                paymentLoc: paymentLoc,
                lockInvoices: value.lockInvoices,
                discountBalance:0,// value.discountBalance,
                balanceAmt: value.balanceAmt,
                discountAmount:0.00, //value.discountBalance === 1 ? (value.balanceAmt - value.paymentAmt) : value.discountAmount,
                asOfDate: this.state.asOfDate,
                salvageInvoiceId: salvageInvoiceIds.find(function (element) {
                    return element === value.salvageInvoiceId
                })
            };
            if (updateInvoicePayments.paymentAmt.trim() != "") {
                updateInvoicePaymentsList.invoiceId.push(updateInvoicePayments.invoiceId);
                updateInvoicePaymentsList.paymentType.push(updateInvoicePayments.paymentType);
                updateInvoicePaymentsList.paymentAmt.push(updateInvoicePayments.paymentAmt);
                updateInvoicePaymentsList.chequeNo.push(updateInvoicePayments.chequeNo);
                updateInvoicePaymentsList.accountName.push(updateInvoicePayments.accountName);
                updateInvoicePaymentsList.lockInvoices.push(updateInvoicePayments.lockInvoices);
                updateInvoicePaymentsList.discountBalance.push(updateInvoicePayments.discountBalance);
                updateInvoicePaymentsList.discountAmount.push(updateInvoicePayments.discountAmount);
                updateInvoicePaymentsList.asOfDate.push(updateInvoicePayments.asOfDate);
                updateInvoicePaymentsList.salvageInvoiceId.push(updateInvoicePayments.salvageInvoiceId);
                updateInvoicePaymentsList.ccTxNo.push(updateInvoicePayments.ccTxNo);
                updateInvoicePaymentsList.userId.push(updateInvoicePayments.userId);
                updateInvoicePaymentsList.paymentLoc.push(updateInvoicePayments.paymentLoc);
                updateInvoicePaymentsList.balanceAmtByInvoice[updateInvoicePayments.invoiceId] = updateInvoicePayments.balanceAmt;
            }
        });
        return updateInvoicePaymentsList;
    }
    paymentSubmit=(e)=>{
        e.preventDefault();
        BatchPaymentsForm.formBatchPaymentsAcceptInfo.validateAll();
        if(isValidForm(BatchPaymentsForm.formBatchPaymentsAcceptInfo)){
            let msg =  parseFloat(this.state.amtToProcess) === 0 ? "Please fill the Amount" : parseFloat(this.state.amtToProcess) !== this.state.amtToPay ? "Amount should be the total of payment amount." : "true";
        if (msg !== "true") {
            alert(msg)
        } else {
            let paymentData = this.mapTableData();
            this.props.dispatch(postMultipleInvoicePayments(JSON.stringify(paymentData)))
        }
         
        }
    }
    getBatchPaymentDetails=(e)=>{
        e.preventDefault();
        BatchPaymentsForm.formBatchPaymentsInfo.validateAll();
        if(isValidForm(BatchPaymentsForm.formBatchPaymentsInfo)){
            this.props.dispatch(getBatchPaymentList(this.state.accountId, this.getByCompanyId()))
            .then(() => {
                this.setState({
                    batchData: this.props.bpReportResponse.multipleInvoicePaymentDetailsList.map((item) => {
                        item.discountBalance = 0;
                        item.lockInvoices = 0;
                        if ((item.salvageInvoiceId || '').trim() !== '') {
                            salvageInvoiceIds.push((item.salvageInvoiceId || '').trim());
                        }
                        return item;
                    }),
                    noOfOpenInv: this.props.bpReportResponse.totalOpenInvoices,
                    totalAmt: utilMethods.convertToFloat(this.props.bpReportResponse.totalAmount) ,
                    dataFetched: true,
                    amtToProcess: 0.00,
                    amtToPay: 0.00,
                    location: '',
                    paymentType: ''
    
                })
                //this.setState({ dataFetched: true })
            })   
            .catch(err => {
                console.error('fetchReport methods failed with error: ', err);
            });
        }
    }
    
    /** Function to maniupulate multipal invoice payment report data */
    handleInputChange = (cellInfo, event) => {
        let data = [...this.state.batchData];
        data[cellInfo.index]["paymentAmt"] = event.currentTarget.checked === true ? data[cellInfo.index]["balanceAmt"] : "";
        data[cellInfo.index]["paidInFull"] = event.currentTarget.checked === true ? true : false;
        data[cellInfo.index]["lockInvoices"] =event.currentTarget.checked === true ? 1 : 0;
        data[cellInfo.index]["discountBalance"] =0
        data[cellInfo.index]["discountAmount"]=0.00
        // amtToPay = amtToPay + parseFloat(event.currentTarget.checked === true ? data[cellInfo.index]["balanceAmt"] : -data[cellInfo.index]["balanceAmt"]);
        let amount = 0.00
        data.map((item) => {
            amount = amount + utilMethods.convertToFloat(item.paymentAmt)
        })
        this.setState({ batchData: data, amtToPay: utilMethods.convertToFloat(amount) });
    };
    /** function to updated invoice payment amount while manually entering  */
    handleInputPaymentChange = (cellInfo, event) => {
        let data = [...this.state.batchData];
        data[cellInfo.index]["paymentAmt"] = event.currentTarget.value;
        data[cellInfo.index]["lockInvoices"] =0
        data[cellInfo.index]["discountBalance"] =0
        data[cellInfo.index]["discountAmount"]=0.00
        let amount = 0.00
        data.map((item) => {
            amount = amount + utilMethods.convertToFloat((item.paymentAmt || '') === '' ? 0 : item.paymentAmt)
        })
        this.setState({ batchData: data, amtToPay: utilMethods.convertToFloat(amount) });
    };
    /** Function to maniupulate multipal invoice payment report data */
    handle = (e) => {
        let status = e.currentTarget.checked;
        this.state.batchData.map((key, index) => {
            this.handleInputAllChange(status, index);
        })
    }
    /** Function to maniupulate multipal invoice payment report data */
    handleInputAllChange = (status, index) => {
        let data = [...this.state.batchData];
        data[index]["paymentAmt"] = status === true ? data[index]["balanceAmt"] : "";
        data[index]["paidInFull"] = status === true ? true : false;
        data[index]["lockInvoices"] = status === true ? 1 : 0;
        data[index]["discountBalance"] =  0;
        data[index]["discountAmount"] =  0.00;
        amtToPay = utilMethods.convertToFloat(status === true ? this.state.totalAmt : 0.00);
        this.setState({ batchData: data, amtToPay: utilMethods.convertToFloat(amtToPay) });
    };
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Batch Payment Report</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <BatchPaymentsCtrl onBatchPaymentsFieldChange={(key, value)=>this.onBatchPaymentsFieldChange(key, value)}
                    initCallBack={(frm)=>{
						BatchPaymentsForm.formBatchPaymentsInfo=frm;
                    }}
                    initCallBackAccept={(frm)=>{
						BatchPaymentsForm.formBatchPaymentsAcceptInfo=frm;
                    }} asofdate={this.state.asOfDate}
                    idCompany={this.state.idCompany}
                    childCompList={this.props.childCompList}
                    accList={this.props.accountList}
                    accountId={this.state.accountId}
                    getBatchPaymentDetails={(e)=>this.getBatchPaymentDetails(e)}
                    dataFetched={this.state.dataFetched}
                    reviewLocation={this.props.reviewLocation}
                    checkno={this.state.checkNo} paymenttype={this.state.paymentType}
                    location={this.state.location} amttopay={this.state.amtToPay}
                    amttoprocess={this.state.amtToProcess} paymentSubmit={(e)=>this.paymentSubmit(e)}/>
                    {this.state.dataFetched && 
                        <Fragment>
                            <div className='form-inline'>
                                <div className='form-group mx-sm-3 mb-2'>

                                    <input type='checkbox' className="form-control" placeholder="Total Amount"
                                        onChange={this.handle} />
                                </div>
                                <div>Select All Total Amount : {this.state.totalAmt}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div>Total Open Invoices : {this.state.noOfOpenInv}</div>
                            </div>
                            <BatchPaymentsTable multipleInvoicePayments={this.state.batchData}
                            handleInputChange={(cellInfo, event)=>this.handleInputChange(cellInfo, event)} 
                            handleInputPaymentChange={(cellInfo, event)=>this.handleInputPaymentChange(cellInfo, event)}/>
                        </Fragment>
                        
                    }
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		accountList:state.reportsAccountList.reportAccountList,
        childCompList:state.searchInvoiceList.childComList,
        bpReportResponse: state.bpDetailsList.bpDetails,
        reviewLocation: state.location.locationList
	}
};

export default withRouter(connect(mapStateToProps)(BatchPayments));
