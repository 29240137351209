import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import moment from 'moment';
import {required,email, isValidForm,
    phone,
    fax,
    isLenghtOf,
    isNumeric,
    zipValidation,
    isCurrency,
    toInt,subdomainValidation,isValidDomain,isValidEmail,validateUser} from '../../../../util/validationMethods';
    import {GlobalValues} from '../../../../util/constants'
    import { notify } from '../../../../util/notifications';
import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import CompanyInfoContactList from './companyInfoContactList';
import CompanyNotes from './companyNotes';
class CompanyInfo extends Component {

    onFieldChange=(e)=>{
        this.props.onCompanyBasicInfoFieldsChange(e.target.name,e.target.value);
    }
    onUserInfoFieldChange=(e)=>{
        let _emp=this.props.company.employee||{}
        _emp[e.target.name]=e.target.value
        this.props.onCompanyBasicInfoFieldsChange('employee',_emp);
    }
    onUserAuthFieldChange=(e)=>{
        let _emp=this.props.company.employee||{}
        let _userAuth=_emp.userAuth||{};
        _userAuth[e.target.name]=e.target.value
        _emp.userAuth=_userAuth
        this.props.onCompanyBasicInfoFieldsChange('employee',_emp);
    }
    render() {
        const {company, companyType, cid}=this.props
        const employee=company.employee||{};
        const userAuth=employee.userAuth||{};
        return (<Fragment>
            <div className="pd-10">
                <Form ref={(c)=> { 
                    this.formCompanyInfo = c;
                    this.props.initCallBack(c);
                     }}>
                    <fieldset disabled={!this.props.canEdit}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 mb-10">
                                <div className="row">
                                    <div className="col">
                                    <div className="">
                                            <label htmlFor="companyType" className="form-label col-form-label">COMPANY TYPE*</label>
                                            <div className="">
                                            <Select className="form-control form-control-sm" name="companyType" value={company.companyType||''}
                                                    onChange={this.onFieldChange} requiredmsg="Please select company type" validations={[required]}>
                                                    <option value={""}>{"Select Company Type"}</option>
                                                    {(companyType.length>0) && companyType.map((value, key) => {
                                                        return (
                                                            <option key={key} value={value}>
                                                                {value}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    <div className="">
                                            <label htmlFor="code" className="form-label col-form-label">COMPANY CODE*</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" 
                                                name="code" value={company.code||''} autocomplete={false}
                                                validations={[required]} 
                                                requiredmsg={'Please Enter Company Code'} onChange={this.onFieldChange} />
                                            </div>
                                        </div>    
                                        <div className="">
                                            <label htmlFor="subdomain" className="form-label col-form-label">SUB DOMAIN NAME *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="subdomain" autocomplete={false} 
                                                value={company.subdomain||''} onChange={e=>{this.onFieldChange(e);this.props.validateDomain(e.target.value)}} isValid={this.props.isDomainValid} validations={[required,subdomainValidation,isValidDomain]} 
                                                requiredmsg={'Please Enter Sub Domain'} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="companyName" className="form-label col-form-label">COMPANY NAME*</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" 
                                                name="name" value={company.name||''} autocomplete={false}
                                                validations={[required]} 
                                                requiredmsg={'Please Enter Company Name'} onChange={this.onFieldChange} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="address1" className="form-label col-form-label">ADDRESS LINE 1 *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" autocomplete={false} 
                                                name="addressLine1" 
                                                value={company.addressLine1||''} validations={[required]} 
                                                requiredmsg={'Please Enter Address Line 1'} onChange={this.onFieldChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="address2" className="form-label col-form-label">ADDRESS LINE 2</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" autocomplete={false}
                                                name="addressLine2" value={company.addressLine2||''} onChange={this.onFieldChange} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="city" className="form-label col-form-label">CITY *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="city"  
                                                value={company.city||''} validations={[required]} autocomplete={false}
                                                requiredmsg={'Please Enter City'} onChange={this.onFieldChange}/>
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="state" className="form-label col-form-label">STATE *</label>
                                            <div className="">
                                            <Select
                                                id="add-info-name"
                                                name="state"
                                                value={company.state||''}
                                                validations={[required]}
                                                className="form-control form-control-sm"
                                                requiredmsg={'Please Select State'} onChange={this.onFieldChange}
                                                >
                                                <option value="" />
                                                {this.props.states.map((loc, k) => {
                                                    return (
                                                    <option key={k} value={loc.optionVal}>
                                                        {loc.optionTxt}
                                                    </option>
                                                    );
                                                })}
                                                </Select>                                        
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="zip" className="form-label col-form-label">ZIP *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="zip" 
                                                value={company.zip||''} validations={[required,zipValidation]} autocomplete={false}
                                                requiredmsg={'Please Enter Zip Code'} onChange={this.onFieldChange} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="phone" className="form-label col-form-label">PHONE *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="contactNo" 
                                                value={company.contactNo||''} validations={[required, phone]} autocomplete={false}
                                                requiredmsg={'Please Enter Phone No'} onChange={this.onFieldChange}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="reviewDate" className="form-label col-form-label">REVIEW DATE *</label>
                                            <div className="">
                                            <TmDateTimePicker
                                                className="form-control"
                                                selectedDate={
                                                    company.reviewDate === "" || company.reviewDate === null
                                                        ? null
                                                        : moment(
                                                            company.reviewDate
                                                        )._d
                                                }
                                                dateFormat={GlobalValues.dateFormat}
                                                validationArray={[required]}
                                                showYearDropdown={true}
                                                showmonthdropdown={true}
                                                name="reviewDate"
                                                reqmsg="Review Date required"
                                                onDateSelectChange={e => {
                                                    this.props.onCompanyBasicInfoFieldsChange('reviewDate', e === null ? '' : moment(e).valueOf())
                                                    this.forceUpdate()
                                                }} 
                                            /> 
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="fax" className="form-label col-form-label">Fax</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="fax" autocomplete={false}
                                                 value={company.fax||''} validations={[fax]} onChange={this.onFieldChange} />
                                            </div>
                                        </div>
                                        <div className="mb-20">
                                            <label htmlFor="contactEmail" className="form-label col-form-label">CONTACT EMAIL *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" autocomplete={false}
                                                name="contactEmail" value={company.contactEmail||''} validations={[required,email]} 
                                                requiredmsg={'Please Enter Email Id'} onChange={this.onFieldChange} />
                                            </div>
                                        </div>

                                        {/* <div className="">
                                            <label htmlFor="accountAssigner" className="form-label col-form-label">ACCOUNT ASSIGNER</label>
                                            <div className="">
                                                <ReactSelect 
                                                closeMenuOnSelect={false} 
                                                isMulti
                                                options={this.props.accounts.map((acc, t) => {
                                                    return ({ value: acc.idAccount, label: acc.accountName })
                                                })} 
                                                value={(company.companyAccountMappings || []).map((item) => {
                                                    return ({ value: item.idAccount, label: item.accountName })
                                                })}
                                                name="accountAssigner" onChange={this.onAccountAssignChange}>
                                                </ReactSelect>
                                            </div>
                                        </div> */}
                                        
                                        <div className="">
                                            <button type="button" disabled={cid==0} className={`btn btn-sm ${company.isActive?'btn-danger':'btn-success'}`}
                                            onClick={(e)=>{
                                                this.props.onCompanyBasicInfoFieldsChange('isActive', !(company.isActive));
                                                this.forceUpdate()
                                                if(company.isActive){
                                                    notify.createNotification('success', 'Company activated successfully', this.props.refNotify);
                                                }else{
                                                    notify.createNotification('success', 'Company deactivated successfully', this.props.refNotify);
                                                }
                                            }} >{`${company.isActive?'Deactivate Company':'Activate Company'}`}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-15">
                                        <h4 className="text-blue">User Info</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                            <div className="">
                                                <label htmlFor="firstName" className="form-label col-form-label">USER FIRST NAME *</label>
                                                <div className="">
                                                    <Input type="text" className="form-control form-control-sm" autocomplete={false}
                                                    name="firstName" value={employee.firstName||''} validations={[required]} 
                                                requiredmsg={'Please Enter User First Name'} onChange={this.onUserInfoFieldChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="">
                                                <label htmlFor="lastName" className="form-label col-form-label">USER LAST NAME *</label>
                                                <div className="">
                                                    <Input type="text" className="form-control form-control-sm" autocomplete={false}
                                                    name="lastName" value={employee.lastName||''} validations={[required]} 
                                                requiredmsg={'Please Enter User Last Name'} onChange={this.onUserInfoFieldChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="">
                                                <label htmlFor="emailId" className="form-label col-form-label">USER EMAIL *</label>
                                                <div className="">
                                                    <Input type="text" className="form-control form-control-sm" name="emailId" autocomplete={false}
                                                    value={employee.emailId||''} onChange={this.onFieldChange} isValid={this.props.isEmailExits} validations={[required,email,isValidEmail]} 
                                                requiredmsg={'Please Enter User Email Id'} onChange={(e)=>{this.onUserInfoFieldChange(e);this.props.emailExits(e.target.value)}} />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                                <div className="row">
                                <div className={`${(cid <= 0) ? 'col-md-4' : 'col'}`}>
                                        <div className="">
                                            <label htmlFor="emer_Contact_no" className="form-label col-form-label">USER CONTACT NO *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="emer_Contact_no" autocomplete={false}
                                                value={employee.emer_Contact_no||''} validations={[required,phone]} 
                                                requiredmsg={'Please Enter User Contact No'} onChange={this.onUserInfoFieldChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${(cid <= 0) ? 'col-md-4' : 'col'}`}>
                                        <div className="">
                                            <label htmlFor="userName" className="form-label col-form-label">USER ID *</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" autocomplete={false}
                                                name="userId" value={userAuth.userId||''} onChange={(e)=>{this.onUserAuthFieldChange(e);this.props.validateUser(e.target.value)}} isValid={this.props.isUserExits} 
                                                validations={(company.idCompany||0)==0?[required]:[required,validateUser]} 
                                                requiredmsg={'Please Enter User Id For Login'} />
                                            </div>
                                        </div>
                                    </div>
                                    {(cid <= 0) ? null : <div className="col">
                                        <div className="">
                                            <label htmlFor="tempPassword" className="form-label col-form-label">TEMPORARY PASSWORD</label>
                                            <div className="">
                                                <Input type="password" className="form-control form-control-sm" autocomplete={false}
                                                name="tempPassword" value={userAuth.tempPassword||''} onChange={(e)=>this.onUserAuthFieldChange(e)}
                                                 />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </Form>
                <CompanyInfoContactList canEdit={this.props.canEdit} data={company.companyContactInfos||[]} 
                isPhoneRequired={true} 
                isEmailRequired={true}
                onContactDelete={(index) => {
                        this.props.onCompanyContactDelete(index)
                    }}
                    onContactSubmit={(e,contactObj,index)=>{
                        return this.props.onCompanyContactSubmit(e,contactObj,index)
                    }}/>
                <CompanyNotes data={company.companyNotes||[]} 
                canEdit={this.props.canEdit}
                onNotesSubmit={(e, notesObj, index) => {
                        this.props.onCompanyNotesSubmit(e, notesObj, index);
                    }}
                    onNotesDelete={(index) => {
                        this.props.onCompanyNotesDelete(index)
                    }}                     
                     />
                <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <div className="mr-3">
                        <button className="btn btn-success btn-sm pull-right" onClick={e => {
                            e.preventDefault();
                            this.formCompanyInfo.validateAll();
                            if (!isValidForm(this.formCompanyInfo)) {
                            } else {
                                this.props.onSubmitBasicInfo(e);
                            }
                        }}>NEXT</button>
                    </div>
                    {(cid <= 0) ? null : <div className="mr-4">
                        <button
                         disabled={!this.props.canEdit}
                            className="btn btn-sm btn-success pull-right"
                            style={{ marginTop: '0px' }} onClick={(e) => { 
                                e.preventDefault();
                                this.formCompanyInfo.validateAll();
                                if (!isValidForm(this.formCompanyInfo)) {
                                } else {       
                                    this.props.onSaveCompany(); 
                                }                             
                            }}
                        >SAVE COMPANY</button>
                    </div>}
                </div>
            </div>
        </Fragment>);
    }

}
export default CompanyInfo
