import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import { appId } from '../../util/constants';
import moment from 'moment';

export const getTruckList=(filter)=>{
   const _url=`truck/searchTruckDetails?searchQuery=${filter.searchQuery}&accountStatus=${filter.accountStatus}&truckIdVal=0&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_ALL_TRUCKS,
                    truckList:response.data||[]
                });
            }else{    
                dispatch({
                    type: actionTypes.GET_ALL_TRUCKS,
                    truckList:[]
                });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ALL_TRUCKS,
                truckList:[]
            });
        }
    }
}
export const getTruck=(id)=>{
    const _url= `truck/truckInfo?searchQuery=&companyId=${getCompanyId()}&truckIdVal=${id}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
       if(id===-1){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
            type: actionTypes.GET_TRUCK_RECORD,
            truckObject:undefined
        }); 
        dispatch({
            type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
            serviceList:undefined
        });
        dispatch({
            type: actionTypes.GET_ALL_TRUCK_NOTES,
            truckNotesList:undefined
        });       
       }
       else{
           try{
                const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
                if(response.status===200) {         
                    dispatch({
                        type: actionTypes.GET_TRUCK_RECORD,
                        truckObject:response.data.truckDetails
                    });
                    dispatch({
                        type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
                        serviceList:response.data.serviceDetails.serviceDetails
                    });
                    dispatch({
                        type: actionTypes.GET_ALL_TRUCK_NOTES,
                        truckNotesList:response.data.truckNotes.truckNotes
                    });
                } else{    
                        dispatch({
                            type: actionTypes.GET_TRUCK_RECORD,
                            truckObject:undefined
                        });
                        dispatch({
                            type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
                            serviceList:undefined
                        });
                        dispatch({
                            type: actionTypes.GET_ALL_TRUCK_NOTES,
                            truckNotesList:undefined
                        });
                    }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_TRUCK_RECORD,
                truckObject:undefined
            });
            dispatch({
                type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
                serviceList:undefined
            });
            dispatch({
                type: actionTypes.GET_ALL_TRUCK_NOTES,
                truckNotesList:undefined
            });
       }       
    }
    }
}
export const getServiceDetails=(id)=>{
    const _url= `truck/select/serviceDetails?truckIdVal=${id}&access_token=${getAccessToken()}`;
    return async(dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
       const response= await getAxiosInstance(moduleTypes.ADMIN).get(_url)
           if(response.status===200) {         
               dispatch({
                   type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
                   serviceList:response.data.serviceDetails
               });
           } else{
                dispatch({
                    type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
                    serviceList:undefined
                });
           }
           //dispatch(spinnerActions.hideSpinner());
       }
       catch(error) {
          // dispatch(spinnerActions.hideSpinner());
         dispatch({
            type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
            serviceList:undefined
        });
       }
        //dispatch(spinnerActions.hideSpinner());
    }
}

export const getTruckNotes=(id)=>{
    const _url= `truck/select/truckNotes?truckIdVal=${id}&access_token=${getAccessToken()}`;
    return async(dispatch) => {
        try{
        //dispatch(spinnerActions.showSpinner());
       const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
           if(response.status===200) {         
               dispatch({
                   type: actionTypes.GET_ALL_TRUCK_NOTES,
                   truckNotesList:response.data.truckNotes
               });
           } else{
                   dispatch({
                       type: actionTypes.GET_ALL_TRUCK_NOTES,
                       truckNotesList:undefined
                   });
               }
           //dispatch(spinnerActions.hideSpinner());
       }
       catch(error){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ALL_TRUCK_NOTES,
                truckNotesList:undefined
            });
       }
    }
}
export const setTruckNotesToList=(notesObj)=>{
    return (dispatch)=>{
        let _notesList=Array.from((store.getState()).truck.truckNotesList);
        _notesList.push(notesObj);
        dispatch({
            type: actionTypes.GET_ALL_TRUCK_NOTES,
            truckNotesList:_notesList
        });
    }
}
export const setTruckServiceToList=(serviceObj)=>{
    return (dispatch)=>{
        let _serviceList=Array.from((store.getState()).truck.serviceList);
        _serviceList.push(serviceObj);
        dispatch({
            type: actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS,
            serviceList:_serviceList
        });
    }
}

export const clearTruck=()=>{
    return (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
       dispatch({
        type: actionTypes.CLEAR_TRUCK_RECORD,
        auctionObject:undefined
    });
    //dispatch(spinnerActions.hideSpinner());
    }
}
export const changeTruckField=(recObject)=>{
    return (dispatch)=>{
         dispatch({
            type: actionTypes.GET_TRUCK_RECORD,
            truckObject:recObject
        }); 
    }
} 
export const saveTruck=(object)=>{
    return async (dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        let _url=`truck/Add/truckInfo?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,object)
            if(response.status===200) {
                return {statusCode:response.status, statusMsg:response.data.message};
            } else{                
                return {statusCode:null, statusMsg:response.data.message};
                //dispatch(getAuctionList());                    
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
          //dispatch(spinnerActions.hideSpinner());
          return {statusCode:null, statusMsg:error.message};
        }
    }
}
export const getVehicleDetails=(object)=>{
    return async(dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        let _url='VINLookupService/getVehicleDetails?companyId='+getCompanyId()+'&access_token=';
        try{
            const response=await getAxiosInstance(moduleTypes.SERVICE).post(_url,object,
                {headers:{appId:appId}})
            if(response.status===200) {
                if(response.data.message === "Valid VIN")
                {
                    let _truckObject=JSON.parse(JSON.stringify((store.getState()).truck.truckObject));                    
                    _truckObject.make=response.data.vehicleDetails.make;
                    _truckObject.model=response.data.vehicleDetails.model;
                    _truckObject.year=response.data.vehicleDetails.modelYear;
                    dispatch({
                        type: actionTypes.GET_TRUCK_RECORD,
                        truckObject: _truckObject
                    })
                    return {statusCode:response.status,statusMsg:response.data.message}
                }else{
                    return {statusCode:null,statusMsg:response.data.message}
                }               
            } else{
                return {statusCode:null,statusMsg:response.data.message}                   
                }
            
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
          //dispatch(spinnerActions.hideSpinner());
          return {statusCode:null,statusMsg:error.message}
        }
    }
}
