import * as actionTypes from './actionTypes';
import {authenticate,getAxiosInstance} from '../util/service';
import {LAUNCH_LOGIN_URL, moduleTypes} from '../util/constants'
import {store} from '../store/configureStore';
import {setDataToLaunchStore} from '../util/customMethods'
import moment from 'moment';

export const authenticateUser = (userName,password,subDomain) => {
  return async (dispatch) => {
    const resp=await authenticate(userName,password,subDomain);
    if(resp.isAuthenticated){             
      const setval = await dispatch({type:actionTypes.SET_AUTH_INFO,payload:resp.loginUserResp});
     // setDataToLaunchStore('authInfo',resp.loginUserResp);
     if(resp.loginUserResp.userDetails.isReset===1){
      dispatch(getAccessLevels(resp.loginUserResp.userDetails.employeeId,
        resp.loginUserResp.userDetails.companyId,
        resp.loginUserResp.oaAuthTokens.access_token));                  
      }
      return resp;
    }else{
      await dispatch({type:actionTypes.SET_AUTH_INFO,payload:undefined});
      return false
    }
  } 
};
export const forgetPassword = (emailId, option) => {
  return async (dispatch) => {
    let _url = `/userAuthService/recoverUserPassword`;
    try {
      const response = await getAxiosInstance(moduleTypes.SERVICE).post(_url, {
        "emailId": emailId,
        "forgetOption": option
      })
      if (response.data.statusCode===200) {
          return response.data;
      } else {
          return response.data;
      }
    }
    catch (error) {
      return {statusCode:null,statusMsg:error.message} 
    };
  }
};
export const resetPassword = (newPassword,forgotToken,empEmail,userId,subDomain) => {
  return async (dispatch) => {
    let _url = `userAuthService/resetPassword`;
    try {
      const response = await getAxiosInstance(moduleTypes.SERVICE).post(_url, {
        "confirmPassword": newPassword,
        "newPassword": newPassword,
        "pwdForgottoken": forgotToken,
        "empEmail":empEmail,
        "userId":userId,
        "subdomain":subDomain
      })
      if (response.data.statusCode===200) {
          return response.data;
      } else {
          return response.data;
      }
    }
    catch (error) {
      return {statusCode:null,statusMsg:error.message} 
    };
  }
};
// export const isValidForgetToken=(forgotToken)=>{
//   const _url= `userAuthService/isActiveForgetPwdToken/${forgotToken}`
//   return async (dispatch) => {
//       try{
//      const resp=await getAxiosInstance(moduleTypes.SERVICE).get(_url,undefined,getAccessToken())      
//       if(resp.data.statusCode===200) {   
//           return resp.data;
//       }else{
//               return resp.data
//           }
//       }
//      catch(ex){ 
//          return {statusCode:null,statusMsg:ex.message}
//      }
//   }
// }
export const getAccessLevels=(empId,companyId,access_token)=>{
  let _url='employee/select/employeeAccessLevel?empId='+empId+'&companyId='+companyId+'&access_token='+access_token;
  return async (dispatch) => {
    try{
      const resp= await getAxiosInstance(moduleTypes.ADMIN).get(`employee/select/employeeAccessLevel?empId=${empId}&companyId=${companyId}&access_token=${getAccessToken()}`); 
      if(resp.status===200){        
          dispatch(
            {
                type: actionTypes.SET_ACCESS_LEVELS,
                payload:resp.data.employeeAccess
            });
            dispatch(
              {
                  type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                  payload:resp.data.dashboardAccess
              });
      }else{
        
        dispatch(
          {
              type: actionTypes.SET_ACCESS_LEVELS,
              payload:undefined
          });
          dispatch(
            {
                type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                payload:undefined
            });
  
      }
    }
    catch(ex){
      
      dispatch(
        {
            type: actionTypes.SET_ACCESS_LEVELS,
            payload:undefined
        });
        dispatch(
          {
              type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
              payload:undefined
          });
    }
}
}

export const logOutUser=()=>{
  return (dispatch) => {
    dispatch({type:actionTypes.SET_AUTH_INFO,payload:undefined})
    window.location.href=LAUNCH_LOGIN_URL;
  }
}
export const getAccessToken=()=>{
  try{
    let _authInfo=(store.getState()).authInfo;
    if(_authInfo.oaAuthTokens){
      return _authInfo.oaAuthTokens.access_token||undefined
    }else{
      return undefined
    }
  }catch(ex){
    return undefined
  }
}
export const setAccessToken=async (resp)=>{
  try{
    let _authInfo=(store.getState()).authInfo;
    let _respObj=_authInfo.oaAuthTokens ||{};
    _respObj.access_token=resp.access_token;
    _respObj.refresh_token=resp.refresh_token;
    _respObj.token_creationTime=resp.expires_in;
    _authInfo.oaAuthTokens=_respObj
    await store.dispatch({type:actionTypes.SET_AUTH_INFO,payload:_authInfo});
    return true;
  }catch(ex){
    return false;
  }
}
export const isAuthenticated=()=>{
  if(getAccessToken()){
    return true;
  }
    return false;
}
export const isManagementCompany=()=>{
  let _authInfo=(store.getState()).authInfo||{};
  return (_authInfo.userDetails||{}).isManagement||false
}