import * as actionTypes from '../../actions/actionTypes';
import { GlobalValues } from '../../util/constants';
import moment from 'moment'
const defaultTruck={
    truckList:[],
    truckObject: {
      "truckId":'',
      "gvwr":"",
      "dpsid":"",
      "truckTypeCd":"",
      "groupNo":"",
      "licenseNo":"",
      "licensePlateNo":"",
      "make":"",
      "model":"",
      "vin":"",
      "year":'',
      "state":"",
      "lastInspectionDate":moment().format(GlobalValues.dateFormat),
      "deviceId":"",
      "initialMileage":'',
      "currentMileage":'',
      "serviceStatus":"In Service",
      "towTypes":""
    },
    serviceList:[],
    truckNotesList:[]
  }
export const truckReducer = (state = defaultTruck, action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_TRUCKS:
        return {...state ,truckList:(action.truckList||defaultTruck.truckList)};
      case actionTypes.GET_TRUCK_RECORD:
        return {...state , truckObject:(action.truckObject|| Object.assign({},defaultTruck.truckObject) ) };
      case actionTypes.CLEAR_TRUCK_RECORD:
        return {...state , truckObject: Object.assign({},defaultTruck.truckObject) };      
      case actionTypes.GET_ALL_TRUCK_SERVICE_DETAILS:
        return {...state ,serviceList:(action.serviceList||defaultTruck.serviceList)};
      case actionTypes.GET_ALL_TRUCK_NOTES:
        return {...state ,truckNotesList:(action.truckNotesList||defaultTruck.truckNotesList)};      
      default:
        return state;
    }
  };