import React, { Component,Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable'
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
import moment from 'moment'

class CustomChargeList extends Component {
    constructor(){
        super();
        this.state={
          searchQuery:'',
          excelDataSet:[]     
        } 
        this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
      }
      componentWillReceiveProps=(nextProps)=>{
        this.setState({excelDataSet:nextProps.globalChargeListData});
      }
      buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'Charge Name'}],      
          data:this.state.excelDataSet.map((item)=>{
            return [{value:item.chargeName}]
          })
        }])
      }
      getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              background: rowInfo.original.isGlobal? 'Aqua' : 'Lime',
              color: 'black'
            }
          }
        }
        return {};
      }
      getFilterData=(data)=>{
        let finalList = [];
        if(data !=undefined && data.length>0){
          let globaleList = data.filter( function (val) {
            return val.isGlobal === true
          });
         // data.filter(val => {return val.isGlobal});
          let localList = data.filter( function (val) {
            return val.isGlobal === false
          });
          //data.filter(val => {return !val.isGlobal});
          if(globaleList !=undefined && globaleList.length>0)
          finalList =finalList.concat(globaleList)
          if(localList !=undefined && localList.length>0)
          finalList =finalList.concat(localList);
        }
        return finalList;

      }
    render(){
    const {globalChargeListData} =this.props;
    const columns=[{
      Header: 'charge name',
      accessor: 'chargeName', // String-based value accessors!
      filterable:true,
      minWidth: 200
    }
    ,{
      Header: 'COMPANY NAME',
      accessor: 'companyName', // String-based value accessors!
      filterable:true,
      minWidth: 200,
      style:{whiteSpace: 'unset'},
      Cell: props => props.original.isGlobal?'':props.value
    },{
        Header: 'IS-BILLABLE',
        accessor: 'isBillable', // String-based value accessors!
        filterable:true,
        style:{textAlign:'center'},
        minWidth: 100,
        Cell: props => <input type='checkbox' checked={props.value} value={props.value} onClick={(e)=>{
          let chargeId=props.original.idChargeList
          this.props.setBillableStatus(e,chargeId)
      }}/>
      }];
        return(<>
      <div className="row">
      <div className="col-md-5 col-sm-12 mb-5 mt-2">            
      </div>
      <div className="col-md-7 col-sm-6 mb-5">  
      
      <div className="pull-right">
      <div className="btn-list" style={{display:'inline-block'}}>
      <TmExportExcel name={'Custom Charges'} filename={`${"CustomCharges_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
      </div>
      </div> 
      </div>
      </div>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">	
        <TmReactTable
        ref={(table)=>this.cChargeTable=table}
        onFilteredChange={()=>{
          let data=this.cChargeTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={this.getFilterData(globalChargeListData)}
            columns={columns}
            getTrProps={this.getTrProps}
            sorted={[{
              id: 'chargeListId',
              desc: false
            }]}
        />	
        </div>
        
        </div>
        <div className="mr-4">
                        <button
                            className="btn btn-sm btn-success pull-right"
                            style={{ marginTop: '0px' }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.onSubmitSaveChargeList();
                            }}
                        >Update</button>
                    </div>
        
        </>)
    }
}
export default CustomChargeList