import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment'

class Notes extends Component {
    buildDataFormatForUserExport = () => {
        return (
        [{
            columns: [{ title: 'USER ID' }, { title: 'DATE' }, { title: 'TIME' }, { title: 'NOTES' }],
            data: (this.props.invoiceInfo._invoiceObj.invoiceNotes || []).filter((item)=>item.notes_indicator===0).map(item => {
            return [{ value: item.userId || '' }, { value: item.createdDate || '' }, { value: item.createdTime || '' }, { value: item.notesText || '' }]
            })
        }]
        )
    }
    buildDataFormatForSystemExport = () => {
        return (
        [{
            columns: [{ title: 'USER ID' }, { title: 'DATE' }, { title: 'TIME' }, { title: 'NOTES' }],
            data: (this.props.invoiceInfo._invoiceObj.invoiceNotes || []).filter((item)=>item.notes_indicator===1).map(item => {
            return [{ value: item.userId || '' }, { value: item.createdDate || '' }, { value: item.createdTime || '' }, { value: item.notesText || '' }]
            })
        }]
        )
    }
    render() {
    const { invoiceNotes } = this.props.invoiceInfo._invoiceObj || [];
    const invoiceBilling = this.props.invoiceInfo._invoiceObj.invoiceBilling || {};
    const isEdit = (!(this.props.invoicePermission.edit||false) || (invoiceBilling.isVerifiedAndLocked==1));
    const isDelete = (!(this.props.invoicePermission.delete||false) || (invoiceBilling.isVerifiedAndLocked==1));
    const userNotes = invoiceNotes.filter((item)=>item.notes_indicator===0);
    const sysNotes = invoiceNotes.filter((item)=>item.notes_indicator===1);
    const columns = [
    {
        Header: 'User Id',
        accessor: 'userId'
    },
    {
        Header: 'Date',
        accessor: 'createdDate'
    },
    {
        Header: 'Time',
        accessor: 'createdTime'
    },
    {
        Header: 'Notes',
        accessor: 'notesText'
    },
    {
        accessor: 'Actions',
        Header: 'Actions',
        sortable: false,
        filterable: false,
        style: { textAlign: 'center' },
        width: 200,
        Cell: (row) => {
          return <div>
              <input type="button" className="btn btn-sm btn-primary mr-10" disabled={isEdit} onClick={() => this.props.editNotesModal(row.original)} value="Edit" />
              <input type="button" className="btn btn-sm btn-danger" disabled={isDelete} onClick={() => this.props.deleteNotesModal(row.original)} value="Delete" />
              </div>;
        }
      }
];
        return (<Fragment>
            <div className="pd-10">
            <Form className="">
            <fieldset disabled={(this.props.invoiceInfo._invoiceObj.callStatus=='Cancel')}>
            <div className="row mb-10">
                    <div className="col-md-6">
                        <textarea className="form-control" name="userNotes" value={this.props.userNotes} onChange={(e)=>this.props.changeUserNotesField(e.target.name, e.target.value)} placeholder="ENTER NEW USER NOTE"></textarea>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="btn btn-success btn-sm" onClick={(e)=>{
                            e.preventDefault();
                            this.props.addUserNotes(0, this.props.userNotes);
                        }} disabled={((this.props.userNotes || '').trim() !== '' ? false : true) || isEdit}>Add Notes</button>
                    </div>
            </div>
                <h6 className="text-blue text-bg-color form-group">User Notes</h6>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                    <TmExportExcel
                        name={"User Notes"}
                        filename={`${"UserNotes_"}${moment().format('MMDDYYhhmm')}`} 
                        fileExtension={'xlsx'}
                        element={<button className="btn btn-warning btn-sm pull-right" onClick={(e)=>e.preventDefault()}><i className="fa fa-file-excel-o"></i> Export</button>}
                        dataSet={this.buildDataFormatForUserExport()}
                        />
                    </div>
                    <div className="col-md-12 col-sm-12 mb-10">
                        <TmReactTable
                        filterable
                        sortable
                        resizable
                        defaultPageSize={25}
                        data={userNotes}
                        columns={columns}
                        />
                    </div>
                </div>
                <h6 className="text-blue text-bg-color form-group">System Notes</h6>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                    <TmExportExcel
                        name={"System Notes"}
                        filename={`${"SystemNotes_"}${moment().format('MMDDYYhhmm')}`} 
                        fileExtension={'xlsx'}
                        element={<button className="btn btn-warning btn-sm pull-right" onClick={(e)=>e.preventDefault()}><i className="fa fa-file-excel-o"></i> Export</button>}
                        dataSet={this.buildDataFormatForSystemExport()}
                        />
                    </div>
                    <div className="col-md-12 col-sm-12 mb-10">
                        <TmReactTable
                        filterable
                        sortable
                        resizable
                        defaultPageSize={25}
                        data={sysNotes}
                        columns={columns.filter(i=>i.accessor !== 'Actions')}
                        />
                    </div>
                </div>
                
                </fieldset>
            </Form>
            </div>
        </Fragment>);
    }
}
export default Notes
