import queryString from 'query-string';

class SideMenuCls {
    constructor() {        
        this.menuObjList =[
            {path: "/admin/company", spath:"/company", sidebarName: "Company" ,module:'Company',submenu:[], isLaunch: false, accessModule:'Admin', baseURL:"/admin",type:"I", showManageCompany:true,showChildCompany:false},
             {path: "/summarydashboard", spath:"/summarydashboard", sidebarName: "Summary Dashboard", submenu:[], isLaunch: true, module:'Dashboard', accessModule:'Dispatcher', type:"I", showManageCompany:true,showChildCompany:true},
            { path: "#", spath:"#", sidebarName: "Admin", className: "", icon: "fa fa-lock", type:"G",
              sub:[
                  {path: "/admin-dashboard", spath:"/admin-dashboard", sidebarName: "Dashboard", submenu:[], isLaunch: true, type:"I", module:'Dashboard', accessModule:'Admin', baseURL:"", showManageCompany:true,showChildCompany:true},
                  {path: "/admin/account", spath:"/account", sidebarName: "Account", submenu:[], type:"I", module:'Account', baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                  {path: "/admin/employees", spath:"/employees", sidebarName: "Employees", submenu:[], module:'Employee', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},                                                     
                  {path: "/admin/trucks", spath:"/trucks", sidebarName: "Trucks", submenu:[],  module:'Trucks', type:"I", baseURL:"/admin", showManageCompany:false,showChildCompany:true},                      
                  {path: "/admin/rateplan", spath:"/rateplan", sidebarName: "Default Rate Plan", submenu:[],  module:'Default Rate Plan', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                  {path: "/admin/commission", spath:"/commission", sidebarName: "Commission", submenu:[],  module:'Commission', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                  {path: "/admin/auction", spath:"/auction", sidebarName: "Auction", submenu:[],  module:'Auction', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},  
                  {path: "/admin/vendors", spath:"/vendors", sidebarName: "Vendors", submenu:[],  module:'Vendor', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},                
                  {path: "#", spath:"#", sidebarName: "Config",
                   submenu:
                   [
                       {path: "/admin/towtype", spath:"/towtype", sidebarName: "Tow Type",  module:'Tow Type', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       {path: "/admin/locations", spath:"/locations", sidebarName: "Locations Manager" ,module:'Location Manager', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       {path: "/admin/defaultRateplanChargeListDetails", spath:"/defaultRateplanChargeListDetails", sidebarName: "Custom Charges" ,module:'Custom Charges', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       //{path: "/admin/monetizationConfig", spath:"/monetizationConfig", sidebarName: "Monetization Charges" ,module:'Custom Charges', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       {path: "/admin/apkUpdate", spath:"/apkUpdate", sidebarName: "APK Update" ,module:'APK Update', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       {path: "/admin/billingReportEmail", spath:"/billingReportEmail", sidebarName: "Statement Email" ,module:'Statement Email', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       {path: "/admin/statementMessage", spath:"/statementMessage", sidebarName: "Statement Message" ,module:'Statement Message', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true},
                       {path: "/admin/paymentSetting", spath:"/paymentSetting", sidebarName: "Payment Settings" ,module:'Statement Message', type:"I", baseURL:"/admin", showManageCompany:true,showChildCompany:true}
                      /*  {path: "/billingterms", sidebarName: "Billing Terms",  module:'Billing Terms', type:"I", mainModule:"admin"}, */
                       
                    ], module:'Admin',subModule:'Config', type:"G"
                   }
                ],rootName:'admin',baseURL:'/admin', module:'Admin'
            },
            { path: "#", spath:"#", sidebarName: "Dispatcher", className: "", icon: "fa fa-user-circle-o", type:"G",
              sub:[                  
                  {path: "/dispatch/dashboard" , spath:"/dashboard", sidebarName: "Dispatcher Dashboard", submenu:[],  module:'Dispatcher Dashboard', type:"I", baseURL:"/dispatch", showManageCompany:true,showChildCompany:true},
                //   {path: "/dispatch/serviceCallInfo", sidebarName: "Add New Service Call", submenu:[],  module:'Create New Call', type:"I", baseURL:"/dispatch", showManageCompany:true,showChildCompany:true},
                  {path: "/dispatch/addNewServiceCall" , spath:"/addNewServiceCall", sidebarName: "Add New Service Call", submenu:[],  module:'Create New Call', type:"I", baseURL:"/dispatch", showManageCompany:true,showChildCompany:true},
                  {path: "/dispatch/invoiceSearch", spath:"/invoiceSearch", sidebarName: "Invoice Details", submenu:[], checkEdit:true, module:'Dispatcher',subModule:'Invoice screen', type:"G", baseURL:"/dispatch", showManageCompany:true,showChildCompany:true},
                //   {path: "/truckSummary", sidebarName: "Truck Details", submenu:[],  module:'Truck Details', type:"I", mainModule:"dispatch"},
                  {path: "/dispatch/map", spath:"/map", sidebarName: "Map", submenu:[],  module:'Map', type:"I", baseURL:"/dispatch", showManageCompany:true,showChildCompany:true}        
                ],rootName:'dispatch',baseURL:'/dispatch',module:'Dispatcher'
            },	
            { path: "#", spath:"#", sidebarName: "Reports", className: "", icon: "fa fa-flag", type:"G",
              sub:[
                  {path: "/reports-dashboard", spath:"/reports-dashboard", sidebarName: "Dashboard", submenu:[], isLaunch: true,  module:'Dashboard', type:"I", baseURL:"",accessModule:'Reports', showManageCompany:true,showChildCompany:true},
                //   {path: "/reports/monetization", spath:"/monetization", sidebarName: "Monetization", submenu:[],  module:'Monetization', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}, 
                  {path: "#", spath: "#", sidebarName: "Monetization", type:"G",
                   submenu:
                   [
                       {path: "/reports/cr-details", spath:"/cr-details", sidebarName: "C/R Detail",submenu:[],  module:'C/R Details', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/cr-summary", spath:"/cr-summary", sidebarName: "C/R Summary" ,submenu:[], module:'C/R Summary', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/cr-statements", spath:"/cr-statements", sidebarName: "C/R Statement" ,submenu:[],  module:'Statements', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/auction-batch-payment", spath:"/auction-batch-payment", sidebarName: "Batch Payment" ,submenu:[],  module:'Auction Batch Payment', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Monetization'
                   }, 
                  {path: "/reports/allInvoices", spath:"/allInvoices", sidebarName: "All Invoice", submenu:[],  module:'All Invoices', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},        
                  {path: "#", spath: "#", sidebarName: "Accounting", type:"G",
                   submenu:
                   [
                       {path: "/reports/ar-details", spath:"/ar-details", sidebarName: "A/R Detail",submenu:[],  module:'A/R Details', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/ar-detail-by-account-type", spath:"/ar-detail-by-account-type", sidebarName: "A/R Detail by Account Type" ,submenu:[], module:'A/R Detail by Account Type', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/ar-summary", spath:"/ar-summary", sidebarName: "A/R Summary" ,submenu:[], module:'A/R Summary', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/multiple-invoice-payment", spath:"/multiple-invoice-payment", sidebarName: "Batch Payments" ,submenu:[],  module:'Multiple Invoice Payment', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/payment-audit-report", spath:"/payment-audit-report", sidebarName: "Payment Audit" ,submenu:[],  module:'Payment Audit Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/receipts", spath:"/receipts", sidebarName: "Receipts" ,submenu:[],  module:'Receipts', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/billing", spath:"/billing", sidebarName: "Statements" ,submenu:[],  module:'Statements', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Accounting'
                   },
                   {path: "#", spath:"#", sidebarName: "Driver Management", type:"G",
                   submenu:
                   [
                       {path: "/reports/driver-commission", spath:"/driver-commission", sidebarName: "Driver Commissions Detail" ,submenu:[],  module:'Single Driver Commission', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/driver-commission-summary", spath:"/driver-commission-summary", sidebarName: "Driver Commissions Summary" ,submenu:[], module:'Driver Commission Summary', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/driver-performance-summary", spath:"/driver-performance-summary", sidebarName: "Driver Performance Summary" ,submenu:[],  module:'Driver Performance Summary', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Driver Management'
                   },
                   {path: "#", spath:"#", sidebarName: "Storage Management", type:"G",
                   submenu:
                   [
                       {path: "/reports/auction-list", spath:"/auction-list", sidebarName: "Auction List" ,submenu:[],  module:'Auction List', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/storage-inventory", spath:"/storage-inventory", sidebarName: "Storage Inventory" ,submenu:[],  module:'Storage Inventory', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/no-avr-filed", spath:"/no-avr-filed", sidebarName: "No AVR Filed" ,submenu:[],  module:'No AVR Filed', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Storage Management'
                   },
                   {path: "#", spath:"#", sidebarName: "Account Management", type:"G",
                   submenu:
                   [
                    {path: "/reports/account-contact-report", spath:"/account-contact-report", sidebarName: "Account Contact" ,submenu:[],  module:'Account Contact Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/activity-report", spath:"/activity-report", sidebarName: "Account Activity" ,submenu:[],  module:'Account Activity', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/ppi-revenue-report", spath:"/ppi-revenue-report", sidebarName: "PPI Revenue" ,submenu:[],  module:'PPI Revenue Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Account Management'
                   },
                   {path: "#", spath:"#", sidebarName: "Invoice Management", type:"G",
                   submenu:
                   [
                    {path: "/reports/missing-po-number", spath:"/missing-po-number", sidebarName: "Missing PO" ,submenu:[],  module:'Missing PO Number', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/negative-balance-report", spath:"/negative-balance-report", sidebarName: "Negative Balance" ,submenu:[],  module:'Negative Balance Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/partial-payment-report", spath:"/partial-payment-report", sidebarName: "Partial Payment" ,submenu:[],  module:'Partial Payment Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/unlocked-invoices", spath:"/unlocked-invoices", sidebarName: "Unlocked" ,submenu:[],  module:"Unlocked Report", type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/unverified-invoices", spath:"/unverified-invoices", sidebarName: "Unverified" ,submenu:[],  module:'Unverified invoices', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Invoice Management'
                   },
                   {path: "#", spath:"#", sidebarName: "Summaries", type:"G",
                   submenu:
                   [
                       {path: "/reports/summary-by-account-rate-plan", spath:"/summary-by-account-rate-plan", sidebarName: "Summary by Account Rate Plan" ,submenu:[],  module:'Summary By Account Rate Plan', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/summary-by-driver", spath:"/summary-by-driver", sidebarName: "Summary by Driver" ,submenu:[],  module:'Summary By Driver', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/summary-by-reason-code", spath:"/summary-by-reason-code", sidebarName: "Summary by Reason" ,submenu:[],  module:'Summary By Reason Code', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/summary-by-tow-type", spath:"/summary-by-tow-type", sidebarName: "Summary by Tow Type" ,submenu:[],  module:'Summary By Tow Type', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/summary-by-truck", spath:"/summary-by-truck", sidebarName: "Summary by Truck" ,submenu:[],  module:'Summary By Truck', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                       {path: "/reports/summary-details", spath:"/summary-details", sidebarName: "Summary by details " ,submenu:[],  module:'Summary by details', type:"I", baseURL:"/reports", showManageCompany:false,showChildCompany:false},
                       {path: "/reports/response-time-report", spath:"/response-time-report", sidebarName: "Response Time" ,submenu:[],  module:'Response Time Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}
                    ], module:'Reports',subModule:'Summaries'
                   },
                   {path: "#", spath: "#", sidebarName: "Custom", type:"G",
                   submenu:
                   [
                    {path: "/reports/batch-invoice-payment", spath:"/batch-invoice-payment", sidebarName: "Auction Batch Payment" ,submenu:[],  module:'Auction Batch Payment', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/custom-phoenix-pd-all-tows", spath:"/custom-phoenix-pd-all-tows", sidebarName: "PHX All Tows" ,submenu:[],  module:'Custom: Phoenix PD All Tows', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/custom-phoenix-pd-release", spath:"/custom-phoenix-pd-release", sidebarName: "PHX Release" ,submenu:[],  module:'Custom: Phoenix PD Release', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    ], module:'Reports',subModule:'Custom'
                   },
                  {path: "/reports/customReports", spath:"/customReports", sidebarName: "Report Builder", submenu:[], module:'Custom Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true}  
                ],rootName:'reports',baseURL:'/reports', module:'Reports'
            },
            {path: "#", spath:"#", sidebarName: "In progress", className: "", icon: "fa fa-wrench", type:"G",
                   sub:
                   [
                    {path: "/reports/account-report", spath:"/account-report", sidebarName: "Contract Metrics" ,submenu:[],  module:'Contract Metrics', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/view-vcr-report", spath:"/view-vcr-report", sidebarName: "View VCR" ,submenu:[],  module:'View VCR Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/owners-contact-info-by-contract", spath:"/owners-contact-info-by-contract", sidebarName: "Contact Info by Contract" ,submenu:[],  module:'Owner Contact Info by Contract', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/dispatch-clear-report", spath:"/dispatch-clear-report", sidebarName: "Shift Report" ,submenu:[],  module:'Shift Report', type:"I", baseURL:"/reports", showManageCompany:true,showChildCompany:true},
                    {path: "/reports/truckSummary", spath:"/truckSummary", sidebarName: "Truck Details", submenu:[],  module:'Truck Details', type:"I", baseURL:"/dispatch", showManageCompany:true,showChildCompany:true}
                    ],rootName:'In progress',baseURL:'/reports', module:'In progress'
                   }
        ];
        this.redirectionMenuObj = [
            {
                path:'/reports/summary-by-driver-details',
                spath:'/summary-by-driver-details',
                selectedPath:'summary-by-account-rate-plan',
                pathParam:'byrateplan'
            },
            {
                path:'/reports/summary-by-driver-details',
                spath:'/summary-by-driver-details',
                selectedPath:'summary-by-reason-code',
                pathParam:'byreason'
            },
            {
                path:'/reports/summary-by-driver-details',
                spath:'/summary-by-driver-details',
                selectedPath:'summary-by-tow-type',
                pathParam:'bytowtype'
            },
            {
                path:'/reports/summary-by-driver-details',
                spath:'/summary-by-driver-details',
                selectedPath:'summary-by-truck',
                pathParam:'bytruck'
            },
            {
                path:'/reports/summary-details',
                spath:'/summary-details',
                selectedPath:'summary-details',
                pathParam:'bydetails'
            },
            {
                path:'/reports/summary-by-driver-details',
                spath:'/summary-by-driver-details',
                selectedPath:'summary-by-driver',
                pathParam:'bydriver'
            },
            {
                path:'/admin/accountDetails',
                spath:'/accountDetails',
                selectedPath:'account',
                pathParam:''
            },
            {
                path:'/dispatch/editServiceCall',
                spath:'/editServiceCall',
                selectedPath:'serviceCallInfo',
                pathParam:''
            },
            {
                path:'/admin/companyRecord',
                spath:'/companyRecord',
                selectedPath:'company',
                pathParam:''
            },
            {
                path:'/admin/vendorsDetails',
                spath:'/vendorsDetails',
                selectedPath:'vendors',
                pathParam:''
            },
            {
                path:'/admin/employeesDetails',
                spath:'/employeesDetails',
                selectedPath:'employees',
                pathParam:''
            },
            {
                path:'/admin/commissionDetails',
                spath:'/commissionDetails',
                selectedPath:'commission',
                pathParam:''
            },
            {
                path:'/admin/locationsDetails',
                spath:'/locationsDetails',
                selectedPath:'locations',
                pathParam:''
            },
            {
                path:'/admin/trucksDetails',
                spath:'/trucksDetails',
                selectedPath:'trucks',
                pathParam:''
            },
            {
                path:'/admin/defaultRateplanDetails',
                spath:'/defaultRateplanDetails',
                selectedPath:'rateplan',
                pathParam:''
            }
        ];
    }
    getMenuList=()=>{
       return this.menuObjList;
    }

    havingAccess=(module,accessLevels={},dashboardAccessLevels={})=>{
        let _accessModuleList=[];
        let _dashboardModuleList=[]; 
        Object.keys(accessLevels).map((_mainMod)=>{            
            Object.keys(accessLevels[_mainMod]).map((_subMod)=>{
                accessLevels[_mainMod][_subMod].map((_mod)=>{
                    _accessModuleList.push({group:_mainMod,subGroup:_subMod,..._mod})
                })
            });
        });
        _dashboardModuleList= Object.keys(dashboardAccessLevels).map((mainObj)=>{
           let viewAccess= dashboardAccessLevels[mainObj].some((item)=>{
                return (item.visible||false)===true;
            });
            if(mainObj==='Dispatcher'){
                viewAccess=true;
            }
            return {module:mainObj,access:viewAccess};
        });
        if(module.type==='G'){
            let filterList=_accessModuleList.filter((item)=>{
                if(module.subModule){
                    return item.group.toLowerCase()===module.module.toLowerCase() && item.subGroup.toLowerCase()===(module.subModule||'').toLowerCase()
                }else{
                    return item.group.toLowerCase()===module.module.toLowerCase() || item.subGroup.toLowerCase()===(module.subModule||'').toLowerCase()
                }                
            });
            let res= filterList.some(item=>{
                return ((item.basicAccess.view||false)===true||(item.basicAccess.add||false)===true||
                (item.basicAccess.edit||false)===true||(item.basicAccess.delete||false)===true);
            });
            if(!module.subModule){           
            const dashboardViewIdx=_dashboardModuleList.findIndex((item)=>{
                return item.module.toLowerCase()===module.module.toLowerCase();
            })
            if(dashboardViewIdx!==-1){
                if(!res){
                    res=_dashboardModuleList[dashboardViewIdx].access;
                }                
            }
        }
            return res;
        }
        if(module.type==='I'){
            if(module.isLaunch===true){
                const dashboardViewIdx=_dashboardModuleList.findIndex((item)=>{
                    return item.module.toLowerCase()===module.accessModule.toLowerCase();
                })
                if(dashboardViewIdx!==-1){
                    return _dashboardModuleList[dashboardViewIdx].access;
                }else{
                    return false;
                }
            }
            return (_accessModuleList.filter((item)=>{
                return (item.pageName===''?item.subGroup.toLowerCase():item.pageName.toLowerCase())===module.module.toLowerCase()
            }).some(item=>{
                return (item.basicAccess.view===true||item.basicAccess.add===true||
                item.basicAccess.edit===true||item.basicAccess.delete===true);
            }));
        }
        return false;
    }    
    getSelectedMenuObj=(urlPath)=>{
        let _urlPath=urlPath.substr(1,urlPath.length);
        let pathObj={root:'',subRoot:'',child:''}
        let pathSplit=_urlPath.split('/');
        let pathName=pathSplit[1];
        if(_urlPath.trim()===''){
            pathObj.root='Dashboard';
            pathObj.subRoot= '';
            pathObj.child='summarydashboard'

        }else if(pathSplit.length===1){
            let _mainIndex=-1
            let _subIndex=-1;
            let _childIdx=-1;
            _mainIndex=this.menuObjList.findIndex(mainObj=>{
                if(mainObj.type==='I'){                    
                    return mainObj.spath.toLowerCase()===('/'+pathSplit[0]).toLowerCase()
                }else{
                   if(mainObj.baseURL.toLowerCase()===('/'+pathSplit[0]).toLowerCase()){
                       return true;
                   } 
                    _subIndex= (mainObj.type==='I'?mainObj.submenu: mainObj.sub).findIndex((subObj=>{
                        if(subObj.spath.toLowerCase()===('/'+pathSplit[0]).toLowerCase()){
                            return true;
                        }else{
                            _childIdx=subObj.submenu.findIndex(childObj=>{
                                return (childObj.spath.toLowerCase()===('/'+pathSplit[0]).toLowerCase())?true:false
                            });
                            if(_childIdx==-1){
                                return false;
                            }else{
                                return true;
                            }
                        }
                    }));
                }                
                if(_subIndex!==-1){
                    return true;
                }
            })
            pathObj.root= this.menuObjList[_mainIndex].type==='I'?this.menuObjList[_mainIndex].module:this.menuObjList[_mainIndex].rootName;
            pathObj.subRoot= _subIndex===-1?'':this.menuObjList[_mainIndex].sub[_subIndex].module;
            pathObj.child=pathSplit[0]
        }else{
            pathObj.root=pathSplit[0]||"";
			let mainIndex=-1;
            mainIndex= this.menuObjList.findIndex(mainObj=>{                
                return (mainObj.rootName||'')===(pathSplit[0]||'')?true:false
            });
            const parsedHash = queryString.parse(window.location.search);
            this.redirectionMenuObj.findIndex(childObj=>{
                if(childObj.spath.toLowerCase()===('/'+pathName).toLowerCase()){
                    if(childObj.pathParam === ''){
                        pathName=childObj.selectedPath;
                    }else{
                        if(childObj.pathParam === parsedHash.type){
                            pathName=childObj.selectedPath;
                        }
                    }
                }
            });
            let subIndex=-1
            if(mainIndex!==-1){
                let childIdx=-1;
                subIndex= this.menuObjList[mainIndex].sub.findIndex((subObj=>{
                if(subObj.spath.toLowerCase()===('/'+pathName).toLowerCase()){
                    return true;
                }else{  
                        childIdx=subObj.submenu.findIndex(childObj=>{
                            return (childObj.spath.toLowerCase()===('/'+pathName).toLowerCase())?true:false
                        });
                        if(childIdx===-1){
                            return false;
                        }else{
                            return true;
                        }
                }
            }));
            }
            if(subIndex!==-1){
                pathObj.subRoot=this.menuObjList[mainIndex].sub[subIndex].module;
            }else{
                pathObj.subRoot=''
            }
            pathObj.child=pathName||"";
        }
        if(pathName === 'company'){
            pathObj.root=pathName;
        }        
        return pathObj
    }
    haveAccess=(mainModule,subModule=undefined,accessLevels={})=>{
        let haveAccess=false;
        let accessGroup= accessLevels;
        if(!subModule){
            if(Object.keys(accessGroup).length>0){
                haveAccess=Object.keys(accessGroup[mainModule]).some((item)=>{
                    let itemAccess=false;
                    itemAccess= (accessGroup[mainModule][item]).some((subItem)=>{
                        return subItem.basicAccess.add===true||subItem.basicAccess.edit===true||subItem.basicAccess.view===true
                    })
                    return itemAccess;
                });                   
            }
        }
        else{
            /* if(Object.keys(accessGroup).length>0){
                let Idx=Object.keys(accessGroup[mainModule]).findIndex((mod)=>{
                    return (mod.toLowerCase()===subModule.toLowerCase())
                })
                if(Idx!==-1){
                    haveAccess=Object.keys(accessGroup[mainModule])[Idx].permissionView===1?true:false
                    if(!haveAccess){
                       let selectedMod= this.getMenuList().find((option)=>{
                            return option.module===mainModule;
                        });
                        if(selectedMod){
                          let subOption=  selectedMod.sub.find(item=>{
                               return item.module===subModule
                            });
                            if((subOption||{}).checkEdit===true){
                                haveAccess=accessGroup[mainModule][Idx].permissionUpdate===1?true:false
                            }
                        }else{
                            haveAccess=false;
                        }
                    }
                }
            } */
    
        }
        return haveAccess=true;
    }
   
}
export default SideMenuCls
