import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { notify } from '../../../util/notifications';
import { isValidForm } from '../../../util/validationMethods';
import SummaryByTruckCtrl from '../../../components/reportsComponents/summaries/summaryByTruckCtrl';
import SummaryByTruckTable from '../../../components/reportsComponents/summaries/summaryByTruckTable';
import {initSummState} from '../../../util/stateUtil/initialStateForComponents';
import { fetchAllTruckList } from '../../../actions/reportAction/allinvoice/allinvoice';
import {truckColumns} from '../../../util/tableColumnUtil/summaryClms';
import {cleanSummaryDataReport,getsummaryDataReport,emailSummaryByType} from '../../../actions/reportAction/summaryAction/summary'
import printJS from 'print-js';
import {isManagement,getCompanyId} from '../../../util/customMethods';
const printJSHeaders = truckColumns.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
const pageTitle = 'Summary By Truck'
let ReportForm={}

class SummaryByTruck extends Component {
	state = {
        ...initSummState
		}

        Go = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(getsummaryDataReport(payload, status));
                if (res.statusCode === 200) {
                    notify.createNotification('success', res.statusMsg, this.props.refNotify);
                    this.setState({ render: false });
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        Export = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(getsummaryDataReport(payload, status));
                if (res.statusCode === 200) {
                    this.setState({ render: !this.state.render });
                    notify.createNotification('success', 'Report exported successfully.', this.props.refNotify);
                    this.setState({ render: false });
                } else {
                    this.setState({ render: false });
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        Print = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(getsummaryDataReport(payload, status));
                if (res.statusCode === 200) {
                    printJS({
                        printable: this.props.summaryObj.truckDetails || [],
                        properties: printJSHeaders,
                        type: 'json',
                        documentTitle: 'Driver summary Report'
                    });
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        Email = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(emailSummaryByType(payload, status));
                if (res.statusCode === 200) {
                    notify.createNotification('success', res.statusMsg, this.props.refNotify);
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        /* Building all invoice object for post request */
        buildObject = () => {
            const stateObj = this.state;
            const constractObj = {
                idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
                fromDate: stateObj.fromDate,
                toDate: stateObj.toDate,
                accountId: 0,
                name: "truck",
                id: stateObj.truckId
            };
            return constractObj;
        }

	componentWillMount = () => {
        const {dispatch} = this.props;
		document.title = pageTitle;
        dispatch(cleanSummaryDataReport());
        dispatch(fetchAllTruckList());
		
    }
    onSummaryByTruckFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let stateObj = this.state;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Summary By Truck</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <SummaryByTruckCtrl 
                     Go={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Go();
                        }
                    }}
                    Export={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Export();
                        }
                    }}
                    Email={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Email();
                        }
                    }} Print={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Print();
                        }
                    }}
                      stateObj={stateObj}
                      accountTypes={this.props.accountTypes}
                      accountList={this.props.accountList}
                      childCompList={this.props.childCompList}
                      callReasons={this.props.callReasons}
                      towTypes={this.props.towTypes}
                    data={this.props.summaryObj}
                      allInvoiceObj={this.props.allInvoiceObj}
                    onSummaryByTruckFieldChange={(key, value)=>this.onSummaryByTruckFieldChange(key, value)}
                                    initCallBack={(frm)=>{
                                        ReportForm=frm;
                                    }} fromdate={this.state.fromDate} todate={this.state.toDate}/>
                    <SummaryByTruckTable 
                    stateObj={stateObj}
                    cols={truckColumns}
                    data={this.props.summaryObj}/>
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        refNotify: state.refNotify,
        accountTypes: state.accountTypes.accountTypes,
        accountList: state.invoiceInfo.accountList,
        childCompList: state.searchInvoiceList.childComList,
        callReasons: state.callReasons.callReasons,
        towTypes: state.towTypeList.towTypes,
        allInvoiceObj: state.allInvoice,
        summaryObj:state.summaryObj.summaryData
	}
};

export default withRouter(connect(mapStateToProps)(SummaryByTruck));
