import React from 'react';
import ReactStreetview from 'react-streetview';
class StreetView extends React.Component {
  constructor(props){
    super(props);
    this.state={
      address: this.props.address || ''
    }
  }
  componentWillMount=()=>{
	  this.setState({address: this.props.address || ''});
  }
  componentWillReceiveProps=(nextProps)=>{
    if(nextProps.address!==this.props.address){
		this.setState({address:nextProps.address});
		this.forceUpdate();
    }  
  }
  componentWillUnmount=()=>{
	  this.setState({address:''})
  }
  render() {
		// see https://developers.google.com/maps/documentation/javascript
    const googleMapsApiKey = this.props.APIkey;
    const lat_long=this.state.address || '';
    const latitude=parseFloat(lat_long.split(",")[0] || 0);
    const longitude=parseFloat(lat_long.split(",")[1] || 0);
		// see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
		const streetViewPanoramaOptions = {
			position: {lat: latitude, lng: longitude},
			pov: {heading: 100, pitch: 0},
			zoom: 1
    };
		return (
			<div style={{
				width: '450px',
				height: '250px',
				backgroundColor: '#eeeeee'
			}}>
				<ReactStreetview
					apiKey={googleMapsApiKey}
					streetViewPanoramaOptions={streetViewPanoramaOptions}
				/>
			</div>
		);
	}
}
export default StreetView;