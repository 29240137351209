import React, { Component, Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { Link } from 'react-router-dom';
class SummaryByDriverTable extends Component {
	render() {
     const driverColumns = [
      {
          accessor: 'driverId',
          Header: 'Driver ID',
          style: { textAlign: 'center' },
          width: 200,
          Cell: props => <Link to={`/reports/summary-details?fromDate=${this.props.stateObj.fromDate}&toDate=${this.props.stateObj.toDate}&id=${props.original.employeeId}&type=${'driver'}&accountId=${this.props.stateObj.accountId}&idCompany=${this.props.stateObj.idCompany}`} target="_blank">{props.original.driverId}</Link>,
          Footer:(<span>Total :</span>)
      },
      {
          accessor: 'driverName',
          Header: 'Driver Name',
          style: { textAlign: 'center' },
          width: 200
      },
      {
          accessor: 'noOfTows',
          Header: 'Number Of Tows',
          style: { textAlign: 'center' },
          width: 200,
          Footer:( `${this.props.data.totalTows||0}`)
      },
      {
          accessor: 'totalCharges',
          Header: '*Total Charges',
          style: { textAlign: 'center' },
          width: 200,
          Footer:( `$ ${this.props.data.sumOfTotalCharges||0}`)
      },
      {
          accessor: 'use',
          Header: 'Use %',
          style: { textAlign: 'center' },
          width: 200
      },
      {
          accessor: 'chargesPerTow',
          Header: '*Charges Per Tow',
          style: { textAlign: 'center' },
          width: 200,
          Footer:( `$ ${this.props.data.avgChargePerTow||0}`)
      }
  ];
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={this.props.data.driverDetails||[]}
                        columns={driverColumns}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default SummaryByDriverTable;