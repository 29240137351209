import * as actionTypes from '../../actionTypes';
import { moduleTypes } from '../../../util/constants';
import { getAccessToken } from '../../authenticateActions'
import { getAxiosInstance } from '../../../util/service';
export const retrieveInvoiceManageReport = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/getManagedInvoices/${obj.name}/${obj.idCompany}/${status}?access_token=${getAccessToken()}&asOfDate=${obj.asOfDate}&location=${obj.location}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if (response.status === 200) {
                dispatch({ type: actionTypes.GET_INVOICE_MANAGE_DATA, invoiceManageReportData: response.data.response || [] })
            } else {
                dispatch({ type: actionTypes.GET_INVOICE_MANAGE_DATA, invoiceManageReportData: undefined })
            }
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_INVOICE_MANAGE_DATA, invoiceManageReportData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}
export const cleanManagedInvociesReport = () => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_INVOICE_MANAGE_DATA, invoiceManageReportData: undefined })
    }
}
export const emailManagedInvoice = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/emailManagedInvoices/${obj.name}/${obj.idCompany}/${status}?access_token=${getAccessToken()}&asOfDate=${obj.asOfDate}&location=${obj.location}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_INVOICE_MANAGE_DATA, invoiceManageReportData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}