 export const allInvoiceFieldData= {
      "Service Call Info": [
         {
            "fieldName": "displayInvoiceId",
            "value": "i.invoiceId",
            "status": false,
            "isManagement": false,
            "priority":6,"title":"Invoice"
         },
         {
            "fieldName": "callerPhone",
            "value": "sc.callerPhoneNo",
            "status": false,
            "isManagement": true,
            "priority":3,"title":"Caller Phone"
         },
         {
            "fieldName": "pickupAddress",
            "value": "i.pickUpAddr_Address_Line1",
            "status": false,
            "isManagement": true,
            "priority":18,"title":"Pick Up Address"
		 },
         {
            "fieldName": "dropOffCity",
            "value": "i.dropOffAddr_Address_City",
            "status": false,
            "isManagement": true,
            "priority":24,"title":"Drop Off City"
         },
         {
            "fieldName": "serviceCallDate",
            "value": "sc.callCreatedTime",
            "status": false,
            "isManagement": true,
            "priority":5,"title":"Service Call Date"
         },
         {
            "fieldName": "callerEmail",
            "value": "sc.callerEmailId",
            "status": false,
            "isManagement": true,
            "priority":4,"title":"Caller Email"
         },
         {
            "fieldName": "pickupCity",
            "value": "i.pickUpAddr_Address_City",
            "status": false,
            "isManagement": true,
            "priority":19,"title":"Pick Up City"
         },
         {
            "fieldName": "dropOffState",
            "value": "i.dropOffAddr_Address_State",
            "status": false,
            "isManagement": true,
            "priority":25,"title":"Drop Off State"
         },
         {
            "fieldName": "requestedBy",
            "value": "a.name as requestedByName",
            "status": false,
            "isManagement": true,
            "priority":0,"title":"Requested By"},
         {
            "fieldName": "contactName",
            "value": "i.contactPerson",
            "status": false,
            "isManagement": true,
            "priority":8,"title":"Contact Name"
         },
         {
            "fieldName": "pickupState",
            "value": "i.pickUpAddr_Address_State",
            "status": false,
            "isManagement": true,
            "priority":20,"title":"Pick Up State"
         },
         {
            "fieldName": "dropOffZipcode",
            "value": "i.dropOffAddr_Address_Zip",
            "status": false,
            "isManagement": true,"priority":26,"title":"Drop Off Zipcode"},
         {
            "fieldName": "ratePlan",
            "value": "arp.AccountPlanRateName",
            "status": false,
            "isManagement": true,"priority":29,"title":"Rate Plan"},
         {
            "fieldName": "contactPhone",
            "value": "i.contactPhoneNo",
            "status": false,
            "isManagement": true,
            "priority":9,"title":"Contact Phone"},
         {
            "fieldName": "pickupZipcode",
            "value": "i.pickUpAddr_Address_Zip",
            "status": false,
            "isManagement": true,
            "priority":21,"title":"Pick Up Zipcode"
         },
         {
            "fieldName": "droppOffLandmark",
            "value": "i.dropOffAddr_Address_Landmark",
            "status": false,
            "isManagement": true,"priority":84,"title":"Drop Off Landmark"},
         {
            "fieldName": "reason",
            "value": "i.reason",
            "status": false,
            "isManagement": true,"priority":0,"title":"Reason"},
         {
            "fieldName": "pickUpLandmark",
            "value": "i.pickUpAddr_Address_Landmark",
            "status": false,
            "isManagement": true,"priority":82,"title":"Pick Up Landmark"},
         {
            "fieldName": "callReceiverId",
            "value": "sc.callRecevierId",
            "status": false,
            "isManagement": true,
            "priority":1,"title":"Call Receiver ID"},
         {
            "fieldName": "towType",
            "value": "i.towType",
            "status": false,
            "isManagement": true,"priority":0,"title":"Tow Type"},
         {
            "fieldName": "truck",
            "value": "i.truckNo",
            "status": false,
            "isManagement": true,"priority":0,"title":"Truck"},
         {
            "fieldName": "dropOffLocation",
            "value": "i.dropOffLocation",
            "status": false,
            "isManagement": true,"priority":22,"title":"Drop Off Location"},
         {
            "fieldName": "dispatchId",
            "value": "i.assignedBy",
            "status": false,
            "isManagement": true,"priority":28,"title":"Dispatch ID"},
         {
            "fieldName": "priority",
            "value": "i.priority",
            "status": false,
            "isManagement": true,
            "priority":7,"title":"Priority"},
         {
            "fieldName": "driverId",
            "value": "i.driverId",
            "status": false,
            "isManagement": true,"priority":27,"title":"Driver ID"},
         {
            "fieldName": "dropOffAddress",
            "value": "i.dropOffAddr_Address_Line1",
            "status": false,
            "isManagement": true,"priority":23,"title":"Drop Off Address"},
         {
            "fieldName": "clearedById",
            "value": "i.clearedBy",
            "status": false,
            "isManagement": true,"priority":0,"title":"Cleared By Id"},
         {
            "fieldName": "callerName",
            "value": "sc.callerName",
            "status": false,
            "isManagement": true,
            "priority":2,"title":"Caller Name"},
         {
            "fieldName": "pickupLocation",
            "value": "i.pickUpLocation",
            "status": false,
            "isManagement": true,
            "priority":17,"title":"Pick Up Location"
         }
      ],
      "Service Call Times": [
         {
            "fieldName": "callTime",
            "value": "i.requestTime",
            "status": false,
            "isManagement": true,
            "priority":10,"title":"Call Time"},
         {
            "fieldName": "dispatchTime",
            "value": "i.dispatchTime",
            "status": false,
            "isManagement": true,"priority":0,"title":"Dispatch Time"},
         {
            "fieldName": "acceptedTime",
            "value": "i.acceptedTime",
            "status": false,
            "isManagement": true,
            "priority":11,"title":"Accepted Time"
         },
         {
            "fieldName": "enrouteTime",
            "value": "i.enrouteTime",
            "status": false,
            "isManagement": true,"priority":12,"title":"Enroute Time"},
         {
            "fieldName": "arrivedTime",
            "value": "i.arrivedTime",
            "status": false,
            "isManagement": true,
            "priority":13,"title":"Arrived Time"
         },
         {
            "fieldName": "hookedTime",
            "value": "i.hookedTime",
            "status": false,
            "isManagement": true,
            "priority":14,"title":"Hooked Time"
         },
         {
            "fieldName": "droppedTime",
            "value": "i.dropOffTime",
            "status": false,
            "isManagement": true,
            "priority":15,"title":"Dropped Time"
         },
         {
            "fieldName": "clearTime",
            "value": "i.clearedTime",
            "status": false,
            "isManagement": true,
            "priority":16,"title":"Clear Time"
         },
         {
            "fieldName": "totalTime",
            "value": "TIMESTAMPDIFF(MINUTE,i.requestTime,i.clearedTime) as totalTime",
            "status": false,
            "isManagement": true,
            "priority":78,"title":"Total Time"
         }
      ],
      "Owner Info": [
         {
            "fieldName": "registeredOwnerName",
            "value": "iv.vehicle_Owner_FirstName,iv.vehicle_Owner_LastName",
            "status": false,
            "isManagement": true,"priority":47,"title":"RO Name"},
         {
            "fieldName": "registeredOwnerAddress",
            "value": "iv.vehicle_Owner_Addr_Line1,iv.vehicle_Owner_Addr_Line2",
            "status": false,
            "isManagement": true,"priority":48,"title":"RO Address"},
         {
            "fieldName": "registeredOwnerCity",
            "value": "iv.vehicle_Owner_City",
            "status": false,
            "isManagement": true,"priority":49,"title":"RO City"},
         {
            "fieldName": "registeredOwnerState",
            "value": "iv.vehicle_Owner_State",
            "status": false,
            "isManagement": true,"priority":50,"title":"RO State"},
         {
            "fieldName": "registeredOwnerZip",
            "value": "iv.vehicle_Owner_Zip",
            "status": false,
            "isManagement": true,"priority":51,"title":"RO Zipcode"},
         {
            "fieldName": "registeredOwnerPhone",
            "value": "iv.vehicle_Owner_PhoneNo",
            "status": false,
            "isManagement": true,"priority":53,"title":"RO Phone"},
         {
            "fieldName": "registeredOwnerEmail",
            "value": "iv.vehicle_Owner_Email",
            "status": false,
            "isManagement": true,"priority":52,"title":"RO Email"},
         {
            "fieldName": "driverLicense",
            "value": "iv.vehicle_Info_DLNo",
            "status": false,
            "isManagement": true,"priority":54,"title":"Driver's License #"},
         {
            "fieldName": "driverIsOwner",
            "value": "iv.isDriverIsOwner",
            "status": false,
            "isManagement": true,"priority":44,"title":"Driver Is Owner"},
         {
            "fieldName": "noOwnerInfo",
            "value": "iv.noInfo",
            "status": false,
            "isManagement": true,
            "priority":45,"title":"No Owner Information"
         }
      ],
      "Vehicle Info": [
         {
            "fieldName": "orDr",
            "value": "iv.vehicle_OR_DR_No",
            "status": false,
            "isManagement": true,"priority":46,"title":"OR/DR#"},
         {
            "fieldName": "style",
            "value": "iv.vehicle_Style",
            "status": false,
            "isManagement": true,"priority":59,"title":"Style"},
         {
            "fieldName": "radio",
            "value": "iv.vehicle_Radio",
            "status": false,
            "isManagement": true,"priority":68,"title":"Radio"},
         {
            "fieldName": "policeImpound",
            "value": "iv.police_impound",
            "status": false,
            "isManagement": true,"priority":67,"title":"Police Impound #"},
         {
            "fieldName": "vin",
            "value": "iv.vehicle_VIN",
            "status": false,
            "isManagement": true,"priority":60,"title":"VIN"},
         {
            "fieldName": "plateCountry",
            "value": "iv.vehicle_Country",
            "status": false,
            "isManagement": true,"priority":61,"title":"Plate Country"},
         {
            "fieldName": "keysLocation",
            "value": "iv.vehicle_Key_Location",
            "status": false,
            "isManagement": true,"priority":65,"title":"Keys Location"},
         {
            "fieldName": "lotLocation",
            "value": "iv.lotLocation",
            "status": false,
            "isManagement": true,"priority":89,"title":"Lot Location"},
         {
            "fieldName": "year",
            "value": "iv.vehicle_Year",
            "status": false,
            "isManagement": true,"priority":55,"title":"Year"},
         {
            "fieldName": "plateState",
            "value": "iv.vehicle_State",
            "status": false,
            "isManagement": true,"priority":62,"title":"Plate State"},
         {
            "fieldName": "defects",
            "value": "iv.vehicle_Defects",
            "status": false,
            "isManagement": true,"priority":87,"title":"Defects"},
         {
            "fieldName": "inStorage",
            "value": "iv.isImpound",
            "status": false,
            "isManagement": true,"priority":71,"title":"In Storage"},
         {
            "fieldName": "make",
            "value": "iv.vehicle_Make",
            "status": false,
            "isManagement": true,"priority":56,"title":"Make"},
         {
            "fieldName": "plate",
            "value": "iv.vehicle_Plate_No",
            "status": false,
            "isManagement": true,"priority":63,"title":"Plate #"},
         {
            "fieldName": "vehicleMileage",
            "value": "iv.vehicle_ODM_Reading",
            "status": false,
            "isManagement": true,"priority":66,"title":"Vehicle Mileage"},
         {
            "fieldName": "policeHold",
            "value": "ib.isPoliceHold",
            "status": false,
            "isManagement": true,"priority":103,"title":"Police Hold"},
         {
            "fieldName": "model",
            "value": "iv.vehicle_Model",
            "status": false,
            "isManagement": true,"priority":57,"title":"Model"},
         {
            "fieldName": "vehicleStatus",
            "value": "iv.vehicle_Status",
            "status": false,
            "isManagement": true,"priority":69,"title":"Vehicle Status"},
         {
            "fieldName": "insCompany",
            "value": "iv.vehicle_InsuranceClaimNo",
            "status": false,
            "isManagement": true,"priority":88,"title":"Ins Company"},
         {
            "fieldName": "noVinButton",
            "value": "iv.noVIN",
            "status": false,
            "isManagement": true,"priority":85,"title":"Novin Button"},
         {
            "fieldName": "color",
            "value": "iv.vehicle_Color",
            "status": false,
            "isManagement": true,"priority":58,"title":"Color"},
         {
            "fieldName": "keys",
            "value": "iv.vehicle_keys",
            "status": false,
            "isManagement": true,"priority":64,"title":"Keys"},
         {
            "fieldName": "commercialUnit",
            "value": "iv.vehicle_Comm_Unit_No",
            "status": false,
            "isManagement": true,"priority":70,"title":"Commercial Unit #"},
         {
            "fieldName": "noVinReason",
            "value": "iv.noVINReason",
            "status": false,
            "isManagement": true,
            "priority":86,"title":"NoVin Reason"
         }
      ],
      "Billing Info": [
         {
            "fieldName": "billTo",
            "value": "at.name as billtoName",
            "status": false,
            "isManagement": true,"priority":0,"title":"Bill To"},
         {
            "fieldName": "daysinStorage",
            "value": "ib.storageDays",
            "status": false,
            "isManagement": true,"priority":98,"title":"#Days InStorage"},
         {
            "fieldName": "winchCharge",
            "value": "(ib.userDefined_Charge) as winch_Charge",
            "status": false,
            "isManagement": true,"priority":34,"title":"Winch Charge"},
         {
            "fieldName": "totalCharges",
            "value": "ib.totalCharge",
            "status": false,
            "isManagement": true,"priority":39,"title":"Total Charges"},
         {
            "fieldName": "towCharge",
            "value": "ib.towCharge",
            "status": false,
            "isManagement": true,"priority":30,"title":"Tow Charge"},
         {
            "fieldName": "unLoadedMileageAmount",
            "value": "ib.unloadedMiles",
            "status": false,
            "isManagement": true,"priority":90,"title":"UnLoaded Mileage Amount"},
         {
            "fieldName": "advancedPayout",
            "value": "ib.advancedPayout",
            "status": false,
            "isManagement": true,"priority":102,"title":"Advanced Payout"},
         {
            "fieldName": "discounts",
            "value": "ib.totalDiscount",
            "status": false,
            "isManagement": true,"priority":41,"title":"Discounts"},
         {
            "fieldName": "laborCharge",
            "value": "ib.laborCharge",
            "status": false,
            "isManagement": true,"priority":32,"title":"Labor Charge"},
         {
            "fieldName": "unLoadedMileageRate",
            "value": "ib.unloadedMilesRate",
            "status": false,
            "isManagement": true,"priority":91,"title":"UnLoaded Mileage Rate"},
         {
            "fieldName": "miscChargeDesc",
            "value": "ib.miscChargeLabel",
            "status": false,
            "isManagement": true,"priority":42,"title":"Miscellaneous Charge Description"},
         {
            "fieldName": "amountPaid",
            "value": "",
            "status": false,
            "isManagement": true,"priority":0,"title":"Amount Paid"},
         {
            "fieldName": "hourlyRate",
            "value": "ib.invoiceHourlyRate",
            "status": false,
            "isManagement": true,"priority":95,"title":"Hourly Rate"},
         {
            "fieldName": "loadedMileageAmount",
            "value": "ib.loadedMiles",
            "status": false,
            "isManagement": true,"priority":92,"title":"Loaded Mileage Amount"},
         {
            "fieldName": "miscCharge",
            "value": "ib.miscCharge",
            "status": false,
            "isManagement": true,"priority":35,"title":"Miscellaneous Charge"},
         {
            "fieldName": "poNo",
            "value": "ib.PONo",
            "status": false,
            "isManagement": true,"priority":40,"title":"PO #"},
         {
            "fieldName": "billableHours",
            "value": "ib.serviceBillableHours",
            "status": false,
            "isManagement": true,"priority":96,"title":"Billable Hours"},
         {
            "fieldName": "loadedMileageRate",
            "value": "ib.loadedMilesRate",
            "status": false,
            "isManagement": true,"priority":94,"title":"Loaded Mileage Rate"},
         {
            "fieldName": "adminCharge",
            "value": "ib.userDefined_Charge",
            "status": false,
            "isManagement": true,"priority":37,"title":"Admin Charge"},
         {
            "fieldName": "serviceAdvisorName",
            "value": "ib.serviceAdvisorName",
            "status": false,
            "isManagement": true,"priority":104,"title":"Service Advisor Name"},
         {
            "fieldName": "storageCharge",
            "value": "ib.storageCharge",
            "status": false,
            "isManagement": true,"priority":31,"title":"Storage Charge"},
         {
            "fieldName": "mileageCharge",
            "value": "ib.mileageCharge",
            "status": false,
            "isManagement": true,"priority":33,"title":"Mileage Charge"},
         {
            "fieldName": "customCharge",
            "value": "(ib.userDefined_Charge) as userCharge",
            "status": false,
            "isManagement": true,"priority":38,"title":"Custom Charge"},
         {
            "fieldName": "repairOrder",
            "value": "ib.repairOrderNo",
            "status": false,
            "isManagement": true,"priority":81,"title":"Repair Order#"},
         {
            "fieldName": "storageRate",
            "value": "arp.storage_each_day_after_limit2_charge",
            "status": false,
            "isManagement": true,"priority":97,"title":"Storage Rate"},
         {
            "fieldName": "gateCharge",
            "value": "ib.gateCharge",
            "status": false,
            "isManagement": true,"priority":36,"title":"Gate Charge"},
         {
            "fieldName": "tax",
            "value": "ib.tax",
            "status": false,
            "isManagement": true,"priority":99,"title":"Tax"},
         {
            "fieldName": "locked",
            "value": "ib.isVerifiedAndLocked",
            "status": false,
            "isManagement": true,
            "priority":43,"title":"Locked"
         }
      ],
      "Release/Auction Info": [
         {
            "fieldName": "releaseName",
            "value": "ir.releaseName",
            "status": false,
            "isManagement": true,"priority":100,"title":"Release Name"},
         {
            "fieldName": "releasedTo",
            "value": "ir.releaseAccountName",
            "status": false,
            "isManagement": true,"priority":72,"title":"Released To"},
         {
            "fieldName": "releaseDate",
            "value": "ir.releaseDate",
            "status": false,
            "isManagement": true,"priority":73,"title":"Release Date"},
         {
            "fieldName": "releaseTime",
            "value": "ir.releaseDate",
            "status": false,
            "isManagement": true,"priority":74,"title":"Release Time"},
         {
            "fieldName": "auctionList",
            "value": "auctionListName",
            "status": false,
            "isManagement": true,"priority":79,"title":"Auction List"},
         {
            "fieldName": "auctionDate",
            "value": "auctionDate",
            "status": false,
            "isManagement": true,"priority":80,"title":"Auction Date"},
         {
            "": "aaFile",
            "value": "iv.aaFile",
            "status": false,
            "isManagement": true,"priority":101,"title":"AA File#"},
         {
            "fieldName": "avrFiledDate",
            "value": "itp.avrDate",
            "status": false,
            "isManagement": true,"priority":75,"title":"AVR Filed Date"},
         {
            "fieldName": "trnsfrOfAuth",
            "value": "itp.transAuthDate",
            "status": false,
            "isManagement": true,"priority":77,"title":"Transfer Of Authorization"},
         {
            "fieldName": "titleDate",
            "value": "itp.titleDate",
            "status": false,
            "isManagement": true,
            "priority":76,
			"title":"Title Date"
         }
      ]
   }
