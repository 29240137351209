import * as actionTypes from '../actionTypes';
import {moduleTypes, GlobalValues} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId ,isManagement} from '../../util/customMethods';
import moment from 'moment';

export const getCallList=(isForMap)=>{
    const _url=`dashboard/callList?companyId=${getCompanyId()}&isForMap=${isForMap||false}&isManagement=${isManagement()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).get(_url)
            if(response.status===200) {        
                    dispatch({
                        type: actionTypes.GET_DISPATCH_CALL_LIST,
                        callList:response.data.response
                    });
                } else{    
                    dispatch({
                        type: actionTypes.GET_DISPATCH_CALL_LIST,
                        callList:[]
                    });
                }
            }
        catch(error){
            dispatch({
                type: actionTypes.GET_DISPATCH_CALL_LIST,
                callList:[]
            })
        }
    }
}

export const getAccountByInvoice=(invoice)=>
{
    let accountId = 0;
    return async (dispatch) => {
        let _callList=(store.getState()).dispatchCalls.callList;
        const invoiceRec= _callList.find((item)=>{return item.invoiceId===invoice})
        accountId =invoiceRec.accountId||0;
        return accountId;
    }
}

export const getTruckList=(invoice)=>{
    let accountId =0;
    if((invoice||'')!=''){
        let _callList=(store.getState()).dispatchCalls.callList;
        const invoiceRec= _callList.find((item)=>{return item.invoiceId===invoice})
        accountId =invoiceRec.accountId||0;
    }
    const _url=`dashboard/truckList?companyId=${getCompanyId()}&accountId=${accountId}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).get(_url)
            if(response.status===200) {        
                    dispatch({
                        type: actionTypes.GET_DISPATCH_TRUCK_LIST,
                        truckList:response.data.response
                    });
                } else{    
                    dispatch({
                        type: actionTypes.GET_DISPATCH_TRUCK_LIST,
                        truckList:[]
                    });
                }
            }
        catch(error){
            dispatch({
                type: actionTypes.GET_DISPATCH_TRUCK_LIST,
                truckList:[]
            })
        }
    }
}

export const invoiceClearOrCancel=(req)=>{
    return async (dispatch)=>{
        const _url=`dashboard/${req.invoiceId}/clearOrCancel?access_token=${getAccessToken()}`
        try{
            const request={callStatus:req.status, noteText:req.note}
            const response= await getAxiosInstance(moduleTypes.DISPATCH).put(_url,request);
            if(response.status===200) {
                return {statusCode:response.status,statusMsg:response.data.statusMsg}
            } else{                
                return {statusCode:null,statusMsg:response.data.message}
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}

        }
    }
}
export const getInvoiceDetails=(invoice)=>{   
    return async (dispatch) => {
        try{
            let _callList=(store.getState()).dispatchCalls.callList;
           const invoiceRec= _callList.find((item)=>{
               return item.invoiceId===invoice
            })
            dispatch({
                type: actionTypes.GET_INVOICE_DETAILS,
                invoiceRecord:invoiceRec||{}
            });
            }
        catch(error){
            dispatch({
                type: actionTypes.GET_INVOICE_DETAILS,
                invoiceRecord:undefined
            })
        }
    }
}

export const forceLogout=(driverId, truckId)=>{
    const _url=`dashboard/forceLogout/${driverId}?truckId=${truckId}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const response= await getAxiosInstance(moduleTypes.MANAGE).get(_url)
            if(response.status===200) {        
                return {statusCode:response.status,statusMsg:response.data.statusMsg}
                } else{    
                    return {statusCode:null,statusMsg:response.data.statusMsg}
                }
            }
        catch(ex){
            return {statusCode:null,statusMsg:ex.statusMsg}
        }
    }
}