import * as actionTypes from '../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
const fetchReportAccountList=(companyId)=>{
    const _url=`company/${companyId}/accountList/{0}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
       try{
           const response=await getAxiosInstance(moduleTypes.DISPATCH).get(_url);       
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_REPORT_ACCOUNT_LIST,
                    reportAccountList:response.data.response
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_REPORT_ACCOUNT_LIST,
                        reportAccountList:undefined
                    });
                }            
        }
        catch(error){            
            dispatch({
                type: actionTypes.GET_REPORT_ACCOUNT_LIST,
                reportAccountList:undefined
            });
        }
    }
}
const fetchReportAccountListClear=()=>{
    return async (dispatch) => {
        dispatch({
            type: actionTypes.GET_REPORT_ACCOUNT_LIST,
            reportAccountList:undefined
        });   
    }
}
export{
    fetchReportAccountList,
    fetchReportAccountListClear
}