import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { required, isValidForm, 
    isCurrency, isDecimal, 
    isPercentage, isNumeric } from '../../../../util/validationMethods';
import {editPermission} from '../../../../util/customMethods';
const hoursFromOptions = { dispatch: 'Dispatch', enroute: 'Enroute', arrived: 'Arrived' };
const hoursToOptions = { hooked: 'Hooked', dropped: 'Dropped', cleared: 'Cleared' }
class CommissionInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.checkAccess = this.checkAccess.bind(this);
    }
    componentWillMount = () => {
    }
    checkAccess = () => {
        if (this.props.action === 'NEW') {
            if ((this.props.accessLevel.basicAccess.add || false) == true) {
                return false
            } else {
                return true
            }
        } else {
            if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                return false;
            } else {
                return true;
            }
        }
    }

    render() {
        const { commissionRec, userDefaultCharges } = this.props;

        return (
            <>
                <div className="pd-10">
                    <Form ref={(frm) => this.form = frm}>
                        <div className="row mb-10">
                            <div className="col-md-12 col-sm-12 mb-5">
                                <div className="card box-shadow">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="col-form-label">COMMISSION TYPE</label>
                                                <div className="">
                                                    <Input className="form-control"
                                                        name="commissionType"
                                                        placeholder="" 
                                                        type="text"
                                                        disabled
                                                        requiredmsg="Please enter Commission Type"
                                                        value={this.props.commissionRec.commissionNames.commissionType || ''}
                                                        validations={[required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label">DRIVER LEVEL</label>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <Input
                                                            className="form-control"
                                                            name="driverLevel"
                                                            disabled
                                                            requiredmsg="Please enter Driver Level"
                                                            placeholder="" 
                                                            type="text"
                                                            value={this.props.commissionRec.commissionNames.driverLevel}
                                                            validations={[required]}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 col-xl-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-sm mr-2"
                                                            onClick={(e) => {
                                                                let _commissionRec = this.props.commissionRec;
                                                                this.props.onSubmitCommissionRec(e, _commissionRec)
                                                            }}
                                                        >
                                                            Save
                                                            </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <fieldset disabled={this.checkAccess()||(!editPermission(this.props.commissionRec.idCompany))}>
                            <div className="row mb-10">
                                <div className="col-md-6 col-sm-12 mb-5">
                                    <div className="card box-shadow">
                                        <div className="card-body pl-2 pt-2 pr-2 pd-2">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-sm-12 col-md-12 mb-5">
                                                            <div className="card box-shadow">
                                                                <h6 className="card-header">
                                                                    <div className="custom-control custom-checkbox mb-5">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="chFlatRate"
                                                                            checked={(this.props.commissionRec.commFlatRate == undefined) ? false : true}
                                                                            onChange={(e) => {
                                                                                const fieldValue = e.target.checked ? '' : undefined
                                                                                this.props.onCommissionInfoFieldValueChange('commFlatRate', fieldValue)
                                                                            }}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="chFlatRate">FLAT RATE</label>
                                                                    </div>
                                                                </h6>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-12">
                                                                            <div className="">
                                                                                <label className="col-form-label">RATE</label>
                                                                                <div className="input-group-prepend">
                                                                                    <div className="input-group-text">$</div>
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        name="commFlatRate"
                                                                                        placeholder="0" type="number"
                                                                                        disabled={(this.props.commissionRec.commFlatRate === undefined) ? true : false}
                                                                                        value={this.props.commissionRec.commFlatRate || ''}
                                                                                        requiredmsg="Please enter Rate"
                                                                                        //validations={!this.state.enableFlatRate?[]:[required, isCurrency]}
                                                                                        onChange={(e) => {
                                                                                            this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card box-shadow">
                                                                <div className="card-header">
                                                                    <div className="custom-control custom-checkbox mb-5">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="chHours"
                                                                            checked={
                                                                                (this.props.commissionRec.hourlyComm === undefined) ? false : true
                                                                            }
                                                                            onChange={(e) => {
                                                                                const fieldValue = e.target.checked ? '' : undefined
                                                                                this.props.onCommissionInfoFieldValueChange('hourlyComm', fieldValue);
                                                                            }}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="chHours">HOURS</label>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-12">
                                                                            <div className="">
                                                                                <label className="col-form-label">PER HOUR</label>
                                                                                <div className="input-group-prepend">
                                                                                    <div className="input-group-text">$</div>
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        name="hourlyComm"
                                                                                        placeholder="0.00" type="text"
                                                                                        disabled={
                                                                                            (this.props.commissionRec.hourlyComm === undefined) ? true : false
                                                                                        }
                                                                                        value={this.props.commissionRec.hourlyComm || ''}
                                                                                        requiredmsg="Please enter Per Hour"
                                                                                        // validations={!this.state.enableHours ? [] : [required, isCurrency]}
                                                                                        onChange={(e) => {
                                                                                            this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <label className="col-form-label">MINIMUM HOURS</label>
                                                                                <div className="">
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        name="hourlyCommMinHrs"
                                                                                        placeholder="0.00" type="text"
                                                                                        disabled={
                                                                                            (this.props.commissionRec.hourlyComm === undefined) ? true : false
                                                                                        }
                                                                                        value={this.props.commissionRec.hourlyCommMinHrs || ''}
                                                                                        requiredmsg="Please enter Minimum Hours"
                                                                                        validations={!this.state.enableHours?[]:[required]}
                                                                                        onChange={(e) => {
                                                                                            this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <label className="col-form-label">INCREMENT</label>
                                                                                <div className="">
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        name="hourlyCommIncrHrs"
                                                                                        placeholder="0" type="text"
                                                                                        disabled={
                                                                                            (this.props.commissionRec.hourlyComm === undefined) ? true : false
                                                                                        }
                                                                                        value={this.props.commissionRec.hourlyCommIncrHrs || ''}
                                                                                        requiredmsg="Please enter Increment"
                                                                                        //validations={!this.state.enableHours ? [] : [required]}
                                                                                        onChange={(e) => {
                                                                                            this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-sm-12">
                                                                                    <label className="weight-600">FROM</label>
                                                                                    <div className="custom-control custom-radio mb-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="rdFromDispatch"
                                                                                            // disabled={!this.state.enableHours}
                                                                                            checked={hoursFromOptions.dispatch === this.props.commissionRec.commStartFrom}
                                                                                            value={hoursFromOptions.dispatch}
                                                                                            defaultChecked={true}
                                                                                            name="commStartFrom"
                                                                                            className="custom-control-input"
                                                                                            onChange={(e) => {
                                                                                                this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="rdFromDispatch">{hoursFromOptions.dispatch}</label>
                                                                                    </div>
                                                                                    <div className="custom-control custom-radio mb-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="rdFromEnroute"
                                                                                            name="commStartFrom"
                                                                                            // disabled={!this.state.enableHours}
                                                                                            value={hoursFromOptions.enroute}
                                                                                            checked={hoursFromOptions.enroute === this.props.commissionRec.commStartFrom}
                                                                                            className="custom-control-input"
                                                                                            onChange={(e) => {
                                                                                                this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="rdFromEnroute">{hoursFromOptions.enroute}</label>
                                                                                    </div>
                                                                                    <div className="custom-control custom-radio mb-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="rdFromArrived"
                                                                                            name="commStartFrom"
                                                                                            // disabled={!this.state.enableHours}
                                                                                            value={hoursFromOptions.arrived}
                                                                                            checked={hoursFromOptions.arrived === this.props.commissionRec.commStartFrom}
                                                                                            className="custom-control-input"
                                                                                            onChange={(e) => {
                                                                                                this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="rdFromArrived">{hoursFromOptions.arrived}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 col-sm-12">
                                                                                    <label className="weight-600">To</label>
                                                                                    <div className="custom-control custom-radio mb-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="rdToHooked"
                                                                                            name="commEndBy"
                                                                                            checked={hoursToOptions.hooked === this.props.commissionRec.commEndBy}
                                                                                            //  disabled={!this.state.enableHours}
                                                                                            value={hoursToOptions.hooked}
                                                                                            className="custom-control-input"
                                                                                            defaultChecked={true}
                                                                                            onChange={(e) => {
                                                                                                this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="rdToHooked">{hoursToOptions.hooked}</label>
                                                                                    </div>
                                                                                    <div className="custom-control custom-radio mb-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="rdToDropped"
                                                                                            name="commEndBy"
                                                                                            //  disabled={!this.state.enableHours}
                                                                                            value={hoursToOptions.dropped}
                                                                                            checked={hoursToOptions.dropped === this.props.commissionRec.commEndBy}
                                                                                            className="custom-control-input"
                                                                                            onChange={(e) => {
                                                                                                this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="rdToDropped">{hoursToOptions.dropped}</label>
                                                                                    </div>
                                                                                    <div className="custom-control custom-radio mb-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="rdToCleared"
                                                                                            name="commEndBy"
                                                                                            // disabled={!this.state.enableHours}
                                                                                            value={hoursToOptions.cleared}
                                                                                            checked={hoursToOptions.cleared === this.props.commissionRec.commEndBy}
                                                                                            className="custom-control-input"
                                                                                            onChange={(e) => {
                                                                                                this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value)
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="rdToCleared">{hoursToOptions.cleared}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card box-shadow">
                                                                <div className="card-header">
                                                                    <div className="custom-control custom-checkbox mb-5">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="chPercentage"
                                                                            checked={this.props.commissionRec.commPercentAllCharges !== undefined ? true : false}
                                                                            onChange={(e) => {
                                                                                const fieldValue = e.target.checked ? '' : undefined
                                                                                this.props.onCommissionInfoFieldValueChange('commPercentAllCharges', fieldValue);
                                                                            }}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="chPercentage">PERCENTAGE</label>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-12">
                                                                            <div className="">
                                                                                <label className="col-form-label">PERCENTAGE</label>
                                                                                <div className="">
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        name="commPercentAllCharges"
                                                                                        placeholder="0.00" type="number"
                                                                                        disabled={this.props.commissionRec.commPercentAllCharges !== undefined ? false : true}
                                                                                        value={this.props.commissionRec.commPercentAllCharges || " "}
                                                                                        requiredmsg="Please enter Percentage"
                                                                                        //  validations={[required, isNumeric, isPercentage]}
                                                                                        onChange={(e) => {
                                                                                            this.props.onCommissionInfoFieldValueChange(e.target.name, e.target.value);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 mb-5">
                                    <div className="card box-shadow">
                                        <div className="card-body pl-2 pt-2 pr-2 pd-2">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group row">
                                                        <div className="col-sm-12 col-md-12 mb-5">
                                                            <div className="card box-shadow">
                                                                <div className="card-header">
                                                                    COMMISSIONABLE CHARGES
                                                            </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-12">
                                                                            <div className="custom-control custom-check mb-5">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id='chSelectAll'
                                                                                    //checked={this.state.chargeList.selectAll ? true : false}
                                                                                    name='chSelectAll'
                                                                                    className="custom-control-input"
                                                                                    onChange={(e) => {
                                                                                        // this.setState({selectAll:e.target.checked?true:false})
                                                                                    }}
                                                                                />
                                                                                <label className="weight-600">DEFAULT CHARGES</label>
                                                                                {
                                                                                    (this.props.commissionRec.defaultCharges || []).map((item, k) => {
                                                                                        return (
                                                                                            <div className="custom-control custom-checkbox mb-5">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    key={k}
                                                                                                    data-index={k}
                                                                                                    id={item.text + '-' + k}
                                                                                                    checked={item.isSelected}
                                                                                                    name={item.text}
                                                                                                    className="custom-control-input"
                                                                                                    onChange={(e) => {
                                                                                                        const index = e.target.getAttribute('data-index')
                                                                                                        let _defaultCharges = this.props.commissionRec.defaultCharges
                                                                                                        _defaultCharges[index].isSelected = e.target.checked ? true : false;
                                                                                                        this.props.onCommissionChargesChange('defaultCharges', _defaultCharges)
                                                                                                    }}
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor={item.text + '-' + k}>{item.text}</label>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <label className="weight-600">CUSTOM CHARGES </label>
                                                                                {
                                                                                    (this.props.userDefaultCharges || []).map((citem, key) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="custom-control custom-checkbox mb-5">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        key={key}
                                                                                                        data-index={key}
                                                                                                        id={citem.text + '-' + key}
                                                                                                        checked={citem.isSelected}
                                                                                                        name={citem.text}
                                                                                                        className="custom-control-input"
                                                                                                        onChange={(e) => {
                                                                                                            const index = e.target.getAttribute('data-index');
                                                                                                            let _userDefaultCharges = this.props.userDefaultCharges
                                                                                                            _userDefaultCharges[index].isSelected = e.target.checked ? true : false;
                                                                                                            this.props.onCommissionChargesChange('userDefaultCharges', _userDefaultCharges)
                                                                                                        }}
                                                                                                    />
                                                                                                    <label className="custom-control-label" htmlFor={citem.text + '-' + key}>{citem.text}</label>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </Form>
                </div></>
        )
    }
}

export default CommissionInfo;