import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment'
import StatusSearch from './statusSearch'
import {callStatus} from '../../../util/constants'
class TruckVisibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
       byStatus: callStatus,
      filterData:this.props.data||[]
    }
  }
  componentWillReceiveProps=(nextPros)=>{
    this.setState({filterData:this.filterList(nextPros.data||[])})
  }
  filterList=(allList)=>{
    const status=this.state.byStatus;
    let _byStatus=[];
    Object.keys(status).forEach(item=>{
      if(status[item].selected){
        _byStatus.push(item.toLowerCase())
      }
    })
    if(_byStatus.length===0)
    return allList;
    else{
      let filterData=[];
      filterData=allList.filter(item=>{
        return ( _byStatus.length>0?_byStatus.includes((item.truckStatus).toLowerCase()):true)
      });      
      return filterData;
    }
  }
  buildDataFormatForExport = () => {
    return (
      [{
        columns: [{ title: 'Truck' }, { title: 'Driver' }, { title: 'Status' }],
        data: this.props.data.map(item => {
          return [{ value: item.truckId || '' }, { value: item.userId || '' }, { value: item.truckStatus || '' }]
        })
      }]
    )
  }
  render() {
    const columns = [{
      Header: 'Truck',
      accessor: 'truckId',
      style: { textAlign: 'center' }
    },
    {
        Header: 'Driver',
        accessor: 'userId',
        style: { textAlign: 'center' }
    },
    {
        Header: 'Status',
        accessor: 'truckStatus',
        style: { textAlign: 'center' }
    },
    {
        Header: 'Actions',
        id: 'actions',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: row => (
           <button
            className="btn btn-sm btn-success" data-add-back-btn="true" hidden={!this.props.canlogout} onClick={(e)=>this.props.forceLogout(e,row)}
          >Force Logout</button>
        )
    }];
    return (
      <Fragment>
        <div className="row">
        <h4 className="col-md-11 mb-10">Driver Availability</h4>
        <div className="col-md-1 mb-10 text-right">
              <TmExportExcel
                  name={"Truck Visibility"}
                  filename={`${"Truck Visibility_"}${moment().format('MMDDYYhhmm')}`} 
                  fileExtension={'xlsx'}
                  element={<button className="btn btn-warning btn-sm" style={{  margin: '0' }}><i className="fa fa-file-excel-o"></i></button>}
                  dataSet={this.buildDataFormatForExport()}
                />
              </div>
          <div className="col-md-12 mb-10">
            <div className="row">
              <div className="col-md-12 mb-5">
              <StatusSearch key={'vehicle'} byType={'vehicle'} id={'vehicle'} label={'By Status:'} 
              searchObject={this.state.byStatus} 
              onChange={(newObj)=>{
                let _search=this.state.byStatus;
                _search=newObj;
                this.setState({byStatus:_search},()=>{
                  this.setState({filterData:this.filterList(this.props.data)})
                })
              }} />
              </div>
            </div>
            </div>
          </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-30">
            <TmReactTable
              filterable
              sortable
              resizable
              defaultPageSize={10}
              data={this.state.filterData}
              columns={columns}
            />
          </div></div>
      </Fragment>
    );
  }
}

export default TruckVisibility;
