import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { notify } from '../../../util/notifications';
import PaymentAuditCtrl from '../../../components/reportsComponents/accounting/paymentAuditCtrl';
import PaymentAuditTable from '../../../components/reportsComponents/accounting/paymentAuditTable';
import { fetchAllDriverList } from '../../../actions/reportAction/allinvoice/allinvoice';
import { initResponseState } from '../../../util/stateUtil/initialStateForComponents';
import { isManagement, getCompanyId } from '../../../util/customMethods';
import { getAuditReport ,cleanAuditReport ,emailResponseReport} from '../../../actions/reportAction/accounting/auditAction';
import { isValidForm } from '../../../util/validationMethods';
import { tableColumns } from '../../../util/tableColumnUtil/audit';
import printJS from 'print-js';
const pageTitle = 'Payment Audit Report';
const printJSHeaders = tableColumns.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
let ReportForm = {}

class PaymentAudit extends Component {
    state = {
        ...initResponseState
    }
    Go = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(getAuditReport(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success', res.statusMsg, this.props.refNotify);
                this.setState({ render: false });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    Export = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(getAuditReport(payload, status));
            if (res.statusCode === 200) {
                this.setState({ render: !this.state.render });
                notify.createNotification('success', 'Report exported successfully.', this.props.refNotify);
                this.setState({ render: false });
            } else {
                this.setState({ render: false });
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    Print = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(getAuditReport(payload, status));
            if (res.statusCode === 200) {
                printJS({
                    printable: this.props.auditReport || [],
                    properties: printJSHeaders,
                    type: 'json',
                    documentTitle: 'Response Time Report'
                });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    Email = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(emailResponseReport(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success', res.statusMsg, this.props.refNotify);
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    /* Building all invoice object for post request */
    buildObject = () => {
        const stateObj = this.state;
        const constractObj = {
            idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
            fromDate: stateObj.fromDate,
            toDate: stateObj.toDate,
            userId: stateObj.userId.replace(/\s*\[[^\]]*\]\s*/g, "") 
        };
        return constractObj;
    }


    componentWillMount = () => {
        document.title = pageTitle;
        this.props.dispatch(cleanAuditReport());
        this.props.dispatch(fetchAllDriverList());

    }
    onPaymentAuditFieldChange = (key, value) => {
        this.setState({ [key]: value });
    }
    render() {
        let stateObj = this.state;
        return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Payment Audit Report</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <PaymentAuditCtrl
                        Go={() => {
                            ReportForm.validateAll()
                            if (isValidForm(ReportForm)) {
                                this.Go();
                            }
                        }}
                        Export={() => {
                            ReportForm.validateAll()
                            if (isValidForm(ReportForm)) {
                                this.Export();
                            }
                        }}
                        Email={() => {
                            ReportForm.validateAll()
                            if (isValidForm(ReportForm)) {
                                this.Email();
                            }
                        }} Print={() => {
                            ReportForm.validateAll()
                            if (isValidForm(ReportForm)) {
                                this.Print();
                            }
                        }}
                        stateObj={stateObj} data={this.props.auditReport} cols={tableColumns}
                        allInvoiceObj={this.props.allInvoiceObj} childCompList={this.props.childCompList}
                        onPaymentAuditFieldChange={(key, value) => this.onPaymentAuditFieldChange(key, value)}
                        initCallBack={(frm) => {
                            ReportForm = frm;
                        }} fromdate={this.state.fromDate} todate={this.state.toDate} />
                    <PaymentAuditTable 
                    data={this.props.auditReport}
                    cols={tableColumns}/>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        allInvoiceObj: state.allInvoice,
        refNotify: state.refNotify,
        childCompList: state.searchInvoiceList.childComList,
        auditReport:state.auditObj.auditReport
    }
};

export default withRouter(connect(mapStateToProps)(PaymentAudit));
