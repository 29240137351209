import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomPDAllTowsCtrl from '../../../components/reportsComponents/custom/customPDAllTowsCtrl';
import CustomPDAllTowsTable from '../../../components/reportsComponents/custom/customPDAllTowsTable';
const pageTitle = 'Custom PD All Tows'
let CustomPDAllTowsForm={
	formCustomPDAllTowsInfo:{}
}

class CustomPDAllTows extends Component {
	constructor() {
		super();
		this.state = {
            fromDate:'',
            toDate:''
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		
    }
    onCustomPDAllTowsFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Custom PD All Tows</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <CustomPDAllTowsCtrl onCustomPDAllTowsFieldChange={(key, value)=>this.onCustomPDAllTowsFieldChange(key, value)}
                    initCallBack={(frm)=>{
						CustomPDAllTowsForm.formCustomPDAllTowsInfo=frm;
                    }} fromdate={this.state.fromDate} todate={this.state.toDate}/>
                    <CustomPDAllTowsTable />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(CustomPDAllTows));
