import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../util/constants';
import { isManagement} from '../../../util/customMethods';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
import {resColumns} from '../../../util/tableColumnUtil/summaryClms'
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ResponseTimeCtrl extends Component {
    render() {
      const {stateObj} = this.props;
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                    <Form ref={c => {
                                        this.formResponseTimeInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                        <div className="offset-2 col-2 form-group">
                                            {/* <div className="custom-control custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="rdType" id="inlineAllId" />
                                                <label htmlFor="inlineAllId" className="custom-control-label">All</label>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-2 form-group">
                                            <div className="custom-control custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="rdlateonly" id="inlineAll" checked={!stateObj.lateOnly} onChange={()=>{this.props.onResponseTimeFieldChange("lateOnly",!stateObj.lateOnly)}}/>
                                                <label htmlFor="inlineAll" className="custom-control-label">ALL</label>
                                            </div>
                                        </div>
                                        <div className="col-2 form-group">
                                            <div className="custom-control custom-control custom-radio">
                                                <label htmlFor="inlineUserName" className="">USER NAME</label>
                                            </div>
                                        </div>
                                        <div style={{ position:"relative", zIndex:"3" }} className="col-2 form-group">
                                        <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={(this.props.allInvoiceObj.driverList||[]).map((item, t) => {
                                                        return ({ value: item.empId, label: item.name })
                                                    })}
                                                    value={{
                                                        value: ((stateObj.empId || '').toString()),
                                                        label: ((this.props.allInvoiceObj.driverList||[]).find((e) => e.empId == (stateObj.empId || '').toString()) || {}).name
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onResponseTimeFieldChange('empId', parseInt(selectedOption&&selectedOption.value||0));
                                                        this.props.onResponseTimeFieldChange('accountId', 0);
                                                    }}
                                                />
                                        </div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="from" className="form-label col-form-label mr-2">FROM</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.fromdate === "" || this.props.fromdate === null
                                                ? null
                                                : moment(
                                                    this.props.fromdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="fromDate"
                                        reqmsg="From date is required"
                                        onDateSelectChange={e => {
                                            this.props.onResponseTimeFieldChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="ml-2 mr-2 form-group">
                                            <label htmlFor="to" className="form-label col-form-label mr-2">TO</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.todate === "" || this.props.todate === null
                                                ? null
                                                : moment(
                                                    this.props.todate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="toDate"
                                        reqmsg="To date is required"
                                        onDateSelectChange={e => {
                                            this.props.onResponseTimeFieldChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-2 form-group">
                                            <div className="custom-control custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="rdlateonly" id="inlineLateOnly" onChange={()=>{this.props.onResponseTimeFieldChange("lateOnly",!stateObj.lateOnly)}} checked={stateObj.lateOnly}/>
                                                <label htmlFor="inlineLateOnly" className="custom-control-label">LATE ONLY</label>
                                            </div>
                                        </div>
                                        <div className="col-2 form-group">
                                            <div className="custom-control custom-control custom-radio">
                                                <label htmlFor="inlineUserId" className="">ACCOUNT</label>
                                            </div>
                                        </div>
                                        <div style={{ position:"relative", zIndex:"2" }} className="col-2 ml form-group">
                                        <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={(this.props.accountList||[]).map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((stateObj.accountId || '').toString()),
                                                        label: ((this.props.accountList||[]).find((e) => e.optionVal == (stateObj.accountId || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onResponseTimeFieldChange('accountId', parseInt(selectedOption&&selectedOption.value||0));
                                                        this.props.onResponseTimeFieldChange('empId', 0);
                                                    }}
                                                />
                                        </div>
                                        <div className="col-1">
                                        </div>
                                        <div className="col-5 ml">
                                            <div className="form-group">
                                                <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                                onClick={this.props.Go}>Go</button>
                                                <button type="button" className="btn btn-warning custom-reports-btn mr-2" onClick={this.props.Export}>Export</button>
                                                {this.props.stateObj.render ?
                                                    <ExcelFile hideElement={true}
                                                        filename={`${"ResponseTimeReport"}${moment().format('MMDDYYhhmm')}`}
                                                        fileExtension={'xlsx'}
                                                    >
                                                        <ExcelSheet
                                                            data={this.props.data || []}
                                                            name={'Response Time Report'}
                                                        >
                                                            {(resColumns || []).map(column => {
                                                                return (
                                                                    <ExcelColumn
                                                                        key={column.accessor}
                                                                        label={column.Header}
                                                                        value={column.accessor}
                                                                    />
                                                                );
                                                            })}
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    : null}
                                                <button type="button" className="btn btn-primary custom-reports-btn mr-2" onClick={this.props.Email} >Email</button>
                                                <button type="button" className="btn btn-success custom-reports-btn mr-2" onClick={this.props.Print} >Print</button>
                                            </div>
                                        </div>
                                    </div>
                                    {isManagement()?<div className="form-row">
                                        <div className="offset-2 col-2 form-group">
                                            <div className="custom-control custom-control custom-radio">
                                                 <label htmlFor="inlineAccount" className="">COMPANY</label>
                                            </div>
                                        </div>
                                        <div style={{ position:"relative", zIndex:"1" }} className="col-md-2 form-group">
                                        <ReactSelect name="idCompany"
                                                    isClearable={true}
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    options={(this.props.childCompList||[]).map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((stateObj.idCompany || '').toString()),
                                                        label: ((this.props.childCompList||[]).find((e) => e.optionVal == (stateObj.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onResponseTimeFieldChange('idCompany', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                        </div>
                                    </div> :null}          
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default ResponseTimeCtrl;