import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required, isValidForm} from '../../../util/validationMethods';
import {editPermission} from '../../../util/customMethods';
class CustomCharge extends Component {
    constructor(props){
        super(props);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    handleSubmit=(event)=>{
        event.preventDefault();
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{
            this.props.onSubmitForm(event)
        }
    }
    render(){
        return(
        <Form ref={c => { this.form = c }}>
            <fieldset disabled={!editPermission(this.props.chargeObj.companyId)}>
            <div className="modal-body">
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <label className="form-control-label">Charge Name*</label>
                        <Input name="chargeName" type="text" className="form-control"  placeholder="" 
                        requiredmsg="Please enter Charge Name"
                        value={this.props.chargeObj.chargeName} onChange={(e)=>{
                            this.props.onFieldDataChange(e.target.name,e.target.value);
                        }} validations={[required]} />
                        
                    </div>
                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="isEnabled" checked={this.props.chargeObj.isEnabled==1?true:false} 
                        id="isEnabled" 
                        className="custom-control-input"
                        onChange={(e)=>{
                                this.props.onFieldDataChange(e.target.name,(e.target.checked?1:0))
                            }}/>
                            <label type="checkbox" htmlFor="isEnabled" className="custom-control-label">Enabled?</label>
                        </div>                 
                    </div>
                <div className="form-group">
                <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="isEnableForDriver" checked={this.props.chargeObj.isEnableForDriver==1?true:false} 
                        id="isEnableForDriver" 
                        className="custom-control-input"
                        onChange={(e)=>{
                            this.props.onFieldDataChange(e.target.name,(e.target.checked?1:0))
                        }}/>
                            <label type="checkbox" htmlFor="isEnableForDriver" className="custom-control-label">Driver Dropdown Enabled?</label>
                        </div>                       
                    </div>
                <div className="form-group">
                <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="addlActionForDriver" checked={this.props.chargeObj.addlActionForDriver==1?true:false} 
                        id="addlActionForDriver" 
                        className="custom-control-input"
                        onChange={(e)=>{
                            this.props.onFieldDataChange(e.target.name,(e.target.checked?1:0))
                        }}/>
                            <label type="checkbox" htmlFor="addlActionForDriver" className="custom-control-label">Additional Actions Required By Driver?</label>
                        </div>                  
                    </div>
                            {/* <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" name="isBillable" checked={this.props.chargeObj.isBillable == 1 ? true : false}
                                        id="isBillable"
                                        className="custom-control-input"
                                        onChange={(e) => {
                                            this.props.onFieldDataChange(e.target.name, (e.target.checked ? 1 : 0))
                                        }} />
                                    <label type="checkbox" htmlFor="isBillable" className="custom-control-label">Billable for monetization</label>
                                </div>
                            </div>     */}
                <div className="form-group">
                    <label className="form-control-label">Prompt Message</label>
                        <textarea style={{minHeight:'75px'}} className="form-control" name="promptMessage" value={this.props.chargeObj.promptMessage} onChange={(e)=>{
                            this.props.onFieldDataChange(e.target.name,e.target.value)
                        }}></textarea>                        
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" variant="secondary" className="btn btn-danger btn-sm" 
                onClick={(e)=>{this.props.onCancelForm(e)}}>Close</button>
                <button type="button" variant="primary" className="btn btn-success btn-sm" onClick={(e)=>{
                    this.handleSubmit(e);
                }}>{this.props.chargeObj.chargeListId>0?'Save Changes':'Save'}</button>
                </div>
            </div>
            </fieldset>
            </Form>
            )
    }
}
export default CustomCharge