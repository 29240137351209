import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';

export const getLocationList=(searchObj)=>{
    if(!searchObj){
        searchObj={searchText:''};
    }
    const _url=`config/getLocationDetailsBySearch?searchText=${searchObj.searchText}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async(dispatch)=>{
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.status===200){
                dispatch({
                    type: actionTypes.GET_ALL_LOCATIONS,
                    locationList:response.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_ALL_LOCATIONS,
                    locationList:undefined
                });
            }

        }catch(ex){
            dispatch({
                type: actionTypes.GET_ALL_LOCATIONS,
                locationList:undefined
            });
        }
    }
}
export const onLocationFieldUpdate=(key,value)=>{
    let _locationObj=Object.assign({},(store.getState()).location.locationObject) ;
    _locationObj[key]=value;
    return (dispatch)=>{
        dispatch({
            type: actionTypes.GET_SINGLE_LOCATION,
            locationObject:_locationObj
        })
    }
}
export const getLocationRecordByName=(locationName)=>{
    const _url=`config/getLocationByName?locationName=${locationName}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch)=>{
        try{
            if(locationName===''){
                dispatch({
                    type: actionTypes.GET_SINGLE_LOCATION,
                    locationObject:undefined
                });
            }else{
                const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
                if(response.status===200) {         
                    dispatch({
                        type: actionTypes.GET_SINGLE_LOCATION,
                        locationObject:response.data
                    });
                }else{
                    dispatch({
                        type: actionTypes.GET_SINGLE_LOCATION,
                        locationObject:undefined
                    });

                }
            }

        }catch(ex){
            dispatch({
                type: actionTypes.GET_SINGLE_LOCATION,
                locationObject:undefined
            });
        }
    }
}
export const onLocationSave=(action,locationObj)=>{
    return async(dispatch)=>{
            try{
                let _url=`config/updateLocationDetailsobj?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
                if(action==='EDIT'){
                    let _locationObj=Object.assign({},locationObj);
                    //dispatch(spinnerActions.showSpinner());
                    const response=await getAxiosInstance(moduleTypes.ADMIN).put(_url,_locationObj);
                    if(response.status===200) { 
                        dispatch(getLocationList({searchText:''}));
                        return {statusCode:200,statusMsg:response.data.message};
                    }else{
                        return {statusCode:null,statusMsg:response.data.message};
                    }
            }else{
                _url=`config/saveLocationType?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
                let _locationObj=Object.assign({},locationObj);
                _locationObj.cityName=[_locationObj.cityLocation]
                //dispatch(spinnerActions.showSpinner());
                const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,_locationObj);
                if(response.status===200) { 
                    dispatch(getLocationList({searchText:''}));
                    return {statusCode:200,statusMsg:response.data.message};
                }else{
                    return {statusCode:null,statusMsg:response.data.message};
                }

            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message};

        }
    }
}