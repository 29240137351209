import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';
import CommissionInfo from '../../components/adminComponents/commission/commissionInfo/commissionInfo';
import {
	getCommissionDetailRecord, onCommissionDetailsFieldChange,
	onCommissionSave, onCommissionChargesChange, onCommissionDetailObjectChanged
} from '../../actions/adminAction/commissionActions'
import { getChargeList } from '../../actions/adminAction/ratePlanActions';
import { notify } from '../../util/notifications';
import { getAccessLevelsByModule } from '../../util/customMethods';
let _type = '';
class CommissionRecPage extends Component {
	constructor() {
		super();
		this.bindCommissionDetails = this.bindCommissionDetails.bind(this);
		this.checkViewAccess = this.checkViewAccess.bind(this);
	}
	componentWillMount = () => {
		const queryValues = queryString.parse(this.props.location.search);
		this.bindCommissionDetails(queryValues.cTypeId, queryValues.dLId, queryValues.Type);
	}
	checkViewAccess = (_accessLevel = {}) => {
		if (_accessLevel.basicAccess.view !== true) {
			this.props.history.push('/admin/notfound');
		}
	}
	bindCommissionDetails = async (cTypeId, dLId, Type) => {
		this.cTypeId = cTypeId;
		this.dLId = dLId;
		_type = Type;
		const { dispatch } = this.props;
		await dispatch(getChargeList());
		await dispatch(getCommissionDetailRecord(cTypeId, dLId));
	}
	
	render() {
		const accessLevelObj = getAccessLevelsByModule('Commission');
		this.checkViewAccess(accessLevelObj)
		document.title=`${_type === 'add' ? 'Add Commission' : 'Edit Commission'}`
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-money"></i> {_type === 'add' ? 'Add Commission' : 'Edit Commission'}</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<CommissionInfo commissionRec={this.props.commission.commissionDetailObj}
						userDefaultCharges={this.props.commission.commissionDetailObj.userDefaultCharges}
						accessLevel={accessLevelObj || {}}
						onCommissionChargesChange={(key, list) => {
							this.props.dispatch(onCommissionChargesChange(key, list));
						}}
						onUpdateCommissionCharge={(commissionObj) => {
							this.props.dispatch(onCommissionDetailObjectChanged(commissionObj));
						}}
						onCommissionInfoFieldValueChange={(key, value) => {
							this.props.dispatch(onCommissionDetailsFieldChange(key, value));
						}}
						onSubmitCommissionRec={async(e, rec) => {
							rec.commissionTypeId = this.cTypeId;
							rec.driverLevelId = this.dLId
							const res=await this.props.dispatch(onCommissionSave(rec))
							if (res.statusCode===200) {
								notify.createNotification('success', res.statusMsg, this.props.refNotify);
								this.props.history.push('/admin/commission');
							}else {
								notify.createNotification('error', res.statusMsg, this.props.refNotify);
							};
						}}
					/>
				</div>

			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
		commission: state.commission
	}
};

export default withRouter(connect(mapStateToProps)(CommissionRecPage));
