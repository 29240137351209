import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import Attachments from './attachments/attachments';
import CoverSheets from './attachments/coversheets';
import TowSheets from './attachments/towsheets';

class Attachment extends Component {
    render() {
        let attachmentData = [];
        let attachmentCoverSheetData = [];
        const isDisabled = (!(this.props.invoicePermission.delete||false)) || (this.props.invoiceInfo._invoiceObj.invoiceBilling.isVerifiedAndLocked==1);
        this.props.data.map((item, i) => {
            item.attachmentPath.includes("coverpageattachment") ? attachmentCoverSheetData.push(item) : attachmentData.push(item)
        })
        return (<Fragment>
            <div className="pd-10">
                <Form className="">
                    <fieldset disabled={(this.props.invoiceInfo._invoiceObj.callStatus=='Cancel')}>
                        <h6 className="text-blue text-bg-color form-group">Attachments</h6>
                        <Attachments data={attachmentData} getFiles={(fileName, fileList) => this.props.getFilesList(fileName, fileList)}
                            uploadAttachments={(name) => this.props.uploadAttachments(name)} isDisabled={isDisabled}
                            deleteAttachmentModal={(type,attachId) => this.props.deleteAttachmentModal(type,attachId)} />
                        <h6 className="text-blue text-bg-color form-group">Cover Sheets</h6>
                        <CoverSheets data={attachmentCoverSheetData} getFiles={(fileName, fileList) => this.props.getFilesList(fileName, fileList)}
                            uploadCoverSheetsAttachments={(name) => this.props.uploadAttachments(name)} isDisabled={isDisabled}
                            deleteAttachmentModal={(type,attachId) => this.props.deleteAttachmentModal(type,attachId)}/>
                        <h6 className="text-blue text-bg-color form-group">Tow Sheets</h6>
                        <TowSheets data={this.props.vendorAttachments} getFiles={(fileName, fileList) => this.props.getFilesList(fileName, fileList)}
                            uploadTowSheetsAttachments={(name) => this.props.uploadAttachments(name)} isDisabled={isDisabled}
                            deleteAttachmentModal={(type,attachId) => this.props.deleteAttachmentModal(type,attachId)}/>
                    </fieldset>
                </Form>
            </div>
        </Fragment>);
    }
}
export default Attachment
