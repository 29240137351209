import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required, email, isValidForm} from '../../../../util/validationMethods';
import TmSlimSelect from '../../../utilComponents/tmSlimSelect/tmSlimSelect'
class VendorInfo extends Component {
        state = {
            validated: false 
        } 
       
    handleSubmit=async (event)=>{
        event.preventDefault();
        this.form.validateAll();
        if(!isValidForm(this.form)){
        }else{
            if(this.props.action==='NEW'){
                 let resp=await this.props.isValidUser(event,this.props.vendor.vendorEmailId);
                    if(resp){
                        this.props.onVendorSubmit();
                    }else{
                        this.form.showError(this.userInput, <span className="has-danger">Sorry, email already exists</span>);
                    }
            }else{
                this.props.onVendorSubmit();
            }
                      
        }
    }
    checkAccess=()=>{
        if(this.props.action==='NEW'){
            if((this.props.accessLevel.basicAccess.add||false)===true){
                return false
            }else{
                return true
            }
        }else{
            if((this.props.accessLevel.basicAccess.edit||false)===true){
                return false;
            }else{
                return true;
            }
        }
    }
    removeApiError = () => {
        this.form.hideError(this.userInput);
    };
    render() {
        const { validated } = this.state;
        const {data}=this.props;
        return (<Fragment>
            <div className="pd-10">
                <div className="row mb-10">
                    <div className="col-md-6 offset-3 col-sm-12 mb-5">
                        <div className="card box-shadow">
                            <div className="card-body">
                                <Form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>
                                    <div className="col-sm-12"> 
                                    <fieldset disabled={this.checkAccess()}>
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="vendor-first-name" className="form-label col-form-label">First Name</label>
                                                <div>
                                                    <Input placeholder="" value={this.props.vendor.vendorFirstName} onChange={(e) => {
                                                        this.props.onVendorFieldChange(e.target.name,e.target.value);
                                                    }}
                                                    validations={[required]} type="text" name="vendorFirstName" className="form-control form-control-sm"/>                     
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="vendor-last-name" className="form-label col-form-label">Last Name</label>
                                                <div className="">
                                                    <Input placeholder="" value={this.props.vendor.vendorLastName} onChange={(e) => {
                                                      this.props.onVendorFieldChange(e.target.name,e.target.value);
                                                        }}
                                                    validations={[required]} type="text" name="vendorLastName" className="form-control form-control-sm"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="vendor-email" className="form-label col-form-label">Email ID</label>
                                                <div className="">
                                                    <Input placeholder="" disabled={this.props.action==='NEW'?false:true} value={this.props.vendor.vendorEmailId} onChange={(e) => {
                                                      //e.preventDefault();
                                                      this.props.onVendorFieldChange(e.target.name,e.target.value);
                                                        }}
                                                    validations={[email, required]} ref={c => { this.userInput = c }} onFocus={this.removeApiError} type="text" name="vendorEmailId" className="form-control form-control-sm"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="vendor-select-account" className="form-label col-form-label">Vendor Accounts </label>
                                                <div className="">
                                                    <TmSlimSelect id={'vendorAccountIds'} selectedValues={(this.props.vendor.accountIds||'')} name="accountIds" dataSet={data} 
                                                    textField={'accountName'} valueField={'accountId'} 

                                                    onSelectionChange={(e)=>{ 
                                                        if(e.length>0){
                                                            let vendorValue = [];
                                                            e.map((key, index) => { 
                                                                vendorValue.push(key.value);
                                                            })
                                                            this.props.onVendorFieldChange("accountIds",vendorValue.toString());
                                                        }
                                                    }} />
                                                </div>                                              
                                            </div>
                                        </div>                                        
                                        </fieldset>
                                        <div className="row">
                                        <div className="col-sm-6 text-left">
                                        {
                                            this.props.action==='NEW'?null:<>
                                            <span><i className={this.props.vendor.isActive?"fa fa-circle text-success":"fa fa-circle text-danger"}></i> {this.props.vendor.isActive?'Active ':'Inactive '}</span>
                                            <button type="button"
                                            className={ `btn btn-sm ${this.props.vendor.isActive ? 'btn-danger' : 'btn-success'}`} onClick={(e)=>{                                               
                                                let confirmationMsg = window.confirm(`Do you want to ${this.props.vendor.isActive ? 'deactivate' : 'activate'} this vendor user?`);
                                                    if(confirmationMsg){
                                                        this.props.activeInactiveVendorOnClick(e,this.props.vendor.vendorId,(!this.props.vendor.isActive));
                                                    } 
                                                }}>{this.props.vendor.isActive ? 'Deactivate' : 'Activate'}</button>
                                            </>
                                        }
                                            
                                        </div>
                                            <div className="col-sm-6 text-right">                                              
                                                    <button type="submit" disabled={this.checkAccess()} 
                                                    className="btn btn-success btn-sm mr-2">{this.props.action==='NEW'?'Add':'Update'}</button>
                                                    <button type="button" className="btn btn-secondary btn-sm" onClick={(e)=>{
                                                        this.props.cancelVendorOnClick();
                                                    }}> Cancel</button>                                               
                                            </div>                                     
                                        </div> 
                                    </div>           
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>);
    }
}
export default withRouter(VendorInfo);