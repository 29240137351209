import 'babel-polyfill'; 
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Provider } from 'react-redux';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import 'popper.js/dist/popper'
 import 'bootstrap/dist/css/bootstrap.css'; 
import './assets/css/style.css';
import {store, persistor} from './store/configureStore';
import App from './App';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(
<PersistGate persistor={persistor}>
    <Provider store={store}>
        <App />
    </Provider>
</PersistGate>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
