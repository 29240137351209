import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CRDetailCtrl from '../../../components/reportsComponents/monetization/crDetailCtrl';
import {isValidForm} from '../../../util/validationMethods';
import { getCompanyId, isManagement } from '../../../util/customMethods';
import {getComapanyTypes} from '../../../actions/adminAction/dropDownDataAction'
import ReactTableDisplay from '../../../components/reportsComponents/commonComponents/crDetailReportTableConfig';
import { tableColumns } from '../../../components/reportsComponents/monetization/crDetailClmns/table-column.config';
import {getCRDetailsList, getClearCRDetailsList} from '../../../actions/reportAction/monetization/crDetailAction';
const pageTitle = 'C/R Details';
let crDetailForm={
	formCRDetailInfo:{}
}
class CRDetails extends Component {
	constructor() {
		super();
		this.state = {
            asOfDate:'',
            idCompany:'',
            companyType:'',
            exportDataSet:[],
            render: false,
            dataFetched:false
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
        this.props.dispatch(getClearCRDetailsList());
        if(isManagement()){
            this.props.dispatch(getComapanyTypes());
        }
    }
    onCRDetailFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
    getByCompanyId=()=>{
        let id_company=0;
        if(!isManagement()){
            id_company=getCompanyId();
        }else{
            id_company=this.state.idCompany;
        }
        return id_company;
    }
    getCRDetails=(e)=>{
        e.preventDefault();
        crDetailForm.formCRDetailInfo.validateAll();
        if(isValidForm(crDetailForm.formCRDetailInfo)){
            this.props.dispatch(getCRDetailsList(this.state.asOfDate, this.getByCompanyId(),this.state.companyType))
            .then(() => {this.setState({ dataFetched: true })})   
            .catch(err => {
                console.error('fetchReport methods failed with error: ', err);
            });
        }
    }
    setReferenceToReactTable = ref => {
        this.reactTable = ref;
    };

    tableDataChanged = () => {
        console.log('table data has changed!');
        this.setState({
            data: this.reactTable.getResolvedState().sortedData
        });
    };
    exportToExcel = e => {
        e.preventDefault();
        
    }
    printReport = (e) =>{
        e.preventDefault();
    }
	render() {
        const {managedCompanyReceivableDetailList, retailCompanyReceivableDetailList} = this.props.crReportResponse || [];
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Company Receivable Details</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <CRDetailCtrl
                    idCompany={this.state.idCompany}
                    childCompList={this.props.childCompList}
                    companyType={this.state.companyType}
                    companyTypes={this.props.companyTypes}
                    initCallBack={(frm)=>{
						crDetailForm.formCRDetailInfo=frm;
                    }} asofdate={this.state.asOfDate}
                    onCRDetailFieldChange={(key, value)=>this.onCRDetailFieldChange(key, value)}
                    getCRDetails={(e)=>this.getCRDetails(e)}
                    exportCRDetailsToExcel={(e)=>this.exportToExcel(e)}
                    printCRDetailsReport={(e)=>this.printReport(e)}/>
                    
                    {this.state.dataFetched &&
                    <Fragment>
                        {(managedCompanyReceivableDetailList.length != 0) && 
                        <Fragment>
                            <h6 className="text-blue text-bg-color form-group">Invoice Receivables:</h6>
                            <ReactTableDisplay
                                data={managedCompanyReceivableDetailList}
                                tableDataChanged={this.tableDataChanged}
                                tableReference={this.setReferenceToReactTable}
                                columns={tableColumns}
                                type="managed"
                                onExpandedChange={this.props.onExpandedChange}
                            />
                        </Fragment>
                        }
                        {(retailCompanyReceivableDetailList.length != 0) &&
                        <Fragment>
                            <h6 className="text-blue text-bg-color form-group mt-5">Monthly Receivables:</h6>
                            <ReactTableDisplay
                                data={retailCompanyReceivableDetailList}
                                tableDataChanged={this.tableDataChanged}
                                tableReference={this.setReferenceToReactTable}
                                columns={tableColumns}
                                type="retail"
                                onExpandedChange={this.props.onExpandedChange}
                            />
                        </Fragment>
                        }
                    </Fragment>
                    }
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        crReportResponse: state.crDetailsList.crDetails,
        childCompList:state.searchInvoiceList.childComList,
        companyTypes:state.companyTypes.companyType
	}
};

export default withRouter(connect(mapStateToProps)(CRDetails));
