import React, { Component, Fragment } from 'react';
import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.min.css';
let selectedValues=[]
class TmSlimSelect extends Component {
    constructor(props){
        super(props);

    }
    // componentWillMount=()=>{
    //     selectedValues=this.props.selectedValues===""?[]:this.props.selectedValues.split(',')
    //     this.slimSelect.set(selectedValues)
    // }
    componentDidMount=()=>{
        this.slimSelect=new SlimSelect({
            select: '#'+this.props.id,
            beforeOnChange:this.props.onSelectionChange
          });
          selectedValues=this.props.selectedValues===""?[]:this.props.selectedValues.split(',')
          this.slimSelect.set(selectedValues)
    }
    componentWillReceiveProps=(nextProps)=>{
        if(selectedValues.join(',')!==nextProps.selectedValues){
            let _selectedValue=nextProps.selectedValues===''?[]:nextProps.selectedValues.split(',')
            selectedValues=_selectedValue
            this.slimSelect.set(_selectedValue)
        }
    }
    render(){
       return(<>
        <select {...this.props} id={this.props.id} name={this.props.name} multiple >
        {this.props.dataSet.map((state, key) => {
             return (
                <option key={key} value={state[this.props.valueField]||''}>
                    {state[this.props.textField]||''}
                </option>
                );})
        }
        </select>
       </>) 
    }
}
export default TmSlimSelect;
