import React, { Component } from 'react';
import {connect} from  'react-redux';
import { Link } from 'react-router-dom';
import * as dispatchActions from '../../../actions/launchAction/dispatchDashboardActions';
const dispatchDashboardTailsTitles={
  TrucksAvailable:'Trucks available',
  UnassignedCalls:'Unassigned calls',
  TripsCompleted:'Trips completed',
  InProgress:'In Progress'
}
class Dispatcher extends Component {
      
      componentDidMount=()=>{
        this.bindData();
      }
      bindData=async()=>{
        const {dispatch} =this.props;
        await dispatch(dispatchActions.getSummaryDashboard())
      }   
    render() {
      const { dispatchDashboard }=this.props;
        return ( 
           
             <>
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.TrucksAvailable.toLowerCase()] && dispatchDashboard.hasOwnProperty("truckAvailable")?
               <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                          <div class="card-header" data-background-color="green"><i style={{width:'42px'}} class="fa fa-2x fa-truck" aria-hidden="true"></i></div>
                          <div class="card-content">
                              <p class="category">Trucks available</p>
                              <span class="title" style={{fontSize: '25px'}}>{dispatchDashboard.truckAvailable || 0}</span>
                          </div>                       
                      </div> 
                    </div>:null
             }
             
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.UnassignedCalls.toLowerCase()]?
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                          <div class="card-header" style={{backgroundColor:'#B1D34A'}} ><i style={{width:'42px'}} class="fa fa-2x fa-phone" aria-hidden="true"></i></div>
                          <div class="card-content">
                              <p class="category">Unassigned invoices</p>
                              <span class="title" style={{fontSize: '25px'}}>{dispatchDashboard.unassignedInvoice || 0}</span>
                          </div>                       
                      </div> 
                    </div>:null
             }
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.TripsCompleted.toLowerCase()]?
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                          <div class="card-header" style={{backgroundColor:'#AB3F97'}}><i style={{width:'42px'}} class="fa fa-2x fa-th-list" aria-hidden="true"></i></div>
                          <div class="card-content">
                              <p class="category">Invoices completed</p>
             <span class="title" style={{fontSize: '25px'}}>{dispatchDashboard.todayCompletedCall || 0}</span>
                          </div>                       
                      </div> 
                    </div> :null
             }
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.InProgress.toLowerCase()]?                
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                        <div class="card-header" style={{backgroundColor:'#FFC233'}}><i style={{width:'42px'}} class="fa fa-2x fa-tasks" aria-hidden="true"></i></div>
                        <div class="card-content">
                            <p class="category">Invoices in progress</p>
                            <span class="title" style={{fontSize: '25px'}}>{dispatchDashboard.inprogressInvoice || 0}</span>
                        </div>                       
                      </div> 
                    </div>:null
             }
                    </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
  return {
      authInfo: state.authInfo,
      dispatchDashboard:state.dispatchDashboard.summaryData || {}
    }
};

export default connect(mapStateToProps)(Dispatcher);