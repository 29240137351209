import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {authenticateUser, forgetPassword} from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";
import {required,email} from '../../util/validationMethods.js'
import TmInputGroup from '../../components/utilComponents/tmInputGroup/tmInputGroup'
import Background from '../../assets/img/bg_body.jpg';
import { forgetOptions } from '../../util/constants';
import './login.css';
const pageTitle="Account Recovery";
class ForgetPassword extends Component {
    constructor() {
        super();
        this.state = {
            emailId: "",
            forgetOption: forgetOptions.FORGET_USER
        }
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    onChangeValues = (event) => {
        let _state = this.state;
        _state[event.currentTarget.name] = event.currentTarget.value;
        this.setState(_state);
    }
    handleSubmit = async () => {   
        const { dispatch } = this.props;
        let res=await dispatch(forgetPassword(this.state.emailId,this.state.forgetOption));
        if(res.statusCode===200){
            notify.createNotification('success',res.message,this.props.refNotify);
        }
        else{
             notify.createNotification('error',res.statusMsg,this.props.refNotify);
        }
    } 
    render() {        
        return (<>
        <section className="wrapper" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat,no-repeat",
                backgroundPosition: "center center,center center",
                backgroundSize: "cover,cover"
            }}>
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center pd-20">
                <div className="login-box bg-white box-shadow pd-20 border-radius-5">
			        <img src={require('../../assets/images/SVMS Logo Final-A-FULL-MED.png')} alt="ACT" className="login-img"/>
			        <h6 className="text-center mb-10"><strong>Account Recovery</strong></h6>
                    <Form name="frmForgot" className="mb-lg">
                        <Fragment>
                        <div className="row">
                            <div className="col-sm-12 mb-10">
                                <div className="custom-control custom-control custom-radio">
                                    <input type="radio" className="custom-control-input" name="forgetOption" id="fguser" value={forgetOptions.FORGET_USER} 
                                     checked={forgetOptions.FORGET_USER===this.state.forgetOption} onChange={(e)=>{
                                        this.onChangeValues(e);
                                    }}/>
                                    <label htmlFor="fguser" className="custom-control-label">Forgot User Name</label>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-10">
                                <div className="custom-control custom-control custom-radio">
                                    <input type="radio" className="custom-control-input" name="forgetOption" id="fgpwd" value={forgetOptions.FORGET_PASS} checked={forgetOptions.FORGET_PASS===this.state.forgetOption} onChange={(e)=>{
                                this.onChangeValues(e);
                            }}/>
                                    <label htmlFor="fgpwd" className="custom-control-label">Forgot Password</label>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-10">
                                <div className="custom-control custom-control custom-radio">
                                    <input type="radio" className="custom-control-input" name="forgetOption" id="fgboth" value={forgetOptions.FORGET_BOTH} checked={forgetOptions.FORGET_BOTH===this.state.forgetOption} onChange={(e)=>{
                                this.onChangeValues(e);
                            }}/>
                                    <label htmlFor="fgboth" className="custom-control-label">Forgot Both (User Name and Password)</label>
                                </div>
                            </div>
                        </div>
                        <div className="input-group custom input-group-lg">
                            <TmInputGroup id="userEmailId" name="emailId" type="text" placeholder="Enter User Email Id"
                                className="form-control" value={this.state.emailId} validations={[required,email]} onChange={(e) => {
                                    this.onChangeValues(e);
                            }} requiredmsg="User email is required!" append='true' appendText={
                            <div className="input-group-append custom">
                                <span className="input-group-text"><i className="fa fa-user" aria-hidden="true"></i></span>
                            </div>} />                                               
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="input-group">                                        
                                    <Button type="button" onClick={()=>{
                                        this.handleSubmit();
                                    }} className="btn btn-outline-primary btn-lg btn-block" >Submit </Button>
                                </div>
                                <div className="input-group">                                        
                                    <input type="button" value="Back" style={{textTransform:'inherit'}} onClick={()=>{
                                        this.props.history.push('/login')
                                    }} className="btn btn-outline-primary btn-lg btn-block" />
                                </div>
                            </div>
                        </div>                            
                        </Fragment>                                    
                    </Form>
                </div>
            </div>
            </section>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        refNotify:state.refNotify
    }
};

export default withRouter(connect(mapStateToProps)(ForgetPassword))