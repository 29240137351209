import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { initInvoiceManageState} from '../../../util/stateUtil/initialStateForComponents'
import MissingPOReportCtrl from '../../../components/reportsComponents/invoicemanagement/missingPOReportCtrl';
import MissingPOReportTable from '../../../components/reportsComponents/invoicemanagement/missingPOReportTable';
const pageTitle = 'Missing Po Number'
class MissingPOReport extends Component {
	state = {
        ...initInvoiceManageState
    }
	componentWillMount = () => {
		document.title = pageTitle;
		
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Missing Po Number</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    {/* <MissingPOReportCtrl 
                    initCallBack={(frm)=>{
						MissingPOReportForm.formMissingPOReportInfo=frm;
                    }}/> */}
                    <MissingPOReportCtrl />
                    <MissingPOReportTable /> 
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(MissingPOReport));
