import React, { Component } from 'react';
import {connect} from  'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import TmExportExcel from '../../../../utilComponents/tmExportExcel/tmExportExcel';
class AccountsWithoutNewInvoicesDetails extends Component {
    constructor() {
        super();
        this.state = {
            excelDataSet:[]
        };
        this.buildDataFormatForExport=this.buildDataFormatForExport.bind(this);
      }
      componentWillMount=()=>{
        this.setState({excelDataSet:this.props.adminDashboard_inv_morethan.invoiceMoreThan});
      }
      componentWillReceiveProps=(nextProps)=>{
        this.setState({excelDataSet:nextProps.adminDashboard_inv_morethan.invoiceMoreThan});
      }
      buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'Account Name'},{title:'Account Type'},{title:'Last New Invoice Creation Date'},{title:'Days Without New Invoice'}],
          data: this.state.excelDataSet.map((item)=>{
            return [{value:item.accountName || ''},{value:item.accountType || ''},{value:item.invoiceCreateDate || ''},{value:item.withoutNewInvoiceDays || ''}]
          })
        }])
      }
    render() {
        const { invoiceMoreThan } = this.props.adminDashboard_inv_morethan;

        const isNumber=(input)=>{
          if(String(input).match(/^-{0,1}\d+$/)){
              return true
            }else if(String(input).match(/^\d+\.\d+$/)){
              return true
            }else{
              return false
            }
        }

        return (
          <>
          <div className="page-header">
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12">
                        <div className="title">
                              <h4><i className="fa fa-search"></i> Account Without New Invoices for {this.props.adminDashboard_Inv_More_Days} days or more</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
            <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <div>
                    <Link class="btn btn-info " to="/admin-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                    <TmExportExcel name={'Account Without New Invoices'} filename={'Account_Without_New_Invoices'} 
                    element={<button className="btn btn-warning btn-sm pull-right"><i className="fa fa-file-excel-o"></i> Export</button>}
                    dataSet={this.buildDataFormatForExport()} />
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <ReactTable ref={(t)=>this.invoiceTable=t}
                        data={invoiceMoreThan}
                        columns={[
                            {
                                Header:'Account Name',
                                accessor:'accountName'
                            },
                            {
                                Header:'Account Type',
                                accessor:'accountType'
                            },
                            {
                                Header:'Last New Invoice Creation Date',
                                accessor:'invoiceCreateDate'
                            },
                            {
                                Header:'Days Without New Invoice',
                                accessor:'withoutNewInvoiceDays'
                            }
                        ]}
                        onFilteredChange={()=>{
                            let invoiceMoreThan=this.invoiceTable.getResolvedState().sortedData;
                            this.setState({excelDataSet:invoiceMoreThan});
                          }}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        filterable="true"
                        defaultFilterMethod={(filter, row)=> {
                          const id = filter.pivotId || filter.id;
                          return (
                            row[id] !== undefined && row[id] !== null ? isNumber(row[id]) ? (row[id].toString()).includes(filter.value) 
                            : String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                            : true
                          )}}
                        />
                    </div>  
            </div> 
            </div> 
            </>                                                    
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        adminDashboard_inv_morethan: state.adminDashboard,
        adminDashboard_Inv_More_Days: state.adminDashboard.invoiceDays,  
    }
};
export default connect(mapStateToProps)(AccountsWithoutNewInvoicesDetails);
