import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import Home from './home'
import Login from './containers/authcomponents/login';
import ForgetPassword from './containers/authcomponents/forgetpassword';
import ResetPassword from './containers/authcomponents/resetPassword';
import InvoicePayment from './containers/dispatcher/InvoicePayment';
import * as notificationActions from './actions/notificationActions';
import{PrivateRoute}  from './components/route/privateRoute';
import {Spinner} from 'react-redux-spinner';
import './assets/css/nprogress.css';
class App extends React.Component {
  constructor(props){
    super(props);
    
  }
  componentWillMount=()=>{
  }  
  componentDidMount= ()=> {
    const {dispatch} =this.props
    this._notificationSystem = this.refs.notificationSystem;
    dispatch(notificationActions.setNotificationRef(this.refs.notificationSystem));
  }
  render(){
  return (
    <>
      <NotificationSystem ref="notificationSystem" />
      <Spinner />
        <Router>
          <Switch> 
          <Route exact path="/login" component={Login} />
          <Route exact path="/accountrecovery" component={ForgetPassword} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <PrivateRoute path="/payment" component={InvoicePayment} /> 
          <PrivateRoute path="/" component={Home} />                       
            
          </Switch>
      </Router>
    </>
  );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
    refNotify:state.refNotify
  }
};
export default connect(mapStateToProps)(App);
