import React, { Component, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import ImageZoom from 'react-medium-image-zoom';
import {
  required,
  email,
  isValidEmail,
  isValidForm,
  zipValidation,
} from "../../../../util/validationMethods";
import profile from "../../../../assets/images/default-avatar.png";
import { isManagement } from "../../../../util/customMethods";
class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: profile
    };
    this.handleFileOnChange = this.handleFileOnChange.bind(this);
    this.checkAccess=this.checkAccess.bind(this);
  }
  handleFileOnChange = event => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    });
  };
  checkAccess=()=>{
    if(this.props.empRec.employeeInformation.employeeId===0){
        if((this.props.accessLevel.basicAccess.add||false)===true){
            return false
        }else{
            return true
        }
    }else{
        if((this.props.accessLevel.basicAccess.edit||false)===true){
            return false;
        }else{
            return true;
        }
    }
}
validateLocationAddress=(key)=>{
  let loc = (this.props.locations.find(loc => loc.location === this.props.empRec.employeeInformation.officeLocation))||{};
      let addrObj= this.props.empRec.employeeInformation;
      let locAddr={
        officeLocation:loc.location||"",
        officeAddressLine1: loc.addrLine1 || "",
        officeAddressLine2: loc.addrLine2 || "",
        officeCity: loc.cityLocation || "",
        officeState: loc.state || "",
        officeZipcode: loc.zip || "",
        latLong:loc.latLong||""
      }
      if((addrObj[key]||'').trim().toUpperCase()!==(locAddr[key]||'').trim().toUpperCase()){
          this.props.onFieldValueChange('officeLocation','');
          this.forceUpdate();
      }   
      
}
getStatus=()=>{
  return this.props.empRec.employeeInformation.neverExpire;
}
getImageUrl=(url)=>{
  if((url || '').includes('/actstageemployeeimages')){
    return `${url}?${(new Date()).getTime()}`;
  }else{
    return url;
  }

}
  render() {
    const { employeeInformation } = this.props.empRec;
    return (
      <Fragment>
        <div className="pd-10">
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
              <Form
                ref={c => {
                  this.formBasicInfo = c;
                  this.props.initCallBack(c);
                }}
              >
              <fieldset disabled={this.checkAccess()}>
                
                <div className="row">
                  <div className="col">
                  <h6 className="text-blue text-bg-color">PROFILE</h6>
                    <div className="">
                      <label
                        htmlFor="emp-first-name"
                        className="form-label col-form-label"
                      >
                        FIRST NAME
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="firstName"
                          type="text"
                          value={employeeInformation.firstName}
                          id="emp-first-name"
                          validations={[required]}
                          requiredmsg="Please enter employee first name"
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  
                    <div className="">
                      <label
                        htmlFor="emp-last-name"
                        className="form-label col-form-label"
                      >
                        LAST NAME
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          type="text"
                          name="lastName"
                          requiredmsg="Please enter employee last name"
                          value={employeeInformation.lastName}
                          id="emp-last-name"
                          className="form-control form-control-sm"
                          validations={[required]}
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="emp-email"
                        className="form-label col-form-label"
                      >
                        EMAIL ID
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="emailId"
                          value={employeeInformation.emailId}
                          type="text"
                          id="emp-email"
                          className="form-control form-control-sm"
                          requiredmsg="Please enter valid Email-ID"
                          validations={[required, email, isValidEmail]}
                          isValid={this.props.isEmailExits}
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                          onBlur={e =>{
                            e.preventDefault();
                            this.props.emailExits(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <h6 className="text-blue text-bg-color mb-10 text-left">
                    EMPLOYEE IMAGE
                    </h6>
                    <ImageZoom
                      image={{
                        src:`${this.getImageUrl(employeeInformation.imageURL)}`,
                        alt: `${employeeInformation.firstName} ${employeeInformation.lastName}`,
                        style:{maxWidth:'300px',minWidth:'150px', maxHeight:'150px', minHeight:'75px'},
                        className:"img-thumbnail form-group"
                      }}
                      zoomImage={{
                        src:`${this.getImageUrl(employeeInformation.imageURL)}`,
                        alt: `${employeeInformation.firstName} ${employeeInformation.lastName}`,
                        className: "img--zoomed"
                      }}
                    />
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <input
                          placeholder=""
                          type="file" accept="image/png, image/jpeg"
                          id="emp-upload-file"
                          className="form-control-file"
                          onChange={(e)=>{
                            this.props.onProfileImageUpload(e.target.files[0])
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                  <h6 className="text-blue text-bg-color">OFFICE ADDRESS</h6>
                  {isManagement()?null:<div className="">
                          <label
                            htmlFor="ofc-loc-name"
                            className="form-label col-form-label"
                          >
                            LOCATION
                          </label>
                          <div className="">
                            <Select
                              id="add-info-name"
                              name="officeLocation"
                              value={employeeInformation.officeLocation||''}
                              className="form-control form-control-sm"
                              onChange={e => {
                                let loc = (this.props.locations.find(loc => loc.location === e.target.value))||{};
                                            let empObj=  {
                                                    ...employeeInformation,
                                                    officeLocation:loc.location||"",
                                                    officeAddressLine1: loc.addrLine1 || "",
                                                    officeAddressLine2: loc.addrLine2 || "",
                                                    officeCity: loc.cityLocation || "",
                                                    officeState: loc.state || "",
                                                    officeZipcode: loc.zip || "",
                                                    latLong:loc.latLong||""
                                                }                
                                this.props.onEmpLocationChange(empObj);
                              }}
                            >
                              <option value=""></option>
                              {this.props.locations.map((loc, k) => {
                                return (
                                  <option key={k} value={loc.location}>
                                    {loc.location}
                                  </option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>}
                        <div className="">
                          <label
                            htmlFor="ofc-addr-ln1"
                            className="form-label col-form-label"
                          >
                            ADDRESS LINE 1
                          </label>
                          <div className="">
                            <Input
                              placeholder=""
                              name="officeAddressLine1"
                              value={employeeInformation.officeAddressLine1}
                              type="text"
                              id="ofc-addr-ln1"
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              onBlur={(e)=>{
                                this.validateLocationAddress(e.target.name)
                            }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label
                            htmlFor="ofc-addr-ln2"
                            className="form-label col-form-label"
                          >
                            ADDRESS LINE 2
                          </label>
                          <div className="">
                            <Input
                              placeholder=""
                              name="officeAddressLine2"
                              value={employeeInformation.officeAddressLine2||''}
                              type="text"
                              id="ofc-addr-ln2"
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              onBlur={(e)=>{
                                this.validateLocationAddress(e.target.name)
                            }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label
                            htmlFor="ofc-city-name"
                            className="form-label col-form-label"
                          >
                            CITY
                          </label>
                          <div className="">
                            <Input
                              placeholder=""
                              name="officeCity"
                              value={employeeInformation.officeCity}
                              type="text"
                              id="ofc-city-name"
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              onBlur={(e)=>{
                                this.validateLocationAddress(e.target.name)
                            }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label
                            htmlFor="ofc-state-name"
                            className="form-label col-form-label"
                          >
                            STATE
                          </label>
                          <div className="">
                            <Select
                              id="add-info-name"
                              name="officeState"
                              value={employeeInformation.officeState}
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              onBlur={(e)=>{
                                this.validateLocationAddress(e.target.name)
                            }}
                            >
                              <option value="" />
                              {this.props.states.map((loc, k) => {
                                return (
                                  <option key={k} value={loc.optionVal}>
                                    {loc.optionTxt}
                                  </option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="">
                          <label
                            htmlFor="ofc-zip-code"
                            className="form-label col-form-label"
                          >
                            ZIP CODE
                          </label>
                          <div className="">
                            <Input
                              placeholder=""
                              name="officeZipcode"
                              value={employeeInformation.officeZipcode}
                              type="text"
                              id="ofc-zip-code"
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              onBlur={(e)=>{
                                this.validateLocationAddress(e.target.name)
                            }}
                              validations={[zipValidation]}
                            />
                          </div>
                        </div>
                        <div className="">
                          <label
                            htmlFor="ofc-ext-no"
                            className="form-label col-form-label"
                          >
                            EXT
                          </label>
                          <div className="">
                            <Input
                              placeholder=""
                              name="ext"
                              value={employeeInformation.ext||''}
                              type="text"
                              id="ofc-ext-no"
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              // validations={[isNumeric]}
                            />
                          </div>
                        </div>
                  </div>
                  <div className="col">
                    <h6 className="text-blue text-bg-color">LOGIN DETAILS</h6>
                    <div className="">
                          <label
                            htmlFor="emp-personal-login-name"
                            className="form-label col-form-label"
                          >
                            PERSONAL LOGIN NAME
                          </label>
                          <div className="">
                            <Input
                              placeholder=""
                              name="loginName"
                              value={employeeInformation.loginName}
                              type="text"
                              id="emp-personal-login-name"
                              validations={[required]}
                              requiredmsg="Please enter Login Name"
                              className="form-control form-control-sm"
                              onChange={e => {
                                this.props.onFieldValueChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-10">
                          <label
                            htmlFor="emp-temporary-password"
                            className="form-label col-form-label"
                          >
                            TEMPORARY PASSWORD
                          </label>
                          <div className="">
                          <Input
                                placeholder=""
                                name="temPassword"
                                value={ employeeInformation.temPassword===null?'':employeeInformation.temPassword}
                                type="password"
                                autocomplete="new-password"
                                id="emp-temporary-password"
                                validations={this.props.eid==0?[required]:[]}
                                requiredmsg="Please enter Password"
                                className="form-control form-control-sm"
                                onChange={e => {
                                  this.props.onFieldValueChange(
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                              />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="">
                              <div className="custom-control custom-checkbox">
                                  <input type="checkbox" id="neverExpire" name="neverExpire" className="custom-control-input" onChange={(e) => {
                                      if (e.target.checked) {
                                          this.props.onFieldValueChange(e.target.name,true)
                                      }else{
                                        this.props.onFieldValueChange(e.target.name,false)
                                      }
                                  }} checked={this.getStatus()} />
                                  <label htmlFor="neverExpire" className="custom-control-label">LOGIN NEVER EXPIRE</label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                <div className="row">               
                  <div className="col-md-12">
                        <div className="">
                          <div className="text-right">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={e => {
                                e.preventDefault();
                                this.formBasicInfo.validateAll();
                                if (!isValidForm(this.formBasicInfo)) {
                                } else {
                                   this.props.onSubmitBasicInfo(e);
                                }
                              }}
                            >
                              Save Next
                            </button>
                          </div>
                        </div>                                 
                  </div>
                  </div>
                  </fieldset>
              </Form>              
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default BasicInfo;
