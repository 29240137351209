import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SummaryDetailsCtrl from '../../../components/reportsComponents/summaries/summaryDetailsCtrl';
import SummaryByDetailsTable from '../../../components/reportsComponents/summaries/summaryByDetailsTable';
import {initSummDetailsState} from '../../../util/stateUtil/initialStateForComponents';
import { detailsColumns } from '../../../util/tableColumnUtil/summaryClms';
import { notify } from '../../../util/notifications';
import { isValidForm } from '../../../util/validationMethods';
import { cleanSummaryDataReport, getsummaryDetailsReport ,cleanSummaryDetailsReport} from '../../../actions/reportAction/summaryAction/summary'
import printJS from 'print-js';
import { isManagement, getCompanyId ,reportText} from '../../../util/customMethods';
import queryString from 'query-string'
const printJSHeaders = detailsColumns.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
const pageTitle = 'Summary By Account Rate Plan'
let ReportForm={}

class SummaryDetails extends Component {
    state = {
        ...initSummDetailsState
    }

    Go = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(getsummaryDetailsReport(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success', res.statusMsg, this.props.refNotify);
                this.setState({ render: false });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    Export = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(getsummaryDetailsReport(payload, status));
            if (res.statusCode === 200) {
                this.setState({ render: !this.state.render });
                notify.createNotification('success', 'Report exported successfully.', this.props.refNotify);
                this.setState({ render: false });
            } else {
                this.setState({ render: false });
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    Print = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if (payload) {
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(getsummaryDetailsReport(payload, status));
            if (res.statusCode === 200) {
                printJS({
                    printable: this.props.summaryObj.summaryDetails || [],
                    properties: printJSHeaders,
                    type: 'json',
                    documentTitle: reportText(payload.name)+' Report'
                });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
    }

    /* Building all invoice object for post request */
    buildObject = () => {
        const stateObj = this.state;
        const constractObj = {
            idCompany: isManagement() ? (stateObj.idCompany || getCompanyId()) : getCompanyId(),
            fromDate: stateObj.fromDate,
            toDate: stateObj.toDate,
            typeId: stateObj.typeId,
            name: stateObj.type,
            accountId: stateObj.accountId
        };
        return constractObj;
    }
	componentWillMount = () => {
        const parsedHash = queryString.parse(this.props.location.search);
        let repoName = reportText(parsedHash.type||'');
        document.title = repoName;
        this.props.dispatch(cleanSummaryDetailsReport());
        this.setState({ 
            typeId: parsedHash.id||'',
            type: parsedHash.type||'',
            fromDate: parsedHash.fromDate||'', 
            toDate: parsedHash.toDate||'', 
            accountId: parseInt(parsedHash.accountId||0),
            idCompany:parseInt(parsedHash.idCompany||0),
            reportText:repoName
        }, ()=>{
            this.Go();
        })
    }
    onSummaryByAccountRatePlanFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let stateObj = this.state ;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4>{stateObj.reportText}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                <SummaryDetailsCtrl 
                    Go={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Go();
                        }
                    }}
                    Export={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Export();
                        }
                    }}
                   Print={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Print();
                        }
                    }}
                    stateObj={stateObj}
                    accountTypes={this.props.accountTypes}
                    accountList={this.props.accountList}
                    childCompList={this.props.childCompList}
                    callReasons={this.props.callReasons}
                    towTypes={this.props.towTypes}
                    allInvoiceObj={this.props.allInvoiceObj}
                    data={this.props.summaryObj}
                    onSummaryByAccountRatePlanFieldChange={(key, value)=>
                        this.onSummaryByAccountRatePlanFieldChange(key, value)}
                    initCallBack={(frm)=>{
                        ReportForm=frm;
                    }} fromdate={this.state.fromDate} todate={this.state.toDate}/>
                    <SummaryByDetailsTable 
                     stateObj={stateObj}
                    data = {this.props.summaryObj.summaryDetails||[]}/>
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        refNotify: state.refNotify,
        accountTypes: state.accountTypes.accountTypes,
        accountList: state.invoiceInfo.accountList,
        childCompList: state.searchInvoiceList.childComList,
        callReasons: state.callReasons.callReasons,
        towTypes: state.towTypeList.towTypes,
        allInvoiceObj: state.allInvoice,
        summaryObj:state.summaryObj.summaryDetailsData
	}
};

export default withRouter(connect(mapStateToProps)(SummaryDetails));
