import React from 'react';
import {tableColumns} from '../accounting/arDetails/table-column.config'
import {InvoicetableColumns} from '../accounting/arDetails/arInvoice-column';
import { ARDetailsClmn } from './ARReportComClmn';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';


//This component takes four main props (data, tableReference, tableDataChanged, columns)
//The columns prop is imported from a column config file that is
//in the same folder as the component being used (see storage-management/negative-balance-report) for example
// let ReactTableDisplay = props => {

class ReactTableDisplay extends React.Component {

    state = {
        expanded: { 0: true }
    }

    handleExpand = () => {
        const expandedObj = {}
        this.props.data.forEach((item, index) => expandedObj[index] = true)
        this.setState({ expanded: expandedObj })
    }

    handleCollapse = () => {
        const expandedObj = {}
        this.props.data.forEach((item, index) => expandedObj[index] = false)
        this.setState({ expanded: expandedObj })
    }

    render(){

    return (
        <React.Fragment>
            <div className="form-group">
            <button className="btn btn-success btn-xs mr-2" style={{width:'auto'}} onClick={this.handleExpand}>Expand All</button>
            <button className="btn btn-success btn-xs" style={{width:'auto'}} onClick={this.handleCollapse}>Collapse All</button>
            </div>
            <TmReactTable
                data={this.props.data}
                defaultPageSize={10}
                getTableProps={() => ({ style: { textTransform: 'uppercase', fontSize: '12px', fontWeight: 700 } })}
                getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
                sortable={false}
                ref={this.props.tableReference}
                filterable={false}
                onFilteredChange={this.props.tableDataChanged}
                onSortedChange={this.props.tableDataChanged}
                resizable={true}
                columns={tableColumns}
                className='-striped -highlight'
                noDataText='No data found'
                expanded={this.state.expanded}
                onExpandedChange={e => this.setState({expanded: e})}
            
                SubComponent={row => {
                    let arr=[];
                    arr.push(row.original)
                    return (
                        <div>
                        <em>
                        <TmReactTable
                        data={arr}
                        defaultPageSize={10}
                        showPagination={true}
                        getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
                        sortable={false}
                        filterable={false}
                        resizable={true}
                        columns={ARDetailsClmn}
                        className='-striped -highlight'
                        noDataText='No data found'
                        />
                        </em>
                        <TmReactTable
                        data={row.original.accountReceivableDetailInfo}
                        // defaultPageSize={row.original.accountReceivableDetailInfo.length || 1}
                        defaultPageSize={10}
                        showPagination={true}
                        getTheadTrProps={() => ({ style: { color: '#0a5e9c' } })}
                        sortable={false}
                        filterable={false}
                        resizable={true}
                        columns={InvoicetableColumns}
                        className='-striped -highlight'
                        noDataText='No data found'
                        />
                    </div>
                    );
                }}
            />
        </React.Fragment>
    );
    }
};

export default ReactTableDisplay;
