import * as actionTypes  from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import {GlobalValues} from '../../util/constants'
import moment from 'moment'

export const getBillingEmail=()=>{
    const _url=`config/getBillingEmail?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async(dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const res=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
        if(res.status===200) {         
            dispatch({
                type: actionTypes.GET_BILLING_EMAIL,
                billingEmail:res.data,
            });
        }else{
            dispatch({
                type: actionTypes.GET_BILLING_EMAIL,
                billingEmail:undefined
            });
        }
        }catch(ex){
            dispatch({
                type: actionTypes.GET_BILLING_EMAIL,
                billingEmail:undefined
            });
        }
    }
}
export const saveBillingEmail=(email)=>{
    return async (dispatch)=>{
        try{
            //dispatch(spinnerActions.showSpinner());
            let _url=`config/billingEmail?billingEmail=${email}&idCompany=${getCompanyId()}&access_token=${getAccessToken()}`;
            const res=await getAxiosInstance(moduleTypes.ADMIN).put(_url);
            if(res.data.statusCode===200){
                dispatch(getBillingEmail())
                dispatch(changeEmailField(''))
                return {statusCode:res.data.statusCode, statusMsg:res.data.message}
            }else{
                return {statusCode:null, statusMsg:res.message}
            }
        }catch(ex){
            return {statusCode:null, statusMsg:ex.message}
        }
    }
}
export const changeEmailField=(emailAddr)=>{
    return (dispatch)=>{
         dispatch({
            type: actionTypes.GET_EMAIL_REC,
            emailAddr:emailAddr
        }); 
    }
}
