import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CallsCompletedChart from '../../../components/launchComponents/Reports/allreports/callscompletedreport/callscompletedchart';
import SalvageCountChart from '../../../components/launchComponents/Reports/allreports/salvagecountreport/salvagecountchart';
import DailyDepositChart from '../../../components/launchComponents/Reports/allreports/dailydepositreport/dailydepositchart';
import StorageCountChart from '../../../components/launchComponents/Reports/allreports/storagecountreport/storagecountchart';
import StorageDrillDownCountChart from '../../../components/launchComponents/Reports/allreports/storagecountreport/storagedrilldowncountchart';
import UnitsReleasedChart from '../../../components/launchComponents/Reports/allreports/unitsreleasedreport/unitsreleasedchart';
import CommissionsChart from '../../../components/launchComponents/Reports/allreports/commissionsReport/commissionsChrt'
import NoAbandonedVehiclesChart from '../../../components/launchComponents/Reports/allreports/noabandonedvehiclesreport/noabandonedvehicleschart';
import {withRouter} from 'react-router-dom';

class ReportChartPage extends Component {
    render() {
        return ( <div>                                   
                        <Route exact path="/reportschart/callscompletedchart" component={CallsCompletedChart} />
                        <Route exact path="/reportschart/salvagecountchart" component={SalvageCountChart} />
                        <Route exact path="/reportschart/dailydepositchart" component={DailyDepositChart} />
                        <Route exact path="/reportschart/storagecountchart" component={StorageCountChart} />
                        <Route exact path="/reportschart/storagedrilldowncountchart" component={StorageDrillDownCountChart} />
                        <Route exact path="/reportschart/unitsreleasedchart" component={UnitsReleasedChart} />
                        <Route exact path="/reportschart/noabandonedvehicleschart" component={NoAbandonedVehiclesChart} />   
                        <Route exact path="/reportschart/commissionchart" component={CommissionsChart} />       
        </div>)
    }

}
export default withRouter(ReportChartPage)