import React, { Component,Fragment } from 'react';
import {Form,Col,Button,Modal} from 'react-bootstrap';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
import CompanyInfoContacts from './companyInfoContacts';
import moment from 'moment'
const companyContactObj={
  "idContact": '',
  "idBilling": '',
  "contactName": '',
  "contactPhone": '',
  "contactEmail": '',
  "contactExt": ''
}
class CompanyInfoContactList extends Component {
  constructor(props){
    super(props);
    this.state={       
      modalIsOpen:false,
      contactRec: {...companyContactObj},
      contactRecIndex: -1
    }
    this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
handleClose(){
  this.setState({modalIsOpen:false,contactRec:{...companyContactObj},
    contactRecIndex: -1})
}
buildDataFormatForExport=()=>{
  return([{
    columns:[{title:'CONTACT NAME'},{title:'PHONE NO'},{title:'EXT'},{title:'EMAIL ID'}],
    data: this.props.data.map((item)=>{
      return [{value:item.contactName||''},{value:item.contactPhone||''},{value:item.contactExt||''},{value:item.contactEmail||''}]
    })
  }])
}
onContactFieldChange=(name,value)=>{
  let _recObj=this.state.contactRec;
  _recObj[name]=value;
  this.setState({contactRec:_recObj})
}

  render(){
    const excelDataSet=this.buildDataFormatForExport();
      const contactCols=[                   
        {
          Header: 'CONTACT NAME',
          accessor: 'contactName', // String-based value accessors!
          filterable:true,
          Cell: props => props.value,
          width:'100%'            
        }, {
          Header: 'PHONE NO',
          accessor: 'contactPhone',
          filterable:true,
          Cell: props => props.value,
          width: 200
        }, {
          Header: 'EXT',
          accessor: 'contactExt',
          filterable:true,
          Cell: props => props.value,
          width: 200
        },
        {
          Header: 'EMAIL ID',
          accessor: 'contactEmail',
          filterable:true,
          Cell: props => props.value,
          width: 200
        },
        {
          Header: 'Edit',
          filterable:false,
          Cell: (props) => <button disabled={!this.props.canEdit} type="button" data-idx={props.index}  className="btn btn-success btn-sm" 
          onClick={(e)=>{
            //this.props.onContactEdit(e,e.target.getAttribute('data-idx'));
            let idx=e.target.getAttribute('data-idx');
            let selectedContact={...this.props.data[idx]};
            this.setState({contactRec:selectedContact ,contactRecIndex:idx,modalIsOpen:true})          
          }} ><i className="fa fa-edit"  data-idx={props.index}></i> Edit</button>,
          width: 100
        },
        {
          Header: 'Delete',
          filterable:false,
          Cell: (props) => <button disabled={!this.props.canEdit} type="button" data-idx={props.index}  className="btn btn-danger btn-sm" 
          onClick={(e)=>{
            this.props.onContactDelete(e.target.getAttribute('data-idx'));
          }} ><i className="fa fa-trash" data-idx={props.index}></i> Delete</button>,
          width: 100
        }
      ];
        
      return(<Fragment>
      <div className="row">
          <div className="col-md-12 col-sm-12 mb-10">
        <div className="pull-left">
          <h4 className="text-blue">Contact Details</h4>				
        </div>
              <div className="pull-right">
                <div className="btn-list">
                   <TmExportExcel name={'Notes'} filename={`${"ContactDetails_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                          element={<button disabled={!this.props.canEdit} className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                          dataSet={excelDataSet} /> 
                  <button className="btn btn-success btn-sm " disabled={!this.props.canEdit} onClick={(e)=>{
                      this.setState({ modalIsOpen: true});
                      }}><i className="fa fa fa-plus"></i>ADD CONTACT</button>
                </div>
              </div>
      </div></div>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">
          <TmReactTable 
          filterable 
          sortable
          resizable
          defaultPageSize={3}
          data={this.props.data}
          columns={contactCols}
      /> 
      </div>
      </div>
      <Modal show={this.state.modalIsOpen} > 
      <div className="modal-header">
          <Modal.Title>Add Contact</Modal.Title>
      </div>
      <div className="modal-body">
          <CompanyInfoContacts 
          submitBtnText={this.state.contactRec.idContact === 0 ? 'Add New Contact' : 'Save Contact'} 
          handleClose={this.handleClose} 
          contactObj={this.state.contactRec}
          onContactFieldChange={(name,value)=>this.onContactFieldChange(name,value)}
          isPhoneRequired={this.props.isPhoneRequired} 
          isEmailRequired={this.props.isEmailRequired}
          onContactSubmit={async(e) => {
            let res=await this.props.onContactSubmit(e, this.state.contactRec,this.state.contactRecIndex)
            if(res){
               this.setState({modalIsOpen:false,
                contactRecIndex:-1,
                contactRec:{...companyContactObj}})
            } 
          }}
          />
      </div>
      </Modal>
      </Fragment>);
  }

}
export default CompanyInfoContactList