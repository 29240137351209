import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactSelect from '../../components/utilComponents/tmSlimSelect/reactSelect';
import {notify} from '../../util/notifications';
import {GOOGLE_API_KEY} from '../../util/constants';
import {isManagement} from '../../util/customMethods';
import {getInvoiceDetails, getTruckList , getAccountByInvoice} from '../../actions/dispatchAction/dispatchCallsAction';
import {assignInvoice,filterTruckList,fitlerInvoiceList,assignInvoiceToCompany} from '../../actions/dispatchAction/assignInvoiceAction';
import VisibilityToggles from '../../components/dispatchComponents/assign/visibilityToggles/VisibilityToggles';
import TmReactTable from '../../components/utilComponents/tmReactTable/tmReactTable';
import Map from '../../components/dispatchComponents/assign/AssignMap';
import StreetView from '../../components/dispatchComponents/assign/StreetView';
import { getChildList } from '../../actions/dispatchAction/invoiceSearchAction';
const pageTitle = 'Assign';
class AssignPage extends Component {
	state={
			invoiceId:'',
			userId:'',
			truckId:'',
			driverId:'',
			companyId:'',
			filterData:{
				trucks:{
					'Available': {text:'Available',selected:false},
					'Assigned': {text:'Assigned',selected:false},
					'Loaded': {text:'Loaded',selected:false},
					'Doubled': {text:'Doubled',selected:false}
				},
				invoice:{
					pickup:{
						'Unassigned': {text:'Unassigned',selected:false},
						'Enroute': {text:'Enroute',selected:false},
						'Arrived': {text:'Arrived',selected:false},
						'Hooked': {text:'Hooked',selected:false},
						'Dropped': {text:'Dropped',selected:false}
					},
					priority:{
						1: {text:'1',selected:false},
						2: {text:'2',selected:false},
						3: {text:'3',selected:false},
						4: {text:'4',selected:false},
						5: {text:'5',selected:false},
						6: {text:'6',selected:false},
						7: {text:'7',selected:false},
						8: {text:'8',selected:false}

					}
				},
				dropLocation:false
			}
		}
	componentWillMount=async()=>{
		const invoiceId=this.props.match.params.invoice
		this.setState({invoiceId:invoiceId},()=>{
			this.getInvoiceDetails();
		});
	}
	getInvoiceDetails=async()=>{
		const {dispatch}=this.props;
		await dispatch(getInvoiceDetails(this.state.invoiceId))
		if(isManagement()){
			await dispatch(getTruckList(this.state.invoiceId))
			const accId  = await dispatch(getAccountByInvoice(this.state.invoiceId))
			dispatch(getChildList(accId));
		}
	}
	assignorunassignInvoiceToCompany=async(invoicestatus)=>{
		const {dispatch, history}=this.props;
		const {invoiceId,companyId}=this.state;
		if(invoicestatus === 'assign'){
			 const res=await dispatch(assignInvoiceToCompany(invoiceId,companyId));
			if(res.statusCode===200){
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
				history.push('/dispatch/dashboard')
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}else if(invoicestatus === 'unassign'){
			const res=await dispatch(assignInvoice(invoiceId,'',''));
			if(res.statusCode===200){
				history.push('/dispatch/dashboard')
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}	
	}
	assignorunassignInvoice=async(invoicestatus)=>{
		const {dispatch, history}=this.props;
		const {invoiceId,driverId,truckId,companyId}=this.state;
		if(invoicestatus === 'assign'){
			const res=await dispatch(assignInvoice(invoiceId,driverId,truckId));
			if(res.statusCode===200){
				history.push('/dispatch/dashboard')
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}else if(invoicestatus === 'unassign'){
			const res=await dispatch(assignInvoice(invoiceId,'',''));
			if(res.statusCode===200){
				history.push('/dispatch/dashboard')
			}else{
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
		}	
	}
	onDriver_TruckChange=(e,key)=>{
		if(e){
			let selectedObj=this.props.truckList.find(item=>{
				return item[key]===e.value||''
			});
			if(selectedObj){
				this.setState({
						userId:selectedObj.userId,
						truckId:selectedObj.truckId,
						driverId:selectedObj.driverId,
						companyId:0
						});
					this.forceUpdate()
			}
		}else{
			this.setState({userId:'',
				truckId:''});
			this.forceUpdate();
		}
	}
    render() {
		document.title=`${pageTitle} ${this.state.invoiceId}`;
		const {invoiceRecord,truckList}=this.props;
		const {userId, truckId, driverId, filterData,companyId}=this.state;
		return (
			<>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Assign {invoiceRecord.displayInvoiceId}</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<div className="row">
						<div className="col-md-12">
							<VisibilityToggles filterContent={filterData} onSelectionChange={(key,newObject)=>{
								let _filterData=this.state.filterData;
								_filterData[key]=newObject;
								this.setState({filterData:_filterData})
							}} />
						</div>
					</div>
					<div className="row">
						{isManagement()?<div className="col-3 form-group">
						<label>Select Company :</label> 
                                              <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    options={this.props.associateComp.map((item, t) => {
                                                        return ({ value: item.idCompany, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: this.state.companyId,
                                                        label: (this.props.associateComp.find((e) => e.idCompany == (this.state.companyId || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
														this.setState({
															companyId:parseInt(selectedOption.value),
															truckId:'',
															driverId:'',
															userId :''
														});this.forceUpdate()
                                                    }}
                                                />
                                        </div>:null}
						<div className="col-md-3">
							<div className="form-group">
								<label>Select Driver:</label>
								<Select									
									name="drivers"
									options={truckList.map(item=>{
										return { value: item.userId, label: isManagement()?(item.userId+" ["+item.subdomain+']'):item.userId}
									})}
									isClearable={true}
									value={{value:userId, label:userId}}
									onChange={(e)=>{this.onDriver_TruckChange(e,'userId')}}
								/>
							</div>				
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<label>Select Truck:</label>
								<Select									
									name="trucks"
									isClearable={true}
									value={{value:truckId, label:truckId}}
									options={truckList.map(item=>{
										return { value: item.truckId, label: isManagement()?(item.truckId+" ["+item.subdomain+']'):item.truckId }
									})}
									onChange={(e)=>{this.onDriver_TruckChange(e,'truckId')}}
								/>
							</div>
							
						</div>
						<div className="col-md-3 text-right">
							<div className="btn-list m-0 mt-4">
								<button type="button" title="Assign" className="btn btn-sm btn-success" onClick={()=>{
									if((driverId !== '' && truckId !== ''))
									this.assignorunassignInvoice('assign');
									else
									this.assignorunassignInvoiceToCompany('assign');
								}} 
									disabled={((driverId||'') === '' || (truckId||'') === '') && ((companyId||'') ==='')}>Assign</button>
								<button type="button" title="Unassign" className="btn btn-sm btn-danger" onClick={()=>{this.assignorunassignInvoice('unassign')}}>Unassign</button>
							</div>			
						</div>
					</div>
					<div className="row">
						<div className="col-md-7">
							<div className="card mt-3" style={{minHeight:"500px"}}>
								<div className="card-body p-0">
									<Map mapVehicleInfo={filterTruckList(truckList,this.state.filterData.trucks)} 
									dropPointers={this.state.filterData.dropLocation} 
									PickUpOinters={true} 
									callData={fitlerInvoiceList([invoiceRecord],{pickup:this.state.filterData.invoice.pickup,priority:this.state.filterData.invoice.priority})} />
								</div>
							</div>						
						</div>
						<div className="col-md-5">
							<dl className="row">
								<dt className="col-sm-12 text-danger">{invoiceRecord.accountingType||''}</dt>
								<dt className="col-sm-4">Invoice</dt>
								<dd className="col-sm-8">{invoiceRecord.invoiceId}</dd>
								<dt className="col-sm-4">Req By</dt>
								<dd className="col-sm-8">{invoiceRecord.accountName}</dd>
								<dt className="col-sm-4">Reason</dt>
								<dd className="col-sm-8">{invoiceRecord.reason}</dd>
								<dt className="col-sm-4">Tow</dt>
								<dd className="col-sm-8">{invoiceRecord.towType}</dd>
								<dt className="col-sm-4">Pickup Address</dt>
								<dd className="col-sm-8">{invoiceRecord.pickupAddress}</dd>
								<dt className="col-sm-4">Drop Address</dt>
								<dd className="col-sm-8">{invoiceRecord.dropoffAddress}</dd>
							</dl>
							<div className="row">
								<div className="col-md-12">
									<div className="card text-center">
										<div className="card-body" style={{minHeight:"250px"}}>
											<StreetView address={invoiceRecord.pickupLatlang} 
											APIkey={GOOGLE_API_KEY} />
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="card mt-3">
										<div className="card-body p-0">
										<TmReactTable
											filterable={false}
											sortable={false}
											resizable={false}
											showPagination={false}									
											data={truckList||[]}
											columns={[{
												Header: 'Truck',
												accessor: 'truckId',
												style: { textAlign: 'center' }

											},{
												Header: 'Driver',
												accessor: 'userId',
												style: { textAlign: 'center' }
											},{
												Header: 'Status',
												accessor: 'truckStatus',
												style: { textAlign: 'center' }
											}]}
											/>						
											
										</div>
									</div>
								</div>
							</div>	
						</div>
					</div>			
				</div>
			</>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify:state.refNotify,
		invoiceRecord: state.dispatchCalls.invoiceRecord,
		truckList:state.dispatchCalls.truckList,
		childCompList:state.searchInvoiceList.childComList,
		associateComp:state.searchInvoiceList.associateComp
	}
};

export default withRouter(connect(mapStateToProps)(AssignPage));

