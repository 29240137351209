import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import DispatchDashboard from './dispatchDashboard';
import AddServiceCall from './addServiceCall';
import ServiceCallInfo from './serviceCallInfo';
import InvoiceSearch from './invoiceSearch';
import AssignPage from './assignPage';
import MapSearch from './map';
import Inbox from './inbox';
import InvoicePayment from './InvoicePayment';
class DispatcherHome extends React.Component {
    constructor(){
      super();
      
    }
    render(){
        return(<>
        <Switch>
            <Route exact path="/dispatch/dashboard" component={DispatchDashboard} />
            <Route exact path="/dispatch/serviceCallInfo/:invoice" component={AddServiceCall} />
            <Route exact path="/dispatch/addNewServiceCall" component={ServiceCallInfo} /> 
            <Route exact path="/dispatch/invoiceSearch" component={InvoiceSearch} />
            <Route exact path="/dispatch/assign/:invoice" component={AssignPage} />
            <Route exact path="/dispatch/map" component={MapSearch} />
            <Route exact path="/dispatch/inbox" component={Inbox} />
            <Route exact path="/dispatch/payment" component={InvoicePayment} />
        </Switch>
        </>)
    }
}
export default DispatcherHome