import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment';
import {isManagement} from '../../../util/customMethods'

class SearchInvoice extends Component {
       state = {
            sortedData: []
       }
    buildDataFormatForExport = () => {
        return (
          [{
            columns: [{ title: 'INVOICE #' }, { title: 'REASON' }, { title: 'TOW DATE' }, { title: 'DRIVER #' }, { title: 'TRUCK #' }, { title: 'TOW TYPE' }
            , { title: 'REQUESTED BY' }, { title: 'OR/DR#' }, { title: 'YEAR' }, { title: 'MAKE' }, { title: 'MODEL' }, { title: 'COLOR' }
            , { title: 'VIN #' }, { title: 'PLATE #' }, { title: 'EMAIL' }, { title: 'PHONE' }],
            data: this.props.data.map(item => {
              return [{ value: item.displayInvoiceId || '' }, { value: item.reason || '' }, { value: item.callCreationDate || '' }, { value: item.driver || '' }, { value: item.truck || '' }, { value: item.towType || '' }
              , { value: item.requestedBy || '' }, { value: item.oR_DR || '' }, { value: item.vehicleYear || '' }, { value: item.make || '' }, { value: item.model || '' }, { value: item.color || '' }
              , { value: item.vehicleVin || '' }, { value: item.vehiclePlateNo || '' }, { value: item.email || '' }, { value: item.phone || '' }]
            })
          }]
        )
      }
      buildDataFormatForExportManage = () => {
        return (
          [{
            columns: [{ title: 'INVOICE #' },{ title: 'COMPANY' }, { title: 'REASON' }, { title: 'TOW DATE' }, { title: 'DRIVER #' }, { title: 'TRUCK #' }, { title: 'TOW TYPE' }
            , { title: 'REQUESTED BY' }, { title: 'OR/DR#' }, { title: 'YEAR' }, { title: 'MAKE' }, { title: 'MODEL' }, { title: 'COLOR' }
            , { title: 'VIN #' }, { title: 'PLATE #' }, { title: 'EMAIL' }, { title: 'PHONE' }],
            data: this.props.data.map(item => {
              return [{ value: item.displayInvoiceId || '' },{ value: item.companyName || '' }, { value: item.reason || '' }, { value: item.callCreationDate || '' }, { value: item.driver || '' }, { value: item.truck || '' }, { value: item.towType || '' }
              , { value: item.requestedBy || '' }, { value: item.oR_DR || '' }, { value: item.vehicleYear || '' }, { value: item.make || '' }, { value: item.model || '' }, { value: item.color || '' }
              , { value: item.vehicleVin || '' }, { value: item.vehiclePlateNo || '' }, { value: item.email || '' }, { value: item.phone || '' }]
            })
          }]
        )
      }
      sort(filtered = []) {
        let sortedData = this.props.data;
        if (this.tableInstance !== undefined) {
          sortedData = this.tableInstance.tmReactTable.getResolvedState().sortedData.map(item => {
            return ([
              { value: item._original.displayInvoiceId || '', key: 'displayInvoiceId' },
              { value: item._original.companyName || '', key: 'companyName' },
              { value: item._original.reason || '', key: 'reason' },
              { value: item._original.callCreationDate || '', key: 'callCreationDate' },
              { value: item._original.driver || '', key: 'driver' },
              { value: item._original.truck || '', key: 'truck' },
              { value: item._original.towType || '', key: 'towType' },
              { value: item._original.requestedBy || '', key: 'requestedBy' },
              { value: item._original.oR_DR || '', key: 'oR_DR' },
              { value: item._original.vehicleYear || '', key: 'vehicleYear' },
              { value: item._original.make || '', key: 'make' },
              { value: item._original.model || '', key: 'model' },
              { value: item._original.color || '', key: 'color' },
              { value: item._original.vehicleVin || '', key: 'vehicleVin' },
              { value: item._original.vehiclePlateNo || '', key: 'vehiclePlateNo' },
              { value: item._original.email || '', key: 'email' },
              { value: item._original.phone || '', key: 'phone' }
            ]);
          });
          this.setState({ sortedData });
        }
        return this.state.sortedData;
      }
    render() {
    const { data } = this.props;
    const columns = [{
      Header: 'INVOICE #',
      accessor: 'displayInvoiceId',
      style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href={`serviceCallInfo/${row.original.invoiceId}`} target="_blank">{row.original.displayInvoiceId}</a>
        )
    },
    {
        Header: 'COMPANY',
        accessor: 'companyName',
        show:isManagement(),
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.companyName || 0}</a>
        )
    },
    {
        Header: 'REASON',
        accessor: 'reason',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.reason || 0}</a>
        )
    },
    {
        Header: 'TOW DATE',
        accessor: 'callCreationDate',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.callCreationDate || 0}</a>
        )
    },
    {
        Header: 'DRIVER #',
        accessor: 'driver',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.driver || 0}</a>
        )
    },
    {
        Header: 'TRUCK #',
        accessor: 'truck',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.truck || 0}</a>
        )
    },
    {
        Header: 'TOW TYPE',
        accessor: 'towType',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.towType || 0}</a>
        )
    },
    {
        Header: 'REQUESTED BY',
        accessor: 'requestedBy',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.requestedBy || 0}</a>
        )
    },
    {
        Header: 'OR/DR#',
        accessor: 'oR_DR',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.oR_DR || 0}</a>
        )
    },
    {
        Header: 'YEAR',
        accessor: 'vehicleYear',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.vehicleYear || 0}</a>
        )
    },
    {
        Header: 'MAKE',
        accessor: 'make',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.make || 0}</a>
        )
    },
    {
        Header: 'MODEL',
        accessor: 'model',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.model || 0}</a>
        )
    },
    {
        Header: 'COLOR',
        accessor: 'color',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.color || 0}</a>
        )
    },
    {
        Header: 'VIN #',
        accessor: 'vehicleVin',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.vehicleVin || 0}</a>
        )
    },
    {
        Header: 'PLATE #',
        accessor: 'vehiclePlateNo',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.vehiclePlateNo || 0}</a>
        )
    },
    {
        Header: 'EMAIL',
        accessor: 'email',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.email || 0}</a>
        )
    },
    {
        Header: 'PHONE',
        accessor: 'phone',
        style: { textAlign: 'center', display: 'inline-grid' },
        Cell: row => (
        <a href="javascript:void(0)" >{row.original.phone}</a>
        )
    }];
    const sortedData = data.map(data => {
        for(let key in data){
            data[key] = typeof data[key] === ('undefined' || 'object') || key.startsWith('_') || Number(data[key]).toString() === 'NaN' ? data[key] : Number(data[key])
        }
        return data
    })
        return (<Fragment>
            <div className="pd-10">
            <Form className="">
            <fieldset>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                    <TmExportExcel
                        name={"Invoice Search"}
                        filename={`${"InvoiceSearch_"}${moment().format('MMDDYYhhmm')}`} 
                        fileExtension={'xlsx'}
                        element={<button className="btn btn-warning btn-sm pull-right"><i className="fa fa-file-excel-o"></i> Export</button>}
                        dataSet={isManagement()?this.buildDataFormatForExportManage():this.buildDataFormatForExport()}
                        />
                    </div>
                    <div className="col-md-12 col-sm-12 mb-10">
                        <TmReactTable
                        filterable
                        sortable
                        resizable
                        defaultPageSize={25}
                        data={sortedData}
                        onFilteredChange={filtered => { this.sort(filtered); console.log('filter called', filtered) }}
                        columns={columns}
                        ref={instance => this.tableInstance = instance}
                        />
                    </div>
                </div>  
                </fieldset>
            </Form>
            </div>
        </Fragment>);
    }
}
export default SearchInvoice
