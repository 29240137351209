import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment'

class CompanyList extends Component {
  constructor() {
    super();
    this.state = {
      ActiveInactiveFilterVal: [1],
      searchObject: {activeFilter: 1 }
    }
    this.handleActiveInactiveFilterChange = this.handleActiveInactiveFilterChange.bind(this)
  }

  handleActiveInactiveFilterChange = (value, e) => {
    let _searchObject = this.state.searchObject;
    _searchObject.activeFilter = parseInt(e.currentTarget.value);
    this.setState({ ActiveInactiveFilterVal: [parseInt(e.currentTarget.value)], searchObject: _searchObject 
    },
      this.props.onSearchChange(_searchObject)
    );
  }

  buildDataFormatForExport = () => {
    return (
      [{
        columns: [{ title: 'COMPANY NAME' }, { title: 'CONTACT EMAIL' },{ title: 'CONTACT MOBILE' }],
        data: this.props.data.map(item => {
          return [{ value: item.name || '' }, { value: item.emailId || '' }, { value: item.contactNo || '' }]
        })
      }]
    )
  }

  render() {
    const { data } = this.props;
    const columns = [{
      Header: 'COMPANY NAME',
      accessor: 'name', // String-based value accessors!
      filterable: true,
      width: 300,
      style: {height: '30px', display: 'flex', alignItems: 'center'},      
      Cell: props => <Link to={'/admin/companyRecord?cid=' + props.original.idCompany} >{props.value}</Link>
    },
    {
      Header: 'COMPANY TYPE',
      accessor: 'companyType', // String-based value accessors!
      filterable: true,
      width: 300,
      style: {height: '30px', display: 'flex', alignItems: 'center'}
    },
    {
      Header: 'Contact Email',
      accessor: 'emailId', // String-based value accessors!
      filterable: true,
      width: 300,
      style: {height: '30px', display: 'flex', alignItems: 'center'},      
      Cell: props => props.value
    },{
      Header: 'Contact Mobile',
      accessor: 'contactNo', // String-based value accessors!
      filterable: true,
      width: 250,
      style: {height: '30px', display: 'flex', alignItems: 'center'},      
      Cell: props => props.value
    }];
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-10 ">
            <div className="pull-right">
              <ToggleButtonGroup
                bsPrefix="btn-group-toggle btn-group"
                type="checkbox"
                value={this.state.ActiveInactiveFilterVal}
                onChange={this.handleActiveInactiveFilterChange} defaultValue={-1}
              >
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}>ACTIVE</ToggleButton>
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>INACTIVE</ToggleButton>                
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>ALL</ToggleButton>
              </ToggleButtonGroup>
              <div className="btn-list " style={{ display: 'inline-block' }}>
                 <TmExportExcel
                  name={"Company List"}
                  filename={`${"CompanyList_"}${moment().format('MMDDYYhhmm')}`} 
                  fileExtension={'xlsx'}
                  element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                  dataSet={this.buildDataFormatForExport()}
                />
                {(this.props.accessLevel.basicAccess.add || false) === true?<button className="btn btn-success btn-sm" onClick={() => {
                      this.props.onClickNewCompany();
                    }} ><i className="fa fa-plus"></i> Add Company</button>:null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-30">
            <TmReactTable
              filterable
              sortable
              resizable
              defaultPageSize={10}
              data={data}
              columns={columns}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default CompanyList;
