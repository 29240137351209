import React, { Component,Fragment } from 'react';
import {Link} from 'react-router-dom'
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import {ToggleButtonGroup,ToggleButton} from 'react-bootstrap';
import moment from 'moment'
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
class TrucksList extends Component {
  constructor(){
    super();
    this.state={
      ActiveInactiveFilterVal:[1],
      searchQuery:'',
      excelDataSet:[]
    }
    this.handleActiveInactiveFilterChange=this.handleActiveInactiveFilterChange.bind(this);
    this.buildDataFormatForExport=this.buildDataFormatForExport.bind(this)
  }
  componentWillReceiveProps=(nextProps)=>{
    this.setState({excelDataSet:nextProps.trucksListData});
  }
  handleActiveInactiveFilterChange=(value,e)=>{
    this.setState(
      {ActiveInactiveFilterVal:[parseInt(e.currentTarget.value)]},   
    this.props.onToggleChange({accountStatus:parseInt(e.currentTarget.value)})
    );
  }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'Truck#'},{title:'Truck Type'},{title:'Tow Type'},{title:'Service Status'},{title:'License State'},{title:'License Plate'}],
      data: this.state.excelDataSet.map((item)=>{
        return [
          {value:item.truckId},
          {value:item.truckTypeCd},
          {value:item.towTypes},
          {value:item.serviceStatus},
          {value:item.state},
          {value:item.licensePlateNo}]
      })
    }])
  }
  
  render() {
    const columns=[{
      Header: 'TRUCK#',
      accessor: 'truckId',
      filterable:true,
     /*  Cell: props => <a className="btn btn-link" href="javascript:void(0)" title="Edit" onClick={(e)=>{
        let _tid=props.original.truckId;
        this.props.editTruckOnClick(e,_tid)
      }}>{props.value}</a>,  */  
      Cell:props=><Link to={'/admin/trucksDetails?tid='+props.original.truckId}>{props.value}</Link>
    }, {
      Header: 'TRUCK TYPE',
      accessor: 'truckTypeCd',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      // width: 300
    },
    {
      Header: 'TOW TYPE',
      accessor: 'towTypes',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      // width: 300
    },
    {
      Header: 'SERVICE STATUS',
      accessor: 'serviceStatus',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      // width: 300
    },
    {
      Header: 'LICENSE STATE',
      accessor: 'state',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      // width: 300
    },
    {
      Header: 'LICENSE PLATE',
      accessor: 'licensePlateNo',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      // width: 300
    }];
     return (
      <Fragment>
      <div className="row">
      <div className="col-md-5 col-sm-12 mb-5 mt-2">
        <div className="input-group mb-1">
          <input className="form-control" placeholder="Search"
          value={this.state.searchQuery} type="text" onChange={(e)=>{
            this.setState(
            {searchQuery:e.target.value});
          }} />
          <div className="input-group-append">
            <button className="btn btn-sm btn-success" type="button"><i className="fa fa-search" aria-hidden="true" onClick={(e)=>{
               this.props.onSearchChange({accountStatus:parseInt(this.state.ActiveInactiveFilterVal),searchQuery:this.state.searchQuery});
            }}></i></button>
          </div>
        </div>
      </div>
      <div className="col-md-7 col-sm-6 mb-10">  
      <div className="pull-right">   
      <ToggleButtonGroup
      bsPrefix="btn-group-toggle btn-group"
        type="checkbox"
        value={this.state.ActiveInactiveFilterVal}
        onChange={this.handleActiveInactiveFilterChange} defaultValue={1}
      >
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}>Active </ToggleButton>
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>Inactive</ToggleButton>
        <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>All</ToggleButton>
      </ToggleButtonGroup>      
      <div className="btn-list" style={{display:'inline-block'}}>
     
      <TmExportExcel name={'Trucks'} filename={`${"TruckDetails_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
      
      {
         (this.props.accessLevel.basicAccess.add||false)===true?<button className="btn btn-success btn-sm" onClick={(e)=>{
        this.props.addTruckOnClick(e);
      }} ><i className="fa fa-plus"></i> Add Truck</button>:null}
      </div>
      </div> 
      </div>
      </div>
      
      
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">				
        <TmReactTable ref={(table)=>this.trucksTable=table}
        onFilteredChange={()=>{
          let data=this.trucksTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={this.props.trucksListData||[]}
            columns={columns}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default TrucksList;
