import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Doughnut} from 'react-chartjs-2';
import queryString from 'query-string';
var _ = require('underscore');
var minPercentLimit=10;
const options1={
    responsive: true,
    maintainAspectRatio: false,
    title: {
        display: true,
        text: 'Salvage Count less than 10%'
    }    
}
const options2={
    responsive: true,
    maintainAspectRatio: false,
    title: {
        display: true,
        text: 'Salvage Count greater than 10%'
    }    
}
class SalvageCountChart extends Component{
    constructor(props){
        super(props);
        this.state={
            fromDate:'',
            toDate:'',
            chartObj1:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                    ],
                    hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                    ]
                }]
            },
            chartObj2:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                    ],
                    hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                    ]
                }]
            },
        }      
    }
    componentWillMount(){
        let _chartObj1=this.state.chartObj1;            
        _chartObj1.labels=[];
        _chartObj1.datasets[0].data=[];
        let _chartObj2=this.state.chartObj2;            
        _chartObj2.labels=[];
        _chartObj2.datasets[0].data=[];
            var maxValObj=_.max(this.props.reportDashboard.salvageCount.callsByReason, function(item){ return item.count; });
            var minPerValue=(minPercentLimit*maxValObj.count)/100;           
            this.props.reportDashboard.salvageCount.callsByReason.map(function(obj){
            if(obj.count<=minPerValue)
            {
                _chartObj1.labels.push(obj.year);
                _chartObj1.datasets[0].data.push(obj.count);
            }
            else{
                _chartObj2.labels.push(obj.year);
                _chartObj2.datasets[0].data.push(obj.count);               
            }
        });
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.setState({fromDate:values.from, toDate:values.to})
      }
    render(){
        return(
            <>
            <div className="page-header">
                  <div className="row mb-10">
                      <div className="col-md-12 col-sm-12">
                          <div className="title">
                          <h4>Duration: {this.state.fromDate === this.state.toDate ? `${this.state.fromDate}` : `${this.state.fromDate} To ${this.state.toDate}`}</h4>
                          </div>
                      </div>
                  </div>
              </div>
               <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
               <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <div>
                    <Link class="btn btn-info" to="/reports-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                    
                    </div>
                    </div>
                    </div>
            <div class="row"> 
                <div class="col-md-6">
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>           
                        <Doughnut height="440" data={this.state.chartObj1} options={options1}/> 
                    </div>
                </div>
                <div class="col-md-6">           
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>           
                        <Doughnut height="440" data={this.state.chartObj2} options={options2}/> 
                    </div> 
                </div>
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(SalvageCountChart);