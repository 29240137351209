import * as actionTypes from '../actionTypes';
import {getAxiosInstance} from '../../util/service';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getCompanyId} from '../../util/customMethods';

export const getSummaryDashboard = () => {
  return async(dispatch) => {
    let url=`dashboard/summaryDashboard?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.DISPATCH).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_SUMMARY_DASHBOARD,
              summaryDashboardData:response.data.response || {}
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_SUMMARY_DASHBOARD,
              summaryDashboardData:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_SUMMARY_DASHBOARD,
            summaryDashboardData:{}
        });
      };
  };
};
