import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultRatePlanList from '../../components/adminComponents/defaultRatePlan/defaultRatePlanList';
import {getRatePlanList} from '../../actions/adminAction/ratePlanActions';
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle='Default Rate Plan'
class RatePlanPage extends Component {
	constructor(){
		super();
		this.state={
			ratePlanSearch:	{"searchQuery":"","ratePlanStatus":"1"}
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.bindRatePlanList=this.bindRatePlanList.bind(this);
		this.addDefaultRatePlanClick=this.addDefaultRatePlanClick.bind(this);
		this.editDefaultRatePlanClick=this.editDefaultRatePlanClick.bind(this);
	}
	componentWillMount=()=>{
		document.title = pageTitle;
		this.bindRatePlanList();		
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindRatePlanList=()=>{
		const {dispatch}=this.props;
		dispatch(getRatePlanList(this.state.ratePlanSearch));
	}
	addDefaultRatePlanClick=(e)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('/admin/defaultRateplanDetails?rateplanid=-1');
	}
	editDefaultRatePlanClick=(e,rpid)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('/admin/defaultRateplanDetails?rateplanid='+rpid);
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Default Rate Plan');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-list-alt"></i> Rate Plan Search</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<DefaultRatePlanList defaultRatePlanListData={this.props.ratePlanList} accessLevel={accessLevelObj||{}} 
				addDefaultRatePlanClick={this.addDefaultRatePlanClick} editDefaultRatePlanClick={this.editDefaultRatePlanClick}/>
			</div>		
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		ratePlanList:state.ratePlanList.ratePlanList
  }
};
export default withRouter(connect(mapStateToProps)(RatePlanPage));
