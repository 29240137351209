import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {isValidForm} from '../../../util/validationMethods';
import {utilMethods}  from '../../../util/util';
import { getCompanyId, isManagement } from '../../../util/customMethods';
import ARDetailByAccountTypeCtrl from '../../../components/reportsComponents/accounting/arDetailByAccountTypeCtrl';
import ReactTableDisplay from '../../../components/reportsComponents/accounting/arDetailByAccountType/ARDetailsByAccTypeConfig';
import { tableColumns } from '../../../components/reportsComponents/accounting/arDetailByAccountType/table-column.config';
import {getARDetailByAccountTypeList, getARDetailByAccountTypeClearList} from '../../../actions/reportAction/accounting/arDetailByAccountTypeAction';
const pageTitle = 'A/R Detail By Account Type';
let arDetailByAccountTypeForm={
	formARDetailByAccountTypeInfo:{}
}

class ARDetailByAccountType extends Component {
	constructor() {
		super();
		this.state = {
			accountType:'',
            accountId:'',
            asOfDate:'',
            idCompany:'',
            exportDataSet:[],
            render: false,
            dataFetched:false
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		this.props.dispatch(getARDetailByAccountTypeClearList());
    }
    onARDetailByAccountTypeFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
    getByCompanyId=()=>{
        let id_company=0;
        if(!isManagement()){
            id_company=getCompanyId();
        }else{
            id_company=this.state.idCompany;
        }
        return id_company;
    }
    getARDetailByAccountType=(e)=>{
        e.preventDefault();
        arDetailByAccountTypeForm.formARDetailByAccountTypeInfo.validateAll();
        if(isValidForm(arDetailByAccountTypeForm.formARDetailByAccountTypeInfo)){
            this.props.dispatch(getARDetailByAccountTypeList(this.state.accountType, this.state.asOfDate, this.getByCompanyId()))
            .then(() => {this.setState({ dataFetched: true })})   
            .catch(err => {
                console.error('fetchReport methods failed with error: ', err);
            });
        }
    }
    setReferenceToReactTable = ref => {
        this.reactTable = ref;
    };

    tableDataChanged = () => {
        console.log('table data has changed!');
        this.setState({
            data: this.reactTable.getResolvedState().sortedData
        });
    };
    exportToExcel = e => {
        e.preventDefault();
        arDetailByAccountTypeForm.formARDetailByAccountTypeInfo.validateAll();
        if(isValidForm(arDetailByAccountTypeForm.formARDetailByAccountTypeInfo)){
            this.props.dispatch(getARDetailByAccountTypeList(this.state.accountType, this.state.asOfDate, this.getByCompanyId()))
            .then(()=>{
                let _exportDataSet=[];                
                let setColHeader1=['ACCOUNT TYPE','AS OF','CURRENT','1-30 DAYS','31-60 DAYS','61-90 DAYS','90+ DAYS','Total'];
                let setColHeader2=['ACCOUNT','#UNPAID','BALANCE'];
                let setCols1= ['accountType','asOfDate','currentDue','thirtyDaysDue','sixtyDaysDue','nintyDaysDue','moreThanNintyDaysDue','total'];
                let setCols2=['account','unPaid','balance'];
                (this.props.arReportByAccTypeResponse||[]).map((rec)=>{
                    let setData=new Array();
                    let subSetData=new Array();
                     setCols1.map((col,i)=>{
                        if(i>1){
                            setData.push(utilMethods.convertToFloat(rec[col]));
                         }else{
                            setData.push(rec[col]);
                         }
                    });
                    subSetData=(rec.accountsReceivableAccTypeDetailList||[]).map((sRec)=>{
                        return setCols2.map((sCol)=>{
                            if(sCol==='unPaid'){
                                return utilMethods.convertToFloat(sRec[sCol]||'',0);
                            }else if(sCol==='balance'){
                                return utilMethods.convertToFloat(sRec[sCol]||'');
                            }else{
                                return sRec[sCol]||'';
                            } 
                        });

                    });
                    _exportDataSet.push({columns:setColHeader1,data:[setData]});
                    _exportDataSet.push({columns:setColHeader2,data:subSetData})
                })                 
                this.setState({exportDataSet:_exportDataSet})
            })
                .then(() =>  this.setState({ dataFetched: true}))   
                .then(() => this.setState({ render: !this.state.render }))
                .catch(err => {
                    console.error('fetchReport methods failed with error: ', err);
                });
            }
        }
	render() {
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> A/R Detail By Account Type</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <ARDetailByAccountTypeCtrl 
                    idCompany={this.state.idCompany}
                    accountTypes={this.props.accountTypes}
                    childCompList={this.props.childCompList}
                    initCallBack={(frm)=>{
						arDetailByAccountTypeForm.formARDetailByAccountTypeInfo=frm;
                    }} asofdate={this.state.asOfDate}
                    onARDetailByAccountTypeFieldChange={(key, value)=>this.onARDetailByAccountTypeFieldChange(key, value)}
                    accountType={this.state.accountType}
                    getARDetailByAccountType={(e)=>this.getARDetailByAccountType(e)}
                    exportARDetailsToExcel={(e)=>this.exportToExcel(e)}/>
                    {this.state.render ? 
                                <ExcelFile hideElement={true}
                                    filename={`${"ARDetailsByAccountType_"}${moment().format('MMDDYYhhmm')}`}
                                    fileExtension={'xlsx'}
                                >
                                 <ExcelSheet dataSet={this.state.exportDataSet}  name={'Activity Report'}>
                                </ExcelSheet>
                                </ExcelFile>
                            : null}
                    {this.state.dataFetched &&
                        <ReactTableDisplay
                            data={this.props.arReportByAccTypeResponse}
                            tableDataChanged={this.tableDataChanged}
                            tableReference={this.setReferenceToReactTable}
                            columns={tableColumns}
                            onExpandedChange={this.props.onExpandedChange}
                        />
                    }
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		accountTypes:state.accountTypes.accountTypes,
        accountList:state.reportsAccountList.reportAccountList,
        arReportByAccTypeResponse: state.arDetailsAccountTypeList.arDetailsAccType,
        childCompList:state.searchInvoiceList.childComList
	}
};

export default withRouter(connect(mapStateToProps)(ARDetailByAccountType));
