import React, { Component,Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import {ToggleButtonGroup,ToggleButton} from 'react-bootstrap'
class APKVersionList extends Component {
  constructor(props){
    super(props);
    this.state={
      ActiveInactiveFilterVal:1
    }
    this.handleActiveInactiveFilterChange=this.handleActiveInactiveFilterChange.bind(this);
  }
  handleActiveInactiveFilterChange=(value,e)=>{
    this.setState(
      {ActiveInactiveFilterVal:[parseInt(e.currentTarget.value)]},   
    this.props.onSearchVersionChange({isActive:parseInt(e.currentTarget.value)})
    );
  }
  render() {
    const columns=[{
      Header: 'Version Name',
      accessor: 'apkVersion', // String-based value accessors!
      filterable:true,
      Cell: props => <span>{props.value}</span>
      
    }, 
    {
      Header: 'Updated Date',
      accessor: 'uploadDateTime',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
     
    },
    {
      Header: 'ACTION',
      filterable:false,
      Cell: (props) => <>
      <span>{props.original.isDeactivated}</span>
      <button type="button" className={`btn btn-sm ${props.original.isDeactivated? 'btn-success':'btn-danger'}`} onClick={(e)=>{
        this.props.onAPKVersionActive_Deactive(props.original.recId,!props.original.isDeactivated);
      }}>
      <i className={`fa ${props.original.isDeactivated?'fa-eye':'fa-eye-slash'}`} aria-hidden="true"></i> {props.original.isDeactivated?'Activate':'Deactivate'}</button></>,
    }];
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-10">
      <div className="row">
        <div className="col-md-7">
          <h5 className="weight-500 mb-10">APK Versions</h5>
        </div>
        <div className="col-md-5">
          <ToggleButtonGroup
          bsPrefix="btn-group-toggle btn-group pull-right"
          type="checkbox"
          value={this.state.ActiveInactiveFilterVal}
          onChange={this.handleActiveInactiveFilterChange} defaultValue={1}
          >
            <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}  >Active </ToggleButton>
            <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>Inactive</ToggleButton>
            <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>All</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>                           
      </div> 
      <div className="col-md-12 col-sm-12 mb-10">				
        <TmReactTable
           ref={(table)=>this.apkTable=table}
        onFilteredChange={()=>{
          //let data=this.apkTable.tmReactTable.getResolvedState().sortedData
         // this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={this.props.apkVersionListData||[]}
            columns={columns}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default APKVersionList;
