import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {tableColumns} from '../../../util/tableColumnUtil/driversColumn';
import { isValidForm } from '../../../util/validationMethods';
import { notify } from '../../../util/notifications';
import printJS from 'print-js';
import {isManagement,getCompanyId} from '../../../util/customMethods'
import {cleanDriverPerReport,emailDriverPerformanceSum,retrieveDriverReport} from '../../../actions/reportAction/driverAction/driverPerformance'
import {initDriverState} from '../../../util/stateUtil/initialStateForComponents';
import DriverPerformanceSummaryCtrl from '../../../components/reportsComponents/drivermanagement/driverPerformanceSummaryCtrl';
import DriverPerformanceSummaryTable from '../../../components/reportsComponents/drivermanagement/driverPerformanceSummaryTable';
const printJSHeaders = tableColumns.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
const pageTitle = 'Driver Performance Summary'
let DriverPerformanceSummaryForm={
}

class DriverPerformanceSummary extends Component {
	state = {
         ...initDriverState
		}
        Go = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(retrieveDriverReport(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success', res.statusMsg, this.props.refNotify);
                this.setState({ render: false });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
        Export = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(retrieveDriverReport(payload, status));
            if (res.statusCode === 200) {
                this.setState({ render: !this.state.render });
                notify.createNotification('success','Report exported successfully.', this.props.refNotify);
                this.setState({ render: false });
            } else {
                this.setState({ render: false });
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
        Print = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(retrieveDriverReport(payload, status));
            if (res.statusCode === 200) {
                printJS({
                    printable: this.props.data,
                    properties: printJSHeaders,
                    type: 'json',
                    documentTitle: 'Driver performance Report'
                });
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
        email = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if(payload){
            if (isManagement() && (this.state.idCompany || 0) == 0) {
                status = true;
            }
            const res = await dispatch(emailDriverPerformanceSum(payload, status));
            if (res.statusCode === 200) {
                notify.createNotification('success',res.statusMsg, this.props.refNotify);
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }
        }
    
            /* Building all invoice object for post request */
            buildObject = () => {
                const stateObj = this.state;
                const constractObj = {
                    idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
                    fromDate: stateObj.fromDate, 
                    toDate: stateObj.toDate,
                };
                return constractObj;
            }
	componentWillMount = () => {
        const { dispatch } = this.props;
        dispatch(cleanDriverPerReport());
		document.title = pageTitle;
    }
    onDriverPerformanceSummaryFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let stateObj = this.state;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Driver Performance Summary</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <DriverPerformanceSummaryCtrl 
                    stateObj={stateObj}
                    onDriverPerformanceSummaryFieldChange={(key, value)=>this.onDriverPerformanceSummaryFieldChange(key, value)}
                    initCallBack={(frm)=>{
						DriverPerformanceSummaryForm=frm;
                    }} startdate={this.state.startDate} enddate={this.state.endDate} 
                    childCompList={this.props.childCompList} data={this.props.data}
                    Go={()=>{
                        DriverPerformanceSummaryForm.validateAll()
                        if(isValidForm(DriverPerformanceSummaryForm)){
                            this.Go();
                        }}}  
                        Export={()=>{
                            DriverPerformanceSummaryForm.validateAll()
                            if(isValidForm(DriverPerformanceSummaryForm)){
                                this.Export();
                            }}}
                    email={()=>{
                        DriverPerformanceSummaryForm.validateAll()
                        if(isValidForm(DriverPerformanceSummaryForm)){
                            this.email();
                        }}} Print={()=>{
                            DriverPerformanceSummaryForm.validateAll()
                            if(isValidForm(DriverPerformanceSummaryForm)){
                                this.Print();
                            }}}
                    />
                    <DriverPerformanceSummaryTable 
                    cols={tableColumns}
                    data={this.props.data}/>
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
        childCompList: state.searchInvoiceList.childComList,
        data:state.driverObj.driverPerformanceData
	}
};

export default withRouter(connect(mapStateToProps)(DriverPerformanceSummary));
