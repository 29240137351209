//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
export const ARDetailsClmn = [
    {
        accessor: 'currentDue',
        Header: 'CURRENT',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.currentDue}`
    },
    {
        accessor: 'thirtyDaysDue',
        Header: '1-30 DAYS',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.thirtyDaysDue}`
    },
    {
        accessor: 'sixtyDaysDue',
        Header: '31-60 DAYS',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.sixtyDaysDue}`
    },
    {
        accessor: 'nintyDaysDue',
        Header: '61-90 DAYS',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.nintyDaysDue}`
    },
    {
        accessor: 'moreThanNintyDaysDue',
        Header: '90+ DAYS',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.moreThanNintyDaysDue}`
    },
    {
        accessor: 'total',
        Header: 'TOTAL',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.total}`
    }
];
