import React, { Component,Fragment } from 'react';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';
class APKUpdateList extends Component {
  render() {
    const columns=[{
      Header: 'DEVICE ID',
      accessor: 'deviceId', // String-based value accessors!
      filterable:true,
      Cell: props => <span>{props.value}</span>
      
    }, 
    {
      Header: 'TRUCK',
      accessor: 'truckNumber',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
     
    },
    {
      Header: 'USER ID',
      accessor: 'driverId',
      sortMethod: (a, b) => a.localeCompare(b, 'en', { numeric: true }),
      filterable:true,
      Cell: props => <a className="btn-link" href="javascript:void(0)">{props.value}</a>,
      
    },
    {
      Header: 'LAST LOGIN TIME',
      accessor: 'truckLoginTime',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
     
    },
    {
      Header: 'CURRENT USER VERSION',
      accessor: 'currentVersion',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      
    },
    {
      Header: 'CURRENTLY ASSIGNED VERSION',
      accessor: '_updateToVersion',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      
    },
    {
      Header: 'AVAILABLE VERSIONS',
      accessor: 'availableversions',
      filterable:false,
      Cell: props =><div className="form-group">
      <fieldset disabled={(this.props.accessLevel.basicAccess.edit||false)===true?false:true}>
        <select className="form-control" data-idx={props.index} value={props.original.updateToVersion} onChange={(e)=>{
          let _idx=e.target.getAttribute('data-idx');
          this.props.onDeviceVersionChange('SINGLE',_idx,e.target.value);
        }}>
          {
            this.props.apkVersions.map((item,k)=>{
            return <option key={k} value={item.apkVersion}>{item.apkVersion}</option>
          })}
        </select>
        </fieldset>
      </div>,
     
    },
    {
      Header: 'ACTION',
      filterable:false,
      Cell: (props) => <button type="button" disabled={(this.props.accessLevel.basicAccess.edit||false)===true?false:true} className="btn btn-danger btn-sm" onClick={(e)=>{
        this.props.onAPKVersionDelete(props.original.employeeId);
      }}>
      <i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>,
    }];
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-10">				
        <TmReactTable
           ref={(table)=>this.apkTable=table}
        onFilteredChange={()=>{
          //let data=this.apkTable.tmReactTable.getResolvedState().sortedData
         // this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={this.props.apkUpdateListData||[]}
            columns={columns}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default APKUpdateList;
