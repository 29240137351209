import React, { Component, Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { Link } from 'react-router-dom';
class SummaryByDetailsTable extends Component {
	render() {
 const detailsColumns = [
    {
        accessor: 'displayInvoiceId',
        Header: 'invoice',
        style: { textAlign: 'center' },
        Cell: props => <Link to={`/dispatch/serviceCallInfo/${props.original.invoice}`} target="_blank">{props.original.displayInvoiceId}</Link>,
        width: 160,
        Footer:(<span>Total :</span>)
    },
    {
        accessor: 'invoiceCreatedDate',
        Header: 'Invoice Created Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'requestedBy',
        Header: 'Requested By',
        style: { textAlign: 'center' },
        width: 160
    },
    {
        accessor: 'ratePlan',
        Header: 'Rate Plan',
        style: { textAlign: 'center' },
        width: 160
    },
    {
        accessor: 'reason',
        Header: 'Reason',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'towType',
        Header: 'Tow Type',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'driver',
        Header: 'Driver',
        style: { textAlign: 'center' },
        width: 120
    },
    
    {
        accessor: 'truck',
        Header: 'Truck',
        style: { textAlign: 'center' },
        width: 120
    },
    
    {
        accessor: 'totalCharges',
        Header: '* Total Charges',
        style: { textAlign: 'center' },
        width: 150,
        //Cell: props => `$ ${props.original.totalCharges.toFixed(2)}`,
    //     Footer:( `$ ${((this.props.driverReportDetails||[]).reduce((a, b)=>
    //         a + convertToFloat(b['totalCharges'],2) 
    //    , 0)).toFixed(2)}`)
    }
];
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={this.props.data||[]}
                        columns={detailsColumns}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default SummaryByDetailsTable;