export const SET_NOTIFY_REF='SET_NOTIFY_REF';
export const GET_COMPANY_LIST='GET_COMPANY_LIST';
export const GET_COMPANY_RECORD='GET_COMPANY_RECORD';
export const SET_AUTH_INFO='SET_AUTH_INFO';
export const SET_ACCESS_LEVELS='SET_ACCESS_LEVELS';
export const SET_DASHBOARD_ACCESS_LEVELS='SET_DASHBOARD_ACCESS_LEVELS';
export const GET_ACCOUNTS_DROPDOWN='GET_ACCOUNTS_DROPDOWN';
export const GET_ASSIGN_COM_REC = 'GET_ASSIGN_COM_REC';
export const LIST_OF_ACCOUNTS_LINK = 'LIST_OF_ACCOUNTS_LINK';

/*start -Launch actions types*/
export const GET_CALLS_COMPLETED = 'GET_CALLS_COMPLETED';
export const GET_SALVAGE_VEHICLE_COUNT = 'GET_SALVAGE_VEHICLE_COUNT';
export const GET_DEPOSITS = 'GET_DEPOSITS';
export const GET_STORAGE_COUNT = 'GET_STORAGE_COUNT';
export const GET_RELEASE_UNITS = 'GET_RELEASE_UNITS';
export const GET_NO_AVRREPORT = 'GET_NO_AVRREPORT';
export const GET_PAYLESS_INVENTORY = 'GET_PAYLESS_INVENTORY';
export const GET_COMMISSIONS = 'GET_COMMISSIONS';
export const GET_SUMMARY_DASHBOARD = 'GET_SUMMARY_DASHBOARD';
export const GET_VIN_DETAILS='GET_VIN_DETAILS';
export const ADMIN_GET_INV_MORETHAN='ADMIN_GET_INV_MORETHAN';
export const ADMIN_GET_INV_MORETHAN_DAYS='ADMIN_GET_INV_MORETHAN_DAYS';
export const ADMIN_GET_EXPIRE_ACCOUNTS='ADMIN_GET_EXPIRE_ACCOUNTS';
export const ADMIN_GET_EXPIRE_ACCOUNTS_DAYS='ADMIN_GET_EXPIRE_ACCOUNTS_DAYS';
export const ADMIN_GET_ALL_ACCOUNTS='ADMIN_GET_ALL_ACCOUNTS';
/*End -Launch actions types */


/* start - admin actions */

export const GET_ALL_ACCOUNTS='GET_ALL_ACCOUNTS';
export const GET_ACCOUNT_REC='GET_ACCOUNT_REC';
export const GET_ACCOUNT_TYPE='GET_ACCOUNT_TYPE';
export const SET_SELECTED_ACCOUNT_RATE_PLAN='SET_SELECTED_ACCOUNT_RATE_PLAN';
export const GET_SALES_REP='GET_SALES_REP';
export const GET_REVIEW_LOCATION='GET_REVIEW_LOCATION';
export const GET_ALL_ACCOUNT_NOTES='GET_ALL_ACCOUNT_NOTES';
export const GET_ALL_BILLING_NOTES='GET_ALL_BILLING_NOTES';
export const GET_ACC_CONTACT_DETAILS='GET_ACC_CONTACT_DETAILS';
export const GET_BILLING_CONTACT_DETAILS='GET_BILLING_CONTACT_DETAILS';


export const GET_ALL_AUCTIONS='GET_ALL_AUCTIONS';
export const GET_AUCTION_RECORD='GET_AUCTION_RECORD';
export const UPDATE_AUCTION_FIELD='UPDATE_AUCTION_FIELD';
export const SAVE_AUCTION_RECORD='SAVE_AUCTION_RECORD';
export const DELETE_AUCTION_RECORD='DELETE_AUCTION_RECORD';
export const CLEAR_AUCTION_RECORD='CLEAR_AUCTION_RECORD';


export const GET_ALL_TRUCKS='GET_ALL_TRUCKS';
export const GET_TRUCK_RECORD='GET_TRUCK_RECORD';
export const UPDATE_TRUCK_FIELD='UPDATE_TRUCK_FIELD';
export const SAVE_TRUCK_RECORD='SAVE_TRUCK_RECORD';
export const DELETE_TRUCK_RECORD='DELETE_TRUCK_RECORD';
export const CLEAR_TRUCK_RECORD='CLEAR_TRUCK_RECORD';
export const GET_ALL_TRUCK_SERVICE_DETAILS='GET_ALL_TRUCK_SERVICE_DETAILS';
export const GET_ALL_TRUCK_NOTES='GET_ALL_TRUCK_NOTES';


export const GET_ALL_TOW_TYPES='GET_ALL_TOW_TYPES';
export const GET_TOWTYPE_RECORD='GET_TOWTYPE_RECORD';
export const UPDATE_TOWTYPE_FIELD='UPDATE_TOWTYPE_FIELD';
export const SAVE_TOWTYPE_RECORD='SAVE_TOWTYPE_RECORD';
export const DELETE_TOWTYPE_RECORD='DELETE_TOWTYPE_RECORD';
export const CLEAR_TOWTYPE_RECORD='CLEAR_TOWTYPE_RECORD';

export const GET_ALL_VENDORS='GET_ALL_VENDORS';
export const GET_VENDOR_RECORD='GET_VENDOR_RECORD';
export const UPDATE_VENDOR_FIELD='UPDATE_VENDOR_FIELD';
export const SAVE_VENDOR_RECORD='SAVE_VENDOR_RECORD';
export const DELETE_VENDOR_RECORD='DELETE_VENDOR_RECORD';
export const CLEAR_VENDOR_RECORD='CLEAR_VENDOR_RECORD';

export const GET_STATEMENT_MESSAGE='GET_STATEMENT_MESSAGE';
export const SET_STATEMENT_MESSAGE='SET_STATEMENT_MESSAGE';

export const GET_ALL_LOCATIONS='GET_ALL_LOCATIONS';
export const GET_ALL_LOT_LOCATIONS='GET_ALL_LOT_LOCATIONS';
export const GET_SINGLE_LOCATION="GET_SINGLE_LOCATION";
export const CLEAR_LOCATION_RECORD='CLEAR_LOCATION_RECORD';

export const GET_ALL_STATES='GET_ALL_STATES';

export const ANY_OF_YOUR_ACTION_TYPES = 'ANY_OF_YOUR_ACTION_TYPES';
export const ANY_OF_YOUR_ACTION_TYPES_FINISH = 'ANY_OF_YOUR_ACTION_TYPES_FINISH';

export const GET_ALL_RATEPLANS='GET_ALL_RATEPLANS';
export const GET_RATEPLAN_RECORD='GET_RATEPLAN_RECORD';
export const CLEAR_RATEPLAN_RECORD='CLEAR_RATEPLAN_RECORD';
export const GET_CHARGE_LIST='GET_CHARGE_LIST';
export const GET_CHARGE_LIST_INDEX='GET_CHARGE_LIST_INDEX';
export const SET_CUSTOM_CHARGE_TYPE='SET_CUSTOM_CHARGE_TYPE';


export const GET_BILLING_EMAIL='GET_BILLING_EMAIL';
export const GET_EMAIL_REC='GET_EMAIL_REC';
export const SAVE_BILLING_EMAIL='SAVE_BILLING_EMAIL';

export const GET_COMMISSION_TYPES='GET_COMMISSION_TYPES';
export const SET_COMMISSION_TYPE='SET_COMMISSION_TYPE';
export const GET_DRIVER_LEVELS='GET_DRIVER_LEVELS';
export const SET_DRIVER_LEVEL='SET_DRIVER_LEVEL';
export const GET_COMMISSION_DETAIL_LIST='GET_COMMISSION_DETAIL_LIST';
//export const GET_USER_DEFAULT_CHARGES='GET_USER_DEFAULT_CHARGES';
export const GET_COMMISSION_DETAIL_REC='GET_COMMISSION_DETAIL_REC';
export const SET_COMMISSION_DETAIL_FIELD='SET_COMMISSION_DETAIL_FIELD';
export const GET_DEFAULT_CHARGES='GET_DEFAULT_CHARGES';
export const GET_COMPANY_PAY_DETAILS = 'GET_COMPANY_PAY_DETAILS';


//Fill Dropdowns data

export const GET_STATES='GET_STATES';
export const GET_DEPARTMENTS='GET_DEPARTMENTS';
export const GET_LICENSE_TYPES='GET_LICENSE_TYPES';
export const GET_DEFAULT_DASHBOARD_TAILS='GET_DEFAULT_DASHBOARD_TAILS';
export const GET_ALL_MODULES='GET_ALL_MODULES';
export const GET_CALL_REASONS='GET_CALL_REASONS'
export const GET_LOCATION_LIST='GET_LOCATION_LIST'
export const GET_COMPANY_TYPE='GET_COMPANY_TYPE'
export const GET_REVIEW_LOCATION_LIST='GET_REVIEW_LOCATION_LIST'
export const GET_REVIEW_INVOICE_LIST='GET_REVIEW_INVOICE_LIST'

// constant variables for basic operations
export const ADD_ACTION= 'ADD';
export const EDIT_ACTION= 'EDIT';
export const DEL_ACTION= 'DEL';

export const GET_ALL_EMPLOYEES='GET_ALL_EMPLOYEES';
export const GET_EMPLOYEE_REC="GET_EMPLOYEE_REC";

export const GET_ALL_APK_RECORDS_DATA='GET_ALL_APK_RECORDS_DATA'
export const GET_ALL_APK_VERSIONS='GET_ALL_APK_VERSIONS'

/* End - admin actions */

/*Dispatch actions */
export const GET_DISPATCH_CALL_LIST='GET_DISPATCH_CALL_LIST';
export const GET_DISPATCH_TRUCK_LIST='GET_DISPATCH_TRUCK_LIST';
export const GET_INVOICE_DETAILS='GET_INVOICE_DETAILS';

export const UPLOAD_ATTACHMENTS='UPLOAD_ATTACHMENTS';

export const GET_INVOICE_SEARCH_LIST='GET_INVOICE_SEARCH_LIST';
/* Dispatch Invoice */
export const GET_ACCOUNT_LIST='GET_ACCOUNT_LIST';
export const GET_MONETIZATION_CHARGE_LIST='GET_MONETIZATION_CHARGE_LIST';
export const GET_INVOICE_INFO='GET_INVOICE_INFO';
export const GET_REQ_ACCOUNT_INFO='GET_REQ_ACCOUNT_INFO';
export const GET_ACCOUNT_BY_TYPE = 'GET_ACCOUNT_BY_TYPE';
export const GET_INVOICE_COMM = 'GET_INVOICE_COMM';
export const PRINT_EMAIL_RECEIPT = 'PRINT_EMAIL_RECEIPT';
export const RATEPLAN_INFO_DETAILS = 'RATEPLAN_INFO_DETAILS';
/*End - dispatch actions */
/**Management child company list */
export const LIST_OF_CHILD_COMP = 'LIST_OF_CHILD_COMP';
export const LIST_OF_ASSOCIATE_COMP = 'LIST_OF_ASSOCIATE_COMP';
export const INVOICE_PAY_OBJ = 'INVOICE_PAY_OBJ';

/* Reports Invoice */
export const GET_REPORT_ACCOUNT_LIST='GET_REPORT_ACCOUNT_LIST';
export const GET_ARSUMMARY_LIST='GET_ARSUMMARY_LIST';
export const GET_ARDETAILS_LIST='GET_ARDETAILS_LIST';
export const GET_ARDETAILBYACCOUNTTYPE_LIST='GET_ARDETAILBYACCOUNTTYPE_LIST';
export const GET_MONETIZAION_LIST='GET_MONETIZAION_LIST';
export const GET_CRDETAIL_LIST='GET_CRDETAIL_LIST';
export const GET_BATCHPAYMENT_LIST='GET_BATCHPAYMENT_LIST';
export const GET_AUCTIONPAYMENT_LIST='GET_AUCTIONPAYMENT_LIST';
export const GET_AUDIT_REPORT = 'GET_AUDIT_REPORT';
export const GET_RECIPT_REPORT = 'GET_RECIPT_REPORT';

/* All invoice report */
export const GET_TRUCK_LIST='GET_TRUCK_LIST';
export const GET_DRIVER_LIST='GET_DRIVER_LIST';
export const GET_REPORTS_DATA='GET_REPORTS_DATA';
/* Storage Inventory report */
export const GET_INVENTORY_DATA='GET_INVENTORY_DATA';
/*Driver performance sum. report */
export const GET_DRIVER_PERFORMANCE_SUM = 'GET_DRIVER_PERFORMANCE_SUM';
/*Invoice Manage reports data. */
export const GET_INVOICE_MANAGE_DATA = 'GET_INVOICE_MANAGE_DATA';
/*Invoice Manage reports data. */
export const GET_SUMMARY_REPORT = 'GET_SUMMARY_REPORT';
export const GET_SUMMARY_DETAILS_REPORT = 'GET_SUMMARY_DETAILS_REPORT';
export const GET_RESPONSE_REPORT = 'GET_RESPONSE_REPORT';
/*End - Reports actions */