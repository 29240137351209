//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
export const ManagedReceivableDetailsClmn = [
    {
        accessor: 'currentDue',
        Header: 'CURRENT',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'thirtyDaysDue',
        Header: '1-30 DAYS',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'sixtyDaysDue',
        Header: '31-60 DAYS',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'nintyDaysDue',
        Header: '61-90 DAYS',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'moreThanNintyDaysDue',
        Header: '90+ DAYS',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'totalReceivable',
        Header: 'TOTAL RECEIVABLE',
        style: { textAlign: 'center' },
        Cell: props => `$ ${props.original.totalReceivable || 0}`
    }
];


export const RetailReceivableDetailsClmn = [
    {
        accessor: 'billableType',
        Header: 'BILLING TYPE',
        style: { textAlign: 'center' },
        Cell: props => `${(props.original.billableType || '') ==='PerMonth' ?
         'Per Month' : (props.original.billableType || '') ==='PerTow' ? 
         'Per Tow' : (props.original.billableType || '') ==='PerUser' ? 
         'Per User' : props.original.billableType}`
    },
    {
        accessor: 'billableRate',
        Header: 'BILLING RATE',
        style: { textAlign: 'center' },
        Cell: props => `$ ${props.original.billableRate  || 0}`
    },
    {
        accessor: 'asOfDate',
        Header: 'AS OF DATE',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'totalReceivable',
        Header: 'TOTAL RECEIVABLE',
        style: { textAlign: 'center' },
        Cell: props => `$ ${props.original.totalReceivable || 0}`
    }
];
