import React from 'react';
import { Redirect, Route} from 'react-router-dom';
import * as authenticationAction from '../../actions/authenticateActions.js'
import {LAUNCH_LOGIN_URL} from '../../util/constants.js'
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
       (authenticationAction.isAuthenticated()===true)
            ? <Component {...props} />
            : process.env.NODE_ENV==='production'?
            <Redirect to={LAUNCH_LOGIN_URL} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)