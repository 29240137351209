import React, { Component } from 'react';
import {connect} from  'react-redux';
import AccountsWithoutNewInvoices from './accounts/accountswithoutnewinvoices/accountswithoutnewinvoices';
import AccountsWillExpire from './accounts/accountswillexpire/accountswillexpire';
import DiabledAccounts from './accounts/disabledaccounts/disabledaccounts';
import ExpiredAccounts from './accounts/expiredaccounts/expiredaccounts';
import CashOnly from './accounts/cashonly/cashonly';
import AccountByDepartment from './accounts/accountbydepartment/accountbydepartment';
import * as adminActions from '../../../actions/launchAction/adminDashboardActions';
import {dashboardViewAccess,dashboardTailViewAccess} from '../../../util/accessLevelMethods';
import {isManagement} from '../../../util/customMethods';
const pageTitle="Admin Dashboard";
const moduleName="Admin";
const dispatchDashboardTailsTitles={
    AccountsWithoutNewInvoices:'Account without new invoices',
    AccountsWillExpire:'Accounts that will expire',
    ExpiredAccounts:'Expired Accounts',
    DisabledAccounts:'Disabled Accounts',
    AccountByDepartment:'Account By Department',
    CashOnly:'Cash Only'
  }
class Admin extends Component {
    constructor(props){
        super(props);
        this.getTailsViewAccess=this.getTailsViewAccess.bind(this);
        this.getInvoiceMoreThan = this.getInvoiceMoreThan.bind(this);
        this.getExpireAccounts = this.getExpireAccounts.bind(this);
        this.saveInvoiceMoreThanDaysCount = this.saveInvoiceMoreThanDaysCount.bind(this);
        this.saveExpireAccountsDaysCount = this.saveExpireAccountsDaysCount.bind(this);
    }
    getInvoiceMoreThan=(range)=>{
        const {dispatch} =this.props;
        dispatch(adminActions.getInvoiceMoreThan(range)); 
    }
    saveInvoiceMoreThanDaysCount=(range)=>{
        const {dispatch} =this.props;
        dispatch(adminActions.saveInvoiceMoreThanDays(range)); 
    }
    saveExpireAccountsDaysCount=(range)=>{
        const {dispatch} =this.props;
        dispatch(adminActions.saveExpireAccountsDays(range)); 
    }
    getExpireAccounts=(range)=>{
        const {dispatch} =this.props;
        dispatch(adminActions.getExpireAccounts(range)); 
    }
    componentWillMount=()=> { 
        document.title=pageTitle
        const {dispatch} =this.props;
        dispatch(adminActions.getInvoiceMoreThan(this.props.adminDashboard_Inv_More_Days));
        dispatch(adminActions.getExpireAccounts(this.props.adminDashboard_Exp_Acc_Days));
        dispatch(adminActions.getAllAccounts());          
    }
    getTailsViewAccess=()=>{
        let resultObj=new Object();
        (this.props.dashboardAccess[moduleName]||[]).map((item)=>{
            resultObj[item.tileName.toLowerCase()]=dashboardTailViewAccess(moduleName,item.tileName,this.props.dashboardAccess)
        });
        return resultObj;
    }
    render() {
        return (
            <> 
            <div className="page-header">
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12">
                        <div className="title">
                            <h4><i className="fa fa-lock"></i> {pageTitle}</h4>
                        </div>
                    </div>
                </div>
            </div>
            {
              dashboardViewAccess(moduleName,this.props.dashboardAccess)===true?
            <div className="pd-10 bg-white border-radius-4 box-shadow container-box tile-card-box">
            {(isManagement()) ? null : <div class="row">
                <div class="col-xl-12 col-md-12 col-lg-12">                                                                                          
                    {
                        this.getTailsViewAccess()[dispatchDashboardTailsTitles.AccountsWithoutNewInvoices.toLowerCase()]===true?
                        <AccountsWithoutNewInvoices  
                    getInvoiceMoreThan={(range)=>{this.getInvoiceMoreThan(range)}}
                    saveInvoiceMoreThanDaysCount={(range)=>{this.saveInvoiceMoreThanDaysCount(range)}}
                    adminDashboardInvoiceMoreThan={this.props.adminDashboard_Inv_More}
                    daysCount={this.props.adminDashboard_Inv_More_Days}/>:null

                    }
                    {
                        this.getTailsViewAccess()[dispatchDashboardTailsTitles.AccountsWillExpire.toLowerCase()]===true?
                    
                    <AccountsWillExpire 
                    getExpireAccounts={(range)=>{this.getExpireAccounts(range)}}
                    saveExpireAccountsDaysCount={(range)=>{this.saveExpireAccountsDaysCount(range)}}
                    adminDashboardexpireAccounts={this.props.adminDashboard_Exp_Acc}
                    daysCount={this.props.adminDashboard_Exp_Acc_Days}/>:null
                    }                        
                     {
                        this.getTailsViewAccess()[dispatchDashboardTailsTitles.ExpiredAccounts.toLowerCase()]===true? 
                    <ExpiredAccounts 
                    accountsCount={this.props.adminDashboard_All_Accounts}/> :null
                    }                        
                     {
                        this.getTailsViewAccess()[dispatchDashboardTailsTitles.DisabledAccounts.toLowerCase()]===true?
                    <DiabledAccounts 
                    accountsCount={this.props.adminDashboard_All_Accounts}/>:null
                    }                        
                     {
                        this.getTailsViewAccess()[dispatchDashboardTailsTitles.AccountByDepartment.toLowerCase()]===true?
                    <AccountByDepartment 
                    accountsCount={this.props.adminDashboard_All_Accounts}/>:null
                    }                        
                     {
                        this.getTailsViewAccess()[dispatchDashboardTailsTitles.CashOnly.toLowerCase()]===true?
                    <CashOnly 
                    accountsCount={this.props.adminDashboard_All_Accounts}/> :null
                    }                        
                </div>
                </div>}</div>:
            <div className="pd-10">
            <div className="error-page-wrap text-center color-dark">
            <h1 className="color-dark weight-500">Error: 403 Forbidden</h1>
            <p>Sorry, access to this resource on the server is denied.
            </p>
            </div>
            </div>
            }</>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        adminDashboard_Inv_More: state.adminDashboard.invoiceMoreThan,
        adminDashboard_Exp_Acc: state.adminDashboard.expireAccounts,
        adminDashboard_Inv_More_Days: state.adminDashboard.invoiceDays, 
        adminDashboard_Exp_Acc_Days: state.adminDashboard.expiryDays,
        adminDashboard_All_Accounts: state.adminDashboard.allAccounts,
        dashboardAccess:state.dashboardAccess
    }
  };
export default connect(mapStateToProps)(Admin);
