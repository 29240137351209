import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const defaultDispatchDashboard={
    "inprogressInvoice":0,
    "todayCompletedCall":0,
    "unassignedInvoice":0,
    "truckAvailable":0  
}

export const dispatchDashboardReducer = (state = defaultDispatchDashboard, action) => {
    switch (action.type) {
        case actionTypes.GET_SUMMARY_DASHBOARD:
            return {...state , summaryData:(action.summaryDashboardData || defaultDispatchDashboard)};
        default:
            return state;
    }
};