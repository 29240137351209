import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import AccountNotes from '../accountInfo/accountNotes';
import AccountInfoContactList from '../accountInfo/accountInfoContactList';
import { isValidForm, required, phone, email, zipValidation } from '../../../../util/validationMethods';
import { paymentType, statementType } from '../../../../util/constants.js'
import ReactSelect from '../../../utilComponents/tmSlimSelect/reactSelect';
import { getCompanyId } from '../../../../util/customMethods';

class Billing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            billingContactRec: {},
            billingContactRecIndex: -1
        }
        this.prepareSelectedList = this.prepareSelectedList.bind(this);
        this.checkAccess = this.checkAccess.bind(this);
    }
    checkAccess = () => {
        if ((this.props.accountBasicInfo.companyId === getCompanyId()) || (this.props.accountBasicInfo.companyId === 0)) {
            if (this.props.accountBasicInfo.accountId === 0) {
                if ((this.props.accessLevel.basicAccess.add || false) === true) {
                    return false
                } else {
                    return true
                }
            } else {
                if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                    return false;
                } else {
                    return true;
                }
            }
        }else{
            return true;
        }
    }
    prepareSelectedList = (paymentTypeString) => {
        let pTypeList = []
        paymentTypeString.split(',').map((option) => {
            let idx = paymentType.findIndex((ptype) => {
                return ptype.value === option
            });
            if (idx > -1) {
                pTypeList.push({ value: paymentType[idx].value, label: paymentType[idx].text })
            }
        });
        return pTypeList
    }
    render() {
        const { allStates,accountBasicInfo,userDefaultCharges } = this.props;
        return (<Fragment>
            <div className="pd-10">
            <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <h6 className="text-blue text-bg-color form-group">BILLING ADDRESS</h6>
                    </div>
                </div>
                <Form ref={c => {this.formBilling = c;this.props.initCallBack(c); }}>
                    <fieldset disabled={this.checkAccess()}>
                        <div className="row">
                            <div className="col-md-7 col-sm-12 mb-10">
                                <div className="row">
                                    <div className="col-sm-8">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" id="chBillingAddress" className="custom-control-input" onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.props.setBillAddressAsAccountAddress(e)
                                                        }
                                                    }} />
                                                    <label htmlFor="chBillingAddress" className="custom-control-label">SAME AS ACCOUNT INFORMATION</label>
                                             
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingAddressLine1" className="form-label col-form-label">ADDRESS LINE 1</label>
                                            <div className="">
                                                <Input type="text" name="billingAddressLine1"
                                                    value={accountBasicInfo.accountAddress.billingAddressLine1}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please enter Address Line1" validations={[required]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingZipCode" className="form-label col-form-label">ZIP</label>
                                            <div className="">
                                                <Input type="text" name="billingZipCode" value={accountBasicInfo.accountAddress.billingZipCode}
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please enter valid ZIP code" validations={[required, zipValidation]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingAddressLine2" className="form-label col-form-label">ADDRESS LINE 2</label>
                                            <div className="">
                                                <Input type="text" name="billingAddressLine2" value={accountBasicInfo.accountAddress.billingAddressLine2}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingPhoneNo" className="form-label col-form-label">PHONE NO</label>
                                            <div className="">
                                                <Input type="text" name="billingPhoneNo" value={accountBasicInfo.accountAddress.billingPhoneNo}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please enter valid Phone Number." validations={[required, phone]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingCity" className="form-label col-form-label">CITY</label>
                                            <div className="">
                                                <Input type="text" name="billingCity" value={accountBasicInfo.accountAddress.billingCity}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please enter City" validations={[required]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingExt" className="form-label col-form-label">EXT</label>
                                            <div className="">
                                                <Input type="text" name="billingExt" value={accountBasicInfo.accountAddress.billingExt}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingState" className="form-label col-form-label">STATE</label>
                                            <div className="">
                                                <Select className="form-control" type="text" name="billingState"
                                                    value={accountBasicInfo.accountAddress.billingState} onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please select State." validations={[required]}>
                                                    <option value={""}>{""}</option>
                                                    {allStates.map((state, key) => {
                                                        return (
                                                            <option key={key} value={state.optionVal}>
                                                                {state.optionTxt}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingFaxNo" className="form-label col-form-label">FAX NO</label>
                                            <div className="">
                                                <Input type="text" name="billingFaxNo" value={accountBasicInfo.accountAddress.billingFaxNo}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please enter valid Phone Number." validations={[ phone]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="">
                                            <label htmlFor="billingEmail" className="form-label col-form-label">BILLING EMAIL ID</label>
                                            <div className="">
                                                <Input type="text" name="billingEmail" value={accountBasicInfo.accountAddress.billingEmail}
                                                    className="form-control form-control-sm" onChange={(e) => {
                                                        this.props.onAccountAddressFieldChange(e.target.name, e.target.value)
                                                    }} requiredmsg="Please enter valid Email-ID." validations={[required, email]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5"></div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 mb-10">
                                <div className="card">

                                    <div className="card-body pd-10">
                                        <div className="mb-5 row">
                                            <div className="col-sm-12">
                                                <h6>ACCOUNTING TYPE</h6></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="accountingType" id="rdBillable" value="Billable"
                                                                checked={accountBasicInfo.accountingType === 'Billable'} onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value);
                                                                        this.forceUpdate();
                                                                    }

                                                                }} />
                                                            <label htmlFor="rdBillable" className="custom-control-label">BILLABLE ACCOUNT</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="accountingType" id="rdCash" value="Cash"
                                                                checked={accountBasicInfo.accountingType === 'Cash'} onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value);
                                                                        this.forceUpdate();
                                                                    }

                                                                }} />
                                                            <label htmlFor="rdCash" className="custom-control-label">CASH ACCOUNT</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="statementType" className="form-label col-form-label">STATEMENT TYPE</label>
                                                    <div className="">
                                                        <Select className="form-control form-control-sm" name="statementType"
                                                            requiredmsg="Please select Statement Type" value={accountBasicInfo.statementType}
                                                            validations={[required]}
                                                            onChange={(e) => {
                                                                this.props.onAccountBasicInfoFieldsChange(e.target.name, e.target.value)
                                                            }}>     <option value="">{"-- Select Statement Type --"}</option>
                                                            {
                                                                statementType.map((item, key) => {
                                                                    return <option value={item.value} key={key}>{item.text}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="paymentType" className="form-label col-form-label">PAYMENT TYPE</label>
                                                    <div className="">
                                                        <ReactSelect name="paymentType"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            options={paymentType.map((payType, t) => {
                                                                return ({ value: payType.value, label: payType.text })
                                                            })}
                                                            value={this.prepareSelectedList(accountBasicInfo.paymentType)}
                                                            onChange={(selectedOption) => {
                                                                let selectedValues = [];
                                                                selectedValues = (selectedOption || []).map((selectedVal) => {
                                                                    return selectedVal.value
                                                                })
                                                                this.props.onAccountBasicInfoFieldsChange(
                                                                    'paymentType',
                                                                    selectedValues.toString()
                                                                );
                                                                this.forceUpdate();
                                                            }} isDisabled={this.checkAccess()} />


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-12">
                                                <h6>PO ALERTS</h6></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-checkbox custom-control">
                                                            <input type="checkbox" className="custom-control-input" name="isPORequired" id="isPORequired" disabled={(accountBasicInfo.isPORequiredDropOff === 1)}
                                                                checked={accountBasicInfo.isPORequired === 1} onChange={(e) => {
                                                                    this.props.onAccountBasicInfoFieldsChange(
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.checked ? 1 : 0
                                                                    );
                                                                    this.forceUpdate()
                                                                }} />
                                                            <label htmlFor="isPORequired" className="custom-control-label">GET PO</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-checkbox custom-control">
                                                            <input type="checkbox" className="custom-control-input" name="isPORequiredDropOff" disabled={!(accountBasicInfo.isPORequired === 1)}
                                                                checked={accountBasicInfo.isPORequiredDropOff === 1} id="isPORequiredDropOff" onChange={(e) => {
                                                                    this.props.onAccountBasicInfoFieldsChange(
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.checked ? 1 : 0
                                                                    );
                                                                    this.forceUpdate()
                                                                }} />
                                                            <label htmlFor="isPORequiredDropOff" className="custom-control-label">PO MANDATORY</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="paymentType" className="form-label col-form-label">CUSTOM CHARGE</label>
                                                    <div style={{position:'relative',zIndex:9}}>
                                                        <ReactSelect name="customChargeList"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            // options={userDefaultCharges||[].map((charge) => {
                                                            //     return ({ value: charge.chargeListId, label: charge.chargeName })
                                                            // })}
                                                            options={userDefaultCharges.map((val, t) => {
                                                                return ({ value: val.chargeListId, label: val.chargeName })
                                                            })}
                                                            value={(this.props.accountBasicInfo.accountChargeMapping || []).map((item) => {
                                                                const found = userDefaultCharges.find(obj => {return obj.chargeListId === item.chrageId;});
                                                                if(found){
                                                                return ({ value: item.chrageId ,label:found.chargeName||''})
                                                                }
                                                            })}
                                                            onChange={(selectedOption) => {
                                                                let selectedValues = [];
                                                                (selectedOption || []).map((selectedVal) => {
                                                                    selectedValues.push({
                                                                        "chrageId": selectedVal.value,
                                                                        'companyId': getCompanyId()
                                                                    })
                                                                })
                                                                this.props.onAccountBasicInfoFieldsChange(
                                                                    'accountChargeMapping',
                                                                    selectedValues
                                                                );
                                                                this.forceUpdate();
                                                            }} isDisabled={this.checkAccess()} />


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </fieldset>
                </Form>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                        <AccountInfoContactList disabled={this.checkAccess()}
                            isPhoneRequired={true} isEmailRequired={true}
                            contactListData={accountBasicInfo.billingContactForm.accountContacts || []}
                            showDefaultOption={true}
                            onDefaultContactChanged={(e, recIndex) => {
                                let selectedRec = Object.assign({}, accountBasicInfo.billingContactForm.accountContacts[recIndex]);
                                if (selectedRec) {
                                    selectedRec.defaultEmailId = 1;
                                    this.props.onBillingContactSubmit(e, selectedRec, recIndex, true, (success) => {
                                    }, (error) => {
                                    });
                                }
                            }}
                            onContactSubmit={(e, contactObj, index, success, fail) => {
                                this.props.onBillingContactSubmit(e, contactObj, index, success, fail);
                            }}
                            onContactDelete={(index) => {
                                this.props.onBillingContactDelete(index);
                            }}
                        />

                    </div>
                </div>
                <AccountNotes disabled={this.checkAccess()} notesData={accountBasicInfo.billingNotesForm.billingNotes || []}
                    onNotesSubmit={(e, notesObj, index) => {
                        this.props.onBillingNotesSubmit(e, notesObj, index);
                    }}
                    onNotesDelete={(index) => {
                        this.props.onBillingNotesDelete(index)
                    }}
                />
            </div>

            <div className="row pd-10">
            <div className="col-md-12 text-right">
                    <button className="btn btn-sm btn-success" disabled={this.checkAccess()} onClick={(e) => {
                        e.preventDefault();
                        this.formBilling.validateAll();
                        if (!isValidForm(this.formBilling)) {
                        } else {
                            this.props.onBillingInfoSubmit(e)
                        }
                    }}>
                        Next
                    </button>
                {
                    ((this.props.accountBasicInfo.accountId||0) !== 0)?
                    <button 
                        className="btn btn-sm btn-success ml-3" 
                        style={{marginTop: '0px'}}
                        onClick={(e) => {
                        e.preventDefault();
                            this.props.onTimePlanSubmit(e);
                           // this.props.history.push('/')
                        // }
                        }} 
                        disabled={this.checkAccess()}
                    >SAVE ACCOUNT</button>:null
                }  
                </div>  
            </div>
        </Fragment>)
    }
}
export default Billing;
