import React, { Component, Fragment } from 'react';
import {isManagement,ROOT_ADMIN,LAUNCH_URL,ROOT_DISPATCHER} from '../customMethods'
export const inventoryColmun = [
    {
        accessor: 'displayInvoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
          window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/serviceCallInfo/${props.original.invoiceId}`,'_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 100
    },
    {
        accessor: 'serviceCallDate',
        Header: 'Service Call Date',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'companyName',
        Header: 'Company Name',
        style: { textAlign: 'center' },
        width: 100,
        show:isManagement()
    },
    {
        accessor: 'account',
        Header: 'Account',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.accountId}`,'_blank');
        }}>{props.original.account}</a>,
        width: 200
    },
    // {
    //     accessor: 'billToAccount',
    //     Header: 'Bill To',
    //     style: { textAlign: 'center' },
    //     Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
    //       //  window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.billToAccountId}`,'_blank');
    //     }}>{props.original.billToAccount}</a>,
    //     width: 200
    // },
    {
        accessor: 'reason',
        Header: 'Reason',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'year',
        Header: 'Year',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.year||0)===parseInt(filter.value||0),
        width: 100
    },
    {
        accessor: 'make',
        Header: 'Make',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'model',
        Header: 'Model',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'vin',
        Header: 'VIN',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'licensePlateCountry',
        Header: 'License Plate Country',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'licensePlateState',
        Header: 'License Plate State',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'licensePlate',
        Header: 'License Plate #',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'daysInStorage',
        Header: 'Days In Storage',
        style: { textAlign: 'center' },
        width: 100,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
                    parseInt(row._original.daysInStorage||0)===parseInt(filter.value||0)
    },
    // {
    //     accessor: 'markedForSalvage',
    //     Header: 'Marked For Salvage',
    //     style: { textAlign: 'center' },
    //     width: 100
    // },
    {
        accessor: 'lotLocation',
        Header: 'Adderess',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'lotFromVehicle',
        Header: 'Lot Location',
        style: { textAlign: 'center' },
        width: 100
    }
];