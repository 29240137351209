import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AccountPage from './accountPage';
import AccountRecPage from './accountRecPage';
import VendorsPage from './vendorsPage';
import VendorsRecPage from './vendorsRecPage';
import AuctionPage from './auctionPage';
import TowTypePage from './towTypePage';
import StatementMessagePage from './statementMessagePage';
import BillingReportEmailPage from './billingReportEmailPage';
import LocationsPage from './locationsPage';
import LocationsRecPage from './locationsRecPage'
import CommissionPage from './commissionPage'
import CommissionRecPage from './commissionRecPage'
import RatePlanPage from './ratePlanPage'
import DefaultRateplanRecPage from './defaultRatePlanRecPage'
import CustomChargesPage from './customChargesPage'
import TrucksPage from './trucksPage'
import TrucksRecPage from './trucksRecPage'
import ApkUpdatePage from './apkUpdatePage'
import EmployeesPage from './employeesPage'
import EmployeesRecPage from './employeesRecPage'
import CompanyPage from './companyPage';
import CompanyRecord from './companyRecord'
import PaymentDetails from './paymentDetails'
import CustomChgMonetizationConfig from './customChgMonetizationConfig'
import NotFound from './notFound'
class AdminHome extends React.Component {
    constructor(){
      super();
      //this.fillDropDowns=this.fillDropDowns.bind(this)
    }
    render(){
        return(<>
        <Switch>
        <Route exact path="/admin/auction" component={AuctionPage} /> 
        <Route exact path="/admin/account" component={AccountPage} />
        <Route exact path="/admin/accountDetails" component={AccountRecPage} />
        <Route exact path="/admin/vendors" component={VendorsPage} />
        <Route exact path="/admin/vendorsDetails" component={VendorsRecPage} />
        <Route exact path="/admin/employees" component={EmployeesPage} />
        <Route exact path="/admin/employeesDetails" component={EmployeesRecPage} />
        <Route exact path="/admin/towtype" component={TowTypePage} />
        <Route exact path="/admin/rateplan" component={RatePlanPage} />
        <Route exact path="/admin/trucks" component={TrucksPage} />
        <Route exact path='/admin/apkupdate' component={ApkUpdatePage} />
        <Route exact path="/admin/trucksDetails" component={TrucksRecPage} />
        <Route exact path="/admin/defaultRateplanDetails" component={DefaultRateplanRecPage} />
        <Route exact path="/admin/defaultRateplanChargeListDetails" component={CustomChargesPage} />
        <Route exact path="/admin/statementMessage" component={StatementMessagePage}/>
        <Route exact path="/admin/billingReportEmail" component={BillingReportEmailPage}/>
        <Route exact path="/admin/locations" component={LocationsPage} />
        <Route exact path="/admin/locationsDetails" component={LocationsRecPage} />
        <Route exact path="/admin/commission" component={CommissionPage} />
        <Route exact path="/admin/commissionDetails" component={CommissionRecPage} />
        <Route exact path="/admin/company" component={CompanyPage} />
        <Route exact path="/admin/companyRecord" component={CompanyRecord} />
        <Route exact path="/admin/paymentSetting" component={PaymentDetails} />
        <Route exact path="/admin/monetizationConfig" component={CustomChgMonetizationConfig} />
        <Route exact path="/admin/notfound" component={NotFound} />
        <Route path="/" component={AccountPage} />
        </Switch>
        </>)
    }
}
export default AdminHome