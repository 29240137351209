import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../../components/utilComponents/tmDateTimePicker/tmDateTimePicker';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { isManagement } from '../../../util/customMethods';
import { GlobalValues } from '../../../util/constants';
import moment from 'moment';
import { required,NotZero,isNumeric} from "../../../util/validationMethods.js";
class AuctionBatchPaymentCtrl extends Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card form-group">
                            <div className="card-body pd-12">
                                <Form ref={c => {
                                    this.formCRDetailInfo = c;
                                    this.props.initCallBack(c);
                                }} autocomplete="off">
                                    <div className="form-row">
                                        {isManagement() ? <div className="col-4 form-group">
                                            <ReactSelect name="companyId"
                                                closeMenuOnSelect={true}
                                                options={this.props.childCompList.map((item, t) => {
                                                    return ({ value: item.optionVal, label: item.optionText })
                                                })}
                                                value={{
                                                    value: ((this.props.idCompany || '').toString()),
                                                    label: (this.props.childCompList.find((e) => e.optionVal == (this.props.idCompany || '').toString()) || {}).optionText
                                                }}
                                                onChange={(selectedOption) => {
                                                    this.props.onCRDetailFieldChange('idCompany', parseInt(selectedOption.value));
                                                }}
                                            />
                                            <Input hidden={true} value={(this.props.idCompany || '')} validations={[required]} name="idCompany" requiredmsg="Please select company name"></Input>

                                        </div> : null}
                                        <div className="col-4 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2"
                                                onClick={(e) => this.props.getCRDetails(e)}>Go</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>

                        </div>
                        {this.props.isFetched?<div className="card form-group">
                            <div className="card-body pd-12">
                                <Form ref={c => {
                                     this.formCRDetailInfo = c;
                                     this.props.initCallBackBatch(c);
                                }} autocomplete="off">
                                    <div className="form-row">
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <Select className='form-control' value={this.props.auctionBatchPaymentObj.paymentType||""} name="paymentType" onChange={(e) => {
                                                this.props.onCRDetailFieldChange(e.target.name, e.target.value)}}
                                                validations={[required]}  requiredmsg="Please select payment type"
                                                >
                                                <option value="">Payment Type</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Credit">Credit</option>
                                                <option value="Check">Check</option>
                                                <option value="EmployeeAdvance">Employee Advance</option>
                                                <option value="Transfer">Transfer</option>
                                            </Select>
                                        </div>
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <TmDateTimePicker
                                                className="form-control"
                                                selectedDate={
                                                    this.props.auctionBatchPaymentObj.paymentDate === "" || this.props.auctionBatchPaymentObj.paymentDate === null
                                                        ? null
                                                        : moment(
                                                            moment(this.props.auctionBatchPaymentObj.paymentDate,GlobalValues.dateTimeFmtForDispatch),
                                                            GlobalValues.dateTimeFormat
                                                        )._d
                                                }
                                                dateFormat={GlobalValues.dateTimeFormat}
                                                validationArray={[required]}
                                                showYearDropdown={true}
                                                showmonthdropdown={true}
                                                showTimeSelect={true}
                                                name="asOfDate"
                                                reqmsg="paymnet date  is required"
                                                onDateSelectChange={e => {
                                                    this.props.onCRDetailFieldChange('paymentDate', e === null ? '' : moment(e).format(GlobalValues.dateTimeFmtForDispatch))
                                                    this.forceUpdate()
                                                }}
                                            />
                                        </div>
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <input className="form-control" placeholder="check#" name="checkNo" value={this.props.auctionBatchPaymentObj.checkNo||""} onChange={(e) => { this.props.onCRDetailFieldChange(e.target.name, e.target.value) }} 
                                            />
                                        </div>
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <input className="form-control" placeholder="transaction no" name="transactionNo" value={this.props.auctionBatchPaymentObj.transactionNo||""} onChange={(e) => { this.props.onCRDetailFieldChange(e.target.name, e.target.value) }} 
                                            />
                                        </div>
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <Input className="form-control" placeholder="Amount" name="totalPaymentAmt" value={this.props.auctionBatchPaymentObj.totalPaymentAmt||""} onChange={(e) => { this.props.onCRDetailFieldChange(e.target.name, e.target.value) }} 
                                            onBlur={(e) => { this.props.onCRDetailFieldChange(e.target.name, parseFloat(e.target.value==""?0:e.target.value).toFixed(2)) }} 
                                             validations={[required,NotZero,isNumeric]}  requiredmsg="Please enter the payment amount"/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <button
                                                type='submit'
                                                className='btn btn-primary mb-2'
                                                onClick={(e) => this.props.savAuctionBatch(e)}
                                            >
                                                Accept
                            </button>
                                        </div>
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <button
                                                className="btn btn-success "
                                            >
                                                Export
                                </button>
                                        </div>
                                        <div className='form-group mx-sm-3 mb-2'>
                                            <button
                                                type='submit'
                                                className='btn btn-primary mb-2'
                                                onClick={(e) => this.props.getCRDetails(e)}
                                                disabled={this.props.auctionBatchPaymentObj.companyId ||0==0}
                                            >
                                                Refresh
                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>

                        </div>:null}
                    </div></div>
            </Fragment>
        );
    }
}
export default AuctionBatchPaymentCtrl;