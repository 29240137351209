import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {notify} from '../../util/notifications';
import APKUpdate from '../../components/adminComponents/apkUpdate/apkUpdate';
import {getAPKList,getAPKVersions,uploadApk,updateVersion,
	updateAPKVersions,deleteDeviceInfo,updateVersionActiveStatus} from '../../actions/adminAction/apkUploadActions'
import {getAccessLevelsByModule} from '../../util/customMethods'
const pageTitle="APK Version Update";
class APKUpdatePage extends Component {
	constructor(props){
		super(props);
		this.state = {
			attachmentFiles : []
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.getAllAPKDevices=this.getAllAPKDevices.bind(this);
		this.getAllVersions=this.getAllVersions.bind(this);
	}
	componentWillMount=()=>{
		document.title=pageTitle
		this.getAllAPKDevices();
		this.getAllVersions();
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	getAllAPKDevices=()=>{
		const {dispatch}=this.props;
		dispatch(getAPKList())
	}
	getAllVersions=()=>{
		const {dispatch}=this.props;
		dispatch(getAPKVersions());
	}
	onFileUploadChange=(files)=>{
		this.setState({attachmentFiles:files});
	}
	uploadAPK=async(e, formData,version)=>{
		e.preventDefault();
		const {dispatch}=this.props;
		const res =await dispatch(uploadApk(formData,version));
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);			
			this.setState({attachmentFiles:[]});
			this.getAllVersions();	
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('APK Update');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4>APK Update</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-30 bg-white border-radius-4 box-shadow container-box">
				{accessLevelObj.basicAccess.view?	<APKUpdate 
					apkDeviceData={this.props.apkUploads||{}}
					apkActiveVersions={this.props.activeVersions||[]}
					accessLevel={accessLevelObj||{}}
					onFileUploadChange={(files)=>this.onFileUploadChange(files)}
					attachedFileName={this.state.attachmentFiles}
					onAPKUpload={(e,formData,version)=>this.uploadAPK(e,formData,version)}
					onAPkVersionUpdate={(type,recId,version)=>{
						this.props.dispatch(updateVersion(type,recId,version));
					}}
					onAPKVersionChangeSave={async (devList)=>{
						let deviceList=[];
						devList.map((dev)=>{
							deviceList.push({
								"deviceId": dev.deviceId,
								"selectedVersion": dev.updateToVersion,
								"version": '',
								"employeeId":dev.employeeId
							});
						})
						const res=await this.props.dispatch(updateAPKVersions(deviceList));
						if(res.statusCode===200){
							notify.createNotification('success',res.stateMsg,this.props.refNotify);
							this.getAllAPKDevices();
						}else{
							notify.createNotification('error',res.stateMsg,this.props.refNotify);
							this.getAllAPKDevices();
						}
					}}
					onAPKVersionActive_Deactive={async (recId,status)=>{
						const res=await this.props.dispatch(updateVersionActiveStatus(recId,status))
						if(res.statusCode===200){
							notify.createNotification('success',res.stateMsg,this.props.refNotify);
							this.getAllVersions();
						}else{
							notify.createNotification('error',res.stateMsg,this.props.refNotify);
						}
					}}
					onAPKVersionDelete={async (employeeId)=>{
						const res=await this.props.dispatch(deleteDeviceInfo(employeeId))
						if(res.statusCode===200){
							notify.createNotification('success',res.stateMsg,this.props.refNotify);
							this.getAllAPKDevices();
						}else{
							notify.createNotification('error',res.stateMsg,this.props.refNotify);
							this.getAllAPKDevices();
						}
					}}
					/>:null}
			</div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		apkUploads:state.apkUploads,
		activeVersions:state.apkUploads.apkVersionList.filter((item)=>{
			return item.isDeactivated===false
		})
	  }
};
export default withRouter(connect(mapStateToProps) (APKUpdatePage));