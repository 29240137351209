import React,{Fragment} from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import {required,isValidForm} from '../../../util/validationMethods';
import {GlobalValues} from '../../../util/constants'
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker'

class AuctionRecord extends React.Component{
    constructor(){
        super();
        this.state={
            validated:false
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.checkAccess=this.checkAccess.bind(this);
    }
    handleSubmit=(event)=>{
        event.preventDefault();
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{
            this.props.onSubmitForm(event,this.props.recData)
        }

    }
    checkAccess=()=>{
        if((this.props.recData.auctionId||0)===0){
            if((this.props.accessLevel.basicAccess.add||false)===true){
                return false
            }else{
                return true
            }
        }else{
            if((this.props.accessLevel.basicAccess.edit||false)===true){
                return false;
            }else{
                return true;
            }
        }
    }
    
    render(){
        return(<Fragment>
       
        <div className="modal-body">
        <Form ref={c => { this.form = c }}>
        <fieldset disabled={this.checkAccess()}>
        <div className="col-md-12 col-sm-12">
			<div className="form-group">
				<label className="form-control-label">AUCTION LIST NAME*</label>
                    <Input name="auctionName" type="text" className="form-control" placeholder="Auction List Name"
                    requiredmsg="Please enter Auction List Name"
                    value={this.props.recData.auctionName} onChange={(e)=>{
                        let _recObj={...this.props.recData};
                        _recObj[e.currentTarget.name]=e.currentTarget.value;
                        this.props.onFieldDataChange(e,_recObj);
                    }} validations={[required]}  />
					
			</div>
            <div className="form-group">
				<label className="form-control-label">AUCTION DATE *</label>
                <div>
                <TmDateTimePicker selectedDate={this.props.recData.date===''?'': moment(this.props.recData.date)._d}
                dateFormat={GlobalValues.dateFormat}
                reqmsg="Please select Auction Date."
                showYearDropdown={true}
                showmonthdropdown={true}
                validationArray={[required]}
                onDateSelectChange={(e)=>{
                        let _recObj={...this.props.recData};
                        _recObj['date']=e|| '';
                        this.props.onFieldDataChange(e,_recObj);
                    }} disabled={this.checkAccess()} />
                </div>
			</div>
            </div>
            </fieldset>
            </Form>
            <div className="modal-footer">
            <button variant="secondary" className="btn btn-danger btn-sm" onClick={(e)=>{this.props.onCancelForm(e)}}>Close</button>
            <button type="button" variant="primary" className="btn btn-success btn-sm"
            onClick={this.handleSubmit}>Save changes</button>

            </div>
        </div>
        
        </Fragment>)
    }
}

export default AuctionRecord;