import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { required, email, isValidForm, phone, isLenghtOf, isNumeric, zipValidation } from '../../../../util/validationMethods';
import { GlobalValues } from "../../../../util/constants";
import TmDateTimePicker from "../../../utilComponents/tmDateTimePicker/tmDateTimePicker";
import moment from 'moment'
import UserNotes from './userNotes';
import TmConformModal from '../../../utilComponents/tmModal/tmConformModal'
import { isManagement } from '../../../../util/customMethods';
class AdditionalInfo extends Component {
 state = {
      showConformModal: false,
      driverValidationRequired: false,
      licenseValidator: []
  }
  checkAccess = () => {
    if (this.props.empRec.employeeInformation.employeeId == 0) {
      if ((this.props.accessLevel.basicAccess.add || false) == true) {
        return false
      } else {
        return true
      }
    } else {
      if ((this.props.accessLevel.basicAccess.edit || false) === true) {
        return false;
      } else {
        return true;
      }
    }
  }
  render() {
    const { employeeInformation } = this.props.empRec;
     return (<Fragment>
      <div className="pd-10">
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-10">
            <Form className="" ref={c => {
              this.formAdvInfo = c;
              this.props.initCallBack(c);
            }}>
              <fieldset disabled={this.checkAccess()}>
                <div className="row">
                  <div className="col-md-6">
                  <div className="">
                      <label htmlFor="add-info-name" className="form-label col-form-label">DEPARTMENT/DIVISION</label>
                      <div className="">
                        <Select id="add-info-name"
                          name="department"
                          value={employeeInformation.department}
                          onChange={(e) => {
                            this.props.onFieldValueChange(e.target.name, e.target.value);
                          }}
                          validations={[required]}
                          requiredmsg="Please select Department" className="form-control form-control-sm"
                        >
                          <option value=""></option>
                          {
                            Object.keys(this.props.departments).map((dept, k) => {
                              return <option key={k} value={dept}>{dept}</option>
                            })
                          }
                        </Select>
                        {/* LEAVE THE HIDDEN DIV TO FORCE VIRTUAL DOM TORE-RENDER
                                ON DEPARTMENT CHANGE */}
                        <div style={{ display: 'none' }}>{employeeInformation.department}</div>
                      </div>
                    </div>
                  <div className="">
                      <label htmlFor="add-info-emp-id" className="form-label col-form-label">EMPLOYEE ID</label>
                      <div className="">
                        <Input placeholder="" name="employeeNo" value={employeeInformation.employeeNo}
                          type="text" id="add-info-emp-id" className="form-control form-control-sm" onChange={(e) => {
                            this.props.onFieldValueChange(e.target.name, e.target.value);
                          }} />
                      </div>
                    </div>                
                    <div className="">
                      <label
                        htmlFor="emp-land-line"
                        className="form-label col-form-label"
                      >
                        LAND LINE NO.
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="landLineNo"
                          value={employeeInformation.landLineNo||''}
                          type="text"
                          id="emp-land-line"
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                          validations={[phone]}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-mobile"
                        className="form-label col-form-label"
                      >
                        MOBILE NO.
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="moblieNo"
                          value={employeeInformation.moblieNo}
                          type="text"
                          id="emp-mobile"
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                          validations={[phone]}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-ecn"
                        className="form-label col-form-label"
                      >
                        EMERG. CONTACT NUMBER
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="emrgencyContactNo"
                          value={employeeInformation.emrgencyContactNo}
                          type="text"
                          id="emp-ecn"
                          className="form-control form-control-sm"
                          validations={[required, phone]}
                          requiredmsg="Please enter valid Contact Number."
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-ssn"
                        className="form-label col-form-label"
                      >
                        LAST 4 DIGITS OF SOCIAL SECURITY NUMBER
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="ssn"
                          value={employeeInformation.ssn}
                          type="text"
                          validations={[isNumeric, isLenghtOf]}
                          id="emp-ssn"
                          className="form-control form-control-sm"
                          length={4}
                          lengthofmsg="Length should be 4 digits"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="">
                      <label
                        htmlFor="emp-doj"
                        className="form-label col-form-label"
                      >
                        DATE OF JOINING
                      </label>
                      <div className="">
                        <TmDateTimePicker
                          className="form-control"
                          selectedDate={
                            employeeInformation.doj === "" || employeeInformation.doj === null
                              ? null
                              : moment(
                                employeeInformation.doj,
                                GlobalValues.dateFormat
                              )._d
                          }
                          dateFormat={GlobalValues.dateFormat}
                          validationArray={[required]}
                          showYearDropdown={true}
                          showmonthdropdown={true}
                          name="doj"
                          reqmsg="Please select DOJ"
                          onDateSelectChange={e => {
                            this.props.onFieldValueChange(
                              "doj",
                              e === null
                                ? ""
                                : moment(e).format(GlobalValues.dateFormat)
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-dob"
                        className="form-label col-form-label"
                      >
                        DATE OF BIRTH
                      </label>
                      <div className="">
                        <TmDateTimePicker
                          className="form-control "
                          selectedDate={
                            employeeInformation.dob === "" || employeeInformation.dob === null
                              ? null
                              : moment(
                                employeeInformation.dob,
                                GlobalValues.dateFormat
                              )._d
                          }
                          dateFormat={GlobalValues.dateFormat}
                          validationArray={[required]}
                          showYearDropdown={true}
                          showmonthdropdown={true}
                          name="dob"
                          reqmsg="Please select DOB"
                          onDateSelectChange={e => {
                            this.props.onFieldValueChange(
                              "dob",
                              e === null
                                ? ""
                                : moment(e).format(GlobalValues.dateFormat)
                            );
                          }}
                        />
                      </div>
                    </div>    
                    <div className="">
                      <label
                        htmlFor="emp-addr21"
                        className="form-label col-form-label"
                      >
                        ADRESS LINE 1
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          value={employeeInformation.personalAddressLine1}
                          type="text"
                          name="personalAddressLine1"
                          id="emp-addr21"
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-addr2"
                        className="form-label col-form-label"
                      >
                        ADRESS LINE 2
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          value={employeeInformation.personalAddressLine2}
                          type="text"
                          id="emp-addr2"
                          name="personalAddressLine2"
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-city"
                        className="form-label col-form-label"
                      >
                        CITY
                      </label>
                      <div className="">
                        <Input
                          placeholder=""
                          name="personalCity"
                          value={employeeInformation.personalCity}
                          type="text"
                          id="emp-city"
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="emp-state"
                        className="form-label col-form-label"
                      >
                        STATE
                      </label>
                      <div className="">
                        <Select
                          id="add-info-name"
                          name="personalState"
                          value={employeeInformation.personalState}
                          className="form-control form-control-sm"
                          onChange={e => {
                            this.props.onFieldValueChange(
                              e.target.name,
                              e.target.value
                            );
                          }}
                        >
                          <option value="" />
                          {this.props.states.map((loc, k) => {
                            return (
                              <option key={k} value={loc.optionVal}>
                                {loc.optionTxt}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="">
                        <label
                          htmlFor="emp-zip"
                          className="form-label col-form-label"
                        >
                          ZIP CODE
                      </label>
                        <div className="">
                          <Input
                            placeholder=""
                            name="personalZipcode"
                            value={employeeInformation.personalZipcode}
                            type="text"
                            id="emp-zip"
                            className="form-control form-control-sm"
                            onChange={e => {
                              this.props.onFieldValueChange(
                                e.target.name,
                                e.target.value
                              );
                            }}
                            validations={[zipValidation]}
                          />
                        </div>
                      </div>
                    </div>

                    {
                      this.props.showEmpActiveStatus ?
                        <div className="">
                          <label htmlFor="activeStatus" className="form-label col-form-label">CURRENT STATUS</label>
                          <div className="pt-2">
                            {employeeInformation.empStatus === 1 ? <label style={{ color: '#28a745' }}><i className="fa fa-circle"></i> ACTIVE</label> : <label style={{ color: '#dc3545' }}><i className="fa fa-circle"></i>INACTIVE</label>}
                          </div>
                          <div className="">
                            <button type="button" className="btn btn-sm btn-success" onClick={(e) => {
                              this.setState({ showConformModal: true })

                              //this.props.onFieldValueChange('empStatus',employeeInformation.empStatus===1?0:1);
                            }}>{employeeInformation.empStatus === 1 ? "De-Activate" : "Activate"}</button>
                            <TmConformModal
                              title={'Conformation'}
                              modalBody={employeeInformation.empStatus === 1 ? 'Do you want to Deactivate the Employee?' : 'Do you want to activate the Employee?'}
                              show={this.state.showConformModal}
                              cancelText={'Cancel'}
                              okText={'Ok'}
                              onHide={() => { this.setState({ showConformModal: false }) }}
                              onSubmit={(e) => {
                                this.setState({ showConformModal: false },
                                  this.props.onActiveStatusChange(employeeInformation.employeeId, employeeInformation.empStatus === 1 ? 0 : 1))
                              }}
                            />
                          </div>
                        </div> : null
                    }
                  </div>

                </div>

              </fieldset>
            </Form>

          </div>
        </div>
        <UserNotes disabled={this.checkAccess()} notesData={this.props.empRec.employeeRemarksForm.remarks || []}
          onNotesSubmit={(e, nodeObj) => {
            this.props.onUserRemarksAdded(e, nodeObj);
          }} />


        <div className="row">
          <div className="col-md-12 col-sm-12 text-right">
            <button className="btn btn-sm btn-primary"
              disabled={this.checkAccess()}
              onClick={e => {
                e.preventDefault();
                this.formAdvInfo.validateAll();
                if (!isValidForm(this.formAdvInfo)) {
                } else {
                  this.props.onSubmitAdditionalInfo(e);
                }
              }}>Save Next</button>
          </div>
        </div>
      </div>
    </Fragment>);
  }

}
export default AdditionalInfo
