import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import AuctionList from '../../components/adminComponents/auction/auctionList'
import AuctionRecord from '../../components/adminComponents/auction/auctionRecord'
import { connect } from 'react-redux';
import {getAuctionList, getAuction, clearAuction,changeAuctionField,
	saveAuction,changeActiveStatus} from '../../actions/adminAction/auctionActions';
import {Modal} from 'react-bootstrap';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods'
const pageTitle="Auction";
class AuctionPage extends Component {
	constructor(){
		super();
		this.state={
		filter:	{isActive:"1"},
		modalOpr:{modalShow:false}
		}
		this.checkViewAccess=this.checkViewAccess.bind(this)
		this.bindAuctionList=this.bindAuctionList.bind(this);
		this.onSearchChange=this.onSearchChange.bind(this);
		this.handleClose=this.handleClose.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
		this.addAuctionOnClick=this.addAuctionOnClick.bind(this);
		this.editAuctionOnClick=this.editAuctionOnClick.bind(this);
		this.onFieldDataChange=this.onFieldDataChange.bind(this);
		this.ActiveInActiveAuction=this.ActiveInActiveAuction.bind(this);
	}	
	componentWillMount=()=>{
		document.title=pageTitle
		this.bindAuctionList();		
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	
	bindAuctionList=()=>{
		const {dispatch}=this.props;
		dispatch(getAuctionList(this.state.filter));
	}
	onSearchChange=(filterObj)=>{
		let _filter=this.state.filter;
		_filter.isActive=filterObj.isActive===-1?"1','0":filterObj.isActive
		this.setState({filter:_filter},this.bindAuctionList());
	}
	addAuctionOnClick=(e)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		let _modalOpr=this.state.modalOpr;
		_modalOpr.modalShow=true;
		this.setState({modalOpr:_modalOpr},
			dispatch(getAuction(0))
			)
	}
	editAuctionOnClick=(e,aid)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		let _modalOpr=this.state.modalOpr;
		_modalOpr.modalShow=true;
		this.setState({modalOpr:_modalOpr},
			dispatch(getAuction(aid))
			);
	}

	handleClose=(e)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		let _modalOpr=this.state.modalOpr;
		_modalOpr.modalShow=false;
		this.setState({modalOpr:_modalOpr},
			dispatch(clearAuction())
		);

	}
	onFieldDataChange=(e,recObj)=>{
		const {dispatch}=this.props;
		dispatch(changeAuctionField(recObj));
	}
	handleSubmit=async (e,recObj)=>{
		e.preventDefault();
		const {dispatch}=this.props;
		const res=await dispatch(saveAuction(recObj))
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);
			this.handleClose(e);
			this.bindAuctionList()
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
	ActiveInActiveAuction=async (e,recObj)=>{
		e.preventDefault();
		const {dispatch}=this.props;
		const res=await dispatch(changeActiveStatus(recObj));
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);			
			this.bindAuctionList()			
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Auction');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4>Auction List</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
			<AuctionList data={this.props.auctionList} onSearchChange={this.onSearchChange} 
			addAuctionOnClick={this.addAuctionOnClick} editAuctionOnClick={this.editAuctionOnClick}
			ActiveInActiveAuction={this.ActiveInActiveAuction} accessLevel={accessLevelObj||{}}
			 />
			</div>

			<Modal show={this.state.modalOpr.modalShow} > 
				<div className="modal-header">
            <Modal.Title>{ this.props.auctionObject.auctionId===0?'Add Auction List':'Edit Auction List'}</Modal.Title>
        </div>
        <AuctionRecord accessLevel={accessLevelObj||{}} 
				recData={this.props.auctionObject}
				onFieldDataChange={(e,recObject)=>{
					this.onFieldDataChange(e,recObject);
				}} 
				onCancelForm={this.handleClose} 
				onSubmitForm={this.handleSubmit} />
        </Modal>;
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		auctionList:state.auction.auctionList,
		auctionObject:state.auction.auctionObject
	  }
};

export default withRouter(connect(mapStateToProps) (AuctionPage));