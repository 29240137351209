import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import Scrollbars from 'react-custom-scrollbars'
import Footer from './components/layout/footer/footer';
import Header from './components/layout/header/header';
import Sidebar from './components/layout/sidebar/sidebar';
import {logOutUser} from './actions/authenticateActions';
import AdminHome from './containers/admin/adminHome.jsx';
import DispatcherHome from './containers/dispatcher/dispatcherHome';
import ReportsHome from './containers/reports/reportsHome';
import * as notificationActions from './actions/notificationActions';
import Admin from './components/launchComponents/Admin/admin';
import DispatcherDashboarPage from './containers/launch/dashboard/dispatcherDashboardPage';
import Reports from './components/launchComponents/Reports/reports';
import AdminAccountDetailPage from './containers/launch/adminAccountDetail/adminAccountDetailPage';
import ReportChartPage from './containers/launch/reportChart/reportChartPage'
import{PrivateRoute}  from './components/route/privateRoute';
import {fillDropDownsData} from './util/customMethods';
import * as authenticationActions from './actions/authenticateActions'
class Home extends React.Component {
  constructor(){
    super();
    this.fillDropDowns=this.fillDropDowns.bind(this)
  }
  componentWillMount=()=>{
  }  
  componentDidMount= ()=> {
    const {dispatch} =this.props
    this._notificationSystem = this.refs.notificationSystem;
    dispatch(notificationActions.setNotificationRef(this.refs.notificationSystem));
    this.fillDropDowns();
  }
  fillDropDowns=()=>{
    const {dispatch} =this.props;
    if(authenticationActions.isAuthenticated()){
      dispatch(fillDropDownsData());
    }
  }
  render(){
  return (
    <>
    <NotificationSystem ref="notificationSystem" />
      <div className="header clearfix">
        <Header onLogOut={()=>{
          this.props.dispatch(logOutUser())
        }} reviewInbox={this.props.reviewInbox} accessLevels={this.props.accessLevel||{}} dashboardAccess={this.props.dashboardAccess} />              
      </div>
      <div id="side-bar" className="left-side-bar">
      <Sidebar accessLevels={this.props.accessLevel||{}} dashboardAccess={this.props.dashboardAccess} />
      </div> 
      <Scrollbars style={{ height: "100vh" }}>           
      <div className="pd-ltr-20" style={{marginBottom: '50px'}}> 
        <div className="main-container" style={{marginBottom: '50px', height:'auto'}}>            
            <Route exact path="/admin-dashboard" component={Admin} />
            <Route exact path="/summarydashboard" component={DispatcherDashboarPage} />
            <Route eaxct path="/reports-dashboard" component={Reports} />
            <Route path="/reportschart" component={ReportChartPage} />
            <Route path="/adminaccountdetail" component={AdminAccountDetailPage} />
            <Route path="/admin" component={AdminHome} />
            <Route path="/dispatch" component={DispatcherHome} />
            <Route path="/reports" component={ReportsHome} /> 
            <Route exact path="/" component={DispatcherDashboarPage} />
          <Footer />
        </div> 
      </div>
      </Scrollbars>
    </>
  );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
    accessLevel:state.accessLevel,
    dashboardAccess:state.dashboardAccess,
    refNotify:state.refNotify,
    reviewInbox:state.reviewInbox.reviewLocationList
  }
};
export default connect(mapStateToProps)(Home);
