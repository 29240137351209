import React, { Component } from 'react';

class Range extends Component {
    applyCallback(event, range) {
        this.props.applyCallback(event,range);
      }
    render() {
        return (                
                                <div class="dropdown pull-right" style={{float: 'right', paddingLeft: '5px'}}>
                                    <a href="javascript:void(0)" type="button" data-toggle="dropdown">
                                    <i class="fa fa-calendar" aria-hidden="true"></i></a>
                                    <ul class="dropdown-menu">
                                    <li><a href="javascript:void(0)" onClick={(e) => this.applyCallback(e,'30')}>30 days</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => this.applyCallback(e,'60')}>60 days</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => this.applyCallback(e,'90')}>90 days</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => this.applyCallback(e,'180')}>180 days</a></li>
                                    <li><a href="javascript:void(0)" onClick={(e) => this.applyCallback(e,'360')}>360 days</a></li>
                                    </ul>
                                </div>                                                                              
        );
    }
}

export default Range;