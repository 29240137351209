import React, { Component } from 'react';
import {LAUNCH_URL,ROOT_DISPATCHER, ROOT_ADMIN} from '../../util/customMethods'
export const tableColumns = [
    {
        accessor: 'reason',
        Header: 'Reason',
        style: { textAlign: 'center' },
        width: 200,
    },
    {
        accessor: 'noOfTows',
        Header: 'Number Of Tows',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'totalCharges',
        Header: '*Total Charges',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.totalCharges.toFixed(2)}`
    },
    {
        accessor: 'use', 
        Header: 'Use %',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'chargesPerTow',
        Header: '*Charges Per Tow',
        style: { textAlign: 'center' },
        width: 200,
        Cell: props => `$ ${props.original.chargesPerTow.toFixed(2)}`
    },
    {
        accessor: 'towType',
        Header: 'Tow Type',
        style: { textAlign: 'center' },
        width: 200
    }
];
export const driverColumns = [
    {
        accessor: 'driverId',
        Header: 'Driver ID',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'driverName',
        Header: 'Driver Name',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'noOfTows',
        Header: 'Number Of Tows',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'totalCharges',
        Header: '*Total Charges',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'use',
        Header: 'Use %',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'chargesPerTow',
        Header: '*Charges Per Tow',
        style: { textAlign: 'center' },
        width: 200
    }
];
export const truckColumns = [
    {
        accessor: 'truck',
        Header: 'Truck ID',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'noOfTows',
        Header: 'Number Of Tows',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'totalCharges',
        Header: '*Total Charges',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'use',
        Header: 'Use %',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'chargesPerTow',
        Header: '*Charges Per Tow',
        style: { textAlign: 'center' },
        width: 200
    }
];
export const reasonClm = [
    {
        accessor: 'reason',
        Header: 'Reason',
        style: { textAlign: 'center' },
        width: 200,
    },
    {
        accessor: 'noOfTows',
        Header: 'Number Of Tows',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'totalCharges',
        Header: '*Total Charges',
        style: { textAlign: 'center' },
        width: 200,
    },
    {
        accessor: 'use', 
        Header: 'Use %',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'chargesPerTow',
        Header: '*Charges Per Tow',
        style: { textAlign: 'center' },
        width: 200,
    }
];
export const towColumns = [
    {
        accessor: 'type',
        Header: 'Type',
        style: { textAlign: 'center' },
        width: 200,
    },
    {
        accessor: 'noOfTows',
        Header: 'Number Of Tows',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.noOfTows||0)===parseInt(filter.value||0),
        width: 200
    },
    {
        accessor: 'totalCharges',
        Header: '*Total Charges',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'use',
        Header: 'Use %',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'chargesPerTow',
        Header: '*Charges Per Tow',
        style: { textAlign: 'center' },
        width: 200,
    }
];
export const rateColumns = [
    {
        accessor: 'accountRatePlan',
        Header: 'Account Rate Plan',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'noOfTows',
        Header: 'Number Of Tows',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.noOfTows||0)===parseInt(filter.value||0),
        width: 200
    },
    {
        accessor: 'totalCharges',
        Header: '*Total Charges',
        style: { textAlign: 'center' },
        width: 200,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.totalCharges||0)===parseInt(filter.value||0),
    },
    {
        accessor: 'use',
        Header: 'Use %',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.use||0)===parseInt(filter.value||0),
        width: 200
    },
    {
        accessor: 'chargesPerTow',
        Header: '*Charges Per Tow',
        style: { textAlign: 'center' },
        width: 200,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.chargesPerTow||0)===parseInt(filter.value||0)
    },
];

export const detailsColumns = [
    {
        accessor: 'invoice',
        Header: 'invoice',
        style: { textAlign: 'center' },
        //Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/editServiceCall?invoiceId=${props.original.invoice}`,'_blank')}}>{props.original.invoice}</a>,
        width: 160,
        Footer:(<span>Total :</span>)
    },
    {
        accessor: 'invoiceCreatedDate',
        Header: 'Invoice Created Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'requestedBy',
        Header: 'Requested By',
        style: { textAlign: 'center' },
        width: 160
    },
    {
        accessor: 'ratePlan',
        Header: 'Rate Plan',
        style: { textAlign: 'center' },
        width: 160
    },
    {
        accessor: 'reason',
        Header: 'Reason',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'towType',
        Header: 'Tow Type',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'driver',
        Header: 'Driver',
        style: { textAlign: 'center' },
        width: 120
    },
    
    {
        accessor: 'truck',
        Header: 'Truck',
        style: { textAlign: 'center' },
        width: 120
    },
    
    {
        accessor: 'totalCharges',
        Header: '* Total Charges',
        style: { textAlign: 'center' },
        width: 150,
        //Cell: props => `$ ${props.original.totalCharges.toFixed(2)}`,
    //     Footer:( `$ ${((this.props.driverReportDetails||[]).reduce((a, b)=>
    //         a + convertToFloat(b['totalCharges'],2) 
    //    , 0)).toFixed(2)}`)
    }
];
export const resColumns = [
    {
        accessor: 'invoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={() => {
            window.open(LAUNCH_URL + '/' + ROOT_DISPATCHER + '/serviceCallInfo/' + props.original.invoiceId, '_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 120
    },
    {
        accessor: 'account',
        Header: 'Account',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={() => {
            window.open(LAUNCH_URL + '/' + ROOT_ADMIN + '/accountDetails?aid=' + props.original.accountId, '_blank');
        }}>{props.original.account}</a>,
        width: 250
    },
    {
        accessor: 'callerTakerId',
        Header: 'Call Taker ID',
        style: { textAlign: 'center' },
        width: 140
    },
    {
        accessor: 'dispatchId',
        Header: 'Dispatch ID',
        style: { textAlign: 'center' },
        width: 140
    },
    {
        accessor: 'driverId',
        Header: 'Driver ID',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
           // window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/employeesDetails?eid=${props.original.employeeId}`,'_blank');
        }}>{props.original.driverId}</a>,
        width: 80
    },
    {
        accessor: 'callTime',
        Header: 'Call Time',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'dispatchedTime',
        Header: 'Dispatched Time',
        style: { textAlign: 'center' },
        width: 150
    },
    {
        accessor: 'arrivalTime',
        Header: 'Arrival Time',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'clearedTime',
        Header: 'Cleared Time',
        style: { textAlign: 'center' },
        width: 120
    },
    {
        accessor: 'callToDispatch',
        Header: 'Call To Dispatch',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.callToDispatch||0)===parseInt(filter.value||0),
        width: 140
    },
    {
        accessor: 'callToArrival',
        Header: 'Call To Arrival',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.callToArrival||0)===parseInt(filter.value||0),
        width: 120
    },
    {
        accessor: 'dispatchToClear',
        Header: 'Dispatch To Clear',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.dispatchToClear||0)===parseInt(filter.value||0),
        width: 130
    },
    {
        accessor: 'late',
        Header: 'Late',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.late||0)===parseInt(filter.value||0),
        width: 50
    }
];
