import * as actionTypes from '../actionTypes';
import * as spinnerActions from '../spinnerActions';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';

export const getAPKList=()=>{
    const _url=`AppAutoUpdater/viewAllApkVersions/?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.SERVICE).get(_url);         
            let _respData=[];
            if(response.status===200) { 
                _respData=(response.data||[]).map((item)=>{
                    item._updateToVersion=item.updateToVersion
                    return item;
                }) 

                dispatch({
                    type: actionTypes.GET_ALL_APK_RECORDS_DATA,
                    apkDeviceList:_respData
                });
            } else{   
                dispatch({
                    type: actionTypes.GET_ALL_APK_RECORDS_DATA,
                    apkDeviceList:undefined
                });
            }
           // dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ALL_APK_RECORDS_DATA,
                apkDeviceList:undefined
            })
        }
    }
}
export const updateAPKVersions=(deviceList)=>{
    let _url=`config/updateApkVersion?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async(dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).put(_url,{"versions":deviceList})        
            if(response.status===200) {
                return {statusCode:response.status,stateMsg:response.data.message}
            } else{                
                return {statusCode:null,stateMsg:response.data.message}
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,stateMsg:error.message}
        }
    }
}
export const updateVersionActiveStatus=(recId,status)=>{   
    let _url=`AppAutoUpdater/updateIsDeactivate?apkRecId=${recId}&isDeactivate=${status}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`
    return async(dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.SERVICE).put(_url)
            if(response.status===200) {
                return {statusCode:response.status,statusMsg:response.data.message};
            } else{
                return {statusCode:null,statusMsg:response.data.message};
                // dispatch(getAuctionList());
            }
            //dispatch(spinnerActions.hideSpinner());
        }catch(error){
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        };
    }
}
export const deleteDeviceInfo=(employeeId)=>{
    let _url=`AppAutoUpdater/deleteDeviceInfo?employeeId=${employeeId}&access_token=${getAccessToken()}`;
    return async(dispatch)=>{
       // dispatch(spinnerActions.showSpinner());
       try{
        const response=getAxiosInstance(moduleTypes.SERVICE).delete(_url)
            if(response.status===200) {
                //success(response.message);
                return {statusCode:response.status,statusMsg:response.data.message};
            } else{
                return {statusCode:null,statusMsg:response.data.message};                 
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        }
    }
}
export const updateVersion=(type,recIdx,version)=>{
    return(dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        let _apkDeviceList=(store.getState()).apkUploads.apkDeviceList;
        if(type==='ALL'){
            _apkDeviceList.map((item)=>{
                item.updateToVersion=version
            });
        }else{
            _apkDeviceList[recIdx].updateToVersion=version
        }
        dispatch({
            type: actionTypes.GET_ALL_APK_RECORDS_DATA,
            apkDeviceList:_apkDeviceList
        });
        //dispatch(spinnerActions.hideSpinner());
    }
}
export const getAPKVersions=()=>{
    const _url=`AppAutoUpdater/getListOfVersions/?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
        const response= await getAxiosInstance(moduleTypes.SERVICE).get(_url);
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_ALL_APK_VERSIONS,
                    apkVersionList:response.data
                });
            } else{
                    dispatch({
                        type: actionTypes.GET_ALL_APK_VERSIONS,
                        apkVersionList:undefined
                    });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: actionTypes.GET_ALL_APK_VERSIONS,
                apkVersionList:undefined
            })
        }
    }
}
export const uploadApk=(formObject,version)=>{    
    return async(dispatch)=>{        
        dispatch(spinnerActions.showSpinner());
        try{
        let _url=`AppAutoUpdater/uploadApk?version=${version}&access_token=${getAccessToken()}`;        
        const response=await getAxiosInstance(moduleTypes.SERVICE).post(_url,formObject,
            {headers:{'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryMWJXSpbjSjn1G8Ap'}});
            if(response.status===200) {
                dispatch(spinnerActions.hideSpinner());
                return {statusCode:response.status,statusMsg:response.data.message};
            }else{
                dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:response.data.message};
                   // dispatch(getAuctionList());                    
                }
        }
        catch(error) {
            dispatch(spinnerActions.hideSpinner());
            return {statusCode:null,statusMsg:error.message};
        }
    }
}