import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs, Marker, TrafficLayer, InfoWindow } from 'react-google-maps';
const GoogleMapsWrapper = withScriptjs(withGoogleMap(props => {
  return <GoogleMap {...props} ref={props.onMapMounted}>{props.children}</GoogleMap>
}));
let availableTruck = require('../../../assets/img/freeTruck.png');
let busyTruck = require('../../../assets/img/busyTruck.png');
let RecoveryTruck = require('../../../assets/img/RecoveryTruck.png');
export default class MapSearch extends React.Component {
  state = {
    truckIndex: null,
    pickUpIndex: null,
    dropOfIndex: null,
    isOpen: false,
  }

  handleToggleOpen = (row, val) => {
    this.setState({ isOpen: true, truckIndex: row });
  }

  handleToggleClose = () => {
    this.setState({ isOpen: false, truckIndex: null });
  }

  handleTogglePickUpOpen = (row, val) => {
    this.setState({ isOpen: true, pickUpIndex: row });
  }

  handleTogglepickUpClose = () => {
    this.setState({ isOpen: false, pickUpIndex: null });
  }

  handleToggledropOfOpen = (row, val) => {
    this.setState({ isOpen: true, dropOfIndex: row });
  }

  handleToggleDropOfClose = () => {
    this.setState({ isOpen: false, dropOfIndex: null });
  }

  render() {
    return (
      <GoogleMapsWrapper
        googleMapURL="https://maps.googleapis.com/maps/api/js?v3.exp&key=AIzaSyCPhSP57_v3UIrDN-9EAB8uXoa6m4pHhr0"// libraries=geometry,drawing,places
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `80vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        defaultZoom={11}
        defaultCenter={{ lat: 33.429078, lng: -111.943817 }}
      >
        <TrafficLayer autoUpdate />
        {this.props.mapVehicleInfo && this.props.mapVehicleInfo.map((trkDtls, i) =>
          trkDtls !== null && trkDtls != undefined ? <Marker
            onMouseOver={this.handleToggleOpen.bind(this, i)}
            onMouseOut={this.handleToggleClose}
            key={i}
            position={{ lat: trkDtls.latitude && Number(trkDtls.latitude||0), lng: trkDtls.longitude && Number(trkDtls.longitude||0) }}
            options={{ icon: trkDtls.invoiceCount >= 2 ? RecoveryTruck : ["available", "Dropped", "Cleared"].includes(trkDtls.truckStatus) ? availableTruck : busyTruck }}>
            {this.state.truckIndex === i && <InfoWindow onCloseClick={this.handleToggleClose} >
              <div>
                <span className='map-pop-label'>Location:</span><span className='map-pop-desc'>{trkDtls.currentAddress}</span><br />
                <span className='map-pop-label'>Truck:</span><span className='map-pop-desc'>{trkDtls.truckId}</span><br />
                <span className='map-pop-label'>Driver Id:</span><span className='map-pop-desc'>{trkDtls.driverId}</span><br />
                <span className='map-pop-label'>Status:</span><span className='map-pop-desc'>{trkDtls.truckStatus}</span>
              </div>
            </InfoWindow>}
          </Marker> : null
        )}
        {this.props.callData && this.props.callData.map((callDetails, i) =>
          callDetails.pickupLatlang !== null && this.props.PickUpOinters === true && callDetails.pickupLatlang !== undefined ? (
            <Marker onMouseOver={this.handleTogglePickUpOpen.bind(this, i)} onMouseOut={this.handleTogglepickUpClose.bind(this, i)}
              key={i}
              position={{ lat: parseFloat(callDetails.pickupLatlang.split(',')[0]), lng: parseFloat(callDetails.pickupLatlang.split(',')[1]) }}
            >
              {this.state.pickUpIndex === i && <InfoWindow onCloseClick={this.handleTogglepickUpClose} >
                <div>
                  <span className='map-pop-label'>Invoice Id:</span><span className='map-pop-desc'>{' ' + callDetails.invoiceId}</span><br />
                  <span className='map-pop-label'>PickUp Location:</span><span className='map-pop-desc'>{' ' + callDetails.pickupAddress}</span><br />
                  <span className='map-pop-label'>ETA:</span><span className='map-pop-desc'>{callDetails.etaTime || ''}</span><br />
                  <span className='map-pop-label'>Status:</span><span className='map-pop-desc'>{' ' + callDetails.callStatus}</span><br />
                  <span className='map-pop-label'>Priority:</span><span className='map-pop-desc'>{' ' + callDetails.priority}</span>
                </div>
              </InfoWindow>}
            </Marker>) : ""
        )}
        {this.props.callData && this.props.callData.map((callDetails, i) =>
          callDetails.dropLatlng !== null && callDetails.dropLatlng !== undefined && this.props.dropPointers === true ? (
            <Marker onMouseOver={this.handleToggledropOfOpen.bind(this, i)} onMouseOut={this.handleToggleDropOfClose} key={i}
              options={{ icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' }}
              position={{
                lat: callDetails.dropLatlng && parseFloat(callDetails.dropLatlng.split(',')[0]),
                lng: callDetails.dropLatlng && parseFloat(callDetails.dropLatlng.split(',')[1])
              }}
            >
              {this.state.dropOfIndex === i && <InfoWindow onCloseClick={this.handleToggleDropOfClose} >
                <div>
                  <span className='map-pop-label'>Invoice Id:</span><span className='map-pop-desc'>{callDetails.invoiceId}</span><br />
                  <span className='map-pop-label'>Drop of Location:</span><span className='map-pop-desc'>{callDetails.dropoffAddress}</span><br />
                  <span className='map-pop-label'>ETA:</span><span className='map-pop-desc'>{callDetails.etaTime}</span><br />
                  <span className='map-pop-label'>Status:</span><span className='map-pop-desc'>{callDetails.callStatus}</span><br />
                  <span className='map-pop-label'>Priority:</span><span className='map-pop-desc'>{' ' + callDetails.priority}</span>
                </div>
              </InfoWindow>}
            </Marker>) : ""
        )}
      </GoogleMapsWrapper>
    );
  }
}
