import * as actionTypes from '../actionTypes';
import {getAxiosInstance} from '../../util/service';
import {moduleTypes} from '../../util/constants';
import{store}  from '../../store/configureStore';
import {getAccessToken} from '../authenticateActions'
import {getCompanyId} from '../../util/customMethods';
import moment from 'moment';

export const getCallsCompleted = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getCallsCount?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_CALLS_COMPLETED,
              callsComplete:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_CALLS_COMPLETED,
              callsComplete:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_CALLS_COMPLETED,
            callsComplete:{}
        });
      };
  };
};

export const getSalvageCount = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getSalvageVehiclesCount?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_SALVAGE_VEHICLE_COUNT,
              salvageCount:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_SALVAGE_VEHICLE_COUNT,
              salvageCount:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_SALVAGE_VEHICLE_COUNT,
            salvageCount:{}
        });
      };
  };
};
export const getDailyDeposit = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getDeposits?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_DEPOSITS,
              dailyDeposit:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_DEPOSITS,
              dailyDeposit:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_DEPOSITS,
            dailyDeposit:{}
        });
      };
  };
};
export const getStorageCount = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getStorageCount?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_STORAGE_COUNT,
              storageCount:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_STORAGE_COUNT,
              storageCount:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_STORAGE_COUNT,
            storageCount:{}
        });
      };
  };
};
export const getUnitsReleased = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getReleaseUnits?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_RELEASE_UNITS,
              unitsReleased:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_RELEASE_UNITS,
              unitsReleased:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_RELEASE_UNITS,
            unitsReleased:{}
        });
      };
  };
};
export const getNoAbandonedVehicles = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getNoAVRReport?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_NO_AVRREPORT,
              noAbandonedVehicles:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_NO_AVRREPORT,
              noAbandonedVehicles:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_NO_AVRREPORT,
            noAbandonedVehicles:{}
        });
      };
  };
};

export const getPaylessInventory = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getPaylessInventory?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_PAYLESS_INVENTORY,
              paylessInventory:response
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_PAYLESS_INVENTORY,
              paylessInventory:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_PAYLESS_INVENTORY,
            paylessInventory:{}
        });
      };
  };
};

export const getCommissions = (fromDate,toDate) => {
  return async(dispatch) => {
    let url=`getCommission?fromDate=${moment(fromDate).format('YYYY-M-D')}&toDate=${moment(toDate).format('YYYY-M-D')}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    try{
    const response=await getAxiosInstance(moduleTypes.REPORTS).get(url)        
        if(response.status==200){
          dispatch(
            {
              type: actionTypes.GET_COMMISSIONS,
              commissions:response.data
          });
          }else{
          dispatch(
            {
              type: actionTypes.GET_COMMISSIONS,
              commissions:{}
          });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      catch(error) {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
            type: actionTypes.GET_COMMISSIONS,
            commissions:{}
        });
      };
  };
};