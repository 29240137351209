import React, { Component,Fragment } from 'react';
import {withRouter,Link} from 'react-router-dom';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment'

class DefaultRatePlanList extends Component {
  constructor(){
    super();
    this.state={
      excelDataSet:[]
    }
    this.buildDataFormatForExport=this.buildDataFormatForExport.bind(this)
  }
  componentWillReceiveProps=(nextProps)=>{
    this.setState({excelDataSet:nextProps.defaultRatePlanListData});
  }

  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'RATE PLAN'},{title:'TOW TYPE'}],
      data: this.state.excelDataSet.map((item)=>{
        return [
          {value:item.ratePlanName},
          {value:item.towType}]
      })
    }])
  }
  
  render() {
    const columns=[{
      Header: 'RATE PLAN',
      accessor: 'ratePlanName', // String-based value accessors!
      filterable:true,
      /* Cell: props => <a className="btn btn-link" href="javascript:void(0)" title="Edit" onClick={(e)=>{
        let _rateplanid=props.original.ratePlanId;
        this.props.editDefaultRatePlanClick(e,_rateplanid);
      }}>{props.value}</a>  */
      Cell:props=><Link to={'defaultRateplanDetails?rateplanid='+props.original.ratePlanId}>{props.value}</Link>
    }, 
    {
      Header: 'TOW TYPE',
      accessor: 'towType',
      filterable:true,
      Cell: props => <span>{props.value}</span>,
      // width: 300
    }];
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-5 col-sm-12 mb-10"></div>
      <div className="col-md-7 col-sm-6 mb-10">  
      <div className="pull-right">        
      <div className="btn-list" style={{display:'inline-block'}}>
      <TmExportExcel name={'DefaultRatePlan'} filename={`${"DefaultRatePlan_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
      {
         (this.props.accessLevel.basicAccess.add||false)===true?
      <button className="btn btn-success btn-sm" onClick={(e)=>{
        this.props.addDefaultRatePlanClick(e);
      }} ><i className="fa fa-plus"></i> Add Rate Plan</button>:null}
      </div>
      </div> 
      </div>
      </div>
      
      
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">				
        <TmReactTable ref={(table)=>this.ratePlanTable=table}
        onFilteredChange={()=>{
          let data=this.ratePlanTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={this.props.defaultRatePlanListData||[]}
            columns={columns}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default withRouter(DefaultRatePlanList);
