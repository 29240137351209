import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const defaultAuctions={
  auctionList:[],
  auctionObject: {
    "auctionId":0,
    "auctionName":"",
    "date":'',
    "userId":"",
    "status":1
  }
  
}
export const auctionReducer = (state = defaultAuctions, action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_AUCTIONS:
        return {...state ,auctionList:(action.auctionList||defaultAuctions.auctionList)};
      case actionTypes.GET_AUCTION_RECORD:
        return {...state , auctionObject:(action.auctionObject|| defaultAuctions.auctionObject) };
        case actionTypes.CLEAR_AUCTION_RECORD:
        return {...state , auctionObject: defaultAuctions.auctionObject };
      default:
        return state;
    }
  };