import * as actionTypes from '../../../actions/actionTypes';
const defaultARSummary={
  arSummaryObj:{}
}
export const arSummaryReducer = (state = defaultARSummary, action) => {
    switch (action.type) {
      case actionTypes.GET_ARSUMMARY_LIST:
        return {...state ,arSummaryDetails:(action.arSummaryObj||defaultARSummary.arSummaryObj)};
      default:
        return state;
    }
  };