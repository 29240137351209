import React, { Component,Fragment } from 'react';
import {withRouter,Link} from 'react-router-dom';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import moment from 'moment'

class LocationList extends Component {
 state={
      searchQuery:'',
      excelDataSet:[]
    }
  componentWillReceiveProps=(nextProps)=>{
    this.setState({excelDataSet:nextProps.locationListData});
  }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'Location'},{title:'Address'},{title:'City'},{title:'City List'},{title:'State'},{title:'Zip'}],
      data: this.state.excelDataSet.map((item)=>{
        return [{value:item.location||''},{value:item.addrLine2||''},{value:item.cityLocation||''},{value:item.cityName||''},{value:item.state||''},{value:item.zip||''}]
      })
    }])
  }
  render() {
    const columns=[{
      Header: 'LOCATION',
      id:'location',
      accessor: 'location', // String-based value accessors!
      filterable:true,
      Cell:props=><Link to={'locationsDetails?lName='+props.original.location}>{props.value}</Link>
      
    },
    { Header: 'PHYSICAL LOCATION',
    id:'PHYSICAL LOCATION',
    accessor: 'physicalLocation', // String-based value accessors!
    Filter: ({ filter, onChange }) =>
    <select className="form-control"
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : ''}
    >
     <option value="">All</option>
      <option value="1">ENABLED</option>
      <option value="0">Disabled</option>
    </select>,
    Cell: props => props.value===1?<i className="fa fa-check"></i>:null
    },
    { Header: 'IMPOUND LOCATION',
    id:'impound location',
    accessor: 'impoundLocation', // String-based value accessors!
    Filter: ({ filter, onChange }) =>
    <select className="form-control"
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : ''}
    >
     <option value="">All</option>
      <option value="1">ENABLED</option>
      <option value="0">Disabled</option>
    </select>,
    Cell: props => props.value===1?<i className="fa fa-check"></i>:null
    },
    // { Header: 'PAYMENT LOCATION',
    // id:'payment location',
    // accessor: 'paymentLocation', // String-based value accessors!
    // Filter: ({ filter, onChange }) =>
    // <select className="form-control"
    //   onChange={event => onChange(event.target.value)}
    //   style={{ width: '100%' }}
    //   value={filter ? filter.value : ''}
    // >
    //  <option value="">All</option>
    //   <option value="1">ENABLED</option>
    //   <option value="0">Disabled</option>
    // </select>,
    // Cell: props => props.value===1?<i className="fa fa-check"></i>:null
    // },
    // { Header: 'REVIEW LOCATION',
    // id:'review location',
    // accessor: 'reviewLocation', // String-based value accessors!
    // Filter: ({ filter, onChange }) =>
    // <select className="form-control"
    //   onChange={event => onChange(event.target.value)}
    //   style={{ width: '100%' }}
    //   value={filter ? filter.value : ''}
    // >
    //  <option value="">All</option>
    //   <option value="1">ENABLED</option>
    //   <option value="0">Disabled</option>
    // </select>,
    // Cell: props => props.value===1?<i className="fa fa-check"></i>:null
    // },
  ];
    return (
      <Fragment>      
      <div className="row">
      <div className="col-md-5 col-sm-12 mb-5 mt-2">
      <div className="input-group mb-1">
        <input className="form-control" placeholder="Search"
         value={this.state.searchQuery} type="text" onChange={(e)=>{
          this.setState({searchQuery:e.target.value})
        }} />
        <div className="input-group-append">
          <button className="btn btn-sm btn-success" type="button"><i className="fa fa-search" aria-hidden="true" onClick={(e)=>{
            this.props.onSearchClick(e,this.state.searchQuery)
          }}></i></button>
        </div>
      </div>      
      </div>
        <div className="col-md-7 col-sm-6 mb-5"> 
        <div className="pull-right">  
        <div className="btn-list" style={{display:'inline-block'}}>
        <TmExportExcel name={'Location'} filename={`${"Location_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
       {
         (this.props.accessLevel.basicAccess.add||false)===true?
        <button className="btn btn-success btn-sm" onClick={(e)=>{
          this.props.addNewLocationOnClick(e,0)
        }} ><i className="fa fa-plus"></i> Add New Location</button>:null
       }
        </div>        
        </div>
        </div>
      </div>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-10">				
        <TmReactTable ref={(table)=>this.locationTable=table}
        onFilteredChange={()=>{
          let data=this.locationTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable={false}
            resizable
            defaultPageSize={10}
            data={this.props.locationListData||[]}
            columns={columns}
            sorted={[{
              id: 'location',
              desc: false
            }]}
        />	
        </div></div>				
      </Fragment>
    );
  }
}

export default withRouter(LocationList);
