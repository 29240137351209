import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import StatementMessage from '../../components/adminComponents/statementMeassage/statementMessage';
import {getStatementMessage,onStatementMessageFieldUpdate,saveStatementMessage} from '../../actions/adminAction/statementMessageAction'
import { connect } from 'react-redux';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle='Statement Message';
class StatementMessagePage extends Component {
	constructor(){
		super();
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.bindStatementMessage=this.bindStatementMessage.bind(this);
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.bindStatementMessage();
	}
	bindStatementMessage=()=>{
		const {dispatch}=this.props;
		dispatch(getStatementMessage());

	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	
  render() {
		const accessLevelObj=getAccessLevelsByModule('Statement Message');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4>Statement Message</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-30 bg-white border-radius-4 box-shadow container-box">
				{accessLevelObj.basicAccess.view?
					<StatementMessage statementObj={this.props.statementObj} accessLevel={accessLevelObj||{}} onFieldValueChange={(e,key,value)=>{
						const {dispatch}=this.props;
						dispatch(onStatementMessageFieldUpdate(key,value))
					}} onSubmitStatementMessage={async()=>{
						const {dispatch}=this.props;
						let res=await dispatch(saveStatementMessage(this.props.statementObj))
						if(res.statusCode===200){
							notify.createNotification('success',res.statusMsg,this.props.refNotify);
						}else{
							notify.createNotification('error',res.statusMsg,this.props.refNotify);
						}
						}}

					/>:null}	
			</div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		statementObj:state.statementMsg
  }
};
export default withRouter(connect(mapStateToProps)(StatementMessagePage));