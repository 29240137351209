import * as actionTypes from '../../actions/actionTypes';
const defaultStates={
    stateList:[]
}

export const stateReducer = (state = defaultStates, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_STATES:
      return {...state , stateList:(action.stateList || defaultStates.stateList)};
    default:
      return state;
  }
};

const defaultDepartment={
  departmentList:{}
}
export const departmentReducer = (state = defaultDepartment, action) => {
switch (action.type) {
  case actionTypes.GET_DEPARTMENTS:
    return {...state , departmentList:(action.departmentList || defaultDepartment.departmentList)};
  default:
    return state;
}
};
const defaultLicenseTypes={
  licenseTypeList:[]
}
export const licenseTypeReducer = (state = defaultLicenseTypes, action) => {
switch (action.type) {
  case actionTypes.GET_LICENSE_TYPES:
    return {...state , licenseTypeList:(action.licenseTypeList || defaultLicenseTypes.licenseTypeList)};
  default:
    return state;
}
};

const defaultAccountTypes={
  accountTypes:[]
}
export const accountTypeReducer = (state = defaultAccountTypes, action) => {
switch (action.type) {
  case actionTypes.GET_ACCOUNT_TYPE:
    return {...state , accountTypes:(action.accountTypes || defaultAccountTypes.accountTypes)};
  default:
    return state;
}
};
const defaultSalesRep={
  salesRep:[]
}
export const salesRepReducer = (state = defaultSalesRep, action) => {
switch (action.type) {
  case actionTypes.GET_SALES_REP:
    return {...state , salesRep:(action.salesRep || defaultSalesRep.salesRep)};
  default:
    return state;
}
};
const defaultCallReasons={
  callReasons:[]
}
export const callReasonsReducer = (state = defaultCallReasons, action) => {
switch (action.type) {
  case actionTypes.GET_CALL_REASONS:
    return {...state , callReasons:(action.callReasons || defaultCallReasons.callReasons)};
  default:
    return state;
}
};

const defaultLocationlist={
  locationList:[]
}
export const locationListReducer = (state = defaultLocationlist, action) => {
switch (action.type) {
  case actionTypes.GET_LOCATION_LIST:
    return {...state , locationList:(action.locationList || defaultLocationlist.locationList)};
  default:
    return state;
}
};

const defaultCompanyType={
  companyType:[]
}
export const companyTypeReducer = (state = defaultCompanyType, action) => {
switch (action.type) {
  case actionTypes.GET_COMPANY_TYPE:
    return {...state , companyType:(action.companyType || defaultCompanyType.companyType)};
  default:
    return state;
}
};