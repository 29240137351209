import * as actionTypes from '../../actions/actionTypes';
const defaultObject={
  searchInvoiceList:[],
  childComList:[],
  associateComp:[]
}
export const invoiceSearchReducer = (state = defaultObject, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_SEARCH_LIST:
        return {...state , searchInvoiceList:(action.searchInvoiceList || defaultObject.searchInvoiceList)};
    case actionTypes.LIST_OF_CHILD_COMP:
          return {...state , childComList:(action.childComList || defaultObject.childComList)};
    case actionTypes.LIST_OF_ASSOCIATE_COMP:
            return {...state , associateComp:(action.associateComp || defaultObject.associateComp)};      
    default:
      return state;
  }
};