import React from 'react'
import {Accordion, Card} from 'react-bootstrap';
import StatusSearch from '../../dashboard/statusSearch'
class VisibilityToggles extends React.Component{
    render(){
        return(
        <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" className="p-2 text-center">
          Visibility Toggles
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card box-shadow text-center map-card-box">
							<div className="card-body p-3">
								<h5 className="card-title">Trucks</h5>
								<div className="card-text">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <StatusSearch 
                                                id={'trucks'} 
                                                showAllOption={true} 
                                                label={'Show'} 
                                                searchObject={this.props.filterContent.trucks}
                                                onChange={(options)=>{
                                                    this.props.onSelectionChange('trucks',options);
                                                }} />
                                        </div>
                                    </div>
                                </div>
							</div>
						</div>
                    </div>
                    <div className="col-md-6">
                        <div className="card box-shadow text-center map-card-box">
							<div className="card-body p-3">
								<h5 className="card-title">Invoice's</h5>
								<div className="card-text">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <StatusSearch 
                                            id={'pickup'} 
                                            showAllOption={false} 
                                            label={'Pickup'} 
                                            searchObject={this.props.filterContent.invoice.pickup}
                                            onChange={(options)=>{
                                                let _inv= this.props.filterContent.invoice;
                                                _inv['pickup']=options;
                                                    this.props.onSelectionChange('invoice',_inv);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <StatusSearch 
                                                id={'pri'} 
                                                showAllOption={false} 
                                                label={'Priority'} 
                                                searchObject={this.props.filterContent.invoice.priority}
                                                onChange={(options)=>{
                                                let _inv= this.props.filterContent.invoice;
                                                _inv['priority']=options;
                                                    this.props.onSelectionChange('invoice',_inv);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 map-toggle-switch">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" 
                                                className="custom-control-input" 
                                                id="customSwitches" checked={this.props.filterContent.dropLocation} onChange={(e)=>{
                                                    this.props.onSelectionChange('dropLocation',e.target.checked?true:false);
                                                }} />
                                                <label className="custom-control-label" for="customSwitches">Drop Locations</label>
                                            </div>                               
                                        </div>
                                    </div>
                                </div>
							</div>
						</div>                        
                    </div>
                </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>  
      </Accordion>)
    }
}
export default VisibilityToggles