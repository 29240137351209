import React, { Component }  from 'react';
import { LAUNCH_URL, ROOT_ADMIN, ROOT_DISPATCHER } from '../../util/customMethods';
export const tableColumns = [
    {
        accessor: 'invoiceNo',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(LAUNCH_URL+'/'+ROOT_DISPATCHER+'/serviceCallInfo/'+props.original.invoiceNo,'_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 100
    },
    {
        accessor: 'dateTimeOfChange',
        Header: 'Date/Time of Change',
        style: { textAlign: 'center' },
        width: 175
    },
    {
        accessor: 'userId',
        Header: 'Original User',
        style: { textAlign: 'center' },
        width: 125
    },
    {
        accessor: 'originalDateTimeofPayment',
        Header: 'Original Date/Time Of Payment',
        style: { textAlign: 'center' },
        width: 175
    },
    {
        accessor: 'originalPaymentLocation',
        Header: 'Original Payment Location',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'originalPaymentType',
        Header: 'Original Payment Type',
        style: { textAlign: 'center' },
        width: 75
    },
    {
        accessor: 'originalPaymentAmt',
        Header: 'Original Amount',
        style: {textAlign: 'center' },
        width: 75,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.originalPaymentAmt||0)===parseInt(filter.value||0),
    },
    {
        accessor: 'newUser',
        Header: 'New User',
        style: { textAlign: 'center' },
        width: 125
    },
    {
        accessor: 'newDateTimeofPayment',
        Header: 'New Date/Time Of Payment',
        style: { textAlign: 'center' },
        width: 175
    },
    {
        accessor: 'newPaymentLocation',
        Header: 'New Location',
        style: { textAlign: 'center' },
        width: 125
    },
    {
        accessor: 'newPaymentType',
        Header: 'New Type',
        style: { textAlign: 'center' },
        width: 100
    },
    {
        accessor: 'newPaymentamt',
        Header: 'New Amount',
        style: { textAlign: 'center' },
        width: 95,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.newPaymentamt||0)===parseInt(filter.value||0)
    }
];
export const receiptcols = [
    {
        accessor: 'invoiceId',
        Header: 'Invoice',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/serviceCallInfo/${props.original.invoiceId}`,'_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 200
    },
    {
        accessor: 'account',
        Header: 'Account',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.accountId}`,'_blank');
        }}>{props.original.account}</a>,
        width: 200
    },
    {
        accessor: 'billTo',
        Header: 'Bill-To',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.billToAccountId}`,'_blank');
        }}>{props.original.billTo}</a>,
        width: 200
    },
    {
        accessor: 'paymentMethod',
        Header: 'Payment Method',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        style: { textAlign: 'center' },
        width: 200,
        filterMethod: (filter, row) =>
                    parseInt(row._original.amount||0)===parseInt(filter.value||0)
    },
    {
        accessor: 'userId',
        Header: 'User-Id',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onClick={()=>{
        }} onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/employeesDetails?eid=${props.original.employeeId}`,'_blank');
        }}>{props.original.userId}</a>,
        width: 200
    },
    {
        accessor: 'location',
        Header: 'Location',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'paymentDate',
        Header: 'Payment Date',
        style: { textAlign: 'center' },
        width: 200
    }
];
export const receiptCol1 = [
    {
        accessor: 'location',
        Header: 'Location',
        style: { textAlign: 'center' },
        width: 125,
        
    },
    {
        accessor: 'cash',
        Header: 'Cash',
        style: { textAlign: 'center' },
        width: 125,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.cash||0)===parseInt(filter.value||0)
    },
    {
        accessor: 'check',
        Header: 'Check',
        style: { textAlign: 'center' },
        width: 125,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.check||0)===parseInt(filter.value||0)
    },
    {
        accessor: 'credit',
        Header: 'Credit',
        style: { textAlign: 'center' },
        width: 125,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.credit||0)===parseInt(filter.value||0),
    },
    {
        accessor: 'transfer',
        Header: 'Transfer',
        style: { textAlign: 'center' },
        width: 125,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.transfer||0)===parseInt(filter.value||0),
    },
    {
        accessor: 'advance',
        Header: 'Advance',
        style: { textAlign: 'center' },
        width: 125,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.advance||0)===parseInt(filter.value||0),

    },
    {
        accessor: 'total',
        Header: 'Total',
        style: { textAlign: 'center' },
        width: 125,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.total||0)===parseInt(filter.value||0)

    }

]