import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import ARS_Acc_Link_Group from '../accountInfo/ARS_Acc_Link_Group';
import ToggleButton from 'react-toggle-button'
import { getCompanyId } from '../../../../util/customMethods';
import ReactSelect from '../../../utilComponents/tmSlimSelect/reactSelect';
class Billing_ARS extends Component {
    state = {
        billingContactRec: {},
        billingContactRecIndex: -1
    }
    checkAccess = () => {
        if ((this.props.accountBasicInfo.companyId === getCompanyId()) || (this.props.accountBasicInfo.companyId === 0)) {
            if (this.props.accountBasicInfo.accountId === 0) {
                if ((this.props.accessLevel.basicAccess.add || false) === true) {
                    return false
                } else {
                    return true
                }
            } else {
                if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
    render() {
        const { allStates, accountBasicInfo, userDefaultCharges } = this.props;
        return (<Fragment>
            <div className="pd-10">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h6 className="text-blue text-bg-color form-group">DISTRIBUTATION CONFIG.</h6>
                    </div>
                </div>
                <Form ref={c => { this.formBilling = c; this.props.initCallBack(c); }}>
                    <fieldset disabled={this.checkAccess()}>
                        <div className="row">
                        <div className="col-md-4 col-sm-12 mb-10">
                                <div className="">
                                <div className="pd-10">
                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.isAutoDispatch == 1}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('isAutoDispatch', value==1?0:1);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="time_priorty">AUTO DISPATCH</label>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12 mb-10">
                                <div className="card">
                                    <div className="card-body pd-10">
                                        <div className="mb-5 row">
                                            <div className="col-sm-12">
                                                <h6>COMPANY</h6>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.company_priorty == 1}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('company_priorty', 1);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="company_priorty">All</label>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.company_priorty == 2}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('company_priorty', 2);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="company_priorty">First in Order (Soft)</label>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.company_priorty == 3}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('company_priorty', 3);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="company_priorty">First Only (Hard)</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 mb-10">
                                <div className="card">
                                    <div className="card-body pd-10">
                                        <div className="mb-5 row">
                                            <div className="col-sm-12">
                                                <h6>TIME</h6>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.time_priorty == 1}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('time_priorty', 1);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="time_priorty">All</label>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.time_priorty == 2}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('time_priorty', 2);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="time_priorty">Within Response (Soft)</label>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.time_priorty == 3}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('time_priorty', 3);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="time_priorty">Fastest Response (Hard)</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 mb-10">
                                <div className="card">
                                    <div className="card-body pd-10">
                                        <div className="mb-5 row">
                                            <div className="col-sm-12">
                                                <h6>AVAILABILITY</h6>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.availability_priorty == 1}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('availability_priorty', 1);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="availability_priorty">All</label>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.availability_priorty == 2}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('availability_priorty', 2);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="availability_priorty">Semi Available (Soft)</label>
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="col-sm-4">
                                                <ToggleButton
                                                    value={accountBasicInfo.availability_priorty == 3}
                                                    onToggle={(value) => {
                                                        this.props.onAccountBasicInfoFieldsChange('availability_priorty', 3);
                                                    }} />
                                            </div>
                                            <div className="col-sm-8">
                                                <label className="form-group" for="availability_priorty">Available (Hard)</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </fieldset>
                </Form>
                <div className="row">
                            <div className="col-md-12 col-sm-12 mb-10">
                                <div className="card">
                                    <div className="card-body pd-10">
                        <h6 className="text-blue text-bg-color form-group">SUB. ACCOUNT.</h6>
                        <ReactSelect name="subAccount"
                        closeMenuOnSelect={false}
                        isMulti
                        options={this.props.accountList.filter(item => item.optionVal !== this.props.accountBasicInfo.accountId).map((item, t) => {
                            return ({ value: item.optionVal, label: item.optionText })
                        })}
                        value={(this.props.accountBasicInfo.subAccountMap || []).map((item) => {
                            let found = (this.props.accountList||[]).find(obj => {return (obj.optionVal == item)});
                            return ({ value: found.optionVal||'' ,label:found.optionText||''})
                        })}
                        onChange={(selectedOption) => {
                            let myArray = [];
                            (selectedOption || []).map((selectedVal) => {
                                myArray.push(selectedVal.value)
                            })
                            this.props.onAccountBasicInfoFieldsChange('subAccountMap', myArray);
                            this.forceUpdate();
                        }} />
                        </div>
                    </div>
                    </div>
                    </div>
                {this.props.aid == 0 ? null : <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                        <ARS_Acc_Link_Group disabled={this.checkAccess()}
                            isPhoneRequired={true} isEmailRequired={true}
                            contactListData={accountBasicInfo.billingContactForm.accountContacts || []}
                            showDefaultOption={true} listOfAccountsLink={this.props.listOfAccountsLink}
                            onDefaultContactChanged={(e, recIndex) => {
                                let selectedRec = Object.assign({}, accountBasicInfo.billingContactForm.accountContacts[recIndex]);
                                if (selectedRec) {
                                    selectedRec.defaultEmailId = 1;
                                    this.props.onBillingContactSubmit(e, selectedRec, recIndex, true, (success) => {
                                    }, (error) => {
                                    });
                                }
                            }}
                            Go={(e, Obj, index, success, fail) => {
                                this.props.Go(e);
                            }}
                            deleteLink={(id) => { this.props.deleteLink(id) }}
                            onContactDelete={(index) => {
                                this.props.onBillingContactDelete(index);
                            }}
                        />

                    </div>
                </div>}
            </div>

            <div className="row pd-10">
                <div className="col-md-12 text-right">
                    <button className="btn btn-sm btn-success" disabled={this.checkAccess()} onClick={(e) => {
                        e.preventDefault();
                        this.props.next()
                    }}>
                        Next
                    </button>
                    {
                        ((this.props.accountBasicInfo.accountId || 0) !== 0) ?
                            <button
                                className="btn btn-sm btn-success ml-3"
                                style={{ marginTop: '0px' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onTimePlanSubmit(e);
                                }}
                                disabled={this.checkAccess()}
                            >SAVE ACCOUNT</button> : null
                    }
                </div>
            </div>
        </Fragment>)
    }
}
export default Billing_ARS;
