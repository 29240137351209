import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../../util/constants';
import { isManagement } from '../../../../util/customMethods';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import {
    required,
} from "../../../../util/validationMethods.js";
import moment from 'moment';
const invoiceListData = [
	{value:"InvoiceNumber", name:"Invoice Number"},
	{value:"Vin", name:"VIN"},
	{value:"Plate", name:"Plate"},
	{value:"RequestedBy", name:"RequestedBy"},
	{value:"Year", name:"Year"},
	{value:"Make", name:"Make"},
	{value:"Model", name:"Model"},
	{value:"Color", name:"Color"},
	{value:"Reason", name:"Reason"},
	{value:"RatePlan", name:"RatePlan"},
	{value:"Driver", name:"Driver"},
	{value:"ORDR", name:"OR/DR"},
	{value:"Truck", name:"Truck"},
	{value:"Units", name:"Unit Number"},
	{value:"OFN", name:"RO First Name"},
	{value:"OLN", name:"RO Last Name"},
    {value:"PONO", name:"PO No"},
    {value:"companyId", name:"Company Name"}
]
class SearchCriteria extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            companyId:null
        } 
    }
	createUI() {
		return this.props.customVal.map((el, i) =>
		  <div className="col-md-2 mb-10">
			  {i === 0 ?
			  <button type="button" title="Add search criteria" className="btn btn-sm btn-success"
			  style={{position:'absolute',bottom:'3px',right:'0',margin:'0',padding:'1px'}} 
			  onClick={(e) => {
				e.preventDefault();
				this.props.addSearchCriteria()
			}}
			  disabled={this.props.customVal.length >= invoiceListData.length}>
			  <i class="fa fa-plus-circle" aria-hidden="true" ></i></button>:
							  <button type="button" title="Remove search criteria" className="btn btn-sm btn-danger"
							  onClick={(e) => {
								e.preventDefault();
								this.props.removeSearchCriteria(i)
							}} 
							  style={{position:'absolute',bottom:'3px',right:'0',margin:'0',padding:'1px'}}>
                            <i class="fa fa-times-circle" aria-hidden="true"></i></button>
                            }
				
				<select className="form-control form-control-sm mb-1" 
				onChange={(e) => {
					e.preventDefault();
					this.props.onInvoiceTyoeChange(e,i,'T')
				}}
				 value={el.optionTxt} style={{height:'24px'}}>
				{invoiceListData.map((item) => <option value={item.value}>{item.name}</option>)}
				</select>
				<div className="">
					<input type="text" className="form-control form-control-sm" autocomplete="off"
					onChange={(e) => {
						e.preventDefault();
						this.props.onInvoiceTyoeChange(e,i,'V')
                    }}
                    onKeyPress={(e)=>this.props.onenterKeyPress(e, this.props.customVal, this.state.companyId)}
					value={el.optionVal}
						name="accountName" requiredmsg="Please enter Account Name"
					/>
				</div>
			</div>
		)
	  }
    render() {
		const {fromDate, toDate, customVal} = this.props;
        return (<Fragment>
			<Form ref={c => { this.form = c }}>
            <div className="pd-10 bg-white border-radius-4 box-shadow">
                <div className="row">
                {isManagement()?<div className="col-md-3">
                        <label htmlFor="fromDate" className="form-label col-form-label">Company List</label>
                        <div className="tm-datepicker">
                            <Select name='companyName' value={this.state.companyId||0} onChange={(e)=>{this.setState({companyId:e.target.value})}} className='form-control'>
                                <option value=''>select company</option>
                                {this.props.childCompList.map((item, key) => { return (<option key={key} value={item.optionVal}>{item.optionText}</option>); })}
                            </Select>
                        </div>
                    </div>:null}
                    <div className="col-md-2">
                        <label htmlFor="fromDate" className="form-label col-form-label">FROM</label>
                        <div className="tm-datepicker">
							<TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            fromDate === "" || fromDate === null
                                                ? null
                                                : moment(
                                                    fromDate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="fromDate"
                                        reqmsg="Expiry Date required"
                                        onDateSelectChange={e => {
                                            this.props.onDateFieldsChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }}
                                    />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="toDate" className="form-label col-form-label">TO</label>
                        <div className="tm-datepicker">
							<TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            toDate === "" || toDate === null
                                                ? null
                                                : moment(
                                                    toDate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="toDate"
                                        reqmsg="Expiry Date required"
                                        onDateSelectChange={e => {
                                            this.props.onDateFieldsChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }}
                                    />
                        </div>
                    </div>
                    {this.createUI()}
                    <div className="col-md-2">
                        <input type="button" className="btn btn-sm btn-success" value="Search"
                        onClick={(e)=>{
                            this.props.onCallActionBtnClick(customVal,this.state.companyId);
                          }}></input>
                    </div>
                </div>
            </div>
			</Form>
        </Fragment>);
    }
}
export default SearchCriteria

