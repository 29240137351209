import React, { Component,Fragment } from 'react';
import {InputGroup} from 'react-bootstrap';
import Input from 'react-validation/build/input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import {GlobalValues} from '../../../../util/constants';
import './tmDateTimePicker.css'
import moment from 'moment'
class TmDateTimePicker extends Component {
    constructor(props){
        super(props)
        this.state={            
            dateFormat:this.props.dateFormat|| GlobalValues.dateTimeFormat,
            validationArray:this.props.validationArray||[],
            showTimeSelect:this.props.showTimeSelect||false,
            timeFormat:this.props.timeFormat||GlobalValues.timeFormat,
            showMonthDropdown:this.props.showmonthdropdown||false,
            showYearDropdown:this.props.showYearDropdown||false
            
        }
    }

    render(){
        return(<>        
                <DatePicker {...this.props}
                className="form-control"
                selected={this.props.selectedDate } 
                dateFormat={this.state.dateFormat.replace('MM/DD/YYYY','MM/dd/yyyy')}
                showTimeSelect={this.state.showTimeSelect}
                timeCaption="time"
                timeFormat={this.state.timeFormat}
                showYearDropdown={this.state.showYearDropdown}
                showMonthDropdown={this.state.showMonthDropdown}
                popperPlacement={this.props.name === 'clearedDateTime' ? 'bottom-end' : 'bottom-start'}
                useShortMonthInDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                disabledKeyboardNavigation
                placeholderText={this.state.dateFormat}                
                ref={(c) => this._calendar = c}
                onChange={(e)=>{
                    if(moment(e).format('MM/DD/YYYY').length > 10 && e !== null){
                        e=(moment(moment(e).format('MM/DD/YYYY').substr(0,10)))
                    }
                    this.props.onDateSelectChange(e);
                    }}
                onClickOutside={(e)=>{
                    if((this.props.selectedDate || '') === ''){
                        this.props.onDateSelectChange(moment(new Date()));
                    }
                    }}  />
                <Input type="text" className="hideTextBox" disabled={this.props.disabled}                           
                    requiredmsg={this.props.reqmsg} 
                    value={this.props.selectedDate===null?'':this.props.selectedDate} 
                    validations={this.props.validationArray} 
                />
        </>)
    }

}
export default TmDateTimePicker