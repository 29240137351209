import React, { Component,Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable'
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
import moment from 'moment'

class TowTypeList extends Component {
  constructor(){
    super();
    this.state={
      searchQuery:'',
      excelDataSet:[]     
    } 
    this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
  }
  componentWillReceiveProps=(nextProps)=>{
    this.setState({excelDataSet:nextProps.towTypeData});
  }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'Tow Types'}],      
      data:this.state.excelDataSet.map((item)=>{
        return [{value:item.towType}]
      })
    }])
  }
  
  render() {
    const {towTypeData} =this.props;
    const columns=[{
      Header: 'Tow Type',
      accessor: 'towType', // String-based value accessors!
      filterable:true,
      Cell: props => <a className="btn-link" href="javascript:void(0)" title="Edit" onClick={(e)=>{
        let _aid=props.original.towTypeId;
        this.props.editTowTypeOnClick(e,_aid);
      }}>{props.value}</a>,
      Footer: (
        <span>Total Records : {
          towTypeData.reduce((total, { accountName }) => total +=1, 0)
        }</span>
      )
    }];
    return (
      <Fragment>
      <div className="row">
      <div className="col-md-5 col-sm-12 mb-5 mt-2">
      <div className="input-group mb-1">
        <input className="form-control" placeholder="Search"
         value={this.state.searchQuery} type="text" onChange={(e)=>{
          this.setState({searchQuery:e.target.value})
        }} />
        <div className="input-group-append">
          <button className="btn btn-sm btn-success" type="button"><i className="fa fa-search" aria-hidden="true" onClick={(e)=>{
            this.props.onSearchClick(e,this.state.searchQuery)
          }}></i></button>
        </div>
      </div>      
      </div>
      <div className="col-md-7 col-sm-6 mb-5">  
      
      <div className="pull-right">
      <div className="btn-list" style={{display:'inline-block'}}>
      <TmExportExcel name={'Tow Types'} filename={`${"TowTypes_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
      {
         (this.props.accessLevel.basicAccess.add||false)===true?
      <button className="btn btn-success btn-sm" onClick={(e)=>{
        this.props.addTowTypeOnClick(e,0)
      }} ><i className="fa fa-plus"></i> Add Tow Type</button>:null}
      </div>
      </div> 
      </div>
      </div>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">	
        <TmReactTable
        ref={(table)=>this.towTypeTable=table}
        onFilteredChange={()=>{
          let data=this.towTypeTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={5}
            data={towTypeData}
            columns={columns}
            sorted={[{
              id: 'towType',
              desc: false
            }]}
        />	
        </div></div>		
      </Fragment>
    );
  }
}

export default TowTypeList;
