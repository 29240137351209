import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { Modal } from 'react-bootstrap';
import vehicleImage from '../../../assets/img/car-top.jpg';
import Cleave from 'cleave.js/react';
import {
    required,
    email,
    isValidForm,
    phone,
    vinValidate,
    isLenghtOf,
    isNumeric,
    zipValidation,
    isCurrency,
    isYear,
    toInt
} from "../../../util/validationMethods.js";
import { isManagement } from '../../../util/customMethods';
class VehicleInfo extends Component {
   state = {
            modalVehicleDetails: false
        }
    validateVin(e,vinNo) {
        e.preventDefault();
        this.vinform.validateAll();
        if (!isValidForm(this.vinform)) {
        } else {
            this.props.validateVinNum(vinNo)
        }
      }
    handleVinDetailsOpen=(e,vinNo)=>{
        e.preventDefault();
        if (!isValidForm(this.vinform)) {
        } else {
            this.props.getVehicleDetails(vinNo)
        }
        this.setState({modalVehicleDetails: true});
    }
    handleVinDetailsClose=()=>{
        this.setState({modalVehicleDetails: false});
    }
    render() {
        const invoiceVehicle = this.props.invoiceInfo._invoiceObj.invoiceVehicle || {};
        const _invoiceObj = this.props.invoiceInfo._invoiceObj||{};
        const { vinDetals } = this.props || [];
        const invoiceBilling = this.props.invoiceInfo._invoiceObj.invoiceBilling || {};
        const isDisabled = (!(this.props.invoicePermission.edit||false)) || (invoiceBilling.isVerifiedAndLocked==1)||(_invoiceObj.callStatus=='Cancel') ;
        return (<Fragment>
            <div className="pd-10">
                <Form ref={c => {
                            this.formVehicleInfo = c;
                            this.props.initCallBack(c);
                        }}className="">
                    <fieldset disabled={isDisabled}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="custom-control custom-checkbox custom-control">
                                    <input type="checkbox" id="inStorage" className="custom-control-input" value={(invoiceVehicle.isImpound || 0) == 1} disabled={((_invoiceObj.dropOffLocation||'')=='') && ((_invoiceObj.invoiceId||'')!='')}
                                        checked={(invoiceVehicle.isImpound || 0) == 1} onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, (invoiceVehicle.isImpound || 0) == 1 ? 0 : 1) }} name="isImpound" />
                                    <label htmlFor="inStorage" className="custom-control-label">IN STORAGE</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-4">
                                <h6 className="text-blue text-bg-color form-group">Owner Information</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-checkbox custom-control">
                                                    <input type="checkbox" className="custom-control-input" name="isDriverIsOwner" id="rdDriverIsOwner" checked={invoiceVehicle.isDriverIsOwner || 0 == 1}
                                                        onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, (invoiceVehicle.isDriverIsOwner || 0) == 1 ? 0 : 1) }} />
                                                    <label htmlFor="rdDriverIsOwner" className="custom-control-label">DRIVER IS OWNER</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-checkbox custom-control">
                                                    <input type="checkbox" className="custom-control-input" name="noInfo" id="rdNoOwnerInfo"
                                                        checked={(invoiceVehicle.noInfo || 0) === 1} onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, (invoiceVehicle.noInfo || 0) === 1 ? 0 : 1) }} />
                                                    <label htmlFor="rdNoOwnerInfo" className="custom-control-label">NO OWNER INFO</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">FIRST NAME</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                    name="vehicle_Owner_FirstName" value={invoiceVehicle.vehicle_Owner_FirstName} requiredmsg="Please enter Account Name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">LAST NAME</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Owner_LastName" value={invoiceVehicle.vehicle_Owner_LastName} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">ADDRESS</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Owner_Addr_Line1" value={invoiceVehicle.vehicle_Owner_Addr_Line1} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CITY</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Owner_City" value={invoiceVehicle.vehicle_Owner_City} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">STATE</label>
                                            <div className="">
                                                <Select className="form-control form-control-sm" value={invoiceVehicle.vehicle_Owner_State || ''} name="vehicle_Owner_State"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}>
                                                    <option value={""}>{"select state"}</option>
                                                    {(this.props.allStates || []).map((states, key) => {
                                                        return (
                                                            <option key={key} value={states.sortName}>
                                                                {states.optionVal}
                                                            </option>);
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">ZIP CODE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Owner_Zip" value={invoiceVehicle.vehicle_Owner_Zip} validations={[zipValidation]} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="ownerPhone" className="form-label col-form-label">PHONE NUMBER</label>
                                            <div className="">
                                                <Input type="text" id="ownerPhone" className="form-control form-control-sm"
                                                    name="vehicle_Owner_PhoneNo" value={invoiceVehicle.vehicle_Owner_PhoneNo} requiredmsg="Please enter phone number"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                    validations={[phone]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">EMAIL</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" validations={[email]}
                                                    name="vehicle_Owner_Email" value={invoiceVehicle.vehicle_Owner_Email} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">DRIVERS'S LICENSE #</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Info_DLNo" value={invoiceVehicle.vehicle_Info_DLNo} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">DEFECTS</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Defects" value={invoiceVehicle.vehicle_Defects} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" checked={(invoiceVehicle.vehicle_Status || '').toUpperCase() == 'WRECKED'}
                                                        className="custom-control-input" name="vehicle_Status" id="rdWrecked" value="Wrecked"
                                                        onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                    />
                                                    <label htmlFor="rdWrecked" className="custom-control-label">WRECKED</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="vehicle_Status" id="rdIntact" value="Intact"
                                                        checked={(invoiceVehicle.vehicle_Status || '').toUpperCase() == 'INTACT'}
                                                        onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }} />
                                                    <label htmlFor="rdIntact" className="custom-control-label">INTACT</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="vehicle_Status" id="rdStripped" value="Stripped"
                                                        checked={(invoiceVehicle.vehicle_Status || '').toUpperCase() == 'STRIPPED'}
                                                        onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }} />
                                                    <label htmlFor="rdStripped" className="custom-control-label">STRIPPED</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h6 className="text-blue text-bg-color form-group" style={{ margin: '0' }}>Vehicle Information</h6>
                                <div className="row">
                                    <div className="col-md-12">
                                    <label htmlFor="accountName" className="form-label col-form-label mr-2">VIN NUMBER</label>
                                    <i class="fa fa-question-circle col-form-label" 
                                    onClick={(invoiceVehicle.vehicle_VIN || '') !== '' ? (e)=>this.handleVinDetailsOpen(e,invoiceVehicle.vehicle_VIN) : ''}
                                    style={{ color: '#00bcf2' }}></i>
                                    </div>
                                    
                                <div className="col-md-8">
                                    <Form
                                  ref={c => {
                                    this.vinform = c;
                                  }}
                                >
                                  <Input
                                    id='vinNo'
                                    ref="targetVinDetails"
                                    className="form-control"
                                    type="text"
                                    name="vehicle_VIN"
                                    requiredmsg="Please enter VIN"
                                    value={invoiceVehicle.vehicle_VIN}
                                    placeholder=""
                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value.toUpperCase())}}
                                    validations={[required, vinValidate]}
                                  />
                                </Form>
                              </div>
                              <div className="col-sm-4 text-right">
                                <input type="button" className="btn btn-sm btn-success" name="" value="Validate" onClick={(event) => this.validateVin(event,invoiceVehicle.vehicle_VIN)} />
                              </div>
                                </div>
                                <div className="row">
                                   {isManagement()?<span> <label htmlFor="accountName" className="form-label col-form-label col-md-12">NO VIN REASON</label>
                                    <div className="col-md-8">
                                        <Select className="form-control form-control-sm" value={invoiceVehicle.noVINReason || ''} disabled={(invoiceVehicle.noVIN || 0) !== 1}
                                            onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}>
                                            <option value={""}>{"select state"}</option>
                                            {(invoiceVehicle.vinReasonOption || []).map((item, key) => {
                                                return (
                                                    <option key={key} value={item.optionVal}>
                                                        {item.optionVal}
                                                    </option>);
                                            })}
                                        </Select>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <div className="custom-control custom-checkbox custom-control">
                                            <input type="checkbox" className="custom-control-input" name="noVIN" id="noVIN" checked={invoiceVehicle.noVIN==1}
                                                onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, (invoiceVehicle.noVIN || 0) === 1 ? 0 : 1) }}
                                            />
                                            <label htmlFor="noVIN" className="custom-control-label">NO VIN</label>
                                        </div>
                                    </div></span>:null}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">YEAR</label>
                                            <div className="">
                                                <Cleave type="text" className="form-control form-control-sm"
                                                placeholder="YYYY"
                                                options={{ date: true, datePattern: ['Y'] }}
                                                    name="vehicle_Year" value={invoiceVehicle.vehicle_Year}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">MAKE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Make" value={invoiceVehicle.vehicle_Make} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">MODEL</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Model" value={invoiceVehicle.vehicle_Model} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">COLOR</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Color" value={invoiceVehicle.vehicle_Color} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">TRIM/STYLE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Style" value={invoiceVehicle.vehicle_Style} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">LICENSE PLATE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Plate_No" value={invoiceVehicle.vehicle_Plate_No} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">COUNTRY</label>
                                            <div className="">
                                                <Select className="form-control form-control-sm" value={invoiceVehicle.vehicle_Country || ''} name="vehicle_Country"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}>
                                                    <option value={""}>{"-select-"}</option>
                                                    <option value={"US"}>{"US"}</option>
                                                    <option value={"CA"}>{"CA"}</option>
                                                    <option value={"MX"}>{"MX"}</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">STATE</label>
                                            <div className="">
                                                <Select className="form-control form-control-sm" name="vehicle_State" value={invoiceVehicle.vehicle_State || ''}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}>
                                                    <option value={""}>{"select state"}</option>
                                                    {(this.props.allStates.filter(o => o.country.toLowerCase() === (invoiceVehicle.vehicle_Country||'').toLowerCase()) || []).map((states, key) => {
                                                        return (
                                                            <option key={key} value={states.sortName}>
                                                                {states.optionVal}
                                                            </option>);
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">LICENSE PLATE EXPIRATION DATE</label>
                                            <div className="">
                                                <Cleave type="text" className="form-control form-control-sm"
                                                    name="vehicle_Plate_Expiry" value={invoiceVehicle.vehicle_Plate_Expiry} 
                                                    placeholder="MM/YY"
                                                    options={{ date: true, datePattern: ['m', 'y'] }}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h6 className="text-blue text-bg-color form-group" style={{ margin: '0' }}>Other Information</h6>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">OR/DR</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_OR_DR_No" value={invoiceVehicle.vehicle_OR_DR_No} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label htmlFor="accountName" className="form-label">RADIO:</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="vehicle_Radio" id="rdYes" value={(invoiceVehicle.vehicle_Radio || 0) === 1}
                                                        onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, 1) }}
                                                    />
                                                    <label htmlFor="rdYes" className="custom-control-label">YES</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="vehicle_Radio" id="rdNo" checked={(invoiceVehicle.vehicle_Radio || 0) === 0}
                                                        value="No" onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, 0) }} />
                                                    <label htmlFor="rdNo" className="custom-control-label">NO</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label htmlFor="accountName" className="form-label">KEYS:</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="vehicle_keys" id="keyYes" checked={(invoiceVehicle.vehicle_keys || 0) === 1}
                                                        value="Yes" onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, 1) }} />
                                                    <label htmlFor="keyYes" className="custom-control-label">YES</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="vehicle_keys" id="keysNo" checked={(invoiceVehicle.vehicle_keys || 0) === 0}
                                                        onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, 0) }} />
                                                    <label htmlFor="keysNo" className="custom-control-label">NO</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">KEYS LOCATION</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Key_Location" value={invoiceVehicle.vehicle_Key_Location} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">MILEAGE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_ODM_Reading" value={invoiceVehicle.vehicle_ODM_Reading} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">LOT LOCATION</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="lotLocation" value={invoiceVehicle.lotLocation} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">COMMERCIAL UNIT</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_Comm_Unit_No" value={invoiceVehicle.vehicle_Comm_Unit_No} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">POLICE IMPOUND</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="police_impound" value={invoiceVehicle.police_impound} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">INSURANCE COMPANY/POLICY</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="vehicle_InsuranceClaimNo" value={invoiceVehicle.vehicle_InsuranceClaimNo} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <img id="vehicleImage" src={(invoiceVehicle.vehicle_Image_URL || '') !== '' ? invoiceVehicle.vehicle_Image_URL : vehicleImage} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </Form>
                    <Modal centered size={'md'} dialogClassName="modal-60w" show={this.state.modalVehicleDetails} onHide={this.handleVinDetailsClose}>
                    {vinDetals.length > 0 && vinDetals.map((item) => {
                    return (<Fragment><Modal.Header closeButton>
                        <Modal.Title>Vehicle Information</Modal.Title>
                      </Modal.Header><div className="modal-body"><div className="row vin-popover-row">
                        <div className="col-sm-6">
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">VIN <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.vinNo || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Model Year <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.modelYear || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Make <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.make || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Model <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.model || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Color <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{invoiceVehicle.vehicle_Color || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Body Type <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.bodyType || '-'}</label>
                        </div>
                        
                        </div>
                        <div className="col-sm-6">
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Trim Level <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.trimLevel || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Drive Type <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.driveLineType || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Engine Type <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.engineType || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">Fuel Type <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.fuelType || '-'}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 form-control-label vin-form-control-label">GVWR Class <i class="fa fa-hand-o-right" aria-hidden="true"></i></label >
                            <label className="col-sm-8 form-control-label">{item.gvwrclass || '-'}</label>
                        </div>
                        </div>
                        <div className="col-sm-12 text-center">
                            <button type="button" className="btn btn-danger" onClick={this.handleVinDetailsClose} >Close</button>
                        </div>
                    </div></div></Fragment>)
                    })}
          </Modal>
            </div>
        </Fragment>);
    }

}
export default VehicleInfo
