import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import moment from 'moment';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import {
  GlobalValues
} from '../../../../util/constants.js';
import {
    required,
    isDecimal
} from "../../../../util/validationMethods.js";
import { billableTypeManaged, billableTypeRetail } from '../../../../util/constants'
class CustomChargeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      excelDataSet: []
    }
    this.buildDataFormatForExport = this.buildDataFormatForExport.bind(this)
  }

  buildDataFormatForExport = () => {
    return ([{
      columns: [{ title: 'Charge Name' }],
      data: this.state.excelDataSet.map((item) => {
        return [{ value: item.chargeName }]
      })
    }])
  }
  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.original.isGlobal ? '#0099ff40' : '#6c757d6e',
          color: 'black'
        }
      }
    }
    return {};
  }

  getMaxDate = (ind) => {
    let maxDate = null;
    if (this.props.accountBasicInfo.accountBillingList.length > 0)
      maxDate = (this.props.accountBasicInfo.accountBillingList[ind + 1] || {}).fromDate || null;
  }

  getMinEndDate = (date) => {
    let currntDate = moment(new Date()).add(1, 'days');
    if ((date || null) == null)
      return moment(currntDate._d).format(GlobalValues.dateFormat);
    let avaDate = moment(date, GlobalValues.dateFormat).add(1, 'days');
    return currntDate.valueOf() > avaDate.valueOf() ? moment(currntDate._d).format(GlobalValues.dateFormat) : moment(avaDate._d).format(GlobalValues.dateFormat);
  }
 
  isGreterThen = (date, id) => {
    let currntDate = moment(new Date());
    if ((date || null) == null || (id || 0) == 0)
      return false;
    let avaDate = moment(date, GlobalValues.dateFormat);
    return currntDate.valueOf() > avaDate.valueOf() ? true : false;
  }

  getMinSrtDate = (ind) => {
    let minDate = null;
    let currntDate = moment(new Date());
    minDate = (this.props.accountBasicInfo.accountBillingList[ind - 1] || {}).toDate || null;
    if ((minDate || null) == null)
      return moment(new Date()).format(GlobalValues.dateFormat);
      let avaDate = moment(minDate, GlobalValues.dateFormat).add(1, 'days');
      return currntDate.valueOf() > avaDate.valueOf() ? moment(currntDate._d).format(GlobalValues.dateFormat) : moment(avaDate._d).format(GlobalValues.dateFormat);
  }

  getFilterData = (data, status) => {
    if (data != undefined && data.length > 0) {
      let filteredData = data.filter(function (val) {
        return val.isGloble == status;
      });
      return filteredData;
    }
    return [];
  }
  render() {
    const { globalChargeListData, accountBasicInfo } = this.props;
    let globleData = globalChargeListData.filter(function (val) {
      return val.isGlobal;
    });
    let localData = globalChargeListData.filter(function (val) {
      return !val.isGlobal;
    });
    let index = this.props.keyNo == null ? accountBasicInfo.accountBillingList.length - 1 : this.props.keyNo;
    let billObj = accountBasicInfo.accountBillingList[index];
    return (<>
          <div className="pd-10">
          <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <h6 className="text-blue text-bg-color form-group">CHARGES</h6>
                    </div>
                </div>
          <Form 
          ref={c => {this.formAccountInfo = c;this.props.initCallBack(c)}}>
          <div className="row form-group">
            <div className="col-md-3" >
            <label htmlFor="billableType" className="form-label col-form-label">ADD/EDIT BILLABLE *</label>
                  <div className="">
                    <Select className="form-control" type="text" name={"billableType"} value={billObj.abid}
                      onChange={(e) => {
                        this.props.addRemoveBillableIndex(e.target.selectedIndex)
                        }}>
                      <option value={0}>{"-NEW BILLABLE-"}</option>
                      {accountBasicInfo.accountBillingList.map((item) => {
                        if(item.abid||0 !=0)
                        return  <option value={item.abid}>{item.name}</option>
                        })}
                    </Select>
                  </div>
                </div>
                <div className="col-md-3" >
                  <label htmlFor="billableType" className="form-label col-form-label">BILLABLE TYPE *</label>
                  <div className="">
                    <Select className="form-control" type="text" name={"billableType"} validations={[required]}
                      value={billObj.billableType} requiredmsg="Please Select Billable Type" 
                      onChange={(e) => {
                        this.props.onAccountBasicInfoFieldsChange(index,"billableType", e.target.value)
                      }}>
                      <option value={""}>{"Select Type"}</option>
                      {Object.keys(billableTypeManaged).map((item) => {return <option value={item}>{item}</option>})}
                    </Select>
                  </div>
                </div>
                <div className="col-md-3" >
                  <label htmlFor="billableAmount" className="form-label col-form-label">BILLABLE RATE *{accountBasicInfo.billableType == 'Percentage' ? '( % )*' : '( $ )*'}</label>
                  <div className="">
                    <Input type="text" name="billableRate" validations={[required,isDecimal]}
                      value={billObj.billableRate} errormsg="Please enter valid amount"
                      onChange={(e) => {this.props.onAccountBasicInfoFieldsChange(index,e.target.name, e.target.value)}}
                      className="form-control form-control-sm" requiredmsg="Please Enter Billable Amount" />
                  </div>
                </div>
                <div className="col-md-3" >
                  <label htmlFor="billableAmount" className="form-label col-form-label">BILLABLE NAME *</label>
                  <div className="">
                    <Input type="text" name="name" validations={[required]} value={billObj.name} 
                      onChange={(e) => {this.props.onAccountBasicInfoFieldsChange(index,e.target.name, e.target.value)}}
                      className="form-control form-control-sm" requiredmsg="Please Enter Billable Name" />
                  </div>
                </div>
          </div>
          <div className="row form-group">
                <div className="col-md-3" >
                  <label htmlFor="billableAmount" className="form-label col-form-label">START DATE *</label>
                  <div className="">
                  <TmDateTimePicker className="form-control"
                    selectedDate={(billObj.fromDate||'')==''?null:moment(billObj.fromDate,GlobalValues.dateFormat)._d}
                    dateFormat={GlobalValues.dateFormat}
                   // minDate={moment(this.getMinSrtDate(index),GlobalValues.dateFormat)._d}
                    disabled={this.isGreterThen(billObj.fromDate,billObj.abid)}
                    maxDate={billObj.toDate==null?null:moment(billObj.toDate,GlobalValues.dateFormat)._d}
                    validationArray={[required]}
                    showYearDropdown={true}
                    showmonthdropdown={true}
                    name={"fromDate"}
                    reqmsg="Enter Effective Start Date"
                    onDateSelectChange={(e) => {
                      this.props.onAccountBasicInfoFieldsChange(index,"fromDate", e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                     }}
                  />
                </div>
              </div>
              <div className="col-md-3" >
                  <label htmlFor="billableAmount" className="form-label col-form-label">END DATE *</label>
                  <div className="">
                  <TmDateTimePicker className="form-control"
                    selectedDate={(billObj.toDate||'')==''?null:moment(billObj.toDate,GlobalValues.dateFormat)._d}
                    dateFormat={GlobalValues.dateFormat}
                    disabled={this.isGreterThen(billObj.toDate,billObj.abid)}
                    maxDate={this.getMaxDate(index)==null?null:moment(this.getMaxDate(index),GlobalValues.dateFormat)._d}
                    minDate={(billObj.fromDate||null)==null?null:moment(this.getMinEndDate(billObj.fromDate),GlobalValues.dateFormat)._d}
                    validationArray={[required]}
                    showYearDropdown={true}
                    showmonthdropdown={true}
                    name={"toDate"}
                    reqmsg="Enter Effective End Date"
                    onDateSelectChange={(e) => {
                      this.props.onAccountBasicInfoFieldsChange(index,"toDate", e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                   }}
                  />
                </div>
              </div>
              <div className="col-md-3">
              <label htmlFor="billableAmount" className="form-label col-form-label"></label>
              <div className="">
              {accountBasicInfo.accountId==0?null:<button className="btn btn-sm btn-success" onClick={(e) => { this.props.addUpdatBillableIndex(e,index)
                    }}>Save</button>}
                    </div>

              </div>
          </div>
         
          </Form>
      <div className="row">
        <div className="col-md-12 col-sm-12 mb-10">
          <form className=''>
            <div className="accordion" id="report-accordion">
              {<div className="card custom-report-card">
                <div className="card-header" id="monetizationDiv">
                  <h5 className="mb-0">Global Charge</h5> </div>
                <div
                  id="monetizationDiv"
                  className='show'
                  aria-labelledby="monetizationDiv"
                  data-parent="Global Charge"
                >
                  <div className="card-body">
                    <div className="form-group row col-md-12">
                      {
                        (globleData||[]).map((obj, ind) => {
                          return (
                            <div className="col-sm-3 form-group" key={ind}>
                              <div className="custom-control custom-checkbox custom-control">
                                <input className="custom-control-input"
                                  id={obj.chargeName}
                                  type="checkbox"
                                  disabled={accountBasicInfo.billableType=='PerTow'}
                                  name={obj.idChargeList}
                                  value={obj.isBillable}
                                  checked={obj.isBillable}
                                  onClick={(e) => {
                                    this.props.setBillableStatus(e, parseInt(e.target.name))
                                  }}
                                />
                                <label className="custom-control-label" htmlFor={obj.chargeName}>{obj.chargeName.toUpperCase()}</label>
                                </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              }

            </div>
          </form>
        </div>
        <div className="col-md-12 col-sm-12 mb-10">
          <form className=''>
            <div className="accordion" id="report-accordion">
              {<div className="card custom-report-card">
                <div className="card-header" id="monetizationDiv">
                  <h6 className="mb-0">CUSTOM CHARGE</h6> </div>
                <div
                  id="monetizationDiv"
                  className='show'
                  aria-labelledby="monetizationDiv"
                  data-parent="Globle Charge"
                >
                  <div className="card-body">
                    <div className="form-group row col-md-12">
                      {
                        (localData||[]).map((obj, ind) => {
                          return (
                            <div className="col-sm-3 form-group" key={ind}>
                              <div className="custom-control custom-checkbox custom-control">
                                <input className="custom-control-input"
                                  id={obj.chargeName}
                                  type="checkbox"
                                  disabled={accountBasicInfo.billableType=='PerTow'}
                                  name={obj.idChargeList}
                                  value={obj.isBillable}
                                  checked={obj.isBillable}
                                  onClick={(e) => {
                                    this.props.setBillableStatus(e, parseInt(e.target.name))
                                  }}
                                />
                                <label className="custom-control-label" htmlFor={obj.chargeName}>{obj.chargeName.toUpperCase()}</label>
                                </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              }

            </div>
          </form>
        </div>
      </div>
      <div className="row">
                <div className="col-md-12 text-right">
                <button className="btn btn-sm btn-success" onClick={(e) => {
                        this.props.onAccountNextClick(e);
                    }}>Next</button>
                {
                  ((this.props.accountBasicInfo.accountId || 0) !== 0) ?
                      <button className="btn btn-sm btn-success ml-3" onClick={(e) => {
                        e.preventDefault();
                        this.props.onTimePlanSubmit(e);
                      }
                      }>SAVE ACCOUNT</button> : null
                }
                </div>
                </div></div>
    </>)
  }
}
export default CustomChargeList