import {GET_COMPANY_LIST,GET_COMPANY_RECORD,GET_COMPANY_PAY_DETAILS} from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import { getCompanyId } from '../../util/customMethods';
import {store} from '../../store/configureStore';
export const getCompanyList=(isManagement=false)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.MANAGE).get(`company/${getCompanyId()}?isManagement=${isManagement}&access_token=${getAccessToken()}`);
            if(resp.status===200){
                dispatch({type:GET_COMPANY_LIST,payload:resp.data.response})
            }else{
                dispatch({type:GET_COMPANY_LIST,payload:undefined})
            }
        }catch(ex){
            dispatch({type:GET_COMPANY_LIST,payload:undefined})
        }
    }
}
export const isValidSubdomain=async(subDomain)=>{
        try{
             const resp = await getAxiosInstance(moduleTypes.MANAGE).get(`company/isValidSubdomain?subdomain=${subDomain}&access_token=${getAccessToken()}`)
             if(resp.status===200){
                return resp.data;
            }else{
                return false;
            }
        }catch(ex){
            return false;
        }
}
// export const isEmailExits=async(emailId)=>{
//     try{
//          const resp = await getAxiosInstance(moduleTypes.MANAGE).get(`company/isEmailValid?emailId=${emailId}&access_token=${getAccessToken()}`)
//          if(resp.status===200){
//             return resp.data;
//         }else{
//             return false;
//         }
//     }catch(ex){
//         return false;
//     }
// }
export const validateUser=async(cId,id,type)=>{
    try{
         const resp = await getAxiosInstance(moduleTypes.MANAGE).get(`company/${cId}/isUserValid?userId=${id}&type=${type}&access_token=${getAccessToken()}`)
         if(resp.status===200){
            return resp.data;
        }else{
            return false;
        }
    }catch(ex){
        return false;
    }
}

const getCompanyById=async(id)=>{
    try{
        const resp=await getAxiosInstance(moduleTypes.MANAGE).get(`company/${id}/?isManagement=false&access_token=${getAccessToken()}`);
        if(resp.status===200){
            
           return formatCompanyObj(resp.data.response)
        }else{
            return undefined
        }
    }catch(ex){
        return undefined
    }
}

export const setPaymentInfo = (event) => {
    return async (dispatch) => {
        let _paymentInfo = (store.getState()).companies.paymentObj || {};
        _paymentInfo[event.target.name] = event.target.value;
        dispatch({ type: GET_COMPANY_PAY_DETAILS, payload: _paymentInfo })
    }
}

const isDuplicate = (_paymentInfo, event) => {
    let arrayObj =(_paymentInfo.payLocationPinJson||[]).filter(v => v.location == event);
    if (arrayObj.length == 0 && event != '')
        return true;
    else
        return false;

}
export const setMapOfKeyInNoSwipePin = (locations) => {
    return async (dispatch) => {
        let _paymentInfo = (store.getState()).companies.paymentObj || {};
        let payLocationPinJson = [];
        locations.map((val)=>{
            if(val !=""){
                let obj = _paymentInfo.payLocationPinJson.find(function (element) {return element.location == val;});
                let objDef={};
                if(obj==undefined){
                    objDef['location']=val;
                    objDef['keyInNo']='';
                    objDef['swipePin']='';
                    payLocationPinJson.push(objDef);
                }
                else
                payLocationPinJson.push(obj);
                // _paymentInfo.keyInNoSwipeMap=keyInNoSwipeMap;
                // dispatch({ type: GET_COMPANY_PAY_DETAILS, payload: _paymentInfo }) 
            }
        })
        if(locations.length==0)
        payLocationPinJson=[];
        _paymentInfo.payLocationPinJson=payLocationPinJson;
        dispatch({ type: GET_COMPANY_PAY_DETAILS, payload: _paymentInfo });
}
}

export const setMapOfKeyInNoSwipePinValue = (event,loc) => {
    return async (dispatch) => {
        let _paymentInfo = (store.getState()).companies.paymentObj || {};
        let obj = _paymentInfo.payLocationPinJson.find(function (element) { return element.location == loc; });
        let index = _paymentInfo.payLocationPinJson.indexOf(obj);
        _paymentInfo.payLocationPinJson[index][event.target.name]=event.target.value;
        dispatch({ type: GET_COMPANY_PAY_DETAILS, payload: _paymentInfo });
    }
}

export const setPaymentInfoToEmpty = () => {
    return async (dispatch) => {
        dispatch({ type: GET_COMPANY_PAY_DETAILS, payload: undefined })
    }
}

export const savePaymentDetails=()=>{
    let _paymentInfo=(store.getState()).companies.paymentObj||{};
    return async (dispatch) => {
    try{
        const resp= await getAxiosInstance(moduleTypes.MANAGE).post(`company/addUpdatePaymentInfo?companyId=${getCompanyId()}&access_token=${getAccessToken()}`,JSON.stringify(_paymentInfo),{
            headers: {
                'Content-Type': 'application/json',
            }});
        if(resp.status===200){
           return resp.data;
        }else{
            return resp.data;
        }
    }catch(ex){
        return ex;
    }
}
}

export const fetchPaymentDetails=()=>{
    return async (dispatch) => {
    try{
        const resp=await getAxiosInstance(moduleTypes.MANAGE).get(`company/fetchPaymentInfo?companyId=${getCompanyId()}&access_token=${getAccessToken()}`);
        if(resp.status===200){
            dispatch({type:GET_COMPANY_PAY_DETAILS,payload:resp.data.response})
           return resp.data;
        }else{
            return resp.data;
        }
    }catch(ex){
        return undefined
    }
}
}

const formatCompanyObj=(compObj)=>{
    let _company={...compObj};
    _company.companyContactInfos=[]
    _company.companyContactInfos= compObj.companyContactInfos.map((item)=>{
        return {
            "idContact": item.idCompanycontact,
            "idBilling": 0,
            "contactName": item.contactName,
            "contactPhone": item.contactPhone,
            "contactEmail": item.contactEmail,
            "contactExt": item.contactExt
          }
    });
    const _billingContacts=_company.companyBilling.companyBillingContacts.slice()
    _company.companyBilling.companyBillingContacts=[]
    _company.companyBilling.companyBillingContacts= _billingContacts.map((item)=>{
        return {
            "idContact": item.idCompBilling_Contact,
            "idBilling": item.idCompanyBilling,
            "contactName": item.contactName,
            "contactPhone": item.contactPhone,
            "contactEmail": item.contactEmail,
            "contactExt": item.billingcontFax
          }
    });
    return _company
}
export const getCompany=(id)=>{
    return async (dispatch) => {
        const res=await getCompanyById(id);
        dispatch({type:GET_COMPANY_RECORD,payload:res})
    }
}
export const clearCompany=()=>{
    return async (dispatch) => {
        dispatch({type:GET_COMPANY_RECORD,payload:undefined});
    }
}

export const companyInfoChange=(key,value)=>{
    return (dispatch)=>{
        try{
            let _companyRec=(store.getState()).companies.companyRec||{};
            _companyRec[key]=value;
            dispatch({type:GET_COMPANY_RECORD,payload:_companyRec})
        }catch(ex){

        }
    }
}
export const saveCompany=async(company)=>{
    try{
        let _reqObj={...company};
        _reqObj.companyAccountMappings.map((item)=>{
            delete item.accountName
        });
        _reqObj.isAccountAssignChange=true;
        if(company.idCompany>0){
            let _reqObj={...company};
            delete _reqObj.companyContactInfos
            delete _reqObj.companyNotes
            delete _reqObj.companyHistories
            delete _reqObj.companyBilling.companyBillingContacts;
            delete _reqObj.companyBilling.companyBillingNotes;            
            const resp=await getAxiosInstance(moduleTypes.MANAGE).put(`company/${company.idCompany}?access_token=${getAccessToken()}`,_reqObj);
            return resp.data
        }else{ 
            let _reqObj={...company}
            const billingNotes=(company.companyBilling.companyBillingNotes||[]).slice();
            _reqObj.companyBilling.companyBillingNotes=[] 
            _reqObj.companyBilling.companyBillingNotes= billingNotes.map((item)=>{
                return{
                    "createdDate": item.createdDate,
                    "createdTime": item.createdTime,
                    "notesTxt": item.notesTxt,
                    "userId": item.userId
                }
            })
            const notes=(company.companyNotes||[]).slice();
            _reqObj.companyNotes=[]  
            _reqObj.companyNotes= notes.map((item)=>{
                return{
                    "createdDate": item.createdDate,
                    "createdTime": item.createdTime,
                    "notesTxt": item.notesTxt,
                    "userId": item.userId
                }
            });

            const _billingContacts=_reqObj.companyBilling.companyBillingContacts.slice()
            _reqObj.companyBilling.companyBillingContacts=[]
            _reqObj.companyBilling.companyBillingContacts= _billingContacts.map((item)=>{
                return {
                    "contactName": item.contactName,
                    "contactPhone": item.contactPhone,
                    "contactEmail": item.contactEmail,
                    "contactExt": item.contactExt,
                    "billingcontFax": item.contactExt,
                    "defaultEmailid": 0
                }
            });
            const contacts=(company.companyContactInfos||[]).slice();
            _reqObj.companyContactInfos=[]  
            _reqObj.idParentCompany=getCompanyId();
            _reqObj.companyContactInfos= contacts.map((item)=>{
                return{
                    "contactName": item.contactName,
                    "contactPhone": item.contactPhone,
                    "contactEmail": item.contactEmail,
                    "contactExt": item.contactExt,
                  }
            })

            const resp=await getAxiosInstance(moduleTypes.MANAGE).post(`company?access_token=${getAccessToken()}`,_reqObj);
            return resp.data
        }
    }catch(ex){
        return ex
    }
}
export const setCompanyContactToList=(contactObj,index,type)=>{
    return async(dispatch)=>{
        let successMessage="Contact Added/Updated successfully";
        if(contactObj.companyId===0){
            let _companyBasicInfo=(store.getState()).companies.companyRec||{};
            if(type==='COMPANY'){
                if(index!==-1){
                    _companyBasicInfo.companyContactInfos[index]=contactObj;
                }else{
                    _companyBasicInfo.companyContactInfos.push(contactObj);
                }
            }
            if(type==='BILLING'){
                if(index!==-1){
                    _companyBasicInfo.companyBilling.companyBillingContacts[index]=contactObj;
                }else{
                    _companyBasicInfo.companyBilling.companyBillingContacts.push(contactObj);
                }
            }
            dispatch({
                type: GET_COMPANY_RECORD,
                payload: {..._companyBasicInfo}
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            try{
                let id=contactObj.idContact;
                let cId=contactObj.companyId;
                let _url='';
                let _reqObj={};                    
                if(type==='COMPANY'){  
                    _reqObj={
                        "contactName": contactObj.contactName,
                        "contactPhone": contactObj.contactPhone,
                        "contactEmail": contactObj.contactEmail,
                        "contactExt": contactObj.contactExt,
                      }                  
                    _url=(id<=0?`company/${cId}/childEntity/?type=Contact&access_token=${getAccessToken()}`:
                    `company/${cId}/childEntity/${id}?type=Contact&access_token=${getAccessToken()}`)
                }
                if(type==='BILLING'){
                    _reqObj={
                        "contactName": contactObj.contactName,
                        "contactPhone": contactObj.contactPhone,
                        "contactEmail": contactObj.contactEmail,
                        "contactExt": contactObj.contactExt,
                        "billingcontFax": contactObj.contactExt,
                        "defaultEmailid": 0
                      }
                    _url=(id<=0?`company/${cId}/childEntity/?type=BillingContact&access_token=${getAccessToken()}`:
                    `company/${cId}/childEntity/${id}?type=BillingContact&access_token=${getAccessToken()}`)
                }
                const resp= (id<=0? await getAxiosInstance(moduleTypes.MANAGE).post(_url,_reqObj):
                    await getAxiosInstance(moduleTypes.MANAGE).put(_url,_reqObj));
                if(resp.data.statusCode===200){                    
                    dispatch(getCompanyContactList(cId,type))                    
                    return resp.data;
                }else{
                    return resp.data
                }
            }catch(ex){
                return {statusCode:null,statusMsg:ex.message}                    
            }
        }        
    }
}
export const setCompanyNotesToList=(notesObj,index,type)=>{
    return async(dispatch)=>{
        let successMessage="Note Added/Updated successfully";
        if(notesObj.idCompany===0){
            let _companyBasicInfo=(store.getState()).companies.companyRec||{};
            if(type==='COMPANY'){
                if(index!==-1){
                    _companyBasicInfo.companyNotes[index]=notesObj;
                }else{
                    _companyBasicInfo.companyNotes.push(notesObj);
                }
            }
            if(type==='BILLING'){
                if(index!==-1){
                    _companyBasicInfo.companyBilling.companyBillingNotes[index]=notesObj;
                }else{
                    _companyBasicInfo.companyBilling.companyBillingNotes.push(notesObj);
                }
            }            
            dispatch({
                type: GET_COMPANY_RECORD,
                payload: {..._companyBasicInfo}
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            try{
                let id=notesObj.idCompanyNote;
                let cId=notesObj.idCompany
                let _url=''                       
                if(type==='COMPANY'){                    
                    _url=(id<=0?`company/${cId}/childEntity/?type=Notes&access_token=${getAccessToken()}`:
                    `company/${cId}/childEntity/${id}?type=Notes&access_token=${getAccessToken()}`)
                }
                if(type==='BILLING'){
                    id=notesObj.idCompanyBillNote||0;
                    delete notesObj.idCompany;
                    _url=(id<=0?`company/${cId}/childEntity/?type=BillingNotes&access_token=${getAccessToken()}`:
                    `company/${cId}/childEntity/${id}?type=BillingNotes&access_token=${getAccessToken()}`)
                }
                const resp= (id<=0? await getAxiosInstance(moduleTypes.MANAGE).post(_url,
                    { 
                    "notesTxt": notesObj.notesTxt,
                    "userId": notesObj.userId,
                    "createdDate":notesObj.createdDate,
                    "createdTime":notesObj.createdTime })
                    :
                    await getAxiosInstance(moduleTypes.MANAGE).put(_url,notesObj));
                if(resp.data.statusCode===200){                    
                    dispatch(getCompanyNotesList(cId,type))                    
                    return resp.data;
                }else{
                    return resp.data
                }
            }catch(ex){
                return {statusCode:null,statusMsg:ex.message}                    
            }
        }
    }
}

export const deleteNoteToList=(cId,index,type)=>{
    return async(dispatch)=>{
        let successMessage="Note deleted successfully";
        let _companyBasicInfo=(store.getState()).companies.companyRec||{};;
        if(cId===0){
            if(type==='COMPANY'){
                _companyBasicInfo.companyNotes.splice(index,1);
            }
            if(type==='BILLING'){
                _companyBasicInfo.companyBilling.companyBillingNotes.splice(index,1);
            }                        
            dispatch({
                type: GET_COMPANY_RECORD,
                payload: {..._companyBasicInfo}
            });
            return {statusCode:200,statusMsg:successMessage}    
        }else{
            try{
                let id=0;
                let _url=''                       
                if(type==='COMPANY'){
                    id=_companyBasicInfo.companyNotes[index].idCompanyNote;
                    _url=`company/${cId}/childEntity/${id}?type=Notes&access_token=${getAccessToken()}`
                }
                if(type==='BILLING'){
                    id=_companyBasicInfo.companyBilling.companyBillingNotes[index].idCompanyBillNote;
                    _url=`company/${cId}/childEntity/${id}?type=BillingNotes&access_token=${getAccessToken()}`
                }
                const resp=await getAxiosInstance(moduleTypes.MANAGE).delete(_url);
                if(resp.data.statusCode===200){                    
                    dispatch(getCompanyNotesList(cId,type))                    
                    return resp.data;
                }else{
                    return resp.data
                }
                }catch(ex){
                    return {statusCode:null,statusMsg:ex.message}                   
                }
        }
    }
}

export const deleteContactToList=(cId,index,type)=>{
    return async(dispatch)=>{
        let successMessage="Contact deleted successfully";
        let _companyBasicInfo=(store.getState()).companies.companyRec||{};;
        if(cId===0){
            if(type==='COMPANY'){
                _companyBasicInfo.companyContactInfos.splice(index,1);
            }
            if(type==='BILLING'){
                _companyBasicInfo.companyBilling.companyBillingContacts.splice(index,1);
            }                        
            dispatch({
                type: GET_COMPANY_RECORD,
                payload: {..._companyBasicInfo}
            });
            return {statusCode:200,statusMsg:successMessage}    
        }else{
            try{
                let id=0;
                let _url=''                       
                if(type==='COMPANY'){
                    id=_companyBasicInfo.companyContactInfos[index].idContact;
                    _url=`company/${cId}/childEntity/${id}?type=Contact&access_token=${getAccessToken()}`
                }
                if(type==='BILLING'){
                    id=_companyBasicInfo.companyBilling.companyBillingContacts[index].idContact;
                    _url=`company/${cId}/childEntity/${id}?type=BillingContact&access_token=${getAccessToken()}`
                }
                const resp=await getAxiosInstance(moduleTypes.MANAGE).delete(_url);
                if(resp.data.statusCode===200){                    
                    dispatch(getCompanyContactList(cId,type))                    
                    return resp.data;
                }else{
                    return resp.data
                }
                }catch(ex){
                    return {statusCode:null,statusMsg:ex.message}                   
                }
        }
    }
}

const getCompanyNotesList=(cid,type)=>{
    return async(dispatch)=>{
        const res=await getCompanyById(cid);
        const _companyBasicInfo=(store.getState()).companies.companyRec||{};
        if(type==='COMPANY'){
            _companyBasicInfo.companyNotes=res.companyNotes;
        }
        if(type==='BILLING'){
        _companyBasicInfo.companyBilling.companyBillingNotes=res.companyBilling.companyBillingNotes;
        }
        dispatch({type:GET_COMPANY_RECORD,payload:{..._companyBasicInfo}})
    }
}
const getCompanyContactList=(cid,type)=>{
    return async(dispatch)=>{
        const res=await getCompanyById(cid);
        const _companyBasicInfo=(store.getState()).companies.companyRec||{};
        if(type==='COMPANY'){
            _companyBasicInfo.companyContactInfos=res.companyContactInfos;
        }
        if(type==='BILLING'){
        _companyBasicInfo.companyBilling.companyBillingContacts=res.companyBilling.companyBillingContacts;
        }
        dispatch({type:GET_COMPANY_RECORD,payload:{..._companyBasicInfo}})
    }
}