import React, { Component } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from "moment";
import {Doughnut} from 'react-chartjs-2';
let _ = require('underscore');
let graphColors;
let graphOutlines;
let hoverColor;
let filterData=[];
class StorageDrillDownCountChart extends Component{
    constructor(props){
        super(props);
        this.state={
            chartObj2:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: []
                }]
            }, 
            optText: ''
        } 
        this.backToFirstLevel = this.backToFirstLevel.bind(this);     
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        this.storageCount(values.text,values.index); 
      }
    backToFirstLevel(event){      
        event.preventDefault();
        this.props.history.push('/reportschart/storagecountchart'); 
    }
    
    storageCount(text,index){
        filterData=_.groupBy(this.props.reportDashboard.storageCount.storageResponseDetails,'lotLocation');
        this.setState({optText: text});
        let _chartObj2=this.state.chartObj2;            
        _chartObj2.labels=[];
        _chartObj2.datasets[0].data=[];
        let items=filterData[Object.keys(filterData)[index]];
        console.log("val:::",items)
        //Linked Chart
        let intervalDays = 100;
        let highest =  Math.max.apply(Math, items.map(function(o) { return o.daysInStorage; }));
        //let noofparts = Math.round(highest/intervalDays);
        let noofparts = 3;
        let arrStorageDays = [];
        for(let i=0 ; i < noofparts; i++){
            let lowerBound = i * intervalDays;
            let upperBound = (i+1) * intervalDays;
            let upperBoundLabel = `${'0-100'}`;
            if(i===1){
                lowerBound = i * intervalDays;
                upperBound = (i+2) * intervalDays;
                upperBoundLabel = `${'101-300'}`;
            }
            if(i===2){
                lowerBound = (i+1) * intervalDays;
                upperBound = (Math.round(highest/intervalDays) + 1) * intervalDays;
                upperBoundLabel = `${'300+'}`;
                console.log(highest)
                console.log(upperBound)
            }
            let objStorageDays = [];
            items.filter((values) => {
                if(i===0){
                    if( values.daysInStorage >= lowerBound && values.daysInStorage <= upperBound){
                        objStorageDays.push(values.daysInStorage)
                    }
                }else{
                    if( values.daysInStorage > lowerBound && values.daysInStorage <= upperBound){
                        objStorageDays.push(values.daysInStorage)
                    }
                }
                
            });
            arrStorageDays.push({"label":upperBoundLabel,"value":objStorageDays})
        }
        console.log("arrStorageDays ::",arrStorageDays);

        graphColors = [];

        graphOutlines = [];
        hoverColor = [];
        let internalDataLength = noofparts;
        let col = 0;
        while (col <= internalDataLength) {
            let randomR = Math.floor((Math.random() * 130) + 100);
            let randomG = Math.floor((Math.random() * 130) + 100);
            let randomB = Math.floor((Math.random() * 130) + 100);
        
            let graphBackground = "rgb(" 
                    + randomR + ", " 
                    + randomG + ", " 
                    + randomB + ")";
            graphColors.push(graphBackground);
            
            let graphOutline = "rgb(" 
                    + (randomR - 80) + ", " 
                    + (randomG - 80) + ", " 
                    + (randomB - 80) + ")";
            graphOutlines.push(graphOutline);
            
            let hoverColors = "rgb(" 
                    + (randomR + 25) + ", " 
                    + (randomG + 25) + ", " 
                    + (randomB + 25) + ")";
            hoverColor.push(hoverColors);
            
        col++;
        }; 
        _chartObj2.datasets[0].backgroundColor=graphColors;
        _chartObj2.datasets[0].hoverBackgroundColor=hoverColor; 
        _chartObj2.datasets[0].borderColor=graphOutlines;    
        arrStorageDays.map((item, index) => {
            if(item.value.length > 0){
            _chartObj2.labels.push(item.label+' days');
            _chartObj2.datasets[0].data.push(item.value.length); 
            }
        }) 
   
    }
    render(){
        const options2={
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'right'
            },
            title: {
                display: true,
                text: 'Vehicles Count By Storage Days '+this.state.optText
            }    
        }
        return(
            <>
            <div className="page-header">
                  <div className="row mb-10">
                      <div className="col-md-12 col-sm-12">
                          <div className="title">
                          <h4>Duration: {moment().format('YYYY-MM-DD')}</h4>
                          </div>
                      </div>
                  </div>
              </div>
               <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
               <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <div>
                    <button class="btn btn-info" onClick={this.backToFirstLevel}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    
                    </div>
                    </div>
                    </div>
            <div class="row"> 
                        <div class="col-md-8 offset-2">
                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.14)' }}>
                            <Doughnut height="440" data={this.state.chartObj2} options={options2} />
                        </div>  
                        </div>                                     
               
            </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(StorageDrillDownCountChart);