import React, { Component, Fragment } from 'react';
import queryString  from 'query-string'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {Tabs,Tab,Modal} from 'react-bootstrap';
import {isValidForm} from '../../util/validationMethods'
import CompanyInfo from '../../components/adminComponents/company/companyInfo/companyInfo';
import CompanyHistory from '../../components/adminComponents/company/companyHistory/companyHistory';
import Billing from '../../components/adminComponents/company/billing/billing';
import AccountAssign from '../../components/adminComponents/company/accountAssign/accountAssign'
import {getComapanyTypes} from '../../actions/adminAction/dropDownDataAction'
import {getCompany,clearCompany,companyInfoChange,saveCompany,deleteNoteToList, 
setCompanyNotesToList, deleteContactToList,setCompanyContactToList,isValidSubdomain,validateUser} from '../../actions/adminAction/companyActions';
import {getAccountListForDropDown} from '../../actions/adminAction/accountAction';
import {notify} from '../../util/notifications';
import {getLoginUserName,getCompanyId,getAccessLevelsByModule} from '../../util/customMethods'
import moment from 'moment'


const pageTitle = 'Add New Company';
const companyTabs={
	TB_COMPANY_INFO: 'TB_COMPANY_INFO',
	TB_COMPANY_BILLING:'TB_COMPANY_BILLING',
	TB_ACCOUNT_ASSIGN:'TB_ACCOUNT_ASSIGN',
 	TB_COMPANY_HISTORY:'TB_COMPANY_HISTORY'
}
let companyForm={
	frmCompanyInfo:{},
	frmBilling:{},
	frmAccount:{},
	frmDefaultAddress:{}
}
class CompanyRecord extends Component {
	constructor() {
		super();
		this.state = {
			tabKey:companyTabs.TB_COMPANY_INFO,
			cid:0,
			isDomainValid:true,
			isEmailExits:true,
			isUserExits:true
		}
	}
	componentWillMount = () => {	
		const queryValues=	queryString.parse(this.props.location.search);
		this.getAccountList();
		if(parseInt(queryValues.cid) !== 0){
			this.setState({cid:parseInt(queryValues.cid)},this.initCompanyForm(queryValues.cid))
			}else{
					this.setState({cid:0},this.initCompanyForm(0))
			}		
	}
	initCompanyForm=(id)=>{
		const {dispatch}=this.props;
		dispatch(getComapanyTypes());
		if(id!==0){
			dispatch(clearCompany())
			this.getCompanyRecordById(id);
		}else{      
				dispatch(clearCompany())
			}
	}
	getAccountList=()=>{
		const {dispatch}=this.props;
		dispatch(getAccountListForDropDown())
	}
	getCompanyRecordById=(id)=>{
		const {dispatch}=this.props;
		dispatch(getCompany(parseInt(id)));
	}
	validateDomain=async(dname)=>{
		let status = await isValidSubdomain(dname);
		this.setState({isDomainValid:status});
	}
	emailExits=async(dname)=>{
		let status =  await validateUser(0,dname,'email');
		this.setState({isEmailExits:status});
	}
	validateUser=async(id)=>{
		let status = await validateUser(getCompanyId(),id,'userId');
		this.setState({isUserExits:status});
	}
	
	onCompanySave=async()=>{
		companyForm.frmCompanyInfo.validateAll();
		companyForm.frmBilling.validateAll();
		if(!isValidForm(companyForm.frmCompanyInfo)){
			this.setState({tabKey:companyTabs.TB_COMPANY_INFO})
		}
		else if(!isValidForm(companyForm.frmBilling)){
			this.setState({tabKey:companyTabs.TB_COMPANY_BILLING})
		}else{
			const resp=await saveCompany(this.props.companyRec);
			if(resp.statusCode===200){
				notify.createNotification(notify.notifyType.Success,resp.statusMsg,this.props.refNotify);
				this.props.history.push('/admin/company');
			}else{
				notify.createNotification(notify.notifyType.Error,resp.statusMsg,this.props.refNotify);
			}
		}
	}
	onCompanyNotesSubmit=async(e,notesObj,index,type)=>{
		const {dispatch}=this.props;		
		notesObj.createdDate= moment().format('YYYY-MM-DD')
		notesObj.createdTime=moment().format("HH:mm:ss")
		notesObj.idCompany= this.state.cid;
		notesObj.userId= getLoginUserName();
		const resp= await dispatch(setCompanyNotesToList(notesObj,index,type));
		if(resp.statusCode===200){
			notify.createNotification(notify.notifyType.Success,resp.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification(notify.notifyType.Error,resp.statusMsg,this.props.refNotify);
		}
	}
	onDeleteNote=async(index,type)=>{
		const resp= await this.props.dispatch(deleteNoteToList(this.state.cid,index,type));
		if(resp.statusCode===200){
			notify.createNotification(notify.notifyType.Success,resp.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification(notify.notifyType.Error,resp.statusMsg,this.props.refNotify);
		}
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	onCompanyContactSubmit=async (e,contactObj,index,type)=>{
		const {dispatch}=this.props;
		contactObj.companyId= this.state.cid;
		const resp= await dispatch(setCompanyContactToList(contactObj,index,type));
		if(resp.statusCode===200){
			notify.createNotification(notify.notifyType.Success,resp.statusMsg,this.props.refNotify);
			return true;
		}else{
			notify.createNotification(notify.notifyType.Error,resp.statusMsg,this.props.refNotify);
			return true;
		}
	}
	onDeleteContact=async(index,type)=>{
		const resp= await this.props.dispatch(deleteContactToList(this.state.cid,index,type));
		if(resp.statusCode===200){
			notify.createNotification(notify.notifyType.Success,resp.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification(notify.notifyType.Error,resp.statusMsg,this.props.refNotify);
		}
	}

	render() {	
		document.title = (this.state.cid) <= 0 ? pageTitle : `Edit ${this.props.companyRec.name}`;
		const accessLevelObj = getAccessLevelsByModule('Company');
		this.checkViewAccess(accessLevelObj)
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-building-o"></i> {this.state.cid<=0 ? pageTitle : `Edit ${this.props.companyRec.name}`}  </h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<Tabs id="tab-company" className="tab" activeKey={this.state.tabKey} onSelect={key => this.setState({ tabKey:key })}>
					<Tab eventKey={companyTabs.TB_COMPANY_INFO} 
					title={<span><i className="fa fa-info-circle" style={{display:'block',textAlign:'center'}}></i>Company Information</span>}>
						<CompanyInfo
						    canEdit={accessLevelObj.basicAccess.edit || false}
							accounts={this.props.accountsList||[]} 
							company={this.props.companyRec||{}}
							states={this.props.states||[]}
							companyType={this.props.companyType||[]}
							initCallBack={(frm)=>{
								companyForm.frmCompanyInfo=frm;
							}}
							onCompanyBasicInfoFieldsChange={(keyName,value)=>{
								this.props.dispatch(companyInfoChange(keyName,value));
							}}
							onSubmitBasicInfo={()=>{
								this.setState({tabKey:companyTabs.TB_ACCOUNT_ASSIGN})
							}}
							validateDomain={(dname)=>{this.validateDomain(dname)}}
							emailExits={(email)=>{this.emailExits(email)}}
							isEmailExits={this.state.isEmailExits}
							isDomainValid={this.state.isDomainValid}
							validateUser={(id)=>{this.validateUser(id)}}
							isUserExits={this.state.isUserExits}
							onSaveCompany={this.onCompanySave}
							onCompanyNotesSubmit={(e,notesObj,index)=>{	
								this.onCompanyNotesSubmit(e,notesObj,index,'COMPANY')
							}}
							onCompanyNotesDelete={(index)=>{
								this.onDeleteNote(index,'COMPANY')
							}}
							onCompanyContactSubmit={(e,contactObj,index)=>{
								return this.onCompanyContactSubmit(e,contactObj,index,'COMPANY')
							}}
							onCompanyContactDelete={(index)=>{
								this.onDeleteContact(index,'COMPANY')
							}}
							refNotify={this.props.refNotify}
							cid={this.state.cid}
						 />
					</Tab>
					<Tab eventKey={companyTabs.TB_ACCOUNT_ASSIGN} title={<span><i className="fa fa-money" 
					style={{display:'block',textAlign:'center'}}></i>Assign Accounts</span>}>
						<AccountAssign 
						canEdit={accessLevelObj.basicAccess.edit || false}
						accountList={[...(this.props.accountsList||[])]} 
						selectedAccounts={this.props.companyRec.companyAccountMappings||[]}
						onCompanyBasicInfoFieldsChange={async (keyName,value)=>{
								await this.props.dispatch(companyInfoChange(keyName,value));
								await this.props.dispatch(companyInfoChange('isAccountAssignChange',true))
								this.forceUpdate();
							}}
							onSubmitAccountAssign={()=>{
								const count=(this.props.companyRec.companyAccountMappings||[]).length;
								if(count>0){
									this.setState({tabKey:companyTabs.TB_COMPANY_BILLING})
								}else{
									notify.createNotification(notify.notifyType.Error,'Select at least one account.',this.props.refNotify);

								}
								
							}}
							onSaveCompany={this.onCompanySave}
							cid={this.state.cid} />

					</Tab>
					<Tab eventKey={companyTabs.TB_COMPANY_BILLING} title={<span><i className="fa fa-money" style={{display:'block',textAlign:'center'}}></i>Billing</span>}>
						<Billing company={this.props.companyRec||{}}
						canEdit={accessLevelObj.basicAccess.edit || false}
						states={this.props.states||[]} 
							initCallBack={(frm)=>{
								companyForm.frmBilling=frm;
							}} 
							onCompanyBillingInfoFieldsChange={(keyName,value)=>{
								this.props.dispatch(companyInfoChange(keyName,value));
							}}
							onSaveCompany={this.onCompanySave}
							onCompanyNotesSubmit={(e,notesObj,index)=>{
								this.onCompanyNotesSubmit(e,notesObj,index,'BILLING')
							}}
							onCompanyNotesDelete={(index)=>{
								this.onDeleteNote(index,'BILLING')
							}}
							onCompanyContactSubmit={(e,contactObj,index)=>{
								return this.onCompanyContactSubmit(e,contactObj,index,'BILLING')
							}}
							onCompanyContactDelete={(index)=>{
								this.onDeleteContact(index,'BILLING')
							}} />
					</Tab>
					{
						this.props.companyRec.idCompany>0?
						<Tab eventKey={companyTabs.TB_COMPANY_HISTORY} title={<span><i className="fa fa-history" style={{display:'block',textAlign:'center'}}></i>Company History</span>}>
						<CompanyHistory canEdit={accessLevelObj.basicAccess.edit || false} company={this.props.companyRec||{}} />
					</Tab>:null
					}
					
				</Tabs>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		//authInfo: state.authInfo,
		refNotify: state.refNotify,		
		companyRec: state.companies.companyRec,
		accountsList:state.accountList.accountsDropDownList,
		states:state.states.stateList,
		companyType:state.companyTypes.companyType
	}
};

export default withRouter(connect(mapStateToProps)(CompanyRecord));