import * as actionTypes from '../../../actions/actionTypes';
const defaultARDetailByAccType={
  arDetailByAccType:{}
}
export const arDetailByAccountTypeReducer = (state = defaultARDetailByAccType, action) => {
    switch (action.type) {
      case actionTypes.GET_ARDETAILBYACCOUNTTYPE_LIST:
        return {...state ,arDetailsAccType:(action.arDetailByAccType||defaultARDetailByAccType.arDetailByAccType)};
      default:
        return state;
    }
  };