import {
  BASE_URL,
  BASE_AUTH_URL,
  DISPATCH_BASE_URL,
  moduleTypes,
  ADMIN_URL,
  LAUNCH_LOGIN_URL,
  REPORT_BASE_URL,
  REPORTS_URL} from './constants';
import {getAccessToken,setAccessToken} from '../actions/authenticateActions';
import {getRefreshToken,clearSessionData,setDataToLaunchStore} from './customMethods'
const axios = require('axios');
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
const config = {
  headers: { 'Authorization': 'Basic ' + btoa('my-trusted-client:secret')},
  responseType: 'json'
};
export const getAxiosInstance=(type)=>{
  const instance = axios.create({
      baseURL: getBaseUrl(type)
    });
    instance.interceptors.response.use( (response)=> {
      // Do something with response data
      return response;
    },(error)=>{
      if(error.response.status===401){
        return resetTokenAndReattemptRequest(error);
      }else{
        return error.response;
      }
          //window.location.href ='/';
      }
    );
 // instance.defaults.timeout = 3000;
  return instance;
}

const resetTokenAndReattemptRequest=async (error)=>{
  try {
    const { response: errorResponse } = error;
    const resetToken = getRefreshToken(); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!resetToken) {
      clearSessionData();
      window.location.href =LAUNCH_LOGIN_URL;
    }
   
    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(() => {
        let accessTokenPart=errorResponse.config.url.split('&').pop()
        errorResponse.config.url= errorResponse.config.url.replace(accessTokenPart,'access_token='+ getAccessToken()) ;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios.post(`${getBaseUrl(moduleTypes.SERVICE)}oauth/token?grant_type=refresh_token&refresh_token=${resetToken}`,'',config);
      if (!response.data) {       
        window.location.href =LAUNCH_LOGIN_URL;
      }else{
        const newToken = response.data.access_token;
        setAccessToken(response.data)
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(newToken);
      } 
    }
    return retryOriginalRequest;
  } catch (err) {
    if(err.response.status===401||err.response.status==400){     
      window.location.href =LAUNCH_LOGIN_URL;
    }else{
      console.log(err);
    } 
  }
}

const onAccessTokenFetched=(access_token)=> {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

const addSubscriber=(callback)=> {
  subscribers.push(callback);
}

const getBaseUrl=(type)=>{
  switch(type){
    case moduleTypes.DISPATCH :
      return DISPATCH_BASE_URL;
    case moduleTypes.SERVICE:
      return BASE_AUTH_URL;
      case moduleTypes.MANAGE:
      return BASE_URL;
    case moduleTypes.ADMIN:
      return ADMIN_URL;
    case moduleTypes.REPORTS:
      return REPORT_BASE_URL;
    case moduleTypes.REPORT:
      return REPORTS_URL;
    default:
      return BASE_URL
  }
}

export const authenticate=async(userName,password,subDomain)=>{
  const reqBody={
    "userName":userName,
    "password":password,
    "subdomain":subDomain,
    "clientId":"my-trusted-client",
    "secretId":"secret"
  }
  try{
    const resp=await axios.post(`${getBaseUrl(moduleTypes.SERVICE)}userAuthService/loginUser`,reqBody,config);
    if(resp.status===200){
      if(resp.data.statusCode===200){
        const data=resp.data
        data.isAuthenticated=true;
         return data
      }else{
       const data=resp.data
       data.isAuthenticated=false;
       return data
      }
    }else{
       return {isAuthenticated:false}

    }
  }
  catch(ex){
    //setDataToLaunchStore('authInfo',{});
    return {isAuthenticated:false};
  }  
}

export const getAccessLevels=async (empId,companyId,access_token)=>{  
  let _url='employee/select/employeeAccessLevel?empId='+empId+'&companyId='+companyId+'&access_token=';
  try{
    const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url,undefined,access_token)    
    if(response.status===200){
        setDataToLaunchStore('accessLevel',response.data.employeeAccess||{})
        setDataToLaunchStore('dashboardAccess',response.data.dashboardAccess||{});
      }else{
         setDataToLaunchStore('accessLevel',{})
         setDataToLaunchStore('dashboardAccess',{})
      } 
    }catch(error) {
      setDataToLaunchStore('accessLevel',{})
      setDataToLaunchStore('dashboardAccess',{})
    }
}