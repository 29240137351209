import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import VendorList from '../../components/adminComponents/vendors/vendorsList';
import { getVendorList, deleteVendor,clearVendor } from '../../actions/adminAction/vendorActions';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods.js'

const pageTitle='Vendor';
class VendorsPage extends Component {
	constructor(props){
		super(props);
		this.state={
			status_val:1
		}
		this.checkViewAccess=this.checkViewAccess.bind(this)
		this.bindVendorList = this.bindVendorList.bind(this);
		this.addVendorOnClick = this.addVendorOnClick.bind(this);
		this.editVendorOnClick = this.editVendorOnClick.bind(this);
		this.activeInactiveVendorOnClick = this.activeInactiveVendorOnClick.bind(this);
	}
	componentWillMount=()=>{
		document.title = pageTitle;
		this.bindVendorList(this.state.status_val);		
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindVendorList=(val)=>{
		this.setState({status_val:val})
		this.props.dispatch(getVendorList())
		this.props.dispatch(clearVendor());		
	}
	addVendorOnClick=(e)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('vendorsDetails?vid=-1');		
	}
	editVendorOnClick=(e,vid)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('vendorsDetails?vid='+vid);		
	}
	activeInactiveVendorOnClick=(e,vid,status)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		dispatch(deleteVendor(vid,status,(deleteSuccess)=>{
			notify.createNotification('success',deleteSuccess,this.props.refNotify);
			this.bindVendorList(this.state.status_val);
			},(deleteFail)=>{
				notify.createNotification('error',deleteFail,this.props.refNotify);
		}));		
	}
	vendorList=()=>{
		let val = parseInt(this.state.status_val);
		if(val === -1){
			return this.props.vendorList;
		}
		else if(val === 1){
			let arr = [];
			this.props.vendorList.filter((status)=>{
				if(status.isActive){
					arr.push(status)
				}
			})
			return arr;
		}
		else if(val === 0){
			let arr = [];
			this.props.vendorList.filter((status)=>{
				if(!status.isActive){
					arr.push(status)
				}
			})
			return arr;
		}
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Vendor');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-users"></i> Vendor Search</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<VendorList data={this.vendorList()} addVendorOnClick={this.addVendorOnClick}
				 bindVendorList={this.bindVendorList} accessLevel={accessLevelObj||{}}
				 editVendorOnClick={this.editVendorOnClick} activeInactiveVendorOnClick
				 ={this.activeInactiveVendorOnClick}/>
			</div>					
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		vendorList:state.vendorList.vendorsList
  }
};
export default withRouter(connect(mapStateToProps)(VendorsPage));
