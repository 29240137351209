import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { isManagement } from '../../../util/customMethods';
import {
    required
} from "../../../util/validationMethods.js";
class BatchPaymentsCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
        const { accList } = this.props || [];
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <Form ref={c => {
                                        this.formBatchPaymentsInfo = c;
                                        this.props.initCallBack(c);
                                    }} autocomplete="off">
                                    <div className="form-row">
                                    {isManagement()?<div className="col-4 form-group">
                                              <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    options={this.props.childCompList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.idCompany || '').toString()),
                                                        label: (this.props.childCompList.find((e) => e.optionVal == (this.props.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onBatchPaymentsFieldChange('idCompany', parseInt(selectedOption.value));
                                                    }}
                                                />
                                                <Input hidden={true} name='idCompany' value={this.props.idCompany || ''} validations={[required]} requiredmsg="Please select company"></Input>
                                        </div>:null}
                                        <div className="col-3 form-group">
                                            <ReactSelect name="accountId"
                                                closeMenuOnSelect={true}
                                                options={accList.map((item, t) => {
                                                    return ({ value: item.optionVal, label: item.optionText })
                                                })}
                                                value={{
                                                    value: ((this.props.accountId || '').toString()),
                                                    label: (accList.find((e) => e.optionVal == (this.props.accountId || '').toString()) || {}).optionText
                                                }}
                                                onChange={(selectedOption) => {
                                                    this.props.onBatchPaymentsFieldChange('accountId', parseInt(selectedOption.value), (accList.find((e) => e.optionVal == (parseInt(selectedOption.value) || '').toString()) || {}).optionText);
                                                }}
                                            />
                                            <Input hidden={true} name='accountId' value={this.props.accountId || ''} validations={[required]} requiredmsg="Please select account name"></Input>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2"
                                            onClick={(e)=>this.props.getBatchPaymentDetails(e)}>Go</button>
                                        </div>
                                    </div>
                                    </Form>
                                    {this.props.dataFetched &&
                                    <Form ref={c => {
                                        this.formBatchPaymentsAcceptInfo = c;
                                        this.props.initCallBackAccept(c);
                                    }} autocomplete="off">
                                    <div className="form-row">
                                        <div className="col-2 form-group">
                                        <Select className="form-control" name="location" value={this.props.location}
                                            onChange={(e)=>this.props.onBatchPaymentsFieldChange(e.target.name,e.target.value)}
                                            validations={[required]} requiredmsg="Please select location">
                                                <option value="">Select Location</option>
                                                {this.props.reviewLocation.sort(function(a,b){
                                                            if(a.location.toUpperCase() < b.location.toUpperCase())
                                                            return -1;
                                                        }).map((loc, key) => {
                                                            return (
                                                                <option key={key} value={loc.location}>
                                                                    {loc.location}
                                                                </option>
                                                            );
                                                        })
                                                }
                                        </Select>
                                        </div>
                                        <div className="col-2 form-group">
                                            <Select className="form-control" name="paymentType" value={this.props.paymenttype}
                                            onChange={(e)=>this.props.onBatchPaymentsFieldChange(e.target.name,e.target.value)}
                                            validations={[required]} requiredmsg="Please select payment type">
                                                <option value="">Payment Type</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Credit">Credit</option>
                                                <option value="Check">Check</option>
                                                <option value="EmployeeAdvance">Employee Advance</option>
                                                <option value="Transfer">Transfer</option>
                                            </Select>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.asofdate === "" || this.props.asofdate === null
                                                ? null
                                                : moment(
                                                    this.props.asofdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="asOfDate"
                                        reqmsg="As of date is required"
                                        onDateSelectChange={e => {
                                            this.props.onBatchPaymentsFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-2 form-group">
                                            <Input type="text" className="form-control" placeholder="checque#" value={this.props.checkno} name="checkNo"
                                            onChange={(e)=>this.props.onBatchPaymentsFieldChange(e.target.name,e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="ml-2 mr-2">
                                            <label htmlFor="paymentamount" className="form-label col-form-label">Payment Amount</label>
                                        </div>
                                        <div className="col-2 form-group custom-input-icon">
                                            <input type="text" className="form-control" 
                                                    value={this.props.amttoprocess} name="amtToProcess"
                                                    onChange={(e)=>this.props.onBatchPaymentsFieldChange(e.target.name,e.target.value)}
                                                    onBlur={(e)=>{
                                                       let val=(e.target.value||'')===''?0:(isNaN(parseFloat(e.target.value))?0:parseFloat(e.target.value))
                                                       let num = parseFloat(val);
                                                       let cleanNum = num.toFixed(2);
                                                       this.props.onBatchPaymentsFieldChange(e.target.name,cleanNum)
                                                    }}
                                                /><i>$</i>
                                        </div>
                                        <div className="ml-2 mr-2">
                                            <label htmlFor="currentamount" className="form-label col-form-label">Current Amount</label>
                                        </div>
                                        <div className="col-2 form-group custom-input-icon">
                                            <input type="text" className="form-control" placeholder="0.00" 
                                                    name="amtToPay" value={this.props.amttopay} disabled
                                                /><i>$</i>
                                        </div>
                                        <div className="col-4 form-group">
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" 
                                            onClick={(e)=>this.props.paymentSubmit(e)}>Accept</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" >Refresh</button>
                                        </div>
                                    </div>
                                    </Form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default BatchPaymentsCtrl;