import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required,isValidForm} from '../../../../util/validationMethods'
import ReactTable from 'react-table-v6';
class CitiesReviewedByLocation extends Component {
    constructor() {
        super();
        this.state = {
            city:''
        }
    }
    render() {
        const columns=[{
            Header: 'LOCATION',
            accessor: 'cityName', // String-based value accessors!
            filterable:true,
            Cell: props => <span>{props.value}</span>
            
          },
          {
            Header: 'ACTIONS',
            filterable:false,
            Cell: (props) => <button type="button" className="btn btn-sm btn-danger" onClick={(e)=>{
                e.preventDefault();
                this.props.onCityNameDelete(e,props.original.cityName)

            }}><i className="fa fa-trash"></i> Delete</button>,
          }];
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-8 col-sm-12 mb-5">
                    <Form ref={(r)=>this.form=r} >
                    <div className="">
                            <label className="col-form-label">City Name</label>
							<div className="row"> 
							<div className="col-md-10">
							<Input ref={(_inputCity)=>{this.inputCity=_inputCity}} className="form-control" name="City Name" type="text" value={this.state.city}
                                requiredmsg="Please City Name"
                                 placeholder="" validations={[required]} onChange={(e)=>{
                                     this.setState({city:e.target.value});
                                 }}
                                  />
							
							</div>
							<div className="col-md-2 text-right">
							<button type="button" className="btn btn-success btn-sm"  onClick={(e)=>{
                                e.preventDefault();
                                this.form.validateAll();
                                if(!isValidForm(this.form)){
                                }else{
                                    let _city=this.state.city;
                                    this.setState({city:''},this.props.onCityNameAdded(e,_city));  
                                    this.form.hideError(this.inputCity) ;                     
                                }                                
                            }}>Add</button></div>
							
							</div>
                            
							
                        </div>

                    </Form>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 col-sm-12 mb-10">
                        <ReactTable
                            filterable
                            sortable
                            showPagination={false}
                            minRows={5}
                            pageSize={this.props.citiesReviewedByLocationListData.length}
                            data={this.props.citiesReviewedByLocationListData||[]}
                            columns={columns}
                        />
                    </div>
                    <div className="col-md-4">
                        <textarea rows="4" cols="50" className="form-control" readOnly="true" style={{minHeight:'120px', fontStyle:'italic',color:'red'}}>
                        {'The automated review system will use an invoice’s drop address city to automatically distribute invoices to a review location with a matching city when other sorting criteria is not available.'}                         
                        </textarea>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CitiesReviewedByLocation;