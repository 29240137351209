import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { notify } from '../../../util/notifications';
import printJS from 'print-js'
import {isManagement,getCompanyId} from '../../../util/customMethods'
import StorageInventoryCtrl from '../../../components/reportsComponents/storagemanagement/storageInventoryCtrl';
import StorageInventoryTable from '../../../components/reportsComponents/storagemanagement/storageInventoryTable';
import {initInventoryState} from '../../../util/stateUtil/initialStateForComponents';
import {inventoryColmun} from '../../../util/tableColumnUtil/inventoryColmun'
import {retrieveStorageInventoryReport,emailStorageInventoryReport,cleanStorageInventoryReport} from '../../../actions/reportAction/storageManagement/storageManagement'
const pageTitle = 'Storage Inventory';
const printJSHeaders = inventoryColmun.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
let StorageInventoryForm={
	formStorageInventoryInfo:{}
}

class StorageInventory extends Component {
	state = {
        ...initInventoryState
    }
	componentWillMount = () => {
        const { dispatch } = this.props;
		document.title = pageTitle;
		dispatch(cleanStorageInventoryReport());
    }
    inventoryGo = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retrieveStorageInventoryReport(payload, status));
        if (res.statusCode === 200) {
            // if (state) {
            //     this.setState({ render: !this.state.render });
            // }
            notify.createNotification('success', res.statusMsg, this.props.refNotify);
            this.setState({ render: false });
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    inventoryExport = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retrieveStorageInventoryReport(payload, status));
        if (res.statusCode === 200) {
            this.setState({ render: !this.state.render });
            notify.createNotification('success','Report exported successfully.', this.props.refNotify);
            this.setState({ render: false });
        } else {
            this.setState({ render: false });
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    inventoryPrint = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(retrieveStorageInventoryReport(payload, status));
        if (res.statusCode === 200) {
            printJS({
                printable: this.props.data,
                properties: printJSHeaders,
                type: 'json',
                documentTitle: 'Storage Inventory Report'
            });
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

    emailInventory = async () => {
        const { dispatch } = this.props;
        let status = false;
        let payload = this.buildObject();
        if(payload){
        if (isManagement() && (this.state.idCompany || 0) == 0) {
            status = true;
        }
        const res = await dispatch(emailStorageInventoryReport(payload, status));
        if (res.statusCode === 202) {
            notify.createNotification('success',res.statusMsg, this.props.refNotify);
        } else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        };
    }
    }

        /* Building all invoice object for post request */
        buildObject = () => {
            const stateObj = this.state;
            const constractObj = {
                idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
                location:stateObj.selectedLocation,
                daysLimitOne: stateObj.daysLimitOne, 
                daysLimitTwo: stateObj.daysLimitTwo,
                accountId:stateObj.accountId
            };
            return constractObj;
        }

    onFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let InventoryState =this.state;
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Storage Inventory</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <StorageInventoryCtrl
                    inventoryState={InventoryState} onFieldChange={(key,val)=>{this.onFieldChange(key,val)}}
                    childCompList={this.props.childCompList} locations={this.props.locations}
                    inventoryGo={this.inventoryGo}  inventoryExport={this.inventoryExport} data={this.props.data}
                    emailInventory={this.emailInventory} inventoryPrint={this.inventoryPrint}
                    accountList = {this.props.accountList||[]}
                    initCallBack={(frm)=>{
						StorageInventoryForm.formStorageInventoryInfo=frm;
                    }} />
                    <StorageInventoryTable 
                    cols={inventoryColmun}
                    data={this.props.data}
                    />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
        childCompList: state.searchInvoiceList.childComList,
        locations: state.location.locationList,
        data:state.storageManagement.inventoryReportData,
        accountList: state.invoiceInfo.accountList,
	}
};

export default withRouter(connect(mapStateToProps)(StorageInventory));
