import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CompanyList from '../../components/adminComponents/company/companyList'
import {getCompanyList} from '../../actions/adminAction/companyActions'
import { getAccessLevelsByModule } from '../../util/customMethods'
const pageTitle = 'Company'
class CompanyPage extends Component {
	constructor(props) {
		super(props);
		this.state={
			filter:1
		}
		this.getCompanyList=this.getCompanyList.bind(this);	
	}
	componentWillMount = () => {
		document.title = pageTitle;	
		this.getCompanyList();	
	}
	getCompanyList=()=>{
		this.props.dispatch(getCompanyList(true))
	}

	render() {	
		const accessLevelObj = getAccessLevelsByModule('Company');	
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Company Search</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
				<CompanyList data={(this.props.companyList||[]).filter(item=>{
					if(this.state.filter===1)
						return item.isActive===true;
					else if(this.state.filter===0)
						return (item.isActive||false)===false;
					else  
						return true;
					})} 
					accessLevel={accessLevelObj || {}}
					onSearchChange={(searchObj)=>{
						this.setState({filter:searchObj.activeFilter})
					}}
					onClickNewCompany={(e) => {
							this.props.history.push('/admin/companyRecord?cid=0');
					}} 
					onClickEditCompany={(e, cid) => {
							this.props.history.push('/admin/companyRecord?cid=' + cid);
					}} />		
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		refNotify: state.refNotify,
		companyList: state.companies.companyList,		
	}
};

export default withRouter(connect(mapStateToProps)(CompanyPage));
