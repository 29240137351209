import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {email,phone,isNumeric,required,
    isValidForm} from '../../../../util/validationMethods';


class CompanyInfoContacts extends Component {
    constructor(props){
        super(props);
        this.state={
            validated:false
        }
        this.handleContactSubmit=this.handleContactSubmit.bind(this);
    }
    handleContactSubmit(event) {
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{            
            this.props.onContactSubmit(event);
        }
      }
      onChangeField=(e)=>{
          this.props.onContactFieldChange(e.target.name,e.target.value)
      }
    render(){
        const {contactObj} =this.props
        return(<Fragment> 
            <Form ref={c => { this.form = c }}>
                <div className="form-group">
                    <label htmlFor="contactName" className="form-label">CONTACT NAME</label>
                    <Input name="contactName" value={contactObj.contactName||''} 
                    type="text" 
                    className="form-control form-control-sm"
                    validations={[required]}
                    requiredmsg="Please enter Contact Name" 
                    onChange={this.onChangeField}
                    />
                    
                </div>
                <div className="form-group">
                    <label htmlFor="contactPhone" className="form-label">PHONE NO</label>
                    <Input name="contactPhone" type="text" value={contactObj.contactPhone||''}
                    validations={(this.props.isPhoneRequired)?[required,phone]:[phone]}
                    className="form-control form-control-sm" 
                    requiredmsg="Please enter Phone No" onChange={this.onChangeField}
                   />
                    
                </div>
                <div className="form-group">
                    <label htmlFor="ext" className="form-label">EXT</label>
                    <Input name="contactExt" value={contactObj.contactExt||''} type="text" 
                    className="form-control form-control-sm" onChange={this.onChangeField} />
                    
                </div>
                <div className="form-group">
                    <label htmlFor="contactEmail" className="form-label">EMAIL-ID</label>                    
                    <Input name="contactEmail" value={contactObj.contactEmail||''} 
                    type="text" className="form-control form-control-sm" 
                    requiredmsg="Please enter Email-ID" onChange={this.onChangeField}
                    validations={(this.props.isEmailRequired)?[required,email]:[email]}
                   />
                    
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>{
                                                this.props.handleClose(e)
                                            }}>{'Cancel'}</button>
                        <button type="button" className="btn btn-success pull-right btn-sm" onClick={(e)=>{
                                                this.handleContactSubmit(e)
                                            }}>{this.props.submitBtnText}</button>
                    </div>
                </div>
            </Form>
        </Fragment>);
    }
}
export default CompanyInfoContacts