import {GET_ARDETAILBYACCOUNTTYPE_LIST} from './../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
//import { getCompanyId } from '../../../util/customMethods';
export const getARDetailByAccountTypeList=(accountType, asOfDate, idCompany)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`showAccountsReceivableDetailByAccType?access_token=${getAccessToken()}&companyId=${idCompany}&accountType=${accountType}&asOfDate=${asOfDate}`);
            if(resp.status===200){
                dispatch({type:GET_ARDETAILBYACCOUNTTYPE_LIST,arDetailByAccType:resp.data})
            }else{
                dispatch({type:GET_ARDETAILBYACCOUNTTYPE_LIST,arDetailByAccType:undefined})
            }
        }catch(ex){
            dispatch({type:GET_ARDETAILBYACCOUNTTYPE_LIST,arDetailByAccType:undefined})
        }
    }
}
export const getARDetailByAccountTypeClearList=()=>{
    return async (dispatch) => {
        dispatch({type:GET_ARDETAILBYACCOUNTTYPE_LIST,arDetailByAccType:undefined})
    }
}
