import * as actionTypes from '../../../actions/actionTypes';
const defaultObject={
    inventoryReportData:[]
}
export const storageManagement = (state = defaultObject, action) => {
    switch (action.type) {
      case actionTypes.GET_INVENTORY_DATA:
        return {...state ,inventoryReportData:(action.inventoryReportData||defaultObject.inventoryReportData)};  
      default:
        return state;
    }
  };