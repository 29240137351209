import * as actionTypes from '../../actions/actionTypes';
const defaultCommission={
  commissionTypes:[],
  userDefaultCharges:[],
  defaultCharges:[],
  newCommissionType:'',
  driverLevels:[],
  newDriverLevel:'',
  commissionDetailsList:[],
  commissionDetailObj:{
      "commissionId":0,
      "commissionTypeId":0,
      "driverLevelId":0,
      "minCommPerCall":'',
      "commFlatRate":'',
      "hourlyComm":'',
      "hourlyCommMinHrs":'',
      "hourlyCommIncrHrs":'',
      "commStartFrom":"",
      "commEndBy":"",
      "commPercentAllCharges":'',
      "commPercentTowCharge":'',
      "commPercentLabourCharge":'',
      "commPercentStorageCharge":'',
      "commPercentMileageCharge":'',
      "commPercentMiscCharges":'',
      "commPercentWinchCharge":'',
      "commissionTypeName":'',
      "driverLevelName":'',
      "commPercentAdvPay":'',
      "commPercentGate":'',
      "commPercentDiscount":'',
      "commPercentTax":'',
      "commPercentCustomCharge":"",
      "idCompany":0,    
      "commissionNames":{
        "commissionType":"",
        "driverLevel":""
      }
  }  
}
export const commissionReducer = (state = defaultCommission, action) => {
    switch (action.type) {
      case actionTypes.GET_COMMISSION_TYPES:
        return {...state ,commissionTypes:(action.commissionTypes||defaultCommission.commissionTypes)};
      case actionTypes.GET_DRIVER_LEVELS:
        return {...state , driverLevels:(action.driverLevels|| defaultCommission.driverLevels) };
      case actionTypes.GET_COMMISSION_DETAIL_LIST:
        return {...state , commissionDetailsList:(action.commissionDetailsList|| defaultCommission.commissionDetailsList) };
      case actionTypes.SET_COMMISSION_TYPE:
        return {...state , newCommissionType:(action.newCommissionType|| defaultCommission.newCommissionType) };
      case actionTypes.SET_DRIVER_LEVEL:
        return {...state , newDriverLevel:(action.newDriverLevel|| defaultCommission.newDriverLevel) };
      // case actionTypes.GET_USER_DEFAULT_CHARGES:
      //   return {...state , userDefaultCharges:(action.userDefaultCharges|| defaultCommission.userDefaultCharges) };
      case actionTypes.GET_COMMISSION_DETAIL_REC:
        let result=action.commissionDetailObj ||defaultCommission.commissionDetailObj
        result.commissionNames=result.commissionNames.length>0?result.commissionNames[0]:defaultCommission.commissionDetailObj.commissionNames
        return {...state , commissionDetailObj:result };
      case actionTypes.SET_COMMISSION_DETAIL_FIELD:
        return {...state , commissionDetailObj:action.commissionDetailObj };
      case actionTypes.GET_DEFAULT_CHARGES:
        return {...state,defaultCharges:(action.defaultCharges||defaultCommission.defaultCharges)}
      default:
        return state;
    }
  };