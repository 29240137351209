import React, { Component, Fragment } from 'react';
// import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import TmDateTimePicker from '../../utilDispatch/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues} from '../../../../util/constants';
import { returnDateObj} from '../../../../util/customMethods';
import Form from 'react-validation/build/form';
import {
    required,
} from "../../../../util/validationMethods.js";
import moment from 'moment';

class ServiceCallTimes extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            editStatus:true,
            saveStatus:false,
            closeStatus:false,
            elpseTime:'00:00',
            timesText:{
                requestTime: 'Recieved Time',
                dispatchTime: "Dispatched Time",
                acceptedTime: 'Accept Time',
                enrouteTime: 'Enroute Time',
                arrivedTime: 'Arrived Time',
                hookedTime: 'Hooked Time',
                dropOffTime: 'Drop Time',
                clearedTime: 'Cleared Time',
                }
        } 
        this.manageDateSelection = this.manageDateSelection.bind(this);
    }
    componentDidMount(){
        setInterval(this.handleReceived, 1000);
    }
    manageDateSelection=(event,key1,key2,key3)=>{
        let sliderObj= this.props.invoiceInfo._invoiceObj || {};
        if (key1 ==null || (sliderObj[key1]||'') != '') {
          if ((key1 == null || returnDateObj(sliderObj[key1]) <= event) && (key3==null || sliderObj[key3]==null || event<=returnDateObj(sliderObj[key3]))) {
            this.props.serviceCallInfoFieldChange('',key2 , event === null ? null : moment(event).format(GlobalValues.dateTimeFmtForDispatch))
          } else if(key1 !=null && returnDateObj(sliderObj[key1])>event){
            alert(this.state.timesText[key2]+" should be greater than "+this.state.timesText[key1])
            return false;
          }else if(key3 !=null && returnDateObj(sliderObj[key3])<event){
            alert(this.state.timesText[key2]+" should be less than "+this.state.timesText[key3])
            return false;
          }
        } else {
          alert("Please select "+this.state.timesText[key1]+ " first");
          this.setState({[key2]:sliderObj[key2]})
          return false;
        }
      }

      handleReceived=()=> {
        let timeLeftFrmt ='00:00';
        let currentDate = moment().format('MM-DD-YYYY HH:mm:ss');
        let finalDate = moment(currentDate);
        if((this.props.invoiceInfo._invoiceObj.requestTime||'') !=''){
          let timeLeft = (this.props.invoiceInfo._invoiceObj.clearedTime==null?finalDate:moment(this.props.invoiceInfo._invoiceObj.clearedTime)).diff(moment(this.props.invoiceInfo._invoiceObj.requestTime));
          timeLeftFrmt=this.millisToMinutesAndSeconds(timeLeft);
        }
        this.setState({elpseTime:timeLeftFrmt})
      }

      millisToMinutesAndSeconds=(millis)=> {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      }
      
    editCallTimes=()=>{
        this.setState({editStatus:false,saveStatus:true})
    }
    saveCallTimes=()=>{
        this.setState({editStatus:true,saveStatus:false})
    }
   
    render() {
        const { _invoiceObj} = this.props.invoiceInfo || {};
        const isDisabled = (!(this.props.invoicePermission.edit||false)) || (_invoiceObj.invoiceBilling.isVerifiedAndLocked==1)||(_invoiceObj.callStatus=='Cancel');
         return (<Fragment>
            <Form ref={c => { this.form = c }}>
            <div className="table-responsive">
				<table className="table table-bordered table-striped"
						style={{borderCollapse:'collapse', width:'100%', tableLayout:'fixed', marginBottom:'0'}}>
                    <thead>
                        <tr>
                            <th className="text-center">ACTION</th>
                            <th className="text-center">RECEIVED</th>
                            <th className="text-center">DISPATCHED</th>
                            <th className="text-center">ACCEPTED</th>
                            <th className="text-center">ENROUTE</th>
                            <th className="text-center">ARRIVED</th>
                            <th className="text-center">HOOKED</th>
                            <th className="text-center">DROPOFF</th>
                            <th className="text-center">CLEARED</th>
                            <th className="text-center">ELAPSED</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                {this.state.editStatus ? <button type="button" title="Edit" disabled={(_invoiceObj.invoiceId||'')==''|| isDisabled} className="btn btn-warning btn-sm mr-1"
                                onClick={()=>this.editCallTimes()}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </button> : null}
                                {this.state.saveStatus ? <button type="button" disabled={isDisabled} title="Save" className="btn btn-success btn-sm mr-1"
                                onClick={()=>this.saveCallTimes()}>
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                </button> : null}
                                {this.state.closeStatus ? <button type="button" disabled={isDisabled} title="Close" className="btn btn-danger btn-sm">
                                    <i className="fa fa-close" aria-hidden="true"></i>
                                </button> : null}     
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.requestTime===null ?'--:--:--':moment(_invoiceObj.requestTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.requestTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.requestTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="receivedDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {
                                        this.manageDateSelection(e,null,'requestTime','dispatchTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.dispatchTime === null ?'--:--:--':moment(_invoiceObj.dispatchTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.dispatchTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.dispatchTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="dispatchedDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {this.manageDateSelection(e,'requestTime','dispatchTime','acceptedTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.acceptedTime === null ?'--:--:--':moment(_invoiceObj.acceptedTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.acceptedTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.acceptedTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="acceptedDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {this.manageDateSelection(e,'dispatchTime','acceptedTime','enrouteTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{ _invoiceObj.enrouteTime === null ?'--:--:--':moment(_invoiceObj.enrouteTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.enrouteTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.enrouteTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="enrouteDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {
                                        this.manageDateSelection(e,'acceptedTime','enrouteTime','arrivedTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.arrivedTime === null ?'--:--:--':moment(_invoiceObj.arrivedTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.arrivedTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.arrivedTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="arrivedDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {
                                        this.manageDateSelection(e,'enrouteTime','arrivedTime','hookedTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.hookedTime === null ?'--:--:--':moment(_invoiceObj.hookedTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.hookedTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.hookedTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="hookedDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {
                                        this.manageDateSelection(e,'arrivedTime','hookedTime','dropOffTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.dropOffTime === null ?'--:--:--':moment(_invoiceObj.dropOffTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.dropOffTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.dropOffTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="dropoffDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {
                                        this.manageDateSelection(e,'hookedTime','dropOffTime','clearedTime')
                                    }}/>
                                </div>}
                            </td>
                            <td className="text-center"> {this.state.editStatus ?
                                <label>{_invoiceObj.clearedTime === null ?'--:--:--':moment(_invoiceObj.clearedTime).format("HH:mm:ss")}</label>:
                                <div className="tm-datepicker">
                                    <TmDateTimePicker 
                                    className="form-control"
                                    selectedDate={
                                        _invoiceObj.clearedTime === null
                                            ? null
                                            : moment(
                                                moment(_invoiceObj.clearedTime,GlobalValues.dateTimeFmtForDispatch)
                                                ,
                                                GlobalValues.dateTimeFormat
                                            )._d
                                    }
                                    dateFormat={GlobalValues.dateTimeFormat}
                                    showTimeSelect={true}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="clearedDateTime"
                                    reqmsg="Expiry Date required"
                                    onDateSelectChange={e => {
                                        this.manageDateSelection(e,'dropOffTime','clearedTime',null)
                                    }}/>
                                </div>}
                            </td>
                                <td className="text-center"><label>{this.state.elpseTime}</label></td>
                        </tr>
                    </tbody>
				</table>
			</div>
        </Form>
        </Fragment>);
    }
}
export default ServiceCallTimes
