import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required, isValidForm} from '../../../../util/validationMethods';
class DriverLevel extends Component {
    constructor(props){
        super(props);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    handleSubmit=(event)=>{
        event.preventDefault();
        this.form.validateAll();
        if(!isValidForm(this.form)){
        }else{
            this.props.onSubmitForm(event)
        }
    }

    render(){
        return(
        <Form ref={c => { this.form = c }} >
            <div className="modal-body">
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <label className="form-control-label">ENTER DRIVER LEVEL*</label>
                        <Input name="Driver Level" type="text" className="form-control"  placeholder=""
                        requiredmsg="Please enter Driver Level" 
                        value={this.props.value} onChange={(e)=>{
                            this.props.onFieldDataChange(e);
                        }} validations={[required]} />
                        
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" variant="secondary" className="btn btn-danger btn-sm" 
                onClick={(e)=>{this.props.onCancelForm(e)}}>Close</button>
                <button type="button" variant="primary" className="btn btn-success btn-sm" onClick={(e)=>{
                    this.handleSubmit(e)
                }}>Save changes</button>
    
                </div>
            </div>
            </Form>
            )
    }

}
export default DriverLevel;