import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { notify } from '../../util/notifications';
import  ServiceCallInfo from '../../components/dispatchComponents/addServiceCall/serviceCallInfo';
import {fetchInvoiceInfoById, fetchReQAccountInfo, serviceCallInfoFieldChange, changeLocation,clearInvoiceRec, defaultInvoiceInfo, updateInvoice,fetchAccountByType, reCalMileage, getRatePlanById } from '../../actions/dispatchAction/invoiceAction';
import { validate, isManagement, getAccessLevelsByModuleName } from '../../util/customMethods';
import { isValidForm } from '../../util/validationMethods';
import { fetchAccountList } from '../../actions/dispatchAction/initialReqInfoAction';
import { serviceCallState } from '../../util/stateUtil/initialStateForComponents';
import { Modal } from 'react-bootstrap';
let newServiceCallForm = { frmNewServiceCallInfo: {},  frmPickAddressEntry: {}, frmDropAddressEntry: {} }
const defaultAccess = { add: false, view: false, edit: false, delete: false };
const pageTitle = 'Add new service call';
class DispatchReview extends Component {
    state = {
        ...serviceCallState
    }
    componentWillMount = () => {
        const invoiceId = this.props.dispatchReInvoice;
        this.props.dispatch(clearInvoiceRec())
        if (!validate(invoiceId, 'String')) {
            this.bindInvoiceInfo(invoiceId);
            this.setState({ invoiceId: invoiceId, newInvoice: false, account_rate_planID: this.props.invoiceInfo._invoiceObj.accountRatePlanId })
        }
    }
    onFieldValueChange = (key, value) => {
        this.setState({ [key]: value })
    }
    componentWillUnmount = () => {
        this.props.dispatch(clearInvoiceRec())
    }
    /*This function is used to get invoice info*/
    bindInvoiceInfo = async (invoiceId) => {
        const { dispatch } = this.props
        let res = await dispatch(fetchInvoiceInfoById(invoiceId));
        if (res.statusCode == 200){
            dispatch(fetchReQAccountInfo((res.response.accountId || 0), invoiceId, 'regular', true));
            dispatch(fetchAccountList(res.response.accountId || 0));
        }
        else {
            notify.createNotification('error', res.statusMsg, this.props.refNotify);
        }
    }
    /** this method is used to Add/update current available invoice in edit mode*/
    addUpdateInvoice = async () => {
        const { dispatch } = this.props;
        let formIsValid = true;
        newServiceCallForm.frmNewServiceCallInfo.validateAll();
        newServiceCallForm.frmPickAddressEntry.validateAll();
        if (!(isManagement())) {
            newServiceCallForm.frmDropAddressEntry.validateAll();
        }
        if (!isValidForm(newServiceCallForm.frmNewServiceCallInfo)) {
            formIsValid = false;
        } else if (!isValidForm(newServiceCallForm.frmPickAddressEntry)) {
            formIsValid = false;
        } else if (!(isManagement()) && !isValidForm(newServiceCallForm.frmDropAddressEntry)) {
            formIsValid = false;
        }
        else if (formIsValid) {
            if (this.props.invoiceInfo._invoiceObj.invoiceId || '' !== '') {
                const res = await dispatch(updateInvoice(this.props.invoiceInfo._invoiceObj.invoiceId));
                if (res.statusCode === 200) {
                    this.setState({
                        invoiceId: res.response.invoiceId,
                        duplicateInvoiceIds: res.response.duplicateInvoiceIds || [],
                        originalInvoiceId: res.response.originalInvoiceId,
                        invoicemsg: res.statusMsg, showInvoiceModel: true,
                        newInvoice: false
                    })
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                }
            }
        }
    }

    serviceCallInfoFieldChange = (objName, key, value) => {
        this.setState({ invoiceObjChanged: true });
        this.props.dispatch(serviceCallInfoFieldChange(objName, key, value));
    }

    changeLocation = (type, key, addObj) => {
        this.props.dispatch(changeLocation(type, key, addObj));
        this.setState({ addChanged: true })
    }
    reloadCall = () => {
        this.props.history.push("serviceCallInfo");
    }
    getFilteredPermission = (obj, key) => {
        let permissionObj = obj.find(item => item.pageName == key);
        if (permissionObj) {
            return permissionObj.basicAccess;
        }
        return defaultAccess;
    }
    permissionByPage = (obj, key, page) => {
        let permissionObj = obj.find(item => item.pageName == key);
        if (permissionObj) {
            return permissionObj.pageLvlAccess[page] || defaultAccess;
        }
        return defaultAccess;
    }
    fetchReQAccountInfo = async (accountId, invoiceId, callType, status) => {
        const { dispatch } = this.props;
        const res = await dispatch(fetchReQAccountInfo(accountId, invoiceId, callType, status));
        this.setState({ cashOnly: res });
    }

    getRatePlanChange = (e) => {
        e.preventDefault();
        let _arpId = e.target.value;
        this.setState({ account_rate_planID: _arpId });
        const { dispatch } = this.props;
        dispatch(getRatePlanById(_arpId));
    }
    handleRatePlanListOpen = (e) => {
        e.preventDefault();
        this.setState({ openRatePlanData: true });
        const { dispatch } = this.props;
        dispatch(getRatePlanById(this.props.invoiceInfo._invoiceObj.accountRatePlanId));
    }
    render() {
        const { _invoiceObj } = this.props.invoiceInfo || {};
        const invoicePermission = getAccessLevelsByModuleName('Invoice screen');
        let vPic = "";
        (_invoiceObj.invoiceAttachments).map((item) => {
            if (item.attachmentPath.includes("Vehicle_Pic")) {
                vPic = item.attachmentPath;
            }
        })
        return (
            <Fragment>
                <div className="col-md-12 col-sm-12">
                    <ServiceCallInfo vPic={vPic}
                        dispatchReview={this.props.dispatchReview}
                        serviceCallInfoFieldChange={(objName, key, value) => { this.serviceCallInfoFieldChange(objName, key, value) }}
                        onAddressChange={(type, key, addObj) => { this.changeLocation(type, key, addObj) }}
                        reviewLocation={this.props.reviewLocation} accountBasicInfo={this.props.accountBasicInfo}
                        defaultInvoiceInfo={() => { this.props.dispatch(defaultInvoiceInfo()) }}
                        reloadCall={this.reloadCall}
                        fetchAccountByType={(type) => { this.props.dispatch(fetchAccountByType(type)) }}
                        invoicePermission={this.getFilteredPermission(invoicePermission, 'Service Call Info Tab')}
                        reCalMileage={() => {
                            if (((_invoiceObj.invoiceId || '') != '') && this.state.addChanged)
                                this.props.dispatch(reCalMileage());
                        }}
                        fetchReQAccountInfo={(accountId, invoiceId, callType, status) => {
                            this.fetchReQAccountInfo(accountId, invoiceId, callType, status);
                        }}
                        invoiceInfo={this.props.invoiceInfo} allStates={this.props.allStates}
                        initCallBack={(frm) => {
                            newServiceCallForm.frmNewServiceCallInfo = frm;
                        }}
                        initPickAddressCallBack={(frm) => {
                            newServiceCallForm.frmPickAddressEntry = frm;
                        }}
                        initDropAddressCallBack={(frm) => {
                            if (isManagement()) {
                                newServiceCallForm.frmDropAddressEntry = {};
                            } else {
                                newServiceCallForm.frmDropAddressEntry = frm;
                            }
                        }}
                        isManagement={isManagement()} />
                        <div className="text-center pd-10 police-hold-btn">
						<button className="btn btn-sm btn-success mr-20 base-line" onClick={this.addUpdateInvoice}>Save</button>
						<button className="btn btn-sm btn-primary mr-20 base-line" onClick={this.props.onClose}>Close</button>
					</div>
                </div>
                <Modal show={this.state.showInvoiceModel} >
						<div className="modal-body">
							<form className="text-center">
								<div className="form-group">
									<p> {this.state.invoicemsg}</p>
									<p>Invoice No: {this.state.invoiceId}</p>
								</div>
								<div className="form-group">
									<p hidden={this.state.duplicateInvoiceIds.length < 1}>Duplicate invoice: {this.state.duplicateInvoiceIds.join(',')}</p>
								</div>
								<div className="text-center">
									<button onClick={(e) => {
										e.preventDefault();
                                        this.props.onClose();
										this.setState({ showInvoiceModel: false, duplicateInvoiceIds: [] });
									}}
										className="btn btn-primary" style={{ width: '80px' }}>Close</button>
								</div>
							</form>
						</div>
					</Modal>
            </Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        refNotify: state.refNotify,
        allStates: state.states.stateList,
        locationList: state.locationList.locationList,
        reviewLocation: state.location.locationList,
        accountBasicInfo: state.accountList.accountBasicInfo,
        invoiceInfo: state.invoiceInfo,
        vinDetals: state.invoiceInfo.vinDetailsList
    }
};

export default withRouter(connect(mapStateToProps)(DispatchReview));
