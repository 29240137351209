import React, { Component, Fragment } from 'react';
import { isManagement } from '../../../util/customMethods';
import {utilMethods}  from '../../../util/util';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
class BatchPaymentsTable extends Component {
	constructor() {
		super();
		this.state = {
			
		}
  }
  /** Function to add check box to react table */
  renderEditable = cellInfo => {
    const cellValue = this.props.multipleInvoicePayments[cellInfo.index][cellInfo.column.id];
    return (
        <input
            placeholder="type here"
            name="input"
            type="checkbox"
            checked={cellValue === true}
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
        />
    );
};

/** Function to add input box to react table */
renderEditable1 = cellInfo => {
    const cellValue = this.props.multipleInvoicePayments[cellInfo.index][cellInfo.column.id];
    return (
        <input
            className="form-control"
            placeholder="type here"
            name="input"
            type="text"
            onChange={this.handleInputPaymentChange.bind(null, cellInfo)}
            value={cellValue}
            onKeyUp={utilMethods.allowNumberWithDecimalNegative}
        />
    );
};
/** Function to maniupulate multipal invoice payment report data */
handleInputChange = (cellInfo, event) => {
  this.props.handleInputChange(cellInfo, event);
};
/** function to updated invoice payment amount while manually entering  */
handleInputPaymentChange = (cellInfo, event) => {
  this.props.handleInputPaymentChange(cellInfo, event);
};

/** Function to maniupulate multipal invoice payment report data */
handleLockInputChange = (cellInfo, event) => {
  let data = [...this.state.batchData];
  data[cellInfo.index]["lockInvoices"] = event.currentTarget.checked === true ? 1 : 0;
  this.setState({ batchData: data });
};
	render() {
    const tableColumns=[
      {
          accessor: 'invoiceId',
          Header: 'Invoice #',
          style: { textAlign: 'center' },
          Cell: props => <Fragment>{isManagement()?<a href="javascript:void(0)">{' '+props.original.displayInvoiceId}</a>:<a href={'/dispatch/serviceCallInfo/'+props.original.invoiceId} target="_blank"
          >{props.original.displayInvoiceId}</a>}</Fragment>,
          width: 125,
          filterable: true
      },
      {
          accessor: 'releaseDate',
          Header: 'Release Date',
          style: { textAlign: 'center' },
          width: 125
      },
      {
          accessor: 'vehicleYear',
          Header: 'Year',
          style: { textAlign: 'center' },
          width: 125
      },
      {
          accessor: 'vehicleMake',
          Header: 'Make',
          style: { textAlign: 'center' },
          width: 125
      },
      {
          accessor: 'vehicleModel',
          Header: 'Model',
          style: { textAlign: 'center' },
          width: 125
      },
      {
          accessor: 'vehicleVIN',
          Header: 'VIN',
          style: { textAlign: 'center' },
          width: 175
      },
      {
          accessor: 'balanceAmt',
          Header: 'Balance',
          style: { textAlign: 'center' },
          width: 125,
          filterable: false,
          Cell: props => `$ ${props.original.balanceAmt}`
      },
      {
          accessor: 'paymentAmt',
          Header: 'Payment',
          style: { textAlign: 'center' },
          width: 125,
          filterable: false,
          Cell: this.renderEditable1
      },
      {
          accessor: 'paidInFull',
          Header: 'Paid In Full',
          style: { textAlign: 'center' },
          width: 100,
          filterable: false,
          Cell: this.renderEditable
      }]
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={this.props.multipleInvoicePayments}
                        columns={tableColumns}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default BatchPaymentsTable;