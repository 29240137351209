import * as actionTypes from '../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {isManagement,getCompanyId} from '../../../util/customMethods';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
export const retrieveStorageInventoryReport=(obj,status)=>{
    return async (dispatch) => {
        try{
            const _url =`/retrieveStorageInventoryReport/${obj.idCompany}/${obj.accountId}/${status}?access_token=${getAccessToken()}&daysLimitOne=${obj.daysLimitOne}&daysLimitTwo=${obj.daysLimitTwo}&Location=${obj.location}`;
            const response=await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if(response.status===200){
                dispatch({type:actionTypes.GET_INVENTORY_DATA,inventoryReportData:response.data.response||[]})
            }else{
                dispatch({type:actionTypes.GET_INVENTORY_DATA,inventoryReportData:undefined})
            }
            return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
        }catch(ex){
            dispatch({type:actionTypes.GET_INVENTORY_DATA,inventoryReportData:undefined})
            return {statusCode:500,statusMsg:'An unexpected error occurred.'}
        }
    }
}
export const cleanStorageInventoryReport=()=>{
    return async (dispatch) => {
           dispatch({type:actionTypes.GET_INVENTORY_DATA,inventoryReportData:undefined})
    }
}
export const emailStorageInventoryReport=(obj,status)=>{
    return async (dispatch) => {
        try{
            const _url =`/emailStorageInventoryReport/${obj.idCompany}/${obj.accountId}/${status}?access_token=${getAccessToken()}&daysLimitOne=${obj.daysLimitOne}&daysLimitTwo=${obj.daysLimitTwo}&Location=${obj.location}`;
            const response=await getAxiosInstance(moduleTypes.REPORT).get(_url);
            return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
        }catch(ex){
            dispatch({type:actionTypes.GET_INVENTORY_DATA,inventoryReportData:undefined})
            return {statusCode:500,statusMsg:'An unexpected error occurred.'}
        }
    }
}