import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import TmDateTimePicker from '../../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { getCompanyId, isManagement } from '../../../../util/customMethods';
import {GlobalValues, constServiceStart, constServiceEnd, constStoragePeriod,constInStorageTime} from '../../../../util/constants.js';
import {required,isValidForm,isNumeric,isCurrency,isPercentage} from "../../../../util/validationMethods.js";
import moment from 'moment';
const defaultNewRatePlanName='NEW RATE PLAN';
class AccountServiceCallRate extends Component {
    constructor(props) {
        super(props);
        this.state = { ratePlanType: 'both'}
        this.checkAccess = this.checkAccess.bind(this);
    }
    checkAccess = () => {
        if ((this.props.accountBasicInfo.companyId === getCompanyId()) || (this.props.accountBasicInfo.companyId === 0)) {
            if (this.props.accountBasicInfo.accountId === 0) {
                if ((this.props.accessLevel.basicAccess.add || false) === true) {
                    return false
                } else {
                    return true
                }
            } else {
                if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                    return false;
                } else {
                    return true;
                }
            }
        }else{
            return true;
        }
    }
    handlePlanCheckbox = (e) => {
        this.setState({ ratePlanType: e.target.value },
            this.props.onRatePlanChange('0'));
    }
    sortRatePlans=(plans)=>{
        let sortedPlans=[];
        let newRateObj={};
        const newRateIdx=plans.findIndex((item)=>{
            return item.name===defaultNewRatePlanName
        });
        if(newRateIdx!==-1){
            newRateObj=plans[newRateIdx];
        }
        delete plans[newRateIdx];
        sortedPlans= plans.sort((a,b)=>{ 
            return ( (a.name).localeCompare(b.name))
           });
          sortedPlans.push(newRateObj);
          return sortedPlans;
    }

    filterPlans = (plans) => {
        const { ratePlanType } = this.state
        let planArray = [];
        for (let plan in plans) {
            planArray.push({ 
                type: plan, 
                name: plan==='0'?defaultNewRatePlanName: plans[plan].serviceCallRate.accountRatePlanName, endDate: plans[plan].serviceCallRate.ratePlanEndDate })
        }
        if (ratePlanType === "active") {
            return (planArray.filter(plan => moment(plan.endDate) > new Date() || plan.endDate === null))
        } else if (ratePlanType === "inactive") {
            return (planArray.filter(plan => moment(plan.endDate) < new Date() || plan.endDate === null))
        } else {
            return (planArray);
        }
    }
    createUI(ratePln,selectedRatePlan) {
        return (ratePln.serviceCallRate.ratePlanAccountChargeMapping||[]).map((rec,i) =>
          <div className="row form-group" key={rec.recId}>
            <label className="col-md-12 form-control-label" htmlFor="advancedPayout">Custom Charge</label>
            <div className="col-sm-5">
                <select className="form-control" name="chargeId" value={rec.chargeId} onChange={(e) => {
                     this.props.accountRatePlanCustomFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value,i,'ratePlanAccountChargeMapping')
                     }}>
                    <option value=''>SELECT</option>
                    {this.props.userDefaultCharges.map((item,i) =>{
                        let statusOne = ((this.props.accountBasicInfo.accountChargeMapping||[]) || []).some( e=> e.chrageId == item.chargeListId);
                        let statusTwo = (ratePln.serviceCallRate.ratePlanAccountChargeMapping || []).some( e=> e.chargeId == item.chargeListId);
                        if(statusOne || (statusTwo && rec.recId||0 !=0)){
                        return (<option  value={item.chargeListId} disabled={!statusOne}>{item.chargeName}</option>)
                        }
                    }
                    )}
                </select>  
            </div>
            <div  className="col-sm-5">
              <div className="input-groups">
                <div className="input-groups-icon"><i className="fa fa-usd"></i></div>
                <div className="input-groups-area">
                    <input
                    type="number" className="form-control"
                    name="chargeAmt" value={rec.chargeAmt}
                    onChange={(e) => {
                        this.props.accountRatePlanCustomFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, parseFloat(e.target.value),i,'ratePlanAccountChargeMapping')
                        }}
                    />
                </div>
              </div>
            </div>
            <span className="col-sm-2"> 
                <button title="Remove" name="charges" className="btn btn-sm btn-danger" 
                onClick={(e) => {
                    e.preventDefault();
                    this.props.removeCustomCharge(selectedRatePlan, 'serviceCallRate', 'ratePlanAccountChargeMapping', i)
                }}>
                <i class="fa fa-close" aria-hidden="true"></i>
                </button>
            </span>
          </div>
        )
      }

    render() {
        const { accRatePlanAndDiscountInfoByName } = this.props.accountBasicInfo
        const { selectedRatePlan } = this.props;
        let ratePln = Object.assign({}, accRatePlanAndDiscountInfoByName[selectedRatePlan]);
        return (<Fragment>
            <div className="pd-10">
            <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <h6 className="text-blue text-bg-color form-group">CALL RATES</h6>
                    </div>
                </div>
                <div className="row mb-10">
                    <div className="pd-10">
                        <Form ref={(f) => {
                        this.frmServiceCallRate = f;
                        }}>
                            <fieldset disabled={this.checkAccess()}>
                                <div className="row mb-10">
                                    <div className="col-md-12 col-sm-12 mb-5">
                                        <div className="card box-shadow">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div role="group" className="btn-group-toggle btn-group btn-group-toggle btn-group-toggle">
                                                            <label className={`btn btn-outline-info btn-sm btn btn-outline-info btn-sm-primary ${this.state.ratePlanType === "active" ? 'active':''}`}>
                                                                <input type="radio" value="active" name="ratePlan" checked={this.state.ratePlanType === "active"} onChange={this.handlePlanCheckbox} />Active </label>
                                                            <label className={`btn btn-outline-info btn-sm btn btn-outline-info btn-sm-primary ${this.state.ratePlanType === "inactive" ? 'active':''}`}>
                                                                <input type="radio" value="inactive" name="ratePlan" checked={this.state.ratePlanType === "inactive"} onChange={this.handlePlanCheckbox} />Inactive</label>
                                                            <label className={`btn btn-outline-info btn-sm btn btn-outline-info btn-sm-primary ${this.state.ratePlanType === "both" ? 'active':''}`}>
                                                                <input type="radio" value="both" name="ratePlan" checked={this.state.ratePlanType === "both"} onChange={this.handlePlanCheckbox} />All</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="acc-rate-plan" className="form-label col-form-label">ADD/EDIT RATE PLAN</label>
                                                            <div className="">
                                                                <select name="selectedAccRatePlan" className="form-control form-control-sm"
                                                                    value={selectedRatePlan} onChange={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.onRatePlanChange(e.target.value);
                                                                    }} placeholder="Select Rate Plan">
                                                                    {
                                                                       this.sortRatePlans(this.filterPlans(accRatePlanAndDiscountInfoByName)).map(((item, index) => {
                                                                            return <option key={index} value={item.type}>{item.name}</option>
                                                                        }))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="name" className="form-label col-form-label">NAME</label>
                                                            <div className="">
                                                                <Input placeholder="" type="text" id="accountRatePlanName" name="accountRatePlanName"
                                                                    value={ratePln.serviceCallRate.accountRatePlanName}
                                                                    validations={[required]} requiredmsg="Please enter rate plan name"
                                                                    className="form-control form-control-sm" onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="tow-type" className="form-label col-form-label">TOW TYPE</label>
                                                            <div className="">
                                                                <Select className="form-control form-control-sm" name="towType"
                                                                    value={ratePln.serviceCallRate.towType} validations={[required]}
                                                                    requiredmsg="Please select tow type"
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value);
                                                                    }}>
                                                                    <option></option>
                                                                    {
                                                                        this.props.towTypes.map((item, k) => {
                                                                            return <option key={k} value={item.towTypeId} >{item.towType}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className="">
                                                            <label htmlFor="arate-plan-effect-start-date" className="form-label col-form-label">RATE PLAN EFFECTIVE START DATE</label>
                                                            <div className="">
                                                                <TmDateTimePicker className="form-control"
                                                                    selectedDate={(ratePln.serviceCallRate.ratePlanStartDate === '' ||
                                                                        ratePln.serviceCallRate.ratePlanStartDate === null) ? null : moment(ratePln.serviceCallRate.ratePlanStartDate, GlobalValues.dateFormat)._d}
                                                                    dateFormat={GlobalValues.dateFormat}
                                                                    validationArray={[required]}
                                                                    showYearDropdown={true}
                                                                    showmonthdropdown={true}
                                                                    name="ratePlanStartDate"
                                                                    reqmsg="Enter Rate Plan Effective Start and End Date"
                                                                    onDateSelectChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'ratePlanStartDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="">
                                                            <label htmlFor="arate-plan-effect-end-date" className="form-label col-form-label">Rate Plan Effective End Date</label>
                                                            <div className="">
                                                                <TmDateTimePicker className="form-control"
                                                                    selectedDate={(ratePln.serviceCallRate.ratePlanEndDate === '' ||
                                                                        ratePln.serviceCallRate.ratePlanEndDate === null) ? null : moment(ratePln.serviceCallRate.ratePlanEndDate, GlobalValues.dateFormat)._d}
                                                                    dateFormat={GlobalValues.dateFormat}
                                                                    validationArray={[required]}
                                                                    showYearDropdown={true}
                                                                    showmonthdropdown={true}
                                                                    name="ratePlanEndDate"
                                                                    reqmsg="Enter Rate Plan Effective Start and End Date"
                                                                    onDateSelectChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'ratePlanEndDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                                    }} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        {
                                                            this.props.accountBasicInfo.accountId === 0 ?
                                                                <button className="btn btn-primary btn-sm mr-2" style={{ marginTop: '15%' }}> Clear</button> :
                                                                <button type="button" className="btn btn-success btn-sm mr-2" style={{ marginTop: '15%' }} onClick={(e) => {
                                                                    this.frmServiceCallRate.validateAll();
                                                                    if (!isValidForm(this.frmServiceCallRate)) {

                                                                    } else {
                                                                        if (ratePln) {
                                                                            this.props.onAccountServiceCallRateSubmit(e, ratePln)
                                                                        }
                                                                    }
                                                                }}> Save</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 mb-5">
                                        <div className="card box-shadow">
                                            <div className="card-header">
                                                <h5>Service</h5></div>
                                            <div className="card-body" style={{ "height": "1020px" }}>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="">
                                                            <h6 className="text-blue text-bg-color">FLAT RATE</h6></div>
                                                        <div className="form-group">
                                                            <label htmlFor="def-rate" className="form-label col-form-label">RATE</label>
                                                            <div className="">
                                                                <Input placeholder="0.00" type="text" name="serviceFlatRate"
                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                    value={ratePln.serviceCallRate.serviceFlatRate || ''}
                                                                    id="def-rate" className="form-control form-control-sm" onChange={(e) => {                                                                         
                                                                            this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value);                                                                       
                                                                                                                                                                                                                  
                                                                    }}
                                                                    validations={[ isNumeric, isPercentage]}
                                                                    requiredmsg="Enter Dollar Currency"
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <h6 className="text-blue text-bg-color">HOURLY RATE</h6></div>
                                                        <div className="">
                                                            <label htmlFor="def-per-hour" className="form-label col-form-label">PER HOUR</label>
                                                            <div className="">
                                                                <Input type="text" id="def-per-hour"
                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                    className="form-control form-control-sm" name="serviceHourlyRate"
                                                                    value={ratePln.serviceCallRate.serviceHourlyRate || ''}
                                                                    placeholder="0"
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <label htmlFor="def-min-hrs" className="form-label col-form-label">MINIMUM HOURS</label>
                                                            <div className="">
                                                                <Input placeholder="0" type="text" id="def-min-hrs" name="serviceMinHours"
                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                    className="form-control form-control-sm"
                                                                     value={ratePln.serviceCallRate.serviceMinHours || ''}
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <label htmlFor="def-increment" className="form-label col-form-label">INCREMENT</label>
                                                            <div className="">
                                                                <Input placeholder="0" type="text" id="def-increment"
                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                    className="form-control form-control-sm" name="serviceIncrementHours"
                                                                    value={ratePln.serviceCallRate.serviceIncrementHours || ''}
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="def-delay" className="form-label col-form-label">DELAY(HOURS)</label>
                                                            <div className="">
                                                                <Input placeholder="0" name="serviceFreeHours"
                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                    value={ratePln.serviceCallRate.serviceFreeHours || ''} 
                                                                    type="text" id="def-delay" className="form-control form-control-sm" onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }} />

                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <h6 className="text-bg-color">FROM</h6></div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <div className="custom-control custom-radio custom-control custom-radio">
                                                                            <input name="serviceStart" type="radio" id="rd-dispatch"
                                                                            disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                checked={constServiceStart.dispatch === ratePln.serviceCallRate.serviceStart}
                                                                                className="custom-control-input" onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'serviceStart', constServiceStart.dispatch)
                                                                                }} />
                                                                            <label title="" type="checkbox" htmlFor="rd-dispatch" className="custom-control-label">DISPATCH</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <div className="custom-control custom-radio custom-control custom-radio">
                                                                            <input name="serviceStart" type="radio" id="rd-enroute"
                                                                            disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                className="custom-control-input"
                                                                                checked={constServiceStart.enroute === ratePln.serviceCallRate.serviceStart}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'serviceStart', constServiceStart.enroute)
                                                                                }}
                                                                            />
                                                                            <label title="" type="checkbox" htmlFor="rd-enroute" className="custom-control-label">ENROUTE</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <div className="custom-control custom-radio custom-control custom-radio">
                                                                            <input name="serviceStart" type="radio" id="rd-arrived"
                                                                            disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                className="custom-control-input"
                                                                                checked={constServiceStart.arrived === ratePln.serviceCallRate.serviceStart}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'serviceStart', constServiceStart.arrived)
                                                                                }}
                                                                            />
                                                                            <label title="" type="checkbox" htmlFor="rd-arrived" className="custom-control-label">ARRIVED</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <h6 className="text-bg-color">TO</h6></div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <div className="custom-control custom-radio custom-control custom-radio">
                                                                            <input name="serviceEnd" type="radio" id="rd-hooked" className="custom-control-input"
                                                                                disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                checked={constServiceEnd.hooked === ratePln.serviceCallRate.serviceEnd}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'serviceEnd', constServiceEnd.hooked)
                                                                                }}
                                                                            />
                                                                            <label title="" type="checkbox" htmlFor="rd-hooked" className="custom-control-label">HOOKED</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <div className="custom-control custom-radio custom-control custom-radio">
                                                                            <input name="serviceEnd" type="radio" id="rd-dropped" className="custom-control-input"
                                                                                disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                checked={constServiceEnd.dropped === ratePln.serviceCallRate.serviceEnd}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'serviceEnd', constServiceEnd.dropped)
                                                                                }}
                                                                            />
                                                                            <label title="" type="checkbox" htmlFor="rd-dropped" className="custom-control-label">DROPPED</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <div className="custom-control custom-radio custom-control custom-radio">
                                                                            <input name="serviceEnd" type="radio" id="rd-cleared"
                                                                                disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                className="custom-control-input" checked={constServiceEnd.cleared === ratePln.serviceCallRate.serviceEnd}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'serviceEnd', constServiceEnd.cleared)
                                                                                }}
                                                                            />
                                                                            <label title="" type="checkbox" htmlFor="rd-cleared" className="custom-control-label">CLEARED</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <h6 className="text-blue text-bg-color">MILEAGE</h6></div>
                                                                <div className="">
                                                                    <h6 className="text-bg-color">LOADED</h6></div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">1ST</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" id="def-delay"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    className="form-control form-control-sm" name="serviceMileageLoadLimit1"
                                                                                      value={ratePln.serviceCallRate.serviceMileageLoadLimit1 || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" id="def-delay" className="form-control form-control-sm" name="serviceMileageLoadLimit1Charge"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                     value={ratePln.serviceCallRate.serviceMileageLoadLimit1Charge || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}

                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">NEXT</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" id="def-delay"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    className="form-control form-control-sm" name="serviceMileageLoadLimit2"
                                                                                    value={ratePln.serviceCallRate.serviceMileageLoadLimit2 || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" id="def-delay"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    className="form-control form-control-sm" name="serviceMileageLoadLimit2Charge"
                                                                                    value={ratePln.serviceCallRate.serviceMileageLoadLimit2Charge || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="def-delay" className="form-label col-form-label">EACH MILE AFTER</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" id="def-delay" name="serviceMileageLoadChargeAfterLimit"
                                                                        readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            className="form-control form-control-sm"
                                                                             value={ratePln.serviceCallRate.serviceMileageLoadChargeAfterLimit || ''}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                            }} validations={[isNumeric, isPercentage]}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h6 className="text-bg-color">UNLOADED</h6></div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">1ST</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" id="def-delay" className="form-control form-control-sm" name="serviceMileageUnloadLimit1"
                                                                                    value={ratePln.serviceCallRate.serviceMileageUnloadLimit1 || ''}
                                                                                    readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" required="" id="def-delay"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    className="form-control form-control-sm" name="serviceMileageUnloadLimit1Charge"
                                                                                     value={ratePln.serviceCallRate.serviceMileageUnloadLimit1Charge || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">NEXT</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" id="def-delay"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    className="form-control form-control-sm" name="serviceMileageUnloadLimit2"
                                                                                    value={ratePln.serviceCallRate.serviceMileageUnloadLimit2 || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="">
                                                                            <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                            <div className="">
                                                                                <Input placeholder="0" type="text" name="serviceMileageUnloadLimit2Charge" 
                                                                                id="def-delay" className="form-control form-control-sm"
                                                                                readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                    value={ratePln.serviceCallRate.serviceMileageUnloadLimit2Charge || ''}
                                                                                    onChange={(e) => {
                                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                    }} validations={[isNumeric, isPercentage]}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="def-delay" className="form-label col-form-label ">EACH MILE AFTER</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" id="def-delay"
                                                                            className="form-control form-control-sm" name="serviceMileageUnloadChargeAfterLimit"
                                                                            readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            value={ratePln.serviceCallRate.serviceMileageUnloadChargeAfterLimit || ''}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                            }} validations={[isNumeric, isPercentage]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h6 className="text-blue text-bg-color">FLAT DISCOUNT</h6></div>
                                                                <div className="">
                                                                    <label htmlFor="discount-rate" className="form-label col-form-label">RATE</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" name="flatDiscountRate"
                                                                            id="discount-rate" className="form-control form-control-sm"
                                                                            value={ratePln.serviceCallRateDiscount.flatDiscountRate || ''} 
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRateDiscount', e.target.name, e.target.value)
                                                                            }} validations={[ isCurrency]} requiredmsg="Enter Dollar Currency"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 mb-5">
                                        <div className="card box-shadow">
                                            <div className="card-header">
                                                <h5>Storage</h5></div>
                                            <div className="card-body" style={{ "height": "1020px" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <h6 className="text-blue text-bg-color">STORAGE PERIOD</h6></div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="storagePeriod" type="radio" id="storage-period-calendar-day" 
                                                                        className="custom-control-input"
                                                                        disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            checked={constStoragePeriod.calenderday === ratePln.serviceCallRate.storagePeriod}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'storagePeriod', constStoragePeriod.calenderday)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="storage-period-calendar-day" className="custom-control-label">CALENDAR DAY</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="storagePeriod" type="radio" id="storage-period-24-hours" className="custom-control-input"
                                                                            disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            checked={constStoragePeriod.hours24 === ratePln.serviceCallRate.storagePeriod}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'storagePeriod', constStoragePeriod.hours24)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="storage-period-24-hours" className="custom-control-label">24 HOURS</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="storagePeriod" type="radio" id="storage-period-custom"
                                                                        disabled={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            className="custom-control-input"
                                                                            checked={constStoragePeriod.custom === ratePln.serviceCallRate.storagePeriod}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'storagePeriod', constStoragePeriod.custom)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="storage-period-custom" className="custom-control-label">CUSTOM</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="form-group">
                                                            <h6 className="text-blue text-bg-color">IN STORAGE TIME</h6></div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-received" className="custom-control-input"
                                                                            checked={constInStorageTime.received === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.received)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-received" className="custom-control-label">RECEIVED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-dispatch" className="custom-control-input"
                                                                            checked={constInStorageTime.dispatch === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.dispatch)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-dispatch" className="custom-control-label">DISPATCH</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-accepted" className="custom-control-input"
                                                                            checked={constInStorageTime.accepted === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.accepted)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-accepted" className="custom-control-label">ACCEPTED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-enroute" className="custom-control-input"
                                                                            checked={constInStorageTime.enroute === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.enroute)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-enroute" className="custom-control-label">ENROUTE</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-arrival" className="custom-control-input"
                                                                            checked={constInStorageTime.arrival === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.arrival)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-arrival" className="custom-control-label">ARRIVAL</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-hooked" className="custom-control-input"
                                                                            checked={constInStorageTime.hooked === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.hooked)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-hooked" className="custom-control-label">HOOKED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-dropped" className="custom-control-input"
                                                                            checked={constInStorageTime.dropped === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.dropped)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-dropped" className="custom-control-label">DROPPED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-radio custom-control custom-radio">
                                                                        <input name="inStorageTime" type="radio" id="instorage-time-clear" className="custom-control-input"
                                                                            checked={constInStorageTime.clear === ratePln.serviceCallRate.inStorageTime}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', 'inStorageTime', constInStorageTime.clear)
                                                                            }}
                                                                        />
                                                                        <label title="" type="checkbox" htmlFor="instorage-time-clear" className="custom-control-label">CLEAR</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <h6 className="text-blue text-bg-color">STORAGE</h6></div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="">
                                                                    <label htmlFor="def-delay" className="form-label col-form-label">1ST</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" name="storageLimit1" id="def-delay" className="form-control form-control-sm"
                                                                            readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            value={ratePln.serviceCallRate.storageLimit1 || ''}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                            }} validations={[isNumeric, isPercentage]}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="">
                                                                    <label htmlFor="def-delay" className="form-label col-form-label">DAYS @</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" id="def-delay" className="form-control form-control-sm" name="storageLimit1Charge"
                                                                           readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            value={ratePln.serviceCallRate.storageLimit1Charge || ''}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                            }} validations={[isNumeric, isPercentage]}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="">
                                                                    <label htmlFor="def-delay" className="form-label col-form-label">NEXT</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" required="" id="def-delay" className="form-control form-control-sm" name="storageLimit2"
                                                                           readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            value={ratePln.serviceCallRate.storageLimit2 || ''}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                            }} validations={[isNumeric, isPercentage]}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="">
                                                                    <label htmlFor="def-delay" className="form-label col-form-label">DAYS @</label>
                                                                    <div className="">
                                                                        <Input placeholder="0" type="text" required="" id="def-delay" className="form-control form-control-sm" name="storageLimit2Charge"
                                                                           readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                            value={ratePln.serviceCallRate.storageLimit2Charge || ''}
                                                                            onChange={(e) => {
                                                                                this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                            }} validations={[isNumeric, isPercentage]}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <label htmlFor="def-delay" className="form-label col-form-label">EACH DAY AFTER</label>
                                                            <div className="">
                                                                <Input placeholder="0" type="text" required="" id="def-delay" className="form-control form-control-sm" name="storageChargeAfterLimit"
                                                                   readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                    value={ratePln.serviceCallRate.storageChargeAfterLimit || ''}
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }} validations={[isNumeric, isPercentage]}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="def-mxstorage-value" className="form-label col-form-label">MAXIMUM STORAGE VALUE</label>
                                                            <div >
                                                                <Input placeholder="0" type="text" name="maxStorage" id="def-mxstorage-value"
                                                                    className="form-control form-control-sm"
                                                                    value={ratePln.serviceCallRate.maxStorage}
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }} validations={[isNumeric, isPercentage]}
                                                                />

                                                            </div>
                                                        </div>
                                                        {
                                                            constStoragePeriod.custom === ratePln.serviceCallRate.storagePeriod ?
                                                                <>
                                                                    <div className="form-group">
                                                                        <h6 className="text-blue text-bg-color">CUSTOM PERIOD</h6></div>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="cp-hours" className="form-label col-form-label col-sm-7">HOURS</label>
                                                                        <div className="col-sm-5">
                                                                            <input placeholder="0" type="text" name="storageCustomPeriod" id="cp-hours" className="form-control form-control-sm"
                                                                                value={ratePln.serviceCallRate.storageCustomPeriod || ''}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                }} validations={[isNumeric, isPercentage]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="cp-storagr-delay" className="form-label col-form-label col-sm-7">STORAGE DELAY(HOURS)</label>
                                                                        <div className="col-sm-5">
                                                                            <input placeholder="0" type="text" name="freeHours" id="cp-storagr-delay" className="form-control form-control-sm"
                                                                               readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                value={ratePln.serviceCallRate.freeHours || ''}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                }} validations={[isNumeric, isPercentage]}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="cp-time" className="form-label col-form-label col-sm-7">TIME</label>
                                                                        <div className="col-sm-5">
                                                                            <select className="form-control form-control-sm" id="" placeholder="12.00 Am" name="threshold"
                                                                            readOnly={(ratePln.serviceCallRate.syncWithDefault||0)===0?false:true}
                                                                                value={parseInt(ratePln.serviceCallRate.threshold)}
                                                                                onChange={(e) => {
                                                                                    this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                                }}>
                                                                                <option value="">Select</option>
                                                                                <option value="0">12:00 AM</option>
                                                                                <option value="1">01:00 AM</option>
                                                                                <option value="2">02:00 AM</option>
                                                                                <option value="3">03:00 AM</option>
                                                                                <option value="4">04:00 AM</option>
                                                                                <option value="5">05:00 AM</option>
                                                                                <option value="6">06:00 AM</option>
                                                                                <option value="7">07:00 AM</option>
                                                                                <option value="8">08:00 AM</option>
                                                                                <option value="9">09:00 AM</option>
                                                                                <option value="10">10:00 AM</option>
                                                                                <option value="11">11:00 AM</option>
                                                                                <option value="12">12:00 PM</option>
                                                                                <option value="13">01:00 PM</option>
                                                                                <option value="14">02:00 PM</option>
                                                                                <option value="15">03:00 PM</option>
                                                                                <option value="16">04:00 PM</option>
                                                                                <option value="17">05:00 PM</option>
                                                                                <option value="18">06:00 PM</option>
                                                                                <option value="19">07:00 PM</option>
                                                                                <option value="20">08:00 PM</option>
                                                                                <option value="21">09:00 PM</option>
                                                                                <option value="22">10:00 PM</option>
                                                                                <option value="23">11:00 PM</option>
                                                                            </select>

                                                                        </div>
                                                                    </div>
                                                                </> : null
                                                        }
                                                        <div className="">
                                                            <h6 className="text-blue text-bg-color">DEFAULT</h6></div>
                                                        <div className="form-group row">
                                                            <label htmlFor="cp-load-default" className="form-label col-form-label col-sm-12">Load Default</label>
                                                            <div className="col-sm-12">
                                                                <Select className="form-control form-control-sm" id="" name="loadDefault"
                                                                    value={ratePln.serviceCallRate.loadDefault}
                                                                    onChange={(e) => {
                                                                        this.props.loadAccountDefaultRatePlan(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        this.props.defaultRatePlanList.sort(function(a,b){
                                                                            if(a.ratePlanName.toUpperCase() < b.ratePlanName.toUpperCase())
                                                                            return -1;
                                                                        }).map((item, k) => {
                                                                            return (<option key={k} value={item.ratePlanId}>{item.ratePlanName}</option>)
                                                                        })
                                                                    }

                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-8">
                                                                <div className="custom-control custom-checkbox custom-control custom-checkbox">
                                                                    <input type="checkbox" id="sync-with-default" className="custom-control-input" name="syncWithDefault"
                                                                        checked={ratePln.serviceCallRate.syncWithDefault === 0 ? false : true} onChange={(e) => {
                                                                            this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.checked ? 1 : 0)
                                                                            if(e.target.checked){
                                                                                this.props.loadAccountDefaultRatePlan(selectedRatePlan, 'serviceCallRate', 'loadDefault',ratePln.serviceCallRate.loadDefault )
                                                                            }
                                                                        }} />
                                                                    <label title="" type="checkbox" htmlFor="sync-with-default" className="custom-control-label">SYNC WITH DEFAULT</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={isManagement()?'invisible':""}>
                                                            <h6 className="text-blue text-bg-color">DRIVER COMMISSION</h6></div>
                                                        <div className={isManagement()?"form-group row invisible":"form-group row"}>
                                                            <label htmlFor="cp-commission-type-default" className="form-label col-form-label col-sm-12">Commission Type</label>
                                                            <div className="col-sm-12">
                                                                <Select className="form-control form-control-sm" id="" name="commissionType"
                                                                    value={ratePln.serviceCallRate.commissionType || ""} validations={isManagement()?[]:[required]}
                                                                    requiredmsg="Please select commission type"
                                                                    onChange={(e) => {
                                                                        this.props.accountRatePlanFieldChange(selectedRatePlan, 'serviceCallRate', e.target.name, e.target.value)
                                                                    }}>
                                                                    <option value="">SELECT</option>
                                                                    {
                                                                        this.props.commissionTypes.map((item, k) => {
                                                                            return (<option key={k} value={item.optionVal}>{item.optionTxt}</option>)
                                                                        })
                                                                    }

                                                                </Select>

                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <h6 className="text-blue text-bg-color mb-10">CUSTOM CHARGE</h6></div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-12">
                                                                <input type="button" className="btn btn-success" name="charges" disabled={(ratePln.serviceCallRate.charges || []).length === 3}
                                                                value="Add Custom Charge"  onClick={(e) => {
                                                                        this.props.addCustomCharge(selectedRatePlan, 'serviceCallRate', 'ratePlanAccountChargeMapping', {
                                                                            "recId":0,
                                                                            "chargeAmt":null,
                                                                            "chargeId": 0,
                                                                            'companyId': getCompanyId()
                                                                        })
                                                                    }}/>
                                                            </div>
                                                        </div>
                                                        {(ratePln.serviceCallRate.ratePlanAccountChargeMapping || []).length > 0 ?
                                                        this.createUI(ratePln,selectedRatePlan):null}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    </div>


                </div>
            </div>
           {isManagement()?<div className="row" style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-30px'}} >
                <div className="mr-3">
                    <button className="btn btn-sm btn-success pull-right" onClick={(e) => {
                        this.props.onAccountServiceCallRateNextClick(e);
                    }}>Next</button>
                </div>
{
    ((this.props.accountBasicInfo.accountId||0) !== 0)?
    <div className="mr-3">
                    <button className="btn btn-sm btn-success pull-right" onClick={(e) => {
                        e.preventDefault();
                        this.frmServiceCallRate.validateAll();
                        if (!isValidForm(this.frmServiceCallRate)) {
                        } else {
                            this.props.onTimePlanSubmit(e);
                        }
                    }} disabled={this.checkAccess()}>SAVE ACCOUNT</button>
                </div>:null
}
                
            </div>:null}</Fragment>)
    }
}
export default AccountServiceCallRate;
