import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import { isDecimal, required, isValidForm } from "../../../../util/validationMethods";
import { utilMethods } from '../../../../util/util'
import { constStoragePeriod } from '../../../../util/constants'
import { editPermission } from '../../../../util/customMethods'
import { log } from 'util';
//import Charges from './charges';
class DefaultRatePlanInfo extends Component {
    constructor() {
        super();
        this.state = {
            validated: false,
            customStatus: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkAccess = this.checkAccess.bind(this);
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.form.validateAll();
        if (!isValidForm(this.form)) {

        } else {
            this.props.onSubmitForm(event, this.props.rateplan)
        }
    }


    checkAccess = () => {
        if (this.props.rateplan_id <= 0) {
            if ((this.props.accessLevel.basicAccess.add || false) === true) {
                return false
            } else {
                return true
            }
        } else {
            if ((this.props.accessLevel.basicAccess.edit || false) === true) {
                return false;
            } else {
                return true;
            }
        }
    }
    getRatePlanName = () => {
        let idx = this.props.ratePlanList.findIndex((state, key) => {
            return this.props.rateplan.defaultRatePlanId === state.ratePlanId

        })
        if (idx === -1)
            return ''
        else
            return this.props.ratePlanList[idx].ratePlanName
    }

    customStatusChange = e => {
        console.log(e);
        if (e.target.value === "Custom") {
            this.setState({ customStatus: true }, () => { console.log(this.state.customStatus); })

        }
        else if (e.target.value === "Calander" || e.target.value === '24_Hours') {
            this.setState({ customStatus: false })
        }


    };


    render() {
        const { validated } = this.state;
        const { rateplan } = this.props;
        const { ratePlanList } = this.props;

        return (<Fragment>
            <div className="pd-10">
                <div className="row mb-10">
                    {/* <div className="col-md-12 col-sm-12 mb-5 text-right">
                        <button className="btn btn-success btn-sm mr-2" onClick={()=>{
                            this.props.history.push('rateplan');
                        }} > Search Rate Plan</button>
                        <button className="btn btn-success btn-sm pull-right" onClick={()=>{
                            this.props.history.push('defaultRateplanChargeListDetails');
                        }}> Charge List</button>
                    </div> */}

                    <Form ref={c => { this.form = c; }} onSubmit={this.handleSubmit}>
                        <fieldset disabled={this.checkAccess()||(!editPermission(rateplan.idCompany))}>
                            <div className="row mb-10">
                                <div className="col-md-12 col-sm-12 mb-5">
                                    <div className="card box-shadow">
                                        <div className="card-body">

                                            <div className=" form-group row">
                                                <div className="col">
                                                    <div className="">
                                                        <label htmlFor="rate-plan" className="form-label col-form-label">RATE PLAN</label>
                                                        <div className="">
                                                            {this.props.rateplan_id > 0 ?
                                                                (<input type="text" name="defaultRatePlanId" disabled={true} className="form-control" value={this.getRatePlanName()} />) :
                                                                (<Select className="form-control"
                                                                    name="defaultRatePlanId"
                                                                    value={rateplan.defaultRatePlanId}
                                                                    onChange={e => {
                                                                        this.props.onRatePlanSelect(
                                                                            e,
                                                                            e.currentTarget.name,
                                                                            e.currentTarget.value
                                                                        );
                                                                    }}
                                                                >
                                                                    <option value={""}>{"--Select Rate Plan--"}</option>
                                                                    {ratePlanList.map((state, key) => {
                                                                        return (
                                                                            <option key={key} value={state.ratePlanId}>
                                                                                {state.ratePlanName}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                    <option value={"new"}>{"New Rate Plan"}</option>
                                                                </Select>)


                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="">
                                                        <label htmlFor="name" className="form-label col-form-label">NAME</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                type="text"
                                                                name="defaultRatePlanName"
                                                                requiredmsg="Enter RatePlan Name"
                                                                value={rateplan.defaultRatePlanName || ''}
                                                                placeholder=""
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[required]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="">
                                                        <label htmlFor="tow-type" className="form-label col-form-label"
                                                        >TOW TYPE</label>
                                                        <div className="">
                                                            <Select className="form-control"
                                                                name="defaultRatePlanTowType"
                                                                requiredmsg="Please select Tow Type"
                                                                value={rateplan.defaultRatePlanTowType}
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[required]}
                                                            >
                                                                <option value={""}>{"--Select Tow Type--"}</option>
                                                                {this.props.towTypeList.map((state, key) => {
                                                                    return (
                                                                        <option key={key} value={state.towTypeId}>
                                                                            {state.towType}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Select>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col">
                                            <div className="">
                                                <label htmlFor="min-charge-per-service" className="form-label col-form-label">Minimum Charge per Service</label>
                                                <div className="">
                                                    <Input
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        name="minServiceCharge"
                                                        requiredmsg="Enter Min. Service Charge"
                                                        errormsg="Enter Dollar currency"
                                                        value={rateplan.minServiceCharge}
                                                        placeholder=""
                                                        onChange={e => {
                                                        this.props.onRatePlanInfoFieldChange(
                                                            e,
                                                            e.currentTarget.name,
                                                            e.currentTarget.value
                                                        );
                                                        }}
                                                        validations={[required, isDecimal]}
                                                    /> 
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>
                                            {/* <div className="row">

                                                <div className="col-md-3 offset-9">
                                                    <div className="text-right">
                                                        <button type="submit" className="btn btn-success btn-sm"> Save</button>
                                                        {/* <button type="button" className="btn btn-danger btn-sm mr-2"onClick={e => {
                                                    let confirmationMsg = window.confirm('Do you want to save Default Rate Plan?');
                                                    if(confirmationMsg){
                                                        this.props.onSubmitForm(e,this.props.rateplan)
                                                    }                        
                                                }}> Close</button>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={e => {
                                                    this.props.handleClearAll(e)
                                                }}> Clear</button> 
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8 col-sm-12 mb-5">
                                    <div className="card box-shadow">
                                        <div className="card-header">
                                            <h5>Service Rate</h5></div>
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col">
                                                    <div className="">
                                                        <h6 className="text-blue text-bg-color">FLAT RATE</h6></div>
                                                    <div className="form-group">
                                                        <label htmlFor="def-rate" className="form-label col-form-label">RATE</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceFlatRate"
                                                                type="text"
                                                                placeholder="0" 
                                                                requiredmsg="Please enter Flat Rate"
                                                                errormsg="Enter Dollar currency"
                                                                value={rateplan.serviceFlatRate || '   '}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[required, isDecimal]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <h6 className="text-blue text-bg-color">HOURLY RATE</h6></div>
                                                    <div className="">
                                                        <label htmlFor="def-per-hour" className="form-label col-form-label">PER HOUR</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceHourlyRate"
                                                                placeholder="0" 
                                                                type="text"
                                                                errormsg="Enter Dollar currency"
                                                                value={rateplan.serviceHourlyRate|| ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="def-min-hrs" className="form-label col-form-label">MINIMUM HOURS</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceMinHours"
                                                                errormsg="Enter digits only"
                                                                value={rateplan.serviceMinHours || ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                placeholder="0" 
                                                                type="text"
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="def-increment" className="form-label col-form-label">INCREMENT</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceIncrementHours"
                                                                placeholder="0" 
                                                                type="text"
                                                                errormsg="Enter Dollar currency"
                                                                value={rateplan.serviceIncrementHours || ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="def-delay" className="form-label col-form-label">DELAY(HOURS)</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceFreeHours"
                                                                errormsg="Enter digits only"
                                                                value={rateplan.serviceFreeHours || ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                placeholder="0" 
                                                                type="text"
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <h6 className="text-bg-color">FROM</h6></div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="serviceStart" checked={rateplan.serviceStart === 'Dispatch'} value="Dispatch" id="dispatchCtrl" className="custom-control-input"
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="dispatchCtrl" className="custom-control-label">DISPATCH</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="serviceStart" checked={rateplan.serviceStart === 'Enroute'} value="Enroute" id="enrouteCtrl" className="custom-control-input"
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="enrouteCtrl" className="custom-control-label">ENROUTE</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="serviceStart" checked={rateplan.serviceStart === 'Arrived'} value="Arrived" id="arricedCtrl" className="custom-control-input"
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="arricedCtrl" className="custom-control-label">ARRIVED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <h6 className="text-bg-color">TO</h6></div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="serviceEnd" checked={rateplan.serviceEnd === 'Hooked'} value="Hooked" id="hookedCtrl" className="custom-control-input"
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="hookedCtrl" className="custom-control-label">HOOKED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="serviceEnd" checked={rateplan.serviceEnd === 'Dropped'} value="Dropped" id="droppedCtrl" className="custom-control-input"
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="droppedCtrl" className="custom-control-label">DROPPED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="serviceEnd" checked={rateplan.serviceEnd === 'Cleared'} value="Cleared" id="clearedCtrl" className="custom-control-input"
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="clearedCtrl" className="custom-control-label">CLEARED</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <h6 className="text-blue text-bg-color">MILEAGE</h6></div>
                                                    <div className="">
                                                        <h6 className="text-bg-color">LOADED</h6></div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">1ST</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageLoadLimit1"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.serviceMileageLoadLimit1|| ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageLoadLimit1Charge"
                                                                        errormsg="Enter Dollar currency"
                                                                        value={rateplan.serviceMileageLoadLimit1Charge|| ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">Next</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageLoadLimit2"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.serviceMileageLoadLimit2|| ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageLoadLimit2Charge"
                                                                        errormsg="Enter Dollar currency"
                                                                        value={rateplan.serviceMileageLoadLimit2Charge|| ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="def-delay" className="form-label col-form-label"> EACH MILE AFTER</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceMileageLoadChargeAfterLimit"
                                                                errormsg="Enter Dollar currency"
                                                                value={rateplan.serviceMileageLoadChargeAfterLimit|| ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                placeholder="0" 
                                                                type="text"
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <h6 className="text-bg-color">UNLOADED</h6></div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">1ST</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageUnloadLimit1"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.serviceMileageUnloadLimit1 || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageUnloadLimit1Charge"
                                                                        errormsg="Enter Dollar currency"
                                                                        value={rateplan.serviceMileageUnloadLimit1Charge || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">Next</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageUnloadLimit2"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.serviceMileageUnloadLimit2 || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">MILES @</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="serviceMileageUnloadLimit2Charge"
                                                                        errormsg="Enter Dollar currency"
                                                                        value={rateplan.serviceMileageUnloadLimit2Charge || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        placeholder="0" type="text"
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="def-delay" className="form-label col-form-label">EACH MILE AFTER</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="serviceMileageUnloadChargeAfterLimit"
                                                                errormsg="Enter Dollar currency"
                                                                value={rateplan.serviceMileageUnloadChargeAfterLimit || ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                placeholder="0" type="text"
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12 mb-5">
                                    <div className="card box-shadow">
                                        <div className="card-header">
                                            <h5>STORAGE</h5></div>
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <h6 className="text-blue text-bg-color">STORAGE PERIOD</h6></div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="storagePeriod" checked={rateplan.storagePeriod === constStoragePeriod.calenderday} value={constStoragePeriod.calenderday} id="calendarCtrl" className="custom-control-input"
                                                                            // onClick={this.customStatusChange}
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="calendarCtrl" className="custom-control-label"
                                                                        >CALENDER DAY</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="storagePeriod" value={constStoragePeriod.hours24} id="hoursCtrl"
                                                                            checked={rateplan.storagePeriod === constStoragePeriod.hours24}
                                                                            className="custom-control-input"
                                                                            //onClick={this.customStatusChange}
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="hoursCtrl" className="custom-control-label"

                                                                        >24 HOURS</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group row">
                                                                <div className="col-sm-12">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" name="storagePeriod"
                                                                            checked={rateplan.storagePeriod === constStoragePeriod.custom} value={constStoragePeriod.custom} id="customCtrl" className="custom-control-input"
                                                                            // onClick={this.customStatusChange}
                                                                            onChange={e => {
                                                                                this.props.onRatePlanInfoFieldChange(
                                                                                    e,
                                                                                    e.currentTarget.name,
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label type="checkbox" htmlFor="customCtrl" className="custom-control-label"

                                                                        >
                                                                          CUSTOM


                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <h6 className="text-blue text-bg-color">STORAGE</h6></div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">1ST</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        placeholder="0" 
                                                                        type="text"
                                                                        name="storageLimit1"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.storageLimit1 || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">DAYS@</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="storageLimit1Charge"
                                                                        errormsg="Enter Dollar currency"
                                                                        placeholder="0" type="text"
                                                                        value={rateplan.storageLimit1Charge || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">Next</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        placeholder="0" type="text"
                                                                        name="storageLimit2"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.storageLimit2 || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">DAYS@</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        placeholder="0" type="text"
                                                                        name="storageLimit2Charge"
                                                                        errormsg="Enter Dollar currency"
                                                                        value={rateplan.storageLimit2Charge || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                      
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="def-delay" className="form-label col-form-label">EACH DAY AFTER</label>
                                                        <div className="">
                                                            <Input
                                                                className="form-control form-control-sm"
                                                                name="storageChargeAfterLimit"
                                                                placeholder="0" 
                                                                type="text"
                                                                errormsg="Enter Dollar currency"
                                                                value={rateplan.storageChargeAfterLimit || ''}
                                                                onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                onChange={e => {
                                                                    this.props.onRatePlanInfoFieldChange(
                                                                        e,
                                                                        e.currentTarget.name,
                                                                        e.currentTarget.value
                                                                    );
                                                                }}
                                                                validations={[isDecimal]}
                                                            />

                                                        </div>
                                                    </div>

                                                    {(rateplan.storagePeriod === constStoragePeriod.custom) ?
                                                        <>
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">EVERY(HOURS)</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="storageCustomPeriod"
                                                                        placeholder="0" type="text"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.storageCustomPeriod || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">STORAGE DELAY(HOURS)</label>
                                                                <div className="">
                                                                    <Input
                                                                        className="form-control form-control-sm"
                                                                        name="freeHours"
                                                                        placeholder="0" type="text"
                                                                        errormsg="Enter digits only"
                                                                        value={rateplan.freeHours || ''}
                                                                        onKeyPress={(e) => utilMethods.isNumeric(e)}
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}
                                                                        validations={[isDecimal]}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="def-delay" className="form-label col-form-label">TIME</label>
                                                                <div className="">
                                                                    <Select className="form-control"
                                                                        name="threshold"
                                                                        value={rateplan.threshold}
                                                                        onChange={e => {
                                                                            this.props.onRatePlanInfoFieldChange(
                                                                                e,
                                                                                e.currentTarget.name,
                                                                                e.currentTarget.value
                                                                            );
                                                                        }}

                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value="00">12:00 AM</option>
                                                                        <option value="01">01:00 AM</option>
                                                                        <option value="02">02:00 AM</option>
                                                                        <option value="03">03:00 AM</option>
                                                                        <option value="04">04:00 AM</option>
                                                                        <option value="05">05:00 AM</option>
                                                                        <option value="06">06:00 AM</option>
                                                                        <option value="07">07:00 AM</option>
                                                                        <option value="08">08:00 AM</option>
                                                                        <option value="09">09:00 AM</option>
                                                                        <option value="10">10:00 AM</option>
                                                                        <option value="11">11:00 AM</option>
                                                                        <option value="12">12:00 PM</option>
                                                                        <option value="13">01:00 PM</option>
                                                                        <option value="14">02:00 PM</option>
                                                                        <option value="15">03:00 PM</option>
                                                                        <option value="16">04:00 PM</option>
                                                                        <option value="17">05:00 PM</option>
                                                                        <option value="18">06:00 PM</option>
                                                                        <option value="19">07:00 PM</option>
                                                                        <option value="20">08:00 PM</option>
                                                                        <option value="21">09:00 PM</option>
                                                                        <option value="22">10:00 PM</option>
                                                                        <option value="23">11:00 PM</option>
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 offset-9">
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-success btn-sm"> Save</button>
                                        {/* <button type="button" className="btn btn-danger btn-sm mr-2"onClick={e => {
                                                                let confirmationMsg = window.confirm('Do you want to save Default Rate Plan?');
                                                                if(confirmationMsg){
                                                                    this.props.onSubmitForm(e,this.props.rateplan)
                                                                }                        
                                                            }}> Close</button>
                                                            <button type="button" className="btn btn-primary btn-sm" onClick={e => {
                                                                this.props.handleClearAll(e)
                                                            }}> Clear</button> */}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </Form>
                </div>
                
            </div>
        </Fragment>);
    }

}
export default DefaultRatePlanInfo;
