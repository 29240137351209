import {GET_ARSUMMARY_LIST} from './../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
import { getCompanyId } from '../../../util/customMethods';
export const getARSummaryDetailsList=(accountType, asOfDate)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`showAccountReceivableSummary_v2?access_token=${getAccessToken()}&companyId=${getCompanyId()}&accountType=${accountType}&asOfDate=${asOfDate}`);
            if(resp.status===200){
                dispatch({type:GET_ARSUMMARY_LIST,arSummaryObj:resp.data})
            }else{
                dispatch({type:GET_ARSUMMARY_LIST,arSummaryObj:undefined})
            }
        }catch(ex){
            dispatch({type:GET_ARSUMMARY_LIST,arSummaryObj:undefined})
        }
    }
}
export const getARSummaryDetailsClearList=()=>{
    return async (dispatch) => {
        dispatch({type:GET_ARSUMMARY_LIST,arSummaryObj:undefined})
    }
}