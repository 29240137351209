import {GET_MONETIZAION_LIST} from './../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
export const getMonetizationDetailsList=(asOfDate, idCompany)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`monetization?access_token=${getAccessToken()}&companyId=${idCompany}&asOfDate=${asOfDate}`);
            if(resp.status===200){
                dispatch({type:GET_MONETIZAION_LIST,monetizationObj:resp.data})
            }else{
                dispatch({type:GET_MONETIZAION_LIST,monetizationObj:undefined})
            }
        }catch(ex){
            dispatch({type:GET_MONETIZAION_LIST,monetizationObj:undefined})
        }
    }
}
export const getMonetizationDetailsClearList=()=>{
    return async (dispatch) => {
        dispatch({type:GET_MONETIZAION_LIST,monetizationObj:undefined})
    }
}

export const viewStatement=(asOfDate, idCompany,invoicesWithStatement)=>{
    return async (dispatch) => {
        try{
            const response = await getAxiosInstance(moduleTypes.REPORT).get(`billing/companyStatement?access_token=${getAccessToken()}&companyId=${idCompany}&asOfDate=${asOfDate}&invoicesWithStatement=${invoicesWithStatement}`,
            { headers: {'Accept': 'application/pdf'},
                responseType: 'arraybuffer',
            });
            if (response.status === 200) {
                let blob = new Blob([response.data], { type: 'application/pdf' } ),
                url = window.URL.createObjectURL(blob)
                window.open(url);
                return response.data;
             } else {
                 return response.data;
             }
        }catch(ex){
            return ex
        }
    }
}

export const sendCompanyStatement=(asOfDate, idCompany,invoicesWithStatement)=>{
    return async (dispatch) => {
        try{
            const response = await getAxiosInstance(moduleTypes.REPORT).get(`billing/sendCompanyStatement?access_token=${getAccessToken()}&companyId=${idCompany}&asOfDate=${asOfDate}&invoicesWithStatement=${invoicesWithStatement}`,
            );
                 return response.data;
        }catch(ex){
            return ex
        }
    }
}

