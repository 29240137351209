import * as actionTypes from '../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {isManagement,getCompanyId} from '../../../util/customMethods';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
export const fetchAllDriverList=()=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`/get/driverIDsList/${getCompanyId()}/${isManagement()}/?access_token=${getAccessToken()}`);
            if(resp.status===200){
                dispatch({type:actionTypes.GET_DRIVER_LIST,driverList:resp.data})
            }else{
                dispatch({type:actionTypes.GET_DRIVER_LIST,driverList:undefined})
            }
        }catch(ex){
            dispatch({type:actionTypes.GET_DRIVER_LIST,driverList:undefined})
        }
    }
}

export const fetchAllTruckList=()=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`/get/truckIDsList/${getCompanyId()}/${isManagement()}/?access_token=${getAccessToken()}`);
            if(resp.status===200){
                dispatch({type:actionTypes.GET_TRUCK_LIST,truckList:resp.data})
            }else{
                dispatch({type:actionTypes.GET_TRUCK_LIST,truckList:undefined})
            }
        }catch(ex){
            dispatch({type:actionTypes.GET_TRUCK_LIST,truckList:undefined})
        }
    }
}

export const retriveAllInvoiceReport = (obj,isManagement) => {
    const _url = `retriveAllInvoiceReport/${isManagement}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.REPORT).post(_url,obj,{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 200) {
                 dispatch({
                     type: actionTypes.GET_REPORTS_DATA,
                     reportData:response.data.response.allInvoices||[]
                 });
                 return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}

            } else {
                dispatch({
                    type: actionTypes.GET_REPORTS_DATA,
                    reportData: undefined
                });
                return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
            }
        }
        catch (error) {
            dispatch({
                type: actionTypes.GET_REPORTS_DATA,
                reportData: undefined
            });
            return {statusCode:500,statusMsg:"An unexpected error occurred."}
        }
    }
}

export const requestInvoiceReportLink = (obj,isManagement) => {
    const _url = `allInvoices/requestInvoiceReportLink/${isManagement}?access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try {
            const response = await getAxiosInstance(moduleTypes.REPORT).post(_url,obj,{
                headers: {
                    'Content-Type': 'application/json',
                }});
            if (response.status === 202) {
                 return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}

            } else {
                return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
            }
        }
        catch (error) {
            return {statusCode:500,statusMsg:"An unexpected error occurred."}
        }
    }
}