import * as actionTypes from '../../../actions/actionTypes';
const defaultObject={
    invoiceManageReportData:[]
}
export const invoiceManageReducer = (state = defaultObject, action) => {
    switch (action.type) {
      case actionTypes.GET_INVOICE_MANAGE_DATA:
        return {...state ,invoiceManageReportData:(action.invoiceManageReportData||defaultObject.invoiceManageReportData)};  
      default:
        return state;
    }
  };