import * as actionTypes from '../../actionTypes';
import { moduleTypes } from '../../../util/constants';
import { getAccessToken } from '../../authenticateActions'
import { getAxiosInstance } from '../../../util/service';
export const getsummaryDataReport = (obj, status) => {
    return async (dispatch) => {
        try {
             const _url = `/summaryByType/${obj.name}/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&typeId=${obj.id}&accountId=${obj.accountId}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if (response.status === 200) {
                dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: response.data.response || [] })
            } else {
                dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: undefined })
            }
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}

export const getResponseReport = (obj, status) => {
    return async (dispatch) => {
        try {
             const _url = `/getResponseTimeDetails/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&lateOnly=${obj.lateOnly}&empId=${obj.empId}&accountId=${obj.accountId}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if (response.status === 200) {
                dispatch({ type: actionTypes.GET_RESPONSE_REPORT, responseReport: response.data.response || [] })
            } else {
                dispatch({ type: actionTypes.GET_RESPONSE_REPORT, responseReport: undefined })
            }
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_RESPONSE_REPORT, responseReport: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}
export const cleanResponseReport = () => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_RESPONSE_REPORT, responseReport: undefined })
    }
}

export const getsummaryDetailsReport = (obj, status) => {
    return async (dispatch) => {
        try {
             const _url = `/summaryDetailsByType/${obj.name}/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&typeId=${obj.typeId}&accountId=${obj.accountId}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if (response.status === 200) {
                dispatch({ type: actionTypes.GET_SUMMARY_DETAILS_REPORT, summaryDetailsData: response.data.response || [] })
            } else {
                dispatch({ type: actionTypes.GET_SUMMARY_DETAILS_REPORT, summaryDetailsData: undefined })
            }
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_SUMMARY_DETAILS_REPORT, summaryDetailsData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}
export const cleanSummaryDataReport = () => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_SUMMARY_DETAILS_REPORT, summaryDetailsData: undefined })
    }
}

export const cleanSummaryDetailsReport = () => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: undefined })
    }
}

export const emailSummaryByType = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/emailsummaryByType/${obj.name}/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&typeId=${obj.id}&accountId=${obj.accountId}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}

export const emailResponseReport = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/emailResponseTimeDetails/${obj.idCompany}/${status}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&lateOnly=${obj.lateOnly}&empId=${obj.empId}&accountId=${obj.accountId}`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_SUMMARY_REPORT, summaryData: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}