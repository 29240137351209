import * as actionTypes  from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';

export const getAllTowTypes=(searchObject)=>{
    if(!searchObject){
        searchObject={searchQuery:''}
    }
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).get(`truck/searchTowType?searchQuery=${searchObject.searchQuery}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`);
            if(resp.status===200){
                dispatch({ 
                    type: actionTypes.GET_ALL_TOW_TYPES,
                    towTypes:resp.data})
            }else{
                dispatch({ type: actionTypes.GET_ALL_TOW_TYPES,
                    towTypes:[]})
            }
        }catch(ex){
            dispatch({ type: actionTypes.GET_ALL_TOW_TYPES,
                towTypes:undefined})
        }
    }
}
export const getTowType=(id)=>{
    return (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
       dispatch({
        type: actionTypes.GET_TOWTYPE_RECORD,
        towTypeObject:getTowTypeRecFromStore(id)
    });
    //dispatch(spinnerActions.hideSpinner());
    }
}
export const clearTowType=()=>{
    return (dispatch) => {
       // dispatch(spinnerActions.showSpinner());
       dispatch({
        type: actionTypes.CLEAR_TOWTYPE_RECORD,
        towTypeObject:undefined
    });
   // dispatch(spinnerActions.hideSpinner());
    }
}
export const changeTowTypeField=(recObject)=>{
    return (dispatch)=>{
         dispatch({
            type: actionTypes.GET_TOWTYPE_RECORD,
            towTypeObject:recObject
        }); 
    }
}
export const saveTowType=(typeObj)=>{
    return async(dispatch) => {
        let _url=`truck/add/towType?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        //dispatch(spinnerActions.showSpinner());
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).post(_url,typeObj);
            if(resp.data.statusCode===200){
                return resp.data;                   
            }else{
                return {statusCode:null,statusMsg:resp.data.message}
            }    
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}
        }
    }
}
const getTowTypeRecFromStore=(aid)=>{
    let _auctionObj={}
    if( Object.keys((store.getState()).towTypeList).length>0){
        _auctionObj= (store.getState()).towTypeList.towTypes.find(obj=>{
           return obj.towTypeId===aid;
       });
       return _auctionObj;    
}}