import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
class AccountContactReportCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                    <Form ref={c => {
                                        this.formAccountContactReportInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                        <div className="col-2 form-group">
                                            <select className="form-control" >
                                                <option>Account Type</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <input type="text" className="form-control"
                                                name="invoiceId" value={''} placeholder="Account Name"
                                            />
                                        </div>
                                        <div className="ml-2 mr-2">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.asofdate === "" || this.props.asofdate === null
                                                ? null
                                                : moment(
                                                    this.props.asofdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="asOfDate"
                                        reqmsg="As of date is required"
                                        onDateSelectChange={e => {
                                            this.props.onAccountContactFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-5 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            >Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" >Email</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" >Print</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default AccountContactReportCtrl;