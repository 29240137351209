import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Pie} from 'react-chartjs-2';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import TmExportExcel from '../../../../utilComponents/tmExportExcel/tmExportExcel';
import {utilMethods} from '../../../../../util/util';
import queryString from 'query-string';

let _ = require('underscore');
let graphColors;
let graphOutlines;
let hoverColor;
let filterData=[];
class CommissionsChart extends Component{
    constructor(props){
        super(props);
        this.state={
            visibleDrillDown:false,
            tableData:[],
            excelDataSet:[],
            fromDate:'',
            toDate:'',
            chartObj1:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                    ],
                    hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                    ]
                }]
            },
            optText: ''
        } 
        this.backToFirstLevel = this.backToFirstLevel.bind(this);     
    }
    componentWillMount(){
        this.commissionDetails();    
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.setState({fromDate:values.from, toDate:values.to})
      }
    backToFirstLevel(event){      
        event.preventDefault();
        this.setState({visibleDrillDown:false,tableData:[],optText:'',excelDataSet:[]},
            this.commissionDetails()
        );      
    }
    commissionDetails=()=>{
        let data = this.props.reportDashboard.commissions||[];
        
        let _chartObj1=this.state.chartObj1;            
        _chartObj1.labels=[];
        _chartObj1.datasets[0].data=[];
        data.forEach(item=>{
            _chartObj1.labels.push(item.department);
            _chartObj1.datasets[0].data.push(item.commission);
        })
        this.setState({chartObj1:_chartObj1})
    }
    
    handleElementClick(evt){
        if(evt.length>0){
            const _label=evt[0]._chart.active[0]._view.label;
            const idx=(this.props.reportDashboard.commissions||[]).findIndex((item)=>{
                return item.department==_label
            })
            if(idx>=0){
                const filterData=this.props.reportDashboard.commissions[idx].commissionByDepartment||[]
                this.setState({visibleDrillDown:true, optText:_label,tableData:filterData,excelDataSet:filterData });
            }else{
                this.setState({visibleDrillDown:true, optText:_label,tableData:[],excelDataSet:[] });

            }   
            
        }  
    }
    buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'First Name'},{title:'Last Name'},{title:'Tow Count'},{title:'Commission'}],
          data: this.state.excelDataSet.map((item)=>{
            return [{value:item.firstName || ''},{value:item.lastName || ''},
            {value:item.towCount || ''},
            {value:item.commission || ''}]
          })
        }])
      }
    render(){
        const options1={
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'Department Wise Commission'
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data){
                        var html = "";
                        for(var dataset in data.datasets){
                            html += ` ${data.labels[tooltipItem.index]} : $ ${utilMethods.convertToFloat(data.datasets[dataset].data[tooltipItem.index])}`;
                        }
                        return html;
                    }
                },
            }     
        }
        return(
            <>
            <div className="page-header">
                  <div className="row mb-10">
                      <div className="col-md-12 col-sm-12">
                          <div className="title">
                          <h4>Duration: {this.state.fromDate === this.state.toDate ? `${this.state.fromDate}` : `${this.state.fromDate} To ${this.state.toDate}`}</h4>
                          </div>
                      </div>
                  </div>
              </div>
               <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
               <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <div>
                    {this.state.visibleDrillDown === false ?
                    <Link class="btn btn-info" to="/reports-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>:
                    <button class="btn btn-info" onClick={this.backToFirstLevel}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>}
                    </div>
                    </div>
                    </div>
            <div class="row"> 
            {this.state.visibleDrillDown === false ?
                <div class="col-md-8 offset-2">
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>           
                        <Pie height="440" data={this.state.chartObj1} onElementsClick={(evt)=>this.handleElementClick(evt)} options={options1}/> 
                    </div>
                </div>:
                <div class="col-md-8 offset-2">           
                    <div className="row"> 
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-10">
                                <h2>{`${this.state.optText} Department Commission Details`}</h2>
                                </div>
                                <div className="col-md-2">
                                <TmExportExcel name={`${this.state.optText} Department Commission Details`} 
                                filename={`${this.state.optText}_Department_Commission_Details`} 
                                element={<button className="btn btn-warning btn-sm mr-15"><i className="fa fa-file-excel-o"></i> Export</button>}
                                dataSet={this.buildDataFormatForExport()} />                            
                                </div>
                            </div>                        
                        </div>
                        <div className="col-md-12">
                            <ReactTable ref={(t)=>this.expiryTable=t}
                                data={this.state.tableData}
                                columns={[
                                    {
                                        Header:'First Name',
                                        accessor:'firstName'
                                    },
                                    {
                                        Header:'Last Name',
                                        accessor:'lastName'
                                    },{
                                        Header:'Tow Count',
                                        accessor:'towCount'
                                    },{
                                        Header:'Commission',
                                        accessor:'commission'
                                    }                            
                                ]}
                                onFilteredChange={()=>{
                                    let disableAccounts=this.expiryTable.getResolvedState().sortedData;
                                    this.setState({excelDataSet:disableAccounts});
                                }}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                filterable="true"
                                defaultFilterMethod={(filter, row)=> {
                                    const id = filter.pivotId || filter.id;
                                    return (
                                    row[id] !== undefined ?
                                    utilMethods.isNumber(row[id])? (row[id].toString()).includes(filter.value):
                                        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                                    :
                                        true
                                    )}}
                                />

                        </div>
                    </div>
                    </div>} 
                </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(CommissionsChart);