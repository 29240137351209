import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import ReactSelect from '../../utilComponents/tmSlimSelect/reactSelect';
import { isManagement } from '../../../util/customMethods';
import {
    required
} from "../../../util/validationMethods.js";
class CRDetailCtrl extends Component {
	constructor() {
		super();
		this.state = {
		}
    }
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formCRDetailInfo = c;
                                    this.props.initCallBack(c);
                                }} autocomplete="off">
                                    <div className="form-row">
                                        {isManagement()?<div className="col-4 form-group">
                                              <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    options={this.props.childCompList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.idCompany || '').toString()),
                                                        label: (this.props.childCompList.find((e) => e.optionVal == (this.props.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onCRDetailFieldChange('idCompany', parseInt(selectedOption.value));
                                                    }}
                                                />
                                        </div>:null}
                                        <Select className="form-control form-control-sm" name="companyType" value={this.props.companyType}
                                            onChange={(e)=>this.props.onCRDetailFieldChange(e.target.name, e.target.value)}>
                                            <option value={""}>{"Select Type"}</option>
                                                    {(this.props.companyTypes.length>0) && this.props.companyTypes.map((value, key) => {
                                                        return (
                                                            <option key={key} value={value}>
                                                                {value}
                                                            </option>
                                                        );
                                                    })}
                                            </Select>
                                        <div className="ml-2 mr-2 form-group">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                            <TmDateTimePicker
                                            className="form-control"
                                            selectedDate={
                                                this.props.asofdate === "" || this.props.asofdate === null
                                                    ? null
                                                    : moment(
                                                        this.props.asofdate,
                                                        GlobalValues.dateFormat
                                                    )._d
                                            }
                                            dateFormat={GlobalValues.dateFormat}
                                            validationArray={[required]}
                                            showYearDropdown={true}
                                            showmonthdropdown={true}
                                            name="asOfDate"
                                            reqmsg="As of date is required"
                                            onDateSelectChange={e => {
                                                this.props.onCRDetailFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                this.forceUpdate()
                                            }} 
                                        />
                                    </div>
                                        </div>
                                        <div className="col-4 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            onClick={(e)=>this.props.getCRDetails(e)}>Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" 
                                            onClick={(e)=>this.props.exportCRDetailsToExcel(e)}>Export</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            onClick={(e)=>this.props.printCRDetailsReport(e)}>Print</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div></div>       
            </Fragment>
        );
    }
}
export default CRDetailCtrl;