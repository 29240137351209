import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import {
    required
} from "../../../util/validationMethods.js";
class ARSummaryCtrl extends Component {
	constructor() {
		super();
		this.state = {
			
		}
    }
    render() {
        //const {accountReceivableSummaryDetails}=this.props.arSummaryList;
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formARSummaryInfo = c;
                                    this.props.initCallBack(c);
                                }} autocomplete="off">
                                    <div className="form-row">
                                        <div className="col-3 form-group">
                                        <Select className="form-control form-control-sm" name="accountType" value={this.props.accountType}
                                        onChange={(e)=>this.props.onSummaryFieldChange(e.target.name, e.target.value)}>
                                        <option value="">Acccount Type</option>
                                        {
                                            this.props.accountTypes.map((atype, k) => {
                                            return <option key={k} value={atype.optionVal}>{atype.optionTxt}</option>
                                            })
                                        }
                                        </Select>
                                        </div>
                                        <div className="col-1 form-group text-right">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of</label>
                                        </div>
                                        <div className="col-3 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.asofdate === "" || this.props.asofdate === null
                                                ? null
                                                : moment(
                                                    this.props.asofdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="asOfDate"
                                        reqmsg="As of date is required"
                                        onDateSelectChange={e => {
                                            this.props.onSummaryFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-5 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" 
                                            onClick={(e)=>this.props.getARSummaryDetails(e)}>Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" >Email</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" >Print</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default ARSummaryCtrl;