import {GET_BATCHPAYMENT_LIST} from './../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
//import { getCompanyId } from '../../../util/customMethods';
export const getBatchPaymentList=(accountId, idCompany)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`showInvoicesDetails?access_token=${getAccessToken()}&companyId=${idCompany}&accountId=${accountId}`);
            if(resp.status===200){
                dispatch({type:GET_BATCHPAYMENT_LIST,bpDetailsObj:resp.data})
            }else{
                dispatch({type:GET_BATCHPAYMENT_LIST,bpDetailsObj:undefined})
            }
        }catch(ex){
            dispatch({type:GET_BATCHPAYMENT_LIST,bpDetailsObj:undefined})
        }
    }
}
export const getBatchPaymentClearList=()=>{
    return async (dispatch) => {
        dispatch({type:GET_BATCHPAYMENT_LIST,bpDetailsObj:undefined})
    }
}
export const postMultipleInvoicePayments=(object)=>{
    return async (dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        let _url=`updateInvoicePayment?access_token=${getAccessToken()}`;
        try{
            const response=await getAxiosInstance(moduleTypes.REPORT).post(_url,object)
            if(response.status===200) {
                return {statusCode:response.status, statusMsg:response.data.message};
            } else{                
                return {statusCode:null, statusMsg:response.data.message};
                //dispatch(getAuctionList());                    
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
          //dispatch(spinnerActions.hideSpinner());
          return {statusCode:null, statusMsg:error.message};
        }
    }
}
