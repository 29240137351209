import {createStore, compose, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import { persistStore } from 'redux-persist'

/* 
export default function configureStore(initialState) {
  const middewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunkMiddleware,
  ];

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middewares)
    )
  );
} */
const middewares = [
  thunkMiddleware,
];
let store =createStore(rootReducer, {}, compose(
  applyMiddleware(...middewares)
  )
);
let persistor = persistStore(store);
export { store, persistor }


