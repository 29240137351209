import React, { Component,Fragment } from 'react';
import ItemInfo from './itemInfo';
import {isManagement} from '../../../../util/customMethods';
class DashboardPermissions extends Component {
    constructor(props){
        super(props);
        this.checkAccess=this.checkAccess.bind(this);
    }
    checkAccess=()=>{
        if(this.props.empRec.employeeInformation.employeeId===0){
            if((this.props.accessLevel.basicAccess.add||false)===true){
                return false
            }else{
                return true
            }
        }else{
            if((this.props.accessLevel.basicAccess.edit||false)===true){
                return false;
            }else{
                return true;
            }
        }
    }
    formatDashboardObject=(dashboardAccess)=>{
        const propName="module";
        return dashboardAccess.reduce((a, b) => {
            (a[b[propName]] = a[b[propName]] || []).push(b);
            return a;
         }, {});
    }

    render(){  
        const {dashboardAccess} =this.props.empRec;
        const dashboardObj=this.formatDashboardObject(dashboardAccess);
        if(isManagement() && dashboardObj.Admin!=undefined)
        delete dashboardObj.Admin;
        return(<Fragment>
        <div className="pd-10">
            <div className="row">
            {
                Object.keys(dashboardObj).map((keyName,key)=>{
                    return(
                        <>
                        <div className="col-md-12" key={key}>
                            <h6 className="text-blue text-bg-color mb-10">{keyName === 'Dispatcher' ? 'Summary' : keyName}</h6>
                            </div>
                            {
                                dashboardObj[keyName].map((item,k)=>{
                                    return (
                                        <ItemInfo disabled={this.checkAccess()} key={k} itemData={item} onItemSelectionChange={(changedItem)=>{
                                            this.props.onItemPermissionChange(changedItem);
                                        }} />
                                    )
                                })
                            }                        
                        </>
                    )
                })
            }
            </div>
            <div className="row">
                        <div className="col-md-12 col-sm-12 text-right">
                            <button className="btn btn-sm btn-primary"
                            disabled={this.checkAccess()}
                             onClick={(e)=>{
                                 this.props.onSubmitEmployee(e);
                            }}>Save</button>
                        </div>
                    </div>            
        </div>    
        </Fragment>);
    }
}
export default DashboardPermissions