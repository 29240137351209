import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { required, isValidForm } from '../../../../util/validationMethods';

class Acc_Link_Info extends Component {
    state = {
        validated: false
    }
    handleContactSubmit(event) {
        this.form.validateAll();
        if (!isValidForm(this.form)) {
        } else {
            this.props.onSubmit(event);
        }
    }
    render() {
        return (<Fragment>
            <Form ref={c => { this.form = c }}>
                <div className="form-group">
                    <label htmlFor="contactName" className="form-label">ARS GROUP NAME</label>
                    <Input name="gp_name" value={this.props.Acc_ARSObj.gp_name} type="text" className="form-control form-control-sm"
                        requiredmsg="Please enter the group Name"
                        onChange={(e) => {
                            this.props.onFieldChange(e.target.name, e.target.value);
                        }} validations={[required]} />

                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                        <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                            this.props.handleClose(e)
                        }}>{'Cancel'}</button>
                        <button type="button" className="btn btn-success pull-right btn-sm" onClick={(e) => {
                            this.handleContactSubmit(e)
                        }}>GENERATE LINK</button>
                    </div>
                </div>
            </Form>
        </Fragment>);
    }
}
export default Acc_Link_Info;