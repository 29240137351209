import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import moment from 'moment'
import { GlobalValues } from '../../../util/constants';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import TmDateTimePicker from '../../../components/utilComponents/tmDateTimePicker/tmDateTimePicker';
import TmDateTimePickers from '../utilDispatch/tmDateTimePicker/tmDateTimePicker';
import Select from 'react-validation/build/select';
import { reqReleaseInfo } from "../../../util/validationMethods";
import { isManagement } from "../../../util/customMethods";

class ReleaseAuction extends Component {
    render() {
        const { _invoiceObj, accountList } = this.props.invoiceInfo || {};
        const { invoiceRelease, invoiceTitleProcess, auctionListInvoice ,invoiceBilling} = _invoiceObj;
        const isAbleToRelease = !(this.props.invoicePermission.edit||false);
        return (<Fragment>
            <div className="pd-10">
                <Form ref={c => {
                    this.frm = c;
                    this.props.releaseCallBack(c);
                }} className="">
                    <fieldset disabled={invoiceBilling.isVerifiedAndLocked==1||(_invoiceObj.callStatus=='Cancel')}>
                        <div className="row">
                            <div className={!isManagement()?"col-md-12":"col-md-6"}>
                                <h6 className="text-blue text-bg-color form-group" style={{ margin: '0' }}>Release</h6>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">NAME</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" 
                                                    name="releaseName" value={invoiceRelease == null ? '' : invoiceRelease.releaseName}
                                                    onChange={(e) => { this.props.handleReleaseChange(e.target.name, e.target.value) }}
                                                    validations={[reqReleaseInfo]} isRelease={this.props.isRelease} requiredmsg="Please Enter Release Name."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">RELEASED TO</label>
                                            <div className="">
                                                <ReactSelect 
                                                    name="releaseTo"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isDisabled={invoiceBilling.isVerifiedAndLocked==1}
                                                    options={accountList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: invoiceRelease == null ? '' : invoiceRelease.releaseTo || '',
                                                        label: invoiceRelease == null ? '' : invoiceRelease.releaseAccountName || ''
                                                    }}
                                                    onChange={(e) => {
                                                        this.props.handleReleaseChange('releaseTo', e.value)
                                                        this.props.handleReleaseChange('releaseAccountName', e.label)
                                                    }}
                                                />
                                                <Input hidden={true} value={invoiceRelease == null ? '' : invoiceRelease.releaseTo || ''}
                                                    validations={[reqReleaseInfo]} isRelease={this.props.isRelease} requiredmsg="Please Select Release To Name."></Input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">RELEASE DATE/TIME</label>
                                            <div className="">
                                                <TmDateTimePickers selectedDate={
                                                    (invoiceRelease == null)
                                                        ? null
                                                        : (invoiceRelease.releaseDate || "") == "" ? null : moment(moment(invoiceRelease.releaseDate, GlobalValues.dateTimeFmtForDispatch)
                                                            ,
                                                            GlobalValues.dateTimeFormat
                                                        )._d
                                                }
                                                    onDateSelectChange={e => {
                                                        this.props.handleReleaseChange('releaseDate', e === null ? '' : moment(e).format(GlobalValues.dateTimeFmtForDispatch))
                                                    }}
                                                    showTimeSelect={true}
                                                    dateFormat={GlobalValues.dateTimeFormat}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                />
                                                <Input value={invoiceRelease == null ? '' : invoiceRelease.releaseDate || ''} hidden={true} name='releaseDate' isRelease={this.props.isRelease} validations={[reqReleaseInfo]} requiredmsg="Please Select Release Date."></Input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button type="button" disabled={(_invoiceObj.isDispatchCleared != 1|| isAbleToRelease)} className="btn btn-success btn-sm mr-10" onClick={this.props.releaseCurrentInvoice}>RELEASE</button>
                                        <button type="button" disabled={((invoiceRelease || null) == null) ||(_invoiceObj.isDispatchCleared != 1)} className="btn btn-success btn-sm" onClick={this.props.returnCurrentInvoice}>RETURN TO STORAGE</button>
                                    </div>
                                </div>

                            </div>
                            {isManagement() ?<div className="col-md-6">
                                <h6 className="text-blue text-bg-color form-group" style={{ margin: '0' }}>Title Process</h6>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">AA FILE#</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="aaFile" value={this.props.fileName} requiredmsg="Please enter Account Name"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('invoiceVehicle', e.target.name, e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">AVR SENT DATE</label>
                                            <div className="">
                                                <TmDateTimePicker selectedDate={
                                                    (invoiceTitleProcess == null)
                                                        ? null
                                                        : (invoiceTitleProcess.avrDate || "") == "" ? null : moment(moment(invoiceTitleProcess.avrDate, GlobalValues.dispatcherDatefmt)
                                                            ,
                                                            GlobalValues.dateFormat
                                                        )._d
                                                }
                                                    onDateSelectChange={e => {
                                                        this.props.handleTitleProcess('avrDate', e === null ? '' : moment(e).format(GlobalValues.dispatcherDatefmt))
                                                    }}
                                                    showTimeSelect={false}
                                                    dateFormat={GlobalValues.dateFormat}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">TRANSFER OF AUTHORIZATION DATE</label>
                                            <div className="">
                                                <TmDateTimePicker selectedDate={
                                                    (invoiceTitleProcess == null)
                                                        ? null
                                                        : (invoiceTitleProcess.transAuthDate || "") == "" ? null : moment(moment(invoiceTitleProcess.transAuthDate, GlobalValues.dispatcherDatefmt)
                                                            ,
                                                            GlobalValues.dateFormat
                                                        )._d
                                                }
                                                    onDateSelectChange={e => {
                                                        this.props.handleTitleProcess('transAuthDate', e === null ? '' : moment(e).format(GlobalValues.dispatcherDatefmt))
                                                    }}
                                                    showTimeSelect={false}
                                                    dateFormat={GlobalValues.dateFormat}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">TITLED DATE</label>
                                            <div className="">
                                                <TmDateTimePicker selectedDate={
                                                    (invoiceTitleProcess == null)
                                                        ? null
                                                        : (invoiceTitleProcess.titleDate || "") == "" ? null : moment(moment(invoiceTitleProcess.titleDate, GlobalValues.dispatcherDatefmt)
                                                            ,
                                                            GlobalValues.dateFormat
                                                        )._d
                                                }
                                                    onDateSelectChange={e => {
                                                        this.props.handleTitleProcess('titleDate', e === null ? '' : moment(e).format(GlobalValues.dispatcherDatefmt))
                                                    }}
                                                    showTimeSelect={false}
                                                    dateFormat={GlobalValues.dateFormat}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>:null}
                            {/* {isManagement() ?<div className="col-md-4">
                                <h6 className="text-blue text-bg-color form-group" style={{ margin: '0' }}>Auction Information</h6>
                                <div className="row ">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">AUCTION LIST</label>
                                            <div className="">
                                                <Select className="form-control form-control-sm" value={auctionListInvoice == null ? "" : auctionListInvoice.auctionListId} name="list" onChange={(e) => { this.props.handleAuctionSelect(parseInt(e.target.value)) }}>
                                                    <option value={""}>{"select Auction"}</option>
                                                    {(_invoiceObj.auctionLists || []).map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.auctionListId}>{val.auctionListName}</option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">AUCTION DATE</label>
                                            <div className="">
                                                <TmDateTimePicker selectedDate={
                                                    (auctionListInvoice == null)
                                                        ? null
                                                        : (auctionListInvoice.auctionDate || "") == "" ? null : moment(moment(auctionListInvoice.auctionDate, GlobalValues.dispatcherDatefmt)
                                                            ,
                                                            GlobalValues.dateFormat
                                                        )._d
                                                }
                                                    disabled={true}
                                                    showTimeSelect={false}
                                                    dateFormat={GlobalValues.dateFormat}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button type="button" disabled={(_invoiceObj.auctionListInvoice || '') == ''} className="btn btn-success btn-sm mr-10" onClick={this.props.addUpdateAuction}>ADD TO AUCTION</button>
                                        <button type="button" disabled={(_invoiceObj.auctionListInvoice || '') == ''} className="btn btn-success btn-sm" onClick={this.props.deleteAuction}>REMOVE FROM AUCTION</button>
                                    </div>
                                </div>
                            </div>:null} */}
                        </div>
                    </fieldset>
                </Form>
            </div>
        </Fragment>);
    }

}
export default ReleaseAuction
