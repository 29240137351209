import * as actionTypes from '../../../actions/actionTypes';
const defaultObject={
    summaryData:[],
    summaryDetailsData:[],
    responseReport:[]
}
export const summaryReducer = (state = defaultObject, action) => {
    switch (action.type) {
      case actionTypes.GET_SUMMARY_REPORT:
        return {...state ,summaryData:(action.summaryData||defaultObject.summaryData)};
      case actionTypes.GET_SUMMARY_DETAILS_REPORT:
        return {...state ,summaryDetailsData:(action.summaryDetailsData||defaultObject.summaryDetailsData)};
      case actionTypes.GET_RESPONSE_REPORT:
        return {...state ,responseReport:(action.responseReport||defaultObject.responseReport)}; 
      default:
        return state;
    }
  };