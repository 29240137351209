import React, { Component,Fragment } from 'react';
import {Card,Button} from 'react-bootstrap';
class ItemInfo extends Component {
    render(){ 
        const {itemData} =this.props
        return(<Fragment>
        
                <div className="col-md-3 mb-10">
                <fieldset disabled={this.props.disabled}>
                    <Card style={{cursor:'pointer',minHeight:'125px'}}
                     className={(itemData.visible ? 'permitted' : 'not-permitted')} onClick={()=>{
                         let itm=this.props.itemData;
                         itm.visible=!itemData.visible
                         this.props.onItemSelectionChange((itm));
                     
                     }}>
                    <Card.Body>
                        <Card.Title>{itemData.tileName}</Card.Title>
                        <Card.Text>
                        {'.....'}
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </fieldset>  
                </div>       
        </Fragment>);
    }

}
export default ItemInfo