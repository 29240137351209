import React from 'react';
import Autosuggest from 'react-autosuggest';
const getSuggestions = (value, reasons, text) => {
  const inputValue = value.toLowerCase();
  return reasons.filter(value =>
    value[text].toLowerCase().includes(inputValue)
  );
};
// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion =>
  suggestion.reason;
export default class UtilAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      suggestionList: [],
      disabled: false
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue.toUpperCase()
    });
    this.props.selectedTask(newValue,{})
  };
// Use your imagination to render suggestions.
 renderSuggestion = suggestion => {
  const reason = suggestion[this.props.optionTxt].trim()
  return (
    <div className='suggestion-content'>
      {(reason||'').toUpperCase()}
    </div>)
};
  componentWillReceiveProps(nextProps) {
    // this.setState({ suggestionList: nextProps.reasonList,disabled:nextProps.status});  
    if (nextProps.data) {
      this.setState({
        suggestionList: nextProps.data.map(account => {
          return { ...account, reason: account[this.props.optionTxt].trim().toUpperCase() }
        }).sort((a, b) => {
          if (a.reason < b.reason) return -1
          if (b.reason < a.reason) return 1
          return 0;
        }), disabled: nextProps.status, value: nextProps.value
      });
    }
  }


  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.state.suggestionList, this.props.optionTxt)
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.props.selectedTask(suggestionValue,suggestion)
    //alert(suggestionValue); //For example alert the selected value
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.labelName,
      value,
      className: 'form-control',
      onChange: this.onChange,
      disabled: this.state.disabled
    };

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}