import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { notify } from '../../util/notifications';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import ServiceCallTimes from '../../components/dispatchComponents/addServiceCall/slider/serciceCallTimes';
import ServiceCallInfo from '../../components/dispatchComponents/addServiceCall/serviceCallInfo';
import VehicleInfo from '../../components/dispatchComponents/addServiceCall/vehicleInfo';
import Billing from '../../components/dispatchComponents/addServiceCall/billing';
import Notes from '../../components/dispatchComponents/addServiceCall/notes';
import Attachment from '../../components/dispatchComponents/addServiceCall/attachment';
import ReleaseAuction from '../../components/dispatchComponents/addServiceCall/releaseauction';
import { uploadAttachments, deleteAttachments, addUserNotes, deleteNotes } from '../../actions/dispatchAction/newServiceCallAction';
import {
	fetchInvoiceInfoById, fetchReQAccountInfo, serviceCallInfoFieldChange, changeLocation,
	clearInvoiceRec, selectCallerInfoByCallerName, defaultInvoiceInfo, AddInvoice, updateInvoice,
	mileageManualCal, handleCharge, addCutomCharge, manipulateCustomCharge, deleteCustomCharge,
	fetchAccountByType, handleSubInvoice, validateVin, getVehicleDetails, handleReleaseChange, releaseInvoice, returnToStorage, handleTitleProcess,
	addUpdateAuction, deleteAuction, handleAuctionSelect, getInvoiceComm, saveInvoiceComm, calculateComm, reCalMileage, reviewInvoice,
	printoremailReceipt, savePrintEmailInvoice, clearPrintEmailInvoice, saveEmailInvoice, addUpdatePayment, deletePayments, getRatePlanById
} from '../../actions/dispatchAction/invoiceAction';
import { validate, isManagement, getAccessLevelsByModuleName, showFile, toBase64 } from '../../util/customMethods';
import { isValidForm } from '../../util/validationMethods';
import { required } from "../../util/validationMethods";
import { fetchAccountList } from '../../actions/dispatchAction/initialReqInfoAction';
import TmReactTable from '../../components/utilComponents/tmReactTable/tmReactTable';
import PaymentProcess from '../../components/dispatchComponents/payment/paymentProcess';
import { serviceCallState } from '../../util/stateUtil/initialStateForComponents'
const pageTitle = 'Add new service call';
let newServiceCallForm = { frmNewServiceCallInfo: {}, frmNewVehicleInfo: {}, frmNewBillingInfo: {}, frmPickAddressEntry: {}, frmDropAddressEntry: {}, releaseform: {}, reviewFom: {} }
const newServiceCallTabs = {
	TB_SERVICE_CALL_INFO: 'TB_SERVICE_CALL_INFO',
	TB_VEHICLE_INFO: 'TB_VEHICLE_INFO',
	TB_BILLING: 'TB_BILLING',
	TB_NOTES: 'TB_NOTES',
	TB_ATTACHMENT: 'TB_ATTACHMENT',
	TB_RELEASE_OR_AUCTION: 'TB_RELEASE_OR_AUCTION',
}
const defaultAccess = { add: false, view: false, edit: false, delete: false }
class AddServiceCall extends Component {
	state = {
		...serviceCallState
	}
	deleteAttachmentModal(type, attachId) {
		this.setState({
			deleteAttachmentIsOpen: true, attachId: attachId, attachmentType: type
		})
	}
	deleteNotesModal(notesObj) {
		this.setState({
			deleteNotesIsOpen: true, notesId: notesObj.invoiceNotesId, notesText: notesObj.notesText
		})
	}
	editNotesModal(notesObj) {
		this.setState({
			editNotesIsOpen: true, notesId: notesObj.invoiceNotesId, notesText: notesObj.notesText
		})
	}
	componentWillMount = () => {
		this.props.dispatch(fetchAccountList());
		const invoiceId = this.props.match.params.invoice || null
		this.props.dispatch(clearInvoiceRec())
		if (!validate(invoiceId, 'String')) {
			this.bindInvoiceInfo(invoiceId);
			this.setState({ invoiceId: invoiceId, newInvoice: false, account_rate_planID: this.props.invoiceInfo._invoiceObj.accountRatePlanId })
		}
	}
	onFieldValueChange = (key, value) => {
		this.setState({ [key]: value })
	}
	componentWillUnmount = () => {
		this.props.dispatch(clearInvoiceRec())
	}
	getFilesList = (fileName, fileList) => {
		const { attachmentFiles } = this.state;
		attachmentFiles[fileName] = fileList
		this.setState({
			attachmentFiles: attachmentFiles, attachment_Name: fileName
		});
	}
	getFileType = (typeName) => {
		switch (typeName) {
			case 'attachment':
				return 'attachments';
				break;
			case 'coversheet':
				return 'cover sheets';
				break;
			case 'towsheet':
				return 'tow sheets';
				break;
			default:
				return '';
		}
	}
	uploadAttachments = async (typeName) => {
		const { dispatch } = this.props
		const { attachment_Name, attachmentFiles } = this.state;
		if (attachmentFiles[typeName].length < 1) {
			alert(`Please upload ${this.getFileType(typeName)} file`);
			return false;
		}
		for (let i = 0; i < attachmentFiles[typeName].length; i++) {
			let ext = attachmentFiles[typeName][i].name.split('.').pop().toLowerCase()
			if ((ext != "pdf") && (typeName === 'towsheet' || typeName === 'coversheet')) {
				alert("Invalid file, File should be PDF only.");
				return false;
			}
			let fileSize = attachmentFiles[typeName][i].size;
			if (fileSize > 3145728) {
				alert('File size could not exceed 3mb !');
				return false;
			}
		}
		const formData = new FormData();
		for (var i = 0; i < attachmentFiles[typeName].length; i++) {
			formData.append('attachImage', attachmentFiles[typeName][i]);
		}
		const resp = await dispatch(uploadAttachments(attachment_Name, formData, this.state.invoiceId));
		if (resp.statusCode === 200) {
			this.setState({
				attachmentFiles: {
					attachments: [], coversheets: [], towsheets: []
				}
			})
			notify.createNotification('success', resp.statusMsg, this.props.refNotify);
		}
		else {
			notify.createNotification('error', resp.statusMsg, this.props.refNotify);
		}
	}

	/*This function is used to get invoice info*/
	bindInvoiceInfo = async (invoiceId) => {
		const { dispatch } = this.props
		let res = await dispatch(fetchInvoiceInfoById(invoiceId));
		if (res.statusCode == 200)
			dispatch(fetchReQAccountInfo((res.response.accountId || 0), invoiceId, 'regular', true));
		else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}
	ValidateForm = (obj) => {
		if (obj == null)
			return false;
		if ((obj.releaseName == '') || (obj.releaseTo == '') || (obj.releaseDate == null))
			return false;
		else
			return true;
	}
	/*function call to release invoice */
	releaseCurrentInvoice = async () => {
		const { dispatch } = this.props;
		this.setState({ isRelease: true });
		newServiceCallForm.releaseform.validateAll();
		if (this.ValidateForm(this.props.invoiceInfo._invoiceObj.invoiceRelease || null)) {
			const res = await dispatch(releaseInvoice())
			const response = await dispatch(updateInvoice(this.props.invoiceInfo._invoiceObj.invoiceId));
			this.setState({ invoiceObjChanged: false });
			if (res.statusCode === 200) {
				notify.createNotification('success', res.statusMsg, this.props.refNotify);
			} else {
				notify.createNotification('error', res.statusMsg, this.props.refNotify);
			}
		}
	}

	/*function call to save invoice commission*/
	saveInvoiceComm = async () => {
		const { dispatch } = this.props
		const res = await dispatch(saveInvoiceComm());
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function call to save invoice commission*/
	deletePayments = async (id, invoice) => {
		const { dispatch } = this.props
		const res = await dispatch(deletePayments(id));
		if (res.statusCode === 200) {
			dispatch(fetchInvoiceInfoById(invoice));
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function call to save invoice commission*/
	addUpdatePayments = async (invoice, obj) => {
		const { dispatch } = this.props
		/**disabled credit payment  */
		// if (obj.paymentId == 0 && (obj.paymentType).toUpperCase() == 'CREDIT') {
		// 	this.setState({ openPaymentWindow: true })
		// 	return false;
		// }
		const res = await dispatch(addUpdatePayment(invoice, obj));
		if (res.statusCode === 200) {
			dispatch(fetchInvoiceInfoById(invoice));
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function call to save invoice review*/
	reviewInvoice = async () => {
		const { dispatch } = this.props
		const res = await dispatch(reviewInvoice());
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function call to release invoice */
	returnCurrentInvoice = async () => {
		const { dispatch } = this.props
		this.setState({ isRelease: false })
		const res = await dispatch(returnToStorage());
		const response = await dispatch(updateInvoice(this.props.invoiceInfo._invoiceObj.invoiceId));
		this.setState({ invoiceObjChanged: false });
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function to get invoice calculation */
	getInvoiceComm = async (isDefault) => {
		const { dispatch } = this.props
		const res = await dispatch(getInvoiceComm(isDefault));
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function call to delete auction */
	deleteAuction = async () => {
		const { dispatch } = this.props
		const res = await dispatch(deleteAuction());
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	/*function call to add or update auction invoice*/
	addUpdateAuction = async () => {
		const { dispatch } = this.props
		const res = await dispatch(addUpdateAuction());
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}
	/** this method is used to print/Email Receipt*/
	printInvoiceDetails = async () => {
		let _status = (this.state.modalTypeVal === 'print' ? false : true);
		const { dispatch } = this.props
		const res = await dispatch(printoremailReceipt(this.props.invoiceInfo._invoiceObj.invoiceId, _status));
		if (res.statusCode === 200) {
			dispatch(clearPrintEmailInvoice());
			this.setState({ printModalIsOpen: false });
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else if (res.status === 200) {
			dispatch(clearPrintEmailInvoice());
			this.setState({ printModalIsOpen: false });
			let blob = new Blob([res.data], { type: "application/pdf" }),
				url = window.URL.createObjectURL(blob);
			window.open(url, "_blank");
		} else {
			this.setState({ printModalIsOpen: false });
			dispatch(clearPrintEmailInvoice());
			notify.createNotification('error', res.errorMsg, this.props.refNotify);
		}
	}
	printoremailReceipts = async (val) => {
		const { dispatch } = this.props;
		const res = await dispatch(clearPrintEmailInvoice());
		this.setState({ printModalIsOpen: true, modalTypeVal: val, otherEmail: false });
	}
	/** this method is used to Add/update current available invoice in edit mode*/
	addUpdateInvoice = async () => {
		const { dispatch, history } = this.props;
		newServiceCallForm.frmNewServiceCallInfo.validateAll();
		newServiceCallForm.frmPickAddressEntry.validateAll();
		if (!(isManagement())) {
			newServiceCallForm.frmDropAddressEntry.validateAll();
		}
		newServiceCallForm.frmNewVehicleInfo.validateAll();
		newServiceCallForm.frmNewBillingInfo.validateAll();
		if (!isValidForm(newServiceCallForm.frmNewServiceCallInfo)) {
			this.setState({ tabKey: newServiceCallTabs.TB_SERVICE_CALL_INFO });
		} else if (!isValidForm(newServiceCallForm.frmPickAddressEntry)) {
			this.setState({ tabKey: newServiceCallTabs.TB_SERVICE_CALL_INFO });
		} else if (!(isManagement()) && !isValidForm(newServiceCallForm.frmDropAddressEntry)) {
			this.setState({ tabKey: newServiceCallTabs.TB_SERVICE_CALL_INFO });
		} else if (!isValidForm(newServiceCallForm.frmNewVehicleInfo)) {
			this.setState({ tabKey: newServiceCallTabs.TB_VEHICLE_INFO });
		} else if (!isValidForm(newServiceCallForm.frmNewBillingInfo)) {
			this.setState({ tabKey: newServiceCallTabs.TB_BILLING });
		} else {
			if (this.props.invoiceInfo._invoiceObj.invoiceId || '' !== '') {
				const res = await dispatch(updateInvoice(this.props.invoiceInfo._invoiceObj.invoiceId));
				if (res.statusCode === 200) {
					this.setState({
						invoiceId: res.response.invoiceId,
						duplicateInvoiceIds: res.response.duplicateInvoiceIds || [],
						originalInvoiceId: res.response.originalInvoiceId,
						invoicemsg: res.statusMsg, showInvoiceModel: true,
						newInvoice: false
					})
				} else {
					notify.createNotification('error', res.statusMsg, this.props.refNotify);
				}
			}
			else {
				const res = await dispatch(AddInvoice());
				if (res.statusCode === 200) {
					this.setState({
						invoiceId: res.response.invoiceId,
						originalInvoiceId: res.response.originalInvoiceId,
						duplicateInvoiceIds: res.response.duplicateInvoiceIds || [],
						invoicemsg: res.statusMsg,
						showInvoiceModel: true, newInvoice: true
					})
				} else {
					notify.createNotification('error', res.statusMsg, this.props.refNotify);
				}
			}
		}
	}
	deleteNotes = async (notesId) => {
		const { dispatch } = this.props;
		const res = await dispatch(deleteNotes(this.state.invoiceId, notesId));
		if (res.statusCode === 200) {
			this.setState({ deleteNotesIsOpen: false });
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			this.setState({ deleteNotesIsOpen: false });
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}
	deleteAttachment = async (type, id) => {
		const { dispatch } = this.props;
		const res = await dispatch(deleteAttachments(this.state.invoiceId, type, id));
		if (res.statusCode === 200) {
			this.setState({ deleteAttachmentIsOpen: false });
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			this.setState({ deleteAttachmentIsOpen: false });
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}
	addUserNotes = async (val, text) => {
		const { dispatch } = this.props;
		const res = await dispatch(addUserNotes(this.state.invoiceId, val, text));
		if (res.statusCode === 200) {
			this.setState({ userNotes: '', notesText: '', editNotesIsOpen: false });
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			this.setState({ userNotes: '', notesText: '', editNotesIsOpen: false });
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}

	validateVinNum = async (vinNo) => {
		const { dispatch } = this.props;
		const res = await dispatch(validateVin(vinNo));
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}
	getVehicleDetails = async (vinNo) => {
		const { dispatch } = this.props;
		const res = await dispatch(getVehicleDetails(vinNo));
	}

	serviceCallInfoFieldChange = (objName, key, value) => {
		this.setState({ invoiceObjChanged: true });
		this.props.dispatch(serviceCallInfoFieldChange(objName, key, value));
	}

	changeLocation = (type, key, addObj) => {
		this.props.dispatch(changeLocation(type, key, addObj));
		this.setState({ addChanged: true })
	}
	reloadCall = () => {
		this.props.history.push("serviceCallInfo");
	}
	getFilteredPermission = (obj, key) => {
		let permissionObj = obj.find(item => item.pageName == key);
		if (permissionObj) {
			return permissionObj.basicAccess;
		}
		return defaultAccess;
	}
	permissionByPage = (obj, key, page) => {
		let permissionObj = obj.find(item => item.pageName == key);
		if (permissionObj) {
			return permissionObj.pageLvlAccess[page] || defaultAccess;
		}
		return defaultAccess;
	}
	fetchReQAccountInfo = async (accountId, invoiceId, callType, status) => {
		const { dispatch } = this.props;
		const res = await dispatch(fetchReQAccountInfo(accountId, invoiceId, callType, status));
		this.setState({ cashOnly: res });
	}
	closeprintModal = () => {
		const { dispatch } = this.props;
		dispatch(clearPrintEmailInvoice());
		this.setState({ printModalIsOpen: false })
	}
	closeAccountNotesListModal = () => {
		this.setState({ openAccountNotesList: false })
	}
	closeRatePlanDataModal = () => {
		this.setState({ openRatePlanData: false })
	}
	imageModalOpen(e, status, imageUrl) {
		e.preventDefault();
		if (!status) {
			window.open(imageUrl.attachmentPath, '_blank')
		}
	}
	saveAttachmentsforPrint(obj, e, type) {
		let status = e.target.checked;
		const { dispatch } = this.props;
		dispatch(savePrintEmailInvoice(obj, type, status));
	}
	emailUploadAttachment = async () => {
		if (this.state.base64Data.length > 0) {
			let base64 = await toBase64(this.state.base64Data[0]);
			let base64_string = base64.split(',')[1];
			let _type = base64.split(',')[0];
			let ext_type = _type.split(';base64')[0].split('/')[1]
			this.saveEmailInfo('base64Data', base64_string, ext_type)
		} else {
			notify.createNotification('error', 'Please upload attachment file', this.props.refNotify);
		}
	}
	saveEmailInfo(name, value, ext) {
		const { dispatch } = this.props;
		dispatch(saveEmailInvoice(name, value, ext));
	}
	getEmailAttachedFile = (fileName, fileList) => {
		this.setState({ [fileName]: fileList })
	}
	handleAccountNotesListOpen = (e) => {
		e.preventDefault();
		this.setState({ openAccountNotesList: true });
		const { dispatch } = this.props;
		dispatch(getRatePlanById(this.props.invoiceInfo._invoiceObj.accountRatePlanId));
	}
	getRatePlanChange = (e) => {
		e.preventDefault();
		let _arpId = e.target.value;
		this.setState({ account_rate_planID: _arpId });
		const { dispatch } = this.props;
		dispatch(getRatePlanById(_arpId));
	}
	handleRatePlanListOpen = (e) => {
		e.preventDefault();
		this.setState({ openRatePlanData: true });
		const { dispatch } = this.props;
		dispatch(getRatePlanById(this.props.invoiceInfo._invoiceObj.accountRatePlanId));
	}
	render() {
		const { _invoiceObj, accountReqData, printEmailReceipt, ratePlanInfoDetails } = this.props.invoiceInfo || {};
		const { serviceCallInfo, invoiceBilling, invoiceVehicle, eligibilityStatus } = _invoiceObj || {};
		document.title = (this.state.invoiceId || '') !== '' ? _invoiceObj.displayInvoiceId : pageTitle;
		const invoicePermission = getAccessLevelsByModuleName('Invoice screen');
		const { invoiceNotes } = _invoiceObj || [];
		const { invoiceAttachments } = _invoiceObj || [];
		const { vendorAttachments } = _invoiceObj || [];
		const _attachments = invoiceAttachments.concat(vendorAttachments);
		const from_Emails = ['noreply@allcitytowing.com'];
		from_Emails.push(accountReqData.billingReportEmail);
		from_Emails.push(this.props.authInfo.userDetails.userEmail);
		const to_Emails = [];
		let _count = 0;
		if ((accountReqData.billingContacts || []).length > 0) {
			accountReqData.billingContacts.map((item, index) => {
				if (item.defaultEmailId === 1) {
					to_Emails.push({
						optionTxt: `Primary Billing Conatct: ${item.billing_Contact_EmailId}`,
						optionVal: item.billing_Contact_EmailId
					})
				} else {
					_count++;
					to_Emails.push({
						optionTxt: `Billing Contact${_count}: ${item.billing_Contact_EmailId}`,
						optionVal: item.billing_Contact_EmailId
					})
				}
			})
		}
		if (_invoiceObj.serviceCallInfo.callerEmailId || '' !== '') {
			to_Emails.push({
				optionTxt: `Caller: ${_invoiceObj.serviceCallInfo.callerEmailId}`,
				optionVal: _invoiceObj.serviceCallInfo.callerEmailId
			})
		}
		if (this.props.authInfo.userDetails.userEmail || '' !== '') {
			to_Emails.push({
				optionTxt: `Self: ${this.props.authInfo.userDetails.userEmail}`,
				optionVal: this.props.authInfo.userDetails.userEmail
			})
		}
		to_Emails.push({
			optionTxt: 'Other',
			optionVal: 'Other'
		})

		const attachment_columns = [
			{
				accessor: 'select',
				Header: 'Select',
				filterable: false,
				width: 200,
				style: { textAlign: 'center' },
				Cell: (row) => {
					return <div><input className=" form-control-label" id={`check-${row.index + 1}`} type="checkbox" onChange={(e) => this.saveAttachmentsforPrint(row.original, e, 'attachments')} /></div>;
				}
			},
			{
				accessor: 'attachmentName',
				Header: 'Attachment Name',
				style: { textAlign: 'center' },
				Cell: (row) => {
					let status = row.original.attachmentName.split('.').pop().toLowerCase() === 'xls' ||
						row.original.attachmentName.split('.').pop().toLowerCase() === 'xlsx';
					return (<div> <a href={status ? row.original.attachmentName : 'Javascript:void(0)'}
						onClick={(e) => this.imageModalOpen(e, status, row.original)}
						download={status ? true : false}>{row.original.attachmentName}</a>
					</div>)
				}
			}]
		const userNotes = invoiceNotes.filter((item) => item.notes_indicator === 0);
		const sysNotes = invoiceNotes.filter((item) => item.notes_indicator === 1);
		const notes_columns = [
			{
				accessor: 'select',
				Header: 'Select',
				filterable: false,
				width: 200,
				style: { textAlign: 'center' },
				Cell: (row) => {
					return <div><input className=" form-control-label" id={`check-${row.index + 1}`} type="checkbox" onChange={(e) => this.saveAttachmentsforPrint(row.original, e, 'notes')} /></div>;
				}
			},
			{
				Header: 'User Id',
				accessor: 'userId'
			},
			{
				Header: 'Date',
				accessor: 'createdDate'
			},
			{
				Header: 'Time',
				accessor: 'createdTime'
			},
			{
				Header: 'Notes',
				accessor: 'notesText'
			}];
		return (
			<Fragment>
				<div className="row mb-10">
					<div className="col-md-12 col-sm-12">
						<ServiceCallTimes invoiceInfo={this.props.invoiceInfo} invoicePermission={this.getFilteredPermission(invoicePermission, 'Status Times')}
							serviceCallInfoFieldChange={(objName, key, value) => { this.props.dispatch(serviceCallInfoFieldChange(objName, key, value)) }} />
					</div>
				</div>
				<div className="page-header">
					<div className="row mb-10">
						<div style={{ padding: '0 15px' }}>
							<h4><i className="fa fa-id-card"></i> {this.state.invoiceId || '' !== '' ? _invoiceObj.displayInvoiceId : pageTitle}</h4>
						</div>
						<div style={{ padding: '0' }}>
							<button type="button" title={this.state.invoiceId || '' !== '' ? "Update" : "Save"} onClick={this.addUpdateInvoice} className="btn btn-primary btn-sm mr-2">
								<i className="fa fa-floppy-o" aria-hidden="true"></i>
							</button>
							{this.state.invoiceId || '' !== '' ? <button type="button" title="print" onClick={() => this.printoremailReceipts('print')} className="btn btn-primary btn-sm mr-2">
								<i className="fa fa-print" aria-hidden="true"></i>
							</button> : null}
							{this.state.invoiceId || '' !== '' ? <button type="button" title="email" onClick={() => this.printoremailReceipts('email')} className="btn btn-primary btn-sm mr-2">
								<i className="fa fa-envelope-o" aria-hidden="true"></i>
							</button> : null}
						</div>

						<div style={{ margin: '-20px 5px' }}>
							<label htmlFor="accountName" className="form-label col-form-label">CREATED BY</label>
							<input type="text" className="form-control form-control-sm text-center" style={{ width: '100px', padding: '3px', height: '22px' }}
								name="accountName" value={serviceCallInfo.callRecevierId || ''} requiredmsg="Please enter Account Name"
							/>
						</div>
						<div style={{ margin: '-20px 5px' }}>
							<label htmlFor="accountName" className="form-label col-form-label">ASSIGNED BY</label>
							<input type="text" className="form-control form-control-sm text-center" style={{ width: '100px', padding: '3px', height: '22px' }}
								name="accountName" value={_invoiceObj.assignedBy || ''} requiredmsg="Please enter Account Name"
							/>
						</div>
						<div style={{ margin: '-20px 5px' }}>
							<label htmlFor="accountName" className="form-label col-form-label">CLEARED BY</label>
							<input type="text" className="form-control form-control-sm text-center" style={{ width: '100px', padding: '3px', height: '22px' }}
								name="accountName" value={_invoiceObj.clearedBy || ''} requiredmsg="Please enter Account Name"
							/>
						</div>
						<div hidden={accountReqData.accountingType !== 'Cash' || _invoiceObj.callStatus == 'Cancel'} class="flag-text-shadow flag-red">CASH ONLY</div>
						<div hidden={invoiceVehicle.isImpound != 1 || _invoiceObj.callStatus == 'Cancel'} class="flag-text-shadow flag-orange">IN STORAGE</div>
						<div hidden={_invoiceObj.callStatus !== 'Cancel'} class="flag-text-shadow flag-orange">CANCELLD</div>
						<div hidden={invoiceBilling.isPoliceHold != 1 || _invoiceObj.callStatus == 'Cancel'} class="flag-text-shadow flag-red">POLICE HOLD</div>
						<div hidden={this.state.invoiceId || '' == '' || _invoiceObj.callStatus == 'Cancel' || _invoiceObj.isDispatchCleared == 1} class="flag-text-shadow flag-green">IN PROGRESS</div>
						<div hidden={invoiceBilling.verifiedForReview == 1 || _invoiceObj.callStatus == 'Cancel' || _invoiceObj.isDispatchCleared != 1} class="flag-text-shadow flag-blue">UNVERIFIED</div>
						{(((_invoiceObj.invoiceTitleProcess || '') !== '') && ((_invoiceObj.invoiceTitleProcess.avrDate || '') !== '') && ((_invoiceObj.invoiceTitleProcess.titleDate || '') !== '') && ((_invoiceObj.invoiceTitleProcess.transAuthDate || '') !== '')) ?
							<div class="flag-text-shadow flag-blue">TITLED</div> : null
						}
						<div hidden={!eligibilityStatus.isSrcInvoiceTowback} class="flag-text-shadow flag-blue">TWOBACK</div>
						<div hidden={!eligibilityStatus.isSourceOfSalvage} class="flag-text-shadow flag-blue">REHOOK</div>
						<div hidden={!eligibilityStatus.isSrcInvoiceRehook} class="flag-text-shadow flag-blue">REHOOK</div>
					</div>
				</div>
				<div className={`pd-10 bg-white border-radius-4 box-shadow container-box ${invoiceBilling.isPoliceHold != 1 ? '' : 'police-hold'}`}>
					<Tabs id="tab-account" className="tab" activeKey={this.state.tabKey}>
						<Tab eventKey={newServiceCallTabs.TB_SERVICE_CALL_INFO}
							title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i> Service Call Info </span>}>
							<ServiceCallInfo dispatchReview={false}
								serviceCallInfoFieldChange={(objName, key, value) => { this.serviceCallInfoFieldChange(objName, key, value) }}
								onAddressChange={(type, key, addObj) => { this.changeLocation(type, key, addObj) }}
								selectCallerInfo={(name) => { this.props.dispatch(selectCallerInfoByCallerName(name)) }}
								reviewLocation={this.props.reviewLocation} accountBasicInfo={this.props.accountBasicInfo}
								defaultInvoiceInfo={() => { this.props.dispatch(defaultInvoiceInfo()) }} reloadCall={this.reloadCall}
								handleSubInvoice={(type, accountId) => { this.props.dispatch(handleSubInvoice(type, accountId)) }}
								fetchAccountByType={(type) => { this.props.dispatch(fetchAccountByType(type)) }}
								invoicePermission={this.getFilteredPermission(invoicePermission, 'Service Call Info Tab')}
								reCalMileage={() => {
									if (((_invoiceObj.invoiceId || '') != '') && this.state.addChanged)
										this.setState({ addresModel: true })
								}}
								fetchReQAccountInfo={(accountId, invoiceId, callType, status) => {
									this.fetchReQAccountInfo(accountId, invoiceId, callType, status);
								}}
								invoiceInfo={this.props.invoiceInfo} allStates={this.props.allStates}
								initCallBack={(frm) => {
									newServiceCallForm.frmNewServiceCallInfo = frm;
								}}
								initPickAddressCallBack={(frm) => {
									newServiceCallForm.frmPickAddressEntry = frm;
								}}
								initDropAddressCallBack={(frm) => {
									if (isManagement()) {
										newServiceCallForm.frmDropAddressEntry = {};
									} else {
										newServiceCallForm.frmDropAddressEntry = frm;
									}
								}}
								isManagement={isManagement()}
								handleAccountNotesListOpen={(e) => this.handleAccountNotesListOpen(e)}
								handleRatePlanListOpen={(e) => this.handleRatePlanListOpen(e)} />
						</Tab>
						<Tab eventKey={newServiceCallTabs.TB_VEHICLE_INFO} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Vehicle Info</span>}>
							<VehicleInfo reviewLocation={this.props.reviewLocation} accountBasicInfo={this.props.accountBasicInfo} allStates={this.props.allStates} invoiceInfo={this.props.invoiceInfo}
								serviceCallInfoFieldChange={(objName, key, value) => { this.serviceCallInfoFieldChange(objName, key, value) }}
								validateVinNum={(vinNo) => this.validateVinNum(vinNo)} vinDetals={this.props.vinDetals}
								getVehicleDetails={(vinNo) => this.getVehicleDetails(vinNo)}
								invoicePermission={this.getFilteredPermission(invoicePermission, 'Vehicle Info Tab')}
								initCallBack={(frm) => {
									newServiceCallForm.frmNewVehicleInfo = frm;
								}} />
						</Tab>
						<Tab eventKey={newServiceCallTabs.TB_BILLING} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i> Billing </span>} >
							<Billing
								serviceCallInfoFieldChange={(objName, key, value) => { this.serviceCallInfoFieldChange(objName, key, value) }}
								mileageManualCal={(key, value) => { this.props.dispatch(mileageManualCal(key, value)) }}
								handleCharge={(key, value) => { this.props.dispatch(handleCharge(key, value)); this.setState({ invoiceObjChanged: true }) }}
								addCutomCharge={() => { this.props.dispatch(addCutomCharge()) }} getInvoiceComm={(isDefault) => { this.getInvoiceComm(isDefault) }}
								calculateComm={(key, value) => { this.props.dispatch(calculateComm(key, value)) }} saveInvoiceComm={this.saveInvoiceComm}
								fetchInvoiceInfoById={(invoice) => (this.props.dispatch(fetchInvoiceInfoById(invoice)))} manipulateCustomCharge={(index1, index2, value) => { this.props.dispatch(manipulateCustomCharge(index1, index2, value)) }}
								deleteCustomCharge={(index) => { this.props.dispatch(deleteCustomCharge(index)) }}
								onAddressChange={(type, key, addObj) => { this.props.dispatch(changeLocation(type, key, addObj)) }} selectCallerInfo={(name) => { this.props.dispatch(selectCallerInfoByCallerName(name)) }}
								reviewLocation={this.props.reviewLocation} accountBasicInfo={this.props.accountBasicInfo} setReview={() => { this.setState({ reviewModel: true }) }}
								fetchReQAccountInfo={(accountId, invoiceId, callType, status) => { this.props.dispatch(fetchReQAccountInfo(accountId, invoiceId, callType, status)) }}
								invoiceInfo={this.props.invoiceInfo} allStates={this.props.allStates} lockaccess={this.permissionByPage(invoicePermission, 'Billing', 'INVOICE UN/LOCK')}
								commissionaccess={this.permissionByPage(invoicePermission, 'Billing', 'Commission')}
								paymentAccess={this.permissionByPage(invoicePermission, 'Billing', 'Payment')}
								invoicePermission={this.getFilteredPermission(invoicePermission, 'Billing')}
								addUpdatePayments={(invoiceId, obj) => this.addUpdatePayments(invoiceId, obj)}
								deletePayments={(id, invoice) => { this.deletePayments(id, invoice) }}
								initCallBack={(frm) => {
									newServiceCallForm.frmNewBillingInfo = frm;
								}} />
						</Tab>
						<Tab eventKey={newServiceCallTabs.TB_NOTES} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>  Notes  </span>} >
							<Notes invoiceInfo={this.props.invoiceInfo} userNotes={this.state.userNotes}
								changeUserNotesField={(name, value) => this.onFieldValueChange(name, value)}
								addUserNotes={(val, text) => this.addUserNotes(val, text)}
								invoicePermission={this.getFilteredPermission(invoicePermission, 'Notes')}
								deleteNotesModal={(notesObj) => this.deleteNotesModal(notesObj)}
								editNotesModal={(notesObj) => this.editNotesModal(notesObj)} />
						</Tab>
						<Tab eventKey={newServiceCallTabs.TB_ATTACHMENT} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i> Attachment </span>} >
							<Attachment data={this.props.invoiceInfo._invoiceObj.invoiceAttachments}
								vendorAttachments={this.props.invoiceInfo._invoiceObj.vendorAttachments}
								invoicePermission={this.getFilteredPermission(invoicePermission, 'Attachments')}
								invoiceInfo={this.props.invoiceInfo}
								getFilesList={(fileName, fileList) => this.getFilesList(fileName, fileList)}
								uploadAttachments={(type) => this.uploadAttachments(type)}
								deleteAttachmentModal={(type, attachId) => this.deleteAttachmentModal(type, attachId)}
							/>
						</Tab>
						<Tab eventKey={newServiceCallTabs.TB_RELEASE_OR_AUCTION}
							title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i> Release/Auction </span>} >
							<ReleaseAuction
								isRelease={this.state.isRelease}
								releaseCurrentInvoice={() => {
									if (this.state.invoiceObjChanged)
										this.setState({ releaseModel: true })
									else
										this.releaseCurrentInvoice();
								}}
								returnCurrentInvoice={() => {
									if (this.state.invoiceObjChanged)
										this.setState({ returnModel: true })
									else
										this.returnCurrentInvoice();
								}}
								invoicePermission={this.getFilteredPermission(invoicePermission, 'Release/Auction')}
								releaseCallBack={(frm) => { newServiceCallForm.releaseform = frm; }} fileName={invoiceVehicle.aaFile}
								serviceCallInfoFieldChange={(objName, key, value) => { this.props.dispatch(serviceCallInfoFieldChange(objName, key, value)) }}
								handleTitleProcess={(key, value) => { this.props.dispatch(handleTitleProcess(key, value)) }}
								handleReleaseChange={(key, value) => { this.props.dispatch(handleReleaseChange(key, value)) }}
								deleteAuction={this.deleteAuction} addUpdateAuction={this.addUpdateAuction}
								handleAuctionSelect={(id) => { this.props.dispatch(handleAuctionSelect(id)) }}
								storagepermission={this.permissionByPage(invoicePermission, 'Release/Auction', 'Return To Storage')}
								invoiceInfo={this.props.invoiceInfo} />
						</Tab>
					</Tabs>
					<Modal show={this.state.showInvoiceModel} >
						<div className="modal-body">
							<form className="text-center">
								<div className="form-group">
									<p> {this.state.invoicemsg}</p>
									<p>Invoice No: {this.state.invoiceId}</p>
								</div>
								<div className="form-group">
									<p hidden={this.state.duplicateInvoiceIds.length < 1}>Duplicate invoice: {this.state.duplicateInvoiceIds.join(',')}</p>
								</div>
								<div className="text-center">
									<button onClick={(e) => {
										e.preventDefault();
										this.setState({ showInvoiceModel: false, duplicateInvoiceIds: [] });
										if (this.state.newInvoice) {
											this.props.history.replace("serviceCallInfo/" + this.state.originalInvoiceId);
										}
									}}
										className="btn btn-primary" style={{ width: '80px' }}>Close</button>
								</div>
							</form>
						</div>
					</Modal>
					<Modal show={this.state.deleteAttachmentIsOpen} >
						<div className="modal-body">
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<div>{'Are you sure you want to delete this attachment?'}</div>
									<br />
									<div>"{this.state.attachId.attachmentName}"</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger btn-sm"
								onClick={(e) => {
									e.preventDefault();
									this.deleteAttachment(this.state.attachmentType, this.state.attachId.attachmentId)
								}}>Delete</button>
							<button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
								this.setState({ deleteAttachmentIsOpen: false });
							}}>Close</button>
						</div>
					</Modal>
					<Modal show={this.state.deleteNotesIsOpen} >
						<div className="modal-body">
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<div>{'Are you sure you want to delete this note?'}</div>
									<br />
									<div>"{this.state.notesText}"</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger btn-sm"
								onClick={(e) => {
									e.preventDefault();
									this.deleteNotes(this.state.notesId)
								}}>Delete</button>
							<button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
								this.setState({ deleteNotesIsOpen: false });
							}}>Close</button>
						</div>
					</Modal>
					<Modal show={this.state.editNotesIsOpen} >
						<div className="modal-header">
							<Modal.Title>{'Update User Note'}</Modal.Title>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-md-8">
									<textarea className="form-control" name="notesText" value={this.state.notesText} onChange={(e) => this.onFieldValueChange(e.target.name, e.target.value)}></textarea>
								</div>
								<div className="col-md-4">
									<button type="button" className="btn btn-success btn-sm" onClick={(e) => {
										e.preventDefault();
										this.addUserNotes(this.state.notesId, this.state.notesText);
									}}>Update Notes</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal show={this.state.returnModel} >
						<div className="modal-body">
							<div className="modal-title h6 mb-20">
								{'Are you sure want to proceed with invoice changes ?'}
							</div>
							<div className="text-right">
								<button type="button" className="btn btn-success btn-sm mr-10" onClick={(e) => {
									e.preventDefault();
									this.returnCurrentInvoice();
									this.setState({ returnModel: false })
								}}>Procced</button>
								<button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
									e.preventDefault();
									this.setState({ returnModel: false })

								}}>Cancel</button>
							</div>
						</div>
					</Modal>
					<Modal show={this.state.releaseModel} >
						<div className="modal-body">
							<div className="modal-title h6 mb-20">
								{'Are you sure want to proceed with invoice changes ?'}
							</div>
							<div className="text-right">
								<button type="button" className="btn btn-success btn-sm mr-10" onClick={(e) => {
									e.preventDefault();
									this.releaseCurrentInvoice();
									this.setState({ releaseModel: false })
								}}>Procced</button>
								<button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
									e.preventDefault();
									this.setState({ releaseModel: false })

								}}>Cancel</button>
							</div>
						</div>
					</Modal>
					<Modal show={this.state.cashOnly} >
						<div className="modal-body">
							<div className="modal-title h6">
								{'Selected account is cash only'}
							</div>
							<div className="text-right">
								<button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
									e.preventDefault();
									this.setState({ cashOnly: false })
								}}>Close</button>
							</div>
						</div>
					</Modal>
					<Modal show={this.state.addresModel} >
						<div className="modal-body">
							<div className="modal-title h6">{'Invoice address is changed. Would you like to re-calculate mileage ?'}</div>
							<div className="text-right">
								<button type="button" className="btn btn-success btn-sm mr-10" onClick={(e) => {
									this.props.dispatch(reCalMileage())
									this.setState({ addresModel: false, addChanged: false })
								}}>Procced</button>
								<button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
									e.preventDefault();
									this.setState({ addresModel: false, addChanged: false })

								}}>Cancel</button>
							</div>
						</div>
					</Modal>
					<Modal show={this.state.reviewModel} >
						<div className="modal-header">
							<Modal.Title>{'Review Invoice'}</Modal.Title>
						</div>
						<div className="modal-body">
							<Form ref={c => {
								newServiceCallForm.reviewFom = c;
							}}>
								<div className="container">
									{/* <div className="row">
										<label className="col-sm-5 form-control-label" htmlFor="reviewLocation">Review Location:</label>
										<div className="col-sm-7">
											<p className="form-control-static">
												<Select id="exampleSelect3" requiredmsg="Please Select Review Location." name='reviewLocation' className="form-control"
													validations={[required]} value={_invoiceObj.reviewLocation}
													onChange={(e) => { this.serviceCallInfoFieldChange('', e.target.name, e.target.value) }}>
													<option value="">--SELECT LOCATION--</option>
													{(this.props.reviewLocation || []).filter(function (item) { return item.reviewLocation == 1; }).map((val, key) => {
														return (<option key={key} value={val.location}>{val.location}</option>);
													})}))}
												</Select>
											</p>
										</div>
									</div> */}
									<div className="row">
										<label className="col-sm-5 form-control-label" htmlFor="noteVal">Note:</label>
										<div className="col-sm-7">
											<p className="form-control-static">
												<Input type="text" className="form-control" requiredmsg="Please Enter Note Text." name='reviewNote' validations={[required]}
													value={_invoiceObj.reviewNote} onChange={(e) => { this.serviceCallInfoFieldChange('', e.target.name, e.target.value) }} />
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-7 offset-md-5 text-right">
											<input type="button" className="btn btn-success mr-10" value="Ok" onClick={(e) => {
												e.preventDefault();
												newServiceCallForm.reviewFom.validateAll();
												if (isValidForm(newServiceCallForm.reviewFom)) {
													this.reviewInvoice();
													this.setState({ reviewModel: false })
												}
											}} style={{ width: '35%' }} />
											<input type="button" className="btn btn-danger" value="Close" onClick={(e) => {
												e.preventDefault(); this.setState({ reviewModel: false })
											}} style={{ width: '35%' }} />
										</div>
									</div>
								</div>
							</Form>
						</div>
					</Modal>
					{/*///////// Print or Email Modal /////////*/}
					<Modal size={'lg'} dialogClassName="modal-90w" show={this.state.printModalIsOpen}>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<h4 className="text-center mt-3">{this.state.modalTypeVal === 'print' ? 'Print Receipt' : 'Email Receipt'}</h4>
							<Form className="">
								<div className="form-group">
									{this.state.modalTypeVal === 'print' ? null : <div className="row">
										<div className="col-md-12 col-sm-12 mb-10">
											<div className="col-md-4">
												<label htmlFor="emailFrom" className="form-label col-form-label">FROM</label>
												<div className="">
													<Select className="form-control form-control-sm" name="emailFrom"
														value={printEmailReceipt.emailFrom} onChange={(e) => {
															this.saveEmailInfo(e.target.name, e.target.value)
														}}>
														{(from_Emails.length > 0) && (from_Emails || []).map((item, key) => { return (<option key={key} value={item}>{item}</option>); })}
													</Select>
												</div>
											</div>
											<div className="col-md-4">
												<label htmlFor="emailTo" className="form-label col-form-label">TO CALLER</label>
												<div className="">
													<Select className="form-control form-control-sm" name="emailTo"
														value={this.state.otherEmail ? 'Other' : printEmailReceipt.emailTo} onChange={(e) => {
															if (e.target.value === 'Other') {
																this.setState({ otherEmail: true });
															} else {
																this.setState({ otherEmail: false });
																this.saveEmailInfo(e.target.name, e.target.value)
															}
														}}>
														<option value={""}>{'Select Email'}</option>
														{(to_Emails.length > 0) && (to_Emails || []).map((item, key) => { return (<option key={key} value={item.optionVal}>{item.optionTxt}</option>); })}
													</Select>
												</div>
											</div>
										</div>
										{this.state.otherEmail ? <div className="col-md-12 col-sm-12 mb-10">
											<div className="col-md-4">
												<label htmlFor="emailTo" className="form-label col-form-label">Other</label>
												<div className="">
													<Input className="form-control form-control-sm" name="emailTo" value={printEmailReceipt.emailTo} onChange={(e) => {
														this.saveEmailInfo(e.target.name, e.target.value)
													}}></Input>
												</div>
											</div>
										</div> : null}
										<div className="col-md-12 col-sm-12 mb-10">
											<div className="col-md-12">
												<label htmlFor="emailText" className="form-label col-form-label">FROM</label>
												<div className="">
													<textarea className="form-control form-control-sm" name="emailText" value={printEmailReceipt.emailText} cols="5" rows="5" onChange={(e) => {
														this.saveEmailInfo(e.target.name, e.target.value)
													}}></textarea>
												</div>
											</div>
										</div>
									</div>}
									<div className="col-md-12 col-sm-12 mb-10">
										<h6 className="text-blue text-bg-color form-group">User Notes</h6>
										<TmReactTable
											filterable
											sortable
											resizable
											defaultPageSize={25}
											data={userNotes}
											columns={notes_columns}
										/>
									</div>
									<div className="col-md-12 col-sm-12 mb-10">
										<h6 className="text-blue text-bg-color form-group">System Notes</h6>
										<TmReactTable
											filterable
											sortable
											resizable
											defaultPageSize={25}
											data={sysNotes}
											columns={notes_columns}
										/>
									</div>
									<div className="col-md-12 col-sm-12 mb-10">
										<h6 className="text-blue text-bg-color form-group">Attachments</h6>
										<TmReactTable
											filterable
											sortable
											resizable
											defaultPageSize={25}
											data={_attachments}
											columns={attachment_columns}
										/>
									</div>
									{this.state.modalTypeVal === 'print' ? null : <div className="col-md-12 col-sm-12 mb-10">
										<h6 className="text-blue text-bg-color form-group">Upload Attachments</h6>
										<div className="mb-10">
											<input type="file" name="base64Data" id="base64Data" className="form-control" onChange={(e) => {
												e.preventDefault();
												this.getEmailAttachedFile(e.target.name, e.target.files)
											}} style={{ height: '40px' }} />
										</div>
										<div className="">
											<input type="button" className="btn btn-success mr-20" value="Upload" onClick={() => this.emailUploadAttachment()} />
											<input type="button" className="btn btn-danger" value="Remove" onClick={() => {
												this.setState({ base64Data: [] });
												document.getElementById("base64Data").value = "";
											}} />
										</div>
									</div>}
									<div className="col-md-12 col-sm-12 mb-10 text-center">
										<input type="button" className="btn btn-success mr-20" value={this.state.modalTypeVal === 'print' ? 'Print' : 'Send Email Receipt'} onClick={this.printInvoiceDetails} />
										<input type="button" className="btn btn-danger" value="Close" onClick={this.closeprintModal} />
									</div>
								</div>

							</Form>
						</div>
					</Modal>
					{/* Modal Window for Account Info Details..... */}
					<Modal size={'lg'} dialogClassName="modal-90w modal-custom-right" show={this.state.openAccountNotesList} >
						<div className="modal-header">
							<Modal.Title>{(this.props.invoiceInfo.accountList.find((e) => e.optionVal == (this.props.invoiceInfo._invoiceObj.accountId || '').toString()) || {}).optionText}</Modal.Title>
						</div>
						<div className="modal-body">
							<div className="form-group row account-info-popup">
								<div className="col-sm-6">
									<h4 className="form-control-label">Billing Info</h4>
									<h5 className="text-center form-group text-blue">Flags</h5>

									<div className="row form-group">
										<label className="col-md-5" htmlFor="postatus">PO Status</label>
										{accountReqData.accountingType !== null && accountReqData.accountingType !== '' && accountReqData.accountingType.toUpperCase() === 'CASH' ?
											<div className="col-md-7"><label className="text-center btn-danger" style={{ padding: '0 10px', fontWeight: '600' }}>CASH ONLY</label></div> : accountReqData.isPORequiredDropOff === 1 ?
												<div className="col-md-7"><label className="text-center btn-warning" style={{ color: '#fff', padding: '0 10px', fontWeight: '600' }}>PO MANDATORY</label></div> : accountReqData.isPORequired === 1 ?
													<div className="col-md-7"><label className="text-center btn-warning" style={{ color: '#fff', padding: '0 10px', fontWeight: '600' }}>GET PO</label></div>
													: null}
									</div>

									<div className="row form-group">
										<label className="col-md-5" htmlFor="paymentTypes">Payment Types</label>
										<div className="col-md-7"><input className="form-control" id="paymentTypes" value={accountReqData.paymentType || 'None'} disabled /></div>
									</div>

									<h5 className="text-center form-group text-blue">Physical Info</h5>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalOwnerAddress">Owner Address</label>
										<div className="col-md-7"><input className="form-control" disabled id="modalOwnerAddress" value={`${accountReqData.accountAddress.corp_addr_line1}, ${accountReqData.accountAddress.corp_addr_line2}`} /></div>
									</div>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalOwnerCity">Owner City</label>
										<div className="col-md-7"><input className="form-control" disabled id="modalOwnerCity" value={accountReqData.accountAddress.corp_addr_city} /></div>
									</div>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalOwnerState">Owner State</label>
										<div className="col-md-7"><input className="form-control" disabled id="modalOwnerState" value={accountReqData.accountAddress.corp_addr_state} /></div>
									</div>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalOwnerZip">Owner Zip</label>
										<div className="col-md-7"><input className="form-control" disabled id="modalOwnerZip" value={accountReqData.accountAddress.corp_addr_zip} /></div>
									</div>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalOwnerPhone">Owner Phone</label>
										<div className="col-md-7"><input className="form-control" disabled id="modalOwnerPhone" value={accountReqData.accountAddress.corp_addr_phone} /></div>
									</div>
									<div className="row">
										<label className="col-md-5" htmlFor="modalOwnerfax">Owner Fax</label>
										<div className="col-md-7"><input className="form-control" disabled id="modalOwnerfax" value={accountReqData.accountAddress.corp_addr_fax} /></div>
									</div>
								</div>
								<div className="col-sm-6">
									<h4 className="form-control-label">Account Rate Plan</h4>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalRatePlan">Rate Plan</label>
										<div className="col-md-7"><select disabled={!isManagement()}
											id="exampleSelect" name="RatePlan" className="form-control"
											value={this.state.account_rate_planID} onChange={(e) => this.getRatePlanChange(e)}>
											{(accountReqData.ratePlanOptions || []).map((item, key) => { return (<option key={key} value={item.optionVal}>{item.optionText}</option>); })}
										</select>
										</div>
									</div>
									<div className="row form-group">
										<label className="col-md-5" htmlFor="modalFlatRate">Flat Rate</label>
										<div className="col-md-7"><input disabled className="form-control" id="modalFlatRate" value={`$ ${ratePlanInfoDetails.service_flat_rate}`} /></div>
									</div>
									<div className="form-group row">
										<label className="col-sm-4 form-control-label" style={{ color: '#00a8ff' }}>HOURLY</label>
										<label className="col-sm-4 form-control-label" style={{ color: '#00a8ff' }}>MILEAGE</label>
										<label className="col-sm-4 form-control-label" style={{ color: '#00a8ff' }}>STORAGE</label>
									</div>
									<div className="form-group row">
										{/*HOURLY  */}
										<label className="col-sm-4 form-control-label">
											{`$${(ratePlanInfoDetails.service_hourly_rate || 0)}`} Per hour <br /> {ratePlanInfoDetails.service_min_hours || 0} Hrs Min <br />
											{ratePlanInfoDetails.service_increment_hours || 0} hr increment<br /> Starting From {ratePlanInfoDetails.service_start_from || 0} to {ratePlanInfoDetails.service_end_by || 0}.
										</label>
										{/* Mileage */}
										<label className="col-sm-4 form-control-label">
											First {ratePlanInfoDetails.service_mileage_loaded_limit1 || 0} loaded miles at {`$${(ratePlanInfoDetails.service_mileage_loaded_limit1_charge || 0)}`} per mile<br />
											Next {ratePlanInfoDetails.service_mileage_loaded_limit2 || 0} loaded miles at {`$${(ratePlanInfoDetails.service_mileage_loaded_limit2_charge || 0)}`} per mile<br />
											Each loaded mile after will be charged at {`$${(ratePlanInfoDetails.service_mileage_loaded_charge_after_limit || 0)}`}<br /><br />
											First {ratePlanInfoDetails.service_mileage_unloaded_limit1 || 0} unloaded miles at {`$${(ratePlanInfoDetails.service_mileage_unloaded_limit1_charge || 0)}`} per mile<br />
											Next {ratePlanInfoDetails.service_mileage_unloaded_limit2 || 0} unloaded miles at {`$${(ratePlanInfoDetails.service_mileage_unloaded_limit2_charge || 0)}`} per mile<br />
											Each unloaded mile after will be charged at {`$${(ratePlanInfoDetails.service_mileage_unloaded_charge_after_limit || 0)}`}
										</label>
										{/* STORAGE */}
										<label className="col-sm-4 form-control-label" hidden={(ratePlanInfoDetails.storage_period || '') === ''}>
											Storage Type: {ratePlanInfoDetails.storage_period}<br />
											For the {ratePlanInfoDetails.storage_limit1_days}{' '} {ratePlanInfoDetails.storage_limit1_days > 1 ? 'Days' : 'Day'} at {`$${(ratePlanInfoDetails.storage_limit1_charge || 0)}`}<br />
											Next {ratePlanInfoDetails.storage_limit2_days}{' '} {ratePlanInfoDetails.storage_limit2_days > 1 ? 'Days' : 'Day'} at {`$${(ratePlanInfoDetails.storage_limit2_charge || 0)}`}<br />
											Each Day at {`$${(ratePlanInfoDetails.storage_each_day_after_limit2_charge || 0)}`} <br /><br />
											{(ratePlanInfoDetails.storage_period || "").toUpperCase() === 'CUSTOM' ? ('Custom Period is ' + (ratePlanInfoDetails.storage_custom_period || 0) + ' HR') : null}
										</label>
										<label className="col-sm-4 form-control-label" hidden={(ratePlanInfoDetails.storage_period || '') !== ''}>None</label>
									</div>
								</div>
							</div>
							<div className="row">
								<h4 className="col-md-12 text-center">Account Notes</h4>
								<div className="col-md-12 text-center">
									<table className="table table-bordered table-hover table-condensed" style={{ marginBottom: '0.5rem' }}>
										<thead>
											<th className="text-center" style={{ color: '#00a8ff' }}>SL.NO</th>
											<th className="text-center" style={{ color: '#00a8ff' }}>Notes Description</th>
											<th className="text-center" style={{ color: '#00a8ff' }}>Notes Created Date</th>
										</thead>
										<tbody>
											{accountReqData.accountNotes.length > 0 && accountReqData.accountNotes.map((item, index) => {
												return (
													<tr><td>{index + 1}</td><td>{item.notes_Txt}</td><td>{item.notes_created_date}</td></tr>
												)
											})}
										</tbody>
									</table>
									<button className="btn btn-danger" onClick={this.closeAccountNotesListModal}>Close</button>
								</div>
							</div>
						</div>
					</Modal>
					{/* Modal Window for Account Rate Plan Details..... */}
					<Modal size={'md'} dialogClassName="modal-60w" show={this.state.openRatePlanData} >
						<div className="modal-header">
							<Modal.Title>{'Rate Plan'}</Modal.Title>
						</div>
						<div className="modal-body">
							<div className="form-group row">
								<label className="col-sm-4 form-control-label">Rateplan Name: {ratePlanInfoDetails.accountPlanRateName}</label>
								<label className="col-sm-4 form-control-label">Tow Type: {ratePlanInfoDetails.towType}</label>
							</div>
							<div className="form-group row">
								<label className="col-sm-2 form-control-label" style={{ color: '#00a8ff' }}>FLAT RATE</label>
								<label className="col-sm-3 form-control-label" style={{ color: '#00a8ff' }}>HOURLY</label>
								<label className="col-sm-4 form-control-label" style={{ color: '#00a8ff' }}>MILEAGE</label>
								<label className="col-sm-3 form-control-label" style={{ color: '#00a8ff' }}>STORAGE</label>
							</div>
							<div className="form-group row">
								<label className="col-sm-2 form-control-label">
									{`$ ${ratePlanInfoDetails.service_flat_rate}`}
								</label>
								<label className="col-sm-3 form-control-label">
									{`$${(ratePlanInfoDetails.service_hourly_rate || 0)}`} Per hour <br /> {ratePlanInfoDetails.service_min_hours || 0} Hrs Min <br />
									{ratePlanInfoDetails.service_increment_hours || 0} hr increment<br /> Starting From {ratePlanInfoDetails.service_start_from || 0} to {ratePlanInfoDetails.service_end_by || 0}.
								</label>
								<label className="col-sm-4 form-control-label">
									First {ratePlanInfoDetails.service_mileage_loaded_limit1 || 0} loaded miles at {`$${(ratePlanInfoDetails.service_mileage_loaded_limit1_charge || 0)}`} per mile<br />
									Next {ratePlanInfoDetails.service_mileage_loaded_limit2 || 0} loaded miles at {`$${(ratePlanInfoDetails.service_mileage_loaded_limit2_charge || 0)}`} per mile<br />
									Each loaded mile after will be charged at {`$${(ratePlanInfoDetails.service_mileage_loaded_charge_after_limit || 0)}`}<br /><br />
									First {ratePlanInfoDetails.service_mileage_unloaded_limit1 || 0} unloaded miles at {`$${(ratePlanInfoDetails.service_mileage_unloaded_limit1_charge || 0)}`} per mile<br />
									Next {ratePlanInfoDetails.service_mileage_unloaded_limit2 || 0} unloaded miles at {`$${(ratePlanInfoDetails.service_mileage_unloaded_limit2_charge || 0)}`} per mile<br />
									Each unloaded mile after will be charged at {`$${(ratePlanInfoDetails.service_mileage_unloaded_charge_after_limit || 0)}`}
								</label>
								<label className="col-sm-3 form-control-label" hidden={(ratePlanInfoDetails.storage_period || '') === ''}>
									Storage Type: {ratePlanInfoDetails.storage_period}<br />
									For the {ratePlanInfoDetails.storage_limit1_days}{' '} {ratePlanInfoDetails.storage_limit1_days > 1 ? 'Days' : 'Day'} at {`$${(ratePlanInfoDetails.storage_limit1_charge || 0)}`}<br />
									Next {ratePlanInfoDetails.storage_limit2_days}{' '} {ratePlanInfoDetails.storage_limit2_days > 1 ? 'Days' : 'Day'} at {`$${(ratePlanInfoDetails.storage_limit2_charge || 0)}`}<br />
									Each Day at {`$${(ratePlanInfoDetails.storage_each_day_after_limit2_charge || 0)}`} <br /><br />
									{(ratePlanInfoDetails.storage_period || "").toUpperCase() === 'CUSTOM' ? ('Custom Period is ' + (ratePlanInfoDetails.storage_custom_period || 0) + ' HR') : null}
								</label>
								<label className="col-sm-4 form-control-label" hidden={(ratePlanInfoDetails.storage_period || '') !== ''}>None</label>
							</div>
							<div className="text-center">
								<button className="btn btn-danger" onClick={this.closeRatePlanDataModal}>Close</button>
							</div>
						</div>
					</Modal>
					{/* Modal Window for Payment Details..... */}
					<Modal size={'md'} dialogClassName="modal-60w" show={this.state.openPaymentWindow} >
						<div className="modal-header bg-primary text-white">
							<Modal.Title><i class="fa fa-credit-card" aria-hidden="true"></i>{' Payment'}</Modal.Title>
						</div>
						<div className="modal-body">
							<PaymentProcess />
						</div>
					</Modal>
					<div className=" text-center pd-10 police-hold-btn">
						<button className="btn btn-sm btn-success mr-20 base-line" hidden={_invoiceObj.callStatus == 'Cancel'} onClick={this.addUpdateInvoice}>{`${_invoiceObj.invoiceId || '' !== '' ? 'UPDATE' : 'SAVE'} SERVICE CALL INFO`}</button>
						{_invoiceObj.invoiceId || '' !== '' ? <button hidden={_invoiceObj.callStatus == 'Cancel'} className="btn btn-sm btn-primary mr-20 base-line" onClick={() => this.printoremailReceipts('email')}>Email Receipt</button> : null}
						{_invoiceObj.invoiceId || '' !== '' ? <button hidden={_invoiceObj.callStatus == 'Cancel'} className="btn btn-sm btn-primary mr-20 base-line" onClick={() => this.printoremailReceipts('print')}>Print Receipt</button>: null}
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		authInfo: state.authInfo,
		refNotify: state.refNotify,
		allStates: state.states.stateList,
		locationList: state.locationList.locationList,
		reviewLocation: state.location.locationList,
		accountBasicInfo: state.accountList.accountBasicInfo,
		invoiceInfo: state.invoiceInfo,
		vinDetals: state.invoiceInfo.vinDetailsList
	}
};

export default withRouter(connect(mapStateToProps)(AddServiceCall));
