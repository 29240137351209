import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import TmGoogleAutocomplete from '../../../utilComponents/tmGooglePlaceSuggest/tmGoogleAutocomplete'
import { sortName } from '../../../../util/customMethods';
import {
    required,
    zipValidation,
    requiredSelect
} from "../../../../util/validationMethods";
class AddressEntry extends Component {
    constructor() {
        super();
        this.state = {
            address: {
                location: '',
                addressLine1: '',
                city: '',
                state: '',
                zipCode: '',
                landmark: '',
                latLong: '',
                sortVal: ''
            }
        }
    }
    validateLocationAddress = (key) => {
        let loc = (this.props.reviewLocation.find(loc => loc.location === this.props.address.location)) || {};
        let addrObj = this.props.address;
        let locAddr = {
            addr1: loc.addrLine1 || "",
            city: loc.cityLocation || "",
            state: loc.state || "",
            zipCode: loc.zip || "",
            latLong: loc.latLong || '',
            sortVal: sortName(loc.state)
        }
        if ((addrObj[key] || '').trim().toUpperCase() !== (locAddr[key] || '').trim().toUpperCase()) {
            this.props.onFieldValueChange('location', '');
            this.forceUpdate();
        }

    }
    render() {
        return (<Fragment>
            <div className="card box-shadow">
                <div className="card-header">{this.props.title}</div>
                <div className="card-body">
                    <Form ref={(f) => {
                        this.frmAddressEntry = f;
                        this.props.initAddressCallBack(f);
                    }}>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="acc-info-name" className="form-label col-form-label">LOCATIONS</label>
                                    <div className="">
                                        <Select className="form-control form-control-sm"
                                            name="location"
                                            onBlur={() => { this.props.reCalMileage() }}
                                            value={this.props.address.location}
                                            onChange={(e) => {
                                                let loc = (this.props.reviewLocation.find(loc => loc.location === e.target.value)) || {};
                                                let addrObj = {
                                                    ...this.props.address,
                                                    location: loc.location || '',
                                                    addr1: loc.addrLine1 || "",
                                                    city: loc.cityLocation || "",
                                                    state: loc.state || "",
                                                    zipCode: loc.zip || "",
                                                    latLong: loc.latLong || '',
                                                    sortVal: sortName(loc.state || '')
                                                }
                                                this.props.onAddressObjByLocationChange(addrObj)
                                            }}>
                                            <option value={""}>{"-- Select Location --"}</option>
                                            {
                                                this.props.title === "Pick-up Location" ?
                                                    this.props.reviewLocation.sort(function (a, b) {
                                                        if (a.location.toUpperCase() < b.location.toUpperCase())
                                                            return -1;
                                                    }).map((loc, key) => {
                                                        return (
                                                            <option key={key} value={loc.location}>
                                                                {loc.location}
                                                            </option>
                                                        );
                                                    })
                                                    :
                                                    this.props.reviewLocation.filter(loc => loc.physicalLocation === 1)
                                                        .map((loc, key) => {
                                                            return (
                                                                <option key={key} value={loc.location}>
                                                                    {loc.location}
                                                                </option>
                                                            );
                                                        })
                                            }


                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="acc-info-name" className="form-label col-form-label">ADDRESS LINE 1</label>
                                    <div className="">
                                        <TmGoogleAutocomplete className="form-control" showConformModel={true}
                                            value={this.props.address.addr1}
                                            onBlur={() => { this.props.reCalMileage() }}
                                            name="addr1" placeholder="Search address.."
                                            validations={this.props.isDispatch ? [required] : []} requiredmsg="Please enter address line 1."
                                            handleSelectAddress={(address) => {
                                                if (address) {
                                                    let addrObj = {
                                                        ...this.props.address,
                                                        location: '',
                                                        addr1: address.addrLine || "",
                                                        city: address.city || "",
                                                        state: address.state || "",
                                                        sortVal: sortName(address.state || ''),
                                                        zipCode: address.zip || "",
                                                        latLong: address.lat && address.lng ? (address.lat + ',' + address.lng) : ''
                                                    }
                                                    this.props.onAddressObjByLocationChange(addrObj);
                                                }

                                            }} />
                                        <Input className="form-control" hidden={true}
                                            value={this.props.address.addr1}
                                            name="addr1" placeholder="Search address.."
                                            validations={this.props.isDispatch ? [required] : []} requiredmsg="Please enter address line 1."
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="acc-info-name" className="form-label col-form-label">CITY</label>
                                    <div className="">
                                        <Input placeholder="" name="city" type="text" id="acc-info-name"
                                            className="form-control form-control-sm" value={this.props.address.city}
                                            validations={this.props.isDispatch ? [required] : []} requiredmsg="Please enter city name."
                                            onChange={(e) => {
                                                this.props.onFieldValueChange(e.target.name, e.target.value)
                                            }} onBlur={(e) => {
                                                this.validateLocationAddress(e.target.name);
                                                this.props.reCalMileage();
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="acc-info-name" className="form-label col-form-label">STATE</label>
                                    <div className="">
                                        <Select id="acc-info-name" name="state" className="form-control form-control-sm"
                                            validations={this.props.isDispatch ? [required] : []} requiredmsg="Please select state name."
                                            value={(this.props.address.state || '').toLowerCase()} onChange={(e) => {
                                                this.props.onFieldValueChange(e.target.name, e.target.value)
                                            }} onBlur={(e) => {
                                                this.validateLocationAddress(e.target.name);
                                                this.props.reCalMileage();
                                            }}>
                                            <option value={""}>{"select state"}</option>
                                            {this.props.allStates.length > 0 && this.props.allStates.map((stateItem, key) => {
                                                return (
                                                    <option key={key} value={(stateItem.optionVal || '').toLowerCase()}>
                                                        {stateItem.optionTxt}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="acc-info-name" className="form-label col-form-label">ZIP CODE</label>
                                    <div className="">
                                        <Input placeholder="" type="text" name="zipCode" id="acc-info-name"
                                            className="form-control form-control-sm"
                                            validations={this.props.isDispatch ? [zipValidation] : []}
                                            value={this.props.address.zipCode} onChange={(e) => {
                                                this.props.onFieldValueChange(e.target.name, e.target.value)
                                            }} onBlur={(e) => {
                                                this.validateLocationAddress(e.target.name);
                                                this.props.reCalMileage();
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="acc-info-name" className="form-label col-form-label">LANDMARKS</label>
                                    <div className="">
                                        <Input placeholder="" type="text" name="landmark" id="acc-info-name"
                                            className="form-control form-control-sm" value={this.props.address.landmark}
                                            onChange={(e) => {
                                                this.props.onFieldValueChange(e.target.name, e.target.value);
                                                this.props.reCalMileage();
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Fragment>)
    }
}
export default AddressEntry
