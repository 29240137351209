import * as actionTypes from '../../../actions/actionTypes';
const auditummary={
  auditReport:[],
  receiptReport:{
    receiptDetails: [],
    receiptPaymentTypeTotals: [],
    receiptsTotalsDetails: []
  }
} 
export const auditReducer = (state = auditummary, action) => {
    switch (action.type) {
      case actionTypes.GET_AUDIT_REPORT:
        return {...state ,auditReport:(action.auditReport||auditummary.auditReport)};
      case actionTypes.GET_RECIPT_REPORT:
          return {...state ,receiptReport:action.receiptReport||auditummary.receiptReport}; ;
      default:
        return state;
    }
  };