import * as actionTypes from '../../../actions/actionTypes';
const defaultObject = {
    reportAccountList:[]
}
export const repAccReducer = (state = defaultObject, action) => {
    switch (action.type) {
        case actionTypes.GET_REPORT_ACCOUNT_LIST:
            return { ...state, reportAccountList: (action.reportAccountList || defaultObject.reportAccountList) };     
        default:
            return state;
    }
};