import * as actionTypes from '../../actions/actionTypes';
const defaultVendor={
  vendorsList:[],
  vendorsObject: {
      "accountId": [],
      "accountIds": "",
      "accountNames": "",
      "companyId": 0,
      "locked": 0,
      "reset": 0,
      "saltKey": "",
      "vendorEmailId": "",
      "vendorFirstName": "",
      "vendorId": 0,
      "vendorLastName": "",
      "vendorUserId": ""
    }  
}

export const vendorReducer = (state = defaultVendor, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_VENDORS:
      return {...state , vendorsList:(action.vendorList || defaultVendor.vendorsList)};
    case actionTypes.GET_VENDOR_RECORD:
        return {...state , vendorsObject:(action.vendorObject|| Object.assign({},defaultVendor.vendorsObject))};
    case actionTypes.CLEAR_VENDOR_RECORD:
        return{...state, vendorsObject:(action.vendorsObject || defaultVendor.vendorsObject)}
    default:
      return state;
  }
};