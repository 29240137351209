import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LocationList from '../../components/adminComponents/locations/locationList';
import {getLocationList, getLocationRecordByName} from '../../actions/adminAction/locationActions'
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle ="Location Manager";
class LocationsPage extends Component {
	constructor(){
		super();
		this.state={
			searchQuery:{searchText:''}
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.addNewLocationOnClick=this.addNewLocationOnClick.bind(this);
		this.editLocationOnClick=this.editLocationOnClick.bind(this);
		this.bindLocationList=this.bindLocationList.bind(this);
		this.onSearchClick=this.onSearchClick.bind(this)
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.bindLocationList();
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	addNewLocationOnClick=(e)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('locationsDetails?lName=');
	}
	onSearchClick=(e,searchQuery)=>{
		let _searchQuery=this.state.searchQuery;
		_searchQuery.searchText=searchQuery;
		this.setState({
			searchQuery:_searchQuery
		}, this.bindLocationList()
		)

	}
	editLocationOnClick=(e,row)=>{
		e.preventDefault();
		const {history} =this.props;
		history.push('locationsDetails?lName='+row.location);
	}
	bindLocationList=()=>{
		const {dispatch} =this.props;
		dispatch(getLocationList(this.state.searchQuery))
	}
	bindLocationRecordByName=()=>{
		const {dispatch} =this.props;
		dispatch(getLocationRecordByName(this.state.searchQuery))
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Location Manager');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-6 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-thumb-tack"></i> Location Manager</h4>
							</div>
						</div>
				</div>
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
			<LocationList accessLevel={accessLevelObj||{}} locationListData={this.props.locationList} addNewLocationOnClick={this.addNewLocationOnClick}
				editLocationOnClick={this.editLocationOnClick} onSearchClick={this.onSearchClick}
			/>
			</div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		locationList:state.location.locationList,
		locationRec:state.location.locationObject
  }
};

export default withRouter(connect(mapStateToProps)(LocationsPage));