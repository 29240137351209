import React, { Component } from 'react';
import {connect} from  'react-redux';
import CallsCompleted from './allreports/callscompletedreport/callscompleted';
import SalvageCount from './allreports/salvagecountreport/salvagecount';
import DailyDeposit from './allreports/dailydepositreport/dailydeposit';
import StorageCount from './allreports/storagecountreport/storagecount';
import UnitsReleased from './allreports/unitsreleasedreport/unitsreleased';
import Commissions from './allreports/commissionsReport/commissions';
import NoAbandonedVehicles from './allreports/noabandonedvehiclesreport/noabandonedvehicles';
import PaylessInventory from './allreports/paylessinventoryreport/paylessinventory';
import {dashboardViewAccess,dashboardTailViewAccess} from '../../../util/accessLevelMethods';
import 'bootstrap-daterangepicker/daterangepicker.css';
const pageTitle="Reports Dashboard";
const moduleName="Reports";
const reportDashboardTailsTitles={
    CallsCompleted:'Calls Completed',
    DailyDeposit:'Daily Deposit',
    NoAbandonedVehicles:'No Abandoned Vehicles',
    PaylessInventory:'Payless Inventory',
    SalvageCount:'Salvage Count',
    StorageCount:'Storage Count',
    UnitsReleased:'Units Released',
    Commissions:'commissions'
  }
class Reports extends Component {
    constructor(props){
        super(props);
        this.getTailsViewAccess=this.getTailsViewAccess.bind(this);
    }
    componentWillMount=()=>{
        document.title=pageTitle
    }
    getTailsViewAccess=()=>{
        let resultObj=new Object();
        (this.props.dashboardAccess[moduleName]||[]).map((item)=>{
            resultObj[item.tileName.toLowerCase()]=dashboardTailViewAccess(moduleName,item.tileName,this.props.dashboardAccess)
        });
        return resultObj;
    }
    render() {
        return (
            <>
            <div className="page-header">
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12">
                        <div className="title">
                            <h4><i className="fa fa-flag"></i> {pageTitle}</h4>
                        </div>
                    </div>
                </div>
            </div>
            {
                dashboardViewAccess(moduleName,this.props.dashboardAccess)===true?
            <div className="pd-10 bg-white border-radius-4 box-shadow container-box tile-card-box">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-lg-12">                                                                                          
                    {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.CallsCompleted.toLowerCase()]===true?
                    <CallsCompleted />:null
                    }
                    {/* {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.SalvageCount.toLowerCase()]===true?
                    <SalvageCount />:null} */}
                    {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.DailyDeposit.toLowerCase()]===true?
                    <DailyDeposit />:null}
                    {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.StorageCount.toLowerCase()]===true?
                    <StorageCount />:null}
                    {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.UnitsReleased.toLowerCase()]===true?
                    <UnitsReleased />:null}
                    {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.NoAbandonedVehicles.toLowerCase()]===true?
                    <NoAbandonedVehicles />:null}
                    {/* {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.PaylessInventory.toLowerCase()]===true?
                    <PaylessInventory />   :null}  */}
                    {/* {
                        this.getTailsViewAccess()[reportDashboardTailsTitles.Commissions.toLowerCase()]===true?
                    <Commissions />   :null} */}
                    {/* <Commissions />          */}
                </div>
            </div></div>:
            <div className="pd-10">
            <div className="error-page-wrap text-center color-dark">
            <h1 className="color-dark weight-500">Error: 403 Forbidden</h1>
            <p>Sorry, access to this resource on the server is denied.
            </p>
            </div>
            </div>
            }
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        dashboardAccess:state.dashboardAccess
    }
  };
export default connect(mapStateToProps) (Reports);