import * as actionTypes from '../../../actions/actionTypes';
const defaultObject={
    driverPerformanceData:[]
}
export const driverReducer = (state = defaultObject, action) => {
    switch (action.type) {
      case actionTypes.GET_DRIVER_PERFORMANCE_SUM:
        return {...state ,driverPerformanceData:(action.driverPerformanceData||defaultObject.driverPerformanceData)};  
      default:
        return state;
    }
  };