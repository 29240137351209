import React, { Component,Fragment } from 'react';
import ReactTable from 'react-table-v6';
import TmExportExcel from '../../../utilComponents/tmExportExcel/tmExportExcel';
import ReactTablePagination from '../../../utilComponents/tmReactTable/reactTablePagination';
import moment from 'moment'

class TruckInfoServiceDetails extends Component {
  constructor(props){
    super(props);
    this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
  }
  buildDataFormatForExport=()=>{
    return([{
      columns:[{title:'USER ID'},{title:'DATE'},{title:'TIME'},{title:'SERVICE STATUS'}],
      data: this.props.serviceDetailsListData.map((item)=>{
        return [{value:item.userId},{value:item.createdDate},{value:item.createdTime},{value:item.serviceStatus}]
      })
    }])
  }
    render(){
        const excelDataSet=this.buildDataFormatForExport();
        const contactCols=[{
            Header: 'USER ID',
            accessor: 'userId', // String-based value accessors!
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width:'100%'
            
          }, {
            Header: 'DATE',
            accessor: 'createdDate',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: 200
          }, {
            Header: 'TIME',
            accessor: 'createdTime',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: 200
          },
          {
            Header: 'SERVICE STATUS',
            accessor: 'serviceStatus',
            filterable:true,
            Cell: props => <span>{props.value}</span>,
            width: 200
          }];
          
        return(<Fragment>
        <div className="row">
            <div className="col-md-12 col-sm-12 mb-10">
			    <div className="pull-left">
				    <h4 className="text-blue">Service Details</h4>				
			    </div>
                <div className="pull-right">
                  <fieldset disabled={this.props.disabled}>
                    <TmExportExcel name={'Truck Info Service Details'} filename={`${"TruckInfoServiceDetails_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                    element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                    dataSet={excelDataSet} />
                  </fieldset>
                </div>
        </div></div>
        <div className="row">
        <div className="col-md-12 col-sm-12 mb-30">
            <ReactTable 
            filterable 
            sortable
            resizable
            defaultPageSize={3}
            PaginationComponent={ReactTablePagination}
            data={this.props.serviceDetailsListData}
            columns={contactCols}
        /> 
        </div>
        </div>
        
        </Fragment>);
    }

}
export default TruckInfoServiceDetails;