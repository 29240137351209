import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table-v6';
import Autocomplete from 'react-google-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_API_KEY } from '../../../util/constants'
const restrictionRegion = 'us';

class TmGoogleAutocomplete extends React.Component {
    state = {
        search: "",
        value: this.props.value || '',
        addressArray: [],
        showAddressModal: false
    }
    componentWillReceiveProps = (nextProps) => {
        if (this.state.value !== nextProps.value) {
            this.setState({ value: nextProps.value })
        }
    }
    handleInputChange = e => {
        if (e.target.value !== this.state) {
            this.setState({ search: e.target.value, value: e.target.value })
        }

    }

    handleSelectSuggest = (geocodedPrediction) => {
        //console.log(geocodedPrediction, originalPrediction) // eslint-disable-line
        this.setState(
            {
                search: "", value: geocodedPrediction.formatted_address,
                showAddressModal: (this.props.showConformModel || false)
            }, () => {
                this.splitGoogleAddress(geocodedPrediction)
            });
    }
    getAddressComponent(address, component, type) {
        var element = '';
        element = address.address_components.filter(address_component => {
            return address_component.types[0] === component
        })
        if (element.length > 0) {
            return type === 'short' ? element[0].short_name || '' : element[0].long_name || '';
        } else {
            return '';
        }
    }
    splitGoogleAddress = (googleAddress) => {
        let _fullAddress = googleAddress.formatted_address;
        let addressObj = {
            selected: false,
            addrLine: _fullAddress.split(',')[0] || '',
            city: this.getAddressComponent(googleAddress, 'locality', 'long'),
            state: this.getAddressComponent(googleAddress, 'administrative_area_level_1', 'long'),
            stateShort: this.getAddressComponent(googleAddress, 'administrative_area_level_1', 'short'),
            country: this.getAddressComponent(googleAddress, 'country', 'long'),
            zip: this.getAddressComponent(googleAddress, 'postal_code', 'short'),
            fullAddress: _fullAddress,
            lat: googleAddress.geometry.location.lat() || undefined,
            lng: googleAddress.geometry.location.lng() || undefined
        }
        if (!(this.props.showConformModel || false)) {
            this.props.handleSelectAddress(addressObj);
        } else {
            this.setState({ addressArray: [addressObj], showAddressModal: true });
        }

    }

    render() {
        return (<>
        <div >
            <Autocomplete {...this.props} 
                value={this.state.value}
                types={[]} style={{ position: "relative", zIndex: "10"  }}
                onPlaceSelected={(place) => { this.handleSelectSuggest(place) }}
                onChange={this.handleInputChange}
                componentRestrictions={{ country: restrictionRegion }}
            />
            </div>

            <Modal show={this.state.showAddressModal} >
                <div className="modal-header">
                    <Modal.Title>{'Add Loction'}</Modal.Title>
                </div>
                <div className="modal-body">
                    <div className="col-md-12 col-sm-12">
                        <ReactTable
                            data={this.state.addressArray}
                            pageSize={this.state.addressArray.length}
                            showPagination={false}
                            columns={
                                [
                                    {
                                        Header: 'Select',
                                        width: 50,
                                        Cell: (props) => <input type='radio' data-index={props.index} checked={props.original.selected} name='addressRadio' onChange={(e, props) => {
                                            let _addressArray = this.state.addressArray;
                                            _addressArray[e.currentTarget.getAttribute('data-index')].selected = e.currentTarget.checked
                                            this.setState({ addressArray: _addressArray })
                                        }} />
                                    },
                                    {
                                        Header: 'ADDRESS',
                                        width: 200,
                                        accessor: 'addrLine'
                                    },
                                    {
                                        Header: 'CITY',
                                        accessor: 'city',
                                        width: 100
                                    },
                                    {
                                        Header: 'STATE',
                                        width: 100,
                                        accessor: 'state'
                                    },
                                    {
                                        Header: 'ZIP',
                                        width: 100,
                                        accessor: 'zip'
                                    }
                                ]
                            } />

                    </div>
                    <div className="modal-footer">
                        <button variant="secondary" className="btn btn-danger btn-sm" onClick={(e) => {
                            this.setState({ showAddressModal: false, addressArray: [] }
                                , this.props.handleSelectAddress(undefined)
                            )
                        }}>Cancel</button>
                        <button type="button" variant="primary" className="btn btn-success btn-sm" onClick={
                            (e) => {
                                const selectedAddress = this.state.addressArray.filter(add => {
                                    return add.selected
                                })
                                if (selectedAddress.length > 0) {
                                    this.setState({ showAddressModal: false, addressArray: [] }
                                        , () => {
                                            this.props.handleSelectAddress(selectedAddress[0]);
                                        }
                                    )
                                } else {
                                    alert('Please check any one of the addresses...');
                                }
                            }
                        } >Ok</button>
                    </div>
                </div>
            </Modal>
        </>)
    }
}
export default GoogleApiWrapper({
    apiKey: (GOOGLE_API_KEY),
    libraries: ['places', 'geocode']
})(TmGoogleAutocomplete)
