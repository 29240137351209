import React, { Component, Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';

class ReviewInvoiceList extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            
        } 
    }
    onFieldChange=(e)=>{
        this.props.onInvoiceInfoFieldsChange(e.target.value);
    }
    render() {
        const {reviewInvoice}=this.props;
        const invoiceList = reviewInvoice.reviewedInvoices || [];
        const locationList = reviewInvoice.allReviewLocations || [];
        return (<Fragment>
            <div className="pd-10 bg-white border-radius-4 box-shadow">
                <div className="row">
                    <div className="col-md-4 mb-10">
                    <Form ref={(c)=> { 
                    this.formInvoiceInfo = c;
                     }}>
                        <Select className="form-control form-control-sm" name="location" value={reviewInvoice.location||''}
                        onChange={this.onFieldChange} disabled={!(this.props.reviewPermission.edit)}>
                            {(this.props.reviewPermission.edit) ? null:
                            <option value={reviewInvoice.location}>{reviewInvoice.location}</option>}
                            {(locationList.length>0) && locationList.map((value, key) => {
                                return (
                                    <option key={key} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                        </Select>
                        </Form>
                    </div>
                    <div className="col-md-12">
                    <TmReactTable
                            filterable={true}
                            sortable={false}
                            resizable={false}									
                            data={invoiceList}
                            columns={
                                [
                                {
                                    Header: 'Invoice #',
                                    accessor: 'invoiceId',
                                    style: {textAlign: 'center'},
                                    Cell: row => (<a href={`serviceCallInfo/${row.original.invoiceId}`} target="_blank">{row.original.invoiceId}</a>
                                    )
                                },
                                {
                                    accessor: 'reviewNote',
                                    Header: 'Reason',
                                    style: {textAlign: 'center'}                                }
                                ]
                            }
                        />
                    </div>
                </div>
            </div>
        </Fragment>);
    }
}
export default ReviewInvoiceList

