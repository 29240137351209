import React, { Component,Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable'
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
import moment from 'moment'

class CustomChargeList extends Component {
    constructor(){
        super();
        this.state={
          searchQuery:'',
          excelDataSet:[]     
        } 
        this.buildDataFormatForExport= this.buildDataFormatForExport.bind(this) 
      }
      componentWillReceiveProps=(nextProps)=>{
        this.setState({excelDataSet:nextProps.globalChargeListData});
      }
      buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'Charge Name'}],      
          data:this.state.excelDataSet.map((item)=>{
            return [{value:item.chargeName}]
          })
        }])
      }
    render(){
        const {globalChargeListData} =this.props;
    const columns=[{
      Header: 'charge name',
      accessor: 'chargeName', // String-based value accessors!
      filterable:true,
      minWidth: 200,
      Cell: props => <a className="btn-link" href="javascript:void(0)" title="Edit" onClick={(e)=>{
          let chargeId=props.original.chargeListId
          this.props.onEditCharge(e,chargeId)
      }}>{props.value}</a> 
    },{
        Header: 'ENABLED',
        accessor: 'isEnabled', // String-based value accessors!
        filterable:true,
        style:{textAlign:'center'},
        minWidth: 100,
        Filter: ({ filter, onChange }) =>
        <select className="form-control"
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
         <option value="">All</option>
          <option value="1">ENABLED</option>
          <option value="0">Disabled</option>
        </select>,
        Cell: props => props.value===1?<i className="fa fa-check"></i>:null}
      // ,{
      //   Header: 'ISBILLABLE',
      //   accessor: 'isBillable', // String-based value accessors!
      //   filterable:true,
      //   style:{textAlign:'center'},
      //   minWidth: 100,
      //   Filter: ({ filter, onChange }) =>
      //   <select className="form-control"
      //     onChange={event => onChange(event.target.value)}
      //     style={{ width: '100%' }}
      //     value={filter ? filter.value : ''}
      //   >
      //    <option value="">All</option>
      //     <option value="1">ENABLED</option>
      //     <option value="0">Disabled</option>
      //   </select>,
      //   Cell: props => props.value===1?<i className="fa fa-check"></i>:null
      // }
      ,{
        Header: 'DRIVER DROPDOWN ENABLED',
        accessor: 'isEnableForDriver', // String-based value accessors!
        filterable:true,
        Filter: ({ filter, onChange }) =>
        <select className="form-control"
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">All</option>
          <option value="1">ENABLED</option>
          <option value="0">Disabled</option>
        </select>,
        minWidth: 150,
        style:{textAlign:'center'},        
        Cell:  props => props.value===1?<i className="fa fa-check"></i>:null
      },{
        Header: 'ADDITIONAL ACTIONS REQUIRED BY DRIVER ?',
        accessor: 'addlActionForDriver', // String-based value accessors!
        filterable:true,
        Filter: ({ filter, onChange }) =>
        <select className="form-control"
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">All</option>
          <option value="1">ENABLED</option>
          <option value="0">Disabled</option>
        </select>,
        minWidth: 150,
        style:{textAlign:'center',whiteSpace: 'unset'},
        Cell:  props => props.value===1?<i className="fa fa-check"></i>:null
      },{
        Header: 'PROMPT MESSAGE',
        accessor: 'promptMessage', // String-based value accessors!
        filterable:true,
        minWidth: 200,
        style:{whiteSpace: 'unset'},
        Cell: props => props.value
      }];
        return(<>
      <div className="row">
      <div className="col-md-5 col-sm-12 mb-5 mt-2">            
      </div>
      <div className="col-md-7 col-sm-6 mb-5">  
      
      <div className="pull-right">
      <div className="btn-list" style={{display:'inline-block'}}>
      <TmExportExcel name={'Custom Charges'} filename={`${"CustomCharges_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
      element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
       dataSet={this.buildDataFormatForExport()} />
      {
         (this.props.accessLevel.basicAccess.add||false)===true?
      <button className="btn btn-success btn-sm" onClick={(e)=>{
        this.props.showModalForAdd(e,0)
      }} ><i className="fa fa-plus"></i> Add Custom Charge</button>:null}
      </div>
      </div> 
      </div>
      </div>
      <div className="row">
      <div className="col-md-12 col-sm-12 mb-30">	
        <TmReactTable
        ref={(table)=>this.cChargeTable=table}
        onFilteredChange={()=>{
          let data=this.cChargeTable.tmReactTable.getResolvedState().sortedData
          this.setState({excelDataSet:data})
        }}
            filterable 
            sortable
            resizable
            defaultPageSize={10}
            data={globalChargeListData}
            columns={columns}
            sorted={[{
              id: 'chargeListId',
              desc: false
            }]}
        />	
        </div></div>
        </>)
    }
}
export default CustomChargeList