import React, { Component } from 'react';
import {isManagement,ROOT_ADMIN,LAUNCH_URL,ROOT_DISPATCHER} from '../customMethods'
export const tableColumns = [
    {
        accessor: 'userId',
        Header: 'Driver',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/employeesDetails?eid=${props.original.employeeId}`,'_blank');
        }}>{props.original.userId}</a>,
        width: 200
    },
    {
        accessor: 'invoiceNumber',
        Header: '# Of Invoices',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'company',
        Header: 'Company',
        style: { textAlign: 'center' },
        width: 200,
        show:isManagement()
    },
    {
        accessor: 'accidentReason',
        Header: '# Of Accidents',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'impoundLot',
        Header: '# To Yard',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'accidenttoYardPercentage',
        Header: '% To Yard',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.accidenttoYardPercentage||0)===parseInt(filter.value||0),
        width: 200
    },
    {
        accessor: 'accidentsROPhone',
        Header: '# RO Phone',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'accidentsROPhonePercentage',
        Header: '% RO Phone',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.accidentsROPhonePercentage||0)===parseInt(filter.value||0),
        width: 200
    },
    {
        accessor: 'invoiceImpoundNum',
        Header: '# OF INVOICES TO IMPOUND',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.invoiceImpoundNum||0)===parseInt(filter.value||0),
        width: 240
    },
    {
        accessor: 'invoiceImpoundWithMileageNum',
        Header: '# MILEAGE',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.invoiceImpoundWithMileageNum||0)===parseInt(filter.value||0),
        width: 160
    },
    {
        accessor: 'mileagePercentage',
        Header: '% MILEAGE',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.mileagePercentage||0)===parseInt(filter.value||0),
        width: 160
    },
    {
        accessor: 'dispatchertoArrivalTimeAvg',
        Header: 'AVG Disp-Arr',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'enroutetoClearTimeAvg',
        Header: 'AVG Enr-Clr',
        style: { textAlign: 'center' },
        width: 200
    }
];
