import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string'
import { Tabs, Tab } from 'react-bootstrap';
import {
	getAccountById, clearAccountRec, accountBasicInfoFieldChange, accountAddressFieldChange,
	accountTimePlanFieldChange, onAccountServiceCallRateSubmit, setSelectedAccountRatePlan,
	accountDefaultLocationFieldChange,accountBillableChange,addRemoveBillableIndex,addUpdatBillableIndex,
	accountRatePlanFieldChange,addLink,getListOfAccountsLink,deleteLink,
	accountRatePlanDiscountAllFieldChange,
	loadAccountDefaultRatePlan,
	onSaveAccount, setBillAddressAsAccountAddress,
	setAccountNotesToList,
	setBillingNotesToList,
	setAccountContactToList,
	setBillingContactToList,
	deleteContactToList,
	deleteNoteToList,
	activeInactiveAccount,
	addCustomCharges,
	removeCustomCharges,
	accountRatePlanCustomFieldChange,
	accountDefaultLocationAddressChange, getAssignedCompList, setBillableStatus
} from '../../actions/adminAction/accountAction';
import { getChargeList ,getEmptyChargeList} from '../../actions/adminAction/ratePlanActions';
import AccountInfo from '../../components/adminComponents/account/accountInfo/accountInfo';
import ChargeSettings from '../../components/adminComponents/account/accountInfo/customChgMonetizationCongiList';
import { notify } from '../../util/notifications'
import Billing from '../../components/adminComponents/account/billing/billing';
import Billing_ARS from '../../components/adminComponents/account/ARS_Config/Billing_ARS';
import DefaultAddress from '../../components/adminComponents/account/defaultInfo/defaultAddress';
import AccountAssignedComp from '../../components/adminComponents/account/accountAssignedComp';
import AccountHistory from '../../components/adminComponents/account/accountHistory/accountHistory';
import AccountServiceCallRate from '../../components/adminComponents/account/accountServiceCallRate/accountServiceCallRate';
import { getLoginUserName, getCompanyId, isManagement, isManagemed } from '../../util/customMethods.js'
import moment from 'moment';
import { isValidForm } from '../../util/validationMethods'
import { getAccessLevelsByModule } from '../../util/customMethods.js'
let pageTitle = 'Add New Account';
const storageBehaviorConst = {
	"accRateplanId": 0,
	"accountId": 0,
	"custBhvrAfterAmnt": 0,
	"custBhvrEndTime": 0,
	"custBhvrFirstAmnt": 0,
	"custBhvrFirstDays": 0,
	"custBhvrId": 0,
	"custBhvrNextAmnt": 0,
	"custBhvrNextDays": 0,
	"custBhvrStartTime": 0,
	"idCompany": 0
}

let accountForm = {
	frmAccountInfo: {},
	frmBilling: {},
	formMonetization:{},
	frmPickAddressEntry: {},
	frmDropAddressEntry: {},
	frmDefaultAddress: {}
}
const accountTabs = {
	TB_ACC_ACCOUNT_INFO: 'TB_ACCOUNT_ACCOUNT_INFO',
	TB_ACC_BILLING: 'TB_ACC_BILLING',
	TB_ACC_ARS: 'TB_ACC_ARS',
	TB_ACC_DEF_INFO: 'TB_ACC_DEF_INFO',
	TB_ACC_SERVICE_CALL_RATE: 'TB_ACC_SERVICE_CALL_RATE',
	TB_ACC_HISTORY: 'TB_ACC_HISTORY',
	TB_ACC_COMP: 'TB_ACC_COMP',
	TB_COMPANY_BILLABLE: 'TB_COMPANY_BILLABLE'
}

class AccountRecPage extends Component {
	state = {
			tabKey: accountTabs.TB_ACC_ACCOUNT_INFO,
			aid: 0,
			modalShow: false,
			keyNo:null,
			storageBehaviorObj: Object.assign({}, storageBehaviorConst)
		}
		
	componentWillMount = () => {
		const queryValues = queryString.parse(this.props.location.search);
		const { dispatch } = this.props;
		if (parseInt(queryValues.aid) !== 0) {
			this.setState({ aid: parseInt(queryValues.aid) }, this.initAccountForm(queryValues.aid))
		} else {
			this.setState({ aid: 0 }, this.initAccountForm(0))
			dispatch(getEmptyChargeList(queryValues.aid));
		}
		dispatch(getChargeList(queryValues.aid));
	}
	checkViewAccess = (_accessLevel = {}) => {
		if (_accessLevel.basicAccess.view !== true) {
			this.props.history.push('/admin/notfound');
		}
	}
	initAccountForm = (id) => {
		const { dispatch } = this.props;
		if (id !== 0) {
			dispatch(clearAccountRec())
			this.getAccountRecordById(id);
			if (isManagement()){
				dispatch(getAssignedCompList(id))
				dispatch(getListOfAccountsLink(id))
			}
		} else {
			dispatch(clearAccountRec())
		}
	}
	getAccountRecordById = (id) => {
		const { dispatch } = this.props;
		dispatch(getAccountById(parseInt(id)));
	}


	Go = async (obj) => {
        const { dispatch } = this.props;
		    obj.acc_Id=this.state.aid;
            const res = await dispatch(addLink(obj));
			if((this.state.aid||0) !=0){
				dispatch(getListOfAccountsLink(this.state.aid))
			}
            if (res.statusCode === 200) {
                notify.createNotification('success', res.statusMsg, this.props.refNotify);
            } else {
                notify.createNotification('error', res.statusMsg, this.props.refNotify);
            };
        }

		deleteLink = async (id) => {
			const { dispatch } = this.props;
				const res = await dispatch(deleteLink(id));
				if((this.state.aid||0) !=0){
					dispatch(getListOfAccountsLink(this.state.aid))
				}
				if (res.statusCode === 200) {
					notify.createNotification('success', res.statusMsg, this.props.refNotify);
				} else {
					notify.createNotification('error', res.statusMsg, this.props.refNotify);
				};
			}
    

		/*function call to release invoice */
        savbillings = async (e,ind,accountId) => {
			e.preventDefault();
			const { dispatch } = this.props
			const res = await dispatch(addUpdatBillableIndex(ind,accountId));
			if (res.statusCode === 200) {
				notify.createNotification('success', res.statusMsg, this.props.refNotify);
				this.setState({keyNo:null})
			} 
			else {
				notify.createNotification('error', res.statusMsg, this.props.refNotify);
			}
		}

	onAccountNotesSubmit = async (e, notesObj, index) => {
		const { dispatch } = this.props;
		notesObj.notesDate = moment().format('MM/DD/YYYY')
		notesObj.notesTime = moment().format("HH:mm:ss")
		notesObj.accountId = this.state.aid;
		notesObj.corporateId = 1;
		notesObj.userId = getLoginUserName()
		await dispatch(setAccountNotesToList(notesObj, index))
	}
	onBillingNotesSubmit = async (e, notesObj, index) => {
		const { dispatch } = this.props;
		notesObj.notesDate = moment().format('MM/DD/YYYY')
		notesObj.notesTime = moment().format("HH:mm:ss")
		notesObj.accountId = this.state.aid;
		notesObj.corporateId = 1;
		notesObj.companyId = getCompanyId()
		notesObj.userId = getLoginUserName()
		await dispatch(setBillingNotesToList(notesObj, index))

	}

	render() {
		const accessLevelObj = getAccessLevelsByModule('Account');
		document.title = this.state.aid === 0 ? pageTitle : this.props.accountBasicInfo.accountName;
		this.checkViewAccess(accessLevelObj)
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i>
									{this.state.aid === 0 ? ` ${pageTitle}` : ` Edit ${this.props.accountBasicInfo.accountName}`}</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<Tabs
						id="tab-account"
						className="tab"
						activeKey={this.state.tabKey}
						onSelect={key => this.setState({ tabKey: key })}
					>
						<Tab eventKey={accountTabs.TB_ACC_ACCOUNT_INFO}
							title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Account Information</span>}>
							<AccountInfo callReasons={this.props.callReasons}
								accessLevel={accessLevelObj || {}}
								onTimePlanFieldChange={(keyName, value) => {
									this.props.dispatch(accountTimePlanFieldChange(keyName, value));
								}}
								initCallBack={(frm) => {
									accountForm.frmAccountInfo = frm;
								}}
								accountTypes={this.props.accountTypes}
								allStates={this.props.allStates}
								salesRep={this.props.salesRep}
								reviewLocation={this.props.reviewLocation}
								accountBasicInfo={this.props.accountBasicInfo}
								onAccountBasicInfoFieldsChange={(keyName, value) => {
									this.props.dispatch(accountBasicInfoFieldChange(keyName, value));
								}}
								onAccountAddressFieldChange={(keyName, value) => {
									this.props.dispatch(accountAddressFieldChange(keyName, value));
								}}
								onAccountNotesSubmit={(e, notesObj, index) => {
									this.onAccountNotesSubmit(e, notesObj, index)
								}}
								onAccountContactSubmit={async (e, contactObj, index, success, fail) => {
									const { dispatch } = this.props;
									contactObj.accountId = this.state.aid;
									contactObj.corporateId = 1;
									contactObj.companyId = getCompanyId();
									const res = await dispatch(setAccountContactToList(contactObj, index));
									if (res.statusCode === 200) {
										success(res.statusMsg)
									} else {
										fail(res.statusMsg)
									}
								}}
								onSubmitBasicInfo={(e) => {
									this.setState({ tabKey: isManagement()?accountTabs.TB_ACC_BILLING:accountTabs.TB_ACC_SERVICE_CALL_RATE })
								}}
								onAccountContactDelete={async (index) => {
									await this.props.dispatch(deleteContactToList(this.state.aid, index, 'ACCOUNT'))
								}}
								onAccountNotesDelete={async (index) => {
									await this.props.dispatch(deleteNoteToList(this.state.aid, index, 'ACCOUNT'))
								}}
								onActiveInactiveAccount={async (status) => {
									const res = await this.props.dispatch(activeInactiveAccount(this.state.aid, status))
									if (res.statusCode === 200) {
										notify.createNotification('success', res.statusMsg, this.props.refNotify);
									} else {
										notify.createNotification('error', res.statusMsg, this.props.refNotify);
									}
								}}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									accountForm.formMonetization.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									} else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo))
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}
								}}
							/>
						</Tab>
						{isManagement()?<Tab eventKey={accountTabs.TB_ACC_BILLING} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Billing</span>}>
							<Billing initCallBack={(frm) => {
								accountForm.frmBilling = frm;
							}}
								accessLevel={accessLevelObj || {}}
								userDefaultCharges={this.props.chargeList}
								setBillAddressAsAccountAddress={(e) => {
									this.props.dispatch(setBillAddressAsAccountAddress())
								}}
								onAccountAddressFieldChange={(keyName, value) => {
									this.props.dispatch(accountAddressFieldChange(keyName, value));
								}}
								onAccountBasicInfoFieldsChange={(keyName, value) => {
									this.props.dispatch(accountBasicInfoFieldChange(keyName, value));
								}}
								onBillingNotesSubmit={(e, notesObj, index) => {
									this.onBillingNotesSubmit(e, notesObj, index)
								}
								}
								onBillingContactSubmit={async (e, contactObj, index, success, fail, isDefaultContact = false) => {
									const { dispatch } = this.props;
									contactObj.accountId = this.state.aid;
									contactObj.corporateId = 1;
									contactObj.companyId = getCompanyId();
									const res = await dispatch(setBillingContactToList(contactObj, index, isDefaultContact));
									if (res.statusCode === 200) {
										success(res.statusMsg);
									} else {
										fail(res.statusMsg)
									}
								}}
								allStates={this.props.allStates}
								accountBasicInfo={this.props.accountBasicInfo}
								onBillingInfoSubmit={(e) => {
									this.setState({ tabKey: accountTabs.TB_ACC_DEF_INFO })
								}}
								onBillingContactDelete={async (index) => {
									await this.props.dispatch(deleteContactToList(this.state.aid, index, 'BILLING'))
								}}
								onBillingNotesDelete={async (index) => {
									await this.props.dispatch(deleteNoteToList(this.state.aid, index, 'BILLING'))
								}}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									accountForm.formMonetization.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									} else if (!isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.formMonetization });
									}else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo))
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}
								}}
							/>
						</Tab>:null}
						{isManagement()?
						<Tab eventKey={accountTabs.TB_ACC_DEF_INFO} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Default Information</span>} >
							<DefaultAddress initCallBack={(frm) => {
								accountForm.frmDefaultAddress = frm;
							}}
								accessLevel={accessLevelObj || {}}
								accountList={this.props.accountList || []}
								accountBasicInfo={this.props.accountBasicInfo}
								allStates={this.props.allStates}
								reviewLocation={this.props.reviewLocation}
								onAddressFieldChange={(type, keyName, value) => {
									this.props.dispatch(accountDefaultLocationFieldChange(type, keyName, value))
								}}
								onAddressObjectChange={(key, objAddress) => {
									this.props.dispatch(accountDefaultLocationAddressChange(key, objAddress))
								}}
								onSubmitDefaultAddress={(e) => {
									this.setState({ tabKey: accountTabs.TB_ACC_SERVICE_CALL_RATE })
								}}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									accountForm.formMonetization.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									}else if (!isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.formMonetization });
									} else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo));
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}
								}}
								initPickAddressCallBack={(frm) => {
									accountForm.frmPickAddressEntry = frm;
								}}
								initDropAddressCallBack={(frm) => {
									accountForm.frmDropAddressEntry = frm;
								}} />
						</Tab>:null}
						<Tab eventKey={accountTabs.TB_ACC_SERVICE_CALL_RATE} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Service Call Rate</span>} >
							<AccountServiceCallRate
								accessLevel={accessLevelObj || {}}
								accountBasicInfo={this.props.accountBasicInfo}
								commissionTypes={this.props.commissionTypes}
								defaultRatePlanList={this.props.ratePlanList}
								userDefaultCharges={this.props.chargeList}
								towTypes={this.props.towTypes}
								selectedRatePlan={this.props.selectedRatePlan}
								onRatePlanChange={(ratePlanName) => {
									this.props.dispatch(setSelectedAccountRatePlan(ratePlanName));
								}}
								accountRatePlanFieldChange={(selectedPlan, parentKey, childKey, value) => {
									this.props.dispatch(accountRatePlanFieldChange(selectedPlan, parentKey, childKey, value));
								}}
								accountRatePlanCustomFieldChange={(selectedPlan, parentKey, childKey, value, index, key) => {
									this.props.dispatch(accountRatePlanCustomFieldChange(selectedPlan, parentKey, childKey, value, index, key));
								}}
								loadAccountDefaultRatePlan={(selectedPlan, parentKey, childKey, value) => {
									this.props.dispatch(loadAccountDefaultRatePlan(selectedPlan, parentKey, childKey, value))
								}}
								accountRatePlanDiscountAllFieldChange={(selectedPlan, parentKey, childKeyArray, value) => {
									let _val = (!value ? 0.00 : value)
									this.props.dispatch(accountRatePlanDiscountAllFieldChange(selectedPlan, parentKey, childKeyArray, _val))
								}}
								onAccountServiceCallRateSubmit={async (e, accountRatePlan) => {
									const res = await this.props.dispatch(onAccountServiceCallRateSubmit(accountRatePlan, this.state.aid))
									if (res.statusCode === 200) {
										notify.createNotification('success', res.statusMsg, this.props.refNotify);
									} else {
										notify.createNotification('error', res.statusMsg, this.props.refNotify);
									}
								}}
								onAccountServiceCallRateNextClick={(e) => {
									this.setState({ tabKey: accountTabs.TB_COMPANY_BILLABLE });
								}}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									accountForm.formMonetization.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									} else if (!isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.formMonetization });
									}else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo))
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}
								}}
								addCustomCharge={(selectedPlan, parentKey, childKey, value) => {
									this.props.dispatch(addCustomCharges(selectedPlan, parentKey, childKey, value))
								}}
								removeCustomCharge={(selectedPlan, parentKey, childKey, index) => {
									this.props.dispatch(removeCustomCharges(selectedPlan, parentKey, childKey, index))
								}}
							/>
						</Tab>
						{(isManagement()) ? <Tab eventKey={accountTabs.TB_COMPANY_BILLABLE} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Charge</span>} >
							<ChargeSettings
							initCallBack={(frm) => {accountForm.formMonetization = frm;}}	
							accountBasicInfo={this.props.accountBasicInfo} keyNo={this.state.keyNo}
							addRemoveBillableIndex={(index) => {
								this.props.dispatch(addRemoveBillableIndex(index));
								this.setState({keyNo:index==0?null:index-1})
							}} onAccountBasicInfoFieldsChange={(ind,keyName, value) => {
									this.props.dispatch(accountBillableChange(ind,keyName, value));}}
									onAccountNextClick={(e) => {
									accountForm.formMonetization.validateAll();
									if (isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ARS });}}
									}
									addUpdatBillableIndex={(e,ind) => {
										e.preventDefault();
										accountForm.formMonetization.validateAll();
										if (isValidForm(accountForm.formMonetization)) {
											this.savbillings(e,ind,this.props.accountBasicInfo.accountId);}}
										}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.formMonetization.validateAll();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									}else if (!isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.TB_COMPANY_BILLABLE });
									}
									 else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo));
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}
								}}
								canEdit={accessLevelObj.basicAccess.edit || false} setBillableStatus={(e, chargeId) => { this.props.dispatch(setBillableStatus(e, chargeId)) }}
								globalChargeListData={this.props.accountBasicInfo.accountBillableChargeList || []} />
						</Tab> : null}
						{isManagement()?<Tab eventKey={accountTabs.TB_ACC_ARS} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>ARS Config</span>}>
							<Billing_ARS initCallBack={(frm) => {
								accountForm.frmBilling = frm;
							}}
								accessLevel={accessLevelObj || {}} aid={this.state.aid||0}
								Go={(obj)=>{this.Go(obj);}} deleteLink={(id)=>{this.deleteLink(id);}}
								onAccountBasicInfoFieldsChange={(keyName, value) => {
									this.props.dispatch(accountBasicInfoFieldChange(keyName, value));
								}}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									accountForm.formMonetization.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									} else if (!isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.TB_COMPANY_BILLABLE });
									}else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo))
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}

								}}
								accountList={this.props.optionAccList}
								accountBasicInfo={this.props.accountBasicInfo}
								listOfAccountsLink={this.props.listOfAccountsLink||[]}
								next={()=>{this.setState({ tabKey: accountTabs.TB_ACC_HISTORY });}}
							/>
						</Tab>:null}
						{isManagement()?<Tab eventKey={accountTabs.TB_ACC_HISTORY} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i> History</span>} >
							<AccountHistory
								accessLevel={accessLevelObj || {}}
								accountBasicInfo={this.props.accountBasicInfo}
								onTimePlanSubmit={async (e) => {
									const { dispatch } = this.props;
									e.preventDefault();
									accountForm.frmAccountInfo.validateAll();
									accountForm.frmBilling.validateAll();
									accountForm.formMonetization.validateAll();
									if (!isValidForm(accountForm.frmAccountInfo)) {
										this.setState({ tabKey: accountTabs.TB_ACC_ACCOUNT_INFO });
									} else if (!isValidForm(accountForm.frmBilling)) {
										this.setState({ tabKey: accountTabs.TB_ACC_BILLING });
									} else if (!isValidForm(accountForm.formMonetization)) {
										this.setState({ tabKey: accountTabs.TB_COMPANY_BILLABLE });
									}else {
										const res = await dispatch(onSaveAccount(this.props.accountBasicInfo))
										if (res.statusCode === 200) {
											notify.createNotification('success', res.statusMsg, this.props.refNotify);
											this.props.history.push('/admin/account')
										} else {
											notify.createNotification('error', res.statusMsg, this.props.refNotify);
										}
									}

								}}
							/>
						</Tab>:null}
						{(isManagement() && this.state.aid != 0) ? <Tab eventKey={accountTabs.TB_ACC_COMP} title={<span><i className="fa fa-info-circle" style={{ display: 'block', textAlign: 'center' }}></i>Assigned Companies</span>} >
							<AccountAssignedComp assignedComList={this.props.assignedComList}
							/>
						</Tab> : null}
						
					</Tabs>
				</div>

			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		authInfo: state.authInfo,
		refNotify: state.refNotify,
		accountTypes: state.accountTypes.accountTypes,
		allStates: state.states.stateList,
		salesRep: state.salesRep.salesRep,
		reviewLocation: state.location.locationList,
		accountList: state.accountList.accountInfoList,
		selectedRatePlan: state.accountList.selectedAccRatePlan,
		accountBasicInfo: state.accountList.accountBasicInfo,
		assignedComList: state.accountList.assignedComList,
		listOfAccountsLink: state.accountList.listOfAccountsLink,
		callReasons: state.callReasons.callReasons,
		towTypes: state.towTypeList.towTypes,
		commissionTypes: state.commission.commissionTypes,
		ratePlanList: state.ratePlanList.ratePlanList,
		chargeList: state.ratePlanList.chargeList,
		optionAccList: state.invoiceInfo.accountList,
	}
};

export default withRouter(connect(mapStateToProps)(AccountRecPage));
