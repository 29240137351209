import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {isManagement} from '../../util/customMethods';
import { notify } from '../../util/notifications';
import { isValidForm } from '../../util/validationMethods';
import PaymentSettings from '../../components/adminComponents/paymentSetting/paymentSettings'
import {savePaymentDetails,setPaymentInfo,fetchPaymentDetails,setPaymentInfoToEmpty,setMapOfKeyInNoSwipePin,setMapOfKeyInNoSwipePinValue} from '../../actions/adminAction/companyActions'
const pageTitle = 'Payment setting';
let paymentDetailsfrm={};
class PaymentDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:props.data,
			paymentObj:props.paymentObj
		}
	}
	componentWillReceiveProps(nextProps) {
        this.setState({data:nextProps.data,paymentObj:nextProps.paymentObj});
    }
	componentWillMount=()=>{
		this.props.dispatch(setPaymentInfoToEmpty());
		this.props.dispatch(fetchPaymentDetails());
	}
	componentWillUnmount=()=>{
		this.props.dispatch(setPaymentInfoToEmpty());
	}
	savePaymentDetails = async () => {
		const { dispatch } = this.props;
		const res = await dispatch(savePaymentDetails());
		if (res.statusCode === 200) {
			notify.createNotification('success', res.statusMsg, this.props.refNotify);
		} else {
			notify.createNotification('error', res.statusMsg, this.props.refNotify);
		}
	}
    render() {
		document.title=`${pageTitle}`;
		return (
			<>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-credit-card"></i> Payment Setting</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<div className="row">
						<div className="col-md-12">
							<PaymentSettings 
							paymentObj={this.props.paymentObj}
							savePaymentDetails={()=>{
								paymentDetailsfrm.validateAll();
								if(isValidForm(paymentDetailsfrm))
								this.savePaymentDetails()}
							} 
							formCallback={(c)=>{paymentDetailsfrm=c}}
							setPaymentInfo={(event)=>{
								this.props.dispatch(setPaymentInfo(event))}
							}
							setMapOfKeyInNoSwipePinValue={(event,loc)=>{this.props.dispatch(setMapOfKeyInNoSwipePinValue(event,loc))}}
							data={this.props.reviewLocation}
							setMapOfKeyInNoSwipePin={
								(location)=>{
								this.props.dispatch(setMapOfKeyInNoSwipePin(location))}
							}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		paymentObj: state.companies.paymentObj,
		reviewLocation:state.location.locationList,
		refNotify: state.refNotify,
	}
};
export default withRouter(connect(mapStateToProps)(PaymentDetails));

