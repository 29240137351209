import * as actionTypes from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId } from '../../util/customMethods';
import { GlobalValues } from '../../util/constants';

export const getRatePlanList=(searchObj)=>{
    const _searchObj=searchObj?searchObj:{"searchQuery":"","ratePlanStatus":"1"}
    const _url=`rateplan/search/defaultRatePlan?qry=${_searchObj.searchQuery}&ratePlanStatus=${_searchObj.ratePlanStatus}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async(dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_ALL_RATEPLANS,
                    ratePlanList:response.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_ALL_RATEPLANS,
                    ratePlanList:[]
                });
            }
        }catch(error){
            dispatch({
                type: actionTypes.GET_ALL_RATEPLANS,
                ratePlanList:[]
            });
        }
    }
}
export const getRatePlanDetails=(id)=>{
    const _url= `rateplan/search/defaultRatePlanForEdit?ratePlanId=${id}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200){
                dispatch({
                    type: actionTypes.GET_RATEPLAN_RECORD,
                    ratePlanObject:response.data
                });
            }else{
                dispatch({
                    type: actionTypes.GET_RATEPLAN_RECORD,
                    ratePlanObject:undefined
                });
            }

        }catch(error){
            dispatch({
                type: actionTypes.GET_RATEPLAN_RECORD,
                ratePlanObject:undefined
            });
        }
    }
}
export const changeRatePlanField=(recObject)=>{
    return (dispatch)=>{
         dispatch({
            type: actionTypes.GET_RATEPLAN_RECORD,
            ratePlanObject:recObject
        }); 
    }
}
export const saveRatePlan=(typeObj)=>{
    return async(dispatch) => {
        let _url=`rateplan/add/defaultRatePlan?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        typeObj['freeHours']=typeObj['freeHours']===''?0:typeObj['freeHours'];
        //dispatch(spinnerActions.showSpinner());
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,typeObj)
            if(response.status===200) { 
                return {statusCode:response.status, statusMsg:response.data.message};
            } else{
                return {statusCode:null, statusMsg:response.data.message};
            }
        }catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null, statusMsg:error.message};
        }           
    }
}
export const clearRatePlan=(id='')=>{
    return (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        dispatch({
            type: actionTypes.CLEAR_RATEPLAN_RECORD,
            ratePlanObject:undefined
        });
        if(id === 'new'){
            let _ratePlanObject=Object.assign({},(store.getState()).ratePlanList.ratePlanObject);
            _ratePlanObject.defaultRatePlanId='new'
            dispatch({
                type: actionTypes.CLEAR_RATEPLAN_RECORD,
                ratePlanObject:_ratePlanObject
            });
        }
    //dispatch(spinnerActions.hideSpinner());
    }
}


export const getEmptyChargeList=(aid)=>{
    const _url=`/account/getEmptyAccountBillableCharges?access_token=${getAccessToken()}`;
    return async(dispatch) => {
        let _accountBasicInfo = (store.getState()).accountList.accountBasicInfo;
       try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                _accountBasicInfo.accountBillableChargeList=response.data.filter((item) => {
                    return (
                      item.chargeName !== "Tow Charge" && 
                      item.chargeName !== "Labor Charge" &&
                      item.chargeName !== "Storage Charge" &&
                      item.chargeName !== "Mileage Charge" &&
                      item.chargeName !== "Miscellaneous Charge" &&
                      item.chargeName !== "Gate Charge"            
                    )
                })
                    dispatch({
                        type: actionTypes.GET_ACCOUNT_REC,
                        accountBasicInfo:_accountBasicInfo
                    });
            }else{    
                dispatch({
                    type: actionTypes.GET_ACCOUNT_REC,
                    accountBasicInfo:_accountBasicInfo
                });
            }
        }
        catch(error) {
            dispatch({
                type: actionTypes.GET_ACCOUNT_REC,
                accountBasicInfo:_accountBasicInfo
            });            
        } 
    }
}

export const getChargeList=(aid)=>{
    const _url=`rateplan/search/chargeList?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async(dispatch) => {
       try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_CHARGE_LIST,
                    chargeList:response.data.filter((item) => {
                        return (
                          item.chargeName !== "Tow Charge" && 
                          item.chargeName !== "Labor Charge" &&
                          item.chargeName !== "Storage Charge" &&
                          item.chargeName !== "Mileage Charge" &&
                          item.chargeName !== "Miscellaneous Charge" &&
                          item.chargeName !== "Gate Charge"            
                        )
                    })
                });
               }else{    
                dispatch({
                    type: actionTypes.GET_CHARGE_LIST,
                    chargeList:[]
                });
            }
        }
        catch(error) {
            dispatch({
                type: actionTypes.GET_CHARGE_LIST,
                chargeList:[]
            });            
        } 
    }
}
export const getMonetizationChargeList=()=>{
    const _url=`dashboard/chargeList?managementCompId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async(dispatch) => {
       try{
            const response=await getAxiosInstance(moduleTypes.DISPATCH).get(_url)
            if(response.status===200) {         
                dispatch({
                    type: actionTypes.GET_MONETIZATION_CHARGE_LIST,
                    monetizationChargeList:response.data.response
                });
            }else{    
                dispatch({
                    type: actionTypes.GET_MONETIZATION_CHARGE_LIST,
                    monetizationChargeList:[]
                });
            }
        }
        catch(error) {
            dispatch({
                type: actionTypes.GET_MONETIZATION_CHARGE_LIST,
                monetizationChargeList:[]
            });            
        } 
    }
}

export const saveMonetizationChargeList=()=>{
    const _url=`dashboard/chargeList?managementCompId=${getCompanyId()}&access_token=${getAccessToken()}`;
    let monetizationChargeList=(store.getState()).ratePlanList.monetizationChargeList
    return async(dispatch) => {
       try{
            const response=await getAxiosInstance(moduleTypes.DISPATCH).put(_url,JSON.stringify(monetizationChargeList),{
                headers: {
                    'Content-Type': 'application/json',
                }})
            return response.data;
        }
        catch(error) {  
            return error;        
        } 
    }
}

export const changeGlobalCharges=(chargeListId)=>{
    return (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
       dispatch({
        type: actionTypes.SET_CUSTOM_CHARGE_TYPE,
        newCustomCharge:getChargeRecFromStore(chargeListId)
    });
    //dispatch(spinnerActions.hideSpinner());
    }
}

export const setBillableStatus=(event,chargeListId)=>{
    return (dispatch) => {
    let monetizationChargeList=(store.getState()).ratePlanList.monetizationChargeList
    let idx=  monetizationChargeList.findIndex(x => x.idChargeList ==chargeListId);
    if(idx!==-1)
    monetizationChargeList[idx].isBillable=event.target.checked;
       dispatch({
        type: actionTypes.GET_MONETIZATION_CHARGE_LIST,
        monetizationChargeList:monetizationChargeList
    });
    }
}

const getChargeRecFromStore=(chargeListId)=>{
    let _ratePlanList=Object.assign({},(store.getState()).ratePlanList)
     let idx= _ratePlanList.chargeList.findIndex(charge=>{
        return charge.chargeListId===chargeListId
    });
    if(idx===-1){
        return undefined;
    }
    return JSON.parse(JSON.stringify(_ratePlanList.chargeList[idx])); 
}
export const saveChargeList=(typeObj)=>{
    return async(dispatch) => {
        let _url=`rateplan/add/chargeList?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        //dispatch(spinnerActions.showSpinner());
        let chargeListObject={"chargeList":typeObj};
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,chargeListObject)
            if(response.status===200) { 
                dispatch(getChargeList());
                return {statusCode:response.status, statusMsg:response.data.message};                
            } else{                
                return {statusCode:null, statusMsg:response.data.message};
            }
            //dispatch(spinnerActions.hideSpinner());
        }catch(error) {
            //dispatch(spinnerActions.hideSpinner());
            return {statusCode:null, statusMsg:error.message};
        }
    }
}
export const onCustomChargeNameFieldChange=(key,value)=>{
    return (dispatch) => {
        let _ratePlanList=Object.assign({},(store.getState()).ratePlanList)
        let chargeObj=_ratePlanList.newCustomCharge;
        chargeObj[key]=value

        dispatch({
            type: actionTypes.SET_CUSTOM_CHARGE_TYPE,
            newCustomCharge: JSON.parse(JSON.stringify(chargeObj))
        }); 
    } 
}
export const clearCustomChargeName=()=>{
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_CUSTOM_CHARGE_TYPE,
            newCustomCharge:undefined
        }); 
    } 
}

export const onCustomChargeNameSubmit=(chargeObj)=>{
    return async(dispatch) => {
        let _url=`rateplan/add/chargeList?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        //dispatch(spinnerActions.showSpinner());
        let chargeListObject={"chargeList":[chargeObj]};
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,chargeListObject)
            if(response.status===200) { 
                dispatch(getChargeList());
                dispatch(clearCustomChargeName());
                return {statusCode:response.status, statusMsg:response.data.message};  
            } else{
                return {statusCode:null, statusMsg:response.data.message}; 
            }
            //dispatch(spinnerActions.hideSpinner());
        }catch(error) {
            return {statusCode:null, statusMsg:error.message};
        }
    }        
}