import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import queryString  from 'query-string'
import {withRouter} from 'react-router-dom'
import {Tabs,Tab} from 'react-bootstrap';
import {getEmployeeRec,clearEmployeeRec,onFieldChange,onDashboardPermissionChange, onProfileImageUpload,
  saveEmployee,onAccessLevelsChanged,onEmployeeRemarksAdded,onEmployeeActiveStatusChange,
  onEmpLocationChange} from '../../actions/adminAction/employeeActions';
import {validateUser} from '../../actions/adminAction/companyActions';
import BasicInfo from '../../components/adminComponents/employees/basicInfo/basicInfo';
import AdditionalInfo from '../../components/adminComponents/employees/additionalInfo/additionalInfo';
import AccessPermissions from '../../components/adminComponents/employees/accessLevel/accessPermissions';
import DashboardPermissions from '../../components/adminComponents/employees/dashboardPermissions/dashboardPermissions';
import {notify} from '../../util/notifications'
import {getAccessLevelsByModule,isManagement} from '../../util/customMethods';
import {isValidForm} from '../../util/validationMethods'
let pageTitle='Add New Employee';
let employeeForm={
  formBasicInfo:{},
  formAdvInfo:{}
}
const empTabs={
  TB_EMP_BASIC_INFO: 'TB_EMP_BASIC_INFO',
  TB_EMP_ADDITIONAL_INFO:'TB_EMP_ADDITIONAL_INFO',
  TB_EMP_ACCESS_LEVEL:'TB_EMP_ACCESS_LEVEL',
  TB_EMP_DASHBOARD_PERMISSIONS:'TB_EMP_DASHBOARD_PERMISSIONS'
};

class EmployeesRecPage extends Component {
	constructor() {
	  super();
	  this.state = {
	    tabKey: empTabs.TB_EMP_BASIC_INFO,
      eid: 0,
      isEmailExits:true
	  }
    this.initEmpForm = this.initEmpForm.bind(this);
    this.checkViewAccess=this.checkViewAccess.bind(this);
	  this.getEmpRecordById = this.getEmpRecordById.bind(this);
	  this.onFieldValueChange = this.onFieldValueChange.bind(this);
	  this.onSubmitEmployee = this.onSubmitEmployee.bind(this);
	}
	componentWillMount = () => {
	  const queryValues = queryString.parse(this.props.location.search);
	  if (parseInt(queryValues.eid) !== 0) {
	    this.setState({
	      eid: parseInt(queryValues.eid)
	    }, this.initEmpForm(queryValues.eid))
	  } else {
	    this.setState({
	      eid: 0
	    }, this.initEmpForm(0))
	  }
	}
	initEmpForm = (id) => {
	  const {
	    dispatch
	  } = this.props;
	  if (id !== 0) {
	    this.getEmpRecordById(id);
	  } else {
	    dispatch(clearEmployeeRec())
	  }
	}
	getEmpRecordById = async(id) => {
	  const {
	    dispatch
	  } = this.props;
    await dispatch(getEmployeeRec(parseInt(id)));
    this.forceUpdate()
	}
	onFieldValueChange = (key, value) => {
	  const {
	    dispatch
	  } = this.props;
	  dispatch(onFieldChange(key, value));
  }
  emailExits=async(dname)=>{
		let status =  await validateUser(0,dname,'email');
		this.setState({isEmailExits:status});
	}
  onEmpLocationChange = (empObj) =>{
    const {
	    dispatch
	  } = this.props;
	  dispatch(onEmpLocationChange(empObj));
  }
	onSubmitEmployee = async () => {
    const { dispatch } = this.props;
    employeeForm.formBasicInfo.validateAll();
    if(isManagement()){
      employeeForm.formAdvInfo.validateAll();
    }
    if(!isValidForm(employeeForm.formBasicInfo)){
      this.setState({tabKey:empTabs.TB_EMP_BASIC_INFO});
    }else if(isManagement() &&!isValidForm(employeeForm.formAdvInfo)){
      this.setState({tabKey:empTabs.TB_EMP_ADDITIONAL_INFO});
    }else{
      const res=await dispatch(saveEmployee(this.props.empRec))
      if(res.statusCode===200){
       notify.createNotification('success', res.statusMsg, this.props.refNotify);
       this.props.history.push('/admin/employees');
     }else {
       notify.createNotification('error', res.statusMsg, this.props.refNotify);
     }
    } 
  }
  checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
  }
  permissionTabStatus = () => {
    if (isManagement())
      return ((this.state.eid || 0) != 0);
    else
      return true;
  }
  render() {
    const accessLevelObj=getAccessLevelsByModule('Employee');
    document.title= this.state.eid===0?pageTitle:this.props.empRec.employeeInformation.firstName+' '+this.props.empRec.employeeInformation.lastName;
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
        <div className="page-header">	
          <div className="row mb-10">			
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4><i className="fa fa-user"></i>
                {this.state.eid===0 ? ` ${pageTitle}` : ` Edit ${this.props.empRec.employeeInformation.firstName} ${this.props.empRec.employeeInformation.lastName}`}
                </h4>
              </div>						
            </div>
          </div>			
        </div>
        <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
          <Tabs
            id="tab-account"
            className="tab"
            activeKey={this.state.tabKey}
            onSelect={key => this.setState({ tabKey:key })}
          >
            <Tab
              eventKey={empTabs.TB_EMP_BASIC_INFO}
              title={<span><i className="fa fa-info-circle" style={{display:'block',textAlign:'center'}}></i>Basic Information</span>}
            >
              <BasicInfo
                states={this.props.states||[]}
                locations={this.props.locations||[]} 
                accessLevel={accessLevelObj||{}}
                eid={this.state.eid}
                empRec={this.props.empRec}
                onFieldValueChange={(key, value)=>{
                  this.onFieldValueChange(key, value)
                }}
                emailExits={(email)=>{this.emailExits(email)}}
							  isEmailExits={this.state.isEmailExits}
                onEmpLocationChange ={(empObj)=>{
                  this.onEmpLocationChange(empObj)
                }}
                onSubmitBasicInfo={(e)=>{
                  this.setState({tabKey:isManagement()?empTabs.TB_EMP_ADDITIONAL_INFO:empTabs.TB_EMP_ACCESS_LEVEL});
                }}
                onProfileImageUpload={(profileFile)=>{
                  this.props.dispatch(onProfileImageUpload(profileFile));
                }}
                initCallBack={(frm)=>{
                  employeeForm.formBasicInfo=frm;
                }} 
              />
            </Tab>
            { isManagement()? <Tab
              eventKey={empTabs.TB_EMP_ADDITIONAL_INFO}
              title={<span><i className="fa fa-info-circle" style={{display:'block',textAlign:'center'}}></i>Additional Information</span>}
            >
            <AdditionalInfo
                accessLevel={accessLevelObj||{}}
                states={this.props.states}
                departments={this.props.department}
                showEmpActiveStatus={this.state.eid == '0' ? false : true}
                licenceTypes={this.props.licenceTypes}
                empRec={this.props.empRec}
                onFieldValueChange={(key,value)=>{
                  this.onFieldValueChange(key,value)
                }}
                onUserRemarksAdded={(e,userRemarkObj)=>{
                  this.props.dispatch(onEmployeeRemarksAdded(userRemarkObj))
                }}
                onActiveStatusChange={(eid,activeStatus)=>{
                   this.props.dispatch(onEmployeeActiveStatusChange(eid,activeStatus))
                }} onSubmitAdditionalInfo={(e)=>{
                  this.setState({tabKey:empTabs.TB_EMP_ACCESS_LEVEL});
                }}
                initCallBack={(frm)=>{
                  employeeForm.formAdvInfo=frm;
                }} 
              />
            </Tab>:null}
            <Tab
              eventKey={empTabs.TB_EMP_ACCESS_LEVEL}
              title={<span><i className="fa fa-info-circle" style={{display:'block',textAlign:'center'}}></i>Permissions</span>}
            >
              <AccessPermissions accessLevel={this.props.empRec.empAccess} eid={this.state.eid}
              onAccessLevelChanged={async (module,group,page)=>{
                let testObj=this.props.empRec.empAccess
                let idx=testObj[module][group].findIndex(_page=>{
                  return _page.moduleId===page.moduleId
                })
                if(idx!==-1){
                  testObj[module][group][idx]=page;
                }
                await this.props.dispatch(onAccessLevelsChanged(testObj));
                this.forceUpdate();
                }} 
                onSubmitAccessLevel={(e)=>{
                  if(!isManagement()){
                    this.onSubmitEmployee();
                  }else{
                    this.setState({tabKey:empTabs.TB_EMP_DASHBOARD_PERMISSIONS});
                  }
                }} 
                onAccessLevelAllChanged={async (accessObj)=>{
                  await this.props.dispatch(onAccessLevelsChanged(accessObj));
                  this.forceUpdate();
                }} />

            </Tab>
            {isManagement()?
             <Tab
              eventKey={empTabs.TB_EMP_DASHBOARD_PERMISSIONS}
              title={<span><i className="fa fa-info-circle" style={{display:'block',textAlign:'center'}}></i>Dashboard Permissions</span>}
            >
              <DashboardPermissions
                accessLevel={accessLevelObj||{}}
                empRec={this.props.empRec}
                onItemPermissionChange={(item)=>{              
                  this.props.dispatch(onDashboardPermissionChange(item))
                }}
                onSubmitEmployee={(e)=>{
                  this.onSubmitEmployee()
                }}
              />
            </Tab>:null}
          </Tabs>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
    department:state.department.departmentList.departments,
    states:state.states.stateList,
    locations:state.location.locationList,
    empRec:state.employees.employeeRec,
    licenceTypes:state.licenseTypes.licenseTypeList
  }
};
export default withRouter(connect(mapStateToProps)(EmployeesRecPage));