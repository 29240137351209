import * as actionTypes from '../actionTypes';
import {moduleTypes, GlobalValues} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId,isManagement } from '../../util/customMethods';
import moment from 'moment';


export const getSearchInvoiceList=(formObject)=>{    
    return async (dispatch)=>{
        const _url=`dashboard/getInvoicesBasedOnFilter?companyId=${getCompanyId()}&isManagement=${isManagement()}&access_token=${getAccessToken()}`
        try{
            const request=formObject;
            const response= await getAxiosInstance(moduleTypes.DISPATCH).put(_url,request,{ headers: { 'Content-Type': 'application/json' } });
            if(response.status===200) {        
                dispatch({
                    type: actionTypes.GET_INVOICE_SEARCH_LIST,
                    searchInvoiceList:response.data.response
                });
                return response.data;
            } else{    
                dispatch({
                    type: actionTypes.GET_INVOICE_SEARCH_LIST,
                    searchInvoiceList:[]
                });
                return response.data;
            }
        }catch(ex){
            dispatch({
                type: actionTypes.GET_INVOICE_SEARCH_LIST,
                searchInvoiceList:[]
            })
                return ex;
        }
    }
}
export const getChildList=(accountId)=>{    
    return async (dispatch)=>{
        const _url=`/invoice/companySelectOpt/${accountId||0}?managementCompanyId=${getCompanyId()}&access_token=${getAccessToken()}`
        try{
            const response= await getAxiosInstance(moduleTypes.DISPATCH).get(_url);
            if(response.status===200) {
                if((accountId||0) ==0){
                    dispatch({
                        type: actionTypes.LIST_OF_CHILD_COMP,
                        childComList:response.data
                    });
                } else{
                    dispatch({
                        type: actionTypes.LIST_OF_ASSOCIATE_COMP,
                        associateComp:response.data
                    });
                }   
                return response.data;
            } else{    
                dispatch({
                    type: actionTypes.LIST_OF_CHILD_COMP,
                    childComList:[]
                });
                return response.data;
            }
        }catch(ex){
            dispatch({
                type: actionTypes.LIST_OF_CHILD_COMP,
                childComList:[]
            })
                return ex;
        }
    }
}
export const clearSearchData=()=>{    
    return async (dispatch)=>{
                dispatch({
                    type: actionTypes.GET_INVOICE_SEARCH_LIST,
                    searchInvoiceList:[]
                });
           
    }
}

