import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const defaultAdminDashboard={
    invoiceMoreThan: [],
    expireAccounts: [],
    invoiceDays: "30",
    expiryDays: "30",
    allAccounts: []    
}
export const adminDashboardReducer = (state = defaultAdminDashboard, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_GET_INV_MORETHAN:
            return {...state,invoiceMoreThan:action.invoiceMoreThan}; 
        case actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS:
            return {...state,expireAccounts:action.expireAccounts};
        case actionTypes.ADMIN_GET_INV_MORETHAN_DAYS:
            return {...state,invoiceDays:action.invoiceMoreThanDays}; 
        case actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS_DAYS:
            return {...state,expiryDays:action.expiryAccountsDays}; 
        case actionTypes.ADMIN_GET_ALL_ACCOUNTS:
            return {...state,allAccounts:action.allAccounts};     
        default:
            return state;
    }
};