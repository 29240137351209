import * as actionTypes from '../../actions/actionTypes';
import moment from 'moment'
const defaultAPK={
  apkDeviceList:[],
  apkVersionList:[]
  
}
export const apkUploadReducer = (state = defaultAPK, action) => {
    switch (action.type) {
      case actionTypes.GET_ALL_APK_RECORDS_DATA:
        return {...state ,apkDeviceList:(action.apkDeviceList||defaultAPK.apkDeviceList)};
      case actionTypes.GET_ALL_APK_VERSIONS:
        return {...state , apkVersionList:(action.apkVersionList|| defaultAPK.apkVersionList) };        
      default:
        return state;
    }
  };