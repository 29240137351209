import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import CompanyNotes from '../companyInfo/companyNotes';
import { billableTypeManaged, billableTypeRetail } from '../../../../util/constants'
import { compStr } from '../../../../util/customMethods'
import {
    required, email, isValidForm, CondRequired,
    phone,
    zipValidation,
    isDecimal
} from '../../../../util/validationMethods';
import CompanyInfoContactList from '../companyInfo/companyInfoContactList';


class Billing extends Component {
    onBillingFieldChange = (e) => {
        let companyBilling = this.props.company.companyBilling
        companyBilling[e.target.name] = e.target.value
        this.props.onCompanyBillingInfoFieldsChange('companyBilling', companyBilling);
    }
    onAddressSyncChange = (e) => {
        const _company = this.props.company
        let companyBilling = _company.companyBilling || {}
        companyBilling[e.target.name] = e.target.checked ? true : false;
        if (e.target.checked) {
            companyBilling['addressLine1'] = _company['addressLine1'];
            companyBilling['addressLine2'] = _company['addressLine2']
            companyBilling['city'] = _company['city']
            companyBilling['state'] = _company['state']
            companyBilling['zip'] = _company['zip']
            companyBilling['phone'] = _company['contactNo']
            companyBilling['billingEmail'] = _company['contactEmail']
        }
        this.props.onCompanyBillingInfoFieldsChange('companyBilling', companyBilling);
        this.forceUpdate()
    }
    render() {
        const companyBilling = this.props.company.companyBilling || {};
        let managedStatus = compStr(this.props.company.companyType, 'Managed') || compStr(this.props.company.companyType, 'Retail');
        return (<Fragment>
            <div className="pd-10">
                <Form ref={c => {
                    this.formBilling = c;
                    this.props.initCallBack(c);
                }}>
                    <fieldset>
                        <div className="row">

                            <div className="col-md-12 col-sm-12 mb-10">

                                <div className="mb-5 row">
                                    <div className="col-sm-12">
                                        <h6>BILLING ADDRESS</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group row">
                                            <div className="col-sm-8">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" id="chBillingAddress" name="isSameCompInfo" checked={companyBilling.isSameCompInfo === true ? true : false} className="custom-control-input" onChange={this.onAddressSyncChange} />
                                                    <label htmlFor="chBillingAddress" className="custom-control-label">SAME AS COMPANY INFORMATION</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="">
                                            <label htmlFor="addressLine1" className="form-label col-form-label">ADDRESS LINE 1 *</label>
                                            <div className="">
                                                <Input type="text" name="addressLine1"
                                                    value={companyBilling.addressLine1 || ''}
                                                    className="form-control form-control-sm"
                                                    requiredmsg="Please enter Address Line1"
                                                    validations={[required]} onChange={this.onBillingFieldChange}
                                                    disabled={companyBilling.isSameCompInfo} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="addressLine2" className="form-label col-form-label">ADDRESS LINE 2 </label>
                                            <div className="">
                                                <Input type="text" name="addressLine2"
                                                    value={companyBilling.addressLine2 || ''}
                                                    className="form-control form-control-sm"
                                                    onChange={this.onBillingFieldChange}
                                                    disabled={companyBilling.isSameCompInfo} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="city" className="form-label col-form-label">CITY *</label>
                                            <div className="">
                                                <Input type="text" name="city"
                                                    value={companyBilling.city || ''}
                                                    className="form-control form-control-sm"
                                                    requiredmsg="Please Enter City Name" validations={[required]}
                                                    onChange={this.onBillingFieldChange}
                                                    disabled={companyBilling.isSameCompInfo} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="">
                                            <label htmlFor="state" className="form-label col-form-label">STATE *</label>
                                            <div className="">
                                                <Select
                                                    id="state"
                                                    name="state"
                                                    value={companyBilling.state}
                                                    validations={[required]}
                                                    className="form-control form-control-sm"
                                                    requiredmsg={'Please Select State'} onChange={this.onBillingFieldChange}
                                                    disabled={companyBilling.isSameCompInfo}
                                                >
                                                    <option value="" />
                                                    {this.props.states.map((loc, k) => {
                                                        return (
                                                            <option key={k} value={loc.optionVal}>
                                                                {loc.optionTxt}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="zip" className="form-label col-form-label">ZIP *</label>
                                            <div className="">
                                                <Input type="text" name="zip"
                                                    value={companyBilling.zip}
                                                    className="form-control form-control-sm" requiredmsg="Please Enter Zip Code"
                                                    validations={[required, zipValidation]} onChange={this.onBillingFieldChange}
                                                    disabled={companyBilling.isSameCompInfo} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="phone" className="form-label col-form-label">PHONE *</label>
                                            <div className="">
                                                <Input type="text" name="phone"
                                                    value={companyBilling.phone}
                                                    className="form-control form-control-sm" requiredmsg="Please Enter Phone No"
                                                    validations={[required, phone]} onChange={this.onBillingFieldChange} disabled={companyBilling.isSameCompInfo} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="">
                                            <label htmlFor="fax" className="form-label col-form-label">FAX</label>
                                            <div className="">
                                                <Input type="text" name="fax"
                                                    value={companyBilling.fax}
                                                    className="form-control form-control-sm"
                                                    validations={[phone]} onChange={this.onBillingFieldChange} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="billingEmail" className="form-label col-form-label">BILLING EMAIL *</label>
                                            <div className="">
                                                <Input type="text" name="billingEmail"
                                                    value={companyBilling.billingEmail}
                                                    className="form-control form-control-sm" requiredmsg="Please Enter Email"
                                                    validations={[required, email]} onChange={this.onBillingFieldChange} disabled={companyBilling.isSameCompInfo} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" hidden={managedStatus}>
                                                <label htmlFor="billableType" className="form-label col-form-label">BILLABLE TYPE *</label>
                                                <div className="">
                                                    <Select className="form-control" type="text" name="billableType"
                                                        value={companyBilling.billableType} requiredmsg="Please Select Billable Type"
                                                        skipValidation={managedStatus} validations={[CondRequired]} onChange={this.onBillingFieldChange}>
                                                        <option value={""}>{""}</option>
                                                        {Object.keys(billableTypeRetail).map((item) => {
                                                            return <option value={item}>{item}</option>
                                                        })
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col" hidden={managedStatus}>
                                                <label htmlFor="billableAmount" className="form-label col-form-label">BILLABLE RATE ($)*</label>
                                                <div className="">
                                                    <Input type="text" name="billableAmt"
                                                        value={(companyBilling.billableAmt || 0.0)} errormsg="Please enter valid amount"
                                                        className="form-control form-control-sm" requiredmsg="Please Enter Billable Amount"
                                                        skipValidation={managedStatus} validations={[CondRequired, isDecimal]} placeholder="0" onChange={this.onBillingFieldChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </Form>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                        <CompanyInfoContactList data={companyBilling.companyBillingContacts || []}
                           canEdit={true}
                            onContactDelete={(index) => {
                                this.props.onCompanyContactDelete(index)
                            }} onContactSubmit={(e, contactObj, index) => {
                                return this.props.onCompanyContactSubmit(e, contactObj, index)
                            }} />
                    </div>
                </div>
                <CompanyNotes canEdit={true} data={companyBilling.companyBillingNotes || []}
                    onNotesSubmit={(e, notesObj, index) => {
                        this.props.onCompanyNotesSubmit(e, notesObj, index);
                    }}
                    onNotesDelete={(index) => {
                        this.props.onCompanyNotesDelete(index)
                    }} />
            </div>


            <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <div className="mr-3">
                    {/* <button className="btn btn-sm btn-success pull-right" onClick={e => {
                            e.preventDefault();
                            this.formBilling.validateAll();
                            if (!isValidForm(this.formBilling)) {
                            } else {
                                this.props.onSubmitBillingInfo(e);
                            }
                        }}>
                        Next
                    </button> */}
                </div>

                <div className="mr-4">
                    <button
                        className="btn btn-sm btn-success pull-right"
                        style={{ marginTop: '0px' }}
                        onClick={(e) => {
                            e.preventDefault();
                            this.formBilling.validateAll();
                            if (!isValidForm(this.formBilling)) {
                            } else {
                                this.props.onSaveCompany();
                            }
                        }}

                    >SAVE COMPANY</button>
                </div>
            </div>
        </Fragment>)
    }
}
export default Billing;
