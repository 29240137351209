import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { required } from "../../../util/validationMethods.js";
import { GlobalValues } from '../../../util/constants.js';
import { isManagement,convertToFloat } from '../../../util/customMethods';
import AutoComplete from '../../utilComponents/UtilAutoComplete';
import { Accordion, Card, Button} from 'react-bootstrap';
import Form from 'react-validation/build/form';
import moment from 'moment';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class AllInvoiceCtrl extends Component {
        state = {}
    render() {
        const { parantObj } = this.props;
        return (
            <Fragment>
                <Form
                 ref={c => {
                    this.props.initCallBack(c);
                  }}
                >
                    <Card className="col-md-12 ">
                    <Card.Body>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            <h6 className="text-blue text-bg-color form-group">Search By:</h6>
                        </div>
                        <div className="col-md-6">
                            <h6 className="text-blue text-bg-color form-group">Include:</h6>
                        </div>
                    </div>
                    <div className="col-md-12 row mb-10">
                    </div>
                    
                    <div className="col-md-12 row mb-10">
                        <div className="col-md-3">
                            <div className="custom-control custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="byServiceCallDate" id="inlineCheckboxServiceCall" checked={parantObj.byServiceCallDate}
                                    onChange={(e) => {
                                        this.props.setStateField('byServiceCallDate', (!parantObj.byServiceCallDate));
                                        this.props.setStateField('byReleaseDate', (parantObj.byServiceCallDate));
                                        this.forceUpdate()
                                    }} />
                                <label htmlFor="inlineCheckboxServiceCall" className="custom-control-label">Service Call Date</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="custom-control custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="byReleaseDate" id="inlineCheckboxRelease" checked={parantObj.byReleaseDate}
                                    onChange={(e) => {
                                        this.props.setStateField('byReleaseDate', (!parantObj.byReleaseDate));
                                        this.props.setStateField('byServiceCallDate', (parantObj.byReleaseDate));
                                        this.forceUpdate()
                                    }} />
                                <label htmlFor="inlineCheckboxRelease" className="custom-control-label">Release Date</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input"
                                    id="inlineCheckboxIncCancel"
                                    type="checkbox"
                                    name="allowCancell"
                                    value={parantObj.allowCancell}
                                    checked={parantObj.allowCancell}
                                    onChange={(e) => {
                                        this.props.setStateField('allowCancell', (!parantObj.allowCancell));
                                        this.forceUpdate()
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="inlineCheckboxIncCancel">Cancelled Calls</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input"
                                    id="inlineCheckboxIncInprogress"
                                    type="checkbox"
                                    name="allowInprogress"
                                    value={parantObj.allowInprogress}
                                    checked={parantObj.allowInprogress}
                                    onChange={(e) => {
                                        this.props.setStateField('allowInprogress', (!parantObj.allowInprogress));
                                        this.forceUpdate()
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="inlineCheckboxIncInprogress">In Progress Calls</label>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-md-12 row mb-10">
                    </div>
                    <div className="col-md-12 row mb-10">
                        <div className="col-1 form-group ">
                            <label  className="text-blue form-group" htmlFor="">FROM</label>
                        </div>
                        <div className={"col-5 form-group"}>
                            <div>
                                <TmDateTimePicker
                                    className="form-control"
                                    selectedDate={
                                        parantObj.fromDate === "" || parantObj.fromDate === null
                                            ? null
                                            : moment(
                                                parantObj.fromDate,
                                                GlobalValues.dateFormat
                                            )._d
                                    }
                                    maxDate={parantObj.toDate === "" || parantObj.toDate === null
                                        ? null
                                        : moment(
                                            parantObj.toDate,
                                            GlobalValues.dateFormat
                                        )._d}
                                    dateFormat={GlobalValues.dateFormat}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="fromDate"
                                    reqmsg="From date is required"
                                    onDateSelectChange={e => {
                                        this.props.setStateField('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                        this.forceUpdate()
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-1 form-group ">
                            <label className="text-blue form-group" htmlFor="">TO</label>
                        </div>
                        <div className={ "col-5 form-group"}>
                            <div>
                                <TmDateTimePicker
                                    className="form-control"
                                    selectedDate={
                                        parantObj.toDate === "" || parantObj.toDate === null
                                            ? null
                                            : moment(
                                                parantObj.toDate,
                                                GlobalValues.dateFormat
                                            )._d
                                    }
                                    minDate={parantObj.fromDate === "" || parantObj.fromDate === null
                                        ? null
                                        : moment(
                                            parantObj.fromDate,
                                            GlobalValues.dateFormat
                                        )._d}
                                    dateFormat={GlobalValues.dateFormat}
                                    validationArray={[required]}
                                    showYearDropdown={true}
                                    showmonthdropdown={true}
                                    name="toDate"
                                    reqmsg="To-date is required"
                                    onDateSelectChange={e => {
                                        this.props.setStateField('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                        this.forceUpdate()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row mb-10">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <button type="button" onClick={this.props.allInvoiceGo} className="btn btn-success mr-1" style={{ width: '24%' }}>Go</button>
                            <button type="button" className="btn btn-warning mr-2" style={{ width: '24%' }} onClick={this.props.allInvoiceExport} render={parantObj.render}>Export</button>
                            {parantObj.render ?
                                    <ExcelFile hideElement={true}
                                    filename={`${"AllInvoices_"}${moment().format('MMDDYYhhmm')}`}
                                        fileExtension={'xlsx'}
                                    >
                                        <ExcelSheet
                                            data={()=>{                                               
                                                let setCols1 = ['callerPhone','contactPhone','pickupZipcode','dropOffZipcode','registeredOwnerZip','registeredOwnerPhone','billableHours','vehicleMileage','daysinStorage','loadedMileageAmount','serviceCallId','unLoadedMileageAmount'];
                                                let setCols2 = ['gateCharge','userDefinedCharge','mileageCharge','miscCharge','storageCharge','totalCharges','towCharge','winchCharge','laborCharge','hourlyRate','storageRate','loadedMileageRate','unLoadedMileageRate','advancedPayout','discounts','tax','amountPaid','adminCharge'];
                                                let setCols3 = ['totalTime'];
                                                let resultList=  (this.props.reportData||[]).map((item)=>{
                                                  let rec={};
                                                  (parantObj.selectedCols||[]).map((column,i) => {                                    
                                                    if(setCols1.includes(column.accessor)){
                                                        rec[column.accessor] =convertToFloat(item[column.accessor]||'',0) 
                                                    }else if(setCols2.includes(column.accessor)){
                                                        rec[column.accessor] =convertToFloat(item[column.accessor]||'',2)
                                                    }else if(setCols3.includes(column.accessor)){
                                                        rec[column.accessor] =moment.utc().startOf('day').add({ minutes: item[column.accessor] }).format('HH:mm');
                                                    }else{
                                                        rec[column.accessor] = item[column.accessor]
                                                    }                                              
                                                })
                                                return rec;                                                  
                                                });
                                                return resultList}}
                                            name={'All Invoice Report'} 
                                        >
                                            {(parantObj.selectedCols||[]).map(column => {
                                                return (
                                                    <ExcelColumn
                                                        key={column.accessor}
                                                        label={column.Header}
                                                        value={column.accessor}
                                                    />
                                                );
                                            })}
                                        </ExcelSheet>
                                    </ExcelFile>
                                    : null}
                            <button type="button" className="btn btn-success mr-2" style={{ width: '24%' }} onClick={this.props.allInvoiceEmail}>Email</button>
                            <button type="button" onClick={this.props.setDefaultState} className="btn btn-primary" style={{ width: '24%' }}>Clear</button>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                   
                    <div className="col-md-12 ">
                        <h6 className="text-blue text-bg-color form-group">Narrow By:</h6>
                        <div className="form-group row">
                            <div className="col-sm-1">
                                <div className="custom-control custom-control custom-checkbox">
                                    <input type="checkbox" checked={parantObj.byDriver} className="custom-control-input" name="byDriver" id="byDriver"
                                        onChange={e => {
                                            this.props.setStateField('byDriver', !parantObj.byDriver)
                                            if (parantObj.byDriver) {
                                                this.props.setStateField('selectedDriver', '')
                                            }
                                            this.forceUpdate()
                                        }} />
                                    <label htmlFor="byDriver" className="custom-control-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <AutoComplete className="form-control" 
                                selectedTask={(e, obj) => {
                                    this.props.setStateField('selectedDriver', e)
                                    this.props.setStateField('driverId', obj.empId||0)
                                    this.forceUpdate();
                                }} labelName='DRIVER' optionTxt='driverID'
                                    status={!parantObj.byDriver} value={parantObj.selectedDriver} data={this.props.allInvoiceObj.driverList} />
                            </div>
                            <div className="col-sm-1">
                                <div className="custom-control custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" checked={parantObj.byTowType} name="byTowType" id="byTowType"
                                        onChange={e => {
                                            this.props.setStateField('byTowType', !parantObj.byTowType)
                                            if (parantObj.byTowType) {
                                                this.props.setStateField('selectedTowType', '')
                                            }
                                            this.forceUpdate()
                                        }} />
                                    <label htmlFor="byTowType" className="custom-control-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <AutoComplete className="form-control" selectedTask={(e, obj) => {
                                    this.props.setStateField('selectedTowType', e)
                                    this.forceUpdate();
                                }} labelName='TOW TYPE' optionTxt='towType'
                                    status={!parantObj.byTowType} value={parantObj.selectedTowType} data={this.props.towTypes} />
                            </div>
                            <div className="col-sm-1">
                                <div className="custom-control custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" checked={parantObj.byAccount} name="byAccount" id="byAccount"
                                        onChange={e => {
                                            this.props.setStateField('byAccount', !parantObj.byAccount)
                                            if (parantObj.byAccount) {
                                                this.props.setStateField('selectedAccount', '')
                                            }
                                            this.forceUpdate()
                                        }} />
                                    <label htmlFor="byAccount" className="custom-control-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <AutoComplete className="form-control"
                                    selectedTask={(e, obj) => {
                                        this.props.setStateField('selectedAccount', e)
                                        this.props.setStateField('accountId', obj.optionVal ||0)
                                        this.forceUpdate();
                                    }} labelName='ACCOUNT' optionTxt='optionText'
                                    status={!parantObj.byAccount} value={parantObj.selectedAccount} data={this.props.accountList} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-1">
                                <div className="custom-control custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" checked={parantObj.byTruck} name="byTruck" id="byTruck"
                                        onChange={e => {
                                            this.props.setStateField('byTruck', !parantObj.byTruck)
                                            if (parantObj.byTruck) {
                                                this.props.setStateField('selectedTruck', '')
                                            }
                                            this.forceUpdate()
                                        }} />
                                    <label htmlFor="byTruck" className="custom-control-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <AutoComplete className="form-control" selectedTask={(e, obj) => {
                                    this.props.setStateField('selectedTruck', e)
                                    this.forceUpdate();
                                }} labelName='TRUCK' optionTxt='truckID'
                                    status={!parantObj.byTruck} value={parantObj.selectedTruck} data={this.props.allInvoiceObj.truckList} />
                            </div>
                            <div className="col-sm-1 mb-10">
                                <div className="custom-control custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" checked={parantObj.byReason} name="byReason" id="byReason"
                                        onChange={e => {
                                            this.props.setStateField('byReason', !parantObj.byReason)
                                            if (parantObj.byReason) {
                                                this.props.setStateField('selectedReason', '')
                                            }
                                            this.forceUpdate()
                                        }} />
                                    <label htmlFor="byReason" className="custom-control-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-3 mb-10">
                                <AutoComplete className="form-control" selectedTask={(e, obj) => {
                                    this.props.setStateField('selectedReason', e)
                                    this.forceUpdate();
                                }} labelName='REASON' optionTxt='optionTxt'
                                    status={!parantObj.byReason} value={parantObj.selectedReason} data={this.props.callReasons} />
                            </div>
                           {isManagement()?
                            <div className="col-sm-1 mb-10">
                            <div className="custom-control custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={parantObj.byCompany} name="byCompany" id="byCompany"
                                    onChange={e => {
                                        this.props.setStateField('byCompany', !parantObj.byCompany)
                                        this.forceUpdate()
                                    }} />
                                <label htmlFor="byCompany" className="custom-control-label"></label>
                            </div>
                        </div>:null}
                            {isManagement() ?
                            <div className="col-sm-3 mb-10">
                                <AutoComplete className="form-control" selectedTask={(e, obj) => {
                                    this.props.setStateField('selectCompany', e)
                                    this.props.setStateField('companyId', obj.optionVal ||0)
                                    this.forceUpdate();
                                }}
                                    labelName='COMPANY' optionTxt='optionText'
                                    status={!parantObj.byCompany} value={parantObj.selectCompany} data={this.props.childCompList} />
                            </div> : null}
                        </div>
                    </div>
                    </Card.Body>
                    </Card>
                </Form>

                <Accordion defaultActiveKey='0'>
                    <Card>
                        <Accordion.Toggle as={Button} variant="link" style={{'background-color':'#dddddd'}} eventKey="0">
                            REPORT OUTPUTS
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Accordion>
                                    {Object.keys(this.props.myObject).map((key, index) => {
                                        return (
                                            <div className='col-md-12'>
                                            <Card>
                                               <Accordion.Toggle as={Card.Header} as={Button}  variant="link" eventKey={index+1}>
                                                    {key}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={index+1}>
                                                    <Card.Body>
                                                        <div className="form-group row">
                                                            {
                                                                this.props.myObject[key].map((v, ind) => {
                                                                    return (
                                                                        <div className="col-sm-3" key={ind}>
                                                                            <div className="custom-control custom-checkbox mb-5 mr-2">

                                                                                <input className="custom-control-input"
                                                                                    id={`rep${index + 1}${ind + 1}`}
                                                                                    type="checkbox"
                                                                                    name={v.fieldName}
                                                                                    value={v.fieldName}
                                                                                    checked={v.status}
                                                                                    onChange={(e)=>{this.props.setReportOutputState(!v.status,key,ind)}}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor={`rep${index + 1}${ind + 1}`}>{v.title}</label>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            </div>
                                        )
                                    })}
                                </Accordion>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Fragment>
        );
    }
}
export default AllInvoiceCtrl;