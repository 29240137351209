import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class DiabledAccounts extends Component {
    constructor(props){
        super(props);
        this.state={
        }
    }
    render() {
        const {accountsCount}=this.props;
        return (
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12"> 
                        <div class="card card-stats">
                            <div class="card-header" data-background-color="red"><i style={{width:'52px'}} class="fa fa-2x fa-ban" aria-hidden="true"></i></div>
                            <div class="card-content">
                                <p class="category">Disabled Accounts</p>
                                <span class="title" style={{fontSize: '25px'}}>{accountsCount.filter(item => item.isActive === 0).length}</span>
                            </div>
                            <div class="card-footer text-right">
                                <div class="stats">
                                    <Link class="btn btn-sm btn-primary" to="/adminaccountdetail/disabledaccountsdetails" style={{marginBottom: '0px', marginRight: '0px', padding: '2px 6px'}}>
                                    <i class="fa fa-table"></i>
                                    </Link>
                                </div>
                            </div>                        
                        </div> 
                    </div>                                                        
        );
    }
}

export default DiabledAccounts;