import React, { Component, Fragment } from 'react';
import { isManagement } from '../../../util/customMethods';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';

class ARSummaryTable extends Component {
	constructor() {
		super();
		this.state = {
			
		}
	}
	render() {
        const {accountReceivableSummaryDetails}=this.props.arSummaryList || [];
        const cols=[          
          {
            accessor: 'accountName',
            Header: 'Account',
            style: { textAlign: 'center' },
            Cell: props => <Fragment>{isManagement()?<a href="javascript:void(0)">{props.original.accountName}</a>:
            <a href={'/admin/accountDetails?aid=' + props.original.accountId} target="_blank">{props.original.accountName}</a>}</Fragment>,
            width: 200
        },
        {
            accessor: 'current',
            Header: 'Current',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.current}`
        },
        {
            accessor: 'thirtyDaysDue',
            Header: '1-30 Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.thirtyDaysDue}`
        },
        {
            accessor: 'sixtyDaysDue',
            Header: '31-60 Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.sixtyDaysDue}`
        },
        {
            accessor: 'nintyDaysDue',
            Header: '61-90 Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.nintyDaysDue}`
        },
        {
            accessor: 'moreThanNintyDaysDue',
            Header: '90+ Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.moreThanNintyDaysDue}`
        },
        {
            accessor: 'total',
            Header: 'Total',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.total}`
        }
          ];
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={accountReceivableSummaryDetails || []}
                        columns={cols}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default ARSummaryTable;