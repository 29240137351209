import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NoAVRFiledCtrl from '../../../components/reportsComponents/storagemanagement/noAVRFiledCtrl';
import NoAVRFiledTable from '../../../components/reportsComponents/storagemanagement/noAVRFiledTable';
const pageTitle = 'No AVR Filed'
let NoAVRFiledForm={
	formNoAVRFiledInfo:{}
}

class NoAVRFiled extends Component {
	constructor() {
		super();
		this.state = {

		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> No AVR Filed</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <NoAVRFiledCtrl 
                    initCallBack={(frm)=>{
						NoAVRFiledForm.formNoAVRFiledInfo=frm;
                    }} />
                    <NoAVRFiledTable />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(NoAVRFiled));
