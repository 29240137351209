import React, { Component } from 'react';
import {connect} from  'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import TmExportExcel from '../../../../utilComponents/tmExportExcel/tmExportExcel';
class AccountsWillExpireDetails extends Component {
    constructor() {
        super();
        this.state = {
            excelDataSet:[]
        };
        this.buildDataFormatForExport=this.buildDataFormatForExport.bind(this);
      }
      componentWillMount=()=>{
        this.setState({excelDataSet:this.props.adminDashboard_expireaccounts.expireAccounts});
      }
      componentWillReceiveProps=(nextProps)=>{
        this.setState({excelDataSet:nextProps.adminDashboard_expireaccounts.expireAccounts});
      }
      buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'Account Name'},{title:'Account Type'},{title:'Expiration Date'},{title:'Days Until Expiration'}],
          data: this.state.excelDataSet.map((item)=>{
            return [{value:item.accountName || ''},{value:item.accountType || ''},{value:item.expireDate || ''},{value:item.daysLeftToExpire || ''}]
          })
        }])
      }
    render() {
        const { expireAccounts } = this.props.adminDashboard_expireaccounts;
        return (
          <>
          <div className="page-header">
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12">
                        <div className="title">
                              <h4><i className="fa fa-hourglass-end" aria-hidden="true"></i> Accounts that will expire in {this.props.adminDashboard_Exp_Acc_Days} days</h4>
                        </div>
                    </div>
                </div>
            </div>
             <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
               <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <div>
                    <Link class="btn btn-info" to="/admin-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                    <TmExportExcel name={'Accounts That Will Expire'} filename={'Accounts_That_Will_Expire'} 
                    element={<button className="btn btn-warning btn-sm pull-right"><i className="fa fa-file-excel-o"></i> Export</button>}
                    dataSet={this.buildDataFormatForExport()} />
                    </div>
                    </div>
                    </div>
            <div className="row">
                <div className="col-md-12">
                <ReactTable ref={(t)=>this.expiryTable=t}
                        data={expireAccounts}
                        columns={[
                            {
                                Header:'Account Name',
                                accessor:'accountName'
                            },
                            {
                                Header:'Account Type',
                                accessor:'accountType'
                            },
                            {
                                Header:'Expiration Date',
                                accessor:'expireDate'
                            },
                            {
                                Header:'Days Until Expiration',
                                accessor:'daysLeftToExpire'
                            }
                        ]}
                        onFilteredChange={()=>{
                            let expireAccounts=this.expiryTable.getResolvedState().sortedData;
                            this.setState({excelDataSet:expireAccounts});
                          }}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        filterable="true"
                        />
                </div>  
            </div> 
            </div>  
            </>                                                 
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        adminDashboard_expireaccounts: state.adminDashboard,
        adminDashboard_Exp_Acc_Days: state.adminDashboard.expiryDays   
    }
};
export default connect(mapStateToProps)(AccountsWillExpireDetails);