import React, { Component, Fragment } from 'react';
import TmReactTable from '../../../utilComponents/tmReactTable/tmReactTable';

class TowSheets extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
        } 
        this.imageModalOpen = this.imageModalOpen.bind(this);
    }
    imageModalOpen(e,status,imageUrl) {
      e.preventDefault();
      if(!status){
          window.open(imageUrl.attachmenturl,'_blank')
      }
    }
    render() {
    const { data } = this.props;
    const columns = [
        {
            accessor: 'sNo',
            Header: 'SNo',
            style: { textAlign: 'center' },
            width: 100,
            Cell: (row) => {
              return <div>{row.index + 1}</div>;
            }
          },
          {
            accessor: 'attachmentName',
            Header: 'Attachment Name',
            style: { textAlign: 'center' },
            Cell: (row) => {
              let status = row.original.attachmentName.split('.').pop().toLowerCase() === 'xls';
              return (<div> <a disabled={this.props.isDisabled} href={status ? row.original.attachmentName : 'Javascript:void(0)'} 
              onClick={(e) => this.imageModalOpen(e,status,row.original) }
              download={status ? true : false}>{row.original.attachmentName}</a>
              </div>)
            }
          },
          {
            accessor: 'Delete',
            Header: 'Delete',
            sortable: false,
            filterable: false,
            style: { textAlign: 'center' },
            width: 100,
            Cell: (row) => {
              return <div><input type="button" disabled={this.props.isDisabled} className="btn btn-sm btn-danger" onClick={() => this.props.deleteAttachmentModal('towsheet', row.original)} value="Delete" /></div>;
            }
          }
    ];
        return (<Fragment>
            
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <TmReactTable
                         filterable={false}
                         sortable
                         resizable
                         showPagination={false}
                         defaultPageSize={25}
                         data={data}
                         columns={columns}
                        />
                    </div>
                    <div className="col-md-12 col-sm-12 mb-10">
                            <input type="file" name="towsheet" disabled={this.props.isDisabled} className="form-control" multiple onChange={(e) => {
                        e.preventDefault();
                        this.props.getFiles(e.target.name, e.target.files)
                    }} style={{height:'40px'}}/>
                    </div>
                    <div className="col-md-12 col-sm-12 mb-10">
                        <button type="button" className="btn btn-success btn-sm" disabled={this.props.isDisabled} onClick={(e) => {
                        e.preventDefault();
                        this.props.uploadTowSheetsAttachments('towsheet');
                    }}>Upload</button>
                    </div>
                </div>
        </Fragment>);
    }
}
export default TowSheets
