import React, { Component } from 'react';
import {connect} from  'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import TmExportExcel from '../../../../utilComponents/tmExportExcel/tmExportExcel';
import {utilMethods} from '../../../../../util/util';
class CashOnlyDetails extends Component {
    constructor() {
        super();
        this.state = {
            excelDataSet:[]
        };
        this.buildDataFormatForExport=this.buildDataFormatForExport.bind(this);
      }
      componentWillMount=()=>{
        this.setState({excelDataSet:this.props.adminDashboard_All_Accounts.filter(item => item.accountingType === 'Cash')});
      }
      componentWillReceiveProps=(nextProps)=>{
        this.setState({excelDataSet:nextProps.adminDashboard_All_Accounts.filter(item => item.accountingType === 'Cash')});
      }
      buildDataFormatForExport=()=>{
        return([{
          columns:[{title:'Account Name'},{title:'Accounting Type'}],
          data: this.state.excelDataSet.map((item)=>{
            return [{value:item.accountName || ''},{value:item.accountingType || ''}]
          })
        }])
      }
    render() {
        const { adminDashboard_All_Accounts } = this.props;
        return (
          <>
          <div className="page-header">
                <div className="row mb-10">
                    <div className="col-md-12 col-sm-12">
                        <div className="title">
                        <h4><i className="fa fa-money"></i> Cash Only Accounts</h4>
                        </div>
                    </div>
                </div>
            </div>
             <div className="pd-10 bg-white border-radius-4 box-shadow container-box">
             <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <div>
                    <Link class="btn btn-info" to="/admin-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                    <TmExportExcel name={'Cash Only Accounts'} filename={'Cash_Only_Accounts'} 
                    element={<button className="btn btn-warning btn-sm pull-right"><i className="fa fa-file-excel-o"></i> Export</button>}
                    dataSet={this.buildDataFormatForExport()} />
                    </div>
                    </div>
                    </div>
            <div className="row">
                <div className="col-md-12">
                <ReactTable ref={(t)=>this.expiryTable=t}
                        data={adminDashboard_All_Accounts.filter(item => item.accountingType === 'Cash')}
                        columns={[
                            {
                                Header:'Account Name',
                                accessor:'accountName'
                            },
                            {
                                Header:'Accounting Type',
                                accessor:'accountingType'
                            }
                        ]}
                        onFilteredChange={()=>{
                            let cashonlyAccounts=this.expiryTable.getResolvedState().sortedData;
                            this.setState({excelDataSet:cashonlyAccounts});
                          }}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        filterable="true"
                        defaultFilterMethod={(filter, row)=> {
                            const id = filter.pivotId || filter.id;
                            return (
                              row[id] !== undefined ?
                              utilMethods.isNumber(row[id])? (row[id].toString()).includes(filter.value):
                                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                              :
                                true
                            )}}
                        />
                </div>  
            </div>  
            </div>   
            </>                                                      
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        adminDashboard_All_Accounts: state.adminDashboard.allAccounts  
    }
};
export default connect(mapStateToProps)(CashOnlyDetails);