
class Notify { 
  constructor(){ 
    this.notifyType={
      Info:'info',
      Success:'success',
      Warning:'warning',
      Error:'error'}
    }
    
    createNotification = (type,message,NotificationManager) => { 
      NotificationManager.clearNotifications();       
          switch (type) {
            case this.notifyType.Info:
              NotificationManager.addNotification({
                title:'Info',
                message: message,
                level: this.notifyType.Info,
                position:'tc'
              });
            break;
            case this.notifyType.Success:
              NotificationManager.addNotification({
                title:'Success',
                message: message,
                level: this.notifyType.Success,
                position:'tc'
              });
            break;
            case this.notifyType.Warning:
              NotificationManager.addNotification({
                title:'Warning',
                message: message,
                level: this.notifyType.Warning,
                position:'tc'
              });
            break;
            case this.notifyType.Error:
              NotificationManager.addNotification({
                title:'Error',
                message: message,
                level: this.notifyType.Error,
                position:'tc'
              });
            break;
            default:
                NotificationManager.addNotification({
                  title:'Info',
                  message: message,
                  level: this.notifyType.Info,
                  position:'tc'
                });
            break;
          }
  }
}
export const notify=new Notify();