//This object is used to provide set up data for exported excel sheet columns and react-table columns
// The excel sheet only uses the accessor and the Header
// react-table uses all values
import React, { Component }  from 'react';
export  const tableColumns = [
    {
        accessor: 'accountType',
        Header: '',
        style: { textAlign: 'center' },
        width: 200,
        Cell: row => <span className='number'>Account Type:{row.original.accountType}</span>
    },
    {
        accessor: 'asOfDate',
        Header: '',
        style: { textAlign: 'center' },
        width: 200,
        Cell: row => <span className='number'>As Of:{row.original.asOfDate}</span>
    }
];
