import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomPDReleaseCtrl from '../../../components/reportsComponents/custom/customPDReleaseCtrl';
import CustomPDReleaseTable from '../../../components/reportsComponents/custom/customPDReleaseTable';
const pageTitle = 'Custom PD Release'
let customPDReleaseForm={
	formcustomPDReleaseInfo:{}
}

class customPDRelease extends Component {
	constructor() {
		super();
		this.state = {
			fromDate:'',
            toDate:''
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		
    }
    onCustomPDReleaseFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Custom PD Release</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <CustomPDReleaseCtrl onCustomPDReleaseFieldChange={(key, value)=>this.onCustomPDReleaseFieldChange(key, value)}
                    initCallBack={(frm)=>{
						customPDReleaseForm.formcustomPDReleaseInfo=frm;
                    }} fromdate={this.state.fromDate} todate={this.state.toDate}/>
                    <CustomPDReleaseTable />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(customPDRelease));
