import React, { Fragment } from 'react';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import { Modal } from 'react-bootstrap';
import Select from 'react-validation/build/select';
import moment from 'moment';
import {
    required,
    isValidForm,
    NotZero
} from "../../../util/validationMethods";
import { isManagement } from '../../../util/customMethods'
import { paymentState } from '../../../util/stateUtil/initialStateForComponents'
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
let paymentForm = {};
class Payment extends React.Component {
    state = {
        ...paymentState
    }

    handlePayment = (event) => {
        let obj = this.state;
        obj[event.target.name] = event.target.value;
        this.setState({ obj });
    }

    initCallBack = (c) => {
        this.setState({ paymentForm: c })
    }

    setPaymentToEmpty = (obj) => {
        this.setState({
            cctxNo: this.state.cctxNo,
            chequeNo: (obj||undefined)==undefined?"":obj.chequeNo,
            invoiceId: (obj||undefined)==undefined?"":obj.invoiceId,
            paymentAmt: (obj||undefined)==undefined?0:obj.paymentAmt,
            paymentDate: (obj||undefined)==undefined?"":obj.paymentDate,
            paymentId: (obj||undefined)==undefined?0:obj.paymentId,
            paymentLocation: (obj||undefined)==undefined?"":obj.paymentLocation,
            paymentType: (obj||undefined)==undefined?"":obj.paymentType,
            transactionNo: (obj||undefined)==undefined?"":obj.transactionNo,
            userId:(obj||undefined)==undefined?"":obj.userId,
        })
    }

    addPayment = (obj) => {
        paymentForm.validateAll();
        if (!isValidForm(paymentForm))
            return false;
        this.props.addUpdatePayments(this.props.invoiceId, {
            cctxNo: this.state.cctxNo,
            chequeNo: this.state.chequeNo,
            invoiceId: this.state.invoiceId,
            paymentAmt: this.state.paymentAmt,
            paymentDate: this.state.paymentDate || moment().format('YYYY-MM-DD HH:mm:ss'),
            paymentId: this.state.paymentId,
            paymentLocation: this.state.paymentLocation,
            paymentType: this.state.paymentType,
            transactionNo: this.state.transactionNo,
            userId: this.state.userId,
        });
        this.setState({ editPaymentModel: false });
        this.setPaymentToEmpty();
    }
    render() {
        const isDisabled = this.props.isDisabled;
        const columns = [{
            accessor: 'userId',
            style: { textAlign: 'center' },
            Header: 'USER ID'
        },
        {
            accessor: 'paymentDate',
            style: { textAlign: 'center' },
            Header: 'DATE'
        },
        // {
        //     accessor: 'paymentLocation',
        //     style: { textAlign: 'center' },
        //     Header: 'PAYMENT LOCATION',
        //     show:!isManagement()
        // },
        {

            accessor: 'paymentType',
            style: { textAlign: 'center' },
            Header: 'PAYMENT TYPE'
        },
        {
            accessor: 'paymentAmt',
            style: { textAlign: 'center' },
            Header: 'AMOUNT'
        },
        {
            accessor: 'Actions',
            Header: 'Actions',
            sortable: false,
            filterable: false,
            style: { textAlign: 'center' },
            Cell: (row) => {
                return <div>
                    <input type="button" disabled={(!this.props.paymentAccess.edit) || isDisabled} className="btn btn-sm btn-primary mr-10" value="Edit" onClick={() => {
                        this.setPaymentToEmpty(row.original)
                        this.setState({ editPaymentModel: true });
                    }} />
                    <input disabled={(!this.props.paymentAccess.delete) || isDisabled} type="button" className="btn btn-sm btn-danger" value="Delete"
                        onClick={() => {
                            this.setState({ deleteModel: true, paymentId: row.original.paymentId })
                        }} />
                </div>;
            }
        }];
        return (<Fragment>
            <Form
                ref={c => {
                    paymentForm = c;
                }}
            >
                <div className="pd-10">
                    <h6 className="text-blue text-bg-color form-group">Payments</h6>
                    <div className="text-right">
                                    <input type="button" disabled={isDisabled} className="btn btn-sm btn-success" value={"Add Payment"} onClick={() => {
                                       this.setPaymentToEmpty(undefined)
                                       this.setState({ editPaymentModel: true });
                                    }} /></div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mb-10">
                            <TmReactTable
                                filterable
                                sortable
                                resizable
                                defaultPageSize={25}
                                data={this.props.invoicePayments}
                                columns={columns}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {/* {!isManagement()?
                    <div className="col-md-3">
                        <label htmlFor="accountName" className="form-label col-form-label">PAYMENT LOCATION</label>
                        <Select className="form-control" name="paymentLocation" onChange={(event)=>{this.handlePayment(event)}} disabled={isDisabled} value={this.state.paymentLocation}
                        validations={isManagement()?[]:[required]} requiredmsg="Please Select Payment Location." >
                            <option value="">Select Location</option>
                            {
                                locationList.length > 0 && locationList.filter(loc => loc.paymentLocation === 1).map((item, i) => {
                                    return (
                                        <option key={i} value={item.location}>{item.location}</option>
                                    )
                                })
                            }
                        </Select>
                    </div>:null} */}
                        {/* <div className="col-md-4">
                            <label htmlFor="accountName" className="form-label col-form-label">PAYMENT TYPE</label>
                            <Select className="form-control mb-10" onChange={(event) => { this.handlePayment(event) }}
                                validations={[required]} requiredmsg="Please Select Payment Type." disabled={isDisabled} name="paymentType"
                                value={this.state.paymentType} >
                                <option value=""> Payment Type</option>
                                <option value="Cash">Cash</option>
                                <option value="Check">Check</option>
                                <option value="EmployeeAdvance">Employee Advance</option>
                                <option value="Transfer">Transfer</option>
                            </Select> {
                                this.state.paymentType === 'Check' ?
                                    <input placeholder="Check #" type="text" name="chequeNo" onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled}
                                        className="form-control" value={this.state.chequeNo} /> : null
                            }
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="accountName" className="form-label col-form-label">AMOUNT</label>
                            <div className="input-groups">
                                <div class="input-groups-icon">$</div>
                                <div class="input-groups-area">
                                    <Input type="number" validations={[required, NotZero]} requiredmsg="Please Enter Payment Amount." disabled={isDisabled} onChange={(event) => { this.handlePayment(event) }} className="form-control form-control-sm"
                                        name="paymentAmt" value={this.state.paymentAmt} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>&nbsp;</label>
                            <div className="">
                                <input type="button" disabled={(!this.props.paymentAccess.add) || isDisabled} className="btn btn-sm btn-success" value="Add Payment" onClick={() => {
                                    this.addPayment(this.state.paymentObj);
                                    this.setState({ paymentObj: this.state.defaultObj })
                                }} />
                            </div>
                        </div> */}
                    </div></div>

            </Form>
            <Modal show={this.state.deleteModel} >
                <div className="modal-header">Delete Payment.
                </div>
                <div className="modal-body">
                    <div className="modal-title h6">{'Do you want to delete the payment ?'}</div>
                    <div className="text-right">
                        <button type="button" className="btn btn-success btn-sm mr-10" onClick={(e) => {
                            this.props.deletePayments(this.state.paymentId, this.props.invoiceId);
                            this.setState({ deleteModel: false, paymentId: 0 })
                        }}>Yes</button>
                        <button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
                            e.preventDefault();
                            this.setState({ deleteModel: false, paymentId: 0 })

                        }}>No</button>
                    </div>
                </div>
            </Modal>
            <Modal show={this.state.editPaymentModel} >
                <div className="modal-header">{(this.state.paymentId||0)==0?"Add Payment":"Update Payment"}
                </div>
                <div className="modal-body">
                    <Form ref={c => {
                        paymentForm = c;
                    }}>
                        {/* {!isManagement()?<div className="row">
                           <div className="col-6">
                                <label htmlFor="accountName" className="form-label col-form-label">PAYMENT LOCATION</label>
                            </div>
                            <div className="col-6">
                            <div className="input-groups">
                                <Select className="form-control mb-10" validations={isManagement()?[]:[required]} requiredmsg="Please Select Payment Type." name="paymentLocation" onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled} value={this.state.paymentLocation} >
                                    <option value="">Select Location</option>
                                    {
                                        locationList.length > 0 && locationList.filter(loc => loc.paymentLocation === 1).map((item, i) => {
                                            return (
                                                <option key={i} value={item.location}>{item.location}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            </div>
                        </div>:null} */}
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="accountName" className="form-label col-form-label">PAYMENT TYPE</label>
                            </div>
                            <div className="col-6">
                                <div className="input-groups">
                                    <Select className="form-control mb-10" validations={[required]} requiredmsg="Please Select Payment Type." onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled} name="paymentType" value={this.state.paymentType} >
                                        <option value="">Select Payment Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Credit">Credit</option>
                                        <option value="Check">Check</option>
                                        <option value="EmployeeAdvance">Employee Advance</option>
                                        <option value="Transfer">Transfer</option>
                                    </Select> {
                                        this.state.paymentType === 'Check' ?
                                            <input placeholder="Check #" type="text" name="chequeNo" onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled} className="form-control mb-10" value={this.state.chequeNo} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="accountName" className="form-label col-form-label">AMOUNT</label>
                            </div>
                            <div className="col-6">
                                <div className="input-groups">
                                    <div class="input-groups-icon">$</div>
                                    <div class="input-groups-area">
                                        <Input type="number" validations={[required, NotZero]} requiredmsg="Please Enter Payment Amount." disabled={isDisabled} onChange={(event) => { this.handlePayment(event) }} className="form-control form-control-sm"
                                            name="paymentAmt" value={this.state.paymentAmt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label>&nbsp;</label>
                                <div className="text-right">
                                    <input type="button" disabled={isDisabled} className="btn btn-sm btn-success" value={(this.state.paymentId||0)==0?"Add Payment":"Update Payment"} onClick={() => {
                                        this.addPayment();
                                    }} />&nbsp;
                                    <input type="button" className="btn btn-sm btn-danger" disabled={isDisabled} onClick={() => {
                                        this.setPaymentToEmpty()
                                        this.setState({ editPaymentModel: false });
                                    }} value="Close" />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

            </Modal>
        </Fragment>);
    }
}
export default Payment