import React, { Component,Fragment } from 'react';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { connect } from 'react-redux';
import CustomChargeList from '../../components/adminComponents/customChgMonetizationCongiList/customChgMonetizationCongiList';
import { setBillableStatus, saveMonetizationChargeList, saveChargeList,getMonetizationChargeList,
	 onCustomChargeNameFieldChange, onCustomChargeNameSubmit, clearCustomChargeName} from '../../actions/adminAction/ratePlanActions';
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods.js';
const pageTitle ="Charges";
class CustomChgMonetizationConfig extends Component {
	constructor(){
		super();
		this.state={
			modalShow:false,
		}
		this.checkViewAccess=this.checkViewAccess.bind(this);
		this.bindChargeList=this.bindChargeList.bind(this);
		//this.onEditCharge=this.onEditCharge.bind(this);
		this.onSubmitSaveChargeList=this.onSubmitSaveChargeList.bind(this);
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.bindChargeList();		
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
	bindChargeList=()=>{
		const {dispatch}=this.props;
		dispatch(getMonetizationChargeList());
	}
	setBillableStatus=(e,chargeId)=>{
		const {dispatch}=this.props;
		dispatch(setBillableStatus(e,chargeId));
	}
	onSubmitSaveChargeList=async ()=>{
		const {dispatch} =this.props;
		const res=await dispatch(saveMonetizationChargeList())
		if(res.statusCode===200){
			notify.createNotification('success',res.statusMsg,this.props.refNotify);
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
	onFieldDataChange=(key,value)=>{
		const {dispatch}=this.props;
		dispatch(onCustomChargeNameFieldChange(key,value))
	}
	onCancelForm=()=>{
		this.setState({
			modalShow:false,
			})
			const {dispatch}=this.props;
		dispatch(clearCustomChargeName());
	}
	onSubmitForm=async (e)=>{
		e.preventDefault();
		const {dispatch}=this.props;
		const res=await dispatch(onCustomChargeNameSubmit(this.props.newCustomCharge));
		if(res.statusCode===200){
				this.setState({
					modalShow:false
					});
				notify.createNotification('success',res.statusMsg,this.props.refNotify);
			}else{
				this.setState({
					modalShow:false
					})
				notify.createNotification('error',res.statusMsg,this.props.refNotify);
			}
	}
	
  render() {
		const accessLevelObj=getAccessLevelsByModule('Custom Charges');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
			<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-globe"></i> {pageTitle}</h4>
							</div>						
						</div>
						</div>			
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
			<CustomChargeList globalChargeListData={this.props.monetizationChargeList}
					accessLevel={accessLevelObj||{}}
					setBillableStatus={(e,chargeId)=>{this.setBillableStatus(e,chargeId)}}					
					onSubmitSaveChargeList={this.onSubmitSaveChargeList} />				
			</div>			
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		monetizationChargeList:state.ratePlanList.monetizationChargeList,
		newCustomCharge:state.ratePlanList.newCustomCharge,
  }
};

export default withRouter(connect(mapStateToProps)(CustomChgMonetizationConfig));