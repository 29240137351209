import * as actionTypes from '../../../actions/actionTypes';
const defaultCRDetails={
  crDetailObj:{}
}
export const crDetailsReducer = (state = defaultCRDetails, action) => {
    switch (action.type) {
      case actionTypes.GET_CRDETAIL_LIST:
        return {...state ,crDetails:(JSON.parse(JSON.stringify(action.crDetailObj||defaultCRDetails.crDetailObj)))};
      default:
        return state;
    }
  };