import React, { Component, Fragment } from 'react';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { Link } from 'react-router-dom';
class SummaryByTruckTable extends Component {
	render() {
 const tableColumns = [
  {
    accessor: 'truck',
    Header: 'Truck ID',
    style: { textAlign: 'center' },
    Cell: props => <Link to={`/reports/summary-details?fromDate=${this.props.stateObj.fromDate}&toDate=${this.props.stateObj.toDate}&id=${props.original.truck}&type=${'truck'}&accountId=${this.props.stateObj.accountId}&idCompany=${this.props.stateObj.idCompany}`} target="_blank">{props.original.truck}</Link>,
    //Cell: props => <Link to={`/summary-by-driver-details?from=${this.state.fromDate}&to=${this.state.toDate}&id=${props.original.truck}&type=${'bytruck'}`} target="_blank">{props.original.truck}</Link>,
    Footer:(<span>Total :</span>)
},
{
    accessor: 'noOfTows',
    Header: 'Number Of Tows',
    style: { textAlign: 'center' },
    Footer:( ` ${this.props.data.totalTows||0}`)
},
{
    accessor: 'totalCharges',
    Header: '* Total Charges',
    style: { textAlign: 'center' },
    Footer:( `$ ${this.props.data.sumOfTotalCharges||0}`)
},
{
    accessor: 'use',
    Header: 'Use %',
    style: { textAlign: 'center' },
},
{
    accessor: 'chargesPerTow',
    Header: '* Charges Per Tow',
    style: { textAlign: 'center' },
    Footer:( `$ ${this.props.data.avgChargePerTow || 0}`)
}
];
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={this.props.data.truckDetails||[]}
                        columns={tableColumns}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default SummaryByTruckTable;