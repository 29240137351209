import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AccountList from '../../components/adminComponents/account/accountList';
import { getAccountList } from '../../actions/adminAction/accountAction';
import { getAccessLevelsByModule} from '../../util/customMethods'
const pageTitle = 'Account'


class AccountPage extends Component {
	constructor() {
		super();
		this.state = {
			accountSearch: { "searchQuery": "", "limit": null, "accountName": "", "accountType": "", "accountStatus": "1" }
		}
		this.checkViewAccess = this.checkViewAccess.bind(this)
		this.bindAccountList = this.bindAccountList.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this)
	}
	componentWillMount = () => {
		document.title = pageTitle;
		this.bindAccountList();
	}
	checkViewAccess = (_accessLevel = {}) => {
		if (!_accessLevel.basicAccess.view) {
			this.props.history.push('/admin/notfound');
		}
	}
	bindAccountList = () => {
		const { dispatch } = this.props;
		dispatch(getAccountList(this.state.accountSearch));
	}
	onSearchChange = (searchObject) => {
		let _accountSearch = this.state.accountSearch;
		_accountSearch.limit = searchObject.limit;
		_accountSearch.accountName = searchObject.accountName;
		_accountSearch.accountType = searchObject.accountType;
		_accountSearch.accountStatus = searchObject.activeFilter === -1 ? "1','0" : searchObject.activeFilter;
		this.setState({ accountSearch: _accountSearch }, this.bindAccountList());
	}

	render() {
		const accessLevelObj = getAccessLevelsByModule('Account');
		this.checkViewAccess(accessLevelObj)
		return (
			<Fragment>
				<div className="page-header">
					<div className="row mb-10">
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Account Search</h4>
							</div>
						</div>
					</div>
				</div>
				<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
					<AccountList data={this.props.accountList} onSearchChange={this.onSearchChange}
						accessLevel={accessLevelObj || {}}
						accountTypes={this.props.accountTypes}
						onClickNewAccount={(e) => {
							this.props.history.push('/admin/accountDetails?aid=0');
						}} onClickEditAccount={(e, aid) => {
							this.props.history.push('/admin/accountDetails?aid=' + aid);
						}} />
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		authInfo: state.authInfo,
		refNotify: state.refNotify,
		accountList: state.accountList.accountInfoList,
		accountTypes: state.accountTypes.accountTypes,
	}
};

export default withRouter(connect(mapStateToProps)(AccountPage));
