import React, { Component, Fragment } from 'react';
import { isManagement } from '../../../util/customMethods';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';

class CRSummaryTable extends Component {
	constructor() {
		super();
		this.state = {
			
		}
	}
	render() {
        const companyReceivableSummaryDetails=this.props.crSummaryList || [];
        const cols=[          
          {
            accessor: 'companyName',
            Header: 'COMPANY',
            style: { textAlign: 'center' },
            Cell: props => <Fragment>{isManagement()?<a href="javascript:void(0)">{props.original.companyName}</a>:
            <a href={'/admin/companyRecord?cid=' + props.original.companyName} target="_blank">{props.original.companyName}</a>}</Fragment>,
            width: 200
        },
        {
            accessor: 'currentDue',
            Header: 'Current',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.currentDue}`
        },
        {
            accessor: 'thirtyDaysDue',
            Header: '1-30 Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.thirtyDaysDue}`
        },
        {
            accessor: 'sixtyDaysDue',
            Header: '31-60 Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.sixtyDaysDue}`
        },
        {
            accessor: 'nintyDaysDue',
            Header: '61-90 Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.nintyDaysDue}`
        },
        {
            accessor: 'moreThanNintyDaysDue',
            Header: '90+ Days',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.moreThanNintyDaysDue}`
        },
        {
            accessor: 'totalReceivable',
            Header: 'Total',
            style: { textAlign: 'center' },
            width: 200,
            Cell: props => `$ ${props.original.totalReceivable}`
        }
          ];
		return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-30">
                        <TmReactTable 
                        filterable 
                        sortable
                        resizable
                        defaultPageSize={10}
                        data={companyReceivableSummaryDetails || []}
                        columns={cols}
                    /> 
                    </div>
                </div>
            </Fragment>
		);
	}
}


export default CRSummaryTable;