import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AuctionBatchPaymentCtrl from '../../../components/reportsComponents/custom/auctionBatchPaymentCtrl';
import AuctionBatchPaymentTable from '../../../components/reportsComponents/custom/auctionBatchPaymentTable';
const pageTitle = 'Auction Batch Payment'


class AuctionBatchPayment extends Component {
	constructor() {
		super();
		this.state = {
			
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
		
	}
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Auction Batch Payment</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <AuctionBatchPaymentCtrl />
                    <AuctionBatchPaymentTable />
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		
	}
};

export default withRouter(connect(mapStateToProps)(AuctionBatchPayment));
