import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import moment from 'moment'
import {isManagement} from '../../../util/customMethods';
class EmployeeList extends Component {
  constructor() {
    super();
    this.state = {
      ActiveInactiveFilterVal: [1],
      searchObject: {
        searchText: '',
        activeFilter: 1
      },
      excelDataSet: []
    }
    this.handleActiveInactiveFilterChange = this.handleActiveInactiveFilterChange.bind(this);
    this.buildDataFormatForExport = this.buildDataFormatForExport.bind(this)
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({ excelDataSet: nextProps.data });
  }
  handleActiveInactiveFilterChange = (value, e) => {
    let _searchObject = this.state.searchObject;
    _searchObject.activeFilter = parseInt(e.currentTarget.value);
    this.setState({ ActiveInactiveFilterVal: [parseInt(e.currentTarget.value)], searchObject: _searchObject },
      this.props.onSearchChange(_searchObject)
    );
  }
  buildDataFormatForExport = () => {
    return ([{
      columns: [{ title: 'NAME' },
      { title: 'USER ID' }, { title: 'JOB TITLE' },
      { title: 'LOCATION' }, { title: 'LEVEL' }],
      data: this.state.excelDataSet.map((item) => {
        return [{ value: item.userFullName || '' },
        { value: item.userId || '' }, { value: item.jobTitle || '' },
        { value: item.location || '' }, { value: item.level || '' }]
      })
    }])
  }

  render() {
    const { data } = this.props;
    const columns = [{
      Header: 'EMPLOYEE FULL NAME',
      accessor: 'userFullName',
      filterable: true,
      Cell: props =>
        <Link to={"/admin/employeesDetails?eid=" + props.original.empId}>{props.value}</Link>
    },
    {
      Header: 'USER ID',
      accessor: 'userId',
      filterable: true,
    },
    {
      Header: 'JOB TITLE',
      accessor: 'jobTitle',
      filterable: true
    },
    {
      Header: 'LOCATION',
      accessor: 'location',
      filterable: true,
      show:!isManagement()
    },
    {
      Header: 'LEVEL',
      accessor: 'level',
      filterable: true,
      show:!isManagement()
    }];
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-5 col-sm-12 mb-5 mt-2">
            <div className="input-group mb-1">
              <input className="form-control" placeholder="Search"
                value={this.state.searchObject.searchText} type="text" onChange={(e) => {
                  let _searchObject = this.state.searchObject
                  _searchObject.searchText = e.target.value
                  this.setState({ searchObject: _searchObject })
                }} />
              <div className="input-group-append">
                <button className="btn btn-sm btn-success" type="button"><i className="fa fa-search" aria-hidden="true" onClick={(e) => {
                  this.props.onSearchChange(this.state.searchObject)
                }}></i></button>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-6 mb-10">
            <div className="pull-right">
              <ToggleButtonGroup
                bsPrefix="btn-group-toggle btn-group"
                type="checkbox"
                value={this.state.ActiveInactiveFilterVal}
                onChange={this.handleActiveInactiveFilterChange} defaultValue={-1}
              > <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={1}  >ACTIVE </ToggleButton>
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={0}>INACTIVE</ToggleButton>
                <ToggleButton type="radio" bsPrefix="btn btn-outline-info btn-sm" value={-1}>ALL</ToggleButton>
              </ToggleButtonGroup>
              <div className="btn-list" style={{ display: 'inline-block' }}>
                <TmExportExcel name={'Employees'} filename={`${"Employees_"}${moment().format('MMDDYYhhmm')}`} fileExtension={'xlsx'}
                  element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i> Export</button>}
                  dataSet={this.buildDataFormatForExport()} />
                {
                  (this.props.accessLevel.basicAccess.add || false) === true ?
                    <button className="btn btn-success btn-sm" onClick={(e) => {
                      this.props.onNewEmpClick(e)
                    }} ><i className="fa fa-plus"></i> Add Employee</button> : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-30">
            <TmReactTable ref={(table) => this.empTable = table}
              onFilteredChange={() => {
                let data = this.empTable.tmReactTable.getResolvedState().sortedData
                this.setState({ excelDataSet: data })
              }}
              filterable
              sortable
              resizable
              defaultPageSize={10}
              data={data}
              columns={columns}
            />
          </div></div>
      </Fragment>
    );
  }
}

export default EmployeeList;
