
import React, { Component } from 'react';
import { LAUNCH_URL,ROOT_DISPATCHER,ROOT_ADMIN} from '../../util/customMethods';
export const tableColumnsNegative = [
    {
        accessor: 'displayInvoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{ 
            window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/serviceCallInfo/${props.original.invoiceNumber}`,'_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 200
    },
    {
        accessor: 'invoiceDate',
        Header: 'Invoice Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'company',
        Header: 'Company',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'requestedBy',
        Header: 'Requested By',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.requestByAccId}`,'_blank');
        }}>{props.original.requestedBy}</a>,
        width: 200
    },
    {
        accessor: 'billTo',
        Header: 'Bill To',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
            window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.billToAccId}`,'_blank');
        }}>{props.original.billTo}</a>,
        width: 200
    },
    {
        accessor: 'totalCharge',
        Header: 'Total Charged',
        style: { textAlign: 'center' },
        width: 150,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.totalCharge||0)===parseInt(filter.value||0),
        Cell: props => `$ ${props.original.totalCharge}`
    },
    {
        accessor: 'balance',
        Header: 'Balance',
        style: { textAlign: 'center' },
        width: 150,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.balance||0)===parseInt(filter.value||0),
        Cell: props => `$ ${props.original.balance}`
    },
    {
        accessor: 'paymentLocation',
        Header: 'Payment Location',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'discount',
        Header: 'Discounts',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.discount||0)===parseInt(filter.value||0),
        width: 200
    }
];
export const tableColumnsPartial = [
    {
        accessor: 'displayInvoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/serviceCallInfo/${props.original.invoiceNumber}`,'_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 200
    },
    {
        accessor: 'invoiceDate',
        Header: 'Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'company',
        Header: 'Company',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'requestedBy',
        Header: 'Requested By',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.requestByAccId}`,'_blank');
        }}>{props.original.requestedBy}</a>,
        width: 200
    },
    {
        accessor: 'billTo',
        Header: 'Bill To',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.billToAccId}`,'_blank');
        }}>{props.original.billTo}</a>,
        width: 200
    },
    {
        accessor: 'totalCharge',
        Header: 'Total Charges',
        style: { textAlign: 'center' },
        width: 200,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.totalCharge||0)===parseInt(filter.value||0),
        Cell: props => `$ ${props.original.totalCharge}`
    },
    {
        accessor: 'balance',
        Header: 'Balance',
        style: { textAlign: 'center' },
        width: 200,
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.balance||0)===parseInt(filter.value||0),
        Cell: props => `$ ${props.original.balance}`
    },
    {
        accessor: 'paymentLocation',
        Header: 'Payment Location',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'discount',
        Header: 'Discount',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.discount||0)===parseInt(filter.value||0),
        width: 200
    }
];
export const tableColumns = [
    {
        accessor: 'displayInvoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/serviceCallInfo/${props.original.invoiceNumber}`,'_blank');
        }}>{props.original.displayInvoiceId}</a>
    },
    {
        accessor: 'invoiceDate',
        Header: 'Date',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'company',
        Header: 'Company',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'requestedBy',
        Header: 'Requested By',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.requestByAccId}`,'_blank');
        }}>{props.original.requestedBy}</a>
    },
    {
        accessor: 'billTo',
        Header: 'Bill To',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.billToAccId}`,'_blank');
        }}>{props.original.billTo}</a>
    },
    {
        accessor: 'totalCharge',
        Header: 'Total Charges',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.totalCharge||0)===parseInt(filter.value||0),
        Cell: props => `$ ${props.original.totalCharge}`
    },
    {
        accessor: 'balance',
        Header: 'Balance',
        style: { textAlign: 'center' },
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.balance||0)===parseInt(filter.value||0),
        Cell: props => `$ ${props.original.balance}`
    },
    {
        accessor: 'paymentLocation',
        Header: 'Location',
        style: { textAlign: 'center' }
    },
    {
        accessor: 'discount',
        Header: 'Discounts',
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) =>
            parseInt(row._original.discount||0)===parseInt(filter.value||0),
        style: { textAlign: 'center' }
    },
    {
        accessor: 'lastPaymentDate',
        Header: 'Last Payment Date',
        style: { textAlign: 'center' }
    }
];
export const unverifiedCols = [
    {
        accessor: 'displayInvoiceId',
        Header: 'Invoice #',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_DISPATCHER}/serviceCallInfo/${props.original.invoiceNumber}`,'_blank');
        }}>{props.original.displayInvoiceId}</a>,
        width: 200
    },
    {
        accessor: 'invoiceDate',
        Header: 'Date',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'company',
        Header: 'Company',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'requestedBy',
        Header: 'Requested By',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.requestByAccId}`,'_blank');
        }}>{props.original.requestedBy}</a>,
        width: 200
    },
    {
        accessor: 'billTo',
        Header: 'Bill To',
        style: { textAlign: 'center' },
        Cell: props => <a href="javascript:void(0)" onDoubleClick={()=>{        
        window.open(`${LAUNCH_URL}/${ROOT_ADMIN}/accountDetails?aid=${props.original.billToAccId}`,'_blank');
        }}>{props.original.billTo}</a>,
        width: 200
    },
    {
        accessor: 'reviewLocation',
        Header: 'Flag/Review Location',
        style: { textAlign: 'center' },
        width: 200
    },
    {
        accessor: 'reviewNote',
        Header: 'Flag Note',
        style: { textAlign: 'center' },
        width: 200
    }
];