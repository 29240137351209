import * as actionTypes from '../../actionTypes';
import { moduleTypes } from '../../../util/constants';
import { getAccessToken } from '../../authenticateActions'
import { getAxiosInstance } from '../../../util/service';
const receiptReport = { receiptDetails: [], receiptPaymentTypeTotals: [], receiptsTotalsDetails: [] }
export const getReciptReport = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/fetchReceipt/${obj.idCompany}/${status}/${obj.isRequestedBy}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&accountId=${obj.accountId}&location=`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            if (response.status === 200) {
                let reportObj = response.data.response || receiptReport;
                receiptReport['receiptDetails'] = reportObj['receiptDetails'] || [];
                receiptReport['receiptPaymentTypeTotals'] = reportObj['receiptPaymentTypeTotals'] || [];
                receiptReport['receiptsTotalsDetails'] = reportObj['receiptsTotalsDetails'] || [];
                dispatch({ type: actionTypes.GET_RECIPT_REPORT, receiptReport: receiptReport })
            } else {
                dispatch({ type: actionTypes.GET_RECIPT_REPORT, reciptReport: undefined })
            }
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_RECIPT_REPORT, receiptReport: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}
export const cleanReciptReport = () => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_RECIPT_REPORT, receiptReport: undefined })
    }
}
export const emailReciptReport = (obj, status) => {
    return async (dispatch) => {
        try {
            const _url = `/emailReceipts/${obj.idCompany}/${status}/${obj.isRequestedBy}?access_token=${getAccessToken()}&fromDate=${obj.fromDate}&toDate=${obj.toDate}&accountId=${obj.accountId}&location=`;
            const response = await getAxiosInstance(moduleTypes.REPORT).get(_url);
            return { statusCode: response.data.statusCode, statusMsg: response.data.statusMsg }
        } catch (ex) {
            dispatch({ type: actionTypes.GET_RECIPT_REPORT, receiptReport: undefined })
            return { statusCode: 500, statusMsg: 'An unexpected error occurred.' }
        }
    }
}