import * as actionTypes from '../../actions/actionTypes';
const defaultObject = {
    printEmailReceipt:{ 
        "emailFrom":"noreply@allcitytowing.com",
        "emailTo":"",
        "emailText":"",
        "base64Data":"",
        "extension":"pdf",
        "attachments": [],
        "systemNotes": [],  
        "userNotes": []
    },
    ratePlanInfoDetails:{},
    vinDetailsList:[],
    accountList: [],
    invoicePayments:{},
    accountListByType:[],
    invoicePaymentObj:{
            cctxNo: "",
            chequeNo: "",
            invoiceId: "",
            paymentAmt: 0,
            paymentDate: "",
            paymentId: 0,
            paymentLocation: "",
            paymentType: "",
            transactionNo: "",
            userId: "",
    },
    _invoiceObj: {
        "invoiceId": null,
        "displayInvoiceId":null,
        "serviceCallId": null,
        "accountId": '',
        "accountRatePlanId": null,
        "priority": null,
        "reason": "",
        "contactPerson": "",
        "contactPhoneNo": "",
        "callStatus": "",
        "requestTime": null,
        "dispatchTime": null,
        "acceptedTime": null,
        "enrouteTime": null,
        "arrivedTime": null,
        "hookedTime": null,
        "dropOffTime": null,
        "clearedTime": null,
        "elapsedTime": null,
        "callHold": 0,
        "callHoldDate": null,
        "callHoldTime": null,
        "rehookInvoiceId": null,
        "pickUpLocation": "",
        "pickUpAddr_Address_Line1": "",
        "pickUpAddr_Address_Line2": "",
        "pickUpAddr_Address_Landmark": "",
        "pickUpAddr_Address_City": "",
        "pickUpAddr_Address_State": "",
        "pickUpAddr_Address_Country": "",
        "pickUpAddr_Address_Zip": "",
        "dropOffLocation": "",
        "dropOffAddr_Address_Line1": "",
        "dropOffAddr_Address_Line2": "",
        "dropOffAddr_Address_Landmark": "",
        "dropOffAddr_Address_Type": "",
        "dropOffAddr_Address_City": "",
        "dropOffAddr_Address_State": "",
        "dropOffAddr_Address_Zip": "",
        "dropOffAddr_Address_Country": "",
        "dropOff_GPS_Address": null,
        "truckNo": 0, "towType": null,
        "driverId": null,
        "assignedBy": null,
        "clearedBy": null,
        "invoiceCreatedDate": "", 
        "enroute_Lat_Lang": null,
        "hooked_Lat_Lang": null,
        "dropoff_Lat_Lang": null,
        "enrouteLocation": null,
        "pickupToDropoffTime": "",
        "statusROPhoneWhenClear": 0,
        "statusROEmailWhenClear": 0,
        "reviewNote": null,
        "reviewLocation": null,
        "isDispatchCleared": null,
        "sourceReqBy": "",
        "salvageInvoiceId": null,
        "towBackSrcInvoiceId": null,
        "srcForSalvage": null,
        "srcForRehook": null,
        "idCompany": null,
        "pick_up_Lat_Lang": "",
        "drop_Lat_Lang": "",
        "dispatchClearedTime": null,
        "etaTime": "null",
        "isAddressChange": true,
        "duplicateCopyCount": null,
        "sourceInvoiceId": null,
        "callType": "",
        "isManualClear": false,
        "disableAutoAssign":false,
        "isArsCall":0,
        invoiceVehicle: {
            "invoiceId": null,
            "vehicle_Info_DLNo": "",
            "vehicle_VIN": "",
            "noVIN": 0,
            "noVINReason": "",
            "vehicle_Year": "",
            "vehicle_Make": "",
            "vehicle_Model": "",
            "vehicle_Color": "",
            "vehicle_Style": "",
            "vehicle_State": "",
            "vehicle_Country": "",
            "vehicle_Plate_No": "",
            "vehicle_Plate_Expiry": "",
            "vehicle_Key_Location": "",
            "vehicle_ODM_Reading": "",
            "vehicle_Status": "",
            "vehicle_Defects": "",
            "vehicle_Comm_Unit_No": "",
            "vehicle_InsuranceClaimNo": "",
            "isDriverIsOwner": 0,
            "noInfo": 0,
            "vehicle_Owner_FirstName": "",
            "vehicle_Owner_LastName": "",
            "vehicle_Owner_Addr_Line1": "",
            "vehicle_Owner_Addr_Line2": null,
            "vehicle_Owner_City": "",
            "vehicle_Owner_State": "",
            "vehicle_Owner_Zip": "",
            "vehicle_Owner_Country": null,
            "vehicle_Owner_PhoneNo": "",
            "vehicle_keys": null,
            "vehicle_Radio": null,
            "police_impound": "",
            "lotLocation": "",
            "vehicle_Owner_Email": "",
            "vehicle_OR_DR_No": "",
            "isImpound": 0,
            "outOfStorageDate": "",
            "vehicle_Image_URL": null,
            "isAppCleared": 0, "aaFile": null,
            "idCompany": null
        },
        serviceCallInfo: {
            "serviceCallId": null,
            "accountId": 0,
            "serviceCallType": "regular",
            "vehiclesToRelocate": null,
            "callRecevierId": "",
            "callRecevierFullName": "",
            "callerName": "",
            "callerPhoneNo": "",
            "callerEmailId": "",
            "callCreatedTime": "",
            "idCompany": null
        },
        invoiceBilling: {
            "invoiceId": null,
            "accountId": null,
            "billingTo": null,
            "acountRatePlan": null,
            "totalTimeToClear": null,
            "unloadedMiles": 0.0,
            "loadedMiles": 0.0,
            "totalMiles": 0.0,
            "unloadedMilesRate": 0.0,
            "loadedMilesRate": 0.0,
            "towCharge": 0.0,
            "laborCharge": 0.0,
            "mileageCharge": 0.0,
            "winch_Charge": 0.0,
            "storageCharge": 0.0,
            "storageDays": 0.0,
            "miscChargeLabel": null,
            "miscCharge": 0.0,
            "gateCharge": 0.0,
            "userDefined_Charge": "",
            "tax": 0.0,
            "invoiceHourlyRate": 0.0,
            "invoiceCommFlatRate": 0.0,
            "invoiceCommHourlyRate": 0.0,
            "invoiceCommHours": 0.0,
            "invoiceCommPercentage": 0.0,
            "invoiceCommMinPayPerCall": 0.0,
            "commission": 0.0,
            "uncollectedRevenue": 0.0,
            "extraCollectedRevenue": 0.0,
            "supervisorFee": 0.0,
            "refrigerationFee": 0.0,
            "dryRunFee": 0.0,
            "advancedPayout": 0.0,
            "totalCharge": 0.0,
            "serviceBillableHours": "0.0",
            "pONo": "",
            "serviceAdvisorName": null,
            "repairOrderNo": null,
            "paymentType": null,
            "flatDiscount": 0.0,
            "discountOnTow": 0.0,
            "discountOnLabor": 0.0,
            "discountOnMileage": 0.0,
            "discountOnWinch": 0.0,
            "discountOnMisc": 0.0,
            "discountOnStorage": 0.0,
            "totalDiscount": 0.0,
            "isPaid": 0,
            "invoiceDate": "",
            "billDate": null,
            "isPoliceHold": 0,
            "isVerifiedAndLocked": 0,
            "paymentLocation": null,
            "verifiedForReview": 0,
            "idCompany": null,
            "rehookedInvoiceId": null,
            "calendarDays": 0.0,
            "paidNoOfDays": 0,
            "storageAfterLimitCharge": 0.0,
            "balanceAmt": 0.0,
            "paymentAmt": 0.0,
            "ratePlan": {
                "accountRatePlanId": 0,
                "accountPlanRateCd": "",
                "idCompany": null,
                "idCorporation": 0,
                "idAccount": 0,
                "accountPlanRateName": "0",
                "accountPlanRateTowType": "",
                "ratePlanStartDate": "",
                "ratePlanEndDate": "",
                "sync_with_default": 0,
                "defaultRatePlanId": 0,
                "min_service_charge": 0.0,
                "service_flat_rate": 0.0,
                "service_hourly_rate": 0.0,
                "service_min_hours": 0.0,
                "service_increment_hours": 0.0,
                "service_free_hours": 0.0,
                "service_start_from": "",
                "service_end_by": "",
                "service_mileage_loaded_limit1": 0.0,
                "service_mileage_loaded_limit2": 0.0,
                "service_mileage_loaded_limit1_charge": 0.0,
                "service_mileage_loaded_limit2_charge": 0.0,
                "service_mileage_loaded_charge_after_limit": 0.0,
                "service_mileage_unloaded_limit1": 0.0,
                "service_mileage_unloaded_limit2": 0.0,
                "service_mileage_unloaded_limit1_charge": 0.0,
                "service_mileage_unloaded_limit2_charge": 0.0,
                "service_mileage_unloaded_charge_after_limit": 0.0,
                "storage_period": "",
                "storage_limit1_days": 0.0,
                "storage_limit1_charge": 0.0,
                "storage_limit2_days": 0.0,
                "storage_limit2_charge": 0.0,
                "storage_custom_period": 0.0,
                "storage_each_day_after_limit2_charge": 0.0,
                "driver_commision_type_id": 0,
                "free_hours": 0,
                "threshold": "",
                "customBehaviorId": null,
                "maxStorageCharge": null,
                "in_storage_time":
                    "Dropped"
            },
            "pcc36Charge": null
        },
        invoiceNotes: [],
        invoiceTitleProcess: null,
        invoiceRelease: null,
        invoicePayments: [],
        invoiceAttachments: [],
        auctionListInvoice: null,
        eligibilityStatus: {},
        eligibilityStatus:{
            "isSrcInvoiceTowback":false,
            "isSourceOfSalvage":false,
            "isSalvageElig":false,
            "isRehookOrTowbackElig":false,
            "isSrcInvoiceRehook":false
        }
    },
    accountReqData: {
    "idAccount":null,
    "idCompany":null,
    "paymentType":"",
    "priority":0,
    "accountingType":"",
    "isPORequired":0,
    "isPORequiredDropOff":0,
    "reviewLocation":"",
    "name":"",
    "accountAddress":{
        "idAccount":null
        ,"corp_addr_line1":""
        ,"corp_addr_line2":"",
        "corp_addr_city":"",
        "corp_addr_state":"",
        "corp_addr_zip":"",
        "corp_addr_phone":"",
        "corp_addr_fax":"",
        "corp_email_id":""
    },"accountDefaultLocation":{
        "accountId":null,
        "pickupAddr_AddressLine1":"",
        "pickupAddr_City":"",
        "pickupAddr_State":"",
        "pickupAddr_Zip":"",
        "pickupAddr_Location":"",
        "pickupAddr_LandMark":"",
        "pickupAddr_Country":"",
        "droffOffAddr_AddressLine1":"",
        "droffOffAddr_City":"",
        "droffOffAddr_State":"",
        "droffOffAddr_Zip":"",
        "droffOffAddr_Location":"",
        "droffOffAddr_LandMark":"",
        "droffOffAddr_Country":"",
        "droffOffAddr_Type":"",
        "defCallerName":"",
        "defCallerPhone":"",
        "defCallerEmail":"",
        "defContactName":"",
        "defContactPhone":"",
        "billTo":null,
        "pickup_lat_long":"",
        "dropoff_lat_long":"",
        "pono":""},
        "accountContacts":[],
        "accountNotes":[],
        "reasonList":[],
        "ratePlanOptions":[]
    } ,
    billingCommission:
    {
    invoiceCommFlatRate : 0.0,
    invoiceCommHourlyRate: 0.0,
	invoiceCommHours:"00:00",
	invoiceCommPercentage : 0.0,
	commission: 0.0,
	invoiceCommMinPayPerCall: 0.0,
	incrementHours : 0.0,
	minHour : 0.0,
	commissionHourlyRate : 0.0
    }

}
export const invoiceReducer = (state = defaultObject, action) => {
    switch (action.type) {
        case actionTypes.GET_ACCOUNT_LIST:
            return { ...state, accountList: (action.accountList || defaultObject.accountList) };
        case actionTypes.GET_INVOICE_INFO:
            return { ...state, _invoiceObj: (action._invoiceObj || defaultObject._invoiceObj) };
        case actionTypes.GET_REQ_ACCOUNT_INFO:
            return { ...state, accountReqData: (action.accountReqData || defaultObject.accountReqData) };
        case actionTypes.GET_INVOICE_COMM:
            return { ...state, billingCommission: (action.billingCommission || defaultObject.billingCommission) };        
        case actionTypes.GET_ACCOUNT_BY_TYPE:
            return { ...state, accountListByType: (action.accountListByType || defaultObject.accountListByType) };  
        case actionTypes.GET_VIN_DETAILS:
            return { ...state, vinDetailsList: (action.vinDetails || defaultObject.vinDetailsList) };
        case actionTypes.PRINT_EMAIL_RECEIPT:
            return { ...state, printEmailReceipt: (action.printemailObj || defaultObject.printEmailReceipt) }; 
        case actionTypes.RATEPLAN_INFO_DETAILS:
            return { ...state, ratePlanInfoDetails: (action._rateplanInfo || defaultObject.ratePlanInfoDetails) };     
        default:
            return state;
    }
};