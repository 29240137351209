import React, { Component,Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {email,phone,isNumeric,required,isValidForm} from '../../../../util/validationMethods';

class AccountInfoContacts extends Component {
    state={
            validated:false
        }
    handleContactSubmit(event) {
        this.form.validateAll();
        if(!isValidForm(this.form)){

        }else{            
            this.props.onContactSubmit(event);
        }
      }
    render(){
        return(<Fragment> 
            <Form ref={c => { this.form = c }}>
                <div className="form-group">
                    <label htmlFor="contactName" className="form-label">CONTACT NAME</label>
                    <Input name="contactName" value={this.props.contactObj.contactName} type="text" className="form-control form-control-sm"
                    requiredmsg="Please enter Contact Name"
                    onChange={(e)=>{
                                        this.props.onContactFieldChange(e.target.name,e.target.value);
                                    }} validations={[required]}/>
                    
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNo" className="form-label">PHONE NO</label>
                    <Input name="phoneNo" value={this.props.contactObj.phoneNo} type="text" 
                    className="form-control form-control-sm" 
                    requiredmsg="Please enter Phone No"
                    onChange={(e)=>{
                                        this.props.onContactFieldChange(e.target.name,e.target.value);
                                    //  validations={[required,phone]}
                                }}
                                    // />
                                     validations={(this.props.isPhoneRequired)?[required,phone]:[phone]}
                                    />
                    
                </div>
                <div className="form-group">
                    <label htmlFor="ext" className="form-label">EXT</label>
                    <Input name="ext" value={this.props.contactObj.ext} type="text" className="form-control form-control-sm" onChange={(e)=>{
                                        this.props.onContactFieldChange(e.target.name,e.target.value);
                                    }}
                                    //  validations={[isNumeric]}
                                    />
                    
                </div>
                <div className="form-group">
                    <label htmlFor="emailId" className="form-label">EMAIL-ID</label>
                    
                    <Input name="emailId" value={this.props.contactObj.emailId} type="text" className="form-control form-control-sm" 
                    requiredmsg="Please enter Email-ID"
                    onChange={(e)=>{
                                        this.props.onContactFieldChange(e.target.name,e.target.value);
                                    }} 
                                    // validations={[required,email]}
                                    // />
                                     validations={(this.props.isEmailRequired)?[required,email]:[email]}
                                     />
                    
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-10">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>{
                                                this.props.handleClose(e)
                                            }}>{'Cancel'}</button>
                        <button type="button" className="btn btn-success pull-right btn-sm" onClick={(e)=>{
                                                this.handleContactSubmit(e)
                                            }}>{this.props.submitBtnText}</button>
                    </div>
                </div>
            </Form>
        </Fragment>);
    }
}
export default AccountInfoContacts