import React, { Component,Fragment } from 'react';
import {Modal,Button} from 'react-bootstrap';
class TmConformModal extends Component{
    render(){
        return(
        <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
             >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                {this.props.title}
            </Modal.Title>
        </Modal.Header>
            <Modal.Body>
               {this.props.modalBody}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="btn btn-danger btn-sm" onClick={this.props.onHide}>{this.props.cancelText||'Cancel'}</Button>
                <Button variant="primary" className="btn btn-primary btn-sm" onClick={this.props.onSubmit}>{this.props.okText||'Ok'}</Button>
            </Modal.Footer>
         </Modal>);
    }
}
export default TmConformModal
  