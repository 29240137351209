import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import TowTypeList from '../../components/adminComponents/towType/towTypeList';
import TowTypeRec from '../../components/adminComponents/towType/towTypeRec'
import {getAllTowTypes,getTowType,clearTowType,changeTowTypeField,saveTowType} from '../../actions/adminAction/towTypeActions'
import {notify} from '../../util/notifications';
import {getAccessLevelsByModule} from '../../util/customMethods';
const pageTitle ="Tow Type";
class TowTypePage extends Component {
	constructor(){
		super();
		this.state={
			searchObject:{searchQuery:''},
			modalOpr:{
				modalShow:false
			}
		}
		this.checkViewAccess=this.checkViewAccess.bind(this)
		this.bindTowTypeList=this.bindTowTypeList.bind(this);
		this.handleClose=this.handleClose.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
		this.addTowTypeOnClick=this.addTowTypeOnClick.bind(this);
		this.editTowTypeOnClick=this.editTowTypeOnClick.bind(this);
		this.onFieldDataChange=this.onFieldDataChange.bind(this);
		this.searchClick=this.searchClick.bind(this)
	}
	componentWillMount=()=>{
		document.title=pageTitle;
		this.bindTowTypeList();		
	}
	bindTowTypeList=()=>{
		const {dispatch}=this.props;
		dispatch(getAllTowTypes(this.state.searchObject));
	}
	searchClick=(e,searchString)=>{
		if(!searchString) {
			notify.createNotification('warning','Please enter Tow Type Name',this.props.refNotify);
		}
		let _searchObject=this.state.searchObject
		_searchObject.searchQuery=searchString
		this.setState({searchObject:_searchObject},
			this.bindTowTypeList())
	}
	
	addTowTypeOnClick=(e)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		let _modalOpr=this.state.modalOpr;
		_modalOpr.modalShow=true;
		this.setState({modalOpr:_modalOpr},
			dispatch(getTowType(0))
			)
	}
	editTowTypeOnClick=(e,aid)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		let _modalOpr=this.state.modalOpr;
		_modalOpr.modalShow=true;
		this.setState({modalOpr:_modalOpr},
			dispatch(getTowType(aid))
			);
	}
	handleClose=(e)=> {
		const {dispatch} =this.props;
		let _modalOpr=this.state.modalOpr;
		_modalOpr.modalShow=false;
		this.setState({modalOpr:_modalOpr},
			dispatch(clearTowType())
		);
	}
	onFieldDataChange=(e,recObj)=>{
		console.log("TOW TYPE CHANGE", recObj)
		const {dispatch}=this.props;
		dispatch(changeTowTypeField(recObj));
	}
	handleSubmit=async (e,recObj)=>{
		e.preventDefault();
		const {dispatch} =this.props;
		if(this.props.towTypeObject.towTypeId<=0)
			recObj.towTypeId=null;
		const res=await dispatch(saveTowType(recObj));
		if(res.statusCode===200){
			notify.createNotification('success',res.message,this.props.refNotify);
			this.handleClose(e);
			this.bindTowTypeList();
		}else{
			notify.createNotification('error',res.statusMsg,this.props.refNotify);
		}
	}
	checkViewAccess=(_accessLevel={})=>{
		if(_accessLevel.basicAccess.view!==true){
			this.props.history.push('/admin/notfound');
		}
	}
  render() {
		const accessLevelObj=getAccessLevelsByModule('Tow Type');
		this.checkViewAccess(accessLevelObj)
    return (
      <Fragment>
				<div className="page-header">	
				<div className="row mb-10">			
						<div className="col-md-12 col-sm-12">
							<div className="title">
								<h4><i className="fa fa-id-card"></i> Tow Type Search</h4>
							</div>						
						</div>
						</div>
			</div>
			<div className="pd-10 bg-white border-radius-4 box-shadow container-box">
			<TowTypeList towTypeData={this.props.towTypeList} 
			addTowTypeOnClick={this.addTowTypeOnClick} accessLevel={accessLevelObj||{}}  
			editTowTypeOnClick={this.editTowTypeOnClick} onSearchClick={this.searchClick}  />
			</div>

			<Modal show={this.state.modalOpr.modalShow} > 
				<div className="modal-header">
            <Modal.Title>{`${this.props.towTypeObject.towTypeId ? 'Edit' : 'Add'} Tow Type`}</Modal.Title>
        </div>
        <TowTypeRec accessLevel={accessLevelObj||{}}
				recData={this.props.towTypeObject}
				onFieldDataChange={(e,recObject)=>{
					console.log(this.props.towTypeObject);
					this.onFieldDataChange(e,recObject);
				}} 
				onCancelForm={this.handleClose} 
				onSubmitForm={this.handleSubmit} />
        </Modal> 			
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo,
		refNotify:state.refNotify,
		towTypeList:state.towTypeList.towTypes,
		towTypeObject:state.towTypeList.towTypeObject
  }
};
export default withRouter(connect(mapStateToProps)(TowTypePage));
