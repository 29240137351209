import {GET_ARDETAILS_LIST} from './../../actionTypes';
import {moduleTypes} from '../../../util/constants';
import {getAccessToken} from '../../authenticateActions'
import {getAxiosInstance} from '../../../util/service';
//import { getCompanyId } from '../../../util/customMethods';
export const getARDetailsList=(accountType, accountId, asOfDate, idCompany)=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.REPORT).get(`showAccountsReceivableDetail_v2?access_token=${getAccessToken()}&companyId=${idCompany}&accountType=${accountType}&accountId=${accountId}&asOfDate=${asOfDate}`);
            if(resp.status===200){
                dispatch({type:GET_ARDETAILS_LIST,arDetailsObj:resp.data})
            }else{
                dispatch({type:GET_ARDETAILS_LIST,arDetailsObj:undefined})
            }
        }catch(ex){
            dispatch({type:GET_ARDETAILS_LIST,arDetailsObj:undefined})
        }
    }
}
export const getARDetailsClearList=()=>{
    return async (dispatch) => {
        dispatch({type:GET_ARDETAILS_LIST,arDetailsObj:undefined})
    }
}
