import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import TmSlimSelect from '../../utilComponents/tmSlimSelect/tmSlimSelect'
import {
    required
} from "../../../util/validationMethods.js";
class PaymentSettings extends Component {
    constructor(props) {
		super(props);
		this.state = {
            keys:''
		}
	}
	componentWillReceiveProps(nextProps) {
       // arr = arr.filter(v=>v!='');
        let objVal=nextProps.paymentObj.payLocationPinJson.map(function(k){return k['location']});
        this.setState({keys:objVal.join(",")});
    }
    createUI(locationPinSet) {
        return (locationPinSet || []).map((obj, i) =>
            <div className="" key={i}>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label htmlFor="keyInNo" className="form-label col-form-label">{obj.location||''} key in numbers</label>
                        <div>
                            <Input placeholder="" value={obj.keyInNo||''} key={i} 
                                validations={[required]} type="text" name="keyInNo" className="form-control form-control-sm" requiredmsg="Please enter key in numbers." onChange={(event) => { this.props.setMapOfKeyInNoSwipePinValue(event,(obj.location||'')) }} />
                        </div>
                    </div>
                    <div className="form-group col-sm-6">
                        <label htmlFor="user-id" className="form-label col-form-label">{obj.location||''} Swipe pin</label>
                        <div className="">
                            <Input placeholder="" value={obj.swipePin||''} onChange={(event) => { this.props.setMapOfKeyInNoSwipePinValue(event,(obj.location||'')) }}
                                validations={[required]} key={i}  requiredmsg="Please enter Swipe pin." type="text" name="swipePin" className="form-control form-control-sm" />
                        </div>
                    </div>
                </div>
                {/* <span className="form-group col-sm-2">
                    <button key={i} title="Remove" name="charges" className="btn btn-sm btn-danger">
                        <i class="fa fa-close" aria-hidden="true"></i>
                    </button>
                </span> */}
            </div>
        )
    }   
    render() {
        const {data,paymentObj}=this.props;
        return (<Fragment>
            <div className="pd-10">
                <div className="row mb-10">
                    <div className="col-md-7 offset-3 col-sm-12 mb-5">
                        <div className="card box-shadow">
                            <div className="card-body">
                                <Form ref={c => { this.form = c;
                                this.props.formCallback(c);
                                }} >
                                    <div className="col-sm-12"> 
                                    <fieldset >
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="marchant-id" className="form-label col-form-label">Marchant Id</label>
                                                <div>
                                                    <Input placeholder="" value={paymentObj.marchantId} 
                                                    validations={[required]} type="text" name="marchantId" className="form-control form-control-sm" requiredmsg="Please enter marchant id." onChange={(event)=>{this.props.setPaymentInfo(event)}}/>                     
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="user-id" className="form-label col-form-label">User Id</label>
                                                <div className="">
                                                    <Input placeholder="" value={paymentObj.userId} onChange={(event)=>{this.props.setPaymentInfo(event)}}
                                                    validations={[required]} requiredmsg="Please enter user id." type="text" name="userId" className="form-control form-control-sm"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="key-in-entry" className="form-label col-form-label">Pin Key In Entry</label>
                                                <div className="">
                                                    <Input placeholder=""  value={paymentObj.keyInEntry} onChange={(event)=>{this.props.setPaymentInfo(event)}}
                                                    validations={[required]} requiredmsg="Please enter keyInNumbersEntry."  type="text" name="keyInEntry" className="form-control form-control-sm"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="pin-swipe" className="form-label col-form-label">Pin Swipe </label>
                                                <div className="">
                                                <Input placeholder=""  value={paymentObj.pinSwipe} onChange={(event)=>{this.props.setPaymentInfo(event)}}
                                                    validations={[required]}  requiredmsg="Please enter swipePin."  type="text" name="pinSwipe" className="form-control form-control-sm"/>  
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="vendor-select-account" className="form-label col-form-label">Payment Loctions </label>
                                                <div className="">
                                                    <TmSlimSelect id={'locationsName'} selectedValues={(this.state.keys)} name="location" dataSet={data} 
                                                    textField={'location'} valueField={'location'} 
                                                    onSelectionChange={(e)=>{                                                       
                                                        let locationOptions = e.currentTarget.selectedOptions;
                                                        let locationValue = [];
                                                        for (let i = 0, l = locationOptions.length; i < l; i++) {
                                                          if (locationOptions[i].selected) {
                                                            locationValue.push(locationOptions[i].value);            
                                                          }
                                                        }                                                
                                                        this.props.setMapOfKeyInNoSwipePin(locationValue);
                                                    }} />
                                                </div>                                              
                                            </div>
                                        </div>                                         
                                        {this.createUI(paymentObj.payLocationPinJson)}
                                        </fieldset>
                                        <div className="row">
                                            <div className="col-sm-6 text-right">                                              
                                                    <button type="button"  onClick={this.props.savePaymentDetails}
                                                    className="btn btn-success btn-sm mr-2">{paymentObj.marchantId==''?'Add Payment Details' :'Update Payment Details'}</button> 
                                            </div>                                   
                                        </div> 
                                    </div>           
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>);
    }

}
export default PaymentSettings
